import React, { useState, useRef, useEffect } from "react";
import InputComponent from "../../components/InputComponent";
import ButtonComponent from "../../components/ButtonComponent";
import { RiImageAddLine } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";
import { Dropdown, Menu, Modal, Input } from "antd";
import { Images } from "../../constant/Images";
import ReactQuill from "react-quill";
import { AddBeneficiary, EditBeneficiary } from "../../config/api/index";
import { connect } from "react-redux";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { objectToFormData } from "../../config/formData/formData";
import {
  DeleteBeneficiariesAtLogoutTime,
  FetchBeneficiariesAction,
} from "../../Redux/actions/fetchBeneficiariesAction";
import { Errors, Success } from "../../components/antdToast";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";
import { DeleteProvidersAtLogoutTime } from "../../Redux/actions/fetchProvidersAction";
import { DeleteServiceAreasAtLogoutTime } from "../../Redux/actions/fetchServiceAreaAction";
import { DeleteBranchesAtLogoutTime } from "../../Redux/actions/fetchBranchesAction";
import { DeleteServicesAtLogoutTime } from "../../Redux/actions/fetchServicesAction";
import { Remove_Data_Form_Reducers } from "../../components/dataRemoveOnLogout";
import { DeleteServiceForms } from "../../Redux/actions/fetchServiceFormsAction";
import { DeleteAppUsers } from "../../Redux/actions/fetchAppUsersAction";

const { uploadImg } = Images;

function AddAdminServiceProvider(props) {
  const { goBack, push } = useHistory();
  // Declare Variable States
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [beneficiaryImage, setBeneficiaryImage] = useState("Upload Image");
  const [description, setDescription] = useState("");
  const [fieldIsRequired, setFieldIsrequired] = useState(false);

  useEffect(() => {
    props.getBeneificiariesList(props);
    if (props.match.params.id) {
      let findObj = props.showBeneficiaries.find(
        (obj) => obj.beneficiary_id === props.match.params.id
      );
      setBeneficiaryName(findObj.name);
      setDescription(findObj.info);
      setBeneficiaryImage(findObj.avatar);
    }
  }, []);

  const createBeneficiary = () => {
    if (
      description.length !== 0 &&
      beneficiaryImage !== "Upload Image" &&
      beneficiaryName !== ""
    ) {
      let obj = {
        name: beneficiaryName,
        info: description,
        avatar: beneficiaryImage,
      };
      let data = objectToFormData(obj);
      props.setLoaderState(true);
      AddBeneficiary(
        data,
        (success) => {
          if (success.status === 200) {
            props.setLoaderState(false);
            Success(success.data.response.message);
            setFieldIsrequired(false);
            setBeneficiaryImage("Upload Image");
            setBeneficiaryName("");
            setDescription("");
            props.getBeneificiariesList();
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            setFieldIsrequired(false);
            Remove_Data_Form_Reducers(props);
            push("/admin");
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
            setFieldIsrequired(false);
          }
        }
      );
    } else {
      setFieldIsrequired(true);
    }
  };

  const editBeneficiary = () => {
    if (
      description.length !== 0 &&
      beneficiaryImage !== "Upload Image" &&
      beneficiaryName !== ""
    ) {
      let obj = {
        name: beneficiaryName,
        info: description,
        avatar: beneficiaryImage,
        beneficiary_id: props.match.params.id,
      };
      let data = objectToFormData(obj);
      props.setLoaderState(true);
      EditBeneficiary(
        data,
        (success) => {
          if (success.status === 200) {
            props.setLoaderState(false);
            Success(success.data.response.message);
            setFieldIsrequired(false);
            setDescription("");
            props.getBeneificiariesList();
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.error?.message);
            setFieldIsrequired(false);
            Remove_Data_Form_Reducers(props);
            push("/admin");
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
            setFieldIsrequired(false);
          }
        }
      );
    } else {
      setFieldIsrequired(true);
    }
  };

  return (
    <section className="">
      <div className="flex self-center items-center ml-12 pt-10 pb-5 sub-h3">
        <div
          onClick={() => goBack()}
          className="flex text-gray-400 items-center cursor-pointer"
        >
          <RiArrowDropLeftLine />
          <span>Back</span>
        </div>

        <h3 className="font-bold text-lg mb-0 ml-10">
          {props.match.params.id
            ? "Edit Beneficiaries"
            : "Add New Beneficiaries"}
        </h3>
      </div>
      <div
        style={{
          paddingBottom: "10px",
          paddingTop: "20px",
        }}
        className="container lg:mx-auto lg:pl-32"
      >
        <div className="grid grid-cols-4 px-2 sm:px-2 lg:px-0 ">
          <div className="col-span-4 lg:col-span-1 sm:col-span-4 md:col-span-2 mt-2 ml-2 mr-2 relative cursor-pointer">
            <input
              className="rounded-full py-3 px-9 w-full absolute opacity-0  cursor-pointer"
              type={"file"}
              onChange={(e) => setBeneficiaryImage(e.target.files[0])}
              disabled={props.loader === true ? true : false}
            />
            <button className="bg-gray-400 rounded-full py-3 px-2 text-sm flex justify-center items-center w-full">
              <img
                style={{ cursor: "pointer" }}
                width="20"
                height="20"
                src={uploadImg.default}
                className="mr-1"
              />
              <span className=" text-white">
                {beneficiaryImage !== "Upload Image"
                  ? beneficiaryImage.name
                  : beneficiaryImage}
              </span>
            </button>
            {beneficiaryImage === "Upload Image" && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">Please fill the field!</span>
            ) : (
              ""
            )}
          </div>
          <div className="col-span-4 lg:col-span-1 sm:col-span-4 md:col-span-2">
            <InputComponent
              placeholder={"Beneficiary Name"}
              value={beneficiaryName}
              onChange={(e) => setBeneficiaryName(e.target.value)}
              disabled={props.loader === true ? true : false}
            />
            {beneficiaryName === "" && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">Please fill the field!</span>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className=" pt-5 pb-5 container px-2 sm:px-2 lg:px-2 grid grid-cols-4">
          <div className=" col-span-4 lg:col-span-2 sm:col-span-4 md:col-span-2">
            <ReactQuill
              className={"richTextBox "}
              value={description}
              onChange={setDescription}
              disabled={props.loader === true ? true : false}
            />
            {description.length === 0 && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">Please fill the field!</span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="w-912/12  sm:w-12/12  lg:w-2/6 mt-3 lg:ml-2 lg:pr-4 px-2 sm:px-2 lg:px-0">
          <ButtonComponent
            className="bg-primary"
            title={"Save"}
            onClick={
              props.match.params.id ? editBeneficiary : createBeneficiary
            }
            loader={props.loader === true ? true : false}
            disabled={props.loader === true ? true : false}
          />
        </div>
      </div>
    </section>
  );
}
const mapStateToProps = (state) => {
  return {
    loader: state.loaderToggle.loaderState,
    showBeneficiaries: state.beneficiariesList.beneficiaries,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBeneificiariesList: (props) => {
      dispatch(FetchBeneficiariesAction(props));
    },
    setLogoutUser: (data) => {
      dispatch(LogoutUserAction(data));
    },
    removeBeneficiaries: () => {
      dispatch(DeleteBeneficiariesAtLogoutTime());
    },
    removeProviders: () => {
      dispatch(DeleteProvidersAtLogoutTime());
    },
    removeServiceAreas: () => {
      dispatch(DeleteServiceAreasAtLogoutTime());
    },
    removeBranches: () => {
      dispatch(DeleteBranchesAtLogoutTime());
    },
    removeServices: () => {
      dispatch(DeleteServicesAtLogoutTime());
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
    removeServiceForms: () => {
      dispatch(DeleteServiceForms());
    },
    removeAppUsers: () => {
      dispatch(DeleteAppUsers());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAdminServiceProvider);
