import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Input,
  Menu,
  Modal,
  Spin,
  Switch,
  Tooltip
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiOutlineArrowDown, AiOutlinePlus } from "react-icons/ai";
import {
  RiArrowDropLeftLine,
  RiArrowDropRightLine,
  RiPencilLine
} from "react-icons/ri";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Errors, Success } from "../../components/antdToast";
import InformationContainer from "../../components/informationContainer";
import TableComponent from "../../components/table";
import {
  GetServiceProvider,
  UpdateServiceProviderManagementCapacityStatus,
  UpdateServiceProviderStatusMethod
} from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { Images } from "../../constant/Images";
import { DeleteBeneficiariesAtLogoutTime } from "../../Redux/actions/fetchBeneficiariesAction";
import { DeleteBranchesAtLogoutTime } from "../../Redux/actions/fetchBranchesAction";
import {
  DeleteProvidersAtLogoutTime,
  FetchProvidersAction
} from "../../Redux/actions/fetchProvidersAction";
import { DeleteServiceAreasAtLogoutTime } from "../../Redux/actions/fetchServiceAreaAction";
import { DeleteServicesAtLogoutTime } from "../../Redux/actions/fetchServicesAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";
import "./adminofserviceprovider.css";

const AdminServiceProvider = (props) => {
  const { goBack, push } = useHistory();
  const [providersList, setProvidersList] = useState([]);
  const [showInfoModal, setShowInfoModal] = React.useState(false);
  const [modal_Content, setModalContent] = React.useState("");
  const [loading, setLoading] = useState(true);
  const [currentSelectedLangue, setCurrentSelectedLangue] = useState("spanish");

  useEffect(() => {
    setCurrentSelectedLangue(localStorage.getItem("primaryLanguage"));
    fetchServiceProviders();
    document.body.style.overflow = "auto";
    return function cleanup() {
      document.body.style.overflow = "auto";
    };
  }, []);
  const fetchServiceProviders = () => {
    GetServiceProvider(
      (res) => {
        let data = res?.data?.response?.detail
          ?.reverse()
          ?.map((provider, index) => {
            return {
              ...provider,
              no: index + 1,
              registerDate: moment(provider?.created_at).format("DD-MMM-YYYY HH:mm"),
              workingHours: provider?.working_hours,
              detail: { avatar: provider.avatar, name: provider.name },
            };
          });
        setProvidersList(data);
        setLoading(false);
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  const setServiceProviderActive = (id) => {
    let userId = new FormData();
    userId.append("service_provider_id", id);
    UpdateServiceProviderStatusMethod(
      userId,
      (success) => {
        if (success.status === 200) {
          let serviceProviderUpdatedArray = providersList.map((item) => {
            if (item.service_provider_id === id) {
              return { ...item, status: item.status === 1 ? 0 : 1 };
            } else {
              return item;
            }
          });
          setProvidersList(serviceProviderUpdatedArray);
          Success(success?.data?.response?.message);
        }
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };

  const setServiceProviderManagementCapacityStatus = (id) => {
    let userId = new FormData();
    userId.append("service_provider_id", id);
    UpdateServiceProviderManagementCapacityStatus(
      userId,
      (success) => {
        if (success.status === 200) {
          let serviceProviderUpdatedArray = providersList.map((item) => {
            if (item.service_provider_id === id) {
              return { ...item, no_capacity: item.no_capacity === 1 ? 0 : 1 };
            } else {
              return item;
            }
          });
          setProvidersList(serviceProviderUpdatedArray);
          Success(success?.data?.response?.message);
        }
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  }
  const columns = [
    {
      align: "center",
      title: adminLabels.no,
      dataIndex: "no",
      key: "no",
      width: 70,
      className: "capitalize",
      render: (text) => <a>{text}</a>,
    },

    {
      align: "center",
      title: adminLabels.name,
      dataIndex: "detail",
      key: "detail",
      width: 200,

      render: (text, index) => (
        <>
          <Tooltip placement="topLeft" title={text.name}>
            <div className={"flex"}>
              <img
                src={
                  text.avatar !== null ? text.avatar : Images.profile.default
                }
                style={{ width: 50, height: 45 }}
                className="rounded-lg"
                alt="detail image"
              />
              <div className="pl-2 text-left mt-2 capitalize">
                <label>{text.name}</label>
                <br />
              </div>
            </div>
          </Tooltip>
        </>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.detail?.name
          ?.toLowerCase()
          .includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.detail?.name?.localeCompare(b?.detail?.name),
    },
    {
      align: "center",
      title: adminLabels.email,
      dataIndex: "email",
      key: "email",
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (email) => (
        <Tooltip placement="topLeft" title={email}>
          {email}
        </Tooltip>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.email.toLowerCase().includes(value.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.email.localeCompare(b.email),
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: adminLabels.phone,
      className: "capitalize",
      key: "phone",
      dataIndex: "phone",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (phone) => (
        <Tooltip placement="topLeft" title={phone}>
          {phone}
        </Tooltip>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.phone.toLowerCase().includes(value.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.phone - b.phone,
      showSorterTooltip: false,
    },

    {
      align: "center",
      className: "capitalize",
      title: adminLabels.registeredDate,
      dataIndex: "registerDate",
      key: "registerDate",
      width: 150,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        moment(a.registerDate).unix() - moment(b.registerDate).unix(),
      showSorterTooltip: false,
      render: (registerDate) => (
        <Tooltip placement="topLeft" title={registerDate}>
          {registerDate}
        </Tooltip>
      ),
    },
    {
      align: "center",
      title: adminLabels.city,
      dataIndex: "city",
      key: "city",
      width: 150,
      className: "capitalize",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.city?.toLowerCase().includes(value.toLowerCase());
      },
      render: (city) => (
        <Tooltip placement="topLeft" title={city}>
          {city}
        </Tooltip>
      ),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.city.localeCompare(b.city),
    },
    {
      align: "center",
      title: adminLabels.region,
      dataIndex: "state",
      key: "Region",
      width: 150,
      className: "capitalize",
      ellipsis: {
        showTitle: false,
      },
      render: (Region) => (
        <Tooltip placement="topLeft" title={Region.join(", ")}>
          {Region.join(", ")}
        </Tooltip>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.Region?.toLowerCase().includes(value.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.Region?.localeCompare(b.Region),
    },
    {
      align: "center",
      title: adminLabels.workingHours,
      key: "workingHours",
      dataIndex: "workingHours",
      width: 350,
      className: "capitalize",
      render: (e) => (
        <Tooltip
          placement="topLeft"
          title={e.map((hour) => {
            return hour?.isFriday === false ? (
              <div className=" w-full flex  flex-col">
                <span>
                  {adminLabels?.monToThu} : {hour?.start} - {hour?.end}
                </span>
                {hour?.hasOwnProperty("monTHuLunchHours") &&
                  hour?.monTHuLunchHours !== null && (
                    <span className="">
                      {adminLabels?.lunchTime} : {hour?.monTHuLunchHours?.start}{" "}
                      - {hour?.monTHuLunchHours?.end}
                    </span>
                  )}
              </div>
            ) : (
              <div className=" w-full flex flex-col">
                <span>
                  {adminLabels?.fri} : {hour?.start} - {hour?.end}
                </span>
                {hour?.hasOwnProperty("fridayLunchHours") &&
                  hour?.fridayLunchHours !== null && (
                    <span className="">
                      {adminLabels?.lunchTime} : {hour?.fridayLunchHours?.start}{" "}
                      - {hour?.fridayLunchHours?.end}
                    </span>
                  )}
              </div>
            );
          })}
        >
          <div
            className={`mb-2    text-sm flex justify-between items-center w-full ${
              e.length === 0 ? `py-3` : `py-2`
            } `}
          >
            {e.length ? (
              <div
                className={` flex flex-col items-start text-xs w-full ${
                  e.length === 1 ? `py-2` : `py-0`
                }`}
              >
                {" "}
                {e.map((hour) => {
                  return hour?.isFriday === false ? (
                    <div className=" w-full flex justify-between">
                      <span>
                        {adminLabels?.monToThu} : {hour?.start} - {hour?.end}
                      </span>
                      {hour?.hasOwnProperty("monTHuLunchHours") &&
                        hour?.monTHuLunchHours !== null && (
                          <span className="ml-2">
                            {adminLabels?.lunchTime} :{" "}
                            {hour?.monTHuLunchHours?.start} -{" "}
                            {hour?.monTHuLunchHours?.end}
                          </span>
                        )}
                    </div>
                  ) : (
                    <div className=" w-full flex justify-between">
                      <span>
                        {adminLabels?.fri} : {hour?.start} - {hour?.end}
                      </span>
                      {hour?.hasOwnProperty("fridayLunchHours") &&
                        hour?.fridayLunchHours !== null && (
                          <span className="ml-2">
                            {adminLabels?.lunchTime} :{" "}
                            {hour?.fridayLunchHours?.start} -{" "}
                            {hour?.fridayLunchHours?.end}
                          </span>
                        )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <span className=" pb-1 text-gray-400 font-medium">
                {adminLabels.working_hours}{" "}
              </span>
            )}
          </div>
        </Tooltip>
      ),
    },
    {
      align: "center",
      title: adminLabels.information,
      className: "capitalize",

      ellipsis: true,
      width: 95,
      render: (text, index) => (
        <div className=" flex justify-center">
          <span
            className="cursor-pointer flex items-center space-x-1 "
            onClick={() => {
              currentSelectedLangue === "english"
                ? setModalContent(text?.info || "")
                : setModalContent(text?.info_sp || "")
              setShowInfoModal(true);
            }}
          >
            <EyeOutlined />
            <span>{adminLabels.infoBox}</span>
          </span>
          {/* </Tooltip> */}
        </div>
      ),
    },
    {
      title: adminLabels.action,
      key: "action",
      className: "capitalize",
      width: 130,
      render: (text, index) => (
        <div className="text-center	">
          <Dropdown
            overlay={() => menu(text.service_provider_id, text)}
            className={"flex"}
            trigger={["click"]}
          >
            <div
              type="disable"
              shape={"round"}
              className="background-color-gray whiteTxt rounded-full py-2 justify-center cursor-pointer	"
            >
              <span>{adminLabels.viewDetails}</span>
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  const menu = (id, values) => (
    <Menu className="menu-class menu-main-class" style={{ borderRadius: 20 }}>
      <Menu.Item className="bg-white" style={{ borderRadius: 20 }}>
        <Link to={`/admin/service-provider/${id}`} params={{ id }}>
          <div
            className="flex justify-between bg-white py-2 w-full  items-center"
            style={{ width: 160 }}
          >
            <div>
              <span>{adminLabels.providerDetails}</span>
            </div>
            <div>
              <RiArrowDropRightLine color="#5ab3f0" size={20} />
            </div>
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item
        className="bg-white"
        style={{ borderRadius: 20 }}
        key={values.id + Math.random()}
        onClick={() => handleMenu(id, values)}
      >
        <div
          className="flex justify-between bg-white py-2 w-full  items-center"
          style={{ width: 160 }}
        >
          <div>
            <span>{adminLabels.edit}</span>
          </div>
          <div>
            <RiPencilLine color="#46bcff" size={20} />
          </div>
        </div>
      </Menu.Item>
      <Menu.Item className="bg-white" style={{ borderRadius: 20 }}>
        <div
          className="flex justify-between bg-white py-2 w-full  items-center"
          style={{ width: 160 }}
        >
          <div>
            <span>{adminLabels.providerStatus}</span>
          </div>

          <div>
            <Switch
              size="small"
              checked={values?.status === 1 ? true : false}
              onChange={() =>
                setServiceProviderActive(values.service_provider_id)
              }
            />
          </div>
        </div>
      </Menu.Item>
      <Menu.Item className="bg-white" style={{ borderRadius: 20 }}>
        <div
          className="flex justify-between bg-white py-2 w-full  items-center"
          style={{ width: 160 }}
        >
          <div>
            <span>{adminLabels.managementCapacity}</span>
          </div>

          <div>
            <Switch
              size="small"
              checked={values?.no_capacity === 1 ? true : false}
              onChange={() =>
                setServiceProviderManagementCapacityStatus(values.service_provider_id)
              }
            />
          </div>
        </div>
      </Menu.Item>
    </Menu>
  );
  const handleMenu = (id, provider) => {
    push({
      pathname: `/admin/edit-admin-service-provider/${id}`,
      state: { provider: provider },
    });
  };
  return (
    <section className="">
      <Spin tip={adminLabels.loading} spinning={loading}>
        <div className="flex justify-between mt-5">
          <div className="pl-5 flex self-center justify-center items-center sub1-h3 ">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{adminLabels.back}</span>
            </div>
            <h3 className="ml-10	">{adminLabels.adminOfServiceProvider}</h3>
          </div>
          <div className="mr-2 sm:mr-2 lg:mr-28 flex">
            {/* <div className="mr-2 ">
            <Button shape={"circle"} className="icon-button p0 ">
              <FiSearch size={15} />
            </Button>
          </div> */}
            <Link
              to="/admin/add-admin-service-provider"
              className="mx-4 md:mx-4  sm:mr-4 md:mr-4 lg:mr-2"
            >
              <Button
                shape={"round"}
                className="icon-button "
                icon={<AiOutlinePlus color="#5ab3f0" />}
              >
                {adminLabels.addNew}
              </Button>
            </Link>
            <CSVLink
              data={providersList?.map((item, index) => {
                return {
                  ...item,
                  no: index + 1,
                  region: item?.state?.join(),
                  hours: item?.workingHours?.map((hour, index) => {
                    if (hour?.isFriday === false) {
                      if (hour?.hasOwnProperty("monTHuLunchHours") && hour?.monTHuLunchHours !== null) {
                        return `${adminLabels?.monToThu} : ${hour?.start} - ${hour?.end} , ${adminLabels?.lunchTime} : ${hour?.monTHuLunchHours?.start} - ${hour?.monTHuLunchHours?.end}`;
                      } else {
                        return `${adminLabels?.monToThu} : ${hour?.start} - ${hour?.end}`;
                      }
                    } else {
                      if (hour?.hasOwnProperty("fridayLunchHours") && hour?.fridayLunchHours !== null) {
                        return `${adminLabels?.fri} : ${hour?.start} - ${hour?.end} , ${adminLabels?.lunchTime} : ${hour?.fridayLunchHours?.start} - ${hour?.fridayLunchHours?.end}`;
                      }else {
                        return `${adminLabels?.fri} : ${hour?.start} - ${hour?.end}`;
                      }
                    }
                  }),
                };
              })}
              headers={[
                { label: adminLabels.no, key: "no" },
                { label: adminLabels.name, key: "name" },
                { label: adminLabels.email, key: "email" },
                { label: adminLabels.phone, key: "phone" },
                { label: adminLabels.registeredDate, key: "registerDate" },
                { label: adminLabels.city, key: "city" },
                { label: adminLabels.region, key: "region" },
                { label: adminLabels.workingHours, key: "hours" },
              ]}
              filename={`${adminLabels.adminOfServiceProvider}.csv`}
              target="_blank"
            >
              <Button
                shape={"round"}
                className="icon-button "
                icon={<AiOutlineArrowDown color="#5ab3f0" />}
              >
                {adminLabels.exportCSV}
              </Button>
            </CSVLink>
          </div>
        </div>
        <div className="container mx-auto px-0.5  sm:px-0.5 lg:px-20 mt-5 ">
          <TableComponent
            columns={columns}
            scrolly="500px"
            scroll="130%"
            data={providersList}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
          />
          <Modal
            visible={showInfoModal}
            footer={null}
            centered
            className="global-modal-css-scroll-height "
            onCancel={() => {
              setShowInfoModal(false);
            }}
          >
            <div>
              <h4 className="font-bold text-2xl text-center text-gray-600	">
                {adminLabels.information}
              </h4>
            </div>
            <div className="scrollable-fixed-height py-5 render-html-class ">
              <InformationContainer info={modal_Content}/>
            </div>
          </Modal>
        </div>
      </Spin>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    showProviders: state.providerList.providers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProvidersList: (props) => {
      dispatch(FetchProvidersAction(props));
    },
    setLogoutUser: (data) => {
      dispatch(LogoutUserAction(data));
    },
    removeBeneficiaries: () => {
      dispatch(DeleteBeneficiariesAtLogoutTime());
    },
    removeProviders: () => {
      dispatch(DeleteProvidersAtLogoutTime());
    },
    removeServiceAreas: () => {
      dispatch(DeleteServiceAreasAtLogoutTime());
    },
    removeBranches: () => {
      dispatch(DeleteBranchesAtLogoutTime());
    },
    removeServices: () => {
      dispatch(DeleteServicesAtLogoutTime());
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminServiceProvider);
