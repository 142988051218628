import {
  BellOutlined,
  FileTextOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Collapse,
  Empty,
  Input,
  List,
  Menu,
  Modal,
  Spin,
} from "antd";
import {
  child,
  onValue,
  push as fbPush,
  ref,
  runTransaction,
  serverTimestamp,
  update,
} from "firebase/database";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RiArrowDropLeftLine } from "react-icons/ri";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Errors, Success } from "../../components/antdToast";
import ButtonComponent from "../../components/ButtonComponent";
import { Remove_Data_Form_Reducers } from "../../components/dataRemoveOnLogout";
import { Remove_Data_Form_Provider_Reducers } from "../../components/dataRemoveOnProviderLogout";
import InformationContainer from "../../components/informationContainer";
import {
  GetMessagesMethod,
  GetOrderNotificationById,
  GetOrdersById,
  OrderDisputeCancelMethod,
  OrderDisputeContinueMethod,
  SendOrderMessageMethod,
  UploadFileMethod,
} from "../../config/api";
import { firebaseDatabase } from "../../config/Firebase";
import { adminLabels } from "../../config/label/adminLabels";
import {
  europeanCurrencyFormatter,
  functionToConvertStringToCamelCase,
} from "../../config/utils";
import { Images } from "../../constant/Images";
import {
  DeleteWorkerAtLogoutTime,
  FetchWorkersAction,
} from "../../Redux/actions/fetchWorkersAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { ProviderLogoutAction } from "../../Redux/actions/logoutAction";
import "./myMessageOrder.css";
import "./user-provider-chat.css";
import { useRef } from "react";
const format = "HH:mm";
let urlArray = [];

const UserProviderOrderMessages = (props) => {
  const { goBack, push } = useHistory();
  const messagesEndRef = useRef();

  const [filterModal, setFilterModal] = useState(false);
  const [message, setMessage] = useState("");
  const [value, setValue] = useState("");
  const [urls, setUrls] = useState([]);
  const [addBudgetUrls, setAddBudgetUrls] = useState([]);
  const [muiltipleDocument, setMuiltipleDocument] = useState("");
  const [additionalBudgetDocument, setAdditionalBudgetDocument] = useState("");
  const [budget, setBudget] = useState(null);
  const [estimatedTime, setEstimatedTime] = useState("");
  const [current, setCurrent] = useState(0);
  const [isSupplementaryCharges, setIsSupplementaryCharges] = useState(false);
  const [supplementaryCharges, setSupplementaryCharges] = useState([]);
  const [userProviderChat, setUserProviderChat] = useState([]);
  const [chatLoading, setChatLoading] = useState(true);
  const [fileUploadLoader, setUploadLoader] = useState(false);

  const [stepsIncrement, setStepsIncrement] = useState([
    {
      id: Math.round(Math.random() * 100),
      name: "",
      price: 0,
    },
  ]);
  const [fieldIsRequired, setFieldIsrequired] = useState(false);
  const [chatSideBar, setChatSideBar] = useState(false);
  const [myChatDocuments, setMyChatDocuments] = useState([]);
  const [userChatDocuments, setUserChatDocuments] = useState([]);
  const [providerChatDocuments, setProviderChatDocuments] = useState([]);
  const [providerSignature, setProviderSignature] = useState();
  const [orderNotification, setOrderNotification] = useState([]);
  const [index, changeIndex] = useState(0);
  const [serviceTimeType, setServiceTimeType] = useState([
    "Hour",
    "Week",
    "Day",
  ]);
  const [selectedTimeType, setSelectedTimeType] = useState(null);
  const [serviceTime, setServiceTime] = useState(null);
  const [visible, setVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [disputedOrder, setDisputedOrder] = useState(false);
  const [disputeConfirmationModal, setDisputeConfirmationModal] =
    useState(false);
  const [disputedDetailsModal, setDisputedDetailsModal] = useState(false);
  const [disputeOrderStatus, setDisputeOrderStatus] = useState("");
  const [disputeOrderRefund, setDisputeOrderRefund] = useState("");
  const [disputeOrderRefundStatus, setDisputeOrderRefundStatus] = useState("");
  const [disputeOrderRefundComplete, setDisputeOrderRefundComplete] =
    useState("");

  const [partialAmount, setPartialAmount] = useState("");
  const [partialAmountSupplementary, setPartialAmountSupplementary] =
    useState("");

  const [is_supplementary, setIs_supplementary] = useState(true);
  const { id } = useParams();

  const fireBaseListenerToGetMessages = () => {
    const getData = ref(firebaseDatabase, `orders/${id}/messages`);

    onValue(getData, (snapshot) => {
      const data = snapshot?.val();
      setUserProviderChat(Object?.values(data || {}));
      runTransaction(
        ref(firebaseDatabase, "orders/" + id + "/adminUnread"),
        () => {
          return 0;
        }
      );

      setUserChatDocuments(
        Object?.values(data || {})
          ?.reverse()
          ?.filter((document) => {
            return (
              document.is_provider_message == "0" &&
              document.is_admin_message == "0"
            );
          })
      );
      setMyChatDocuments(
        Object?.values(data || {})
          ?.reverse()
          ?.filter((document) => {
            return document.is_admin_message == "1";
          })
      );
      setProviderChatDocuments(
        Object?.values(data || {})
          ?.reverse()
          ?.filter((document) => {
            return document.is_provider_message == "1";
          })
      );

      setChatLoading(false);
    });
  };

  const firebaseSendMessage = (messages) => {
    // A post entry.
    const postData = {
      is_provider_message: "0",
      service_provider_id: providerSignature?.service_provider_id,
      service_provider: providerSignature,
      admin_id: null,
      admin: null,
      is_admin_message: "1",
      user_id: selectedOrder?.user_id,
      user: selectedOrder?.user,
      message: messages,
      time: serverTimestamp(),
    };

    // Get a key for a new Post.
    const newPostKey = fbPush(child(ref(firebaseDatabase), "posts")).key;

    runTransaction(
      ref(firebaseDatabase, "orders/" + id + "/adminUnread"),
      () => {
        return 0;
      }
    );
    runTransaction(
      ref(firebaseDatabase, "orders/" + id + "/userUnread"),
      (post) => {
        if (post === null) return 1;
        return post + 1;
      }
    );
    runTransaction(
      ref(firebaseDatabase, "orders/" + id + "/providerUnread"),
      (post) => {
        if (post === null) return 1;
        return post + 1;
      }
    );
    // Write the new post's data simultaneously in the posts list and the user's post list.
    const updates = {};
    // updates['/posts/' + newPostKey] = postData;
    updates["/orders/" + id + "/messages/" + newPostKey] = postData;
    scrollToBottom();
    return update(ref(firebaseDatabase), updates);
  };
  function scrollToBottom() {
    messagesEndRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "end",
    });
  }
  useEffect(() => {
    scrollToBottom();
    fetchServiceProviderOrderNotifications(id);
    fireBaseListenerToGetMessages();
    getOrdersByProvider(id);

    const interval = setInterval(() => {
      fetchServiceProviderOrderNotifications(id);
    }, 20000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (disputeOrderStatus === "" || disputeOrderStatus === "continue") {
      setDisputeOrderRefundStatus("");
      setDisputeOrderRefundComplete("");
    }
  }, [disputeOrderStatus]);

  useEffect(() => {
    if (
      disputeOrderRefundStatus === "" ||
      disputeOrderRefundStatus === "partial"
    ) {
      setDisputeOrderRefundComplete("");
    }
  }, [disputeOrderRefundStatus]);
  const getOrdersByProvider = (id) => {
    GetOrdersById(
      id,
      (success) => {
        if (success.status === 200) {
          if (success.data.response.detail !== null) {
            setSelectedOrder(success?.data?.response?.detail);
            if (
              success?.data?.response?.detail?.status === "dispute" ||
              success?.data?.response?.detail?.status === "disputed"
            ) {
              setDisputedOrder(false);
            } else {
              setDisputedOrder(false);
            }

            setProviderSignature(
              success?.data?.response?.detail?.service_provider
            );
          }
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  useEffect(() => {
    const disputeData = selectedOrder?.order_dispute_refund;

    setDisputeOrderRefundStatus(disputeData?.type);
    setPartialAmount(Number(disputeData?.user_percent));
    if (disputeData?.type === "complete") {
      setDisputeOrderRefundComplete(
        disputeData?.user_percent === 100 ? "user" : "provider"
      );
    }
  }, [selectedOrder]);
  const fetchUserAndProviderMessages = (id) => {
    GetMessagesMethod(
      id,
      (res) => {
        let data = res?.data?.response?.detail?.messages;
        setUserProviderChat(data);
        setMyChatDocuments(
          data.reverse().filter((document) => {
            return document.is_provider_message == "1";
          })
        );
        setUserChatDocuments(
          data.reverse().filter((document) => {
            return document.is_provider_message == "0";
          })
        );
        setChatLoading(false);
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  const fetchServiceProviderOrderNotifications = (id) => {
    GetOrderNotificationById(
      id,
      (res) => {
        setOrderNotification(res?.data?.response?.detail);
        setChatLoading(false);
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  const {
    document,
    additionalBudgetIcon,
    orderCanceledIcon,
    orderPlacedIcon,
    checkbox,
    checkboxActive,
  } = Images;

  const sendMessageToUser = () => {
    if (disputedOrder === false) {
      if (message !== "" || urls.length !== 0) {
        let obj = {
          order_id: props.match.params.id,

          is_provider_message: "0",
          is_admin_message: "1",
          message: JSON.stringify([
            {
              message: message === "" ? null : message,
              documents: urls.length === 0 ? null : urls,
            },
          ]),
        };
        firebaseSendMessage([
          {
            message: message === "" ? null : message,
            documents: urls.length === 0 ? null : urls,
          },
        ]);

        SendOrderMessageMethod(
          obj,
          (success) => {
            if (success.status === 200) {
              props.setLoaderState(false);

              Success(success.data.response.message);
              // fetchUserAndProviderMessages(id);
              setMessage("");
              setMuiltipleDocument("");
              setUrls([]);
            }
          },
          (error) => {
            if (error?.response?.status === 401) {
              props.setLoaderState(false);
              Errors(error?.response?.data?.message);
              Remove_Data_Form_Provider_Reducers(props);
              push("/");
            } else if (error?.response?.status === 409) {
              Errors(error?.response?.data?.error?.message);
              props.setLoaderState(false);
            } else {
              props.setLoaderState(false);
              Errors("Network Error");
            }
          }
        );
      }
    }
  };
  const handleText = (e, index) => {
    const mappedStepsIncrement = stepsIncrement?.map((value, index1) => {
      if (index1 === index) {
        return {
          name: e.target.value,
          id: value.id,
          price: value?.price,
        };
      } else {
        return value;
      }
    });

    setStepsIncrement(mappedStepsIncrement);
  };
  const handleAmount = (e, index) => {
    const mappedStepsIncrement = stepsIncrement?.map((value, index1) => {
      if (index1 === index) {
        return {
          name: value?.name,
          id: value?.id,
          price: e.target.value,
        };
      } else {
        return value;
      }
    });

    setStepsIncrement(mappedStepsIncrement);
  };
  const prev = (id, index) => {
    supplementaryCharges.splice(index, 1);
    deleteStepInput(id);
  };
  // delete antd stepper in dropdownmodal function
  const deleteStepInput = (id) => {
    setCurrent(current - 1);
    let updatedArray = stepsIncrement.filter((item) => {
      return item.id != id;
    });
    setStepsIncrement(updatedArray);
  };
  //stepper add in dropdown modal function
  const next = () => {
    setStepsIncrement((prevState) => {
      setCurrent(current + 1);
      return [
        ...prevState,
        {
          id: Math.round(Math.random() * 100),
          name: "",
          price: 0,
        },
      ];
    });
  };

  const getUrl = (url, name, type, id) => {
    setUrls((prevurls) => [...prevurls, { url, name, type, id }]);
  };
  const selectMuiltipleDocument = async (valueArray) => {
    setUploadLoader(true);
    let muiltipleFileNames = "";
    valueArray?.map((r, i) => {
      muiltipleFileNames += `${r.name}, `;
    });
    valueArray?.map((file, i) => {
      let formData = new FormData();
      formData.append("file", file);
      UploadFileMethod(
        formData,
        (success) => {
          if (success.status === 200) {
            if (success.data.response.detail !== null) {
              let id = Math.round(Math.random() * 10000);
              getUrl(success.data.response.detail, file.name, file.type, id);
            }
          }
          setUploadLoader(false);
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            Remove_Data_Form_Reducers(props);
            push("/");
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
          }
          setUploadLoader(false);
        }
      );
    });
    setMuiltipleDocument(muiltipleFileNames);
  };
  const fileprev = (item, index) => {
    setAddBudgetUrls(addBudgetUrls?.filter((file) => file.id !== item.id));
  };
  const getadditionalBudgetUrl = (url, name, type, id) => {
    setAddBudgetUrls((prevurls) => [...prevurls, { url, name, type, id }]);
  };
  const selectMuiltipleBudgetDocument = async (valueArray) => {
    let muiltipleFileNames = "";
    valueArray?.map((r, i) => {
      muiltipleFileNames += `${r.name}, `;
    });
    valueArray?.map((file, i) => {
      let formData = new FormData();
      formData.append("file", file);
      UploadFileMethod(
        formData,
        (success) => {
          if (success.status === 200) {
            if (success.data.response.detail !== null) {
              let id = Math.round(Math.random() * 10000);
              getadditionalBudgetUrl(
                success.data.response.detail,
                file.name,
                file.type,
                id
              );
            }
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            Remove_Data_Form_Reducers(props);
            push("/");
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
          }
        }
      );
    });
    setAdditionalBudgetDocument(muiltipleFileNames);
  };

  const downloadAllUserFiles = () => {};
  // const extraDownloadAllUserFiles = () => (
  //   <Tooltip title="download all user files">
  //     <DownloadOutlined
  //       style={{ fontSize: "18px", color: "#46bcff " }}
  //       onClick={(event) => {
  //         axios({
  //           url: "https://docudoc-resources.s3.eu-west-3.amazonaws.com/files/3bd13025-727e-4c46-8309-6ef1edc69275.doc",
  //           method: "GET",
  //           responseType: "blob",
  //           // headers: { Accept: '*/*' }
  //         })
  //           .then((response) => {
  //             const url = window.URL.createObjectURL(new Blob([response.data]));
  //             const link = document.createElement("a");
  //             link.href = url;
  //             link.setAttribute("download", "word.jpg");
  //             document.body.appendChild(link);
  //             link.click();
  //             document.body.removeChild(link);
  //           })
  //           .catch((err) => {
  //
  //           });
  //         // If you don't want click extra trigger collapse, you can prevent this:
  //         event.stopPropagation();
  //       }}
  //       // onClick={downloadAllUserFiles()}
  //     />
  //   </Tooltip>
  // );

  const handleMenuClick = (e) => {
    setSelectedTimeType(e.key);
    if (e.key === "3") {
      setVisible(false);
    }
  };
  const menu1 = (
    <Menu onClick={handleMenuClick}>
      {serviceTimeType?.map((type) => {
        return <Menu.Item key={type}>{type}</Menu.Item>;
      })}
    </Menu>
  );
  const PartialCancelOrderDispute = () => {
    const obj = {
      order_id: id,
      type: disputeOrderRefundStatus,
      user_percent: Number(partialAmount),
      provider_percent: 100 - Number(partialAmount),
      supplementary_user_percent: Number(partialAmountSupplementary),
      supplementary_provider_percent: 100 - Number(partialAmountSupplementary),
      is_supplementary: 1,
    };

    OrderDisputeCancelMethod(
      obj,
      (res) => {
        Success(res.data.response.message);
        setDisputeConfirmationModal(false);
        const myTimeout = setTimeout(() => {
          push("/admin/payouts");
        }, 2000);
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const CompleteCancelOrderDispute = () => {
    const userPercent = disputeOrderRefundComplete === "user" ? 100 : 0;
    const providerPercent = disputeOrderRefundComplete === "provider" ? 100 : 0;

    const obj = {
      order_id: id,
      type: disputeOrderRefundStatus,
      user_percent: userPercent,
      provider_percent: providerPercent,
      is_supplementary: 1,
    };
    OrderDisputeCancelMethod(
      obj,
      (res) => {
        Success(res.data.response.message);
        setDisputeConfirmationModal(false);
        const myTimeout = setTimeout(() => {
          push("/admin/payouts");
        }, 2000);
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const ContinueOrderDispute = () => {
    const obj = {
      order_id: id,
    };
    OrderDisputeContinueMethod(
      obj,
      (res) => {
        Success(res.data.response.message);
        setDisputeConfirmationModal(false);
        const myTimeout = setTimeout(() => {
          push("/admin/payouts");
        }, 2000);
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const vat = "21";
  const orderAmountCalculation = (price) => {
    let taxAmount = (vat / 100) * Number(price);
    let totalAmount = Number(price - taxAmount);
    return totalAmount;
  };
  const vatAmountCalculation = (price) => {
    let taxAmount = (vat / 100) * Number(price);
    return taxAmount;
  };
  const totalAmountCalculation = (service, vat, supplementary) => {
    return Number(service) + Number(vat) + Number(supplementary);
  };
  return (
    <Spin spinning={fileUploadLoader} size="large" tip={adminLabels.loading}>
      <section>
        <div className="flex justify-between mt-20 mb-10">
          <div className="pl-5 flex self-center justify-center items-center w-1/2">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{adminLabels.back}</span>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex">
                <h3 className="ml-8	">
                  {adminLabels.orderId}: {props.match.params.id}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="container grid grid-cols-12 gap-3 px-2 sm:px-2 lg:px-0 lg:pl-20 ">
          <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4">
            <div className="bg-white container rounded-lg px-5 py-3 mh64 ">
              <div className="bg-white container rounded-lg col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-3">
                <ReactQuill
                  disabled={disputedOrder}
                  className={"richTextBox shadow-lg"}
                  value={message}
                  onChange={(content, delta, source, editor) => {
                    setMessage(content);
                    if (editor.getText().trim()?.length === 0) {
                      setMessage("");
                    }
                  }}
                />
              </div>

              <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
                <div className="md:flex">
                  <div className="md:shrink-0">
                    <img
                      className="h-48 w-full object-cover md:h-full md:w-48"
                      src={providerSignature?.avatar}
                      style={{
                        objectFit: "cover",
                        width: "150px",
                        height: "150px",
                      }}
                      alt="Man looking at item at a store"
                    />
                  </div>
                  <div className="px-4 py-3">
                    <h4 className="capitalize tracking-wide text-sm  font-semibold">
                      <label>{providerSignature?.name}</label>
                    </h4>
                    <p className=" text-slate-500">
                      {providerSignature?.position}
                    </p>
                    <p className="mb-0 mt-2 text-slate-500">
                      {providerSignature?.phone}
                    </p>
                    <p className="text-slate-500">{providerSignature?.email}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mt-3 justify-end">
              {/* <div className="w-1/2 m-2">
              <ButtonComponent
                disabled={disputedOrder}
                title={adminLabels.addBudget}
                onClick={() => {
                  if (disputedOrder === false) {
                    setFilterModal(true);
                  }
                }}
              />
            </div> */}
              <div className="file btn btn-lg btn-primary uploadDiv">
                {muiltipleDocument.length > 0
                  ? adminLabels.fileUploaded
                  : adminLabels.upload}
                <input
                  disabled={disputedOrder}
                  type="file"
                  name="file"
                  className="uploadInput cursor-pointer"
                  multiple
                  accept=".jpg, .jpeg, .png, .doc, .docx, .docs, .pdf, .xls, .csv, .xslx , .docx , .xlsx"
                  onChange={(e) => {
                    if (disputedOrder === false) {
                      let f = e.target.files;
                      let fileArray = [];
                      for (var i = 0; i < f.length; i++) {
                        fileArray.push(f[i]);
                      }
                      selectMuiltipleDocument(fileArray);
                    }
                  }}
                />
              </div>
              <div className="w-1/2 m-2">
                <ButtonComponent
                  title={adminLabels.send}
                  disabled={props.loader === true}
                  loader={props.loader === true ? true : false}
                  onClick={sendMessageToUser}
                />
              </div>
            </div>
          </div>

          <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-8 user-provider-chat-container">
            <Spin spinning={chatLoading} size="large" tip={adminLabels.loading}>
              <div className="px-20 " style={{ background: "#FAFAFA" }}>
                <div className="chat-white-color-background flex justify-end border-t-0 border-l-0 border-r-0 border py-2 rounded-t-2xl">
                  {["dispute", "disputed"]?.includes(selectedOrder?.status) ? (
                    <>
                      {selectedOrder?.status === "dispute" ? (
                        <>
                          {" "}
                          <Button
                            shape={"round"}
                            className={`icon-button mx-1 bg-danger`}
                            onClick={() => setDisputeConfirmationModal(true)}
                          >
                            <span className="text-white">
                              {adminLabels?.endDispute}
                            </span>
                          </Button>
                        </>
                      ) : null}

                      {selectedOrder?.status === "disputed" ? (
                        <>
                          <Button
                            shape={"round"}
                            className={`icon-button mx-1 bg-primary`}
                            onClick={() => setDisputeConfirmationModal(true)}
                          >
                            <span className="text-white">
                              {adminLabels?.result}
                            </span>
                          </Button>
                        </>
                      ) : null}
                    </>
                  ) : null}
                  <Button
                    shape={"round"}
                    onClick={() => changeIndex(0)}
                    icon={<MessageOutlined color="#5ab3f0" />}
                    className={
                      index === 0
                        ? `icon-button mx-1 bg-primary text-white`
                        : `icon-button mx-1`
                    }
                  >
                    {adminLabels.messages}
                  </Button>

                  <Button
                    shape={"round"}
                    onClick={() => changeIndex(1)}
                    icon={<FileTextOutlined color="#5ab3f0" />}
                    className={
                      index === 1
                        ? `icon-button mx-1 bg-primary text-white`
                        : `icon-button mx-1`
                    }
                  >
                    {adminLabels.documents}
                  </Button>

                  <Button
                    shape={"round"}
                    onClick={() => changeIndex(2)}
                    icon={<BellOutlined color="#5ab3f0" />}
                    // icon={<FileSearchOutlined color="#5ab3f0" />}
                    className={
                      index === 2
                        ? `icon-button mx-1 bg-primary text-white`
                        : `icon-button mx-1`
                    }
                  >
                    {adminLabels.notifications}
                  </Button>
                </div>
                {index === 0 ? (
                  userProviderChat.length === 0 ? (
                    <div
                      className="bg-white flex items-center justify-center flex-col"
                      style={{
                        height: "40rem",
                      }}
                    >
                      <Empty description="" />
                      <span className="capitalize font-bold text-gray-400">
                        {adminLabels.noChatAvailable}
                      </span>
                    </div>
                  ) : (
                    <div
                      className={`bg-white ${
                        chatSideBar ? "overflow-y-hidden" : "overflow-y-scroll"
                      }  chat-ui-container flex flex-col-reverse `}
                      style={{
                        height: "20rem",
                      }}
                    >
                      <div className="  grid grid-cols-1  px-4 py-4 space-y-12">
                        {userProviderChat?.map((item, index) =>
                          item?.is_admin_message == "0" ? (
                            <>
                              <div
                                className="place-self-start text-right "
                                key={index}
                              >
                                <p className="text-gray-500 text-xs text-left  py-1 mb-2">
                                  <span className="font-black text-xs  text-black capitalize">
                                    {`(${
                                      item.is_provider_message == "1"
                                        ? `${item?.service_provider?.name}`
                                        : `${item?.user?.first_name} ${item?.user?.last_name}`
                                    })  `}
                                  </span>

                                  <br />
                                  <span className="text-xs float-left  text-gray-500">
                                    {` ${
                                      item.is_provider_message == "1"
                                        ? adminLabels.serviceProvider
                                        : adminLabels.user
                                    }`}
                                  </span>
                                </p>
                                <div className="bg-white p-5 shadow-md rounded-2xl rounded-tl-none render-html-class">
                                  {item?.message[0]?.message === null ? null : (
                                    <InformationContainer
                                      info={item?.message[0]?.message || ""}
                                    />
                                  )}

                                  {item?.message[0]?.documents?.map(
                                    (docItem, index) => {
                                      return (
                                        <a
                                          key={index}
                                          href={`${docItem.url}`}
                                          target="_blank"
                                          download={docItem?.name}
                                        >
                                          <div className="flex space-x-1">
                                            <img
                                              src={document?.default}
                                              className="document_icon"
                                            />
                                            <p className="text-sm">
                                              {docItem?.name}
                                            </p>
                                          </div>
                                        </a>
                                      );
                                    }
                                  )}
                                </div>
                                <p className="text-xs float-right mr-2 mt-2 text-gray-500">
                                  {`${moment(item?.timestamp).format(
                                    "H:mm"
                                  )} || ${moment(item?.timestamp).format(
                                    "DD-MMM-YYYY"
                                  )}`}
                                </p>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="place-self-end">
                                <div className=" shadow-md  border-primary-message-sender  p-5 rounded-2xl rounded-tr-none render-html-class">
                                  {item?.message[0]?.message === null ? null : (
                                    <InformationContainer
                                      info={item?.message[0]?.message || ""}
                                    />
                                  )}
                                  {item?.message[0]?.documents?.map(
                                    (docItem, index) => {
                                      return (
                                        <a
                                          href={`${docItem.url}`}
                                          target="_blank"
                                          download={docItem?.name}
                                        >
                                          <div className="flex  space-x-1">
                                            <img
                                              src={document?.default}
                                              className="document_icon"
                                            />
                                            <p className="text-sm">
                                              {docItem?.name}
                                            </p>
                                          </div>
                                        </a>
                                      );
                                    }
                                  )}
                                </div>
                                <p className="text-xs float-left ml-2 mt-2 text-gray-500">
                                  {" "}
                                  {`${moment(item?.timestamp).format(
                                    "H:mm"
                                  )} || ${moment(item?.timestamp).format(
                                    "DD-MMM-YYYY"
                                  )}`}
                                </p>
                              </div>
                            </>
                          )
                        )}
                      </div>
                    </div>
                  )
                ) : null}
                {index === 1 ? (
                  <div
                    className={`bg-white  float-right overflow-y-hidden side-bar-scroll w-full`}
                    style={{
                      height: "40rem",
                    }}
                  >
                    <div className="  grid grid-cols-1   py-4 space-y-12">
                      <div className="my-5">
                        <h3 className="ml-8	">
                          {adminLabels.attachedDocuments}
                        </h3>
                      </div>
                      <div className="chatDocsDiv">
                        <Collapse>
                          <Collapse.Panel
                            header={adminLabels.myDocuments}
                            key="1"
                          >
                            {myChatDocuments.length === 0 ? (
                              <p>N/A</p>
                            ) : (
                              <div>
                                <div id="scrollableDiv">
                                  <List
                                    dataSource={myChatDocuments}
                                    renderItem={(item) =>
                                      item?.message[0]?.documents?.map(
                                        (docItem, index) => {
                                          return (
                                            <List.Item
                                              id="scrollableDiv"
                                              className="sideBarDocumentsList"
                                            >
                                              <a
                                                href={`${docItem.url}`}
                                                target="_blank"
                                                download={docItem?.name}
                                              >
                                                <div className="flex space-x-1">
                                                  <img
                                                    src={document?.default}
                                                    className="document_icon"
                                                  />
                                                  <p className="text-sm">
                                                    {docItem?.name}
                                                  </p>
                                                </div>
                                              </a>
                                            </List.Item>
                                          );
                                        }
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </Collapse.Panel>
                          <Collapse.Panel
                            header={adminLabels.userDocuments}
                            key="2"
                            // extra={extraDownloadAllUserFiles()}
                          >
                            {userChatDocuments.length === 0 ? (
                              <p>N/A</p>
                            ) : (
                              <div>
                                <div id="scrollableDiv" className="">
                                  <List
                                    dataSource={userChatDocuments}
                                    renderItem={(item) =>
                                      item?.message[0]?.documents?.map(
                                        (docItem, index) => {
                                          return (
                                            <List.Item
                                              id="scrollableDiv"
                                              className="sideBarDocumentsList"
                                            >
                                              <a
                                                href={`${docItem.url}`}
                                                target="_blank"
                                                download={docItem?.name}
                                              >
                                                <div className="flex space-x-1">
                                                  <img
                                                    src={document?.default}
                                                    className="document_icon"
                                                  />
                                                  <p className="text-sm">
                                                    {docItem?.name}
                                                  </p>
                                                </div>
                                              </a>
                                            </List.Item>
                                          );
                                        }
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </Collapse.Panel>
                          <Collapse.Panel
                            header={adminLabels.providerDocuments}
                            key="3"
                            // extra={extraDownloadAllUserFiles()}
                          >
                            {providerChatDocuments.length === 0 ? (
                              <p>N/A</p>
                            ) : (
                              <div>
                                <div id="scrollableDiv" className="">
                                  <List
                                    dataSource={providerChatDocuments}
                                    renderItem={(item) =>
                                      item?.message[0]?.documents?.map(
                                        (docItem, index) => {
                                          return (
                                            <List.Item
                                              id="scrollableDiv"
                                              className="sideBarDocumentsList"
                                            >
                                              <a
                                                href={`${docItem.url}`}
                                                target="_blank"
                                                download={docItem?.name}
                                              >
                                                <div className="flex space-x-1">
                                                  <img
                                                    src={document?.default}
                                                    className="document_icon"
                                                  />
                                                  <p className="text-sm">
                                                    {docItem?.name}
                                                  </p>
                                                </div>
                                              </a>
                                            </List.Item>
                                          );
                                        }
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </Collapse.Panel>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                ) : null}
                {index === 2 ? (
                  <div
                    className={`bg-white  overflow-y-hidden  notification-ui-container  `}
                    style={{
                      height: "40rem",
                    }}
                  >
                    <div className="  grid grid-cols-1  px-4 py-4 space-y-12">
                      <List
                        itemLayout="horizontal"
                        size="large"
                        header={
                          <div>
                            {" "}
                            <h3 className="ml-8	">
                              {adminLabels.orderNotification}
                            </h3>
                          </div>
                        }
                        dataSource={orderNotification}
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  size="large"
                                  src={
                                    item.type === "budget"
                                      ? additionalBudgetIcon.default
                                      : item.type === "further"
                                      ? orderPlacedIcon.default
                                      : item.type === "completed"
                                      ? orderPlacedIcon.default
                                      : item.type === "canceled"
                                      ? orderCanceledIcon.default
                                      : item.type === "placed"
                                      ? orderPlacedIcon.default
                                      : null
                                  }
                                />
                              }
                              title={
                                <h4>
                                  {
                                    adminLabels?.[
                                      functionToConvertStringToCamelCase(
                                        item?.notification
                                      )
                                    ]
                                  }
                                </h4>
                              }
                              description={
                                <div>{`${moment(item?.created_at).format(
                                  "H:mm"
                                )} | ${moment(item?.created_at).format(
                                  "DD-MMM-YYYY"
                                )}`}</div>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </Spin>
          </div>
        </div>

        <Modal
          visible={disputeConfirmationModal}
          onCancel={() => setDisputeConfirmationModal(false)}
          footer={false}
        >
          {" "}
          <div>
            {
              <h4 className="font-bold text-2xl text-center text-gray-600	">
                {selectedOrder?.status !== "dispute" ? adminLabels.details : adminLabels.endDispute}
              </h4>
            }
          </div>
          <div className="w-full col-span-12 rounded-full lg:col-span-3 sm:col-span-6 md:col-span-6 text-center py-2 px-8"></div>
          <div className={`flex justify-around items-center ${selectedOrder?.status === "dispute" ? "" : "pointer-events-none"}`}>
            <div className="flex flex-col space-y-2 w-96">
              <div className="pt-2">
                <div className="pb-2 px-8">
                  <p>
                    {
                      adminLabels?.ifYouwantToContinueClickOnContinueOrderOrIfYouWantToCancelClickOnCancelOrder
                    }
                  </p>
                </div>
                <div className="flex justify-start space-x-12">
                  <div className="flex items-center justify-start w-1/2">
                    {disputeOrderStatus === "cancel" ? (
                      <img
                        src={checkboxActive.default}
                        className="cursor-pointer"
                        onClick={() => setDisputeOrderStatus("")}
                      />
                    ) : (
                      <img
                        src={checkbox.default}
                        className="cursor-pointer"
                        onClick={() => setDisputeOrderStatus("cancel")}
                      />
                    )}
                    <span className=" text-dark   font-bold ml-2">
                      {adminLabels?.cancelOrder}
                    </span>
                  </div>
                  <div className="flex items-center justify-start w-1/2">
                    {disputeOrderStatus === "continue" ? (
                      <img
                        src={checkboxActive.default}
                        className="cursor-pointer"
                        onClick={() => setDisputeOrderStatus("")}
                      />
                    ) : (
                      <img
                        src={checkbox.default}
                        className="cursor-pointer"
                        onClick={() => setDisputeOrderStatus("continue")}
                      />
                    )}
                    <span className="mr-2 text-dark  font-bold ml-2">
                      {adminLabels?.continueOrder}
                    </span>
                  </div>
                </div>
              </div>

              {disputeOrderStatus === "cancel" && (
                <div className="pt-2">
                  <div className="pb-2">
                    {adminLabels?.doYouLikeToGoForCompleteOrPartial}
                  </div>
                  <div className="flex justify-start space-x-12">
                    <div className="flex items-center justify-start w-1/2">
                      {disputeOrderRefundStatus === "complete" ? (
                        <img
                          src={checkboxActive.default}
                          className="cursor-pointer"
                          onClick={() => setDisputeOrderRefundStatus("")}
                        />
                      ) : (
                        <img
                          src={checkbox.default}
                          className="cursor-pointer"
                          onClick={() => {
                            setIs_supplementary(true);
                            setDisputeOrderRefundStatus("complete");
                          }}
                        />
                      )}
                      <span className=" text-dark   font-bold ml-2">
                        {adminLabels?.complete}
                      </span>
                    </div>
                    <div className="flex items-center justify-start w-1/2">
                      {disputeOrderRefundStatus === "partial" ? (
                        <img
                          src={checkboxActive.default}
                          className="cursor-pointer"
                          onClick={() => setDisputeOrderRefundStatus("")}
                        />
                      ) : (
                        <img
                          src={checkbox.default}
                          className="cursor-pointer"
                          onClick={() => setDisputeOrderRefundStatus("partial")}
                        />
                      )}
                      <span className="mr-2 text-dark  font-bold ml-2">
                        {adminLabels?.partial}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {disputeOrderRefundStatus === "complete" && (
                <div className="pt-2">
                  <div className="pb-2">
                    {adminLabels?.toWhomeYouLikeToMakeACompleteRefund}
                  </div>
                  <div className="flex justify-start space-x-12">
                    <div className="flex items-center justify-start w-1/2">
                      {disputeOrderRefundComplete === "provider" ? (
                        <img
                          src={checkboxActive.default}
                          className="cursor-pointer"
                          onClick={() => setDisputeOrderRefundComplete("")}
                        />
                      ) : (
                        <img
                          src={checkbox.default}
                          className="cursor-pointer"
                          onClick={() =>
                            setDisputeOrderRefundComplete("provider")
                          }
                        />
                      )}
                      <span className=" text-dark   font-bold ml-2">
                        {adminLabels?.serviceProvider}
                      </span>
                    </div>
                    <div className="flex items-center justify-start w-1/2">
                      {disputeOrderRefundComplete === "user" ? (
                        <img
                          src={checkboxActive.default}
                          className="cursor-pointer"
                          onClick={() => setDisputeOrderRefundComplete("")}
                        />
                      ) : (
                        <img
                          src={checkbox.default}
                          className="cursor-pointer"
                          onClick={() => setDisputeOrderRefundComplete("user")}
                        />
                      )}
                      <span className="mr-2 text-dark  font-bold ml-2">
                        {adminLabels?.user}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {disputeOrderRefundStatus === "partial" && (
                <>
                  <div className="pt-2">
                    <div className="pb-2">
                      {adminLabels?.enterPercentYouwantToRefundUser}
                    </div>
                    <div className="flex justify-start space-x-12 items-end">
                      <div>
                        <Input
                          type={"number"}
                          min={1}
                          max={99}
                          value={partialAmount}
                          onChange={(e) => {
                            let value = e?.target.value;
                            if (Number(value) <= 99) {
                              setPartialAmount(value);
                            }
                          }}
                        />
                      </div>
                      <div>
                        {adminLabels?.remaining}{" "}
                        <b>{" " + 100 - partialAmount + " "}</b> %{" "}
                        {adminLabels?.forServiceProvider}
                      </div>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="pb-2">
                      {
                        adminLabels?.enterPercentOfSupplementaryYouWantToRefundUser
                      }
                    </div>
                    <div className="flex justify-start space-x-12 items-end">
                      <div>
                        <Input
                          type={"number"}
                          min={1}
                          max={99}
                          value={partialAmountSupplementary}
                          onChange={(e) => {
                            let value = e?.target.value;
                            if (Number(value) <= 99) {
                              setPartialAmountSupplementary(value);
                            }
                          }}
                        />
                      </div>
                      <div>
                        {adminLabels?.remaining}{" "}
                        <b>{" " + 100 - partialAmountSupplementary + " "}</b> %{" "}
                        {adminLabels?.forServiceProvider}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {disputeOrderRefundStatus === "complete" &&
                disputeOrderRefundComplete === "user" && (
                  <div>
                    <p>
                      {adminLabels?.orderAmount} {" = "}
                      <b>
                        €
                        {europeanCurrencyFormatter(
                          selectedOrder?.after_discount_amount
                        )}
                      </b>
                    </p>
                    {/* <p>
                   <div className="flex items-center">
                    <div>
                      <img
                        src={is_supplementary ? checkboxActive.default : checkbox.default}
                        className="cursor-pointer"
                        onClick={() => setIs_supplementary(!is_supplementary)}
                      />
                    </div>
                    <div>
                      <span className="mr-2 text-dark  font-bold ml-2">
                        {adminLabels?.supplementary}
                      </span>
                    </div>
                   </div>
                  </p> */}
                    <p>
                      {adminLabels?.supplementaryAmount} {" = "}
                      <b>
                        €{" "}
                        {europeanCurrencyFormatter(
                          !is_supplementary
                            ? 0
                            : selectedOrder?.supplementary_fees
                        )}
                      </b>
                    </p>
                    <b>{adminLabels?.totalAmount}</b>
                    <p>
                      {adminLabels?.orderAmount} {" + "}
                      {adminLabels?.supplementaryAmount} {" = "}
                      <b>
                        €
                        {europeanCurrencyFormatter(
                          Number(selectedOrder?.after_discount_amount) +
                            Number(
                              !is_supplementary
                                ? 0
                                : selectedOrder?.supplementary_fees
                            )
                        )}{" "}
                      </b>
                    </p>
                  </div>
                )}
              {disputeOrderRefundStatus === "complete" &&
                disputeOrderRefundComplete === "provider" && (
                  <div>
                    <p>
                      {adminLabels?.orderAmount} ={" "}
                      <b>
                        €{" "}
                        {europeanCurrencyFormatter(
                          selectedOrder?.after_discount_amount
                        )}{" "}
                      </b>
                    </p>

                    <p>
                      {adminLabels?.supplementaryAmount} {" = "}
                      <b>
                        €{" "}
                        {europeanCurrencyFormatter(
                          !is_supplementary
                            ? 0
                            : selectedOrder?.supplementary_fees
                        )}
                      </b>
                    </p>
                    <p>
                      {adminLabels?.docuDocDeduction} 30% {" = "}
                      <b>
                        €
                        {europeanCurrencyFormatter(
                          Number(selectedOrder?.after_discount_amount) * 0.3
                        )}{" "}
                      </b>
                    </p>
                    <p>
                      {adminLabels?.serviceProvider} 70% {" = "}
                      <b>
                        €
                        {europeanCurrencyFormatter(
                          Number(selectedOrder?.after_discount_amount) * 0.7
                        )}{" "}
                      </b>
                    </p>
                    <b>{adminLabels?.totalAmount}</b>
                    <p>
                      {adminLabels?.docuDocDeduction} 70% +{" "}
                      {adminLabels?.supplementaryAmount} {" = "}
                      <b>
                        €
                        {europeanCurrencyFormatter(
                          Number(selectedOrder?.after_discount_amount) * 0.7 +
                            Number(
                              !is_supplementary
                                ? 0
                                : selectedOrder?.supplementary_fees
                            )
                        )}{" "}
                      </b>
                    </p>
                  </div>
                )}
              {disputeOrderRefundStatus === "partial" && (
                <div>
                  <b>{adminLabels.totalAmountPaid}</b>
                  <p className="mb-0">
                    {adminLabels.service} {" = "}
                    <b>
                      €{" "}
                      {europeanCurrencyFormatter(
                        orderAmountCalculation(
                          selectedOrder?.after_discount_amount
                        )
                      )}{" "}
                    </b>
                  </p>
                  <p className="mb-0">
                    {adminLabels.vat} {" = "}
                    <b>
                      €{" "}
                      {europeanCurrencyFormatter(
                        vatAmountCalculation(
                          selectedOrder?.after_discount_amount
                        )
                      )}{" "}
                    </b>
                  </p>
                  <p className="mb-0">
                    {adminLabels.supplementaryAmount} {" = "}
                    <b>
                      €{" "}
                      {europeanCurrencyFormatter(
                        !is_supplementary
                          ? 0
                          : selectedOrder?.supplementary_fees
                      )}
                    </b>
                  </p>
                  <p className="">
                    {adminLabels.serviceVATSupplements} {" = "}
                    <b>
                      €{" "}
                      {europeanCurrencyFormatter(
                        totalAmountCalculation(
                          Number(
                            orderAmountCalculation(
                              selectedOrder?.after_discount_amount
                            )
                          ),
                          Number(
                            vatAmountCalculation(
                              selectedOrder?.after_discount_amount
                            )
                          ),
                          Number(
                            !is_supplementary
                              ? 0
                              : selectedOrder?.supplementary_fees
                          )
                        )
                      )}
                    </b>
                  </p>
                  <p>
                    <b className="text-lg">{adminLabels.user}</b>
                  </p>
                  <p>
                    {adminLabels?.partialAmountOf} {partialAmount} % {" = "}
                    <b>
                      €
                      {europeanCurrencyFormatter(
                        Number(selectedOrder?.after_discount_amount) *
                          (Number(partialAmount) / 100)
                      )}{" "}
                    </b>
                  </p>
                  <p>
                    {adminLabels?.partialAmountOf} {adminLabels?.supplementary}{" "}
                    {partialAmountSupplementary} % {" = "}
                    <b>
                      €
                      {europeanCurrencyFormatter(
                        Number(
                          !is_supplementary
                            ? 0
                            : selectedOrder?.supplementary_fees
                        ) *
                          (Number(partialAmountSupplementary) / 100)
                      )}{" "}
                    </b>
                  </p>
                  <p>
                    <b className="text-lg">{adminLabels?.serviceProvider}</b>
                  </p>
                  <p>
                    {adminLabels?.partialAmountOf} {100 - partialAmount} %{" "}
                    {" = "}
                    <b>
                      €
                      {europeanCurrencyFormatter(
                        Number(selectedOrder?.after_discount_amount) *
                          (Number(100 - partialAmount) / 100)
                      )}{" "}
                    </b>
                  </p>
                  <p>
                    {adminLabels?.partialAmountOf} {adminLabels?.supplementary}{" "}
                    {100 - partialAmountSupplementary} % {" = "}
                    <b>
                      €
                      {europeanCurrencyFormatter(
                        Number(
                          !is_supplementary
                            ? 0
                            : selectedOrder?.supplementary_fees
                        ) *
                          (Number(100 - Number(partialAmountSupplementary)) /
                            100)
                      )}{" "}
                    </b>
                  </p>
                  <p>
                    {adminLabels?.docuDocDeduction} 30%{" "}
                    {adminLabels?.ofPartialAmount} ={" "}
                    <b>
                      €
                      {europeanCurrencyFormatter(
                        Number(
                          Number(selectedOrder?.after_discount_amount) *
                            (Number(100 - partialAmount) / 100)
                        ) * 0.3
                      )}{" "}
                    </b>
                  </p>
                  <p>
                    {adminLabels?.serviceProvider} 70%{" "}
                    {adminLabels?.ofPartialAmount} ={" "}
                    <b>
                      €
                      {europeanCurrencyFormatter(
                        Number(
                          Number(selectedOrder?.after_discount_amount) *
                            (Number(100 - partialAmount) / 100)
                        ) * 0.7
                      )}{" "}
                    </b>
                  </p>
                  <b>{adminLabels?.totalAmount}</b>
                  <p>
                    {adminLabels?.serviceProvider} 70% +{" "}
                    {adminLabels?.supplementaryAmount} {" = "}
                    <b>
                      €
                      {europeanCurrencyFormatter(
                        Number(
                          Number(selectedOrder?.after_discount_amount) *
                            (Number(100 - partialAmount) / 100)
                        ) *
                          0.7 +
                          Number(
                            !is_supplementary
                              ? 0
                              : selectedOrder?.supplementary_fees
                          ) *
                            (Number(100 - partialAmountSupplementary) / 100)
                      )}{" "}
                    </b>
                  </p>
                </div>
              )}

              {selectedOrder?.status === "dispute" ? <>
                <div className="flex justify-center py-2">
                  {disputeOrderStatus === "continue" && (
                    <ButtonComponent
                      title={adminLabels?.confirm}
                      onClick={ContinueOrderDispute}
                    />
                  )}
                  {["user", "provider"].includes(disputeOrderRefundComplete) && (
                    <ButtonComponent
                      title={adminLabels?.confirm}
                      onClick={CompleteCancelOrderDispute}
                    />
                  )}
                  {disputeOrderRefundStatus === "partial" && (
                    <ButtonComponent
                      title={adminLabels?.confirm}
                      onClick={PartialCancelOrderDispute}
                    />
                  )}
                </div>
              </> : null}
            </div>
          </div>
        </Modal>
      </section>
    </Spin>
  );
};

const mapStateToProps = (state) => {
  return {
    providerDetail: state.providerLoginDetail.loggedUserDetailOfProvider,
    loader: state.loaderToggle.loaderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
    getWorkers: (props) => {
      dispatch(FetchWorkersAction(props));
    },
    removeWorkerList: () => {
      dispatch(DeleteWorkerAtLogoutTime());
    },
    setProviderLogout: () => {
      dispatch(ProviderLogoutAction());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProviderOrderMessages);
