import React from "react";

export default function OrderStepper({
  steps,
  fetchFormData,
  fetchOrderStatus,
  disputedOrder,
}) {
  return (
    <div className="lg:w-full py-6 px-2 sm:px-2 lg:px-40  overflow-x-auto  ">
      <div className="h-32 px-52">
        {/* <span className="text-gray-400 mr-2">Section</span> */}
        <div className="w-full">
          <div className="relative mb-2">
            <div
              className="w-full absolute flex align-center items-center align-middle content-center"
              style={{
                top: "50%",
              }}
            >
              <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                <div
                  className="w-0 bg-green-300 py-1 rounded"
                  style={{ width: "33%" }}
                ></div>
              </div>
            </div>
            <div className="flex justify-between">
              {steps.map((item, index) => {
                return (
                  <div key={index} className="relative">
                    <div
                      // className={`absolute ${
                      //   steps.length === index + 1 ? "w-0" : "w-[30rem] "
                      // } z-40 `}
                      className="absolute z-40"
                      style={{
                        top: 20,
                        width: `${steps.length === index + 1 ? "0" : "30rem"}`,
                      }}
                    >
                      <div
                        className="flex align-center items-center align-middle content-center"
                        style={{
                          top: "50%",
                        }}
                      >
                        <div
                          // className={`z-10 w-full
                          // ${item?.status !== "" ? "bg-primary" : "bg-gray-200"}
                          // rounded items-center align-middle align-center flex-1`}
                          className={`z-10 w-full 
                          ${
                            item?.order_status === null
                              ? "bg-gray-200 "
                              : "bg-primary"
                          } 
                          rounded items-center align-middle align-center flex-1`}
                        >
                          <div
                            className="z-10 w-0 bg-green-300 py-1 rounded"
                            style={{ width: "100%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`w-10 h-10 z-50  ${
                        item?.status && item?.status !== ""
                          ? "bg-primary"
                          : "bg-white"
                      } border-2 border-gray-200 rounded-full text-lg text-white flex items-center cursor-pointer`}
                    >
                      <span className="text-center text-white w-full z-50 relative">
                        <span
                          onClick={
                            item.name == "+"
                              ? () => {}
                              : item.order_status === null
                              ? () => fetchFormData(item.id)
                              : () => fetchOrderStatus(item.order_status)
                          }
                          className={`text-center text-white ${
                            item?.order_status && item?.order_status !== null
                              ? "bg-primary"
                              : "bg-gray-200"
                          } rounded-full h-12 w-12 flex items-center justify-center`}
                        >
                          {item?.name}
                        </span>
                        <div className="absolute w-40" style={{ left: -50 }}>
                          <label
                            className={`${
                              item?.name === "+"
                                ? "text-gray-400 "
                                : "text-black text-sm text-justify"
                            }`}
                          >
                            {item?.name === "+"
                              ? "Add New"
                              : item?.status && item?.status}
                          </label>
                        </div>
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
