import { Col } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { adminLabels } from "../../config/label/adminLabels";
import "./AppHeader.css";

function Data({ prize, name, route, total = adminLabels.total, col = 8 }) {
  return (
    <Col className="gutter-row my-3 " xs={24} span={col} md={12} sm={24} lg={6}>
      <Link to={route} className="text-black">
        <div className="w-full p-3 bg-white rounded-lg shadow-md">
          <div className="flex justify-between  font-block mb-2">
            <span className="text-blue-50 data-prize-style text-xl font-bold font-color-blue">
              <strong>
              {name === adminLabels.revenue ? "€" : ""} {Math.floor(prize)}
              </strong>
            </span>
          </div>
          <div className="mt-5">
            <span className="text-gray-400 font-bold">{total}</span> <br />
            <span className="">{name}</span>
          </div>
        </div>
      </Link>
    </Col>
  );
}

export default Data;
