import React, { useEffect, useState } from "react";
import { Button, Dropdown, Menu, Space, Tag } from "antd";
import { TableComponent } from "../../components/index";
import { Link, useHistory } from "react-router-dom";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { AiOutlinePlus } from "react-icons/ai";
import { Images } from "../../constant/Images";
import { FiSearch } from "react-icons/fi";
import { connect } from "react-redux";
import {
  RiDeleteBinFill,
  RiPencilLine,
  RiArrowDropRightLine,
} from "react-icons/ri";
import {
  DeleteBeneficiariesAtLogoutTime,
  FetchBeneficiariesAction,
} from "../../Redux/actions/fetchBeneficiariesAction";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";
import { DeleteProvidersAtLogoutTime } from "../../Redux/actions/fetchProvidersAction";
import { DeleteServiceAreasAtLogoutTime } from "../../Redux/actions/fetchServiceAreaAction";
import { DeleteBranchesAtLogoutTime } from "../../Redux/actions/fetchBranchesAction";
import { DeleteServicesAtLogoutTime } from "../../Redux/actions/fetchServicesAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";

const Beneficiaries = (props) => {
  const { push, goBack } = useHistory();
  const [beneficiaries, setBeneficiaries] = useState(props.showBeneficiaries);

  useEffect(() => {
    props.getBeneificiariesList(props);
    let mapedArray = beneficiaries.map((Ben, index) => {
      return {
        ...Ben,
        no: index + 1,
        beneficiaries: {
          name: Ben.name,
          url: Ben.avatar,
        },
      };
    });
    setBeneficiaries(mapedArray);
  }, []);

  const handleMenu = (id) => {
    push(`/admin/edit-beneficiaries/${id}`);
  };

  const menu = (text) => {
    return (
      <Menu className="menu-class menu-main-class" style={{ borderRadius: 20 }}>
        <Menu.Item key={text.id + Math.random()}>
          <div
            className="flex justify-between bg-white py-2 w-full  items-center"
            style={{ width: 160 }}
          >
            <div>
              <span>Delete </span>
            </div>
            <div>
              <RiDeleteBinFill color="red" size={20} />
            </div>
          </div>
        </Menu.Item>
        <Menu.Item
          key={text.id + Math.random()}
          onClick={() => handleMenu(text.beneficiary_id)}
        >
          <div
            className="flex justify-between bg-white py-2 w-full  items-center"
            style={{ width: 160 }}
          >
            <div>
              <span>Edit</span>
            </div>
            <div>
              <RiPencilLine color="#46bcff" size={20} />
            </div>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: "No",
      align: "center",
      dataIndex: "no",
      key: "no",
      render: (text) => <a>{text}</a>,
      width: 40,
    },
    {
      align: "left",
      title: "Beneficiaries",
      dataIndex: "beneficiaries",
      key: "beneficiaries",
      width: 300,
      render: (e) => (
        <div className="flex items-center ml-2">
          <img src={e?.url} alt="" width="50" height="50" />
          <span className="ml-4">{e?.name}</span>
        </div>
      ),
    },
    {
      align: "center",
      title: "Action",
      key: "action",
      width: 60,
      render: (text, index) => (
        <div className="text-center">
          <Dropdown
            overlay={() => menu(text)}
            className={"flex"}
            trigger={["click"]}
          >
            <div
              type="disable"
              shape={"round"}
              className="background-color-gray whiteTxt rounded-full py-3 justify-center cursor-pointer	"
            >
              <span>View Details</span>
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <section className="">
      <div className="flex justify-between mt-20 ">
        <div className="pl-5 flex self-center justify-center items-center sub-h3 ">
          <div
            onClick={() => goBack()}
            className="flex text-gray-400 items-center cursor-pointer"
          >
            <RiArrowDropLeftLine />
            <span>Back</span>
          </div>
          <h3 className="ml-10	">Beneficiaries</h3>
        </div>

        <div className=" mr-2 sm:mr-2 lg:mr-28 flex">
          <div className="mr-2">
            <Button shape={"circle"} className="icon-button p0 ">
              <FiSearch size={15} />
            </Button>
          </div>
          <Link to="/admin/add-beneficiaries">
            <Button
              shape={"round"}
              className="icon-button"
              icon={<AiOutlinePlus color="#5ab3f0" />}
            >
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div className="container mx-auto lg:px-20 mt-5">
        <TableComponent columns={columns} data={beneficiaries} />
      </div>
    </section>
  );
};
const mapStateToProps = (state) => {
  return {
    showBeneficiaries: state.beneficiariesList.beneficiaries,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBeneificiariesList: (props) => {
      dispatch(FetchBeneficiariesAction(props));
    },
    setLogoutUser: (data) => {
      dispatch(LogoutUserAction(data));
    },
    removeBeneficiaries: () => {
      dispatch(DeleteBeneficiariesAtLogoutTime());
    },
    removeProviders: () => {
      dispatch(DeleteProvidersAtLogoutTime());
    },
    removeServiceAreas: () => {
      dispatch(DeleteServiceAreasAtLogoutTime());
    },
    removeBranches: () => {
      dispatch(DeleteBranchesAtLogoutTime());
    },
    removeServices: () => {
      dispatch(DeleteServicesAtLogoutTime());
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Beneficiaries);
