import { Errors } from "../../components/antdToast";
import { GetHelpMessagesMethod } from "../../config/api/index";

const  fetchHelpAction = (props) => {
    return (dispatch) => {
    GetHelpMessagesMethod(
      (success) => {
        if (success?.status === 200) {
          dispatch({
            type: "GET_HELP",
            payload: success?.data?.response?.detail,
          });
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          Errors(error?.response?.data?.message);
        //   Remove_Data_Form_Reducers(props);
          props.history.push("/admin");
        } else {
          Errors("Network Error");
        }
      }
    );
  };
}

// const DeleteProvidersAtLogoutTime = () => {
//   return (dispatch) => {
//     dispatch({
//       type: "REMOVE_PROVIDERS",
//       payload: [],
//     });
//   };
// };

export { fetchHelpAction };
