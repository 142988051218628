import React from "react";

export default function DFormToolKit({
  onClick,
  toolList,
  arrayLength,
  stateOfToolkit,
}) {

  return (
    <div
      className={`overflow-x-auto w-full sm:w-full lg:w-max rounded-full sm:rounded-full lg:rounded-full bg-white sm:bg-white mb-4 sm:mb-4 lg:mb-0 shadow-md`}
    >
      <div
        className={`bg-white flex border-radius10 divide-x divide-gray-300`}
      >
        {toolList.length
          ? toolList?.map((t, i) => {
              return (
                <div
                  key={i}
                  className="flex justify-around flex-col item-center text-center py-1/2 cursor-pointer px-5 "
                  onClick={() => onClick(t.id)}
                >
                  <div className="flex  justify-center text-color-primary">
                    <img src={t.img.default} />
                  </div>
                  <div>
                    <label
                      style={{ fontSize: "8px" }}
                      className="font-black text-gray-400  capitalize"
                    >
                      {t.name}
                    </label>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}
