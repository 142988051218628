import { Errors } from "../../components/antdToast";
import { GetWorkwithUsMethod } from "../../config/api";

const fetchWorkWithUsAction = (props) => {
  return (dispatch) => {
    GetWorkwithUsMethod(
      (success) => {
        if (success?.status === 200) {
          dispatch({
            type: "GET_WORK_WITH_US",
            payload: success?.data?.response.detail,
          });
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          Errors(error?.response?.data?.message);
          props.history.push("/admin");
        } else {
          Errors("Network Error");
        }
      }
    );
  };
};
export { fetchWorkWithUsAction };