import { Tooltip, Button, Divider } from "antd";
import { AiOutlineInfoCircle } from "react-icons/ai";

const CustomTooltip = ({ description }) => {
  return (
    <Tooltip title={description} color={"#46bcff"}>
      <Button style={{background:'transparent',border:'none'}} icon={<AiOutlineInfoCircle size={25} />}></Button>
    </Tooltip>
  );
};

export default CustomTooltip;
