import { Button, Input, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { TableComponent } from "../../components";
import { adminLabels } from "../../config/label/adminLabels";

import { SearchOutlined } from "@ant-design/icons";
import { Errors } from "../../components/antdToast";

import { onValue, ref } from "firebase/database";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { firebaseDatabase } from "../../config/Firebase";
import { GetAllAdminMessagesMethod } from "../../config/api";
const AdminMessages = () => {
  const { goBack, push } = useHistory();
  const [adminMessages, setAdminMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderCount, setOrderCount] = useState([]);
  const currentSelectedLangue = localStorage.getItem("primaryLanguage");

  const getOrderUnreadCountData = (orderID, setOrderCount) => {
    const getData = ref(firebaseDatabase, `orders/${orderID}/adminUnread`);
    let count = 0;
    onValue(getData, (snapshot) => {
      const data = snapshot?.val();
      if (data !== null) {
        setOrderCount((prev) => [...prev, data]);
      } else {
        setOrderCount((prev) => [...prev, 0]);
      }
    });
  };
  const getOrderUnreadCountDataDot = (orderID) => {
    const getData = ref(firebaseDatabase, `orders/${orderID}/adminUnread`);
    let count = 0;
    onValue(getData, (snapshot) => {
      const data = snapshot?.val();
      count = data;
    });
    return count;
  };
  const allMessagesO = () => {
    GetAllAdminMessagesMethod(
      (success) => {
        if (success?.status === 200) {
          let mappedOut = success?.data?.response?.detail;
          setAdminMessages(
            mappedOut?.map((item, index) => {
              const servicePackType = item?.order?.service?.service_pack_type;
              return {
                ...item,
                no: index + 1,
                unreadCount: getOrderUnreadCountData(
                  item?.order_id,
                  setOrderCount
                ),
                name:
                  item?.order?.data !== null &&
                  item?.order?.data?.[0]?.personal?.firstName +
                    " " +
                    item?.order?.data?.[0]?.personal?.lastName,
                phone:
                  item?.order?.data !== null &&
                  item?.order?.data?.[0]?.personal.countryCode,
                product: {
                  ...item?.order?.service,
                  name: item?.order?.service?.name,
                  avatar: item?.order?.service?.avatar,
                  info: item?.order?.service?.info,
                },
                date: moment(item?.created_at)?.format("DD-MMM-YYYY HH:mm"),
                orderId: item?.order_id,
                time:
                  servicePackType === "monthly"
                    ? adminLabels[servicePackType]
                    : item?.order?.service?.time === null &&
                      item?.order?.service?.time_type === null
                    ? "0"
                    : `${item?.order?.service?.time}  ${
                        Number(item?.order?.service?.time) > 1
                          ? adminLabels[
                              item?.order?.service?.time_type === "Hours"
                                ? item?.order?.service?.time_type
                                : item?.order?.service?.time_type?.toLowerCase() +
                                  "s"
                            ]
                          : adminLabels[
                              item?.order?.service?.time_type === "Hours"
                                ? "hour"
                                : item?.order?.service?.time_type?.toLowerCase()
                            ]
                      }`,
              };
            })
          );

          // setAdminMessages(
          //   mappedOut?.map((item, index) => {
          //     return {
          //       ...item,
          //       no: index + 1,
          //       name:
          //         item?.data !== null &&
          //         item?.data[0].personal.firstName +
          //           " " +
          //           item?.data[0].personal.lastName,
          //       phone: item?.data !== null && item?.data[0].personal.countryCode,
          //       product: {
          //         ...item?.service,
          //         name: item?.service.name,
          //         avatar: item?.service.avatar,
          //         info: item?.service.info,
          //       },
          //       date: moment(item?.created_at).format("DD-MMM-YYYY HH:mm"),
          //       orderId: item?.order_id,
          //       time:
          //         item?.service.time === null && item?.service.time_type === null
          //           ? "0"
          //           : `${item?.service.time}  ${
          //               Number(item?.service.time) > 1
          //                 ? adminLabels?.[
          //                     item?.service.time_type === "Hours"
          //                       ? item?.service.time_type
          //                       : item?.service.time_type?.toLowerCase() + "s"
          //                   ]
          //                 : item?.service.time_type?.toLowerCase()
          //             }`,
          //     };
          //   })
          // );
          setLoading(false);
        }
      },
      (fail) => {
        if (fail !== null) {
          if (fail?.response?.status === 409) {
            Errors(fail?.response?.data?.error?.message);
          } else if (fail?.response?.status === 401) {
            Errors(fail?.response?.data?.error?.message);
            push("/admin");
          } else {
            Errors("Network Error");
          }
        }
      }
    );
  };

  const columns = [
    {
      align: "center",
      title: adminLabels.no,
      dataIndex: "no",
      key: "no",
      width: 20,
      render: (text) => <a>{text}</a>,
    },
    {
      align: "center",
      title: adminLabels.product,
      dataIndex: "product",
      // defaultSortOrder: 'descend',
      // sorter: (a, b) => a.name - b.name,
      key: "product",
      width: 80,
      render: (text, index) => (
        <div className={"flex"}>
          <img
            src={text?.avatar}
            style={{ width: 50, height: 45 }}
            className="rounded-lg"
            alt="detail image"
          />
          <div className="pl-2 text-left">
            <label className="proximaHeadings">
              <b>
                {
                  text?.[
                    currentSelectedLangue === "english" ? "name" : "name_sp"
                  ]
                }
              </b>
            </label>{" "}
            {/* <br />
                {renderHTML(text.info)} */}
          </div>
        </div>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]
          ?.toLowerCase()
          .includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a?.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]?.localeCompare(
          b?.product?.[currentSelectedLangue === "english" ? "name" : "name_sp"]
        ),
    },
    {
      align: "center",
      title: adminLabels.name,
      dataIndex: "name",
      key: "name",
      width: 40,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.name?.toLowerCase()?.includes(value?.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: adminLabels.orderId,
      dataIndex: "orderId",
      key: "orderId",
      width: 30,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.orderId.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.orderId?.localeCompare(b?.orderId),
    },
    {
      align: "center",
      title: adminLabels.date,
      key: "date",
      dataIndex: "date",
      width: 40,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: adminLabels.timeOfDelivery,
      key: "time",
      dataIndex: "time",
      width: 40,
    },
    {
      align: "center",
      title: adminLabels.action,
      key: "action",
      width: 35,

      render: (text) => (
        <div className="gap-1 flex items-center justify-center">
          <div className="mb-1">
            {orderCount[text?.no - 1] > 0 ? (
              <span className="font-bold text-red-500 text-4xl mr-1">.</span>
            ) : (
              <span className="font-bold font-color-blue text-4xl mr-1 opacity-0">
                .
              </span>
            )}
          </div>
          <div>
            <Link
              to={`${"/admin/user-provider-order-messages/"}${text.orderId}`}
            >
              <Button
                type="disable"
                shape={"round"}
                className="background-color-gray whiteTxt"
              >
                {adminLabels.viewMessage}
              </Button>
            </Link>
          </div>
        </div>
      ),
    },
  ];
  useEffect(() => {
    allMessagesO();
    document.body.style.overflow = "auto";
    return function cleanup() {
      document.body.style.overflow = "auto";
    };
  }, []);
  return (
    <section className="">
      <Spin tip={adminLabels.loading} spinning={loading}>
        <div className="flex justify-between mt-5 mr-2 sm:mr-2 lg:mr-28">
          <div className="pl-5 flex self-center justify-center items-center">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{adminLabels.back}</span>
            </div>
            <h3 className="ml-10	">{adminLabels.messages}</h3>
          </div>
        </div>
        <div className="container mx-auto lg:px-20 mt-5">
          <TableComponent
            columns={columns}
            data={adminMessages}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
            scrolly="500px"
            scroll="100%"
          />
        </div>
      </Spin>
    </section>
  );
};

export default AdminMessages;
