import React from "react";
import { Spin, message } from "antd";

export const Success = (detail) => {
  message.success(detail, 3);
};

export const Errors = (error) => {
  message.error(error, 3);
};
