// GET_PROVIDER_MESSAGES
const initialState = {
  orders: [],
  loading: true,
};

const GetServiceProviderOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PROVIDER_ORDERS": {
      return {
        ...state,
        orders: action.payload,
        loading: action.loading,
      };
    }
    default:
      return state;
  }
};
export default GetServiceProviderOrdersReducer;
