import { Button, Dropdown, Menu, Space, Tag } from "antd";
import React, { useState, useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import TableComponent from "../../components/table";
import {
  RiDeleteBinFill,
  RiPencilLine,
  RiArrowDropRightLine,
} from "react-icons/ri";
import { Images } from "../../constant/Images";
import { Link, useHistory } from "react-router-dom";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { FiSearch } from "react-icons/fi";
import { connect } from "react-redux";
import {
  DeleteBeneficiariesAtLogoutTime,
  FetchBeneficiariesAction,
} from "../../Redux/actions/fetchBeneficiariesAction";
import {
  DeleteServiceAreasAtLogoutTime,
  FetchServiceAreaAction,
} from "../../Redux/actions/fetchServiceAreaAction";
import ReactQuill from "react-quill";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";
import { DeleteProvidersAtLogoutTime } from "../../Redux/actions/fetchProvidersAction";
import { DeleteBranchesAtLogoutTime } from "../../Redux/actions/fetchBranchesAction";
import { DeleteServicesAtLogoutTime } from "../../Redux/actions/fetchServicesAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";

const ServiceArea = (props) => {
  const { goBack, push } = useHistory();
  const [serviceAreaList, setServiceAreaList] = useState(props.showServiceArea);
  useEffect(() => {
    props.getServiceAreaList(props);
    let mappedArray = serviceAreaList.map((serviceArea, index) => {
      return {
        ...serviceArea,
        no: index + 1,
        beneficiaries: serviceArea.beneficiaries,
        servicearea: {
          name: serviceArea.name,
          url: serviceArea.avatar,
        },
      };
    });
    setServiceAreaList(mappedArray);
  }, []);

  const handleMenu = (id) => {
    push(`/admin/edit-service-area/${id}`);
  };

  const menu = (text) => {
    return (
      <Menu className="menu-class menu-main-class" style={{ borderRadius: 20 }}>
        <Menu.Item key={text.id + Math.random()}>
          <div
            className="flex justify-between bg-white py-2 w-full  items-center"
            style={{ width: 160 }}
          >
            <div>
              <span>Delete </span>
            </div>
            <div>
              <RiDeleteBinFill color="red" size={20} />
            </div>
          </div>
        </Menu.Item>
        <Menu.Item
          key={text.id + Math.random()}
          onClick={() => handleMenu(text.service_area_id)}
        >
          <div
            className="flex justify-between bg-white py-2 w-full  items-center"
            style={{ width: 160 }}
          >
            <div>
              <span>Edit</span>
            </div>
            <div>
              <RiPencilLine color="#46bcff" size={20} />
            </div>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      align: "center",
      width: 60,
      render: (text) => <a>{text}</a>,
    },
    {
      align: "center",
      title: "Service Area",
      dataIndex: "servicearea",
      key: "servicearea",
      width: 200,
      render: (e) => (
        <div className="flex items-center ml-2">
          <img src={e?.url} alt="" className="h-8 w-8" />
          <span className="ml-4">{e?.name}</span>
        </div>
      ),
    },
    {
      align: "center",
      title: "Beneficiaries",
      dataIndex: "beneficiaries",
      key: "beneficiaries",
      width: 200,
      render: (e) =>
        e.map((c, i) => {
          return (
            <div key={i} className="flex items-center ml-2">
              <span className="ml-4">{`${c.beneficiary.name},`}</span>
            </div>
          );
        }),
    },
    {
      title: "Information",
      key: "info",
      dataIndex: "info",
      align: "center",
      width: 400,
      render: (text, index) => (
        <div className="table-quill">
          <ReactQuill readOnly value={text} style={{ width: 380 }} />
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 130,
      render: (text, index) => (
        <div className="text-center">
          <Dropdown
            overlay={() => menu(text)}
            className={"flex"}
            trigger={["click"]}
          >
            <div
              type="disable"
              shape={"round"}
              className="background-color-gray whiteTxt rounded-full py-3 justify-center cursor-pointer	"
            >
              <span>View Details</span>
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <section className="">
      <div className="flex justify-between mt-20 ">
        <div className="pl-5 flex self-center justify-center items-center sub-h3">
          <div
            onClick={() => goBack()}
            className="flex text-gray-400 items-center cursor-pointer"
          >
            <RiArrowDropLeftLine />
            <span>Back</span>
          </div>
          <h3 className="ml-10	">Service Area</h3>
        </div>
        <div className="mr-1 sm:mr-1 lg:mr-28 flex">
          <div className="mr-2">
            <Button shape={"circle"} className="icon-button p0 ">
              <FiSearch size={15} />
            </Button>
          </div>
          <Link to="/admin/add-service-area">
            <Button
              shape={"round"}
              className="icon-button"
              icon={<AiOutlinePlus color="#5ab3f0" />}
            >
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div className="container mx-auto  pl-0.5 sm:pl-0.5 lg:px-20 mt-5">
        <TableComponent columns={columns} data={serviceAreaList} />
      </div>
    </section>
  );
};
const mapStateToProps = (state) => {
  return {
    showServiceArea: state.serviceAreaList.serviceAreas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getServiceAreaList: () => {
      dispatch(FetchServiceAreaAction());
    },
    setLogoutUser: (data) => {
      dispatch(LogoutUserAction(data));
    },
    removeBeneficiaries: () => {
      dispatch(DeleteBeneficiariesAtLogoutTime());
    },
    removeProviders: () => {
      dispatch(DeleteProvidersAtLogoutTime());
    },
    removeServiceAreas: () => {
      dispatch(DeleteServiceAreasAtLogoutTime());
    },
    removeBranches: () => {
      dispatch(DeleteBranchesAtLogoutTime());
    },
    removeServices: () => {
      dispatch(DeleteServicesAtLogoutTime());
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ServiceArea);
