import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Spin, Tooltip, Badge } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiOutlineArrowDown } from "react-icons/ai";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { ModalComponent } from "../../components";
import { Errors, Success } from "../../components/antdToast";
import ButtonComponent from "../../components/ButtonComponent";
import TableComponent from "../../components/table";
import {
  GetPayouts,
  PayoutBudgetReleaseMethod,
  PayoutOrderReleaseMethod,
} from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { europeanCurrencyFormatter } from "../../config/utils";

function Payouts(params) {
  const { goBack } = useHistory();
  const [functionalityModal, setFunctionalityModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ordersPayouts, setOrdersPayouts] = useState([]);
  const [disputeOrdersPayouts, setDisputeOrdersPayouts] = useState([]);
  const [additional_budgetPayouts, setAdditional_budgetPayouts] = useState([]);
  const [disputeAdditionalBudgetPayouts, setDisputeAdditionalBudgetPayouts] =
    useState([]);

  const [index, setIndex] = useState(0);
  const [orderAndBudgetPayoutsInfo, setOrderAndBudgetPayoutsInfo] = useState(
    {}
  );
  const currentSelectedLangue = localStorage.getItem("primaryLanguage");
  const [payoutsLoader, setpayoutsLoader] = useState(false);

  useEffect(() => {
    fetchPayouts();
    document.body.style.overflow = "auto";
    return function cleanup() {
      document.body.style.overflow = "auto";
    };
  }, []);
  const fetchPayouts = () => {
    GetPayouts(
      (success) => {
        let orders = success.data.response.detail.orders.map((order, index) => {
          return {
            ...order,
            no: index + 1,
            product: order.order.service,
            orderId: order.order_id,
            date: moment(order.created_at).format("DD-MMM-YYYY HH:mm"),
            price:
              Number(order.after_discount_amount) +
              Number(order.supplementary_fees),
            provider_price:
              Number(order.after_discount_amount * 0.7) +
              Number(order.supplementary_fees),
            payment: order.charge_id,
            orderStatus: adminLabels?.[order.status],
            serviceProvider: order.order.service_provider.name,
          };
        });

        const allOrders = orders
          .filter((item) => item?.status !== "disputed")
          ?.map((item, index) => {
            return {
              ...item,
              no: index + 1,
            };
          });
        const allDisputedOrders = orders
          .filter((item) => item?.status === "disputed")
          ?.map((item, index) => {
            return {
              ...item,
              no: index + 1,
            };
          });
        setOrdersPayouts(allOrders);
        setDisputeOrdersPayouts(allDisputedOrders);
        const additional_budget =
          success.data.response.detail.additional_budget;
        const additional_budget_completed = additional_budget
          .filter((item) => item?.status !== "disputed")
          .map((budget, index) => {
            return {
              ...budget,
              no: index + 1,
              product: budget.order.service,
              orderId: budget.order_id,
              date: moment(budget.created_at).format("DD-MMM-YYYY HH:mm"),
              price: Number(budget.amount) + Number(budget.supplementary_fees),
              provider_price:
                Number(budget.amount * 0.7) + Number(budget.supplementary_fees),
              payment: budget.charge_id,
              orderStatus: adminLabels?.[budget.status],
              serviceProvider: budget.order.service_provider.name,
            };
          });
        const allDisputedAdditionalBudgets = additional_budget
          .filter((item) => item?.status === "disputed")
          ?.map((budget, index) => {
            return {
              ...budget,
              no: index + 1,
              product: budget.order.service,
              orderId: budget.order_id,
              date: moment(budget.created_at).format("DD-MMM-YYYY HH:mm"),
              price: Number(budget.amount) + Number(budget.supplementary_fees),
              provider_price:
                Number(budget.amount * 0.7) + Number(budget.supplementary_fees),
              payment: budget.charge_id,
              orderStatus: adminLabels?.[budget.status],
              serviceProvider: budget.order.service_provider.name,
            };
          });
        setAdditional_budgetPayouts(additional_budget_completed);
        setDisputeAdditionalBudgetPayouts(allDisputedAdditionalBudgets);
        setLoading(false);
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  const releaseOrderPayout = () => {
    setpayoutsLoader(true);

    let formdata = new FormData();
    formdata.append(
      "order_detail_id",
      orderAndBudgetPayoutsInfo?.order_detail_id
    );

    PayoutOrderReleaseMethod(
      formdata,
      (res) => {
        if (res?.status === 200) {
          let ordersPayoutUpdatedArray = ordersPayouts.map((item) => {
            if (
              item.order_detail_id ===
              orderAndBudgetPayoutsInfo?.order_detail_id
            ) {
              return { ...item, payment_released: 1 };
            } else {
              return item;
            }
          });
          setOrdersPayouts(ordersPayoutUpdatedArray);
          setpayoutsLoader(false);
          setFunctionalityModal(false);
          Success(res?.data?.response?.message);
          fetchPayouts();
        }
      },
      (err) => {
        if (err.response.status === 500) {
          Errors("Network Error");
        } else if (err.response.status === 422) {
          Errors(err.response.data.error.message);
        }
        setpayoutsLoader(false);
        setFunctionalityModal(false);
      }
    );
  };
  const releaseBudgetPayout = () => {
    setpayoutsLoader(true);
    let formdata = new FormData();
    formdata.append(
      "additional_budget_id",
      orderAndBudgetPayoutsInfo?.additional_budget_id
    );
    PayoutBudgetReleaseMethod(
      formdata,
      (res) => {
        if (res?.status === 200) {
          let additionalBudgetPayoutUpdatedArray = additional_budgetPayouts.map(
            (item) => {
              if (
                item.additional_budget_id ===
                orderAndBudgetPayoutsInfo?.additional_budget_id
              ) {
                return { ...item, payment_released: 1 };
              } else {
                return item;
              }
            }
          );
          setAdditional_budgetPayouts(additionalBudgetPayoutUpdatedArray);

          setpayoutsLoader(false);
          setFunctionalityModal(false);
          Success(res?.data?.response?.message);
          fetchPayouts();
        }
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors("Network Error");
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors(err?.response?.data?.error?.message);
        }
        setpayoutsLoader(false);
        setFunctionalityModal(false);
      }
    );
  };
  const onFeatured = (e, value) => {
    setOrderAndBudgetPayoutsInfo(value);
    setFunctionalityModal(true);
  };
  const columns = [
    {
      align: "center",
      title: adminLabels.no,
      dataIndex: "no",
      key: "no",
      width: 70,
      render: (text) => <a>{text}</a>,
    },
    {
      align: "center",
      title: adminLabels.product,
      dataIndex: "product",
      key: "product",
      width: 220,
      render: (text, index) => (
        <Tooltip
          placement="topLeft"
          title={
            currentSelectedLangue === "english" ? text?.name : text?.name_sp
          }
        >
          <div className={"flex items-center"}>
            <img
              src={text?.avatar}
              style={{ width: 50, height: 45 }}
              alt="detail image"
            />
            <div className="pl-2 text-left">
              <label>
                <b>
                  {currentSelectedLangue === "english"
                    ? text?.name
                    : text?.name_sp}
                </b>
              </label>
            </div>
          </div>
        </Tooltip>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return currentSelectedLangue === "english"
          ? record.product.name.toLowerCase().includes(value.toLowerCase())
          : record.product.name_sp.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        currentSelectedLangue === "english"
          ? a.product.name.localeCompare(b.product.name)
          : a.product?.name_sp.localeCompare(b.product?.name_sp),
    },
    {
      align: "center",
      title: adminLabels.orderId,
      dataIndex: "orderId",
      key: "orderId",
      width: 130,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.orderId.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.orderId.localeCompare(b?.orderId),
      render: (orderId) => (
        <Tooltip placement="topLeft" title={orderId}>
          {orderId}
        </Tooltip>
      ),
    },
    {
      align: "center",
      title: adminLabels.orderDate,
      key: "date",
      dataIndex: "date",
      width: 180,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      showSorterTooltip: false,
      render: (date) => (
        <Tooltip placement="topLeft" title={date}>
          {date}
        </Tooltip>
      ),
    },
    {
      align: "center",
      title: adminLabels.totalAmount,
      key: "price",
      dataIndex: "price",
      width: 150,
      render: (text) => (
        <Tooltip
          placement="topLeft"
          title={"€ " + europeanCurrencyFormatter(text)}
        >
          € {europeanCurrencyFormatter(text)}
        </Tooltip>
      ),

      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.price - b.price,
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: adminLabels.providerAmount,
      key: "provider_price",
      dataIndex: "provider_price",
      width: 150,
      render: (text, index) => <span>€ {europeanCurrencyFormatter(text)}</span>,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.provider_price - b.provider_price,
      showSorterTooltip: false,
      render: (provider_price) => (
        <Tooltip
          placement="topLeft"
          title={"€ " + europeanCurrencyFormatter(provider_price)}
        >
          € {europeanCurrencyFormatter(provider_price)}
        </Tooltip>
      ),
    },
    {
      align: "center",
      title: adminLabels.chargeId,
      dataIndex: "payment",
      key: "payment",
      width: 200,

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.payment.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      render: (payment) => (
        <Tooltip placement="topLeft" title={payment}>
          {payment}
        </Tooltip>
      ),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.payment.localeCompare(b?.payment),
    },
    {
      align: "center",
      title: adminLabels.orderStatus,
      className: "capitalize",
      key: "orderStatus",
      dataIndex: "orderStatus",
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.orderStatus.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      render: (orderStatus) => (
        <Tooltip placement="topLeft" title={orderStatus}>
          {orderStatus}
        </Tooltip>
      ),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.orderStatus?.localeCompare(b?.orderStatus),
      // render: (text, index) => (
      //   <span style={{ color: "green", fontWeight: "bold" }}>
      //     {text.orderStatus}
      //   </span>
      // ),
    },
    {
      align: "center",
      title: adminLabels.serviceProvider,
      dataIndex: "serviceProvider",
      key: "serviceProvider",
      width: 160,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.serviceProvider
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      render: (provider) => (
        <Tooltip placement="topLeft" title={provider}>
          {provider}
        </Tooltip>
      ),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.serviceProvider?.localeCompare(b?.serviceProvider),
    },
    {
      align: "center",
      title: adminLabels.releaseDate,
      key: "releaseDate",
      width: 200,
      render: (text) => (
        <span>
          {text?.payment_released
            ? moment(text.payment_released_date).format("DD-MMM-YYYY HH:mm")
            : "-"}
        </span>
      ),
    },
    {
      align: "center",
      title: adminLabels.action,
      key: "action",
      width: 150,

      render: (text, index) => (
        <div className="text-center	">
          <Button
            onClick={(e) => onFeatured(e, text)}
            shape={"round"}
            className={` ${
              text.payment_released
                ? "bg-transparent text-primary"
                : "whiteTxt bg-primary"
            } `}
          >
            {text.payment_released ? adminLabels.details : adminLabels.release}
          </Button>
        </div>
      ),
    },
  ];

  //////////// Modal //////////////////
  const changeIndex = (i) => {
    setIndex(i);
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <section className="">
      <Spin tip={adminLabels.loading} spinning={loading}>
        <div className="flex justify-between mt-5 ">
          <div className="pl-5 flex self-center justify-center items-center sub-h3">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{adminLabels.back}</span>
            </div>
            <h3 className="ml-10	">{adminLabels.payouts}</h3>
          </div>
          <div className="mr-1 sm:mr-1 lg:mr-28 flex">
            {/* <Link to="/add-admin" className="px-1">
            <Button shape={"round"} className="icon-button">
              All
            </Button>
          </Link> */}
            {/* <Button shape={"circle"} className="icon-button p0 my-auto">
              <FiSearch size={15} />
            </Button> */}
            <div className="rounded-lg p-2 icon-button  justify-center item-center mx-1  space-x-2">
              <Badge
                count={
                  ordersPayouts?.filter((item) => item?.payment_released === 0)
                    ?.length
                }
                offset={[-11]}
                overflowCount={100}
              >
                <Button
                  shape={"round"}
                  onClick={() => changeIndex(0)}
                  className={
                    index == 0
                      ? `icon-button mx-1 bg-primary text-white space-x-1`
                      : `icon-button mx-1`
                  }
                >
                  {adminLabels.orders}
                </Button>
              </Badge>
              <Badge
                count={
                  disputeOrdersPayouts?.filter(
                    (item) => item?.payment_released === 0
                  )?.length
                }
                offset={[-11]}
                overflowCount={100}
              >
                <Button
                  shape={"round"}
                  onClick={() => changeIndex(2)}
                  className={
                    index == 2
                      ? `icon-button mx-1 bg-primary text-white`
                      : `icon-button mx-1`
                  }
                >
                  {adminLabels.disputeOrders}
                </Button>
              </Badge>
              <Badge
                count={
                  additional_budgetPayouts?.filter(
                    (item) => item?.payment_released === 0
                  )?.length
                }
                offset={[-11]}
                overflowCount={100}
              >
                <Button
                  shape={"round"}
                  onClick={() => changeIndex(1)}
                  className={
                    index == 1
                      ? `icon-button mx-1 bg-primary text-white`
                      : `icon-button mx-1`
                  }
                >
                  {adminLabels.additionalBudget}
                </Button>
              </Badge>
              <Badge
                count={
                  disputeAdditionalBudgetPayouts?.filter(
                    (item) => item?.payment_released === 0
                  )?.length
                }
                offset={[-11]}
                overflowCount={100}
              >
                <Button
                  shape={"round"}
                  onClick={() => changeIndex(3)}
                  className={
                    index == 3
                      ? `icon-button mx-1 bg-primary text-white`
                      : `icon-button mx-1`
                  }
                >
                  {adminLabels.disputeAdditionalBudget}
                </Button>
              </Badge>
              <CSVLink
                data={
                  (index === 0 &&
                    ordersPayouts?.map((item, index) => {
                      return {
                        ...item,
                        no: index + 1,
                        nameOfService:
                          item?.product?.[
                            currentSelectedLangue === "english"
                              ? "name"
                              : "name_sp"
                          ],
                        paymentRelease: item?.payment_released
                          ? adminLabels.yes
                          : adminLabels.yes,
                      };
                    })) ||
                  (index === 1 &&
                    additional_budgetPayouts?.map((item, index) => {
                      return {
                        ...item,
                        no: index + 1,
                        nameOfService:
                          item?.product?.[
                            currentSelectedLangue === "english"
                              ? "name"
                              : "name_sp"
                          ],
                        paymentRelease: item?.payment_released
                          ? adminLabels.yes
                          : adminLabels.yes,
                      };
                    })) ||
                  (index === 2 &&
                    disputeOrdersPayouts?.map((item, index) => {
                      return {
                        ...item,
                        no: index + 1,
                        nameOfService:
                          item?.product?.[
                            currentSelectedLangue === "english"
                              ? "name"
                              : "name_sp"
                          ],
                        paymentRelease: item?.payment_released
                          ? adminLabels.yes
                          : adminLabels.yes,
                      };
                    })) ||
                  (index === 3 &&
                    disputeAdditionalBudgetPayouts?.map((item, index) => {
                      return {
                        ...item,
                        no: index + 1,
                        nameOfService:
                          item?.product?.[
                            currentSelectedLangue === "english"
                              ? "name"
                              : "name_sp"
                          ],
                        paymentRelease: item?.payment_released
                          ? adminLabels.yes
                          : adminLabels.yes,
                      };
                    }))
                }
                filename={
                  (index === 0 &&
                    `${adminLabels.orders} ${adminLabels.payouts}.csv`) ||
                  (index === 1 &&
                    `${adminLabels.additionalBudget} ${adminLabels.payouts}.csv`) ||
                  (index === 2 &&
                    `${adminLabels.disputeOrders} ${adminLabels.payouts}.csv`) ||
                  (index === 3 &&
                    `${adminLabels.disputeAdditionalBudget} ${adminLabels.payouts}.csv`)
                }
                headers={[
                  { label: adminLabels.no, key: "no" },
                  { label: adminLabels.product, key: "nameOfService" },
                  { label: adminLabels.orderId, key: "orderId" },
                  { label: adminLabels.orderDate, key: "date" },
                  { label: adminLabels.totalAmount, key: "price" },
                  { label: adminLabels.providerAmount, key: "provider_price" },
                  { label: adminLabels.chargeId, key: "payment" },
                  { label: adminLabels.orderStatus, key: "orderStatus" },
                  {
                    label: adminLabels.serviceProvider,
                    key: "serviceProvider",
                  },
                  { label: adminLabels.paymentRelease, key: "paymentRelease" },
                ]}
                target="_blank"
              >
                <Button
                  shape={"round"}
                  className="icon-button "
                  icon={<AiOutlineArrowDown color="#5ab3f0" />}
                >
                  {adminLabels.exportCSV}
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <div className="container mx-auto lg:px-20 mt-5">
          <TableComponent
            columns={columns}
            data={
              (index === 0 && ordersPayouts) ||
              (index === 1 && additional_budgetPayouts) ||
              (index === 2 && disputeOrdersPayouts) ||
              (index === 3 && disputeAdditionalBudgetPayouts)
            }
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
            scrolly="500px"
          />
        </div>

        {/* Modal */}
        <ModalComponent
          style={{ marginTop: "8%" }}
          filterModal={functionalityModal}
          width={500}
          footer={false}
          closeModal={
            payoutsLoader ? () => {} : () => setFunctionalityModal(false)
          }
          modalTitle={
            orderAndBudgetPayoutsInfo?.payment_released
              ? adminLabels.details
              : [2, 3].includes(index)
              ? adminLabels.releaseDisputedAmount
              : adminLabels.releaseAmount
          }
        >
          <div>
            <div calssName=" divide-black-600 divide-solid">
              <div className="flex mt-8 ml-2">
                <img
                  src={orderAndBudgetPayoutsInfo?.product?.avatar}
                  style={{ width: 50, height: 50 }}
                  alt="detail image"
                />

                <div className="pl-2 text-left text-xl ml-3">
                  <label>
                    <strong>
                      {currentSelectedLangue === "english"
                        ? orderAndBudgetPayoutsInfo?.product?.name
                        : orderAndBudgetPayoutsInfo?.product?.name_sp}
                    </strong>
                  </label>
                  <p className="text-sm text-gray-400">
                    {orderAndBudgetPayoutsInfo?.charge_id}
                  </p>
                </div>
              </div>
              {[2, 3].includes(index) ? (
                <div>
                  <div className="grid grid-cols-2 ml-2 ">
                    <div className="grid ">
                      <div className="mt-5">
                        <h5 className="font-black">
                          {adminLabels.disputeRefund}
                        </h5>
                        <p className="text-gray-400">
                          {
                            adminLabels?.[
                              orderAndBudgetPayoutsInfo?.[
                                index === 2
                                  ? "order_dispute_refund"
                                  : "budget_dispute_refund"
                              ]?.type
                            ]
                          }
                        </p>
                      </div>
                    </div>

                    <div className="grid grid-cols-2 ">
                      <div className="col-span-12  lg:col-span-3 sm:col-span-6 md:col-span-6">
                        <div className=" mt-5">
                          <h5 className="font-black">{adminLabels.orderId}</h5>
                          <p className="text-gray-400">
                            {orderAndBudgetPayoutsInfo?.order_id}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className=" " style={{ width: "200%" }}>
                      <Divider />
                      <div className="">
                        <div className="grid">
                          <div className="mt-5 flex justify-between">
                            <h5 className="font-black">
                              {adminLabels.serviceAmount}
                            </h5>
                            <p className="text-gray-400">
                              <span className="text-gray-400 text-sm">
                                €{" "}
                                {europeanCurrencyFormatter(
                                  orderAndBudgetPayoutsInfo?.after_discount_amount ||
                                    orderAndBudgetPayoutsInfo?.amount
                                )}
                              </span>
                            </p>
                          </div>
                        </div>
                        <Divider />
                        <div className=" mt-1 flex justify-between">
                          <div>
                            <h5 className="font-black">
                              {adminLabels.supplementaryCharges}
                            </h5>
                          </div>
                          <div>
                            <span className="text-gray-400">
                              €{" "}
                              {europeanCurrencyFormatter(
                                orderAndBudgetPayoutsInfo?.supplementary_fees
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="pl-3">
                          {index === 2
                            ? orderAndBudgetPayoutsInfo?.product?.service_supplementary_fees.map(
                                (item, index) => {
                                  return (
                                    <div
                                      className="  flex justify-between"
                                      key={index}
                                    >
                                      <div>
                                        <h6 className="font-black text-xs">
                                          - {item?.name}
                                        </h6>
                                      </div>
                                      <div>
                                        <span className="text-gray-400 text-xs">
                                          €{" "}
                                          {europeanCurrencyFormatter(
                                            item?.price
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            : orderAndBudgetPayoutsInfo?.supplementaries_fees?.map(
                                (item, index) => {
                                  return (
                                    <div
                                      className="  flex justify-between"
                                      key={index}
                                    >
                                      <div>
                                        <h6 className="font-black text-xs">
                                          - {item?.name}
                                        </h6>
                                      </div>
                                      <div>
                                        <span className="text-gray-400 text-xs">
                                          €{" "}
                                          {europeanCurrencyFormatter(
                                            item?.price
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                        </div>
                        <Divider />
                      </div>
                    </div>
                  </div>
                  {/* <div className="grid ml-2">
                    <div className="mt-1">
                      <h5 className="font-black">
                        {adminLabels.serviceAmount}
                      </h5>
                      <p className="text-gray-400">
                        <span className="text-gray-400 text-sm">
                          €{" "}
                          {europeanCurrencyFormatter(
                            orderAndBudgetPayoutsInfo?.after_discount_amount ||
                              orderAndBudgetPayoutsInfo?.amount
                          )}
                        </span>
                      </p>
                    </div>
                  </div> */}
                  <div className="grid ml-2">
                    <div className="mt-1">
                      <h5 className="font-black">
                        {adminLabels.amountForUser}{" "}
                      </h5>
                      <p className="text-gray-400">
                        <div className="flex justify-between flex-wrap items-center w-full">
                          <div className="space-x-2">
                            <b>{adminLabels.serviceAmount}</b>
                            <span>
                              {orderAndBudgetPayoutsInfo?.[
                                index === 2
                                  ? "order_dispute_refund"
                                  : "budget_dispute_refund"
                              ]?.user_percent || 0}
                              %
                            </span>
                          </div>
                          <div>
                            <span>
                                €{" "} {europeanCurrencyFormatter((Number(orderAndBudgetPayoutsInfo?.[index === 2 ? "order_dispute_refund" : "budget_dispute_refund" ]?.user_percent || 0)) * (Number(orderAndBudgetPayoutsInfo?.after_discount_amount || orderAndBudgetPayoutsInfo?.amount)/100))}
                            </span>
                          </div>
                        </div>
                      </p>
                      <p className="text-gray-400">
                        <div className="flex justify-between flex-wrap items-center w-full">
                          <div className="space-x-2">
                            <b>{adminLabels.supplementaryAmount}</b>
                            <span>
                              {orderAndBudgetPayoutsInfo?.[
                                index === 2
                                  ? "order_dispute_refund"
                                  : "budget_dispute_refund"
                              ]?.supplementary_user_percent || 0}
                              %
                            </span>
                          </div>
                          <div>
                            <span>
                                €{" "} {europeanCurrencyFormatter((Number(orderAndBudgetPayoutsInfo?.[index === 2 ? "order_dispute_refund" : "budget_dispute_refund" ]?.supplementary_user_percent || 0)) * (Number(orderAndBudgetPayoutsInfo?.supplementary_fees || orderAndBudgetPayoutsInfo?.supplementary_fees)/100))}
                            </span>
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                  <Divider />
                  <div className="grid ml-2">
                    <div className="mt-1">
                      <h5 className="font-black">
                        {adminLabels.amountForServiceProvider}{" "}
                      </h5>
                      <p className="text-gray-400">
                        <div className="flex justify-between flex-wrap items-center w-full">
                          <div className="space-x-2">
                            <b>{adminLabels.serviceAmount}</b>
                            <span>
                              {orderAndBudgetPayoutsInfo?.[
                                index === 2
                                  ? "order_dispute_refund"
                                  : "budget_dispute_refund"
                              ]?.provider_percent || 0}
                              %
                            </span>
                          </div>
                          <div>
                            <span>
                                €{" "} {europeanCurrencyFormatter((Number(orderAndBudgetPayoutsInfo?.[index === 2 ? "order_dispute_refund" : "budget_dispute_refund" ]?.provider_percent || 0)) * (Number(orderAndBudgetPayoutsInfo?.after_discount_amount || orderAndBudgetPayoutsInfo?.amount)/100))}
                            </span>
                          </div>
                        </div>
                      </p>
                      <p className="text-gray-400">
                        <div className="flex justify-between flex-wrap items-center w-full">
                          <div className="space-x-2">
                            <b>{adminLabels.seviceProvider70SeviceWithoutVAT}</b>
                          </div>
                          <div>
                            <span>
                                €{" "} {europeanCurrencyFormatter(((Number(orderAndBudgetPayoutsInfo?.[index === 2 ? "order_dispute_refund" : "budget_dispute_refund" ]?.provider_percent || 0)) * (Number(orderAndBudgetPayoutsInfo?.after_discount_amount || orderAndBudgetPayoutsInfo?.amount)/100)) * 0.7)}
                            </span>
                          </div>
                        </div>
                      </p>
                      
                      <p className="text-gray-400">
                        <div className="flex justify-between flex-wrap items-center w-full">
                          <div className="space-x-2">
                            <b>{adminLabels.supplementaryAmount}</b>
                            <span>
                              {orderAndBudgetPayoutsInfo?.[
                                index === 2
                                  ? "order_dispute_refund"
                                  : "budget_dispute_refund"
                              ]?.supplementary_provider_percent || 0}
                              %
                            </span>
                          </div>
                          <div>
                            <span>
                                €{" "} {europeanCurrencyFormatter((Number(orderAndBudgetPayoutsInfo?.[index === 2 ? "order_dispute_refund" : "budget_dispute_refund" ]?.supplementary_provider_percent || 0)) * (Number(orderAndBudgetPayoutsInfo?.supplementary_fees || orderAndBudgetPayoutsInfo?.supplementary_fees)/100))}
                            </span>
                          </div>
                        </div>
                      </p>
                      {/* <p className="text-gray-400">
                        {
                          orderAndBudgetPayoutsInfo?.[
                            index === 2
                              ? "order_dispute_refund"
                              : "budget_dispute_refund"
                          ]?.provider_percent
                        }{" "}
                        %
                      </p> */}
                    </div>
                  </div>
                  <Divider />
                  <div className="grid ml-2">
                    <div className="mt-1">
                      <p className="text-gray-400">
                        <div className="flex justify-between flex-wrap items-center w-full">
                          <div className="space-x-2">
                            <h5 className="font-black">{adminLabels.docuDocAmount}</h5>
                          </div>
                          <div>
                            <span>
                                €{" "} {europeanCurrencyFormatter((((Number(orderAndBudgetPayoutsInfo?.[index === 2 ? "order_dispute_refund" : "budget_dispute_refund" ]?.provider_percent || 0)) * (Number(orderAndBudgetPayoutsInfo?.after_discount_amount || orderAndBudgetPayoutsInfo?.amount)/100)))* 0.3)}
                            </span>
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                  <Divider />
                  <div className="grid ml-2">
                    <div className="mt-1">
                      <p className="text-gray-400">
                        <div className="flex justify-between flex-wrap items-center w-full">
                          <div className="space-x-2">
                            <h5 className="font-black">{adminLabels.paymentRelease}</h5>
                          </div>
                          <div>
                            <span>
                                €{" "} {europeanCurrencyFormatter(((((Number(orderAndBudgetPayoutsInfo?.[index === 2 ? "order_dispute_refund" : "budget_dispute_refund" ]?.provider_percent || 0)) * (Number(orderAndBudgetPayoutsInfo?.after_discount_amount || orderAndBudgetPayoutsInfo?.amount)/100)))* 0.3) + ((Number(orderAndBudgetPayoutsInfo?.[index === 2 ? "order_dispute_refund" : "budget_dispute_refund" ]?.supplementary_provider_percent || 0)) * (Number(orderAndBudgetPayoutsInfo?.supplementary_fees || orderAndBudgetPayoutsInfo?.supplementary_fees)/100)))}
                            </span>
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="grid grid-cols-2 ml-2 ">
                    <div className="grid ">
                      <div className="mt-5">
                        <h5 className="font-black">
                          {adminLabels.serviceProvider}
                        </h5>
                        <p className="text-gray-400">
                          {
                            orderAndBudgetPayoutsInfo?.order?.service_provider
                              .name
                          }
                        </p>
                      </div>
                    </div>

                    <div className="grid grid-cols-2 ">
                      <div className="col-span-12  lg:col-span-3 sm:col-span-6 md:col-span-6">
                        <div className=" mt-5">
                          <h5 className="font-black">{adminLabels.orderId}</h5>
                          <p className="text-gray-400">
                            {orderAndBudgetPayoutsInfo?.order_id}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className=" " style={{ width: "200%" }}>
                      <div className=" mt-5 flex justify-between">
                        <div>
                          <h5 className="font-black">
                            {adminLabels.serviceAmount}
                          </h5>
                        </div>
                        <div>
                          <span className="text-gray-400">
                            €{" "}
                            {europeanCurrencyFormatter(
                              index === 0
                                ? Number(orderAndBudgetPayoutsInfo?.after_discount_amount)
                                : Number(orderAndBudgetPayoutsInfo?.amount)
                            )}
                          </span>
                        </div>
                      </div>
                      <Divider />
                      <div className="">
                        <div className=" mt-5 flex justify-between">
                          <div>
                            <h5 className="font-black">
                              {adminLabels.supplementaryCharges}
                            </h5>
                          </div>
                          <div>
                            <span className="text-gray-400">
                              €{" "}
                              {europeanCurrencyFormatter(
                                orderAndBudgetPayoutsInfo?.supplementary_fees
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="pl-3">
                          {index === 0
                            ? orderAndBudgetPayoutsInfo?.product?.service_supplementary_fees?.map(
                                (item, index) => {
                                  return (
                                    <div
                                      className="  flex justify-between"
                                      key={index}
                                    >
                                      <div>
                                        <h6 className="font-black text-xs">
                                          - {item?.name}
                                        </h6>
                                      </div>
                                      <div>
                                        <span className="text-gray-400 text-xs">
                                          €{" "}
                                          {europeanCurrencyFormatter(
                                            item?.price
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            : orderAndBudgetPayoutsInfo?.supplementaries_fees?.map(
                                (item, index) => {
                                  return (
                                    <div
                                      className="  flex justify-between"
                                      key={index}
                                    >
                                      <div>
                                        <h6 className="font-black text-xs">
                                          - {item?.name}
                                        </h6>
                                      </div>
                                      <div>
                                        <span className="text-gray-400 text-xs">
                                          €{" "}
                                          {europeanCurrencyFormatter(
                                            item?.price
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <div className=" ml-2 ">
                    <div className="">
                      <div className=" pt-1 flex justify-between">
                        <div>
                          <h5 className="font-black">
                            {adminLabels.paymentRecieved}
                          </h5>
                        </div>
                        <div>
                          <span className="text-gray-400">
                            €{" "}
                            {europeanCurrencyFormatter(
                              index === 0
                                ? Number(orderAndBudgetPayoutsInfo?.after_discount_amount) + Number(orderAndBudgetPayoutsInfo?.supplementary_fees)
                                : Number(orderAndBudgetPayoutsInfo?.amount) + Number(orderAndBudgetPayoutsInfo?.supplementary_fees)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <div className=" ml-2 ">
                    <div className="">
                      <div className=" pt-1 flex justify-between">
                        <div>
                          <h5 className="font-black">
                            {adminLabels.serviceProviderServiceAmount}
                          </h5>
                        </div>
                        <div>
                          <span className="text-gray-400">
                            €{" "}
                            {europeanCurrencyFormatter(
                              index === 0
                                ? Number(orderAndBudgetPayoutsInfo?.after_discount_amount) * 0.7
                                : Number(orderAndBudgetPayoutsInfo?.amount) * 0.7
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" ml-2 ">
                    <div className="">
                      <div className=" pt-1 flex justify-between">
                        <div>
                          <h5 className="font-black">
                            {adminLabels.serviceProviderSupplementaryAmount}
                          </h5>
                        </div>
                        <div>
                          <span className="text-gray-400">
                            €{" "}
                            {europeanCurrencyFormatter(Number(orderAndBudgetPayoutsInfo?.supplementary_fees))}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <div className=" ml-2 ">
                    <div className="">
                      <div className=" pt-1 flex justify-between">
                        <div>
                          <h5 className="font-black">
                            {adminLabels.docuDocAmount}
                          </h5>
                        </div>
                        <div>
                          <span className="text-gray-400">
                            €{" "}
                            {europeanCurrencyFormatter(
                              index === 0
                                ? Number(orderAndBudgetPayoutsInfo?.after_discount_amount) * 0.3
                                : Number(orderAndBudgetPayoutsInfo?.amount) * 0.3
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <div className=" ml-2 ">
                    <div className="">
                      <div className=" py-2 pb-4 flex justify-between">
                        <div>
                          <h5 className="font-black">
                            {adminLabels.paymentRelease}
                          </h5>
                        </div>
                        <div>
                          <span className="text-gray-400">
                            €{" "}
                            {europeanCurrencyFormatter(
                              index === 0
                                ? Number(orderAndBudgetPayoutsInfo?.after_discount_amount * 0.7) + Number(orderAndBudgetPayoutsInfo?.supplementary_fees)
                                : Number(orderAndBudgetPayoutsInfo?.amount * 0.7) + Number(orderAndBudgetPayoutsInfo?.supplementary_fees)
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {orderAndBudgetPayoutsInfo?.payment_released ? (
                <></>
              ) : (
                <div className="w-full ">
                  <ButtonComponent
                    className={
                      payoutsLoader ? "background-color-gray" : "bg-primary"
                    }
                    title={adminLabels.release}
                    disabled={payoutsLoader}
                    onClick={
                      index === 0
                        ? releaseOrderPayout
                        : index === 1
                        ? releaseBudgetPayout
                        : index === 3
                        ? releaseBudgetPayout
                        : releaseOrderPayout
                    }
                    loader={payoutsLoader}
                  />
                </div>
              )}
            </div>
          </div>
        </ModalComponent>
        {/* Modal */}
      </Spin>
    </section>
  );
}

export default Payouts;
