export const LogoutUserAction = () => {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT_USER",
      payload: "",
    });
  };
};

export const ProviderLogoutAction = () => {
  return (dispatch) => {
    dispatch({
      type: "PROVIDER_LOGOUT_USER",
      payload: "",
    });
  };
};
