import {
  DeleteOutlined,
  FileTextOutlined,
  MessageOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { Button, Collapse, Empty, Input, List, Spin } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { connect } from "react-redux";
import renderHTML from "react-render-html";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Errors, Success } from "../../components/antdToast";
import ButtonComponent from "../../components/ButtonComponent";
import { Remove_Data_Form_Provider_Reducers } from "../../components/dataRemoveOnProviderLogout";
import {
  GetDisputedMessagesMethod,
  GetOrdersById,
  OrderDisputeCancelMethod,
  OrderDisputeContinueMethod,
  SendDisputeOrderMessageMethod,
  UploadFileMethod,
} from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { providerLabels } from "../../config/label/providerLabels";
import { Images } from "../../constant/Images";
import {
  DeleteWorkerAtLogoutTime,
  FetchWorkersAction,
} from "../../Redux/actions/fetchWorkersAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { ProviderLogoutAction } from "../../Redux/actions/logoutAction";
import "./dispute-chat.css";
import "./disputeMessage.css";

const format = "HH:mm";
const { document, loginLogo, uploadImg, checkbox, checkboxActive } = Images;
const DisputeChatMessages = (props) => {
  const { goBack, push } = useHistory();
  const location = useLocation();
  const [disuputeChat, setDisuputeChat] = useState([]);
  const [chatLoading, setChatLoading] = useState(true);
  const [chatSideBar, setChatSideBar] = useState(false);
  const [myChatDocuments, setMyChatDocuments] = useState([]);
  const [userChatDocuments, setUserChatDocuments] = useState([]);
  const [adminChatDocuments, setAdminChatDocuments] = useState([]);
  const [muiltipleDocument, setMuiltipleDocument] = useState("");
  const [urls, setUrls] = useState([]);
  const [message, setMessage] = useState("");
  const [index, changeIndex] = useState(0);
  const [user, setUser] = useState("");
  const [disputeConfirmationModal, setDisputeConfirmationModal] =
    useState(false);
  const [disputedDetailsModal, setDisputedDetailsModal] = useState(false);
  const [disputeOrderStatus, setDisputeOrderStatus] = useState("");
  const [disputeOrderRefund, setDisputeOrderRefund] = useState("");
  const [disputeOrderRefundStatus, setDisputeOrderRefundStatus] = useState("");
  const [disputeOrderRefundComplete, setDisputeOrderRefundComplete] =
    useState("");

  const [partialAmount, setPartialAmount] = useState("");
  const [selectedOrder, setSelectedOrder] = useState({});
  const [disputedScreen, setDisputedScreen] = useState(false);
  const [labels, setLabels] = useState({});
  const { id } = useParams();
  const fileRef = useRef(null);
  const { TextArea } = Input;

  useEffect(() => {
    getDisputedMessages(id);
    getOrdersByProvider(id);
    const user = localStorage.getItem("type");
    setUser(user);
    const interval = setInterval(() => {
      getDisputedMessages(id);
    }, 20000);

    if (
      location.pathname.includes("admin/disputed/") ||
      location.pathname.includes("/disputed/")
    ) {
      setDisputedScreen(true);

      //selectedOrder
    }
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setLabels(user === "admin" ? adminLabels : providerLabels);
  }, [user]);
  useEffect(() => {
    if (
      location.pathname.includes("admin/disputed/") ||
      location.pathname.includes("/disputed/")
    ) {
      const disputeData = selectedOrder?.order_dispute_refund;

      setDisputeOrderStatus(
        disputeData?.status === "disputed" ? "cancel" : "continue"
      );

      setDisputeOrderRefundStatus(disputeData?.type);
      setPartialAmount(Number(disputeData?.user_percent));
      if (disputeData?.type === "complete") {
        setDisputeOrderRefundComplete(
          disputeData?.user_percent === 100 ? "user" : "provider"
        );
      }
    }
  }, [selectedOrder]);
  useEffect(() => {
    if (disputeOrderStatus === "" || disputeOrderStatus === "continue") {
      setDisputeOrderRefund("");
      setDisputeOrderRefundStatus("");
      setDisputeOrderRefundComplete("");
    }
  }, [disputeOrderStatus]);

  useEffect(() => {
    if (
      disputeOrderRefundStatus === "" ||
      disputeOrderRefundStatus === "partial"
    ) {
      setDisputeOrderRefundComplete("");
    }
  }, [disputeOrderRefundStatus]);
  const getOrdersByProvider = (id) => {
    GetOrdersById(
      id,
      (success) => {
        if (success.status === 200) {
          if (success.data.response.detail !== null) {
            setSelectedOrder(success?.data?.response?.detail);
          }
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const getDisputedMessages = (orderID) => {
    GetDisputedMessagesMethod(
      orderID,
      (res) => {
        let data = res?.data?.response?.detail;
        setDisuputeChat(data);
        setChatLoading(false);
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };

  const sendMessage = () => {
    if (disputedScreen === false) {
      if (message !== "" || urls.length !== 0) {
        let obj = {
          order_id: id,

          is_provider_message: user === "service_provider" ? 1 : 0,
          message: JSON.stringify([
            {
              message: message === "" ? null : message,
              documents: urls.length === 0 ? null : urls,
            },
          ]),
          is_admin_message: user === "admin" ? 1 : 0,
        };

        SendDisputeOrderMessageMethod(
          obj,
          (success) => {
            if (success.status === 200) {
              getDisputedMessages(id);
              props.setLoaderState(false);
              Success(success.data.response.message);
              setMessage("");
              setUrls([]);
            }
          },
          (error) => {
            if (error?.response?.status === 401) {
              props.setLoaderState(false);
              Errors(error?.response?.data?.message);
              Remove_Data_Form_Provider_Reducers(props);
              push("/");
            } else if (error?.response?.status === 409) {
              Errors(error?.response?.data?.error?.message);
              props.setLoaderState(false);
            } else {
              props.setLoaderState(false);
              Errors("Network Error");
            }
          }
        );
      }
    }
  };
  const getUrl = (url, name, type, id) => {
    setUrls((prevurls) => [...prevurls, { url, name, type, id }]);
  };
  const selectMuiltipleDocument = async (valueArray) => {
    let muiltipleFileNames = "";
    valueArray.map((r, i) => {
      muiltipleFileNames += `${r.name}, `;
    });
    valueArray?.map((file, i) => {
      let formData = new FormData();
      formData.append("file", file);
      UploadFileMethod(
        formData,
        (success) => {
          if (success.status === 200) {
            if (success.data.response.detail !== null) {
              let id = Math.round(Math.random() * 10000);
              getUrl(success.data.response.detail, file.name, file.type, id);
            }
          }
        },
        (error) => {
          Errors(error);
        }
      );
    });
    setMuiltipleDocument(muiltipleFileNames);
  };
  const fileprev = (item, index) => {
    setUrls(urls?.filter((file) => file.id !== item.id));
  };

  const onIconClick = () => {
    if (disputedScreen === false) {
      fileRef.current.click();
    }
  };
  const ContinueOrderDispute = () => {
    const obj = {
      order_id: id,
    };
    OrderDisputeContinueMethod(
      obj,
      (res) => {
        Success(res.data.response.message);
        setDisputeConfirmationModal(false);
        const myTimeout = setTimeout(() => {
          push("/admin/payouts");
        }, 2000);
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const CompleteCancelOrderDispute = () => {
    const userPercent = disputeOrderRefundComplete === "user" ? 100 : 0;
    const providerPercent = disputeOrderRefundComplete === "provider" ? 100 : 0;

    const obj = {
      order_id: id,
      type: disputeOrderRefundStatus,
      user_percent: userPercent,
      provider_percent: providerPercent,
    };
    OrderDisputeCancelMethod(
      obj,
      (res) => {
        Success(res.data.response.message);
        setDisputeConfirmationModal(false);
        const myTimeout = setTimeout(() => {
          push("/admin/payouts");
        }, 2000);
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };

  const PartialCancelOrderDispute = () => {
    const obj = {
      order_id: id,
      type: disputeOrderRefundStatus,
      user_percent: Number(partialAmount),
      provider_percent: 100 - Number(partialAmount),
    };
    OrderDisputeCancelMethod(
      obj,
      (res) => {
        Success(res.data.response.message);
        setDisputeConfirmationModal(false);
        const myTimeout = setTimeout(() => {
          push("/admin/payouts");
        }, 2000);
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  return (
    <section>
      <div className="flex justify-between mt-20 mb-10">
        <div className="flex items-start pl-5 w-1/2">
          <div
            onClick={() => goBack()}
            className="flex text-gray-400 items-center cursor-pointer"
          >
            <RiArrowDropLeftLine />
            <span>{user === "admin" ? labels.back : labels.back}</span>
          </div>
          <div className="flex justify-between w-full">
            <div className="flex flex-col">
              <h3 className="ml-8	">
                {user === "admin" ? labels.orderId : labels.orderID}:{" "}
                {props.match.params.id}
              </h3>
              <div className="ml-8 ">
                <Link
                  to={`${
                    user === "admin" ? "/admin/order-steps/" : "/order-steps/"
                  }${id}`}
                  params={{ OrderID: id }}
                >
                  <span className="cursor-pointer view-datails-blue-color">
                    {labels.viewDetails}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container grid grid-cols-12 gap-3 px-2 sm:px-2 lg:px-0  ">
        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 dispute-user-provider-admin-chat-container">
          <Spin
            spinning={chatLoading}
            size="large"
            tip={user === "admin" ? labels.loading : labels.loading}
          >
            <div className="px-72 " style={{ background: "#FAFAFA" }}>
              <div
                className="flex justify-end border-t-0 border-l-0 border-r-0 border py-2 rounded-t-2xl"
                style={{ background: "#ffff" }}
              >
                <Button
                  shape={"round"}
                  onClick={() => changeIndex(0)}
                  icon={<MessageOutlined color="#5ab3f0" />}
                  className={
                    index === 0
                      ? `icon-button mx-1 bg-primary text-white`
                      : `icon-button mx-1`
                  }
                >
                  {user === "admin" ? labels.messages : labels.messages}
                </Button>
                <Button
                  shape={"round"}
                  onClick={() => changeIndex(1)}
                  icon={<FileTextOutlined color="#5ab3f0" />}
                  className={
                    index === 1
                      ? `icon-button mx-1 bg-primary text-white`
                      : `icon-button mx-1`
                  }
                >
                  {user === "admin" ? labels.documents : labels.documents}
                </Button>
                {disputedScreen === false && user === "admin" && (
                  <Button
                    shape={"round"}
                    className={`icon-button mx-1 bg-danger`}
                    onClick={() => setDisputeConfirmationModal(true)}
                  >
                    <span className="text-white">{labels?.endDispute}</span>
                  </Button>
                )}
                {disputedScreen === true && (
                  <Button
                    shape={"round"}
                    className={
                      disputedDetailsModal
                        ? `icon-button mx-1 bg-primary capitalize text-white`
                        : `icon-button mx-1 capitalize`
                    }
                    onClick={() => setDisputedDetailsModal(true)}
                  >
                    <span>{labels?.disputeDetails}</span>
                  </Button>
                )}
              </div>
              <div>
                {index === 0 ? (
                  <div
                    className="relative"
                    style={{
                      height: "40rem",
                    }}
                  >
                    <div className="bg-white flex items-center justify-center flex-col h-full ">
                      {disuputeChat?.length === 0 ? (
                        <div className=" w-full flex items-center justify-center flex-col  h-full">
                          <Empty description="" />
                          <span className="capitalize font-bold text-gray-400">
                            {user === "admin"
                              ? labels.noChatAvailable
                              : labels.noChatAvailable}
                          </span>
                        </div>
                      ) : (
                        <div
                          className={` w-full h-full pb-20  ${
                            chatSideBar
                              ? "overflow-y-hidden"
                              : "overflow-y-scroll"
                          }  dispute-chat-ui-container flex flex-col-reverse `}
                        >
                          {user === "admin" ? (
                            <div className="  grid grid-cols-1  px-4 py-4 space-y-12">
                              {disuputeChat?.map((item, index) =>
                                item.is_admin_message === "0" ? (
                                  <>
                                    <div
                                      className="place-self-start text-right "
                                      key={index}
                                    >
                                      <p className="text-gray-500 text-xs text-left  py-1 mb-2">
                                        <span className="font-black text-xs  text-black capitalize">
                                          {`(${
                                            item.is_provider_message === "1"
                                              ? `${item?.service_provider?.name}`
                                              : `${item?.user?.first_name} ${item?.user?.last_name}`
                                          })  `}
                                        </span>

                                        <br />
                                        <span className="text-xs float-left  text-gray-500">
                                          {` ${
                                            item.is_provider_message === "1"
                                              ? labels.serviceProvider
                                              : labels.user
                                          }`}
                                        </span>
                                      </p>
                                      <div className="bg-white p-5 shadow-md rounded-2xl rounded-tl-none">
                                        {item?.message[0]?.message === null
                                          ? null
                                          : renderHTML(
                                              item?.message[0]?.message || ""
                                            )}

                                        {item?.message[0]?.documents?.map(
                                          (docItem, index) => {
                                            return (
                                              <a
                                                key={index}
                                                href={`${docItem.url}`}
                                                target="_blank"
                                                download={docItem?.name}
                                              >
                                                <div className="flex justify-between space-x-1">
                                                  <img
                                                    src={document?.default}
                                                    className="document_icon"
                                                  />
                                                  <p className="text-sm">
                                                    {docItem?.name}
                                                  </p>
                                                </div>
                                              </a>
                                            );
                                          }
                                        )}
                                      </div>
                                      <p className="text-xs float-right mr-2 mt-2 text-gray-500">
                                        {`${moment(item?.created_at).format(
                                          "H:mm"
                                        )} | ${moment(item?.created_at).format(
                                          "DD-MMM-YYYY"
                                        )}`}
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="place-self-end text-left">
                                      <div className=" shadow-md  border-primary-message-sender  p-5 rounded-2xl rounded-tr-none">
                                        {item?.message[0]?.message === null
                                          ? null
                                          : renderHTML(
                                              item?.message[0]?.message || ""
                                            )}
                                        {item?.message[0]?.documents?.map(
                                          (docItem, index) => {
                                            return (
                                              <a
                                                key={index}
                                                href={`${docItem.url}`}
                                                target="_blank"
                                                download={docItem?.name}
                                              >
                                                <div className="flex justify-between space-x-1">
                                                  <img
                                                    src={document?.default}
                                                    className="document_icon"
                                                  />
                                                  <p className="text-sm">
                                                    {docItem?.name}
                                                  </p>
                                                </div>
                                              </a>
                                            );
                                          }
                                        )}
                                      </div>
                                      <p className="text-xs float-left ml-2 mt-2 text-gray-500">
                                        {" "}
                                        {`${moment(item?.created_at).format(
                                          "H:mm"
                                        )} | ${moment(item?.created_at).format(
                                          "DD-MMM-YYYY"
                                        )}`}
                                      </p>
                                    </div>
                                  </>
                                )
                              )}
                            </div>
                          ) : (
                            <div className="  grid grid-cols-1  px-4 py-4 space-y-12">
                              {disuputeChat?.map((item, index) =>
                                item.is_provider_message === "0" ? (
                                  <>
                                    <div
                                      className="place-self-start text-left "
                                      key={index}
                                    >
                                      <p className="text-gray-500 text-xs float-righ  py-1 mb-2">
                                        <span className="font-black text-xs  text-black capitalize">
                                          {item.is_admin_message === "1"
                                            ? ``
                                            : `(${item?.user?.first_name} ${item?.user?.last_name})`}
                                        </span>

                                        <br />
                                        <span className="text-xs float-left  text-gray-500">
                                          {item.is_admin_message === "1" ? (
                                            <img
                                              src={loginLogo.default}
                                              width="80"
                                            />
                                          ) : (
                                            labels.user
                                          )}
                                        </span>
                                      </p>
                                      <div className="bg-white p-5 shadow-md rounded-2xl rounded-tl-none">
                                        {item?.message[0]?.message === null
                                          ? null
                                          : renderHTML(
                                              item?.message[0]?.message || ""
                                            )}

                                        {item?.message[0]?.documents?.map(
                                          (docItem, index) => {
                                            return (
                                              <a
                                                key={index}
                                                href={`${docItem.url}`}
                                                target="_blank"
                                                download={docItem?.name}
                                              >
                                                <div className="flex justify-between space-x-1">
                                                  <img
                                                    src={document?.default}
                                                    className="document_icon"
                                                  />
                                                  <p className="text-sm">
                                                    {docItem?.name}
                                                  </p>
                                                </div>
                                              </a>
                                            );
                                          }
                                        )}
                                      </div>
                                      <p className="text-xs float-right mr-2 mt-2 text-gray-500">
                                        {`${moment(item?.created_at).format(
                                          "H:mm"
                                        )} | ${moment(item?.created_at).format(
                                          "DD-MMM-YYYY"
                                        )}`}
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="place-self-end"
                                      key={index}
                                    >
                                      <div className=" shadow-md  border-primary-message-sender  p-5 rounded-2xl rounded-tr-none">
                                        {item?.message[0]?.message === null
                                          ? null
                                          : renderHTML(
                                              item?.message[0]?.message || ""
                                            )}
                                        {item?.message[0]?.documents?.map(
                                          (docItem, index) => {
                                            return (
                                              <a
                                                key={index}
                                                href={`${docItem.url}`}
                                                target="_blank"
                                                download={docItem?.name}
                                              >
                                                <div className="flex justify-between space-x-1">
                                                  <img
                                                    src={document?.default}
                                                    className="document_icon"
                                                  />
                                                  <p className="text-sm">
                                                    {docItem?.name}
                                                  </p>
                                                </div>
                                              </a>
                                            );
                                          }
                                        )}
                                      </div>
                                      <p className="text-xs float-left ml-2 mt-2 text-gray-500">
                                        {" "}
                                        {`${moment(item?.created_at).format(
                                          "H:mm"
                                        )} | ${moment(item?.created_at).format(
                                          "DD-MMM-YYYY"
                                        )}`}
                                      </p>
                                    </div>
                                  </>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="w-full bg-white pb-2">
                      {urls.length > 0 && (
                        <div className="pt-2 flex flex-wrap">
                          {urls?.map((item, index) => {
                            return (
                              <div
                                className="flex justify-between px-2  w-auto"
                                key={index}
                              >
                                <div>
                                  {item?.name.substring(0, 10)}
                                  {item?.name?.length > 10 &&
                                    "... " + item?.name?.split(".")[1]}{" "}
                                </div>
                                <div
                                  className="cursor-pointer	"
                                  onClick={() => fileprev(item, index)}
                                >
                                  {" "}
                                  <DeleteOutlined
                                    className="cursor-pointer	pl-1"
                                    style={{ color: "red" }}
                                  />{" "}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                    <div
                      className="w-full absolute bottom-0 px-10 pt-5 pb-2 flex items-center border-t border-solid "
                      style={{ background: "#fff" }}
                    >
                      <div className="w-10/12 flex items-center">
                        <TextArea
                          style={{ borderRadius: 4 }}
                          autoSize={{ minRows: 2, maxRows: 6 }}
                          placeholder={
                            user === "admin"
                              ? labels.sendMessage
                              : labels.sendMessage
                          }
                          disabled={disputedScreen}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />

                        <input
                          type="file"
                          name="file"
                          multiple
                          className="hidden"
                          accept=".jpg, .jpeg, .png, .doc, .docx, .docs, .pdf, .xls, .csv"
                          ref={fileRef}
                          onChange={(e) => {
                            let f = e.target.files;
                            let fileArray = [];
                            for (var i = 0; i < f.length; i++) {
                              fileArray.push(f[i]);
                            }

                            selectMuiltipleDocument(fileArray);
                          }}
                        />
                      </div>
                      <div className="w-2/12 px-2 flex items-center">
                        <div className="px-2 flex items-center text-gray-200">
                          <PaperClipOutlined
                            onClick={onIconClick}
                            className="text-gray-200 cursor-pointer"
                            style={{ fontSize: "35px" }}
                          />
                        </div>

                        <ButtonComponent
                          title={user === "admin" ? labels?.send : labels.send}
                          onClick={sendMessage}
                          disabled={
                            message.trim().length < 3 && urls.length === 0
                          }
                          className={
                            message.trim().length < 3 && urls.length === 0
                              ? " opacity-60 cursor-not-allowed"
                              : "opacity-100"
                          }
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              {index === 1 ? (
                <div
                  className={`bg-white  float-right overflow-y-hidden side-bar-scroll w-full`}
                  style={{
                    height: "40rem",
                  }}
                >
                  <div className="  grid grid-cols-1   py-4 space-y-12">
                    <div className="my-5">
                      <h3 className="ml-8	">
                        {user === "admin"
                          ? labels?.attachedDocuments
                          : labels.attachedDocuments}{" "}
                      </h3>
                    </div>
                    <div className="chatDocsDiv">
                      <Collapse>
                        <Collapse.Panel header={labels.myDocuments} key="1">
                          {myChatDocuments.length === 0 ? (
                            <p>N/A</p>
                          ) : (
                            <div>
                              <div id="scrollableDiv">
                                <List
                                  dataSource={myChatDocuments}
                                  renderItem={(item) =>
                                    item?.message[0]?.documents?.map(
                                      (docItem, index) => {
                                        return (
                                          <List.Item
                                            key={index}
                                            id="scrollableDiv"
                                            className="sideBarDocumentsList"
                                          >
                                            <a
                                              href={`${docItem.url}`}
                                              target="_blank"
                                              download={docItem?.name}
                                            >
                                              <div className="flex justify-between space-x-1">
                                                <img
                                                  src={document?.default}
                                                  className="document_icon"
                                                />
                                                <p className="text-sm">
                                                  {docItem?.name}
                                                </p>
                                              </div>
                                            </a>
                                          </List.Item>
                                        );
                                      }
                                    )
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </Collapse.Panel>
                        <Collapse.Panel header={labels.userDocuments} key="2">
                          {userChatDocuments.length === 0 ? (
                            <p>N/A</p>
                          ) : (
                            <div>
                              <div id="scrollableDiv" className="">
                                <List
                                  dataSource={userChatDocuments}
                                  renderItem={(item) =>
                                    item?.message[0]?.documents?.map(
                                      (docItem, index) => {
                                        return (
                                          <List.Item
                                            key={index}
                                            id="scrollableDiv"
                                            className="sideBarDocumentsList"
                                          >
                                            <a
                                              href={`${docItem.url}`}
                                              target="_blank"
                                              download={docItem?.name}
                                            >
                                              <div className="flex justify-between space-x-1">
                                                <img
                                                  src={document?.default}
                                                  className="document_icon"
                                                />
                                                <p className="text-sm">
                                                  {docItem?.name}
                                                </p>
                                              </div>
                                            </a>
                                          </List.Item>
                                        );
                                      }
                                    )
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </Collapse.Panel>
                        <Collapse.Panel header={"Admin Documents"} key="3">
                          {adminChatDocuments.length === 0 ? (
                            <p>N/A</p>
                          ) : (
                            <div>
                              <div id="scrollableDiv" className="">
                                <List
                                  dataSource={userChatDocuments}
                                  renderItem={(item) =>
                                    item?.message[0]?.documents?.map(
                                      (docItem, index) => {
                                        return (
                                          <List.Item
                                            key={index}
                                            id="scrollableDiv"
                                            className="sideBarDocumentsList"
                                          >
                                            <a
                                              href={`${docItem.url}`}
                                              target="_blank"
                                              download={docItem?.name}
                                            >
                                              <div className="flex justify-between space-x-1">
                                                <img
                                                  src={document?.default}
                                                  className="document_icon"
                                                />
                                                <p className="text-sm">
                                                  {docItem?.name}
                                                </p>
                                              </div>
                                            </a>
                                          </List.Item>
                                        );
                                      }
                                    )
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </Collapse.Panel>
                      </Collapse>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </Spin>
        </div>
      </div>
      {user === "admin" && (
        <Modal
          visible={disputeConfirmationModal}
          onCancel={() => setDisputeConfirmationModal(false)}
          footer={false}
        >
          {" "}
          <div>
            {
              <h4 className="font-bold text-2xl text-center text-gray-600	">
                {labels.endDispute}
              </h4>
            }
          </div>
          <div className="w-full col-span-12 rounded-full lg:col-span-3 sm:col-span-6 md:col-span-6 text-center py-2 px-8"></div>
          <div className="flex justify-around items-center	">
            <div className="flex flex-col space-y-2 w-96">
              <div className="pt-2">
                <div className="pb-2 px-8">
                  <p>
                    {
                      labels?.ifYouwantToContinueClickOnContinueOrderOrIfYouWantToCancelClickOnCancelOrder
                    }
                  </p>
                </div>
                <div className="flex justify-start space-x-12">
                  <div className="flex items-center justify-start w-1/2">
                    {disputeOrderStatus === "cancel" ? (
                      <img
                        src={checkboxActive.default}
                        className="cursor-pointer"
                        onClick={() => setDisputeOrderStatus("")}
                      />
                    ) : (
                      <img
                        src={checkbox.default}
                        className="cursor-pointer"
                        onClick={() => setDisputeOrderStatus("cancel")}
                      />
                    )}
                    <span className=" text-dark   font-bold ml-2">
                      {labels?.cancelOrder}
                    </span>
                  </div>
                  <div className="flex items-center justify-start w-1/2">
                    {disputeOrderStatus === "continue" ? (
                      <img
                        src={checkboxActive.default}
                        className="cursor-pointer"
                        onClick={() => setDisputeOrderStatus("")}
                      />
                    ) : (
                      <img
                        src={checkbox.default}
                        className="cursor-pointer"
                        onClick={() => setDisputeOrderStatus("continue")}
                      />
                    )}
                    <span className="mr-2 text-dark  font-bold ml-2">
                      {labels?.continueOrder}
                    </span>
                  </div>
                </div>
              </div>

              {disputeOrderStatus === "cancel" && (
                <div className="pt-2">
                  <div className="pb-2">
                    {labels?.doYouLikeToGoForCompleteOrPartial}
                  </div>
                  <div className="flex justify-start space-x-12">
                    <div className="flex items-center justify-start w-1/2">
                      {disputeOrderRefundStatus === "complete" ? (
                        <img
                          src={checkboxActive.default}
                          className="cursor-pointer"
                          onClick={() => setDisputeOrderRefundStatus("")}
                        />
                      ) : (
                        <img
                          src={checkbox.default}
                          className="cursor-pointer"
                          onClick={() =>
                            setDisputeOrderRefundStatus("complete")
                          }
                        />
                      )}
                      <span className=" text-dark   font-bold ml-2">
                        {labels?.complete}
                      </span>
                    </div>
                    <div className="flex items-center justify-start w-1/2">
                      {disputeOrderRefundStatus === "partial" ? (
                        <img
                          src={checkboxActive.default}
                          className="cursor-pointer"
                          onClick={() => setDisputeOrderRefundStatus("")}
                        />
                      ) : (
                        <img
                          src={checkbox.default}
                          className="cursor-pointer"
                          onClick={() => setDisputeOrderRefundStatus("partial")}
                        />
                      )}
                      <span className="mr-2 text-dark  font-bold ml-2">
                        {labels?.partial}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {disputeOrderRefundStatus === "complete" && (
                <div className="pt-2">
                  <div className="pb-2">
                    {labels?.toWhomeYouLikeToMakeACompleteRefund}
                  </div>
                  <div className="flex justify-start space-x-12">
                    <div className="flex items-center justify-start w-1/2">
                      {disputeOrderRefundComplete === "provider" ? (
                        <img
                          src={checkboxActive.default}
                          className="cursor-pointer"
                          onClick={() => setDisputeOrderRefundComplete("")}
                        />
                      ) : (
                        <img
                          src={checkbox.default}
                          className="cursor-pointer"
                          onClick={() =>
                            setDisputeOrderRefundComplete("provider")
                          }
                        />
                      )}
                      <span className=" text-dark   font-bold ml-2">
                        {labels?.serviceProvider}
                      </span>
                    </div>
                    <div className="flex items-center justify-start w-1/2">
                      {disputeOrderRefundComplete === "user" ? (
                        <img
                          src={checkboxActive.default}
                          className="cursor-pointer"
                          onClick={() => setDisputeOrderRefundComplete("")}
                        />
                      ) : (
                        <img
                          src={checkbox.default}
                          className="cursor-pointer"
                          onClick={() => setDisputeOrderRefundComplete("user")}
                        />
                      )}
                      <span className="mr-2 text-dark  font-bold ml-2">
                        {labels?.user}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {disputeOrderRefundStatus === "partial" && (
                <div className="pt-2">
                  <div className="pb-2">
                    {labels?.enterPercentYouwantToRefundUser}
                  </div>
                  <div className="flex justify-start space-x-12 items-end">
                    <div>
                      <Input
                        type={"number"}
                        min={1}
                        max={99}
                        value={partialAmount}
                        onChange={(e) => {
                          let value = e?.target.value;
                          if (Number(value) <= 99) {
                            setPartialAmount(value);
                          }
                        }}
                      />
                    </div>
                    <div>
                      {labels?.remaining}{" "}
                      <b>{" " + 100 - partialAmount + " "}</b> %{" "}
                      {labels?.forServiceProvider}
                    </div>
                  </div>
                </div>
              )}
              {disputeOrderRefundStatus === "complete" &&
                disputeOrderRefundComplete === "user" && (
                  <div>
                    <p>
                      {labels?.orderAmount} {" = "}
                      <b>{selectedOrder?.after_discount_amount} €</b>
                    </p>
                    <p>
                      {labels?.supplementaryAmount} {" = "}
                      <b>{selectedOrder?.supplementary_fees} €</b>
                    </p>
                    <b>{labels?.totalAmount}</b>
                    <p>
                      {labels?.orderAmount} {" + "}
                      {labels?.supplementaryAmount} {" = "}
                      <b>
                        {(
                          Number(selectedOrder?.after_discount_amount) +
                          Number(selectedOrder?.supplementary_fees)
                        ).toFixed()}{" "}
                        €
                      </b>
                    </p>
                  </div>
                )}
              {disputeOrderRefundStatus === "complete" &&
                disputeOrderRefundComplete === "provider" && (
                  <div>
                    <p>
                      {labels?.orderAmount} ={" "}
                      <b>{selectedOrder?.after_discount_amount} €</b>
                    </p>
                    <p>
                      {labels?.supplementaryAmount} {" = "}
                      <b>{selectedOrder?.supplementary_fees} €</b>
                    </p>
                    <p>
                      {labels?.docuDocDeduction} 30% {" = "}
                      <b>
                        {(
                          Number(selectedOrder?.after_discount_amount) * 0.3
                        )?.toFixed()}{" "}
                        €
                      </b>
                    </p>
                    <p>
                      {labels?.serviceProvider} 70% {" = "}
                      <b>
                        {(
                          Number(selectedOrder?.after_discount_amount) * 0.7
                        )?.toFixed()}{" "}
                        €
                      </b>
                    </p>
                    <b>{labels?.totalAmount}</b>
                    <p>
                      {labels?.docuDocDeduction} 70% +{" "}
                      {labels?.supplementaryAmount} {" = "}
                      <b>
                        {(
                          Number(selectedOrder?.after_discount_amount) * 0.7 +
                          Number(selectedOrder?.supplementary_fees)
                        ).toFixed()}{" "}
                        €
                      </b>
                    </p>
                  </div>
                )}
              {disputeOrderRefundStatus === "partial" && (
                <div>
                  <p>
                    {labels?.orderAmount} {" : "}
                    <b>{selectedOrder?.after_discount_amount} €</b>
                  </p>
                  <p>
                    {labels.supplementaryAmount} {" = "}
                    <b>{selectedOrder?.supplementary_fees} €</b>
                  </p>
                  <p>
                    <b className="text-lg">{labels.user}</b>
                  </p>
                  <p>
                    {labels?.partialAmountOf} {partialAmount} % {" = "}
                    <b>
                      {(
                        Number(selectedOrder?.after_discount_amount) *
                        (Number(partialAmount) / 100)
                      ).toFixed()}{" "}
                      €
                    </b>
                  </p>
                  <b>{labels?.totalAmount}</b>
                  <p>
                    {labels?.partialAmount} + {labels?.supplementaryAmount}{" "}
                    {" = "}
                    <b>
                      {(
                        Number(selectedOrder?.after_discount_amount) *
                          (Number(partialAmount) / 100) +
                        Number(selectedOrder?.supplementary_fees)
                      ).toFixed()}{" "}
                      €
                    </b>
                  </p>
                  <p>
                    <b className="text-lg">{labels?.serviceProvider}</b>
                  </p>
                  <p>
                    {labels?.partialAmountOf} {100 - partialAmount} % {" = "}
                    <b>
                      {(
                        Number(selectedOrder?.after_discount_amount) *
                        (Number(100 - partialAmount) / 100)
                      ).toFixed()}{" "}
                      €
                    </b>
                  </p>
                  <p>
                    {labels?.docuDocDeduction} 30% {labels?.ofPartialAmount} ={" "}
                    <b>
                      {(
                        Number(
                          Number(selectedOrder?.after_discount_amount) *
                            (Number(100 - partialAmount) / 100)
                        ) * 0.3
                      )?.toFixed()}{" "}
                      €
                    </b>
                  </p>
                  <p>
                    {labels?.serviceProvider} 70% {labels?.ofPartialAmount} ={" "}
                    <b>
                      {(
                        Number(
                          Number(selectedOrder?.after_discount_amount) *
                            (Number(100 - partialAmount) / 100)
                        ) * 0.7
                      )?.toFixed()}{" "}
                      €
                    </b>
                  </p>
                  <b>{labels?.totalAmount}</b>
                  <p>
                    {labels?.serviceProvider} 70% +{" "}
                    {labels?.supplementaryAmount} {" = "}
                    <b>
                      {(
                        Number(
                          Number(selectedOrder?.after_discount_amount) *
                            (Number(100 - partialAmount) / 100)
                        ) *
                          0.7 +
                        Number(selectedOrder?.supplementary_fees)
                      ).toFixed()}{" "}
                      €
                    </b>
                  </p>
                </div>
              )}
              <div className="flex justify-center py-2">
                {disputeOrderStatus === "continue" && (
                  <ButtonComponent
                    title={labels?.confirm}
                    onClick={ContinueOrderDispute}
                  />
                )}
                {["user", "provider"].includes(disputeOrderRefundComplete) && (
                  <ButtonComponent
                    title={labels?.confirm}
                    onClick={CompleteCancelOrderDispute}
                  />
                )}
                {disputeOrderRefundStatus === "partial" && (
                  <ButtonComponent
                    title={labels?.confirm}
                    onClick={PartialCancelOrderDispute}
                  />
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}

      {disputedScreen === true && (
        <Modal
          visible={disputedDetailsModal}
          onCancel={() => setDisputedDetailsModal(false)}
          footer={false}
        >
          {" "}
          <div>
            {
              <h4 className="font-bold text-2xl text-center text-gray-600	">
                {labels.disputeDetails}
              </h4>
            }
          </div>
          <div className="w-full col-span-12 rounded-full lg:col-span-3 sm:col-span-6 md:col-span-6 text-center py-2 px-8"></div>
          <div className="flex justify-around items-center	">
            <div className="flex flex-col space-y-2 w-96">
              <div className="pt-2">
                <div className="pb-2 px-8">
                  <p>
                    {
                      labels?.ifYouwantToContinueClickOnContinueOrderOrIfYouWantToCancelClickOnCancelOrder
                    }
                  </p>
                </div>
                <div className="flex justify-start space-x-12">
                  <div className="flex items-center justify-start w-1/2">
                    {disputeOrderStatus === "cancel" ? (
                      <img src={checkboxActive.default} className="" />
                    ) : (
                      <img src={checkbox.default} className="" />
                    )}
                    <span className=" text-dark   font-bold ml-2">
                      {labels?.cancelOrder}
                    </span>
                  </div>
                  <div className="flex items-center justify-start w-1/2">
                    {disputeOrderStatus === "continue" ? (
                      <img src={checkboxActive.default} className="" />
                    ) : (
                      <img src={checkbox.default} className="" />
                    )}
                    <span className="mr-2 text-dark  font-bold ml-2">
                      {labels?.continueOrder}
                    </span>
                  </div>
                </div>
              </div>

              {disputeOrderStatus === "cancel" && (
                <div className="pt-2">
                  <div className="pb-2">
                    {labels?.doYouLikeToGoForCompleteOrPartial}
                  </div>
                  <div className="flex justify-start space-x-12">
                    <div className="flex items-center justify-start w-1/2">
                      {disputeOrderRefundStatus === "complete" ? (
                        <img src={checkboxActive.default} className="" />
                      ) : (
                        <img src={checkbox.default} className="" />
                      )}
                      <span className=" text-dark   font-bold ml-2">
                        {labels?.complete}
                      </span>
                    </div>
                    <div className="flex items-center justify-start w-1/2">
                      {disputeOrderRefundStatus === "partial" ? (
                        <img src={checkboxActive.default} className="" />
                      ) : (
                        <img src={checkbox.default} className="" />
                      )}
                      <span className="mr-2 text-dark  font-bold ml-2">
                        {labels?.partial}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {disputeOrderRefundStatus === "complete" && (
                <div className="pt-2">
                  <div className="pb-2">
                    {labels?.toWhomeYouLikeToMakeACompleteRefund}
                  </div>
                  <div className="flex justify-start space-x-12">
                    <div className="flex items-center justify-start w-1/2">
                      {disputeOrderRefundComplete === "provider" ? (
                        <img src={checkboxActive.default} className="" />
                      ) : (
                        <img src={checkbox.default} className="" />
                      )}
                      <span className=" text-dark   font-bold ml-2">
                        {labels?.serviceProvider}
                      </span>
                    </div>
                    <div className="flex items-center justify-start w-1/2">
                      {disputeOrderRefundComplete === "user" ? (
                        <img src={checkboxActive.default} className="" />
                      ) : (
                        <img src={checkbox.default} className="" />
                      )}
                      <span className="mr-2 text-dark  font-bold ml-2">
                        {labels?.user}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {disputeOrderRefundStatus === "partial" && (
                <div className="pt-2">
                  <div className="pb-2">
                    {labels?.enterPercentYouwantToRefundUser}
                  </div>
                  <div className="flex justify-start space-x-12 items-end">
                    <div>
                      <Input
                        type={"number"}
                        min={1}
                        max={99}
                        value={partialAmount}
                      />
                    </div>
                    <div>
                      {labels?.remaining}{" "}
                      <b>{" " + 100 - partialAmount + " "}</b> %{" "}
                      {labels?.forServiceProvider}
                    </div>
                  </div>
                </div>
              )}
              {disputeOrderRefundStatus === "complete" &&
                disputeOrderRefundComplete === "user" && (
                  <div>
                    <p>
                      {labels?.orderAmount} {" = "}
                      <b>{selectedOrder?.after_discount_amount} €</b>
                    </p>
                    <p>
                      {labels?.supplementaryAmount} {" = "}
                      <b>{selectedOrder?.supplementary_fees} €</b>
                    </p>
                    <b>{labels?.totalAmount}</b>
                    <p>
                      {labels?.orderAmount} {" + "}
                      {labels?.supplementaryAmount} {" = "}
                      <b>
                        {(
                          Number(selectedOrder?.after_discount_amount) +
                          Number(selectedOrder?.supplementary_fees)
                        ).toFixed()}{" "}
                        €
                      </b>
                    </p>
                  </div>
                )}
              {disputeOrderRefundStatus === "complete" &&
                disputeOrderRefundComplete === "provider" && (
                  <div>
                    <p>
                      {labels?.orderAmount} ={" "}
                      <b>{selectedOrder?.after_discount_amount} €</b>
                    </p>
                    <p>
                      {labels?.supplementaryAmount} {" = "}
                      <b>{selectedOrder?.supplementary_fees} €</b>
                    </p>
                    <p>
                      {labels?.docuDocDeduction} 30% {" = "}
                      <b>
                        {(
                          Number(selectedOrder?.after_discount_amount) * 0.3
                        )?.toFixed()}{" "}
                        €
                      </b>
                    </p>
                    <p>
                      {labels?.serviceProvider} 70% {" = "}
                      <b>
                        {(
                          Number(selectedOrder?.after_discount_amount) * 0.7
                        )?.toFixed()}{" "}
                        €
                      </b>
                    </p>
                    <b>{labels?.totalAmount}</b>
                    <p>
                      {labels?.docuDocDeduction} 70% +{" "}
                      {labels?.supplementaryAmount} {" = "}
                      <b>
                        {(
                          Number(selectedOrder?.after_discount_amount) * 0.7 +
                          Number(selectedOrder?.supplementary_fees)
                        ).toFixed()}{" "}
                        €
                      </b>
                    </p>
                  </div>
                )}
              {disputeOrderRefundStatus === "partial" && (
                <div>
                  <p>
                    {labels?.orderAmount} {" : "}
                    <b>{selectedOrder?.after_discount_amount} €</b>
                  </p>
                  <p>
                    {labels.supplementaryAmount} {" = "}
                    <b>{selectedOrder?.supplementary_fees} €</b>
                  </p>
                  <p>
                    <b className="text-lg">{labels.user}</b>
                  </p>
                  <p>
                    {labels?.partialAmountOf} {partialAmount} % {" = "}
                    <b>
                      {(
                        Number(selectedOrder?.after_discount_amount) *
                        (Number(partialAmount) / 100)
                      ).toFixed()}{" "}
                      €
                    </b>
                  </p>
                  <b>{labels?.totalAmount}</b>
                  <p>
                    {labels?.partialAmount} + {labels?.supplementaryAmount}{" "}
                    {" = "}
                    <b>
                      {(
                        Number(selectedOrder?.after_discount_amount) *
                          (Number(partialAmount) / 100) +
                        Number(selectedOrder?.supplementary_fees)
                      ).toFixed()}{" "}
                      €
                    </b>
                  </p>
                  <p>
                    <b className="text-lg">{labels?.serviceProvider}</b>
                  </p>
                  <p>
                    {labels?.partialAmountOf} {100 - partialAmount} % {" = "}
                    <b>
                      {(
                        Number(selectedOrder?.after_discount_amount) *
                        (Number(100 - partialAmount) / 100)
                      ).toFixed()}{" "}
                      €
                    </b>
                  </p>
                  <p>
                    {labels?.docuDocDeduction} 30% {labels?.ofPartialAmount} ={" "}
                    <b>
                      {(
                        Number(
                          Number(selectedOrder?.after_discount_amount) *
                            (Number(100 - partialAmount) / 100)
                        ) * 0.3
                      )?.toFixed()}{" "}
                      €
                    </b>
                  </p>
                  <p>
                    {labels?.serviceProvider} 70% {labels?.ofPartialAmount} ={" "}
                    <b>
                      {(
                        Number(
                          Number(selectedOrder?.after_discount_amount) *
                            (Number(100 - partialAmount) / 100)
                        ) * 0.7
                      )?.toFixed()}{" "}
                      €
                    </b>
                  </p>
                  <b>{labels?.totalAmount}</b>
                  <p>
                    {labels?.serviceProvider} 70% +{" "}
                    {labels?.supplementaryAmount} {" = "}
                    <b>
                      {(
                        Number(
                          Number(selectedOrder?.after_discount_amount) *
                            (Number(100 - partialAmount) / 100)
                        ) *
                          0.7 +
                        Number(selectedOrder?.supplementary_fees)
                      ).toFixed()}{" "}
                      €
                    </b>
                  </p>
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    providerDetail: state.providerLoginDetail.loggedUserDetailOfProvider,
    loader: state.loaderToggle.loaderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
    getWorkers: (props) => {
      dispatch(FetchWorkersAction(props));
    },
    removeWorkerList: () => {
      dispatch(DeleteWorkerAtLogoutTime());
    },
    setProviderLogout: () => {
      dispatch(ProviderLogoutAction());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisputeChatMessages);
