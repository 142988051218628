import { Badge, Button, Dropdown, Menu } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  GetOrdersOfProvider,
  ServiceProviderAdditionalBudgets,
  GetCoWorkerOrders,
} from "../../config/api";
import { providerLabels } from "../../config/label/providerLabels";
import { Images } from "../../constant/Images";
import {
  DeleteWorkerAtLogoutTime,
  FetchWorkersAction,
} from "../../Redux/actions/fetchWorkersAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { ProviderLogoutAction } from "../../Redux/actions/logoutAction";
import { fetchServiceProviderMessages } from "../../Redux/actions/serviceProviderActions/messages";
import { SessionContext } from "../../store";
import { Errors } from "../antdToast";
import { Remove_Data_Form_Provider_Reducers } from "../dataRemoveOnProviderLogout";
import {
  child,
  onValue,
  push as fbPush,
  ref,
  runTransaction,
  serverTimestamp,
  update,
} from "firebase/database";
import { firebaseDatabase } from "../../config/Firebase";
import "./AppHeader.css";
import { fetchServiceProviderAdditionalBudgets } from "../../Redux/actions/serviceProviderActions/additionalBudgets";
import { fetchServiceProviderAllOrders } from "../../Redux/actions/serviceProviderActions/orders";

function SubAdminHeader(props) {
  const [collapse, setCollapse] = React.useState(false);
  const [allOrders, setAllOrders] = useState([]);
  const [assignedOrders, setAssignedOrders] = useState([]);
  const [unAssignedOrders, setUnAssignedOrders] = useState([]);
  const [additionalBudget, setAdditionalBudget] = useState([]);
  const [serviceProviderMessages, setServiceProviderMessages] = useState([]);
  const [
    serverWorkerPendingDisputeCancelledCount,
    setServerWorkerPendingDisputeCancelledCount,
  ] = useState(0);
  const { session } = useContext(SessionContext);
  const { push, location } = useHistory();
  const dispatch = useDispatch();
  const orders = useSelector(
    (state) => state?.serviceProviderAllOrders?.orders?.orders
  );
  const messages = useSelector(
    (state) => state.serviceProviderMessages?.messages
  );
  const budgets = useSelector(
    (state) => state?.serviceProviderAllAdditionalBudgets?.budgets
  );
  const actionRoute = (e) => {
    setCollapse(false);
    push(e);
  };
  useEffect(() => {}, [localStorage.getItem("primaryLanguage")]);

  useEffect(() => {
     if (localStorage.getItem("type") == "co_worker") {
      fetchServiceProviderWorkerOrders();
    }
    dispatch(fetchServiceProviderAllOrders(push));
    dispatch(
      fetchServiceProviderAdditionalBudgets(
        props?.providerDetail?.admin?.service_provider_id,
        push
      )
    );
   
    // getOrdersByProvider();
    const interval = setInterval(() => {
      dispatch(fetchServiceProviderMessages(push));
    }, 20000);
    dispatch(fetchServiceProviderMessages(push));
    return () => {
      clearInterval(interval);
    };
  }, []);
  const getOrderUnreadCountData = (orderID) => {
    const getData = ref(firebaseDatabase, `orders/${orderID}/providerUnread`);
    let count = 0;
    onValue(getData, (snapshot) => {
      const data = snapshot?.val();
      count = data;
    });
    return count || 0;
  };

  const getOrdersByProvider = () => {
    GetOrdersOfProvider(
      (success) => {
        if (success.status === 200) {
          if (success.data.response.detail !== null) {
            const apiData = success?.data?.response?.detail?.orders;
            setAllOrders(
              apiData?.filter(
                (item) =>
                  ["pending", "dispute", "cancelled"]?.includes(item?.status) &&
                  !item?.is_read_provider
              )
            );
            const providerAssignedOrders = apiData?.filter(
              (item) =>
                item.worker_provider_id !== null && item.status !== "completed"
            );
            setAssignedOrders(providerAssignedOrders);
            const providerUnAssignedOrders = apiData?.filter(
              (item) => item.worker_provider_id === null
            );
            setUnAssignedOrders(providerUnAssignedOrders);
          }
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  useEffect(() => {
    if (localStorage.getItem("type") == "service_provider") {
      setMenus((prev) => {
        return prev?.map((item) => {
          if (item?.id === 2) {
            return {
              ...item,
              count: assignedOrders?.filter((item) =>
                ["pending", "dispute", "cancelled"]?.includes(item?.status)
              )?.length,
            };
          }
          if (item?.id === 1) {
            return {
              ...item,
              count: unAssignedOrders?.length,
            };
          }
          if (item?.id === 3) {
            return {
              ...item,
              count: serviceProviderMessages
                ?.map((item) => item["messageCount"])
                ?.reduce((a, b) => a + b, 0),
            };
          }
          if (item?.id === 4) {
            return {
              ...item,
              count: additionalBudget.filter(
                (fItem) => fItem.is_read_provider === 0
              )?.length,
            };
          }
          if (item?.id === 7) {
            return {
              ...item,
              count: allOrders?.length,
            };
          } else {
            return item;
          }
        });
      });
    }
    if (localStorage.getItem("type") == "co_worker") {
      setMenus((prev) => {
        return prev?.map((item) => {
          if (item?.id === 4) {
            return {
              ...item,
              count: additionalBudget.filter(
                (fItem) => fItem.is_read_provider === 0
              )?.length,
            };
          } else if (item?.id === 3) {
            return {
              ...item,
              count: serviceProviderMessages
                ?.map((item) => item["messageCount"])
                ?.reduce((a, b) => a + b, 0),
            };
          } else if (item?.id === 1) {
            return {
              ...item,
              count: serverWorkerPendingDisputeCancelledCount
            };
          } else {
            return item;
          }
        });
      });
    }
  }, [
    assignedOrders,
    unAssignedOrders,
    additionalBudget,
    serviceProviderMessages,
  ]);
  useEffect(() => {
    const messagesWithTheCount = messages?.map((item) => {
      return { ...item, messageCount: getOrderUnreadCountData(item?.order_id) };
    });
    setServiceProviderMessages(messagesWithTheCount);
  }, [messages]);
  useEffect(() => {
    setAdditionalBudget(budgets || []);
  }, [budgets]);
  useEffect(() => {
    const apiData = orders;
    setAllOrders(
      apiData?.filter(
        (item) =>
          ["pending", "dispute", "cancelled"]?.includes(item?.status) &&
          !item?.is_read_provider
      )
    );
    const providerAssignedOrders = apiData?.filter(
      (item) => item.worker_provider_id !== null && item.status !== "completed"
    );
    setAssignedOrders(providerAssignedOrders);
    const providerUnAssignedOrders = apiData?.filter(
      (item) => item.worker_provider_id === null
    );
    setUnAssignedOrders(providerUnAssignedOrders);
  }, [orders]);

  const fetchServiceProviderWorkerOrders = () => {
    GetCoWorkerOrders(
      (success) => {
        if (success.status === 200) {
          if (success.data.response.detail !== null) {
            let count = success?.data?.response?.detail?.orders?.filter((item) =>
                ["pending", "dispute", "disputed", "cancelled"]?.includes(
                  item?.status
                )
            )?.length;
            setServerWorkerPendingDisputeCancelledCount(count);
          }
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const langMenu = (
    <Menu>
      {localStorage.getItem("primaryLanguage") === "english" ? (
        <Menu.Item
          onClick={() => changeLanguage("spanish")}
          icon={
            <img
              src={require(`../../assets/flags/ES.png`).default}
              style={{ width: 15, height: 15, Radius: 20 }}
            />
          }
        >
          <b>Es</b>
        </Menu.Item>
      ) : (
        <Menu.Item
          onClick={() => changeLanguage("english")}
          icon={
            <img
              src={require(`../../assets/flags/US.png`).default}
              style={{ width: 15, height: 15, Radius: 20 }}
            />
          }
        >
          <b>Eng</b>
        </Menu.Item>
      )}
    </Menu>
  );
  const changeLanguage = (lang) => {
    localStorage.setItem("primaryLanguage", lang);
    window.location.reload();
  };
  const onLogout = () => {
    Remove_Data_Form_Provider_Reducers(props);
    push("/");
  };
  const provider = [
    {
      name: providerLabels.allOrders,
      id: 7,
      sub: [],
      route: "/all-orders",
      collapse: false,
    },
    {
      name: providerLabels.assignedOrders,
      id: 2,
      sub: [],
      route: "/order-assignation",
      collapse: false,
    },

    {
      name: providerLabels.newOrdersToBeAssigned,
      id: 1,
      sub: [],
      route: "/dashboard",
    },
    {
      name: providerLabels.myMessage,
      id: 3,
      sub: [],
      route: "/my-message",
      collapse: false,
    },
    {
      name: providerLabels.additionalBudgets,
      id: 4,
      route: "/additional-budget",
      sub: [],
      collapse: false,
    },
    {
      name: providerLabels.profile,
      id: 5,
      route: "/profile",
      sub: [],
      collapse: false,
    },
    {
      name: providerLabels.workers,
      id: 6,
      route: "/worker-of-service-provider",
      sub: [],
      collapse: false,
    },
    {
      name: providerLabels.payments,
      id: 8,
      route: "/incomes-payments-bills",
      sub: [],
      collapse: false,
    },
  ];
  const worker = [
    { name: providerLabels.orders, id: 1, sub: [], route: "/worker/dashboard" },
    {
      name: providerLabels.myMessage,
      id: 3,
      sub: [],
      route: "/worker/my-message",
      collapse: false,
    },
    {
      name: providerLabels.additionalBudgets,
      id: 4,
      route: "/worker/additional-budget",
      sub: [],
      collapse: false,
    },
    {
      name: providerLabels.profile,
      id: 5,
      route: "/worker/profile",
      sub: [],
      collapse: false,
    },
  ];
  const [menus, setMenus] = React.useState(
    localStorage.getItem("type") == "service_provider" ? provider : worker
  );

  const func = (a, b, bool) => {
    const array = menus?.map((z, x) => {
      if (x === b) {
        return {
          ...z,
          collapse: bool,
        };
      } else {
        return {
          ...z,
        };
      }
    });
    setMenus(array);
  };
  const menu = (
    <Menu className="menu-class custom-dropdown-styling">
      {menus.length
        ? menus?.map((t, i) => {
            if (t.sub.length) {
              return (
                <div
                  className="px-3 ant-dropdown-menu-title-content cursor-pointer"
                  key={t.name + i}
                  onClick={() => func(t, i, !t.collapse)}
                >
                  <div className="flex items-center">
                    {t.collapse ? (
                      <FaAngleDown
                        size={15}
                        className="mr-4"
                        color={"#5ab3f0"}
                      />
                    ) : (
                      <FaAngleRight size={15} className="mr-4" />
                    )}
                    {t.name}
                  </div>
                  {t.collapse && t.sub?.length
                    ? t.sub.map((a, b) => {
                        return (
                          <Menu.Item
                            className={
                              location.pathname === a.route
                                ? "activeMenuStyle ml-10 "
                                : "ml-10"
                            }
                            onClick={() => actionRoute(a.route)}
                          >
                            <div
                              className={
                                location.pathname === a.route
                                  ? "flex items-center font-black"
                                  : "flex items-center "
                              }
                            >
                              <FaAngleRight size={15} className="mr-4" />
                              {a.name}
                            </div>
                          </Menu.Item>
                        );
                      })
                    : null}
                </div>
              );
            } else {
              return (
                <Menu.Item
                  key={t.name + i}
                  onClick={() => actionRoute(t.route)}
                  className={
                    location.pathname === t.route ? "activeMenuStyle  " : ""
                  }
                >
                  <div
                    className={
                      location.pathname === t.route
                        ? "flex items-center font-black  "
                        : "flex items-center"
                    }
                  >
                    <FaAngleRight size={15} className="mr-4" />
                    {t.name}
                    {t?.count !== undefined && t?.count > 0 ? (
                      <span className="ml-5 font-normal text-xs text-red-500 p-2">
                        {`( ${t?.count} )`}
                      </span>
                    ) : null}
                  </div>
                </Menu.Item>
              );
            }
          })
        : null}
      <Menu.Item onClick={() => onLogout()}>
        {/* <Link to={/}> */}
        <div className="flex items-center">
          <FaAngleRight size={15} className="mr-4" />
          {providerLabels.logOut}
        </div>
        {/* </Link> */}
      </Menu.Item>
    </Menu>
  );
  const sideBarBadgeCounterFunction = () => {
    const a = assignedOrders?.filter((item) =>
      ["pending", "dispute", "disputed", "cancelled"]?.includes(item?.status)
    )?.length;
    const b = unAssignedOrders?.length;
    const c = serviceProviderMessages
      ?.map((item) => item["messageCount"])
      ?.reduce((a, b) => a + b, 0);
    const d = additionalBudget?.filter((item) =>
      ["pending", "accepted", "declined"]?.includes(item?.status)
    )?.length;
    const e = allOrders?.length;
    return a + b + c + d + e;
  };
  return (
    <header className="grid grid-flow-row auto-rows-max">
      <nav className="">
        <div className="nav-center">
          <div className="nav-header">
            <Dropdown
              overlay={menu}
              overlayClassName={"nav-drop-down-custom"}
              overlayStyle={{ top: 75, minWidth: "350px" }}
              trigger={["click"]}
              onVisibleChange={(e) => setCollapse(e)}
              arrow={false}
            >
              <a className="ant-dropdown-link" style={{ minWidth: 40 }}>
                <Badge count={sideBarBadgeCounterFunction() || 0}>
                  <img
                    className=""
                    src={collapse ? Images.cross.default : Images.menu.default}
                  />
                </Badge>
              </a>
            </Dropdown>
            <Link to="/" className="ml-14">
              <img width={"160px"} src={Images.logo.default} />
            </Link>
          </div>
          <div className="flex items-center	">
            <div className="mr-5 text-xs">
              <Dropdown
                overlay={langMenu}
                placement="bottomRight"
                className=""
                arrow
              >
                <Button
                  type="text"
                  icon={
                    <img
                      src={
                        require(`../../assets/flags/${
                          localStorage.getItem("primaryLanguage") === "english"
                            ? "US"
                            : "ES"
                        }.png`).default
                      }
                      style={{ width: 15, height: 15, Radius: 20 }}
                    />
                  }
                >
                  <b>
                    {localStorage.getItem("primaryLanguage") === "english"
                      ? "Eng"
                      : "Es"}
                  </b>
                </Button>
              </Dropdown>
            </div>

            <div className="mr-5">
              <Link
                to={
                  localStorage.getItem("type") == "service_provider"
                    ? "/profile"
                    : "/worker/profile"
                }
              >
                <span className="capitalize">
                  {props.providerDetail.admin.name}
                </span>
              </Link>
            </div>
            <div>
              <Link
                to={
                  localStorage.getItem("type") == "service_provider"
                    ? "/profile"
                    : "/worker/profile"
                }
              >
                <img
                  className="w-10 h-10 rounded-3xl"
                  src={props.providerDetail.admin.avatar}
                  style={{ border: "1px solid #46BCFF" }}
                />
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

const mapStateToProps = (state) => {
  return {
    showWorkers: state.workerList.workers,
    providerDetail: state.providerLoginDetail.loggedUserDetailOfProvider,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
    getWorkers: (props) => {
      dispatch(FetchWorkersAction(props));
    },
    removeWorkerList: () => {
      dispatch(DeleteWorkerAtLogoutTime());
    },
    setProviderLogout: () => {
      dispatch(ProviderLogoutAction());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubAdminHeader);
