import { Button, Space, Tag } from "antd";
import React from "react";
import { AiFillStar } from "react-icons/ai";
import TableComponent from "../../components/table";
import { Images } from "../../constant/Images";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";

const columns = [
  {
    align: "center",
    title: "No",
    dataIndex: "no",
    key: "no",
    width: 50,
    render: (text) => <a>{text}</a>,
  },
  {
    align: "center",
    title: "Name",
    dataIndex: "fullname",
    key: "fullname",
    width: 200,
  },
  {
    align: "center",
    title: "Rating",
    key: "ratingGiven",
    dataIndex: "ratingGiven",
    width: 100,
    render: (text, index) => (
      <div className="flex items-center justify-center">
        <AiFillStar color={"#FFC107"} style={{ marginRight: 5 }} />
        <label>{text.ratting}</label>
      </div>
    ),
  },
  {
    align: "center",
    title: "Possible relevent times",
    dataIndex: "prt",
    key: "prt",
    width: 160,
  },
  {
    align: "center",
    title: "Completed Bookings",
    key: "completedBooking",
    dataIndex: "completedBooking",
    width: 140,
  },
  {
    align: "center",
    title: "Open Bookings",
    dataIndex: "openbooking",
    key: "openbooking",
    width: 100,
  },
];

const data = [
  {
    no: "1",
    fullname: "John Brown",
    ratingGiven: { ratting: 4.3 },
    prt: "None",
    completedBooking: "23",
    openbooking: "23",
  },
  {
    no: "2",
    fullname: "John Brown",
    ratingGiven: { ratting: 4.3 },
    prt: "None",
    completedBooking: "23",
    openbooking: "23",
  },
  {
    no: "3",
    fullname: "John Brown",
    ratingGiven: { ratting: 4.3 },
    prt: "None",
    completedBooking: "23",
    openbooking: "23",
  },
];

export default function ServiceProviderDetail(params) {
  const { goBack } = useHistory();
  return (
    <section className="">
      <div className="flex justify-between mt-20 ml-5 mb-10">
        <div className="flex self-center justify-center items-center sub-h3">
          <div onClick={() => goBack()} className="flex text-gray-400 items-center cursor-pointer">
            <RiArrowDropLeftLine />
            <span>Back</span>
          </div>
          <h3 className="ml-10	">
            Service Provider Detail
          </h3>
        </div>
        <div className="lg:mr-28 flex">
          <Button type={"primary"} shape={"round"} className="icon-button mx-1">
            Download Report
          </Button>
        </div>
      </div>
      <label className="ml-4 sm:ml-4 lg:ml-32 mt-32">
        <b>Workers of Service Provider</b>
      </label>
      <div className="mt-5 flex justify-between contaier mx-auto items-center lg:ml-28">
        <TableComponent columns={columns} data={data} pagination={{ defaultPageSize: 6 ,showSizeChanger:true }} scrolly="500px"  />
      </div>
    </section>
  );
}
