export const flags = {
  AC: require('./AC.png'),
  AD: require('./AD.png'),
  AE: require('./AE.png'),
  AF: require('./AF.png'),
  AG: require('./AG.png'),
  AI: require('./AI.png'),
  AL: require('./AL.png'),
  AM: require('./AM.png'),
  AO: require('./AO.png'),
  AR: require('./AR.png'),
  AS: require('./AS.png'),
  AT: require('./AT.png'),
  AU: require('./AU.png'),
  AW: require('./AW.png'),
  AX: require('./AX.png'),
  AZ: require('./AZ.png'),
  BA: require('./BA.png'),
  BB: require('./BB.png'),
  BD: require('./BD.png'),
  BE: require('./BE.png'),
  BF: require('./BF.png'),
  BG: require('./BG.png'),
  BH: require('./BH.png'),
  BI: require('./BI.png'),
  BJ: require('./BJ.png'),
  BL: require('./BL.png'),
  BM: require('./BM.png'),
  BN: require('./BN.png'),
  BO: require('./BO.png'),
  BR: require('./BR.png'),
  BS: require('./BS.png'),
  BT: require('./BT.png'),
  BV: require('./BV.png'),
  BW: require('./BW.png'),
  BY: require('./BY.png'),
  BZ: require('./BZ.png'),
  CA: require('./CA.png'),
  CC: require('./CC.png'),
  CD: require('./CD.png'),
  CF: require('./CF.png'),
  CG: require('./CG.png'),
  CH: require('./CH.png'),
  CI: require('./CI.png'),
  CK: require('./CK.png'),
  CL: require('./CL.png'),
  CM: require('./CM.png'),
  CN: require('./CN.png'),
  CO: require('./CO.png'),
  CR: require('./CR.png'),
  CU: require('./CU.png'),
  CV: require('./CV.png'),
  CW: require('./CW.png'),
  CX: require('./CX.png'),
  CY: require('./CY.png'),
  CZ: require('./CZ.png'),
  DE: require('./DE.png'),
  DJ: require('./DJ.png'),
  DK: require('./DK.png'),
  DM: require('./DM.png'),
  DO: require('./DO.png'),
  DZ: require('./DZ.png'),
  EC: require('./EC.png'),
  EE: require('./EE.png'),
  EG: require('./EG.png'),
  ER: require('./ER.png'),
  ES: require('./ES.png'),
  ET: require('./ET.png'),
  EU: require('./EU.png'),
  FI: require('./FI.png'),
  FJ: require('./FJ.png'),
  FK: require('./FK.png'),
  FM: require('./FM.png'),
  FO: require('./FO.png'),
  FR: require('./FR.png'),
  GA: require('./GA.png'),
  GB: require('./GB.png'),
  GD: require('./GD.png'),
  GE: require('./GE.png'),
  GF: require('./GF.png'),
  GG: require('./GG.png'),
  GH: require('./GH.png'),
  GI: require('./GI.png'),
  GL: require('./GL.png'),
  GM: require('./GM.png'),
  GN: require('./GN.png'),
  GP: require('./GP.png'),
  GQ: require('./GQ.png'),
  GR: require('./GR.png'),
  GS: require('./GS.png'),
  GT: require('./GT.png'),
  GU: require('./GU.png'),
  GW: require('./GW.png'),
  GY: require('./GY.png'),
  HK: require('./HK.png'),
  HM: require('./HM.png'),
  HN: require('./HN.png'),
  HR: require('./HR.png'),
  HT: require('./HT.png'),
  HU: require('./HU.png'),
  ID: require('./ID.png'),
  IE: require('./IE.png'),
  IL: require('./IL.png'),
  IM: require('./IM.png'),
  IN: require('./IN.png'),
  IO: require('./IO.png'),
  IQ: require('./IQ.png'),
  IR: require('./IR.png'),
  IS: require('./IS.png'),
  IT: require('./IT.png'),
  JE: require('./JE.png'),
  JM: require('./JM.png'),
  JO: require('./JO.png'),
  JP: require('./JP.png'),
  KE: require('./KE.png'),
  KG: require('./KG.png'),
  KH: require('./KH.png'),
  KI: require('./KI.png'),
  KM: require('./KM.png'),
  KN: require('./KN.png'),
  KP: require('./KP.png'),
  KR: require('./KR.png'),
  KW: require('./KW.png'),
  KY: require('./KY.png'),
  KZ: require('./KZ.png'),
  LA: require('./LA.png'),
  LB: require('./LB.png'),
  LC: require('./LC.png'),
  LI: require('./LI.png'),
  LK: require('./LK.png'),
  LR: require('./LR.png'),
  LS: require('./LS.png'),
  LT: require('./LT.png'),
  LU: require('./LU.png'),
  LV: require('./LV.png'),
  LY: require('./LY.png'),
  MA: require('./MA.png'),
  MC: require('./MC.png'),
  MD: require('./MD.png'),
  ME: require('./ME.png'),
  MF: require('./MF.png'),
  MG: require('./MG.png'),
  MH: require('./MH.png'),
  MK: require('./MK.png'),
  ML: require('./ML.png'),
  MM: require('./MM.png'),
  MN: require('./MN.png'),
  MO: require('./MO.png'),
  MP: require('./MP.png'),
  MQ: require('./MQ.png'),
  MR: require('./MR.png'),
  MS: require('./MS.png'),
  MT: require('./MT.png'),
  MU: require('./MU.png'),
  MV: require('./MV.png'),
  MW: require('./MW.png'),
  MX: require('./MX.png'),
  MY: require('./MY.png'),
  MZ: require('./MZ.png'),
  NA: require('./NA.png'),
  NC: require('./NC.png'),
  NE: require('./NE.png'),
  NF: require('./NF.png'),
  NG: require('./NG.png'),
  NI: require('./NI.png'),
  NL: require('./NL.png'),
  NO: require('./NO.png'),
  NP: require('./NP.png'),
  NR: require('./NR.png'),
  NU: require('./NU.png'),
  NZ: require('./NZ.png'),
  OM: require('./OM.png'),
  PA: require('./PA.png'),
  PE: require('./PE.png'),
  PF: require('./PF.png'),
  PG: require('./PG.png'),
  PH: require('./PH.png'),
  PK: require('./PK.png'),
  PL: require('./PL.png'),
  PM: require('./PM.png'),
  PN: require('./PN.png'),
  PR: require('./PR.png'),
  PS: require('./PS.png'),
  PT: require('./PT.png'),
  PW: require('./PW.png'),
  PY: require('./PY.png'),
  QA: require('./QA.png'),
  RE: require('./RE.png'),
  RO: require('./RO.png'),
  RS: require('./RS.png'),
  RU: require('./RU.png'),
  RW: require('./RW.png'),
  SA: require('./SA.png'),
  SB: require('./SB.png'),
  SC: require('./SC.png'),
  SD: require('./SD.png'),
  SE: require('./SE.png'),
  SG: require('./SG.png'),
  SH: require('./SH.png'),
  SI: require('./SI.png'),
  SJ: require('./SJ.png'),
  SK: require('./SK.png'),
  SL: require('./SL.png'),
  SM: require('./SM.png'),
  SN: require('./SN.png'),
  SO: require('./SO.png'),
  SR: require('./SR.png'),
  SS: require('./SS.png'),
  ST: require('./ST.png'),
  SV: require('./SV.png'),
  SX: require('./SX.png'),
  SY: require('./SY.png'),
  SZ: require('./SZ.png'),
  TC: require('./TC.png'),
  TD: require('./TD.png'),
  TF: require('./TF.png'),
  TG: require('./TG.png'),
  TH: require('./TH.png'),
  TJ: require('./TJ.png'),
  TK: require('./TK.png'),
  TL: require('./TL.png'),
  TM: require('./TM.png'),
  TN: require('./TN.png'),
  TO: require('./TO.png'),
  TR: require('./TR.png'),
  TT: require('./TT.png'),
  TV: require('./TV.png'),
  TW: require('./TW.png'),
  TZ: require('./TZ.png'),
  UA: require('./UA.png'),
  UG: require('./UG.png'),
  UM: require('./UM.png'),
  US: require('./US.png'),
  UY: require('./UY.png'),
  UZ: require('./UZ.png'),
  VA: require('./VA.png'),
  VC: require('./VC.png'),
  VE: require('./VE.png'),
  VG: require('./VG.png'),
  VI: require('./VI.png'),
  VN: require('./VN.png'),
  VU: require('./VU.png'),
  WF: require('./WF.png'),
  WS: require('./WS.png'),
  XK: require('./XK.png'),
  YE: require('./YE.png'),
  YT: require('./YT.png'),
  ZA: require('./ZA.png'),
  ZM: require('./ZM.png'),
  ZW: require('./ZW.png'),
};
