import { Collapse, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { AiOutlinePlus } from "react-icons/ai";
import { Link, useHistory } from "react-router-dom";
import { Button } from "antd";
import { ModalComponent } from "../../components";
import InputComponent from "../../components/InputComponent";
import {
  AddFaqMethod,
  DeleteFaqMethod,
  EditFaqMethod,
  GetFaqsMethod,
} from "../../config/api";
import ButtonComponent from "../../components/ButtonComponent";
import { connect } from "react-redux";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { DeleteServicesAtLogoutTime } from "../../Redux/actions/fetchServicesAction";
import { DeleteBranchesAtLogoutTime } from "../../Redux/actions/fetchBranchesAction";
import { DeleteServiceAreasAtLogoutTime } from "../../Redux/actions/fetchServiceAreaAction";
import {
  DeleteProvidersAtLogoutTime,
  FetchProvidersAction,
} from "../../Redux/actions/fetchProvidersAction";
import { DeleteBeneficiariesAtLogoutTime } from "../../Redux/actions/fetchBeneficiariesAction";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";
import { Errors, Success } from "../../components/antdToast";
import { Remove_Data_Form_Reducers } from "../../components/dataRemoveOnLogout";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { adminLabels } from "../../config/label/adminLabels";

const { Panel } = Collapse;

const Faqs = (props) => {
  const currentSelectedLangue = localStorage.getItem("primaryLanguage");

  const [openModal, setOpenModal] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [questionSp, setQuestionSp] = useState("");
  const [answerSp, setAnswerSp] = useState("");
  const [faqs, setFaqs] = useState([]);
  const [isFieldError, setIsFieldError] = useState(false);
  const { goBack, push } = useHistory();
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [faqId, setFaqId] = useState("");

  useEffect(() => {
    getFaqs();
  }, []);

  const getFaqs = () => {
    GetFaqsMethod(
      (success) => {
        if (success?.status === 200) {
          props.setLoaderState(false);
          setFaqs(success?.data?.response?.detail);
        }
      },
      (fail) => {
        if (fail !== null) {
          if (fail?.response?.status === 409) {
            Errors(fail?.response?.data?.error?.message);
            props.setLoaderState(false);
            setIsFieldError(false);
          } else if (fail?.response?.status === 401) {
            props.setLoaderState(false);
            Remove_Data_Form_Reducers(props);
            push("/admin");
          }
        }
      }
    );
  };
  const createFaqs = () => {
    let obj = {
      question,
      answer,
      question_sp: questionSp,
      answer_sp: answerSp,
    };
    if (
      question?.trim()?.length !== 0 &&
      questionSp?.trim()?.length !== 0 &&
      answer?.trim()?.length !== 0 &&
      answerSp?.trim()?.length !== 0
    ) {
      props.setLoaderState(true);
      AddFaqMethod(
        obj,
        (success) => {
          if (success?.status === 200) {
            Success(success?.data?.response?.message);
            props.setLoaderState(false);
            setQuestion("");
            setQuestionSp("");
            setAnswer("");
            setAnswerSp("");
            getFaqs();
            setOpenModal(false);
          }
        },
        (fail) => {
          if (fail !== null) {
            if (fail?.response?.status === 409) {
              Errors(fail?.response?.data?.error?.message);
              props.setLoaderState(false);
              setIsFieldError(false);
            } else if (fail?.response?.status === 401) {
              props.setLoaderState(false);
              Remove_Data_Form_Reducers(props);
              push("/admin");
            }
          }
        }
      );
    } else {
      setIsFieldError(true);
    }
  };

  const deleteFaqMethod = () => {
    if (faqId !== "") {
      props.setLoaderState(true);
      DeleteFaqMethod(
        faqId,
        (success) => {
          if (success?.status === 200) {
            Success(success?.data?.response?.message);
            props.setLoaderState(false);
            getFaqs();
            setDeleteModal(false);
          }
        },
        (fail) => {
          if (fail !== null) {
            if (fail?.response?.status === 409) {
              Errors(fail?.response?.data?.error?.message);
              props.setLoaderState(false);
              setIsFieldError(false);
            } else if (fail?.response?.status === 401) {
              props.setLoaderState(false);
              Remove_Data_Form_Reducers(props);
              push("/admin");
            } else {
              props.setLoaderState(false);
            }
          }
        }
      );
    }
  };

  const getDataForUpdate = (data) => {
    setQuestion(data.question);
    setAnswer(data.answer);
    setQuestionSp(data.question_sp)
    setAnswerSp(data.answer_sp)

    setFaqId(data.faq_id);


  };

  const UpdateFaqMethod = () => {
    let obj = {
      faq_id: faqId,
      answer,
      question,
      question_sp: questionSp,
      answer_sp: answerSp,
    };
    if (
      question?.trim()?.length !== 0 &&
      questionSp?.trim()?.length !== 0 &&
      answer?.trim()?.length !== 0 &&
      answerSp?.trim()?.length !== 0 &&
      faqId !== ""
    ) {
      props.setLoaderState(true);
      EditFaqMethod(
        obj,
        (success) => {
          if (success?.status === 200) {
            Success(success?.data?.response?.message);
            props.setLoaderState(false);
            setQuestion("");
            setQuestionSp("");
            setAnswer("");
            setAnswerSp("");
            getFaqs();
            setEditModal(false);
            setFaqId("");
          }
        },
        (fail) => {
          if (fail !== null) {
            if (fail?.response?.status === 409) {
              Errors(fail?.response?.data?.error?.message);
              props.setLoaderState(false);
              setIsFieldError(false);
            } else if (fail?.response?.status === 401) {
              props.setLoaderState(false);
              Remove_Data_Form_Reducers(props);
              push("/admin");
            }
          }
        }
      );
    } else {
      setIsFieldError(true);
    }
  };

  return (
    <section className="mb-48 mt-20 ">
      <div className="flex justify-between mt-20 ">
        <div className="pl-5 flex self-center justify-center items-center sub-h3">
          <div
            onClick={() => goBack()}
            className="flex text-gray-400 items-center cursor-pointer"
          >
            <RiArrowDropLeftLine />
            <span>{adminLabels.back}</span>
          </div>
          <h3 className="ml-10	">{adminLabels.frequentlyAskedQuestion}</h3>
        </div>
        <div className="mr-1 sm:mr-1 lg:mr-20">
          <Button
            shape={"round"}
            className="icon-button "
            icon={<AiOutlinePlus color="#5ab3f0" />}
            onClick={() => setOpenModal(true)}
          >
            {adminLabels.addNew}
          </Button>
        </div>
      </div>
      <div className="mt-10 container mx-auto px-2 sm:px-2 lg:px-20">
        <div className="bg-white rounded-2xl">
          <Collapse
            defaultActiveKey={["0"]}
            className=""
            bordered={false}
            expandIconPosition={"right"}
            ghost
            accordion={true}
          >
            {faqs?.map((t, i) => {
              return (
                <>
                  {i !== 0 && <Divider className="borderBottomDivider" />}
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        display: "flex",
                        position: " absolute",
                        justifyContent: "space-between",
                        alignItems: " center",
                        right: 10,
                        top: "20px",
                        width: 50,
                      }}
                    >
                      <AiOutlineEdit
                        className="cursor-pointer	"
                        onClick={() => {
                          setEditModal(true);
                          getDataForUpdate(t);
                        }}
                      />
                      <AiOutlineDelete
                        className="cursor-pointer	"
                        onClick={() => {
                          setDeleteModal(true);
                          setFaqId(t.faq_id);
                        }}
                      />
                    </div>
                  </div>

                  <Panel
                    header={
                      <strong>
                        {currentSelectedLangue === "english"
                          ? t?.question
                          : t?.question_sp }
                      </strong>
                    }
                    key={`${i + 1}`}
                    style={{ width: "93%" }}
                  >
                    <p>
                      {currentSelectedLangue === "english"
                        ? t?.answer
                        : t?.answer_sp}
                    </p>
                  </Panel>
                </>
              );
            })}
          </Collapse>
        </div>
      </div>
      <ModalComponent
        modalTitle={adminLabels.addQuestionAndAnswer}
        closeModal={() => setOpenModal(false)}
        filterModal={openModal}
        btn={false}
      >
        <div className="w-full col-span-12 rounded-full lg:col-span-3 sm:col-span-6 md:col-span-6">
          <InputComponent
            placeholder={adminLabels.questionEng}
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
          {isFieldError === true && question?.trim()?.length === 0 && (
            <p className="text-red-500">{adminLabels.pleaseFillTheField}</p>
          )}
          <InputComponent
            placeholder={adminLabels.questionEsp}
            value={questionSp}
            onChange={(e) => setQuestionSp(e.target.value)}
          />
          {isFieldError === true && questionSp?.trim()?.length === 0 && (
            <p className="text-red-500">{adminLabels.pleaseFillTheField}</p>
          )}
        </div>
        <div className=" col-span-12 lg:col-span-6 sm:col-span-12 md:col-span-12 ">
          <form>
            <textarea
              className=" p-2 pl-3 rounded-2xl w-full text-area-outline border-none"
              placeholder={adminLabels.answerEng}
              rows="5"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            ></textarea>
          </form>
          {isFieldError === true && answer?.trim()?.length === 0 && (
            <p className="text-red-500">{adminLabels.pleaseFillTheField}</p>
          )}
          <form>
            <textarea
              className=" p-2 pl-3 rounded-2xl w-full text-area-outline border-none"
              placeholder={adminLabels.answerEsp}
              rows="5"
              value={answerSp}
              onChange={(e) => setAnswerSp(e.target.value)}
            ></textarea>
          </form>
          {isFieldError === true && answerSp?.trim()?.length === 0 && (
            <p className="text-red-500">{adminLabels.pleaseFillTheField}</p>
          )}
        </div>
        <ButtonComponent
          title={adminLabels.save}
          onClick={createFaqs}
          disabled={props.loader === true}
          loader={props.loader === true ? true : false}
        />
      </ModalComponent>
      <ModalComponent
        modalTitle={adminLabels.confirmation}
        closeModal={() => setDeleteModal(false)}
        filterModal={deleteModal}
        btn={false}
      >
        <div className="w-full col-span-12 rounded-full lg:col-span-3 sm:col-span-6 md:col-span-6 text-center">
          <p>{adminLabels.areYouSureWantDeleteThisFaq}</p>
        </div>
        <div className="flex justify-around items-center	gap-4">
          <ButtonComponent
            title={adminLabels.cancel}
            type={true}
            onClick={() => setDeleteModal(false)}
          />

          <ButtonComponent
            title={adminLabels.ok}
            disabled={props.loader === true}
            loader={props.loader === true ? true : false}
            onClick={deleteFaqMethod}
          />
        </div>
      </ModalComponent>
      <ModalComponent
        modalTitle={adminLabels.editQuestionAndAnswer}
        closeModal={() => setEditModal(false)}
        filterModal={editModal}
        btn={false}
      >
        <div className="w-full col-span-12 rounded-full lg:col-span-3 sm:col-span-6 md:col-span-6">
          <InputComponent
            placeholder={adminLabels.questionEng}
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
          {isFieldError === true && question?.trim()?.length === 0 && (
            <p className="text-red-500">{adminLabels.pleaseFillTheField}</p>
          )}
          <InputComponent
            placeholder={adminLabels.questionEsp}
            value={questionSp}
            onChange={(e) => setQuestionSp(e.target.value)}
          />
          {isFieldError === true && questionSp?.trim()?.length === 0 && (
            <p className="text-red-500">{adminLabels.pleaseFillTheField}</p>
          )}
        </div>
        <div className=" col-span-12 lg:col-span-6 sm:col-span-12 md:col-span-12 ">
          <form>
            <textarea
              className=" p-2 pl-3 rounded-2xl w-full text-area-outline border-none"
              placeholder={adminLabels.answerEng}
              rows="5"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            ></textarea>
          </form>
          {isFieldError === true && answer?.trim()?.length === 0 && (
            <p className="text-red-500">{adminLabels.pleaseFillTheField}</p>
          )}
          <form>
            <textarea
              className=" p-2 pl-3 rounded-2xl w-full text-area-outline border-none"
              placeholder={adminLabels.answerEsp}
              rows="5"
              value={answerSp}
              onChange={(e) => setAnswerSp(e.target.value)}
            ></textarea>
          </form>
          {isFieldError === true && answerSp?.trim()?.length === 0 && (
            <p className="text-red-500">{adminLabels.pleaseFillTheField}</p>
          )}
        </div>
        <ButtonComponent
          title={adminLabels.save}
          onClick={UpdateFaqMethod}
          disabled={props.loader === true}
          loader={props.loader === true ? true : false}
        />
      </ModalComponent>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.loaderToggle.loaderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLogoutUser: (data) => {
      dispatch(LogoutUserAction(data));
    },
    getProvidersList: (props) => {
      dispatch(FetchProvidersAction(props));
    },
    removeBeneficiaries: () => {
      dispatch(DeleteBeneficiariesAtLogoutTime());
    },
    removeProviders: () => {
      dispatch(DeleteProvidersAtLogoutTime());
    },
    removeServiceAreas: () => {
      dispatch(DeleteServiceAreasAtLogoutTime());
    },
    removeBranches: () => {
      dispatch(DeleteBranchesAtLogoutTime());
    },
    removeServices: () => {
      dispatch(DeleteServicesAtLogoutTime());
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Faqs);
