import React, { useState } from "react";
import { Switch } from "antd";
import { Menu, Dropdown, Button, Space } from "antd";

import { AiFillStar } from "react-icons/ai";
import { Images } from "../constant/Images";
import moment from "moment";

const menu = (
  <Menu>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.antgroup.com"
      >
        1st menu item
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.aliyun.com"
      >
        2nd menu item
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.luohanacademy.com"
      >
        3rd menu item
      </a>
    </Menu.Item>
  </Menu>
);
function TableTwo({
  names,
  job,
  table = [],
  dividerShow = false,
  providerDetail = "",
  orderDetail = "",
  clientDetail = "",
}) {
  return (
    <div className="bg-white container rounded-lg ">
      <div style={{ display: "flex" }} className="p-5 pb-0">
        {clientDetail !== "" && (
          <img src={Images.userProfilePlaceholder.default} style={{ height: 48 }} />
        )}
        {providerDetail !== "" && (
          <img src={providerDetail?.admin?.avatar} style={{ height: 48 }} />
        )}
        {clientDetail !== "" && (
          <div className="pl-2">
            <strong className=" ">
              {clientDetail?.data?.[0]?.personal?.firstName +
                " " +
                clientDetail?.data?.[0]?.personal?.lastName}
            </strong>
            <p className="">Client</p>
          </div>
        )}

        {providerDetail !== "" && (
          <div className="pl-2">
            <strong className=" ">{providerDetail?.admin?.name}</strong>
            <p className="">{providerDetail?.admin?.position}</p>
          </div>
        )}
      </div>
      <div className="grid grid-cols-2 p-6 pb-3">
        {table.map((t, i) => {
          if (t.name === "Rating") {
            return (
              <div className="" style={{ position: "relative" }}>
                {dividerShow && t.id === 3 && (
                  <div
                    style={{
                      border: "1px solid gray",
                      opacity: 0.2,
                      marginBottom: "15px",
                    }}
                  />
                )}
                {dividerShow && t.id === 4 && (
                  <div
                    style={{
                      border: "1px solid gray",
                      opacity: 0.2,
                      marginBottom: "15px",
                    }}
                  />
                )}
                <div style={{ paddingLeft: 20 }}>
                  <b>{t.name}</b>
                  <p style={{ display: "flex" }}>
                    {" "}
                    <AiFillStar color="gold" />
                    {t.value}
                  </p>
                </div>
                {dividerShow && t.id === 3 && (
                  <div
                    style={{
                      border: "1px solid gray",
                      opacity: 0.2,
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                  />
                )}
                {dividerShow && t.id === 1 && (
                  <div
                    style={{
                      border: "1px solid gray",
                      opacity: 0.2,
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                  />
                )}
              </div>
            );
          } else if (t.name === "Status") {
            return (
              <div style={{ position: "relative" }}>
                {dividerShow && t.id === 4 && (
                  <div
                    style={{
                      border: "1px solid gray",
                      opacity: 0.2,
                      marginBottom: "15px",
                    }}
                  />
                )}
                {dividerShow && t.id === 5 && (
                  <div
                    style={{
                      border: "1px solid gray",
                      opacity: 0.2,
                      marginBottom: "15px",
                    }}
                  />
                )}
                <div style={{ paddingLeft: 20 }}>
                  <b>{t.name}</b>
                  <div className="flex space-x-2">
                    <p className="">{t.value}</p>
                    <p>{t.switch}</p>
                  </div>
                </div>
                {dividerShow && t.id === 3 && (
                  <div
                    style={{
                      border: "1px solid gray",
                      opacity: 0.2,
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                  />
                )}
                {dividerShow && t.id === 1 && (
                  <div
                    style={{
                      border: "1px solid gray",
                      opacity: 0.2,
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                  />
                )}
              </div>
            );
          } else 
            return (
              <div className="" style={{ position: "relative" }}>
                {dividerShow && t.id === 3 && (
                  <div
                    style={{
                      border: "1px solid gray",
                      opacity: 0.2,
                      marginBottom: "15px",
                    }}
                  />
                )}
                {dividerShow && t.id === 4 && (
                  <div
                    style={{
                      border: "1px solid gray",
                      opacity: 0.2,
                      marginBottom: "15px",
                    }}
                  />
                )}
                <div style={{ paddingLeft: 20 }}>
                  <b>{t.name}</b>
                  <p>{t.value}</p>
                </div>
                {dividerShow && t.id === 3 && (
                  <div
                    style={{
                      border: "1px solid gray",
                      opacity: 0.2,
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                  />
                )}
                {dividerShow && t.id === 1 && (
                  <div
                    style={{
                      border: "1px solid gray",
                      opacity: 0.2,
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                  />
                )}
              </div>
            );
          
        })}
        {providerDetail !== "" && (
          <>
            <div className="w-full  border-b-2 px-2">
              <h4>Phone Number</h4>
              <p>{providerDetail?.admin?.phone}</p>
            </div>
            <div className="w-full border-b-2 border-l-2 px-2">
              <h4>Service Name</h4>
              <p>{orderDetail?.service?.name}</p>
            </div>
            <div className="w-full  border-b-0 px-2 py-1">
              <h4>Email Address</h4>
              <p>{providerDetail?.admin?.email}</p>
            </div>
            <div className="w-full border-b-0 border-l-2 px-2 py-1">
              <h4>Rating</h4>
              <p>4.3</p>
            </div>
          </>
        )}

        {clientDetail !== "" && (
          <>
            <div className="w-full  border-b-2 px-2">
              <h4>Phone Number</h4>
              <p>
                {clientDetail?.data?.[0]?.personal?.countryCode +
                  " " +
                  clientDetail?.data?.[0]?.personal?.contactNo}
              </p>
            </div>
            <div className="w-full border-b-2 border-l-2 px-2">
              <h4>Order Date</h4>
              <p>{moment(orderDetail?.created_at).format("DD-MMM-YYYY")}</p>
            </div>
            <div className="w-full  border-b-0 px-2 py-1">
              <h4>Region</h4>
              <p>{clientDetail?.data?.[1].region?.state}</p>
            </div>
            <div className="w-full border-b-0 border-l-2 px-2 py-1">
              <h4>Address</h4>
              <p>{clientDetail?.data?.[0]?.personal?.address}</p>
            </div>
          </>
        )}

        <div className="thisisdropdown float-right mt-2">
          <Space direction="vertical">
            <Space wrap>
              {/* <Dropdown
                overlay={menu}
                placement="bottomRight"
                trigger={["click"]}
              >
                <Button>Multiple Profiles</Button>
              </Dropdown> */}
            </Space>
          </Space>
        </div>
      </div>
    </div>
  );
}

export default TableTwo;
