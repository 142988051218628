import { DatePicker, Row, Spin } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { connect } from "react-redux";
import Data from "../../components/Dashboard/Data";
import { GetAdminPanelDetails } from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { Images } from "../../constant/Images";
import {
  DeleteAppUsers,
  FetchAppUsersAction,
} from "../../Redux/actions/fetchAppUsersAction";
import { DeleteBeneficiariesAtLogoutTime } from "../../Redux/actions/fetchBeneficiariesAction";
import { DeleteBranchesAtLogoutTime } from "../../Redux/actions/fetchBranchesAction";
import {
  DeleteProvidersAtLogoutTime,
  FetchProvidersAction,
} from "../../Redux/actions/fetchProvidersAction";
import { DeleteServiceAreasAtLogoutTime } from "../../Redux/actions/fetchServiceAreaAction";
import {
  DeleteServiceForms,
  FetchServiceForms,
} from "../../Redux/actions/fetchServiceFormsAction";
import {
  DeleteServicesAtLogoutTime,
  FetchServicesAction,
} from "../../Redux/actions/fetchServicesAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";
import moment from "moment";
const { filter } = Images;
function Dashboard(props) {
  const [filterModal, setFilterModal] = React.useState(false);
  const [loading, setLoading] = useState(true);

  const [dashboarddata, setDashboardData] = useState([
    {
      name: adminLabels.budgetResponse,
      count: "0",
      route: "/admin/budget-response",
      boxId: "1",
      visible: true,
    },
    {
      name: adminLabels.usersRegistered,
      count: "0",
      route: "/admin/user-account-management",
      boxId: "2",
      visible: true,
    },
    {
      name: adminLabels.serviceProvider,
      total: adminLabels.adminOf,
      count: "0",
      route: "/admin/admin-service-provider",
      boxId: "3",
      visible: true,
    },
    {
      name: adminLabels.serviceProvider,
      total: adminLabels.workerOf,
      count: "0",
      route: "/admin/worker-service-provider",
      boxId: "4",
      visible: true,
    },
    {
      name: adminLabels.bookRequests,
      count: "0",
      route: "/admin/booking-management",
      boxId: "5",
      visible: true,
    },
    {
      name: adminLabels.completedBookings,
      count: "0",
      route: "/admin/booking-management",
      boxId: "6",
      visible: true,
    },
    {
      name: adminLabels.openBookings,
      count: "0",
      route: "/admin/booking-management",
      boxId: "7",
      visible: true,
    },
    // { name: "Payment Release Requests", count: "0", route: "#" ,boxId :"8" },
    // { name: "Reject order by providers", count: "0", route: "#" ,boxId :"9"},
    {
      name: adminLabels.reassignedOrders,
      count: "0",
      route: "/admin/booking-management",
      boxId: "10",
      visible: true,
    },
    // { name: "Final reject order", count: "0", route: "#"  ,boxId :"11"},
    {
      name: adminLabels.revenue,
      count: "0",
      route: "/admin/my-earnings",
      boxId: "12",
      visible: true,
    },
  ]);

  const [startDate, setStartDate] = useState("2019-01-01");
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [subAdminRoles, setSubAdminRoles] = useState(
    props?.loginInfo?.admin?.roles
  );

  const onFilter = () => {
    setFilterModal(true);
  };
  useEffect(() => {
    if (props.loginInfo?.admin?.status === "sub_admin") {
      setDashboardData(
        dashboarddata?.filter(
          (item) => (item?.boxId !== "1") & (item?.boxId !== "12")
        )
      );
    }
    FetchDashboardData();

    props.getProviders(props);
    props.setServiceForms(props);
    props.getServicesList(props);
    props.getAppUsers(props);
  }, []);

  const checkDashboardBox = (menuId, boxId) => {
    setDashboardData(
      subAdminRoles?.some(
        (item) => (item?.menuId === menuId) & (item?.checked === false)
      ) === true
        ? dashboarddata?.map((dashBox) => {
            if (dashBox.boxId === boxId) {
              return {
                ...dashBox,
                visible: false,
              };
            } else {
              return dashBox;
            }
          })
        : dashboarddata?.filter(
            (item) => (item?.boxId !== "1") & (item?.boxId !== "12")
          )
    );
  };
  const FetchDashboardData = () => {
    GetAdminPanelDetails(
      startDate,
      endDate,
      (res) => {
        let item = res?.data?.response?.detail;
        dashboarddata.map((count) => {
          if (count.boxId === "1") {
            count.count = item.budgest_response;
          }
          if (count.boxId === "2") {
            count.count = item.users;
            count.visible =
              props.loginInfo?.admin?.status === "sub_admin"
                ? subAdminRoles?.some(
                    (item) => (item?.menuId === "2") & (item?.checked === true)
                  )
                : true;
          }
          if (count.boxId === "3") {
            count.count = item.service_providers;
            count.visible =
              props.loginInfo?.admin?.status === "sub_admin"
                ? subAdminRoles?.some(
                    (item) => (item?.menuId === "3") & (item?.checked === true)
                  )
                : true;
          }
          if (count.boxId === "4") {
            count.count = item.service_workers;
            count.visible =
              props.loginInfo?.admin?.status === "sub_admin"
                ? subAdminRoles?.some(
                    (item) => (item?.menuId === "4") & (item?.checked === true)
                  )
                : true;
          }
          if (count.boxId === "5") {
            count.count = item.booking_requests;
            count.visible =
              props.loginInfo?.admin?.status === "sub_admin"
                ? subAdminRoles?.some(
                    (item) => (item?.menuId === "6") & (item?.checked === true)
                  )
                : true;
          }
          if (count.boxId === "6") {
            count.count = item.booking_completed;
            count.visible =
              props.loginInfo?.admin?.status === "sub_admin"
                ? subAdminRoles?.some(
                    (item) => (item?.menuId === "6") & (item?.checked === true)
                  )
                : true;
          }
          if (count.boxId === "7") {
            count.count = item.open_completed;
            count.visible =
              props.loginInfo?.admin?.status === "sub_admin"
                ? subAdminRoles?.some(
                    (item) => (item?.menuId === "6") & (item?.checked === true)
                  )
                : true;
          }
          if (count.boxId === "Payment Release Requests") {
            // count.count = item.booking_completed;
          }
          if (count.boxId === "Reject order by providers") {
            // count.count = item.booking_completed;
          }

          if (count.boxId === "10") {
            count.count = item.reassign_order;
            count.visible =
              props.loginInfo?.admin?.status === "sub_admin"
                ? subAdminRoles?.some(
                    (item) => (item?.menuId === "6") & (item?.checked === true)
                  )
                : true;
          }
          if (count.boxId === "Final reject order") {
            // count.count = item.booking_completed;
          }
          if (count.boxId === "12") {
            count.count = item.total_revenue;
          }
        });
        setLoading(false);
      },
      (err) => {
      }
    );
  };
  const filterDashBoardDataByDate = () => {
    FetchDashboardData();
  };
  const onChangeStartDate = (date, dateString) => {
    setStartDate(dateString === null ? "2019-01-01" : dateString);
  };
  const onChangeEndDate = (date, dateString) => {
    setEndDate(dateString === null ? "2023-01-01" : dateString);
  };

  return (
    <section className="sm:px-0.5 md:px-0.5 lg:px-14">
      <Spin tip={adminLabels.loading} spinning={loading}>
        <div className="container dashboard-container mx-auto mt-20">
          <div className="flex justify-between items-center mx-4  md:mx-4  sm:mr-4 md:mr-4 lg:mr-2">
            <h3 className=" text-xs sm:text-sm lg:text-2xl">
              {adminLabels.welcomeToDashboard}
            </h3>
            <button
              className="flex justify-around items-center bg-white rounded-full py-2 px-7  w-24 sm:w-24 md:w-28 lg:w-32"
              onClick={() => onFilter()}
            >
              <img height="17" width="17" src={filter.default} />
              <span className="ml-2">{adminLabels.filter}</span>
            </button>
          </div>
          <div className="container">
            <Row
              gutter={24}
              className="dashboard-data dashboard-columns"
              style={{ marginLeft: 0, marginRight: 0 }}
            >
              {dashboarddata?.map((t, index) => {
                return t.visible === true ? (
                  props.loginInfo?.admin?.status === "sub_admin" ? (
                    t.boxId === "1" || t.boxId === "12" ? null : (
                      loading === false && (
                        <Data
                          key={index}
                          name={t?.name}
                          prize={t?.count}
                          route={t?.route}
                          total={t?.total}
                        />
                      )
                    )
                  ) : (
                    <Data
                      key={index}
                      name={t?.name}
                      prize={t?.count}
                      route={t?.route}
                      total={t?.total}
                    />
                  )
                ) : null;
              })}
            </Row>
          </div>
          <Modal
            visible={filterModal}
            width={400}
            footer={false}
            onCancel={() => setFilterModal(false)}
          >
            <div>
              <h4 className="font-bold text-2xl text-center text-gray-600	">
                {adminLabels.filterByDate}
              </h4>
            </div>
            <div className="flex justify-between items-center my-5 ">
              <DatePicker
                className="border-radius20 datePickerStyling"
                suffixIcon={<FaCalendarAlt color={"#5ab3f0"} />}
                onChange={onChangeStartDate}
              />
              <span>{adminLabels?.to}</span>
              <DatePicker
                className="border-radius20 datePickerStyling"
                suffixIcon={<FaCalendarAlt color={"#5ab3f0"} />}
                onChange={onChangeEndDate}
              />
            </div>

            <button
              className="w-full bg-primary rounded-full py-3 text-white"
              onClick={() => {
                setFilterModal(false);
                filterDashBoardDataByDate();
              }}
            >
              {adminLabels.apply}
            </button>
          </Modal>
        </div>
      </Spin>
    </section>
  );
}
const mapStateToProps = (state) => {
  return {
    loginInfo: state.loginDetail.loggedUserDetail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
    setLogoutUser: () => {
      dispatch(LogoutUserAction());
    },
    getProviders: (props) => {
      dispatch(FetchProvidersAction(props));
    },
    getServicesList: (props) => {
      dispatch(FetchServicesAction(props));
    },
    setServiceForms: (props) => {
      dispatch(FetchServiceForms(props));
    },
    getAppUsers: (props) => {
      dispatch(FetchAppUsersAction(props));
    },
    removeBeneficiaries: () => {
      dispatch(DeleteBeneficiariesAtLogoutTime());
    },
    removeProviders: () => {
      dispatch(DeleteProvidersAtLogoutTime());
    },
    removeServiceAreas: () => {
      dispatch(DeleteServiceAreasAtLogoutTime());
    },
    removeBranches: () => {
      dispatch(DeleteBranchesAtLogoutTime());
    },
    removeServices: () => {
      dispatch(DeleteServicesAtLogoutTime());
    },
    removeServiceForms: () => {
      dispatch(DeleteServiceForms());
    },
    removeAppUsers: () => {
      dispatch(DeleteAppUsers());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
