import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function ButtonComponent({
  title,
  onClick,
  className,
  type = false,
  height = "h-12",
  loader = false,
  disabled = false,
}) {
  return (
    <button
      onClick={onClick}
      className={`${
        type ? "bg-danger  text-white " : " bg-primary  text-white h-6"
      }  rounded-full rounded-lg ${height} w-full ${className}`}
      disabled={disabled}
    >
      {title} {loader === true ? <Spin indicator={antIcon} /> : ""}
    </button>
  );
}

export default ButtonComponent;
