import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiOutlineArrowDown } from "react-icons/ai";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { Errors } from "../../components/antdToast";
import TableComponent from "../../components/table";
import { GetMyEarnings } from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { europeanCurrencyFormatter } from "../../config/utils";

export default function MyEarnings(params) {
  const currentSelectedLangue = localStorage.getItem("primaryLanguage");

  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [ordersEarnings, setOrdersEarnings] = useState([]);
  const [additional_budgetEarnings, setAdditional_budgetEarnings] = useState(
    []
  );
  const [earningCardsData, setEarningCardsData] = useState({});
  useEffect(() => {
    fetchMyEarnings();
    document.body.style.overflow = "auto";
    return function cleanup() {
      document.body.style.overflow = "auto";
    };
  }, []);
  const fetchMyEarnings = () => {
    GetMyEarnings(
      (res) => {
        let orders = res.data.response.detail.orders.map((order, index) => {
          return {
            ...order,
            no: index + 1,
            product: order.order.service,
            orderId: order.order_id,
            date: moment(order.created_at).format("DD-MMM-YYYY HH:mm"),
            price: (Number(order.after_discount_amount) * 0.3),
            ammountServiceProvider: (
              Number(order.after_discount_amount) * 0.7 +
              Number(order.supplementary_fees)
            ),
            payment: order.charge_id,
            serviceAmount: Number(order.after_discount_amount),
            orderStatus: adminLabels?.[order.status],
            supplementaryAmount: Number(order.supplementary_fees),
          };
        });

        setOrdersEarnings(orders);
        let additional_budget = res.data.response.detail.additional_budget.map(
          (budget, index) => {
            return {
              ...budget,
              no: index + 1,
              product: budget.order.service,
              orderId: budget.order_id,
              date: moment(budget.created_at).format("DD-MMM-YYYY"),
              price: (Number(budget.amount) * 0.3),
              ammountServiceProvider: Math.ceil(
                parseInt(budget.amount) * 0.7 +
                  parseInt(budget.supplementary_fees)
              ),
              payment: budget.charge_id,
              serviceAmount: Number(budget.amount),
              orderStatus: budget.status,
              supplementaryAmount: Number(budget.supplementary_fees),
            };
          }
        );
        setAdditional_budgetEarnings(additional_budget);

        setLoading(false);

        let dataObj = {
          budget_supplementary_fees:
            res.data.response.detail.budget_supplementary_fees,
          order_supplementary_fees:
            res.data.response.detail.order_supplementary_fees,
          provider_budget_amount:
            res.data.response.detail.provider_budget_amount,
          provider_order_amount: res.data.response.detail.provider_order_amount,
          total_budget_amount: res.data.response.detail.total_budget_amount,
          total_order_amount: res.data.response.detail.total_order_amount,
        };
        setEarningCardsData(dataObj);
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  const { goBack } = useHistory();
  const changeIndex = (i) => {
    setIndex(i);
  };
  const columns = [
    {
      align: "center",
      title: adminLabels.no,
      dataIndex: "no",
      key: "no",
      width: 40,
      render: (text) => <a>{text}</a>,
    },
    {
      align: "center",
      title: adminLabels.serviceName,
      dataIndex: "product",
      key: "product",
      width: 190,

      render: (text, index) => (
        <div className={"flex items-center"}>
          <img
            src={text.avatar}
            style={{ width: 50, height: 45 }}
            alt="detail image"
          />
          <div className="pl-2 text-left">
            <label>
              <b>
                {
                  text?.[
                    currentSelectedLangue === "english" ? "name" : "name_sp"
                  ]
                }
              </b>
            </label>
          </div>
        </div>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.product?.[currentSelectedLangue === "english" ?"name" : "name_sp"]
          ?.toLowerCase()
          .includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.product?.[currentSelectedLangue === "english" ?"name" : "name_sp"]?.localeCompare(b?.product?.[currentSelectedLangue === "english" ?"name" : "name_sp"]),
    },
    {
      align: "center",
      title: adminLabels.orderId,
      dataIndex: "orderId",
      key: "orderId",
      width: 100,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.orderId.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.orderId?.localeCompare(b?.orderId),
    },
    {
      align: "center",
      title: adminLabels.orderDate,
      key: "date",
      dataIndex: "date",
      width: 100,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },
    {
      align: "center",
      title: adminLabels.payment,
      dataIndex: "payment",
      key: "payment",
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.payment.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.payment?.localeCompare(b?.payment),
    },
    // {
    //   align: "center",
    //   title: "Payment Via",
    //   dataIndex: "payment",
    //   key: "payment",
    //   width: 100,
    // },
    {
      align: "center",
      title: adminLabels.orderStatus,
      key: "orderStatus",
      width: 80,

      render: (text, index) => (
        <span style={{ color: "green", fontWeight: "bold" }}>
          {text.orderStatus}
        </span>
      ),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.orderStatus?.localeCompare(b?.orderStatus),
    },
    // {
    //   align: "center",
    //   title: "Courier",
    //   key: "courier",
    //   dataIndex: "courier",
    //   width: 120,
    // },
    {
      align: "center",
      title: `${
        index === 0 ? adminLabels.serviceAmount : adminLabels.additionalAmount
      }`,
      key: "serviceAmount",
      dataIndex: "serviceAmount",
      width: 90,
      render: (text) => (
        <div className="text-center flex justify-center">
          {text ? "€ " + europeanCurrencyFormatter(text) : ""}
        </div>
      ),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.serviceAmount - b.serviceAmount,
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: adminLabels.supplementaryAmount,
      key: "supplementaryAmount",
      dataIndex: "supplementaryAmount",
      width: 90,
      render: (text) => (
        <div className="text-center flex justify-center">
          {text ? "€ " + europeanCurrencyFormatter(text) : ""}
        </div>
      ),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.supplementaryAmount - b.supplementaryAmount,
      showSorterTooltip: false,
    },
    // {
    //   align: "center",
    //   title: "Additional Charges",
    //   key: "vat",
    //   dataIndex: "vat",
    //   width: 80,
    // },
    {
      align: "center",
      title: adminLabels.serviceProviderAmount,
      dataIndex: "ammountServiceProvider",
      key: "ammountServiceProvider",
      width: 90,
      render: (text) => (
        <div className="text-center flex justify-center">
          {text ? "€ " + europeanCurrencyFormatter(text) : ""}
        </div>
      ),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.ammountServiceProvider - b.ammountServiceProvider,
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: adminLabels.received,
      key: "price",
      dataIndex: "price",
      width: 90,
      render: (text) => (
        <div className="text-center flex justify-center">
          {text ? "€ " + europeanCurrencyFormatter(text) : ""}
        </div>
      ),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.price - b.price,
      showSorterTooltip: false,
    },
  ];
  return (
    <section className="">
      <Spin tip={adminLabels.loading} spinning={loading}>
        <div className="flex justify-start lg:justify-between mt-5  flex-wrap sm:flex-wrap lg:flex-nowrap ">
          <div className="pl-5 flex self-center justify-center items-center  sub-h3 mb-4 sm:mb-4 lg:mb-0">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{adminLabels.back}</span>
            </div>
            <h3 className="ml-10	">{adminLabels.myEarnings}</h3>
          </div>
          <div className="lg:mr-28 flex justify-center  space-x-6 flex-wrap  sm:flex-wrap lg:flex-nowrap ">
            {/* <Button shape={"circle"} className="icon-button p0 mt-3">
            <FiSearch size={15} />
          </Button> */}
            {index === 0 && (
              <div className="rounded-lg p-2 icon-button flex flex-col justufy-center item-center mx-1 bg-white mb-4 sm:mb-4 lg:mb-0">
                <span className="text-black font-black text-2xl">
                  € {europeanCurrencyFormatter(earningCardsData?.provider_budget_amount)}
                </span>
                <span className="text-color-primary font-bold text-xs capitalize">
                  {adminLabels.providerBudgetAmount}
                </span>
              </div>
            )}
            {index === 0 && (
              <div className="rounded-lg p-2 icon-button flex flex-col justufy-center item-center mx-1 bg-white mb-4 sm:mb-4 lg:mb-0">
                <span className="text-black font-black text-2xl">
                  € {europeanCurrencyFormatter(earningCardsData?.provider_order_amount)}
                </span>
                <span className="text-color-primary font-bold text-xs capitalize">
                  {adminLabels.providerOrderAmount}
                </span>
              </div>
            )}
            {index === 0 && (
              <div className="rounded-lg p-2 icon-button flex flex-col justufy-center item-center mx-1 bg-white mb-4 sm:mb-4 lg:mb-0">
                <span className="text-black font-black text-2xl">
                  € {europeanCurrencyFormatter(earningCardsData?.total_budget_amount)}
                </span>
                <span className="text-color-primary font-bold text-xs capitalize">
                  {adminLabels.totalBudgetAmount}
                </span>
              </div>
            )}
            {index === 0 && (
              <div className="rounded-lg p-2 icon-button flex flex-col justufy-center item-center mx-1 bg-white mb-4 sm:mb-4 lg:mb-0">
                <span className="text-black font-black text-2xl">
                  € {europeanCurrencyFormatter(earningCardsData?.budget_supplementary_fees)}
                </span>
                <span className="text-color-primary font-bold text-xs capitalize">
                  {adminLabels.totalOrderAmount}
                </span>
              </div>
            )}

            {index === 1 && (
              <div className="rounded-lg p-2 icon-button flex flex-col justufy-center item-center mx-1 bg-white">
                <span className="text-black font-black text-2xl">
                  € {europeanCurrencyFormatter(earningCardsData?.budget_supplementary_fees)}
                </span>
                <span className="text-color-primary font-bold text-xs capitalize">
                  {adminLabels.budgetSupplementary}
                </span>
              </div>
            )}

            {index === 1 && (
              <div className="rounded-lg p-2 icon-button flex flex-col justufy-center item-center mx-1 bg-white">
                <span className="text-black font-black text-2xl">
                  € {europeanCurrencyFormatter(earningCardsData?.order_supplementary_fees)}
                </span>
                <span className="text-color-primary font-bold text-xs capitalize">
                  {adminLabels.orderSupplementary}
                </span>
              </div>
            )}

            <div className="rounded-lg p-2 icon-button  justufy-center item-center mx-1 ">
              <Button
                shape={"round"}
                onClick={() => changeIndex(0)}
                className={
                  index == 0
                    ? `icon-button mx-1 bg-primary text-white`
                    : `icon-button mx-1`
                }
              >
                {adminLabels.orders}
              </Button>
              <Button
                shape={"round"}
                onClick={() => changeIndex(1)}
                className={
                  index == 1
                    ? `icon-button mx-1 bg-primary text-white`
                    : `icon-button mx-1`
                }
              >
                {adminLabels.additionalBudget}
              </Button>
              <CSVLink
                data={
                  index === 0
                    ? ordersEarnings?.map((item, index) => {
                        return {
                          ...item,
                          no: index + 1,
                          nameOfService: item?.product?.[currentSelectedLangue === "english" ?"name" : "name_sp"],

                          // nameOfService: item?.product?.name,
                          orderAssignedStatus:
                            item?.assignOrder?.is_reassign === 1
                              ? adminLabels.letsAssign
                              : adminLabels.assigned,
                        };
                      })
                    : additional_budgetEarnings?.map((item, index) => {
                        return {
                          ...item,
                          no: index + 1,
                          // nameOfService: item?.product?.name,
                          nameOfService: item?.product?.[currentSelectedLangue === "english" ?"name" : "name_sp"],
                          orderAssignedStatus:
                            item?.assignOrder?.is_reassign === 1
                              ? adminLabels.letsAssign
                              : adminLabels.assigned,
                        };
                      })
                }
                headers={[
                  { label: adminLabels.no, key: "no" },
                  { label: adminLabels.serviceName, key: "nameOfService" },
                  { label: adminLabels.orderId, key: "orderId" },
                  { label: adminLabels.orderDate, key: "date" },
                  { label: adminLabels.payment, key: "payment" },
                  { label: adminLabels.orderStatus, key: "orderStatus" },
                  {
                    label:
                      index === 0
                        ? adminLabels.serviceAmount
                        : adminLabels.additionalAmount,
                    key: "serviceAmount",
                  },
                  {
                    label: adminLabels.supplementaryAmount,
                    key: "supplementaryAmount",
                  },
                  {
                    label: adminLabels.serviceProviderAmount,
                    key: "ammountServiceProvider",
                  },
                  { label: adminLabels.received, key: "price" },
                ]}
                filename={
                  index === 0
                    ? `${adminLabels.orders} ${adminLabels.myEarnings}.csv`
                    : `${adminLabels.additionalBudget} ${adminLabels.myEarnings}.csv`
                }
                target="_blank"
              >
                <Button
                  shape={"round"}
                  className="icon-button "
                  icon={<AiOutlineArrowDown color="#5ab3f0" />}
                >
                  {adminLabels.exportCSV}
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <div className="container mx-auto lg:px-20 mt-5">
          <TableComponent
            columns={columns}
            scroll="130%"
            scrolly="500px"
            data={index === 0 ? ordersEarnings : additional_budgetEarnings}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
          />
        </div>
      </Spin>
    </section>
  );
}
