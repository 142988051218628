import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Spin, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiOutlineArrowDown } from "react-icons/ai";
import { RiArrowDropLeftLine } from "react-icons/ri";
import ReactQuill from "react-quill";
import { useHistory } from "react-router-dom";
import { Errors } from "../../components/antdToast";
import TableComponent from "../../components/table";
import {
  GetAllOrdersMethod,
  GetAppUsers,
  GetServiceProvider,
} from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { Images } from "../../constant/Images";
const bookingManagmentColumns = [
  {
    align: "center",
    title: adminLabels.no,
    dataIndex: "no",
    key: "no",
    width: 60,
    render: (text) => <a>{text}</a>,
  },
  {
    title: adminLabels.personalData,
    dataIndex: "personalData",
    key: "personalData",
    width: 200,
    render: (text) => (
      <div>
        <p>Name : {text[0].personal.firstName} </p>
        <p>Surname : {text[0].personal.lastName} </p>
        <p>Email : {text[0]?.personal?.emailAddress} </p>
        <p>Telephone : </p>
        <p>Region : {text[1]?.region?.state} </p>
        <p>City : {text[1]?.region?.city} </p>
        {/* <p>Type of Beneficiary  : {text} </p> */}
      </div>
    ),
  },
  {
    title: adminLabels.service,
    dataIndex: "product",
    key: "product",
    width: 180,

    render: (text, index) => (
      <div className={"flex"}>
        <img
          src={text?.avatar}
          style={{ width: 50, height: 45 }}
          className="rounded-lg"
          alt="detail image"
        />
        <div className="pl-2 text-left">
          <label>
            <b>{text?.name}</b>
          </label>{" "}
        </div>
      </div>
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <span className="search-filter-dropdown">
          <Input
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            autoFocus
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
          {/* <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
          >
            Search
          </Button> */}
          {/* <Button
            onClick={() => {
              clearFilters();
            }}
            type="danger"
            size="small"
          >
            Reset
          </Button> */}
        </span>
      );
    },
    filterIcon: () => {
      return <SearchOutlined style={{ color: "#ffff" }} />;
    },
    onFilter: (value, record) => {
      return record.product.name.toLowerCase().includes(value.toLowerCase());
    },
    ellipsis: {
      showTitle: false,
    },
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a?.name?.localeCompare(b?.name),
  },
  {
    align: "center",
    title: adminLabels.orderId,
    key: "orderId",
    dataIndex: "orderId",
    width: 100,
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <span className="search-filter-dropdown">
          <Input
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            autoFocus
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
          {/* <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
          >
            Search
          </Button> */}
          {/* <Button
            onClick={() => {
              clearFilters();
            }}
            type="danger"
            size="small"
          >
            Reset
          </Button> */}
        </span>
      );
    },
    filterIcon: () => {
      return <SearchOutlined style={{ color: "#ffff" }} />;
    },
    onFilter: (value, record) => {
      return record.orderId.toLowerCase().includes(value.toLowerCase());
    },
    ellipsis: {
      showTitle: false,
    },
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a?.orderId?.localeCompare(b?.orderId),
  },
  {
    align: "center",
    title: adminLabels.orderNumber,
    key: "orderNumber",
    dataIndex: "orderNumber",
    width: 100,
  },
  {
    align: "center",
    title: adminLabels.date,
    key: "date",
    dataIndex: "date",
    width: 100,
    render: (text) => (
      <div>
        <p>Date : {moment(text)?.format("DD-MMM-YYYY")} </p>
        <p>Time : {moment(text)?.format("h:mm:ss a")}</p>
      </div>
    ),
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    showSorterTooltip: false,
  },
  {
    align: "center",
    title: adminLabels.price,
    key: "price",
    dataIndex: "price",
    width: 100,
    render: (text, index) => (
      <div className="text-center flex justify-center">
        {text ? text + " €" : ""}
      </div>
    ),
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a?.price?.localeCompare(b?.price),
    // render: (text, index) => <div className="text-center	">{text}</div>,
  },
  {
    align: "center",
    title: adminLabels.timeOfDelivery,
    key: "estimatedTime",
    dataIndex: "estimatedTime",
    width: 100,
  },
  {
    align: "center",
    title: adminLabels.oderDueDateAndTime,
    key: "deliveryDate",
    dataIndex: "deliveryDate",
    width: 100,
    sortDirections: ["descend", "ascend"],
    sorter: (aa, bb) =>
      moment(aa.deliveryDate).unix() - moment(bb.deliveryDate).unix(),
    showSorterTooltip: false,
  },
  {
    align: "center",
    title: adminLabels.paymentDetails,
    key: "paymentDetails",
    dataIndex: "paymentDetails",
    width: 200,
    render: (text) => (
      <div className="text-left">
        <p>Amount of Total payment :</p>
        <p>Amount of Service provider : </p>
        <p>Amount of DocuDoc : </p>
        <p>Name of Additional Fees : </p>
        <p>Amount of Additional Fees : </p>
        <p>VAT : </p>
        {/* <p>Type of Beneficiary  : {text} </p> */}
      </div>
    ),
  },
];
const providerColumns = [
  {
    align: "center",
    title: adminLabels.no,
    dataIndex: "no",
    key: "no",
    width: 70,
    render: (text) => <a>{text}</a>,
  },
  {
    align: "center",
    title: adminLabels.dateAndTimeOfCreation,
    dataIndex: "registrationDate",
    key: "registrationDate",
    width: 200,
    ellipsis: true,
    render: (text) => (
      <div>
        <p>Date : {moment(text)?.format("DD-MMM-YYYY")} </p>
        <p>Time : {moment(text)?.format("h:mm:ss a")}</p>
      </div>
    ),
  },
  {
    align: "center",
    title: adminLabels.name,
    dataIndex: "name",
    key: "name",
    width: 200,
    render: (text) => <span>{text}</span>,
  },
  {
    align: "center",
    title: adminLabels.yearsOfExperience,
    dataIndex: "experience",
    key: "experience",
  },
  {
    title: adminLabels.positionDesignation,
    dataIndex: "position",
    key: "position",
  },
  {
    align: "center",
    title: adminLabels.address,
    dataIndex: "address",
    key: "address",
    width: 300,
    ellipsis: true,
  },
  {
    align: "center",
    title: adminLabels.email,
    dataIndex: "email",
    key: "email",
    ellipsis: true,
  },
  { title: adminLabels.phone, dataIndex: "phone", key: "phone" },
  {
    title: adminLabels.states,
    dataIndex: "states",
    key: "states",
    ellipsis: true,
    render: (Region) => (
      <Tooltip placement="topLeft" title={Region.join(", ")}>
        {Region.join(", ")}
      </Tooltip>
    ),
    ellipsis: {
      showTitle: false,
    },
    width: 150,
  },
  { title: adminLabels.city, dataIndex: "city", key: "city" },
  {
    title: adminLabels.workingHours,
    dataIndex: "workingHours",
    key: "workingHours",
    width: 200,
    render: (e) => (
      <Tooltip
        placement="topLeft"
        title={e.map((wH, index) => (
          <div className={"flex justify-around items-center "}>
            <span>{`${wH.isFriday === false ? "Mon - Thur" : "Fri"} : ${
              wH.start
            } - ${wH.end}`}</span>{" "}
          </div>
        ))}
      >
        {e.map((wH, index) => (
          <div className={"flex justify-around items-center "}>
            <span>{`${wH.isFriday === false ? "Mon - Thur" : "Fri"} : ${
              wH.start
            } - ${wH.end}`}</span>{" "}
          </div>
        ))}
      </Tooltip>
    ),
  },
  {
    title: adminLabels.servicesCovered,
    dataIndex: "servicesCovered",
    key: "servicesCovered",
  },
  // {
  //   title: "Product",
  //   dataIndex: "product",
  //   key: "product",

  //   width: 300,
  //   render: (text, index) => (
  //     <div className={"flex items-center"}>
  //       <img
  //         src={text.image}
  //         style={{ width: 45, height: 45 }}
  //         alt="detail image"
  //       />
  //       <div className="pl-2 text-left">
  //         <label>
  //           <b>{text.name}</b>
  //         </label>
  //         <br />
  //         <span style={{ color: "blue", marginBottom: 0 }}>{text.desc}</span>
  //       </div>
  //     </div>
  //   ),
  // },
  // {

  //   title: "Beneficiaries",
  //   dataIndex: "beneficiaries",
  //   key: "beneficiaries",
  //   width: 200,
  //   render: (e) => (
  //     <div className="flex items-center ml-2">
  //       <img src={e?.url} alt="" />
  //       <span className="ml-3">{e?.name}</span>
  //     </div>
  //   ),
  // },
  // {

  //   title: "Service Area",
  //   dataIndex: "servicearea",
  //   key: "servicearea",
  //   width: 160,
  //   render: (e) => (
  //     <div className="flex items-center ml-2">
  //       <img src={e?.url} alt="" className="h-8 w-8" />
  //       <span className="ml-2">{e?.name}</span>
  //     </div>
  //   ),
  // },
  // {
  //   title: "Order ID",
  //   dataIndex: "ordierId",
  //   key: "ordierId",
  //   width: 150,

  // },
  // {
  //   title: "Date",
  //   key: "date",
  //   dataIndex: "date",
  //   width: 150,

  // },
  // {
  //   title: "Price",
  //   key: "price",
  //   dataIndex: "price",
  //   width: 100,

  // },
  // {
  //   title: "Panctual service",
  //   key: "panctualservice",
  //   dataIndex: "panctualservice",
  //   width: 150,

  // },
  // {
  //   title: "Service Pack",
  //   key: "servicepack",
  //   dataIndex: "servicepack",
  //   width: 150,

  // },
  // {
  //   title: "DueDate of contract",
  //   key: "duedateofcontract",
  //   dataIndex: "duedateofcontract",
  //   width: 200,

  // },
  // {
  //   title: "Contracting Time",
  //   key: "contractingtime",
  //   dataIndex: "contractingtime",
  //   width: 200,

  // },
  // {
  //   title: "Name of Service",
  //   key: "nameofservice",
  //   dataIndex: "nameofservice",
  //   width: 200,

  // },
  // {
  //   title: "Location",
  //   key: "location",
  //   dataIndex: "location",
  //   width: 100,

  // },
  // {
  //   title: "Order Status",
  //   key: "orderstatus",
  //   dataIndex: "orderstatus",
  //   width: 150,

  // },
  // {
  //   title: "Order Delivery Time ",
  //   key: "orderdeliverytime",
  //   dataIndex: "orderdeliverytime",
  //   width: 200,

  //   //
  // },
  // {
  //   title: "Each Step Time",
  //   key: "eachsteptime",
  //   dataIndex: "eachsteptime",
  //   width: 200,

  // },
  // {
  //   title: "Total Payment made",
  //   key: "totalpaymentmade",
  //   dataIndex: "totalpaymentmade",
  //   width: 250,

  // },
  // {
  //   title: "Amount of Service provider",
  //   key: "amountofserviceprovider",
  //   dataIndex: "amountofserviceprovider",
  //   width: 300,

  // },
  // {
  //   title: "Amount of Additional Fees(Courier)",
  //   key: "amountofadditionalfees",
  //   dataIndex: "amountofadditionalfees",
  //   width: 300,

  // },
  // {
  //   title: "VAT",
  //   key: "vat",
  //   dataIndex: "vat",
  //   width: 80,

  // },
  // {
  //   title: "Actual Profit for Service",
  //   key: "actualprofitforservice",
  //   dataIndex: "actualprofitforservice",
  //   width: 300,

  // },
  // {
  //   title: "Name of Service Provider",
  //   key: "nameofserviceprovider",
  //   dataIndex: "nameofserviceprovider",
  //   width: 300,

  // },
  // {
  //   title: "Name Admin of Service Provider",
  //   key: "nameadminofserviceprovider",
  //   dataIndex: "nameadminofserviceprovider",
  //   width: 300,
  //   align: "center",
  // },
  // {
  //   title: "Name Worker of Service Provider",
  //   key: "nameworkerofserviceprovider",
  //   dataIndex: "nameworkerofserviceprovider",
  //   width: 300,

  // }
  {
    title: adminLabels.action,
    key: "action",
    width: 200,

    render: (text, index) => (
      <div className="text-center">
        <div
          type="disable"
          shape={"round flex"}
          className="background-color-gray whiteTxt rounded-full py-1 px-2 justify-center cursor-pointer	"
        >
          <span>{adminLabels.viewDetails}</span>
        </div>
      </div>
    ),
  },
];

const clientColumns = [
  {
    align: "center",
    title: adminLabels.no,
    dataIndex: "no",
    key: "no",
    width: 60,
    render: (text) => <a>{text}</a>,
  },
  // {
  //   align: "center",
  //   title: "Full Name",
  //   dataIndex: "fullname",
  //   key: "fullname",
  //   width: 150,
  // },
  // {
  //   align: "center",
  //   title: "Email",
  //   dataIndex: "email",
  //   key: "email",
  //   width: 200,
  // },
  // {
  //   align: "center",
  //   title: "Amount of Booking",
  //   key: "ammountOfBooking",
  //   dataIndex: "ammountOfBooking",
  //   width: 160,
  // },
  {
    align: "left",
    title: adminLabels.registrationDateAndTime,
    dataIndex: "registrationDate",
    key: "registrationDate",
    width: 160,
    render: (text) => (
      <div>
        <p>Date : {moment(text)?.format("DD-MMM-YYYY")} </p>
        <p>Time : {moment(text)?.format("h:mm:ss a")}</p>
      </div>
    ),
  },
  {
    align: "left",
    title: adminLabels.registrationDetails,
    dataIndex: "userDetails",
    key: "userDetails",
    width: 160,
    render: (text) => (
      <div>
        <p>Name : {text?.first_name} </p>
        <p>Surname : {text?.last_name}</p>
        <p>Email : {text?.email}</p>
      </div>
    ),
  },

  // {
  //   align: "center",
  //   title: "Rating given",
  //   key: "ratingGiven",
  //   dataIndex: "ratingGiven",
  //   width: 100,
  //   render: (text, index) => (
  //     <div className="flex items-center justify-center">
  //       <AiFillStar color={"#FFC107"} style={{ marginRight: 5 }} />
  //       <label>{text.ratting}</label>
  //     </div>
  //   ),
  // },
  // {
  //   align: "center",
  //   title: "Service which he clicked",
  //   dataIndex: "serviceClicked",
  //   width: 200,
  //   render: (text, index) => (
  //     <div className={"flex items-center"}>
  //       <img
  //         src={text.image}
  //         style={{ width: 30, height: 30 }}
  //         alt="detail image"
  //       />
  //       <div className="pl-2 text-left">
  //         <label>{text.name}</label>
  //       </div>
  //     </div>
  //   ),
  // },
  // {
  //   align: "center",
  //   title: "Amount of clicks",
  //   dataIndex: "amountofclicks",
  //   key: "amountofclicks",
  //   width: 150,
  // },
  // {
  //   align: "center",
  //   title: "Date and time of clicks",
  //   dataIndex: "dateandtiemofclicks",
  //   key: "dateandtiemofclicks",
  //   width: 200,
  // },
  // {
  //   align: "center",
  //   title: "Amount of booking made",
  //   dataIndex: "amountofbookingmade",
  //   key: "amountofbookingmade",
  //   width: 200,
  // },
  // {
  //   align: "center",
  //   title: "Action",
  //   key: "action",
  //   width: 160,
  //   render: (text, index) => (
  //     <div className="text-center">
  //       <Button
  //         type="disable"
  //         shape={"round"}
  //         className="background-color-gray whiteTxt"
  //       >
  //         View Details
  //       </Button>
  //     </div>
  //   ),
  // },
];

const clientData = [
  {
    no: "1",
    fullname: "John Brown",
    email: "abc@gmail.com",
    ammountOfBooking: "$845",
    registrationDate: "10 May 2021",
    ratingGiven: { ratting: 4.3 },
    serviceClicked: {
      image: Images.punctual1.default,
      name: "Punctual Service 1",
    },
    amountofclicks: "3",
    dateandtiemofclicks: "28 May 2021 & 12:00",
    amountofbookingmade: "$859",
  },
  {
    no: "2",
    fullname: "John Brown",
    email: "abc@gmail.com",
    ammountOfBooking: "$845",
    registrationDate: "10 May 2021",
    ratingGiven: { ratting: 4.3 },
    serviceClicked: {
      image: Images.punctual2.default,
      name: "Punctual Service 2",
    },
    amountofclicks: "3",
    dateandtiemofclicks: "28 May 2021 & 12:00",
    amountofbookingmade: "$859",
  },
  {
    no: "3",
    fullname: "John Brown",
    email: "abc@gmail.com",
    ammountOfBooking: "$845",
    registrationDate: "10 May 2021",
    ratingGiven: { ratting: 4.3 },
    serviceClicked: {
      image: Images.servicepack.default,
      name: "Service Pack 1",
    },
    amountofclicks: "3",
    dateandtiemofclicks: "28 May 2021 & 12:00",
    amountofbookingmade: "$859",
  },
];

export default function ReportAndAnaytics(params) {
  const [index, setIndex] = useState(0);
  const [users, setUsers] = useState([]);
  const [providersList, setProvidersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allOrders, setAllOrders] = useState([]);

  useEffect(() => {
    allOrdersOfProviders();
    fetchServiceProviders();
    fetchAppUsers();
  }, []);
  const fetchAppUsers = () => {
    GetAppUsers(
      (res) => {
        let data = res?.data?.response?.detail
          ?.reverse()
          ?.map((item, index) => {
            return {
              ...item,
              no: index + 1,
              userDetails: {
                first_name: item?.first_name,
                last_name: item?.last_name,
                email: item?.email,
              },
              fullName: `${item?.first_name} ${item?.last_name}`,
              registrationDate: item?.created_at,
              phone: item?.phone_number,
            };
          });
        setUsers(data);
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  const fetchServiceProviders = () => {
    setLoading(true);
    GetServiceProvider(
      (res) => {
        let data = res?.data?.response?.detail
          ?.reverse()
          ?.map((provider, index) => {
            return {
              ...provider,
              no: index + 1,
              name: provider?.name,
              experience: provider?.experience,
              position: provider?.position,
              states: provider?.state,
              registrationDate: provider?.created_at,
              workingHours: provider?.working_hours,
            };
          });
        setProvidersList(data);
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  const allOrdersOfProviders = () => {
    GetAllOrdersMethod(
      (success) => {
        if (success?.status === 200) {
          let mappedOut = success?.data?.response?.detail?.map(
            (item, index) => {
              let dates = moment(item?.created_at).format("DD-MMM-YYYY");
              return {
                no: index + 1,
                personalData: item.data,
                product: {
                  name: item?.service?.name,
                  avatar: item?.service?.avatar,
                  info: item?.service?.info,
                },
                orderId: item?.order_id,
                date: item.created_at,
                deliveryDate: moment(dates)
                  .add(item.service?.time, item?.service?.time_type)
                  .local()
                  .format("DD-MMM-YYYY"),
                price: item?.amount,
                estimatedTime: item?.service?.time + item?.service?.time_type,
                is_reassign: item?.is_reassign,
                status: item?.status,
                service_provider_id: item?.service_provider_id,
              };
            }
          );

          setAllOrders(mappedOut);
          setLoading(false);
        }
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };

  const changeIndex = (i) => {
    setIndex(i);
  };
  const { goBack } = useHistory();
  return (
    <section>
      <Spin tip={adminLabels.loading} spinning={loading}>
        <div className="flex justify-start lg:justify-between mt-5  flex-wrap sm:flex-wrap">
          <div className="pl-5 flex self-center justify-center items-center sub-h3 mb-4 sm:mb-4 lg:mb-0">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{adminLabels.back}</span>
            </div>
            <h3 className="ml-10	">{adminLabels.reportAndAnaytics}</h3>
          </div>
          <div className="lg:mr-28 flex justify-end flex-wrap sm:flex-wrap">
            <Button
              onClick={() => changeIndex(0)}
              shape={"round"}
              className={
                index == 0
                  ? `icon-button mx-1 bg-primary text-white`
                  : `icon-button mx-1`
              }
            >
              {adminLabels.clientUserReports}
            </Button>
            {/* <Link to={"/admin/booking-report"}> */}
            <Button
              shape={"round"}
              className={
                index == 1
                  ? `icon-button mx-1 bg-primary text-white`
                  : `icon-button mx-1`
              }
              onClick={() => changeIndex(1)}
            >
              {adminLabels.bookingReports}
            </Button>
            <Button
              shape={"round"}
              className={
                index == 2
                  ? `icon-button mx-1 bg-primary text-white`
                  : `icon-button mx-1`
              }
              onClick={() => changeIndex(2)}
            >
              {adminLabels.serviceProviderReports}
            </Button>
            <div className="ml-2">
              {/* <Button shape={"circle"} className="icon-button p0 ">
                <FiSearch size={15} />
              </Button> */}

              <CSVLink
                data={
                  index === 0
                    ? users
                    : index === 1
                    ? allOrders
                    : index === 2
                    ? providersList
                    : []
                }
                filename={
                  index === 0
                    ? "client user reports.csv"
                    : index === 1
                    ? "booking report reports.csv"
                    : index === 2
                    ? "service provider reports.csv"
                    : []
                  // index === 0
                  //   ? "ordersEarnings.csv"
                  //   : "additional_budgetEarnings.csv"
                }
                target="_blank"
              >
                <Button
                  shape={"round"}
                  className="icon-button "
                  icon={<AiOutlineArrowDown color="#5ab3f0" />}
                >
                  {adminLabels.exportCSV}
                </Button>
              </CSVLink>
            </div>
            {/* </Link> */}
            {/* <div
              className=" bg-white flex justify-center item-center"
              style={{
                borderRadius: "100px",
                padding: "10px",
                width: "40px",
                height: "40px",
              }}
            >
              <FcDown color="green" />
            </div> */}
          </div>
        </div>
        <div className="container mx-auto lg:px-20 mt-5">
          <TableComponent
            scroll={index === 0 ? "100%" : "150%"}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
            scrolly="500px"
            columns={
              index === 0
                ? clientColumns
                : index === 1
                ? bookingManagmentColumns
                : index === 2
                ? providerColumns
                : []
            }
            data={
              index === 0
                ? users
                : index === 1
                ? allOrders
                : index === 2
                ? providersList
                : []
            }
          />
        </div>
      </Spin>
    </section>
  );
}
