import React, { useEffect } from "react";
import Orders from "../screens/orders";
import OrdersAssignation from "../screens/orderAssignation";
import SubAdminHeader from "../components/Dashboard/SubAdminHeader";
import orderInfoDetail from "../screens/orderInfo";
import MyMessage from "../screens/myMessage";
import orderInfoClient from "../screens/orderInfoClient";
import Profile from "../screens/profile";
import WorkerServiceProvider from "../screens/workerServiceProvider";
import AddWorkerServiceAdmin from "../screens/addWorkerServiceProvider";
import OrdersWorker from "../screens/orderWorker";
import MessageDetail from "../screens/myMessageDetail";
import AddMyMessage from "../screens/myMessageAdd";
import OrderAssgin from "../screens/orderAssign";
import OrderSteps from "../screens/orderSteps";
import { Route } from "react-router-dom";
import { Images } from "../constant/Images";
import AddMyMessageOrder from "../screens/myMessageOrder";
import { spanishLabels } from "../config/label/providerLabels";
import ProviderAdditionalBudgets from "../screens/providerAdditionalBudgets";
import WorkerAdditionalBudget from "../screens/workerAdditionalBudgets";
const { dotPattern } = Images;
export default function Worker(params) {
  useEffect(() => {
    document.title = spanishLabels.DoCuDocPartner;
  }, []);
  return (
    <>
      <SubAdminHeader />
      <div className="absolute right-10 top-20 " style={{ zIndex: -1 }}>
        <img src={dotPattern.default} />
      </div>
      <Route path="/worker/dashboard" component={OrdersWorker} />
      <Route path="/worker/order-assignation" component={OrdersAssignation} />
      <Route path="/worker/my-message" component={MyMessage} />
      <Route path="/worker/order-info-detail/:id" component={orderInfoDetail} />
      <Route path="/worker/order-info-client" component={orderInfoClient} />
      <Route path="/worker/profile" component={Profile} />
      <Route
        path="/worker/worker-of-service-provider"
        component={WorkerServiceProvider}
      />
      <Route
        path="/worker/add-worker-of-service-provider"
        component={AddMyMessage}
      />
      <Route path="/worker/message-detail" component={MessageDetail} />
      <Route
        path="/worker/add-my-message-order/:id"
        component={AddMyMessageOrder}
      />
      <Route path="/worker/add-my-message" component={AddMyMessage} />
      <Route path="/worker/order-assign" component={OrderAssgin} />
      <Route path="/worker/order-steps/:id" component={OrderSteps} />
      <Route
        path="/worker/additional-budget"
        exact
        component={WorkerAdditionalBudget}
      />
    </>
  );
}
