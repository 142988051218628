import { EyeOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import InformationContainer from "../../components/informationContainer";
import { adminLabels } from "../../config/label/adminLabels";
import UserDetailContainerCard from "./UserDetailContainerCard";
import FilledServiceFormFields from "./filledServiceFormFields";
import { functionToConvertStringToCamelCase } from "../../config/utils";

const currentSelectedLangue = localStorage.getItem("primaryLanguage");
function OrderInfoClient(props) {
  const { goBack } = useHistory();
  const [docs, setDocs] = useState([]);
  const [orderDetail, setOrderDetail] = useState({});
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [modal_Content, setModalContent] = useState("");
  
  useEffect(() => {
    if (
      props?.location?.state?.orderDetail === undefined ||
      props?.location?.state?.orderDetail === null
    ) {
      goBack();
    } else {
      setOrderDetail(props?.location?.state?.orderDetail);
      let userFilesArray =
        props?.location?.state?.orderDetail?.data?.[2]?.additionalInfo?.filter(
          (item) => item?.fieldId === 4
        ) || [];
      let adminFilesArray =
        props?.location?.state?.orderDetail?.data?.[2]?.additionalInfo?.filter(
          (item) => item?.fieldId === 8
        ) || [];

      setDocs([...userFilesArray, ...adminFilesArray]);
    }
  }, []);
  const downloadFiles = (url, name) => {
    axios({
      url: url,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
      });
  };
  
  const downloadAllFiles = async () => {
    docs?.map((item) => {
      if (item?.hasOwnProperty("value") || item?.hasOwnProperty("files")) {
        item?.value?.map((itemm) => {
          return downloadFiles(itemm?.url, itemm?.name);
        });
        item?.files?.map((itemm) => {
          return downloadFiles(itemm?.url, itemm?.name);
        });
      } else {
        return downloadFiles(item?.url, item?.name);
      }
    });
  };
  return (
    <section className="pb-20">
      <div className="flex justify-between mt-20 mb-5 ">
        <div className="pl-5 flex self-center justify-center items-center">
          <div
            onClick={() => goBack()}
            className="flex text-gray-400 items-center cursor-pointer"
          >
            <RiArrowDropLeftLine />
            <span>{adminLabels.back}</span>
          </div>
          <h3 className="ml-10	">
            {adminLabels?.orderId}:{" "}
            {props?.location?.state?.orderDetail?.order_id}
          </h3>
        </div>
      </div>
      <div className="container grid grid-cols-12 gap-3 px-2 sm:px-2 lg:px-0 lg:pl-20">
        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex justify-between mb-5">
          <div className="flex">
            <h3 className="lg:ml-5	my-4">{adminLabels.currentStep}</h3>
            <span
              className="flex items-center"
              style={{
                fontWeight: "normal",
                fontSize: 20,
                color: "#7D7D7D",
                marginLeft: 10,
                marginTop: 12,
              }}
            >
              {adminLabels?.[functionToConvertStringToCamelCase(props?.location?.state?.orderDetail?.service?.service_statuses?.findLast((currentStep) => currentStep?.order_status !==null)?.status)] || 'N/A'}
            </span>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4 lg:ml-5">
          <UserDetailContainerCard
            clientDetail={props?.location?.state?.orderDetail}
          />
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-7">
          <div className="bg-white container rounded-lg px-5 py-3 mh64">
            <div>
              <div className="flex w-full justify-between flex-wrap">
                <div>
                  <p>
                    <b>{adminLabels.serviceSelected}</b> - "{" "}
                    {`${
                      orderDetail?.service?.[
                        currentSelectedLangue === "english" ? "name" : "name_sp"
                      ]
                    }`}
                    " <br />
                  </p>
                </div>
                <div>
                  <div className="table-quill flex justify-center items-center">
                    <Button
                      shape={"round"}
                      className="icon-button proximaRegular capitalize"
                      onClick={() => {
                        setShowInfoModal(true);
                        currentSelectedLangue === "english"
                          ? setModalContent(orderDetail?.service?.info || "")
                          : setModalContent(
                              orderDetail?.service?.info_sp || ""
                            );
                      }}
                    >
                      <EyeOutlined />
                      <span>{adminLabels.infoBox}</span>
                    </Button>
                  </div>
                </div>
              </div>
              <div>
                <p>
                  <b>{adminLabels.selectedUserType}</b>
                  <br />
                  {orderDetail?.data?.[0]?.personal?.business?.length ? (
                    <>
                      {adminLabels.business} : {adminLabels.yes}
                    </>
                  ) : null}
                  {orderDetail?.data?.[0]?.personal?.self?.length ? (
                    <>
                      {adminLabels.selfEmployed} : {adminLabels.yes}
                    </>
                  ) : null}

                  <br />
                  <div>
                    {orderDetail?.data?.[0]?.personal?.isBillRequired ? (
                      <div>
                        <b className="capitalize">{adminLabels.billNeeded}</b>
                      </div>
                    ) : null}
                    {orderDetail?.data?.[0]?.personal?.isBillRequired ? (
                      <>
                        <b>{adminLabels.company} -</b> "
                        {orderDetail?.data?.[0]?.personal.companyName}"
                      </>
                    ) : null}
                  </div>
                  <div>
                    {orderDetail?.data?.[0]?.personal?.isBillRequired ? (
                      <>
                        <b>{adminLabels.cif} -</b> "
                        {orderDetail?.data?.[0]?.personal.CIF}"
                      </>
                    ) : null}
                  </div>
                  <div>
                    {orderDetail?.data?.[0]?.personal?.isBillRequired ? (
                      <>
                        <b>{adminLabels.address} -</b> "
                        {orderDetail?.data?.[0]?.personal.address}"
                      </>
                    ) : null}
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-10  ">
        <div className="pl-5 flex self-center justify-center items-center">
          <div className="lg:ml-20">
            <span
              className="flex items-center "
              style={{
                fontWeight: "normal",
                fontSize: 20,
                color: "#7D7D7D",
                marginLeft: 10,
                marginTop: 12,
              }}
            >
              {adminLabels.serviceForm}
            </span>
          </div>
        </div>
      </div>
      <div className="container grid grid-cols-12 gap-3 px-2 sm:px-2 lg:px-20 mt-5">
        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 lg:ml-5 lg:mr-10 bg-white">
          <div className="bg-white container rounded-lg px-5 py-3 mh64">
            {docs !== null && docs?.length !== 0 ? (
              <div className="flex justify-end">
                <Button
                  shape={"round"}
                  className="icon-button proximaRegular capitalize"
                  onClick={downloadAllFiles}
                >
                  {adminLabels.downloadAllFiles}
                </Button>
              </div>
            ) : null}
            <div className="flex grid grid-cols-12 gap-2 divide-x-2 divide-gray-100 divide-solid mt-2">
              <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12  bg-white">
                <FilledServiceFormFields
                  filledServiceForm={
                    props?.location?.state?.orderDetail?.data?.[2]
                      ?.additionalInfo
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        visible={showInfoModal}
        centered
        footer={null}
        onCancel={() => {
          setShowInfoModal(false);
        }}
        className="global-modal-css-scroll-height "
      >
        <div>
          <h4 className="font-bold text-2xl text-center text-gray-600	">
            {adminLabels.information}
          </h4>
        </div>
        <div className="scrollable-fixed-height py-5 render-html-class">
          {<InformationContainer info={modal_Content} />}
        </div>
      </Modal>
    </section>
  );
}
export default OrderInfoClient;
