import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Tooltip, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiOutlineArrowDown } from "react-icons/ai";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ModalComponent } from "../../components";
import { Errors } from "../../components/antdToast";
import TableComponent from "../../components/table";
import { GetWorkwithUsMethod, HelpAndWorkWithUsReplyMethod } from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { fetchWorkWithUsAction } from "../../Redux/actions/fetchWorkWithUsAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";

const WorkRequestManagement = (props) => {
  const { goBack,push } = useHistory();
  const [loading, setLoading] = useState(true);
  const [filterModal, setFilterModal] = useState(false);
  const [filterModal_Content, setFilterModalContent] = useState({});
  const [workWithUsList, setWorkWithUsList] = useState([]);
  const [showInfoModal, setShowInfoModal] = React.useState(false);
  const [modal_Content, setModalContent] = React.useState("");

  useEffect(() => {
    getWorkWithUs();
    document.body.style.overflow = "auto";
    return function cleanup() {
      document.body.style.overflow = "auto";
    };
  }, []);
  const workWithUsResponseFunction = (params) => {
    const obj = {
      id:params?.id,
      actionFor:"work"
    }
    HelpAndWorkWithUsReplyMethod(obj,(res) => {
      if(res?.data?.status){
        getWorkWithUs();
      }   
    } , (err) => {
      if (err?.response?.status === 401) {
        props.setLoaderState(false);
        Errors(err?.response?.data?.message);
        push("/admin");
      } else {
        props.setLoaderState(false);
        Errors("Network Error");
      }
    })
   } 
  const columns = [
    {
      align: "center",
      title: adminLabels.no,
      dataIndex: "no",
      key: "no",
      width: 40,
      render: (text) => <a>{text}</a>,
    },
    {
      align: "center",
      title: adminLabels.fullName,
      dataIndex: "fullname",
      key: "fullname",
      className: "capitalize",
      width: 100,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.fullname.toLowerCase().includes(value.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.fullname.localeCompare(b.fullname),
      showSorterTooltip: false,
      render: (fullname) => (
        <Tooltip placement="topLeft" title={fullname}>
          {fullname}
        </Tooltip>
      ),
    },
    {
      align: "center",
      title: adminLabels.email,
      dataIndex: "email",
      key: "email",
      width: 130,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.email.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      render: (email) => (
        <Tooltip placement="topLeft" title={email}>
          {email}
        </Tooltip>
      ),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.email.localeCompare(b.email),
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: adminLabels.phone,
      key: "phone",
      dataIndex: "phone",
      width: 100,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.phone.toLowerCase().includes(value.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.phone - b.phone,
      showSorterTooltip: false,
      render: (phone) => (
        <Tooltip placement="topLeft" title={phone}>
          {phone}
        </Tooltip>
      ),
    },
    {
      align: "center",
      title: adminLabels.description,
      dataIndex: "description",
      key: "description",
      width: 60,
      render: (text, index) => (
        <div className="table-quill flex justify-center items-center">
          <span
            className="cursor-pointer flex items-center space-x-1 "
            onClick={() => {
              setShowInfoModal(true);
              setModalContent(text);
             
            }}
          >
            <EyeOutlined />
            <span>{adminLabels.infoBox}</span>
          </span>
        </div>
      ),
    },
    {
      align: "center",
      title: adminLabels.date,
      key: "Date",
      dataIndex: "date",
      width: 100,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      showSorterTooltip: false,
      render: (Date) => (
        <Tooltip placement="topLeft" title={Date}>
          {Date}
        </Tooltip>
      ),
    },
    {
      align: "center",
      title: adminLabels.action,
      key: "action",
      width: 100,
      render: (text, index) => (
        <div className="gap-1 items-center justify-center">
          {!text?.is_read ? <span className="font-bold text-red-500 text-4xl mr-1">.</span> : <span className="font-bold font-color-blue text-4xl mr-1 opacity-0">.</span> }
            
      
          <Button
            type="disable"
            shape={"round"}
            onClick={() => {
              setFilterModalContent(text);
              setFilterModal(true);
              workWithUsResponseFunction(text)
            }}
            className="background-color-gray whiteTxt w-9/12"
          >
            {adminLabels.view}
          </Button>
        </div>
      ),
    },
  ];

  const getWorkWithUs = () => {
    GetWorkwithUsMethod(
      (success) => {
        if (success?.status === 200) {
          const data = success?.data?.response.detail;
          const mappedArray = data?.reverse()?.map((workWithUs, index) => {
            return {
              ...workWithUs,
              no: index + 1,
              fullname: workWithUs?.name,
              email: workWithUs?.email,
              phone: workWithUs?.number,
              description: workWithUs?.description,
              date: moment(workWithUs?.created_at)?.format("DD-MMM-YYYY"),
            };
          });
          setWorkWithUsList(mappedArray);
          setLoading(false)
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          Errors(error?.response?.data?.message);
          props.history.push("/admin");
        } else {
          Errors("Network Error");
        }
      }
    );
    // props.getWorkWithUsList(props);
    // let mappedArray = props?.showWorkWithUs
    //   ?.reverse()
    //   ?.map((workWithUs, index) => {
    //     return {
    //       ...workWithUs,
    //       no: index + 1,
    //       fullname: workWithUs?.name,
    //       email: workWithUs?.email,
    //       phone: workWithUs?.number,
    //       description: workWithUs?.description,
    //       date: moment(workWithUs?.created_at).format("DD-MMM-YYYY"),
    //     };
    //   });
    // setWorkWithUsList(mappedArray);
  };
  return (
    <section className="">
      <Spin tip={adminLabels.loading} spinning={loading}>
        <div className="flex justify-between mt-5 ">
          <div className="pl-5 flex self-center justify-center items-center sub-h3">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span> {adminLabels.back}</span>
            </div>
            <h3 className="ml-10	">
              {adminLabels.workWithUsRequestsManagement}
            </h3>
          </div>
          <div className="mr-1 sm:mr-1 lg:mr-28 flex">
            {/* <div>
            <Button shape={"circle"} className="icon-button p0 mr-1">
              <FiSearch size={15} />
            </Button>
          </div> */}
            <div>
              <CSVLink
                data={workWithUsList?.map((item,index) => {
                  return {
                    ...item,
                    no: index + 1,
                  }
                })}
                headers={[
                  { label: adminLabels.no, key: "no" },
                  { label: adminLabels.name, key: "name" },
                  { label: adminLabels.email, key: "email" },
                  { label: adminLabels.phone, key: "number" },
                  { label: adminLabels.description, key: "description" },
                ]}
                filename={`${adminLabels.workWithUsRequestsManagement}.csv`}
                target="_blank"
              >
                <Button
                  shape={"round"}
                  className="icon-button "
                  icon={<AiOutlineArrowDown color="#5ab3f0" />}
                >
                  {adminLabels.exportCSV}
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>

        <div className="container mx-auto lg:px-20 mt-5">
          <TableComponent
            columns={columns}
            // data={data}
            // scrolly="260px"
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
            scrolly="500px"
            data={workWithUsList}
            // pagination={{ defaultPageSize: 5, showSizeChanger: false }}
          />
          <Modal
            visible={filterModal}
            onCancel={() => {
              setFilterModal(false);
            }}
            footer={null}
            centered
            className="global-modal-css-scroll-height"
          >
            <div>
              <h4 className="font-bold text-2xl text-center text-gray-600	">
                {adminLabels.requestDetail}
              </h4>
            </div>
            <div className="divide-gray-200 divide-y divide-solid scrollable-fixed-height ">
              <div className="grid grid-cols-12 gap-3">
                <div className="col-span-6 sm:col-span-6 md:col-span-6 lg:col-span-6 my-3">
                  <label className="BLab text-gray-100">
                    <b>{adminLabels.name}:</b>
                  </label>
                  <p className="hh01 font-black mb-0  mt-1">
                    {filterModal_Content?.name}
                  </p>
                </div>
                <div className="col-span-6 sm:col-span-6 md:col-span-6 lg:col-span-6 my-3">
                  <label className="BLab text-gray-100">
                    <b>{adminLabels.email}:</b>
                  </label>
                  <p className="hh01 font-black mb-0  mt-1">
                    {filterModal_Content?.email}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-3">
                <div className="col-span-6 sm:col-span-6 md:col-span-6 lg:col-span-6 my-3">
                  <label className="BLab text-gray-100">
                    <b>{adminLabels.phoneNo}:</b>
                  </label>
                  <p className="hh01 font-black mb-0  mt-1">
                    {filterModal_Content?.phone}
                  </p>
                </div>
                <div className="col-span-6 sm:col-span-6 md:col-span-6 lg:col-span-6 my-3">
                  <label className="BLab text-gray-100">
                    <b>{adminLabels.date}:</b>
                  </label>
                  <p className="hh01 font-black mb-0  mt-1">
                    {filterModal_Content?.date}
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-12 gap-3">
                <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 my-3">
                  <label className="BLab text-gray-100">
                    <b>{adminLabels.description}:</b>
                  </label>

                  <p className="hh01 font-black mb-0 mt-1">
                    {filterModal_Content?.description}
                  </p>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            visible={showInfoModal}
            footer={null}
            centered
            onCancel={() => {
              setShowInfoModal(false);
            }}
            className="global-modal-css-scroll-height "
          >
            <div>
              <h4 className="font-bold text-2xl text-center text-gray-600	">
                {adminLabels.description}
              </h4>
            </div>
            <div className="scrollable-fixed-height py-5">{modal_Content}</div>
          </Modal>
        </div>
      </Spin>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    showWorkWithUs: state.getWorkWithUsList.WORK_WITH_US,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getProvidersList: (props) => {
    //   dispatch(fetchHelpAction(props));
    // },
    getWorkWithUsList: (props) => {
      dispatch(fetchWorkWithUsAction(props));
    },
    setLogoutUser: (data) => {
      dispatch(LogoutUserAction(data));
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkRequestManagement);
