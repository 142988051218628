import React from "react";
import ForgotCards from "../../components/ForgotCard";

export default function forgot(params) {
  return (
    <div className="bg-img">
      <ForgotCards />
    </div>
  );
}
