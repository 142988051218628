import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Input,
  Menu,
  Modal,
  Spin,
  Switch,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
// import { FaAngleRight, FaAngleDown } from "react-icons/fa";
import {
  AiFillQuestionCircle,
  AiOutlineArrowDown,
  AiOutlinePlus,
} from "react-icons/ai";
import { RiArrowDropLeftLine, RiPencilLine } from "react-icons/ri";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Errors, Success } from "../../components/antdToast";
import InformationContainer from "../../components/informationContainer";
import TableComponent from "../../components/table";
import {
  DeleteBranchMethod,
  GetBranches,
  UpdateBranchStatus,
} from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { DeleteBeneficiariesAtLogoutTime } from "../../Redux/actions/fetchBeneficiariesAction";
import {
  DeleteBranchesAtLogoutTime,
  FetchBranchesAction,
} from "../../Redux/actions/fetchBranchesAction";
import { DeleteProvidersAtLogoutTime } from "../../Redux/actions/fetchProvidersAction";
import { DeleteServiceAreasAtLogoutTime } from "../../Redux/actions/fetchServiceAreaAction";
import { DeleteServicesAtLogoutTime } from "../../Redux/actions/fetchServicesAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";

const Branches = (props) => {
  const { goBack, push } = useHistory();
  const [branchList, setBranchList] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [deleteBranchID, setDeleteBranchID] = useState("");
  const [showInfoModal, setShowInfoModal] = React.useState(false);
  const [modal_Content, setModalContent] = React.useState("");
  const [loading, setLoading] = useState(true);
  const [currentSelectedLangue, setCurrentSelectedLangue] = useState("spanish");
  useEffect(() => {
    setCurrentSelectedLangue(localStorage.getItem("primaryLanguage"));
    getBranches();

    document.body.style.overflow = "auto";
    return function cleanup() {
      document.body.style.overflow = "auto";
    };
  }, []);

  const deleteBranch = () => {
    if (deleteBranchID !== "") {
      props.setLoaderState(true);
      DeleteBranchMethod(
        deleteBranchID,
        (success) => {
          if (success?.status === 200) {
            getBranches();
            setOpenConfirmationModal(false);
            Success(success?.data?.response?.message);
            props.setLoaderState(false);
            // getFaqs();
            // setDeleteModal(false);
          }
        },
        (err) => {
          if (err?.response?.status === 500) {
            Errors("Network Error");
          } else if (err.response.status === 422) {
            Errors(err.response.data.error.message);
          } else {
            Errors(err?.response?.data?.error?.message);
          }
        }
      );
    }
  };

  const getBranches = () => {
    GetBranches(
      (res) => {
        let Branches = res?.data?.response?.detail
          ?.reverse()
          ?.map((branch, index) => {
            return {
              ...branch,
              no: index + 1,
              beneficiaries: branch.branch_beneficiaries?.map((item) => {
                return {
                  ...item,
                  priority: item?.priority,
                  service_beneficiary: item?.beneficiary,
                };
              }),
              branchInfo: {
                name: branch?.name,
                NameEsp: branch?.name_sp,
                url: branch?.avatar,
                desc: branch?.info_service_pack,
              },
              branch_service_areas: branch?.service_area,
              info: branch?.info,
            };
          });
        setBranchList(Branches);
        setLoading(false);
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  const updateBranchStatus = (branchID) => {
    UpdateBranchStatus(
      branchID,
      (success) => {
        if (success?.status === 200) {
          Success(success?.data?.response?.message);
          getBranches();
        }
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors("Network Error");
        } else if (err.response.status === 422) {
          Errors(err.response.data.error.message);
        } else {
          Errors(err?.response?.data?.error?.message);
        }
      }
    );
  };
  const menu = (text) => {
    return (
      <Menu className="menu-class menu-main-class" style={{ borderRadius: 20 }}>
        <Menu.Item
          className="bg-white"
          style={{ borderRadius: 20 }}
          key={text.id + Math.random()}
          onClick={() => handleMenu(text.branch_id, text)}
        >
          <div
            className="flex justify-between bg-white py-2 w-full  items-center"
            style={{ width: 160 }}
          >
            <div>
              <span>{adminLabels.edit}</span>
            </div>
            <div>
              <RiPencilLine color="#46bcff" size={20} />
            </div>
          </div>
        </Menu.Item>
        <Menu.Item
          className="bg-white"
          style={{ borderRadius: 20 }}
          key={text.id + Math.random()}
        >
          <div
            className="flex justify-between bg-white py-2 w-full  items-center"
            style={{ width: 160 }}
          >
            <div>
              <span>
                {text?.status === 1
                  ? adminLabels.enabled
                  : adminLabels.disabled}
              </span>
            </div>

            <div>
              <Switch
                size="small"
                checked={text?.status === 1 ? true : false}
                onChange={() => updateBranchStatus(text.branch_id)}
              />
            </div>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const handleMenu = (id, branch) => {
    push({ pathname: `/admin/edit-branch/${id}`, state: { branch: branch } });
  };
  const deleteModal = (id) => {
    setOpenConfirmationModal(true);
    setDeleteBranchID(id);
  };

  const columns = [
    {
      title: adminLabels.no,
      dataIndex: "no",
      key: "no",
      className: "text-center",
      width: 60,
      render: (text) => <a>{text}</a>,
    },
    {
      title: adminLabels.branch,
      dataIndex: "branchInfo",
      key: "branchInfo",
      width: 180,
      className: "capitalize",
      render: (text, index) =>
        currentSelectedLangue === "english" ? (
          <Tooltip placement="topLeft" title={text?.name}>
            <div className={"flex items-center"}>
              <img
                src={text?.url}
                style={{ width: 45, height: 45 }}
                alt="detail image"
              />
              <div className="pl-2 text-left">
                <label>
                  <b>{text?.name}</b>
                </label>
                <br />
              </div>
            </div>
          </Tooltip>
        ) : (
          <Tooltip placement="topLeft" title={text?.NameEsp}>
            <div className={"flex items-center"}>
              <img
                src={text?.url}
                style={{ width: 45, height: 45 }}
                alt="detail image"
              />
              <div className="pl-2 text-left">
                <label>
                  <b>{text?.NameEsp}</b>
                </label>
                <br />
              </div>
            </div>
          </Tooltip>
        ),

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return currentSelectedLangue === "english"
          ? record.branchInfo.name.toLowerCase().includes(value.toLowerCase())
          : record?.branchInfo?.NameEsp.toLowerCase().includes(
              value.toLowerCase()
            );
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        currentSelectedLangue === "english"
          ? a.branchInfo.name.localeCompare(b.branchInfo.name)
          : a.branchInfo.NameEsp.localeCompare(b.branchInfo.NameEsp),
    },
    {
      title: adminLabels.serviceArea,
      dataIndex: "branch_service_areas",
      key: "branch_service_areas",
      width: 120,
      className: "capitalize",
      render: (e) => (
        <Tooltip
          placement="topLeft"
          title={currentSelectedLangue === "english" ? e?.name : e?.info}
        >
          <div className="flex items-center ml-2">
            <span className="ml-4">{`${
              currentSelectedLangue === "english" ? e?.name : e?.info
            }`}</span>
          </div>
        </Tooltip>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.branch_service_areas?.[
          currentSelectedLangue === "english" ? "name" : "info"
        ]
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a.branch_service_areas?.[
          currentSelectedLangue === "english" ? "name" : "info"
        ].localeCompare(
          b.branch_service_areas?.[
            currentSelectedLangue === "english" ? "name" : "info"
          ]
        ),
    },
    {
      title: adminLabels.beneficiaries,
      dataIndex: "beneficiaries",
      key: "beneficiaries",
      className: "capitalize",
      width: 180,
      render: (e) => (
        <Tooltip
          placement="topLeft"
          title={e?.map((benf, i) => (
            <div className="flex justify-between" key={i}>
              <div>
                {
                  benf?.beneficiary?.[
                    currentSelectedLangue === "english" ? "name" : "info"
                  ]
                }
              </div>
              <div>&#40;{benf?.priority}&#41;</div>
            </div>
          ))}
        >
          <div className="flex   flex-col">
            {e?.map((benf, index) => {
              return (
                <div className="flex justify-between" key={index}>
                  <div className="benefi 	flex space-x-2 items-center">
                    <div>
                      <img
                        src={benf?.beneficiary?.avatar}
                        style={{ width: 20, height: 20 }}
                        alt="benf Image"
                      />
                    </div>
                    <div>
                      {
                        benf?.beneficiary?.[
                          currentSelectedLangue === "english" ? "name" : "info"
                        ]
                      }
                    </div>
                  </div>
                  <div className="priority font-bold">
                    &#40;{benf?.priority}&#41;
                  </div>
                </div>
              );
            })}
          </div>
        </Tooltip>
      ),
    },
    {
      title: adminLabels.branchLevel,
      dataIndex: "level",
      key: "level",
      width: 80,
      className: "text-center",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.level - b.level,
      showSorterTooltip: false,
      render: (level) => (
        <Tooltip placement="topLeft" title={level}>
          {level}
        </Tooltip>
      ),
    },
    {
      title: adminLabels.information,
      // key: "info",
      align: "center",
      // dataIndex: "info",
      width: 70,
      render: (text, index) => (
        <div className="table-quill flex justify-center items-center">
          <span
            className="cursor-pointer flex items-center space-x-1 "
            onClick={() => {
              currentSelectedLangue === "english"
                ? setModalContent(text?.info || "")
                : setModalContent(text?.info_sp || "");
              setShowInfoModal(true);
            }}
          >
            <EyeOutlined />
            <span>{adminLabels?.infoBox}</span>
          </span>
        </div>
      ),
    },
    {
      title: adminLabels.action,
      key: "action",
      width: 120,

      render: (text, index) => (
        <div className="text-center">
          <Dropdown
            overlay={() => menu(text)}
            className={"flex"}
            trigger={["click"]}
          >
            <div
              type="disable"
              shape={"round"}
              className="background-color-gray whiteTxt rounded-full py-3 justify-center cursor-pointer	"
            >
              <span>{adminLabels.viewDetails}</span>
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <section>
      <Spin tip={adminLabels.loading} spinning={loading}>
        <div className="flex justify-between mt-5 ">
          <div className="pl-5 flex self-center justify-center items-center sub-h3 ">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{adminLabels.back}</span>
            </div>
            <h3 className="ml-10	">{adminLabels.branches}</h3>
          </div>
          <div className="mr-1 sm:mr-1 lg:mr-28 flex">
            <Link to="/admin/add-branch">
              <Button
                shape={"round"}
                className="icon-button"
                icon={<AiOutlinePlus color="#5ab3f0" />}
              >
                {adminLabels.addNew}
              </Button>
            </Link>
            <div className="ml-2">
              <CSVLink
                data={branchList?.map((item, index) => {
                  return {
                    ...item,
                    no: index + 1,
                    branchName:
                      currentSelectedLangue === "english"
                        ? item?.branchInfo?.name
                        : item?.branchInfo?.NameEsp,
                    branchServiceArea:
                      item?.branch_service_areas?.[
                        currentSelectedLangue === "english" ? "name" : "info"
                      ],
                    beneficiary: item?.beneficiaries?.map((item) => {
                      return ` ${
                        item?.beneficiary?.[
                          currentSelectedLangue === "english" ? "name" : "info"
                        ]
                      } (${item?.priority}) `;
                    }),
                  };
                })}
                headers={[
                  { label: adminLabels.no, key: "no" },
                  { label: adminLabels.branch, key: "branchName" },
                  { label: adminLabels.serviceArea, key: "branchServiceArea" },
                  { label: adminLabels.beneficiaries, key: "beneficiary" },
                  {
                    label: adminLabels.branchLevel,
                    key: "level",
                  },
                ]}
                filename={`${adminLabels.branches}.csv`}
                target="_blank"
              >
                <Button
                  shape={"round"}
                  className="icon-button "
                  icon={<AiOutlineArrowDown color="#5ab3f0" />}
                >
                  {adminLabels.exportCSV}
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <div className="container mx-auto ox-0.5 sm:px-0.5 lg:px-20 mt-5">
          <TableComponent
            columns={columns}
            data={branchList}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
            scrolly="500px"
          />
          <Modal
            visible={showInfoModal}
            centered
            footer={null}
            onCancel={() => {
              setShowInfoModal(false);
            }}
            className="global-modal-css-scroll-height "
          >
            <div>
              <h4 className="font-bold text-2xl text-center text-gray-600	">
                {adminLabels.information}
              </h4>
            </div>
            <div className="scrollable-fixed-height py-5 render-html-class">
              <InformationContainer info={modal_Content} />
            </div>
          </Modal>
          <Modal
            visible={openConfirmationModal}
            width={400}
            footer={false}
            onCancel={() => setOpenConfirmationModal(false)}
          >
            <div>
              <div className="flex justify-center w-full">
                <AiFillQuestionCircle
                  size={80}
                  className="mr-4"
                  color={"#5ab3f0"}
                />
              </div>
              <div className="my-5">
                <h4 className="font-bold text-2xm text-center text-gray-600	">
                  Do you really want to Delete this Branch ?
                </h4>
              </div>
            </div>

            <div className="flex justify-between">
              <button
                className="w-2/5 bg-danger  rounded-full py-2 text-white"
                onClick={deleteBranch}
              >
                {adminLabels.yes}
              </button>
              <button
                onClick={() => setOpenConfirmationModal(false)}
                className="w-2/5 bg-primary rounded-full py-2 text-white"
              >
                {adminLabels.no}
              </button>
            </div>
          </Modal>
        </div>
      </Spin>
    </section>
  );
};
const mapStateToProps = (state) => {
  return {
    showBranches: state.branchList.branches,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchesList: () => {
      dispatch(FetchBranchesAction());
    },
    setLogoutUser: (data) => {
      dispatch(LogoutUserAction(data));
    },
    removeBeneficiaries: () => {
      dispatch(DeleteBeneficiariesAtLogoutTime());
    },
    removeProviders: () => {
      dispatch(DeleteProvidersAtLogoutTime());
    },
    removeServiceAreas: () => {
      dispatch(DeleteServiceAreasAtLogoutTime());
    },
    removeBranches: () => {
      dispatch(DeleteBranchesAtLogoutTime());
    },
    removeServices: () => {
      dispatch(DeleteServicesAtLogoutTime());
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Branches);
