const myConstant = {
  dispute: "dispute",
  disputed:"disputed",
  zero:"0",
  one:"1",
  two:"2",
  three:"3",
  four:"4",
  five:"5"
  

};
export default myConstant;
