const initialState = {
  loggedUserDetail: "",
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGGED_USER": {
      return { ...state, loggedUserDetail: action.payload };
    }
    case "LOGOUT_USER": {
      return {
        ...state,
        loggedUserDetail: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default LoginReducer;
