import React ,{useEffect} from "react";
import { Images } from "../../constant/Images";
import { AiFillStar } from "react-icons/ai";
import { BsCheckCircle } from "react-icons/bs";
import { CardProfile } from "../../components";
import { Link, useHistory } from "react-router-dom";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { Errors, Success } from "../../components/antdToast";

function AdminProfile() {
  const { goBack } = useHistory()

  return (
    <section className="mt-20">
      <div className="flex self-center items-center ml-10">
        <div onClick={() => goBack()} className="flex text-gray-400 items-center cursor-pointer">
          <RiArrowDropLeftLine />
          <span>Back</span>
        </div>
        <h3 className="font-bold text-lg mb-0 ml-10 	">Sub Admin Profile</h3>
      </div>
      <div className=" mr-36 ml-36 ">
        <br />
        <CardProfile
          name={"Shoaib"}
          rating={4.3}
          email={"abs@mail.com"}
          noOfManagers={34}
          nameOfService={"Business"}
        />
        <br />
        <div className=" rounded-lg bg-white p-4 grid grid-rows-3 grid-flow-col">
          <div className="grid grid-cols-4">
            <h3>
              Funtionalities and Features
            </h3>
          </div>
          <div className=" grid grid-cols-4">
            <div className="flex flex-row ">
              <BsCheckCircle color={"green"} size={"30"} />
              <span className="pl-3">Sub Admin Account Management</span>
            </div>
            <div className="flex flex-row">
              <BsCheckCircle color={"green"} size={"30"} />
              <span className="pl-3">User Account Management</span>
            </div>
            <div className="flex flex-row">
              <BsCheckCircle color={"green"} size={"30"} />
              <span className="pl-3">Admin of Service Provider</span>
            </div>
          </div>
          <div className="grid pt-2 grid-cols-4">
            <div className="flex flex-row">
              <BsCheckCircle color={"green"} size={"30"} />
              <span className="pl-3">Account Management</span>
            </div>
            <div className="flex flex-row">
              <BsCheckCircle color={"green"} size={"30"} />
              <span className="pl-3">Categories Management</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdminProfile;
