import moment from "moment";
import { AiFillStar } from "react-icons/ai";
import { adminLabels } from "../../config/label/adminLabels";
import "./UserDetailContainerCard.css";

const UserDetailContainerCard = ({ dividerShow = false, providerDetails }) => {
  const currentSelectedLangue = localStorage.getItem("primaryLanguage")

  return (
    <div className="bg-white rounded-lg">
      <div className=" p-6  pl-3 pt-0 pb-3 flex w-full justify-between items-center">
        <div style={{ display: "flex" }} className="p-5 pb-0">
          <img
            className="rounded-full"
            src={providerDetails?.service_provider?.avatar}
            style={{ height: 48, width: 48 }}
          />
          <div className="pl-2">
            <span className="flex space-x-2">
              <strong className=" ">
                {providerDetails?.service_provider?.name}
              </strong>
              {/* <strong className=" ">{service_provider?.last_name}</strong> */}

              <div className="flex items-center ">
                <span className="text-sm mr-1" style={{ color: "#808080" }}>
                  &#40;
                </span>
                <p className="text-sm mb-0">
                  {parseFloat(
                    providerDetails?.service_provider?.average_rating
                      ? providerDetails?.service_provider?.average_rating
                      : 0
                  ).toFixed(1)}
                </p>
                <AiFillStar
                  color={"#FFC107"}
                  style={{
                    marginRight: 5,
                    marginTop: 3,
                    fontSize: "13px",
                  }}
                />
                <span className="text-sm mr-1" style={{ color: "#808080" }}>
                  &#41;
                </span>
              </div>
            </span>

            <p>{providerDetails?.service_provider?.email}</p>
          </div>
        </div>
      </div>
      <div className="bg-white container rounded-lg ">
        <div className="grid grid-cols-2 p-6 pl-3 pt-0 pb-3">
          {/* /////////////////details */}
          <div className="" style={{ position: "relative" }}>
            {dividerShow && (
              <div
                style={{
                  border: "1px solid gray",
                  opacity: 0.2,
                  marginBottom: "15px",
                }}
              />
            )}
            <div style={{ paddingLeft: 20 }}>
              <b> {adminLabels.phoneNo}</b>
              <p>{providerDetails?.service_provider?.phone}</p>
            </div>
          </div>
          <div className="" style={{ position: "relative" }}>
            {dividerShow && (
              <div
                style={{
                  border: "1px solid gray",
                  opacity: 0.2,
                  marginBottom: "15px",
                }}
              />
            )}
            <div style={{ paddingLeft: 20 }}>
              <b>{adminLabels.serviceName}</b>
              <p>{providerDetails?.service?.[currentSelectedLangue === "english" ? "name" : "name_sp"]}</p>
            </div>
          </div>

          <div className="mt-4" style={{ position: "relative" }}>
            {dividerShow && (
              <div
                style={{
                  border: "1px solid gray",
                  opacity: 0.2,
                  marginBottom: "15px",
                }}
              />
            )}
            <div style={{ paddingLeft: 20 }}>
              <b>{adminLabels.registeredDate}</b>
              <p>
                {moment(providerDetails?.created_at).format("DD-MMM-YYYY")}
              </p>
            </div>
          </div>

          <div className="mt-4" style={{ position: "relative" }}>
            {dividerShow && (
              <div
                style={{
                  border: "1px solid gray",
                  opacity: 0.2,
                  marginBottom: "15px",
                }}
              />
            )}

            <div style={{ paddingLeft: 20 }}>
              <b>{adminLabels.workingHours}</b>

              <div className="flex flex-col items-start">
                <div
                  className={` flex flex-col items-start text-xs w-full ${
                    providerDetails?.service_provider?.working_hours
                      ?.length === 1
                      ? `py-2`
                      : `py-0`
                  }`}
                >
                  {" "}
                  {providerDetails?.service_provider?.working_hours?.map(
                    (hour) => {
                      return hour?.isFriday === false ? (
                        <div className=" w-full flex justify-between">
                          <span className="w-1/2">
                            {adminLabels?.monToThu} : {hour?.start} -{" "}
                            {hour?.end}
                          </span>
                          {hour?.hasOwnProperty("monTHuLunchHours") &&
                            hour?.monTHuLunchHours !== null && (
                              <span className=" w-1/2 text-right">
                                {adminLabels?.lunchTime} :{" "}
                                {hour?.monTHuLunchHours?.start} -{" "}
                                {hour?.monTHuLunchHours?.end}
                              </span>
                            )}
                        </div>
                      ) : (
                        <div className="w-full flex justify-between">
                          <span className="w-1/2">
                            {adminLabels?.fri} : {hour?.start} - {hour?.end}
                          </span>
                          {hour?.hasOwnProperty("fridayLunchHours") &&
                            hour?.fridayLunchHours !== null && (
                              <span className=" w-1/2 text-right">
                                {adminLabels.lunchTime} :{" "}
                                {hour?.fridayLunchHours?.start} -{" "}
                                {hour?.fridayLunchHours?.end}
                              </span>
                            )}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserDetailContainerCard;
