export const Remove_Data_Form_Reducers = (props) => {
  props.removeBeneficiaries();
  props.removeProviders();
  props.removeServiceAreas();
  props.removeBranches();
  props.removeServices();
  props.setLogoutUser("");
  props.setLoaderState(false);
  props.removeServiceForms();
  props.removeAppUsers();
};
