import moment from "moment";
import React, { useEffect, useState } from "react";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { ModalComponent } from "../../components";
import { Errors, Success } from "../../components/antdToast";
import ButtonComponent from "../../components/ButtonComponent";
import { Remove_Data_Form_Provider_Reducers } from "../../components/dataRemoveOnProviderLogout";
import OrderStepper from "../../components/orderSteppers";
import TextAreaComponent from "../../components/textArea";
import {
  GetOrdersById,
  SendOrderBillMethod,
  UploadFileMethod,
  uploadOrderStatusMethod,
} from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { functionToConvertStringToCamelCase } from "../../config/utils";
import { Images } from "../../constant/Images";
import {
  DeleteWorkerAtLogoutTime,
  FetchWorkersAction,
} from "../../Redux/actions/fetchWorkersAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { ProviderLogoutAction } from "../../Redux/actions/logoutAction";
import "./orderSteps.css";
let urlArray = [];
const OrderSteps = (props) => {
  const { goBack, push } = useHistory();
  const [reassign, setReassign] = useState(false);
  const [block, setBlock] = useState(false);
  const [steps, setSteps] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [orderInfo, setOrderInfo] = useState("");
  const [statusId, setStatusId] = useState("");
  const [urls, setUrls] = useState([]);
  const [orderStatus, setOrderStatus] = useState("");
  const [isFinish, setIsFininsh] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [muiltipleDocument, setMuiltipleDocument] = useState("");
  const [orderInVoiceBill, setOrderInVoiceBill] = useState("");
  const [disputedOrder, setDisputedOrder] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    getOrdersByProvider(props.match.params.id);
  }, []);

  const getOrdersByProvider = (id) => {
    GetOrdersById(
      id,
      (success) => {
        if (success?.status === 200) {
          if (success?.data?.response?.detail !== null) {
            setSelectedOrder(success?.data?.response?.detail);
            let filtered =
              success?.data?.response?.detail?.service?.service_statuses.filter(
                (item) => item.description !== ""
              );
            let mapped = filtered?.map((item, index, array) => {
              const slicedArray = filtered
                ?.slice(index)
                ?.some((item) => item?.order_status !== null)
                ? {
                    id: item.id,
                    info: "completed",
                    order_id: props.match.params.id,
                    service_status_id: item.id,
                  }
                : null;

              return {
                name: index + 1,
                status:
                  adminLabels[functionToConvertStringToCamelCase(item.status)],
                description: item.description,
                order_status:
                  item?.order_status !== null
                    ? item?.order_status
                    : slicedArray,
                id: item.id,
              };
            });

            if (
              success?.data?.response?.detail?.status === "dispute" ||
              success?.data?.response?.detail?.status === "disputed"
            ) {
              setDisputedOrder(true);
            } else {
              setDisputedOrder(false);
            }

            setSteps(mapped);
            let filteredByOrderStatus = mapped.filter(
              (item) => item.order_status !== null
            );
            setOrderStatus(
              mapped?.[filteredByOrderStatus.length - 1]?.order_status?.info
            );
            setShowForm(false);
            if (mapped?.[mapped.length - 1]?.order_status !== null) {
              setIsFininsh(true);
              setShowForm(false);
            } else {
              setIsFininsh(false);
            }
          }
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };

  const handleOrderFileInvoiceBill = (e) => {
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    UploadFileMethod(
      formData,
      (success) => {
        if (success.status === 200) {
          if (success.data.response.detail !== null) {
            setOrderInVoiceBill(success.data.response.detail);
            //getUrl(success.data.response.detail);
          }
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const selectMuiltipleDocument = async (valueArray) => {
    let muiltipleFileNames = "";
    valueArray.map((r, i) => {
      muiltipleFileNames += `${r.name}, `;
    });
    valueArray?.map((file, i) => {
      let formData = new FormData();
      formData.append("file", file);
      UploadFileMethod(
        formData,
        (success) => {
          if (success.status === 200) {
            if (success.data.response.detail !== null) {
              getUrl(success.data.response.detail);
            }
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            Remove_Data_Form_Provider_Reducers(props);
            push("/");
          } else if (error?.response?.status === 409) {
            Errors(error?.response?.data?.error?.message);
            props.setLoaderState(false);
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
          }
        }
      );
    });
    setMuiltipleDocument(muiltipleFileNames);
  };

  const getUrl = (url) => {
    urlArray.push(url);
    setUrls(urlArray);
  };

  const fetchOrderStatus = (status) => {
    setOrderStatus(status.info);
    setShowForm(false);
  };

  const fetchOrderStatusId = (id) => {
    setStatusId(id);
    setShowForm(true);
  };

  const finsihOrderStep = () => {
    let obj = {
      order_id: props.match.params.id,
      status_id: statusId,
      info: orderInfo,
    };
    if (statusId !== "" && orderInfo !== "") {
      props.setLoaderState(true);
      uploadOrderStatusMethod(
        obj,
        (success) => {
          if (success.status === 200) {
            Success(success.data.response.message);
            props.setLoaderState(false);
            setShowForm(false);
            getOrdersByProvider(props.match.params.id);
            setOrderInfo("");
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            Remove_Data_Form_Provider_Reducers(props);
            push("/");
          } else if (error?.response?.status === 409) {
            Errors(error?.response?.data?.error?.message);
            props.setLoaderState(false);
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
          }
        }
      );
    } else {
      Errors("Please Click on Stepper for status id");
    }
  };

  const sendBillToUser = () => {
    let obj = {
      order_id: selectedOrder.order_id,
      order_bill: orderInVoiceBill,
    };
    SendOrderBillMethod(
      obj,
      (success) => {
        if (success.status === 200) {
          Success(success.data.response.message);
          props.setLoaderState(false);
          setTimeout(() => {
            push("/admin/booking-management");
          }, 500);
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };

  return (
    <section>
      <div className="flex justify-between mt-20 ">
        <div className="pl-5 flex self-center justify-center items-start">
          <div
            onClick={() => goBack()}
            className="flex text-gray-400 items-center cursor-pointer"
          >
            <RiArrowDropLeftLine />
            <span>{adminLabels.back}</span>
          </div>
          <div className="flex justify-between w-full">
            <div className="flex flex-col">
              <h3 className="ml-10	">
                {adminLabels.orderId}: {id}
              </h3>{" "}
              {/* <div className="ml-10 ">
                <Link
                  to={`/admin/user-provider-order-messages/${id}`}
                  params={{ OrderID: id }}
                >
                  <span className="cursor-pointer view-datails-blue-color">
                    {adminLabels.orderChats}
                  </span>
                </Link>
              </div> */}
            </div>
          </div>
          {/* <h3 className="ml-10	">
            {adminLabels.orderId}: {id}
          </h3> */}
        </div>
      </div>
      <OrderStepper
        steps={steps}
        fetchFormData={fetchOrderStatusId}
        fetchOrderStatus={fetchOrderStatus}
        disputedOrder={disputedOrder}
      />
      <div className="grid grid-cols-3 ">
        <div className="col-start-2 col-end-2">
          {showForm ? (
            <div>
              <div className=" container rounded-lg  py-7 mt-5 pb-12 text-center items-center">
                <TextAreaComponent
                  disabled={disputedOrder}
                  placeholder={"Enter Order Status"}
                  value={orderInfo}
                  onChange={(e) => {
                    if (disputedOrder === false) {
                      setOrderInfo(e.target.value);
                    }
                  }}
                  shadowState={true}
                />
              </div>
              <div className="flex justify-center items-center">
                <div className="my-5 text-center w-1/2 justify-center items-center">
                  <ButtonComponent
                    onClick={finsihOrderStep}
                    className="bg-primary my-5 p-2"
                    title={adminLabels.finishThisStep}
                    loader={props.loader === true ? true : false}
                    disabled={disputedOrder || props.loader === true}
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="flex justify-center items-center flex-col mt-10">
                <h3>{adminLabels.orderStatusProgress}</h3>
                <p className="mt-5">{orderStatus}</p>
              </div>
              {isFinish === true ? (
                <div className="flex justify-center items-center">
                  <div className="my-5 text-center w-1/2 justify-center items-center">
                    <ButtonComponent
                      disabled={disputedOrder}
                      onClick={() => setReassign(true)}
                      className="bg-primary my-5 p-2"
                      title={adminLabels.finishThisOrder}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
      <ModalComponent
        filterModal={reassign}
        closeModal={() => setReassign(false)}
      >
        <div>
          <div className="justify-center">
            <div className="col-span-12 sm:col-span-6 md:col-span-6 flex">
              <img
                className="h-14"
                src={Images.userProfilePlaceholder.default}
              />
              <div className="pl-2 ">
                <span className="lab2">
                  {selectedOrder?.data?.[0]?.personal?.firstName +
                    " " +
                    selectedOrder?.data?.[0]?.personal.lastName}
                </span>
                <p>{adminLabels.customer}</p>
              </div>
            </div>
            <div className="divide-y divide-gray-200 divide-solid py-3">
              <div className="flex justify-between py-3 ">
                <span className="lab1">{adminLabels.service}</span>
                <span className="lab2">{selectedOrder?.service?.name}</span>
              </div>
              <div className="flex justify-between  py-3">
                <span className="lab1">{adminLabels.amount}</span>
                <span className="lab2">{`$${selectedOrder?.amount}`}</span>
              </div>
              <div className="flex justify-between  py-3">
                <span className="lab1">{adminLabels.dueDate}</span>
                <span className="lab2">
                  {moment(selectedOrder?.created_at)
                    .add(
                      selectedOrder?.service?.time,
                      selectedOrder?.service?.time_type
                    )
                    .local()
                    .format("DD-MMM-YYYY")}
                </span>
              </div>
              <div className="flex justify-between  py-3 items-center">
                <span className="lab1">{adminLabels.addBill}</span>
                {/* <button className="ml-4 text-white bg-primary rounded-full h-12 w-12">
                  +
                </button> */}
                <div className="file btn btn-lg btn-primary orderBillUploadDiv">
                  {orderInVoiceBill !== ""
                    ? adminLabels.fileUploaded
                    : adminLabels.upload}
                  <input
                    type="file"
                    name="file"
                    className="uploadInput"
                    onChange={handleOrderFileInvoiceBill}
                  />
                </div>
              </div>
              <div className="flex justify-between  py-3">
                <span className="lab1">{adminLabels.totalAmount}</span>
                <span className="lab2">{`$${selectedOrder?.amount}`}</span>
              </div>
            </div>
          </div>
          <ButtonComponent
            onClick={() => sendBillToUser()}
            className="bg-primary my-5 p-2"
            title={adminLabels.sendToUser}
            disabled={props.loader === true}
            loader={props.loader === true ? true : false}
          />
        </div>
      </ModalComponent>
      {disputedOrder === false && (
        <ModalComponent
          filterModal={block}
          closeModal={(e) => setBlock(false)}
          modalTitle="Reason of Block"
        >
          <div>
            <div className="justify-center">
              <div className=" col-span-12 lg:col-span-6 sm:col-span-12 md:col-span-12 mt-5">
                <form>
                  <textarea
                    className="p-2 rounded-lg w-full shadow-md"
                    placeholder="Reason"
                    rows="6"
                  ></textarea>
                </form>
              </div>
              <button className="w-full bg-primary rounded-full py-3 text-white mt-4">
                {adminLabels.send}
              </button>
            </div>
          </div>
        </ModalComponent>
      )}
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    showWorkers: state.workerList.workers,
    loader: state.loaderToggle.loaderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
    getWorkers: (props) => {
      dispatch(FetchWorkersAction(props));
    },
    removeWorkerList: () => {
      dispatch(DeleteWorkerAtLogoutTime());
    },
    setProviderLogout: () => {
      dispatch(ProviderLogoutAction());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderSteps);
