import { Button, Space, Tag } from "antd";
import React, { useState } from "react";
import { FcDown } from "react-icons/fc";
import TableComponent from "../../components/table";
import { Images } from "../../constant/Images";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";

const columns = [
  {
    align: "center",
    title: "No",
    dataIndex: "no",
    key: "no",
    width:40,
    render: (text) => <a>{text}</a>,
  },
  {
    align: "center",
    title: "Name",
    dataIndex: "fullname",
    key: "fullname",
    width:120,
    
  },
  {
    align: "center",
    title: "Branches covered",
    dataIndex: "branchesCovered",
    key: "branchesCovered",
    width: 180,
  },
  {
    align: "center",
    title: "Completed Bookings",
    key: "completedBookings",
    dataIndex: "completedBookings",
    width: 180,
  },
  {
    align: "center",
    title: "Open Bookings",
    dataIndex: "openBookings",
    key: "openBookings",
    width: 160,
  },
  {
    align: "center",
    title: "Payment Release Requests",
    key: "paymentReleaseRequests",
    dataIndex: "paymentReleaseRequests",
    width: 220,
  },
  {
    align: "center",
    title: "Total Revenue",
    key: "totalRevenue",
    dataIndex: "totalRevenue",
    width: 160,
  },
  {
    align: "center",
    title: "Region",
    key: "region",
    dataIndex: "region",
    width: 160,
  },
  {
    align: "center",
    title: "Services covered",
    key: "servicecovered",
    dataIndex: "servicecovered",
    width: 160,
  },
  {
    align: "center",
    title: "Individual Rating",
    key: "individualrating",
    dataIndex: "individualrating",
    width: 160,
  },
  {
    align: "center",
    title: "Overall Rating",
    key: "overallrating",
    dataIndex: "overallrating",
    width: 160,
  },
  {
    align: "center",
    title: "Action",
    key: "action",
    width:120,
    render: (text, index) => (
      <div className="text-center">
        <Link to={"/service-provider-detail"}>
          <Button
            type="disable"
            shape={"round"}
            className="background-color-gray whiteTxt"
          >
            View Details
          </Button>
        </Link>
      </div>
    ),
  },
];

const columns1 = [
  {
    align: "center",
    title: "No",
    dataIndex: "no",
    key: "no",
    width:40,
    render: (text) => <a>{text}</a>,
  },
  {
    align: "center",
    title: "Name",
    dataIndex: "fullname",
    key: "fullname",
    width:120,

  },
  {
    align: "center",
    title: "Individual Rating",
    key: "individualRating",
    dataIndex: "individualRating",
    width: 180,
  },
  {
    align: "center",
    title: "Complete Bookings",
    key: "completeBookings",
    dataIndex: "completeBookings",
    width: 180,
  },
  {
    align: "center",
    title: "Open Bookings",
    dataIndex: "openBookings",
    key: "openBookings",
    width: 140,
  },
  {
    align: "center",
    title: "Action",
    key: "action",
    width:120,
    render: (text, index) => (
      <div className="text-center">
        <Link to={"/service-provider-detail"}>
          <Button
            type="disable"
            shape={"round"}
            className="background-color-gray whiteTxt"
          >
            View Details
          </Button>
        </Link>
      </div>
    ),
  },
];
const data1 = [
  {
    no: "1",
    fullname: "John Brown",
    individualRating: "Provide Type",
    completeBookings: "65",
    openBookings: "53",
  },
  {
    no: "1",
    fullname: "John Brown",
    individualRating: "Provide Type",
    completeBookings: "65",
    openBookings: "53",
  },
  {
    no: "1",
    fullname: "John Brown",
    individualRating: "Provide Type",
    completeBookings: "65",
    openBookings: "53",
  },
];
const data = [
  {
    no: "1",
    fullname: "John Brown",
    branchesCovered: "Provide Type",
    completedBookings: "65",
    openBookings: "53",
    paymentReleaseRequests: "65",
    totalRevenue: "$850",
    region: "Spain",
    servicecovered: "service1,service2",
    individualrating: "4.3",
    overallrating: "4.3",
  },
  {
    no: "2",
    fullname: "John Brown",
    branchesCovered: "Provide Type",
    completedBookings: "65",
    openBookings: "53",
    paymentReleaseRequests: "65",
    totalRevenue: "$850",
    region: "Spain",
    servicecovered: "service1,service2",
    individualrating: "4.3",
    overallrating: "4.3",
  },
  {
    no: "3",
    fullname: "John Brown",
    branchesCovered: "Provide Type",
    completedBookings: "65",
    openBookings: "53",
    paymentReleaseRequests: "65",
    totalRevenue: "$850",
    region: "Spain",
    servicecovered: "service1,service2",
    individualrating: "4.3",
    overallrating: "4.3",
  },
];

export default function ServiceProviderUserReport(params) {
  const { goBack } = useHistory();
  const [reports, setReports] = useState(false);
  const [active, SetActive] = useState(false);
  return (
    <section className="">
      <div className="flex    flex-start sm:flex-start lg:justify-between flex-wrap sm:flex-wrap lg:flex-nowrap mt-20 ">
        <div className="pl-5 flex self-center justify-center items-center sub-h3">
          <div
            onClick={() => goBack()}
            className="flex text-gray-400 items-center cursor-pointer"
          >
            <RiArrowDropLeftLine />
            <span>Back</span>
          </div>
          <h3 className="ml-10	">Service Providers Users Reports</h3>
        </div>
        <div className="flex justify-center item-center">
          <div className="lg:mr-2  py-2">
            <Button
              shape="round"
              style={{
                backgroundColor: reports === false ? "#46bcff" : "white",
                color: reports === false ? "white" : "#000",
              }}
              onClick={() => {
                setReports(false);
              }}
            >
              Service Provider
            </Button>
          </div>
          <div className="lg:mr-2  py-2">
            <Button
              style={{
                backgroundColor: reports === true ? "#46bcff" : "white",
                color: reports === true ? "white" : "#000",
              }}
              onClick={() => {
                setReports(true);
              }}
              shape="round"
            >
              Worker of Service Provider
            </Button>
          </div>
          <div
            className="lg:mr-28 bg-white flex justify-center item-center"
            style={{
              borderRadius: "100px",
              padding: "10px",
              width: "40px",
              height: "40px",
            }}
          >
            <FcDown color="green" />
          </div>
        </div>
      </div>
      <div className="container mx-auto lg:px-20 mt-5">
        <TableComponent
          columns={reports ? columns1 : columns}
          data={reports ? data1 : data}
        />
      </div>
    </section>
  );
}
