import { LoadingOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Errors, Success } from "../../components/antdToast";
import { ProviderVerifyCodeApi } from "../../config/api";
import { spanishLabels } from "../../config/label/providerLabels";
import { Images } from "../../constant/Images";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";

const { logo } = Images;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Otp = (props) => {
  // declare variable
  const [OTP, setOTP] = useState("");
  const { push } = useHistory();
  useEffect(() => {
    !props.location.state && push({ pathname: `/` });
  }, []);

  const sendOTPForPassword = () => {
    let obj = {
      email: props.location.state,
      verification_code: OTP,
    };
    if (OTP) {
      props.setLoaderState(true);
      ProviderVerifyCodeApi(
        obj,
        (success) => {
          if (success?.status === 200) {
            Success(success?.data?.response?.message);
            props.setLoaderState(false);
            setOTP("");
            setTimeout(() => {
              push({ pathname: "/new-Password", state: obj.email });
            }, 1000);
          }
        },
        (error) => {
          if (error?.response?.status === 422) {
            Errors(error?.response?.data?.error?.message_sp);
            props.setLoaderState(false);
          } else {
            Errors("Network Error");
            props.setLoaderState(false);
          }
        }
      );
    }
  };
  return (
    <div className="bg-img">
      <div className="absolute left-14 top-absolute-40 w-1/4">
        <img src={logo.default} />
      </div>
      <div className="flex text-center h-screen	justify-center items-center">
        <div className="rounded-lg bg-white p-5 relative w-1/4 h-2/2">
          <img src={Images.verification.default} className="m-auto my-5" />
          <h3 className="text-center mb-10">
            {spanishLabels.enterVerificationCode}
          </h3>
          <p style={{ fontSize: 16 }}>{props.location.state}</p>
          <div className="my-12">
            <OtpInput
              value={OTP}
              onChange={(e) => setOTP(e)}
              numInputs={4}
              className="shadow-md border-radius10 mx-2"
              inputStyle={{
                borderRadius: 30,
                height: 55,
                width: 65,
              }}
              containerStyle={{
                justifyContent: "space-around",
                alignSelf: "center",
                alignItems: "center",
              }}
              separator={<span> </span>}
            />
          </div>
          <Button
            className="padding-sign-button"
            shape="round"
            type="primary"
            htmlType="submit"
            block
            onClick={sendOTPForPassword}
          >
            {props.loader === true ? (
              <Spin indicator={antIcon} />
            ) : (
              spanishLabels.confirm
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.loaderToggle.loaderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Otp);
