const initialState = {
  workers: [],
};

const GetWorkersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_WORKERS": {
      return {
        ...state,
        workers: action.payload,
      };
    }
    case "REMOVE_WORKERS": {
      return {
        ...state,
        workers: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default GetWorkersReducer;
