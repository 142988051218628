import React from "react";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useState } from "react";
function InputComponent({
  value,
  onChange,
  onKeyDown,
  placeholder,
  type = "text",
  onKeyPress = () => {},
  shadowState = false,
  width = "auto",
  maximum = 5,
  maxLength,
  disabled = false,
  classNames = "",
  minimum = "",
}) {
  const [hideShowPassword, setHideShowPassword] = useState(true);
  return (
    <div
      className={` mb-2 mt-2 rounded-lg ${classNames}`}
      style={{ width: width }}
    >
      {type === "password" ? (
        <div className="flex items-center rounded-3xl bg-white">
          <input
            className={`rounded-full py-3 px-4 w-full ${
              shadowState ? "shadow-md" : ""
            }`}
            type={`${hideShowPassword === true ? "password" : "text"}`}
            max={maximum}
            min={minimum}
            maxLength={maxLength}
            onChange={onChange}
            onKeyPress={onKeyPress}
            value={value}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            disabled={disabled}
          />
          <div
            className="p-2 cursor-pointer"
            onClick={() => setHideShowPassword(!hideShowPassword)}
          >
            {!hideShowPassword ? (
              <EyeOutlined
                style={{
                  color: "rgba(0,0,0,.45)",
                  fontSize: "16px",
                }}
              />
            ) : (
              <EyeInvisibleOutlined
                style={{
                  color: "rgba(0,0,0,.45)",
                  fontSize: "16px",
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <>
          <input
            className={`rounded-full py-3 px-4 w-full ${
              shadowState ? "shadow-md" : ""
            }`}
            type={type}
            max={maximum}
            min={minimum}
            maxLength={maxLength}
            onChange={onChange}
            onKeyPress={onKeyPress}
            value={value}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            disabled={disabled}
          />
        </>
      )}
    </div>
  );
}

export default InputComponent;
