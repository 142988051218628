import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { CountryCodes } from "../../assets/flags/CountryCodes";
import { Errors, Success } from "../../components/antdToast";
import ButtonComponent from "../../components/ButtonComponent";
import InputComponent from "../../components/InputComponent";
import { AddWorker, EditServiceWorker } from "../../config/api";
import { objectToFormData } from "../../config/formData/formData";
import { providerLabels } from "../../config/label/providerLabels";
import { Images } from "../../constant/Images";
import {
  DeleteWorkerAtLogoutTime,
  FetchWorkersAction,
} from "../../Redux/actions/fetchWorkersAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { ProviderLogoutAction } from "../../Redux/actions/logoutAction";

const { uploadImg } = Images;

function AddWorkerServiceAdmin(props) {
  const { goBack, push } = useHistory();
  // Declare Variable States
  const [fullName, setFullName] = useState("");
  const [surName, setSurName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+34");
  const [countryFlag, setCountryFlag] = useState(
    "https://cdn.britannica.com/36/4336-004-6BD81071/Flag-Spain.jpg"
  );
  const [visible, setVisible] = useState(false);
  const [fieldIsRequired, setFieldIsrequired] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [countryCodeState, setCountryCodeState] = useState(
    CountryCodes.map((country) => {
      return {
        ...country,
        name: country.name.toLowerCase(),
      };
    })
  );
  const [searchCountry, setSearchCountry] = useState("");
  const handleMenuClick2 = (e) => {
    setCountryCode("+" + e.key);
    if (e.key === "3") {
      setVisible(false);
    }
  };
  let history = useHistory();
  useEffect(() => {
    setAvatar(null);
    if (props?.match?.params?.id) {
      props?.location?.state === undefined &&
        push("/worker-of-service-provider");
      let worker = props?.location?.state?.worker;
      setFullName(worker?.name);
      setEmail(worker?.email);
      setAvatar(worker?.avatar);
      setCountryCode(worker?.phone.split("-")[0]);
      setPhoneNumber(worker?.phone.split("-")[1]);
      let flag = CountryCodes.map((cc) => cc)?.filter((country, index) => {
        return country?.phoneCode === worker?.phone.split("-")[0].toString();
      });

      setCountryFlag(!flag.length ? "ES" : flag[0].code);
    }
  }, []);

  const onKeyDown = (e) => {
    if (e.keyCode === 8 || e.keyCode == 46) {
      setCountryCodeState(
        CountryCodes.map((country) => {
          return {
            ...country,
            name: country.name.toLowerCase(),
          };
        })
      );
    }
  };
  const countryHandleChange = (e) => {
    setSearchCountry(e.target.value);

    if (e.target.value === "" || e.target.value.trim().length === 0) {
      setCountryCodeState(
        CountryCodes.map((country) => {
          return {
            ...country,
            name: country.name.toLowerCase(),
          };
        })
      );
    } else {
      setCountryCodeState(
        countryCodeState.filter((country) => {
          return country.name.includes(e.target.value.toLowerCase());
        })
      );
    }
  };
  const countryCodeBlur = () => {
    setSearchCountry("");
  };
  const menu = (
    <Menu className="menu-class country-menu" onClick={handleMenuClick2}>
      <div>
        <input
          className="pl-2"
          type="text"
          placeholder="search"
          value={searchCountry}
          onChange={countryHandleChange}
          onKeyDown={onKeyDown}
          onBlur={countryCodeBlur}
        />
      </div>
      <Menu.Divider />
      {countryCodeState.map((country, index) => {
        var intKey = parseInt(country.phoneCode);
        return (
          <Menu.Item key={intKey} onClick={() => setCountryFlag(country.code)}>
            <div className="flex bg-white 	py-2 w-full pl-2 pr-10 rounded-full items-center">
              <img
                src={require(`../../assets/flags/${country.code}.png`).default}
                style={{ width: 15, height: 15, margin: 2, Radius: 20 }}
              />
              <span>{country.phoneCode}</span>
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const onSubmitWorker = () => {
    if (
      avatar !== null &&
      fullName !== "" &&
      phoneNumber !== "" &&
      email !== ""
    ) {
      let obj = {
        avatar,
        name: fullName,
        email,
        phone: `${countryCode}-${phoneNumber}`,
      };
      let data = objectToFormData(obj);
      props.setLoaderState(true);
      AddWorker(
        data,
        (success) => {
          if (success.status === 200) {
            props.setLoaderState(false);
            Success(success.data.response.message);
            setFieldIsrequired(false);
            setAvatar(null);
            setEmail("");
            setFullName("");
            setCountryCode("+34");
            setPhoneNumber("");
            setCountryFlag(
              "https://cdn.britannica.com/36/4336-004-6BD81071/Flag-Spain.jpg"
            );
            props.getWorkers(props);
            history.push("/worker-of-service-provider");
          }
          props.getWorkers(props);
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            setFieldIsrequired(false);
          } else if (error?.response?.status === 409) {
            Errors(error?.response?.data?.error?.message);
            props.setLoaderState(false);
            setFieldIsrequired(false);
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
            setFieldIsrequired(false);
          }
        }
      );
    } else {
      setFieldIsrequired(true);
    }
  };

  const editWorker = () => {
    if (
      avatar !== null &&
      fullName !== "" &&
      // surName !== "" &&
      phoneNumber !== "" &&
      email !== ""
    ) {
      let obj = {
        avatar,
        name: fullName,
        email,
        phone: `${countryCode}-${phoneNumber}`,
        service_provider_id: props.match.params.id,
      };
      let data = objectToFormData(obj);
      props.setLoaderState(true);
      EditServiceWorker(
        data,
        (success) => {
          if (success.status === 200) {
            props.setLoaderState(false);
            Success(success.data.response.message);
            setFieldIsrequired(false);
            setAvatar(null);
            setEmail("");
            setFullName("");
            // setSurName("");
            setCountryCode("+34");
            setPhoneNumber("");
            setCountryFlag(
              "https://cdn.britannica.com/36/4336-004-6BD81071/Flag-Spain.jpg"
            );
            props.getWorkers(props);
            history.push("/worker-of-service-provider");
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            setFieldIsrequired(false);
            // Remove_Data_Form_Reducers(props);
            // push("/admin");
          } else if (error?.response?.status === 409) {
            Errors(error?.response?.data?.error?.message);
            props.setLoaderState(false);
            setFieldIsrequired(false);
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
            setFieldIsrequired(false);
          }
        }
      );
    } else {
      setFieldIsrequired(true);
    }
  };

  return (
    <section className="mt-5">
      <div className="flex self-center items-center ml-12 pt-5 ">
        <div
          onClick={() => goBack()}
          className="flex text-gray-400 items-center cursor-pointer"
        >
          <RiArrowDropLeftLine />
          <span>{providerLabels.back}</span>
        </div>
        <h3 className="font-bold text-lg mb-0 ml-10">
          {props.match.params.id
            ? providerLabels.editWorkerOfServiceProvider
            : providerLabels.addWorkerOfServiceProvider}
        </h3>
      </div>
      <div className="container mx-auto px-20">
        <h5 className="mb-0 mt-5  ml-5 text-gray-500 font-bold">
          <strong>{providerLabels.personalDetails}</strong>
        </h5>
        <div className="mt-5 grid grid-cols-12">
          <div className="col-span-12  lg:col-span-3 sm:col-span-6 md:col-span-6 mt-2 lg:ml-2 relative cursor-pointer mx-4 sm:mx-4 lg:mr-2 lg:mx-0">
            <input
              className="rounded-full py-3 px-9 w-full absolute opacity-0  cursor-pointer"
              type={"file"}
              accept={".png, .jpg, .jpeg"}
              onChange={(e) => {
                setAvatar(e.target.files[0]);
              }}
              disabled={props.loader === true ? true : false}
            />
            <button className="bg-gray-400 rounded-full py-3 px-2 text-sm flex justify-center items-center w-full">
              <img
                style={{ cursor: "pointer" }}
                width="20"
                height="20"
                src={uploadImg.default}
                className="mr-0 sm:mr-0 lg:mr-1"
              />
              <span className="text-white">
                {avatar !== null ? avatar?.name : avatar}
              </span>
            </button>
            {avatar === null && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">
                {providerLabels.pleaseFillTheField}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 lg:ml-2">
            <InputComponent
              value={fullName}
              placeholder={providerLabels.name}
              onChange={(e) => setFullName(e.target.value)}
              disabled={props.loader === true ? true : false}
            />
            {fullName === "" && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">
                {providerLabels.pleaseFillTheField}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 ml-2 ">
            {/* <InputComponent
              value={surName}
              placeholder={"Sur Name"}
              value={surName}
              onChange={(e) => setSurName(e.target.value)}
              disabled={props.loader === true ? true : false}
            />
            {surName === "" && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">{providerLabels.pleaseFillTheField}</span>
            ) : (
              ""
            )} */}
          </div>
          <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6"></div>
          <div className="col-span-12  lg:col-span-3 sm:col-span-6 md:col-span-6  lg:ml-2 relative cursor-pointer mx-4 sm:mx-4 lg:mr-2 lg:mx-0">
            <div className="flex">
              <div className="flex items-center mr-2">
                <Dropdown
                  overlay={menu}
                  className={"flex height45 cursor-pointer"}
                  trigger={["click"]}
                  disabled={props.loader === true ? true : false}
                >
                  <div className="flex bg-white w-max	py-2 w-full pl-2  pr-10 rounded-full items-center lg:mr-2">
                    <img
                      src={
                        countryFlag ===
                        "https://cdn.britannica.com/36/4336-004-6BD81071/Flag-Spain.jpg"
                          ? countryFlag
                          : require(`../../assets/flags/${countryFlag}.png`)
                              .default
                      }
                      style={{
                        width: 15,
                        height: 15,
                        Radius: 20,
                        margin: 2,
                      }}
                    />
                    <span className="text-sm">{countryCode}</span>
                    <DownOutlined className="ml-1" />
                  </div>
                </Dropdown>
              </div>
              <div className="w-full">
                <InputComponent
                  placeholder={providerLabels.phoneNo}
                  type="number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  disabled={props.loader === true ? true : false}
                />
              </div>
            </div>
            {phoneNumber === "" && fieldIsRequired === true ? (
              <span className="text-red-500 ">
                {providerLabels.pleaseFillTheField}
              </span>
            ) : (
              ""
            )}
          </div>

          <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 lg:ml-2">
            <InputComponent
              value={email}
              className="row-span-2"
              placeholder={providerLabels.email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={
                props.loader === true
                  ? true
                  : props?.match?.params?.id
                  ? true
                  : false
              }
            />
            {email === "" && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">
                {providerLabels.pleaseFillTheField}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="w-full sm:w-3/12 mt-3 ml-2 pr-4">
          {/* <Link to='/admin-profile' className='text-white' > */}
          <ButtonComponent
            className="bg-primary"
            title={providerLabels.save}
            onClick={props?.match?.params?.id ? editWorker : onSubmitWorker}
            loader={props.loader === true ? true : false}
            disabled={props.loader === true ? true : false}
          />
          {/* </Link> */}
        </div>
      </div>
    </section>
  );
}
const mapStateToProps = (state) => {
  return {
    loader: state.loaderToggle.loaderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
    getWorkers: (props) => {
      dispatch(FetchWorkersAction(props));
    },
    removeWorkerList: () => {
      dispatch(DeleteWorkerAtLogoutTime());
    },
    setProviderLogout: () => {
      dispatch(ProviderLogoutAction());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddWorkerServiceAdmin);
