import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { spanishLabels } from "../config/label/adminLabels.js";
import { Images } from "../constant/Images";
import { LoaderStateAction } from "../Redux/actions/loaderStateAction";
import { SetLoginDataInAction } from "../Redux/actions/loginInfoAction";
import { SessionContext } from "../store";




const { loginLogo } = Images;

const Cards = (props) => {
  const [dashboard, setDashboard] = React.useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [isEnable, setIsEnable] = useState(false);
  const { setSession } = useContext(SessionContext);
  const history = useHistory();

  useEffect(() => {
    if (props.loginInfo !== "") {
      history.push("/admin/dashboard");
    }
    document.title = spanishLabels.DoCuDocAdmin

    // localStorage.setItem("primaryLanguage", "spanish");
  }, []);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const onFinish = (values) => {
    props.setLoaderState(true);
    sumbitLoggedUser(email, password);
  };

  const onFinishFailed = (errorInfo) => {
  };

  const sumbitLoggedUser = (e, p) => {
    let data = {
      email: e,
      password: p,
    };
    props.getLoginInfo(data, props, props.props);
  };

  return (
    <>
      <div className="absolute left-14 top-absolute-40  hidden sm:hidden lg:block w-1/4 ">
        <img src={loginLogo.default} />
        <h3 className="font-bold ml-4 mt-2" id="welcome-heading">
          {spanishLabels.welcomeAdmin}
        </h3>
        {/* <p className="mt-4 text-gray-500 ml-4 text-sm text-left welcome-text">
         {spanishLabels.helloAdmin}
        </p> */}
      </div>
      <div className="flex text-center h-screen	justify-center items-center">
        <div className="rounded-lg bg-white  p-10  relative m-4 sm:m-4 md:m-8 lg:m-0  w-full sm:w-full md:w-full lg:w-1/4">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div className="mt-2 mb-12">
              <h3 className=" text-center text-gray-600  font-normals">
                {spanishLabels.logIn}
              </h3>
            </div>
            <Form.Item
              name="username"
              style={{ textAlign: "left" }}
              rules={[
                {
                  required: true,
                  message: spanishLabels.pleaseInputYoureEmailAddress,
                },
                {
                  type: "email",
                  message: spanishLabels.theInputIsNotvalidEMail,
                },
              ]}
            >
              <Input
                className="shadow-md border-radius20 p-3"
                placeholder={spanishLabels.emailAddress}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={props.loader === true ? true : false}
              />
            </Form.Item>
            <Form.Item
              className="form"
              name="password"
              style={{ textAlign: "left" }}
              rules={[
                { required: true, message: spanishLabels.pleaseEnterYourPassword },
              ]}
            >
              <Input.Password
                className="shadow-md border-radius20 p-3"
                placeholder={spanishLabels.password}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={props.loader === true ? true : false}
              />
            </Form.Item>
            <Form.Item>
              <Button
                className="padding-sign-button bg-primary capitalize"
                shape="round"
                type="primary"
                htmlType="submit"
                block
                disabled={props.loader === true ? true : false}
              >
                {spanishLabels.logIn}
                {props.loader === true ? <Spin indicator={antIcon} /> : ""}
              </Button>
            </Form.Item>

            {/* <div className="absolute bottom-3">
              <span>Don't have an account?</span>
              <a href="#">Let's Sign up</a>
            </div> */}
          </Form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loginInfo: state.loginDetail.loggedUserDetail,
    loader: state.loaderToggle.loaderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLoginInfo: (data, props, props1) => {
      dispatch(SetLoginDataInAction(data, props, props1));
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cards);