import { SearchOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Menu, Spin, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiOutlineArrowDown, AiOutlinePlus } from "react-icons/ai";
import {
  RiArrowDropLeftLine,
  RiArrowDropRightLine,
  RiPencilLine,
} from "react-icons/ri";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Errors, Success } from "../../components/antdToast";
import { Remove_Data_Form_Provider_Reducers } from "../../components/dataRemoveOnProviderLogout";
import TableComponent from "../../components/table";
import {
  GetWorkers,
  UpdateServiceProviderStatusMethod,
} from "../../config/api";
import { providerLabels } from "../../config/label/providerLabels";
import { Images } from "../../constant/Images";
import {
  DeleteWorkerAtLogoutTime,
  FetchWorkersAction,
} from "../../Redux/actions/fetchWorkersAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { ProviderLogoutAction } from "../../Redux/actions/logoutAction";

const WorkerServiceProvider = (props) => {
  const [workers, setWorkers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { goBack, push } = useHistory();

  useEffect(() => {
    getWorkers();

    document.body.style.overflow = "auto";
    return function cleanup() {
      document.body.style.overflow = "auto";
    };
  }, []);

  const setServiceWorkerActive = (id) => {
    // let userId = new FormData();
    // userId.append("service_provider_id", id);

    let userId = new FormData();
    userId.append("service_provider_id", id);
    UpdateServiceProviderStatusMethod(
      userId,
      (success) => {
        if (success.status === 200) {
          getWorkers();
          let serviceWorkerUpdatedArray = workers.map((item) => {
            if (item.service_provider_id === id) {
              return { ...item, status: item.status === 1 ? 0 : 1 };
            } else {
              return item;
            }
          });
          setWorkers(serviceWorkerUpdatedArray);
          Success(success?.data?.response?.message);
          // fetchServiceProviders();
        }
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  const menu = (id, text) => {
    return (
      <Menu className="menu-class menu-main-class" style={{ borderRadius: 20 }}>
        <Menu.Item className="bg-white" style={{ borderRadius: 20 }}>
          <Link to={`/worker-detail/${id}`} params={{ id }}>
            <div
              className="flex justify-between bg-white py-2 w-full  items-center"
              style={{ width: 160 }}
            >
              <div>
                <span>{providerLabels.workerDetails}</span>
              </div>
              <div>
                <RiArrowDropRightLine color="#5ab3f0" size={20} />
              </div>
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item
          className="bg-white"
          style={{ borderRadius: 20 }}
          key={text.id + Math.random()}
          onClick={() => handleMenu(id, text)}
        >
          <div
            className="flex justify-between bg-white py-2 w-full  items-center"
            style={{ width: 160 }}
          >
            <div>
              <span>{providerLabels.edit}</span>
            </div>
            <div>
              <RiPencilLine color="#46bcff" size={20} />
            </div>
          </div>
        </Menu.Item>
        <Menu.Item
          className="bg-white"
          style={{ borderRadius: 20 }}
          key={text.id + Math.random()}
        >
          <div
            className="flex justify-between bg-white py-2 w-full  items-center"
            style={{ width: 160 }}
          >
            <div>
              <span>{providerLabels.status}</span>
            </div>

            <div>
              <Switch
                size="small"
                onChange={() =>
                  setServiceWorkerActive(text.service_provider_id)
                }
                checked={text?.status === 1 ? true : false}
              />
            </div>
          </div>
        </Menu.Item>
      </Menu>
    );
  };
  const handleMenu = (id, worker) => {
    push({
      pathname: `/edit-worker-of-service-provider/${id}`,
      state: { worker: worker },
    });
  };
  const columns = [
    {
      align: "center",
      title: providerLabels.no,
      dataIndex: "no",
      key: "no",
      width: 40,
      render: (text) => <a>{text}</a>,
    },
    {
      align: "center",
      title: providerLabels.name,
      dataIndex: "detail",
      key: "detail",
      width: 120,

      render: (text, index) => (
        <div className={"flex"}>
          <img
            src={text.avatar !== null ? text.avatar : Images.profile.default}
            style={{ width: 50, height: 45 }}
            className="rounded-lg"
            alt="detail image"
          />
          <div className="pl-2 text-left mt-2 capitalize">
            <label>{text.name}</label>
            <br />
          </div>
        </div>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.detail?.name
          ?.toLowerCase()
          .includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.detail?.name?.localeCompare(b?.detail?.name),
    },

    {
      align: "center",
      title: providerLabels.email,
      dataIndex: "email",
      key: "email",
      width: 120,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.email?.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
    },
    {
      align: "center",
      title: providerLabels.phone,
      key: "phone",
      dataIndex: "phone",
      width: 100,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.phone?.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
    },

    {
      align: "center",
      title: providerLabels.action,
      key: "action",
      width: 40,

      render: (text, index) => (
        <div className="text-center	">
          {/* <Link to="/order-info-detail"> */}
          <Dropdown
            overlay={() => menu(text.service_provider_id, text)}
            className={"flex"}
            trigger={["click"]}
          >
            <div
              type="disable"
              shape={"round"}
              className="background-color-gray whiteTxt rounded-full py-3 justify-center cursor-pointer	"
            >
              <span>{providerLabels.viewDetails}</span>
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  const getWorkers = () => {
    GetWorkers(
      (success) => {
        if (success?.status === 200) {
          const data = success?.data?.response?.detail;
          const mappedWorkers = data?.map((worker, index) => {
            return {
              ...worker,
              no: index + 1,
              detail: { avatar: worker.avatar, name: worker.name },
            };
          });
          setWorkers(mappedWorkers);
          setLoading(false);
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          props.history.push("/");
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  return (
    <Spin tip={providerLabels.loading} spinning={loading}>
      <section className="">
        <div className="flex flex-center sm:flex-center  lg:justify-between flex-wrap sm:flex-wrap lg:flex-nowrap mt-5 ml-5 ">
          <div className="flex self-center justify-center items-center ">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{providerLabels.back}</span>
            </div>
            <h3 className="ml-10	">{providerLabels.workers}</h3>
          </div>
          <div className="lg:mr-28 flex space-x-1">
            <Link to="/add-worker-of-service-provider">
              <Button
                shape={"round"}
                className="icon-button"
                icon={<AiOutlinePlus color="#5ab3f0" />}
              >
                {providerLabels.addNew}
              </Button>
            </Link>
            <CSVLink
              data={
                workers?.map((item, index) => {
                  return {
                    no: index + 1,
                    name: item?.name,
                    email: item?.email,
                    phone: item?.phone,
                  };
                }) || []
              }
              headers={[
                { label: providerLabels.no, key: "no" },
                { label: providerLabels.name, key: "name" },
                { label: providerLabels.email, key: "email" },
                { label: providerLabels.phone, key: "phone" },
              ]}
              filename={`${providerLabels.workers}.csv`}
              target="_blank"
            >
              <Button
                shape={"round"}
                className="icon-button "
                icon={<AiOutlineArrowDown color="#5ab3f0" />}
              >
                {providerLabels.exportCSV}
              </Button>
            </CSVLink>
          </div>
        </div>
        <div className="container mx-auto lg:px-20 mt-5">
          <TableComponent
            columns={columns}
            data={workers}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
            scrolly="500px"
          />
        </div>
      </section>
    </Spin>
  );
};

const mapStateToProps = (state) => {
  return {
    showWorkers: state.workerList.workers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
    getWorkers: (props) => {
      dispatch(FetchWorkersAction(props));
    },
    removeWorkerList: () => {
      dispatch(DeleteWorkerAtLogoutTime());
    },
    setProviderLogout: () => {
      dispatch(ProviderLogoutAction());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkerServiceProvider);
