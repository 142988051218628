import { Switch } from "antd";
import { AiFillStar } from "react-icons/ai";
import moment from "moment";
import { List, message, Avatar, Skeleton, Divider } from "antd";
import { UpdateServiceProviderStatusMethod } from "../../config/api";
import { Errors, Success } from "../../components/antdToast";
import "./UserDetailContainerCard.css";
import { adminLabels } from "../../config/label/adminLabels";
const UserDetailContainerCard = ({
  dividerShow = false,
  providerDetails,
  services,
  updateServiceWorkerData,
}) => {
  const setServiceProviderActive = (id) => {
    let userId = new FormData();
    userId.append("service_provider_id", id);
    UpdateServiceProviderStatusMethod(
      userId,
      (success) => {
        if (success.status === 200) {
          updateServiceWorkerData();
          Success(success?.data?.response?.message);
        }
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  return (
    <div className="bg-white rounded-lg">
      <div className=" p-6  pl-3 pt-0 pb-3 flex w-full justify-between items-center">
        <div style={{ display: "flex" }} className="p-5 pb-0">
          <img
            className="rounded-full"
            src={providerDetails?.avatar}
            style={{ height: 48, width: 48 }}
          />
          <div className="pl-2">
            <span className="flex space-x-2">
              <strong className="capitalize">{providerDetails?.name}</strong>
            </span>

            <p>{providerDetails?.email}</p>
          </div>
        </div>
        <div>
          <div className="flex flex-col">
            <Switch
              size="small"
              onChange={() =>
                setServiceProviderActive(providerDetails?.service_provider_id)
              }
              checked={providerDetails?.status === 1 ? true : false}
            />
            <span>{adminLabels.status}</span>
          </div>
        </div>
      </div>
      <div className="bg-white container rounded-lg ">
        <div className="grid grid-cols-2 p-6 pl-3 pt-0 pb-3">
          {/* /////////////////details */}
          <div className="" style={{ position: "relative" }}>
            {dividerShow && (
              <div
                style={{
                  border: "1px solid gray",
                  opacity: 0.2,
                  marginBottom: "15px",
                }}
              />
            )}
            <div style={{ paddingLeft: 20 }}>
              <b>{adminLabels.phone}</b>
              <p>{providerDetails?.phone}</p>
            </div>
          </div>

          <div className="" style={{ position: "relative" }}>
            {dividerShow && (
              <div
                style={{
                  border: "1px solid gray",
                  opacity: 0.2,
                  marginBottom: "15px",
                }}
              />
            )}
            <div style={{ paddingLeft: 20 }}>
              <b>{adminLabels.providerName}</b>
              <p>{providerDetails?.provider?.name}</p>
            </div>
          </div>

          <div className="" style={{ position: "relative" }}>
            {dividerShow && (
              <div
                style={{
                  border: "1px solid gray",
                  opacity: 0.2,
                  marginBottom: "15px",
                }}
              />
            )}
            <div style={{ paddingLeft: 20 }}>
              <b>{adminLabels.serviceName}</b>
              {services.length === 0 ? (
                <p>N/A</p>
              ) : (
                <div>
                  <div id="scrollableDiv" className="userDetailCardServices">
                    <List
                      dataSource={services}
                      renderItem={(item) => (
                        <List.Item>
                          <div>{item.name}</div>
                        </List.Item>
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="" style={{ position: "relative" }}>
            {dividerShow && (
              <div
                style={{
                  border: "1px solid gray",
                  opacity: 0.2,
                  marginBottom: "15px",
                }}
              />
            )}
            <div style={{ paddingLeft: 20 }}>
              <b>{adminLabels.state}</b>
              <div>
                <div id="scrollableDiv" className="userDetailCardStates">
                  <List
                    dataSource={providerDetails?.provider?.state}
                    renderItem={(item) => (
                      <List.Item>
                        <div>{item}</div>
                      </List.Item>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4" style={{ position: "relative" }}>
            {dividerShow && (
              <div
                style={{
                  border: "1px solid gray",
                  opacity: 0.2,
                  marginBottom: "15px",
                }}
              />
            )}
            <div style={{ paddingLeft: 20 }}>
              <b>{adminLabels.registeredDate}</b>
              <p>{moment(providerDetails?.created_at).format("DD-MMM-YYYY")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserDetailContainerCard;
