const initialState = {
    Help: [],
  };
  
  const GeHelpReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_HELP": {
        return {
          ...state,
          Help: action.payload,
        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default GeHelpReducer;
  