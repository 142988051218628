import React, { useState } from "react";
import { Images } from "../../constant/Images";
import { useHistory } from "react-router-dom";
import { RiArrowDropLeftLine } from "react-icons/ri";
import ButtonComponent from "../../components/ButtonComponent";
import ReactQuill from "react-quill";
import { IoIosAttach } from "react-icons/io";
export default function AddMyMessage(params) {
  const { goBack } = useHistory();
  const [value, setValue] = useState("");
  const [valueOld] = useState([
    { message: '<p><strong>John</strong></p><br /> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>' },
    { message: '<p><strong>John</strong></p><br /> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>' },
  ]);
  return (
    <section>
      <div className="flex justify-between mt-20 mb-10">
        <div className="pl-5 flex self-center justify-center items-center w-full sm:w-full lg:w-1/2">
          <div onClick={() => goBack()} className="flex text-gray-400 items-center cursor-pointer">
            <RiArrowDropLeftLine />
            <span>Back</span>
          </div>
          <div className="flex justify-between w-full">
            <div className="flex">
              <h3 className="ml-8">Message To Sent Budget</h3>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>

        <div className="grid grid-cols-12 gap-3 mx-2 sm:mx-2 lg:mx-20">
          <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4">
            <div className="bg-white container rounded-lg px-5 py-3 mh64 ">
              <div className="bg-white container rounded-lg col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-3">
                {/* <h3 className="font-color-Dblue mt-5">Dear John,</h3> */}
                <div className="richTextContainer">
                  <div className='relative'>

                    <ReactQuill
                      className={"richTextBox shadow-lg"}
                      value={value}
                      onChange={setValue}
                    />
                    <div className='absolute cursor-pointer bottom-4 right-4 rounded-full p-2 bg-primary'>
                      <IoIosAttach color='#fff' />
                    </div>
                  </div>
                  <div className='flex'>
                    <div className='mr-6'>
                      <img src={'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500'} width='100px' />
                    </div>
                    <div>
                      <h4>
                        Nicolas Adolph
                      </h4>
                      <label>
                        Lawer
                      </label><br />
                      <label>DocuDoc Legal Services Team</label>
                      <img src={Images.logo.default} className="mt-5 mb-3" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4">
            <div className="items-center">
              <h3>Add a new Budget</h3>
            </div>
            <div className="richTextContainer">
              <ReactQuill
                className={"richTextBox shadow-lg"}
                value={value}
                onChange={setValue}
              />

              <div className="flex my-3 items-center justify-between">
                <label className="lab1">Estimated Time</label>
                <div className="flex">
                  <div className="wid-70 mx-2">
                    <ButtonComponent title="Hours" />
                  </div>
                  <div className="wid-70">
                    <ButtonComponent title="Days" type={true} />
                  </div>
                </div>
              </div>
              <div className="flex my-3 justify-between items-center">
                <label className="lab1">Budget</label>
                <div className="w-1/5">
                  <ButtonComponent title="Max" />
                </div>
              </div>
              <div className="flex my-2 justify-between">
                <label className="lab1">DocuDoc Deduction</label>
                <label className="lab1">30%</label>
              </div>
              <div className="flex my-2 justify-between">
                <label className="lab1">Cost Budget</label>
                <label className="lab1">€45</label>
              </div>
              <div className="px-20 mt-5">
                <ButtonComponent title="Send" />
              </div>
            </div>
          </div>
          <div className='col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4'>
            <div className="items-center">
              <h3>Messages History</h3>
            </div>
            {
              valueOld.map((t, i) => {
                return (
                  <div key={i} className='relative'>
                    <ReactQuill
                      theme="bubble"
                      className={"richTextBox shadow-lg"}
                      value={t.message}
                      readOnly
                    // onChange={setValueOld}
                    />
                    <div className='message-time'>
                      <span>
                        24-08-2021 05:30 PM
                      </span>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>

    </section>
  );
}
