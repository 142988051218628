import { Errors } from "../../../../components/antdToast";
import { ServiceProviderAdditionalBudgets } from "../../../../config/api";

export const fetchServiceProviderAdditionalBudgets = (id, push) => {
  return (dispatch) => {
    ServiceProviderAdditionalBudgets(
      id,
      (success) => {
        if (success?.status === 200) {
          dispatch({
            type: "GET_PROVIDER_ADDITIONAL_BUDGETS",
            payload: success?.data?.response?.detail,
            loading: false,
          });
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          Errors(error?.response?.data?.message);
          push("/");
        } else {
          Errors("Network Error");
        }
      }
    );
  };
};
