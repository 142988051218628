import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Spin } from "antd";

import moment from "moment";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiOutlineArrowDown } from "react-icons/ai";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import InformationContainer from "../../components/informationContainer";
import TableComponent from "../../components/table";
import { getServiceProviderBillLogs } from "../../config/api";
import { providerLabels } from "../../config/label/providerLabels";
import {
  currencyFormat,
  europeanCurrencyFormatter,
  functionToConvertStringToCamelCase,
  onePointOffOForderOriginalPriceWithVat,
  orderDiscountPercentage,
  orderDiscountPriceWithVat,
  orderOriginalPriceWithVat,
} from "../../config/utils";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function ServiceProviderIncomePaymentsBills(props) {
  const currentSelectedLangue = localStorage.getItem("primaryLanguage");

  const serviceProvider = JSON?.parse(JSON?.parse(localStorage.getItem("persist:root"))?.providerLoginDetail)?.loggedUserDetailOfProvider?.admin;
  const [loading, setLoading] = useState(false);
  const [billLogsData, setBillLogsData] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [modal_Content, setModalContent] = useState("");

  const { goBack } = useHistory();
  const tableColumns = [
    {
      align: "center",
      title: providerLabels.no,
      dataIndex: "no",
      key: "no",
      width: 100,
    },
    {
      align: "center",
      title: providerLabels.product,
      dataIndex: "product",
      key: "product",
      width: 400,
      render: (text, index) => (
        <div className={"flex"}>
          <img
            src={text?.avatar}
            style={{ width: 50, height: 45 }}
            alt="detail image"
          />
          <div className="pl-2 text-left">
            <label>
              <b>
                {
                  text?.[
                    currentSelectedLangue === "english" ? "name" : "name_sp"
                  ]
                }
              </b>
            </label>
          </div>
        </div>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]
          ?.toLowerCase()
          .includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a?.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]?.localeCompare(
          b?.product?.[currentSelectedLangue === "english" ? "name" : "name_sp"]
        ),
    },
    {
      align: "center",
      title: providerLabels.orderID,
      dataIndex: "orderId",
      key: "orderId",
      width: 200,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.orderId.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.orderId?.localeCompare(b?.orderId),
    },
    {
      align: "center",
      title: providerLabels.serviceInformation,
      align: "center",
      width: 150,
      render: (text, index) => (
        <div className="table-quill flex justify-center items-center">
          <span
            className="cursor-pointer flex items-center space-x-1 "
            onClick={() => {
              setShowInfoModal(true);
              currentSelectedLangue === "english"
                ? setModalContent(text?.product.info || "")
                : setModalContent(text?.product.info_sp || "");
            }}
          >
            <EyeOutlined />
            <span>{providerLabels.infoBox}</span>
          </span>
        </div>
      ),
    },
    {
      align: "center",
      key: "additionalBudgetNumber",
      dataIndex: "additionalBudgetNumber",
      title: providerLabels.additionalBudgetNumber,
    },

    {
      align: "center",
      title: providerLabels.typeOfMovement,

      dataIndex: "typeOfMovement",
      key: "typeOfMovement",
      width: 300,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.typeOfMovement
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.typeOfMovement?.localeCompare(b?.typeOfMovement),
    },
    {
      align: "center",
      title: providerLabels.dateOfOrder,
      key: "date",
      width: 180,
      dataIndex: "date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      showSorterTooltip: false,
    },
    {
      width: 250,
      title: providerLabels.nameAndSurnameOfClient,
      key: "clientNameSurName",
      dataIndex: "clientNameSurName",
    },
    {
      align: "center",
      title: providerLabels.companyName,
      key: "clientCompanyName",
      dataIndex: "clientCompanyName",
    },
    {
      align: "center",
      title: providerLabels.cifOrNif,
      key: "clientCIfNIF",
      dataIndex: "clientCIfNIF",
    },
    {
      align: "center",
      title: providerLabels.companyAddress,
      key: "clientAddress",
      dataIndex: "clientAddress",
    },
    {
      align: "center",
      title: providerLabels.dispute,
      key: "isDispute",
      dataIndex: "isDispute",
    },
    {
      align: "center",
      title: providerLabels.originalServiceVATPriceEstablished,
      key: "originalServiceVATPriceEstablished",
      dataIndex: "originalServiceVATPriceEstablished",
      width: 200,
      render: (text, index) => (
        <div>
          {text < 0 ? "-" : ""}€{currencyFormat(Math?.abs(text))}
        </div>
      ),
    },
    {
      align: "center",
      title: providerLabels.originalServicePriceEstablished,
      key: "originalServicePriceEstablished",
      dataIndex: "originalServicePriceEstablished",
      render: (text, index) => (
        <div>
          {text < 0 ? "-" : ""}€{currencyFormat(Math?.abs(text))}
        </div>
      ),
    },
    {
      align: "center",
      title: providerLabels.discount,
      key: "serviceDiscountPercentage",
      dataIndex: "serviceDiscountPercentage",
      render: (text) => `${Number(text).toFixed(1)}%`,
    },
    {
      align: "center",
      title: providerLabels.discountServiceVAT,
      key: "discountServiceVAT",
      dataIndex: "discountServiceVAT",
      render: (text, index) => (
        <div>
          {text < 0 ? "-" : ""}€{currencyFormat(Math?.abs(text))}
        </div>
      ),
    },
    {
      align: "center",
      title: providerLabels.serviceDiscount,
      key: "serviceDiscount",
      dataIndex: "serviceDiscount",
      render: (text, index) => (
        <div>
          {text < 0 ? "-" : ""}€{currencyFormat(Math?.abs(text))}
        </div>
      ),
    },
    {
      align: "center",
      title: providerLabels.vatToBePayedTwentyPercentOfServiceDiscount,
      key: "vatToBePayedTwentyPercentOfServiceDiscount",
      dataIndex: "vatToBePayedTwentyPercentOfServiceDiscount",
      render: (text, index) => (
        <div>
          {text < 0 ? "-" : ""}€{currencyFormat(Math?.abs(text))}
        </div>
      ),
    },
    {
      align: "center",
      title: providerLabels.serviceDiscountVAT,
      key: "serviceDiscountVAT",
      dataIndex: "serviceDiscountVAT",
      render: (text, index) => (
        <div>
          {text < 0 ? "-" : ""}€{currencyFormat(Math?.abs(text))}
        </div>
      ),
    },
    {
      align: "center",
      title: providerLabels.sumOfAllSuplements,
      key: "sumOfAllSuplements",
      dataIndex: "sumOfAllSuplements",
      render: (text, index) => (
        <div>
          {text < 0 ? "-" : ""}€{currencyFormat(Math?.abs(text))}
        </div>
      ),
    },

    {
      align: "center",
      title: providerLabels.serviceProviderSeventyPercentWithoutVat,
      key: "serviceProviderSeventyPercentWithoutVat",
      dataIndex: "serviceProviderSeventyPercentWithoutVat",
      render: (text, index) => (
        <div>
          {text < 0 ? "-" : ""}€{currencyFormat(Math?.abs(text))}
        </div>
      ),
    },

    {
      align: "center",
      title: providerLabels.vatServiceProvider,
      key: "vatServiceProvider",
      dataIndex: "vatServiceProvider",
      render: (text, index) => (
        <div>
          {text < 0 ? "-" : ""}€{currencyFormat(Math?.abs(text))}
        </div>
      ),
    },
    {
      align: "center",
      title: providerLabels.toBePayedServiceProvider,
      key: "toBePayedServiceProvider",
      dataIndex: "toBePayedServiceProvider",
      render: (text, index) => (
        <div>
          {text < 0 ? "-" : ""}€{currencyFormat(Math?.abs(text))}
        </div>
      ),
    },
    {
      align: "center",
      title: providerLabels.statusPenidngOrCompleted,
      key: "statusPenidngOrCompleted",
      dataIndex: "statusPenidngOrCompleted",
    },
    {
      align: "center",
      title: providerLabels.completionDate,
      key: "completionDate",
      dataIndex: "completionDate",
    },
    {
      align: "center",
      title: providerLabels.liberatedPaymentPendingOrYes,
      key: "liberatedPaymentPendingOrYes",
      dataIndex: "liberatedPaymentPendingOrYes",
    },
    {
      align: "center",
      title: providerLabels.liberationDate,
      key: "liberationDate",
      dataIndex: "liberationDate",
    },
    {
      align: "center",
      title: providerLabels.action,
      key: "action",
      width: 180,
      render: (text) => (
        <div className="text-center	gap-1 flex items-center justify-center">
          {text?.additional_budget === null ? <>
          <Link to={`/order-info-detail/${text.order_id}`}>
            <Button
              type="disable"
              shape={"round"}
              className="background-color-gray whiteTxt"
            >
              {providerLabels.viewDetails}
            </Button>
          </Link>
          </> : null}
        </div>
      ),
    },
  ];
  const getProviderIncomePayment = () => {
    setLoading(true);
    getServiceProviderBillLogs(
      serviceProvider?.service_provider_id,
      (res) => {
    
        const data = res?.data?.response?.detail?.map((item, index) => {
          
          const isOderOrBudget = item?.data?.is_additional_budget 
          const dates = moment(isOderOrBudget ? item?.additional_budget?.created_at : item?.order?.created_at).format("DD-MMM-YYYY HH:mm");
          const servicePackType = item?.order?.service?.service_pack_type;
          
          const order_OR_AdditionalBudget_Dispute_Status = item?.data?.dispute;
          const disputeType = order_OR_AdditionalBudget_Dispute_Status ? isOderOrBudget ? item?.additional_budget?.budget_dispute_refund?.type : item?.order?.order_dispute_refund?.type || "-" : "-";
          const BillNo = (isOderOrBudget ? item?.additional_budget?.[order_OR_AdditionalBudget_Dispute_Status? "reimb_id" : "bill_id"] : item?.order?.[order_OR_AdditionalBudget_Dispute_Status? "reimb_id" : "bill_id"]) || "-" ;
          const column_M = disputeType?.toLowerCase() === "partial" ? (Number(isOderOrBudget ? item?.additional_budget?.amount || 0: item?.service?.original_price || 0)) * (Number(isOderOrBudget ? Number(item?.additional_budget?.budget_dispute_refund?.provider_percent) / 100 : Number(item?.order?.order_dispute_refund?.provider_percent) / 100)) : (Number(isOderOrBudget ? item?.additional_budget?.amount || 0: item?.service?.original_price || 0));
          const column_N = Number(column_M / 1.21);
          const column_O = item?.type === "order" ? Number(orderDiscountPercentage(item?.service)) :  0;
          const column_P = Number(column_N) * Number(column_O / 100);
          const column_Q = Number(column_N) - Number(column_P);
          const column_R = Number(column_Q) * 0.21;
          const column_S = Number(column_Q) + Number(column_R);
          const column_T = disputeType?.toLowerCase() === "partial" ? (Number(isOderOrBudget ? item?.additional_budget?.supplementary_fees || 0: item?.service?.supplementary_fees || 0)) * (Number(isOderOrBudget ? Number(item?.additional_budget?.budget_dispute_refund?.supplementary_provider_percent) / 100 : Number(item?.order?.order_dispute_refund?.supplementary_provider_percent) / 100)) : (Number(isOderOrBudget ? item?.additional_budget?.supplementary_fees || 0: item?.service?.supplementary_fees || 0));;
          const column_W = Number(column_Q) * 0.7;
          const column_AB = Number(column_R) * 0.7;
          const column_AC =
            Number(column_AB) + Number(column_W) + Number(column_T);
          const column_AD = providerLabels?.[isOderOrBudget ? item?.additional_budget?.status : item?.order_detail?.status];
          const column_AE = isOderOrBudget? moment(dates)?.add(item?.additional_budget?.time, item?.additional_budget?.time_type).local().format("DD-MMM-YYYY HH:mm") : servicePackType === "monthly" ? providerLabels[servicePackType] : moment(dates).add(item.service.time, item?.service.time_type).local().format("DD-MMM-YYYY HH:mm");
        
          const column_AF = `${
            providerLabels?.[
              item?.order_detail?.payment_released === 1 ? "yes" : "pending"
            ]
          }`;

          const coulumn_AG =
            item?.order_detail?.payment_released === 1
              ? moment(item?.order_detail?.updated_at).format(
                  "DD-MMM-YYYY HH:mm"
                )
              : "";
          return {
            ...item,
            no: index + 1,
            product: {
              ...item.service,
              name: item.service.name,
              avatar: item.service.avatar,
              info: isOderOrBudget ? item.additional_budget?.notes : item.service.info,
            },
            additionalBudgetNumber: item?.additional_budget?.bill_id || "-",
            orderId: item.order_id,
            isDispute: providerLabels?.[order_OR_AdditionalBudget_Dispute_Status ? "yes" : "no"],
            clientNameSurName: `${item?.order?.data?.[0]?.personal?.firstName} ${item?.order?.data?.[0]?.personal?.lastName}` || "-",
            clientCompanyName: item?.order?.data?.[0]?.personal.companyName || "-",
            clientCIfNIF: item?.order?.data?.[0]?.personal?.CIF || "-",
            clientAddress: item?.order?.data?.[0]?.personal?.address || "-",
            date: moment(item.created_at).format("DD-MMM-YYYY HH:mm"),
            typeOfMovement: `${providerLabels?.[functionToConvertStringToCamelCase(item?.data?.movement?.replace("_", " "))]} ${item?.data?.movement === "order" && item?.service?.service_pack_type === "monthly" ? (`(${providerLabels?.[item?.service?.service_pack_type]})`) : ""} ${order_OR_AdditionalBudget_Dispute_Status ? (`(${providerLabels?.["reimbursement"]})`) : ""}`,
            originalServiceVATPriceEstablished: Number(
              `${order_OR_AdditionalBudget_Dispute_Status ? `-${column_M}` : `${column_M}`}`
            ),
            originalServicePriceEstablished: Number(
              `${order_OR_AdditionalBudget_Dispute_Status ? `-${column_N}` : `${column_N}`}`
            ),
            serviceDiscountPercentage: column_O,
            discountServiceVAT: column_P,
            serviceDiscount: Number(
              `${order_OR_AdditionalBudget_Dispute_Status ? `-${column_Q}` : `${column_Q}`}`
            ),
            vatToBePayedTwentyPercentOfServiceDiscount: Number(
              `${order_OR_AdditionalBudget_Dispute_Status ? `-${column_R}` : `${column_R}`}`
            ),
            serviceDiscountVAT: Number(
              `${order_OR_AdditionalBudget_Dispute_Status ? `-${column_S}` : `${column_S}`}`
            ),
            sumOfAllSuplements: Number(
              `${order_OR_AdditionalBudget_Dispute_Status ? `-${column_T}` : `${column_T}`}`
            ),
            serviceProviderSeventyPercentWithoutVat: Number(
              `${order_OR_AdditionalBudget_Dispute_Status ? `-${column_W}` : `${column_W}`}`
            ),
            vatServiceProvider: Number(
              `${order_OR_AdditionalBudget_Dispute_Status ? `-${column_AB}` : `${column_AB}`}`
            ),
            toBePayedServiceProvider: Number(
              `${order_OR_AdditionalBudget_Dispute_Status ? `-${column_AC}` : `${column_AC}`}`
            ),
            statusPenidngOrCompleted: column_AD,
            completionDate: column_AE,
            liberatedPaymentPendingOrYes: column_AF,
            liberationDate: coulumn_AG || "-",
            // typeOfMovement: item?.data?.movement,
          };
        });
        setBillLogsData(data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    getProviderIncomePayment();
  }, []);
  return (
    <section className="pb-10">
      <Spin tip={providerLabels.loading} spinning={loading}>
        <div className="flex justify-start lg:justify-between mt-5  flex-wrap sm:flex-wrap">
          <div className="pl-5 flex self-center justify-center items-center sub-h3 mb-4 sm:mb-4 lg:mb-0">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{providerLabels.back}</span>
            </div>
            <h3 className="ml-10">{providerLabels.payments}</h3>
          </div>
          <div className="lg:mr-28 flex justify-end flex-wrap sm:flex-wrap">
            <div className="ml-2">
              <CSVLink
                filename={`${providerLabels.payments}.csv`}
                target="_blank"
                data={billLogsData?.map((item, index) => {
                  return {
                    ...item,
                    no: index + 1,
                    product:
                      item?.product?.[
                        currentSelectedLangue === "english" ? "name" : "name_sp"
                      ],
                    orderID: item?.orderId,
                    orderDate: item?.date,
                  };
                })}
                headers={[
                  {
                    label: providerLabels.no,
                    key: "no",
                  },
                  {
                    label: providerLabels.product,
                    key: "product",
                  },
                  {
                    label: providerLabels.orderID,
                    key: "orderId",
                  },

                  {
                    key: "additionalBudgetNumber",

                    label: providerLabels.additionalBudgetNumber,
                  },

                  {
                    label: providerLabels.typeOfMovement,
                    key: "typeOfMovement",
                  },
                  {
                    label: providerLabels.dateOfOrder,
                    key: "date",
                  },
                  {
                    label: providerLabels.nameAndSurnameOfClient,
                    key: "clientNameSurName",
                  },
                  {
                    label: providerLabels.companyName,
                    key: "clientCompanyName",
                  },
                  {
                    label: providerLabels.cifOrNif,
                    key: "clientCIfNIF",
                  },
                  {
                    label: providerLabels.companyAddress,
                    key: "clientAddress",
                  },
                  {
                    label: providerLabels.dispute,
                    key: "isDispute",
                  },
                  {
                    label: providerLabels.originalServiceVATPriceEstablished,
                    key: "originalServiceVATPriceEstablished",
                  },
                  {
                    label: providerLabels.originalServicePriceEstablished,
                    key: "originalServicePriceEstablished",
                  },
                  {
                    label: providerLabels.discount,
                    key: "serviceDiscountPercentage",
                  },
                  {
                    label: providerLabels.discountServiceVAT,
                    key: "discountServiceVAT",
                  },
                  {
                    label: providerLabels.serviceDiscount,
                    key: "serviceDiscount",
                  },
                  {
                    label:
                      providerLabels.vatToBePayedTwentyPercentOfServiceDiscount,
                    key: "vatToBePayedTwentyPercentOfServiceDiscount",
                  },
                  {
                    label: providerLabels.serviceDiscountVAT,
                    key: "serviceDiscountVAT",
                  },
                  {
                    label: providerLabels.sumOfAllSuplements,
                    key: "sumOfAllSuplements",
                  },

                  {
                    label:
                      providerLabels.serviceProviderSeventyPercentWithoutVat,
                    key: "serviceProviderSeventyPercentWithoutVat",
                  },

                  {
                    label: providerLabels.vatServiceProvider,
                    key: "vatServiceProvider",
                  },
                  {
                    label: providerLabels.toBePayedServiceProvider,
                    key: "toBePayedServiceProvider",
                  },
                  {
                    label: providerLabels.statusPenidngOrCompleted,
                    key: "statusPenidngOrCompleted",
                  },
                  {
                    label: providerLabels.completionDate,
                    key: "completionDate",
                  },
                  {
                    label: providerLabels.liberatedPaymentPendingOrYes,
                    key: "liberatedPaymentPendingOrYes",
                  },
                  {
                    label: providerLabels.liberationDate,
                    key: "liberationDate",
                  },
                ]}
              >
                <Button
                  shape={"round"}
                  className="icon-button "
                  icon={<AiOutlineArrowDown color="#5ab3f0" />}
                >
                  {providerLabels.exportCSV}
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <div className="container mx-auto lg:px-20 mt-5">
          <TableComponent
            scroll={"400%"}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
            scrolly="500px"
            columns={tableColumns}
            data={billLogsData || []}
          />
        </div>
      </Spin>
      <Modal
        visible={showInfoModal}
        centered
        footer={null}
        onCancel={() => {
          setShowInfoModal(false);
        }}
        className="global-modal-css-scroll-height "
      >
        <div>
          <h4 className="font-bold text-2xl text-center text-gray-600	">
            {providerLabels.information}
          </h4>
        </div>
        <div className="scrollable-fixed-height py-5 render-html-class">
          {<InformationContainer info={modal_Content} />}
        </div>
      </Modal>
    </section>
  );
}
