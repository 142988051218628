import { Button, Space, Tag, Input, Tooltip } from "antd";
import React, { useEffect } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import TableComponent from "../../components/table";
import { Images } from "../../constant/Images";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { FiSearch } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
const { dotPattern } = Images;

const columns = [
  {
    align: "center",
    title: "No",
    dataIndex: "no",
    key: "no",
    width: 40,
    render: (text) => <a>{text}</a>,
  },
  {
    align: "center",
    title: "Full Name",
    dataIndex: "fullname",
    key: "fullname",
    width: 130,
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <span className="search-filter-dropdown">
          <Input
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            autoFocus
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
          {/* <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
          >
            Search
          </Button> */}
          {/* <Button
            onClick={() => {
              clearFilters();
            }}
            type="danger"
            size="small"
          >
            Reset
          </Button> */}
        </span>
      );
    },
    filterIcon: () => {
      return <SearchOutlined style={{ color: "#ffff" }} />;
    },
    onFilter: (value, record) => {
      return record.fullname.toLowerCase().includes(value.toLowerCase());
    },
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a.fullname.localeCompare(b.fullname),
    showSorterTooltip: false,
  },
  {
    align: "center",
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: 150,
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <span className="search-filter-dropdown">
          <Input
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            autoFocus
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
          {/* <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
          >
            Search
          </Button> */}
          {/* <Button
            onClick={() => {
              clearFilters();
            }}
            type="danger"
            size="small"
          >
            Reset
          </Button> */}
        </span>
      );
    },
    filterIcon: () => {
      return <SearchOutlined style={{ color: "#ffff" }} />;
    },
    onFilter: (value, record) => {
      return record.email.toLowerCase().includes(value.toLowerCase());
    },
    ellipsis: {
      showTitle: false,
    },
    render: (email) => (
      <Tooltip placement="topLeft" title={email}>
        {email}
      </Tooltip>
    ),
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a.email.localeCompare(b.email),
    showSorterTooltip: false,
  },
  {
    align: "center",
    title: "Phone",
    key: "phone",
    dataIndex: "phone",
    width: 120,
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <span className="search-filter-dropdown">
          <Input
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            autoFocus
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
          {/* <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
          >
            Search
          </Button> */}
          {/* <Button
            onClick={() => {
              clearFilters();
            }}
            type="danger"
            size="small"
          >
            Reset
          </Button> */}
        </span>
      );
    },
    filterIcon: () => {
      return <SearchOutlined style={{ color: "#ffff" }} />;
    },
    onFilter: (value, record) => {
      return record.phone.toLowerCase().includes(value.toLowerCase());
    },
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a.phone - b.phone,
    showSorterTooltip: false,
  },
  {
    align: "center",
    title: "Date",
    key: "Date",
    dataIndex: "date",
    width: 150,
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) =>
      moment(a.registerDate).unix() - moment(b.registerDate).unix(),
    showSorterTooltip: false,
  },
  {
    align: "center",
    title: "Time",
    key: "Time",
    dataIndex: "time",
    width: 180,
  },
  {
    align: "center",
    title: "Address",
    dataIndex: "address",
    key: "address",
    width: 200,
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <span className="search-filter-dropdown">
          <Input
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            autoFocus
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
          {/* <Button
            onClick={() => {
              confirm();
            }}
            type="primary"
            size="small"
          >
            Search
          </Button> */}
          {/* <Button
            onClick={() => {
              clearFilters();
            }}
            type="danger"
            size="small"
          >
            Reset
          </Button> */}
        </span>
      );
    },
    filterIcon: () => {
      return <SearchOutlined style={{ color: "#ffff" }} />;
    },
    onFilter: (value, record) => {
      return record.address.toLowerCase().includes(value.toLowerCase());
    },
    sortDirections: ["descend", "ascend"],
    sorter: (a, b) => a.address.localeCompare(b.address),
    showSorterTooltip: false,
  },
  {
    align: "center",
    title: "Action",
    key: "action",
    width: 100,
    render: (text, index) => (
      <div className="text-center">
        <Button
          type="disable"
          shape={"round"}
          className="background-color-gray whiteTxt"
        >
          Edit
        </Button>
      </div>
    ),
  },
];

const data = [
  {
    no: "1",
    fullname: "John Brown",
    email: "abc@gmail.com",
    address: "New York No. 1 Lake Park",
    date: "10 May 2021",
    time: "10:00 am to 08:30 pm",
    date: "10 May 2021",
    time: "10:00 am to 08:30 pm",
    tags: "nice",
    phone: "0123456787",
  },
  {
    no: "2",
    fullname: "John Brown",
    email: "abc@gmail.com",
    address: "New York No. 1 Lake Park",
    date: "10 May 2021",
    time: "10:00 am to 08:30 pm",
    tags: "nice",
    phone: "0123456787",
  },
  {
    no: "3",
    fullname: "John Brown",
    email: "abc@gmail.com",
    address: "New York No. 1 Lake Park",
    date: "10 May 2021",
    time: "10:00 am to 08:30 pm",
    tags: "nice",
    phone: "0123456787",
  },
  {
    no: "4",
    fullname: "John Brown",
    email: "abc@gmail.com",
    address: "New York No. 1 Lake Park",
    date: "10 May 2021",
    time: "10:00 am to 08:30 pm",
    date: "10 May 2021",
    time: "10:00 am to 08:30 pm",
    tags: "nice",
    phone: "0123456787",
  },
  {
    no: "5",
    fullname: "John Brown",
    email: "abc@gmail.com",
    address: "New York No. 1 Lake Park",
    date: "10 May 2021",
    time: "10:00 am to 08:30 pm",
    tags: "nice",
    phone: "0123456787",
  },
  {
    no: "6",
    fullname: "John Brown",
    email: "abc@gmail.com",
    address: "New York No. 1 Lake Park",
    date: "10 May 2021",
    time: "10:00 am to 08:30 pm",
    tags: "nice",
    phone: "0123456787",
  },
  {
    no: "7",
    fullname: "John Brown",
    email: "abc@gmail.com",
    address: "New York No. 1 Lake Park",
    date: "10 May 2021",
    time: "10:00 am to 08:30 pm",
    date: "10 May 2021",
    time: "10:00 am to 08:30 pm",
    tags: "nice",
    phone: "0123456787",
  },
  {
    no: "8",
    fullname: "John Brown",
    email: "abc@gmail.com",
    address: "New York No. 1 Lake Park",
    date: "10 May 2021",
    time: "10:00 am to 08:30 pm",
    tags: "nice",
    phone: "0123456787",
  },
  {
    no: "9",
    fullname: "John Brown",
    email: "abc@gmail.com",
    address: "New York No. 1 Lake Park",
    date: "10 May 2021",
    time: "10:00 am to 08:30 pm",
    tags: "nice",
    phone: "0123456787",
  },
];

export default function SubAdminAccLog(params) {
  useEffect(() => {
    document.body.style.overflow = "auto";
    return function cleanup() {
      document.body.style.overflow = "auto";
    };
  }, []);

  const { goBack } = useHistory();
  return (
    <section className="">
      <div className="flex justify-between  mt-5 ">
        <div className="pl-5 flex self-center justify-center items-center sub-h3">
          <div
            onClick={() => goBack()}
            className="flex text-gray-400 items-center cursor-pointer"
          >
            <RiArrowDropLeftLine />
            <span>Back</span>
          </div>
          <h3 className="ml-5 sm:ml-5	lg:ml-10">Sub-Admin Activity Logs</h3>
        </div>
        <div className=" flex mr-0 sm:mr-0 lg:mr-28 ">
          <div className="mr-2 ">
            <Button shape={"circle"} className="icon-button p0 ">
              <FiSearch size={15} />
            </Button>
          </div>
          <div>
            <CSVLink
              data={data}
              filename={"Sub-Admin Activity Logs.csv"}
              target="_blank"
            >
              <Button
                shape={"round"}
                className="icon-button "
                icon={<AiOutlineArrowDown color="#5ab3f0" />}
              >
                Export CSV
              </Button>
            </CSVLink>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-1 sm:px-0 lg:px-20 mt-5">
        <TableComponent
          columns={columns}
          data={data}
          // scrolly="600px"
          pagination={{ defaultPageSize: 5 }}
        />
      </div>
    </section>
  );
}
