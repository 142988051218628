import { SearchOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Menu, Spin, Switch, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiOutlineArrowDown, AiOutlinePlus } from "react-icons/ai";
import { RiArrowDropLeftLine, RiDeleteBinFill } from "react-icons/ri";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Errors, Success } from "../../components/antdToast";
import { Remove_Data_Form_Reducers } from "../../components/dataRemoveOnLogout";
import TableComponent from "../../components/table";
import { ChangePromoCodeStatus, GetPromoCodesMethod } from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { DeleteBeneficiariesAtLogoutTime } from "../../Redux/actions/fetchBeneficiariesAction";
import { DeleteBranchesAtLogoutTime } from "../../Redux/actions/fetchBranchesAction";
import {
  DeleteProvidersAtLogoutTime,
  FetchProvidersAction,
} from "../../Redux/actions/fetchProvidersAction";
import { DeleteServiceAreasAtLogoutTime } from "../../Redux/actions/fetchServiceAreaAction";
import { DeleteServicesAtLogoutTime } from "../../Redux/actions/fetchServicesAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";

const PromoCodes = (props) => {
  const { goBack, push } = useHistory();
  const [promoCodes, setPromoCodes] = useState([]);
  const [loading, setLoading] = useState(true);

  const menu = (id, values) => (
    <Menu className="menu-class menu-main-class" style={{ borderRadius: 20 }}>
      <Menu.Item className="bg-white" style={{ borderRadius: 20 }} key={id}>
        <div
          className="flex justify-between bg-white py-2 w-full  items-center"
          style={{ width: 160 }}
        >
          <div>{adminLabels.status}</div>
          <div>
            <Switch
              size="small"
              checked={Number(values?.status) === 1 ? true : false}
              onChange={() => enableORDisableStatusOFPromoCode(values?.code)}
            />
          </div>
        </div>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: adminLabels.no,
      dataIndex: "no",
      key: "no",
      align: "center",
      width: 40,
      render: (text) => <a>{text}</a>,
    },
    {
      title: adminLabels.promoCode,
      dataIndex: "code",
      key: "code",
      align: "center",
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.code.toLowerCase().includes(value.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.code?.localeCompare(b?.code),
      render: (promoCode) => (
        <Tooltip placement="topLeft" title={promoCode}>
          {promoCode}
        </Tooltip>
      ),
    },
    {
      title: adminLabels.duration,
      dataIndex: "duration",
      key: "duration",
      align: "left",
      width: 300,
      render: (duration) => (
        <Tooltip placement="topLeft" title={duration}>
          {duration}
        </Tooltip>
      ),
    },
    {
      title: adminLabels.userLimit,
      dataIndex: "limit",
      key: "limit",
      align: "center",
      width: 100,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.limit - b?.limit,
      render: (limit) => (
        <Tooltip placement="topLeft" title={limit}>
          {limit}
        </Tooltip>
      ),
    },
    {
      title: adminLabels.discount,
      dataIndex: "discount",
      key: "discount",
      align: "center",
      width: 100,

      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.discount - b?.discount,
      render: (text) => (
        <Tooltip placement="topLeft" title={<span>{`${text}%`} </span>}>
          <span>{`${text}%`} </span>
        </Tooltip>
      ),
    },
    {
      title: adminLabels.status,
      key: "action",
      width: 140,
      align: "center",
      render: (text, index) => (
        <div className="flex justify-center">
          <Dropdown
            overlay={() => menu(text?.code, text)}
            className={"flex "}
            trigger={["click"]}
          >
            <div
              type="disable"
              shape={"round"}
              style={{ width: "100px" }}
              className={`${
                text?.status === "1" ? "bg-primary" : "background-color-gray"
              } whiteTxt rounded-full py-1 justify-center cursor-pointer	`}
            >
              <span className="capitalize">
                {text?.status === "1"
                  ? adminLabels.enabled
                  : adminLabels.disabled}
              </span>
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getPromos();
    document.body.style.overflow = "auto";
    return function cleanup() {
      document.body.style.overflow = "auto";
    };
  }, []);

  const getPromos = () => {
    GetPromoCodesMethod(
      (success) => {
        if (success.status === 200) {
          let mappedOut = success?.data?.response?.detail
            ?.reverse()
            ?.map((item, index) => {
              return {
                ...item,
                no: index + 1,
                duration: ` ${moment(item?.start_date).format(
                  "DD-MMM-YYYY"
                )}  to  ${moment(item?.end_date).format("DD-MMM-YYYY")} `,
              };
            });
          setPromoCodes(mappedOut);
          setLoading(false);
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Reducers(props);
          push("/admin");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const enableORDisableStatusOFPromoCode = (id) => {
    let promoCode = new FormData();
    promoCode.append("code", id);
    ChangePromoCodeStatus(
      promoCode,
      (success) => {
        if (success.status === 200) {
          //promoCodes,
          setPromoCodes((prev) =>
            prev?.map((item) => {
              if (item?.code === id) {
                return {
                  ...item,
                  status: item.status === "1" ? 0 : "1",
                };
              } else {
                return item;
              }
            })
          );

          Success(success?.data?.response?.message);
        }
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors("Network Error");
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  return (
    <section className="">
      <Spin tip={adminLabels.loading} spinning={loading}>
        <div className="flex justify-between mt-5 ">
          <div className="pl-5 flex self-center justify-center items-center sub-h3">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{adminLabels.back}</span>
            </div>
            <h3 className="ml-10	">{adminLabels.promoCodes}</h3>
          </div>
          <div className="flex mr-0 sm:mr-0 lg:mr-28">
            <Link to="/admin/add-promo-code" className="px-1">
              <Button
                shape={"round"}
                className="icon-button"
                icon={
                  <AiOutlinePlus color="#5ab3f0" style={{ marginRight: 10 }} />
                }
              >
                {adminLabels.addNew}
              </Button>
            </Link>

            <CSVLink
              data={promoCodes?.map((item, index) => {
                return {
                  ...item,
                  no: index + 1,
                };
              })}
              filename={`${adminLabels.promoCodes}.csv`}
              target="_blank"
              headers={[
                { label: adminLabels.no, key: "no" },
                { label: adminLabels.promoCode, key: "code" },
                { label: adminLabels.duration, key: "duration" },
                { label: adminLabels.userLimit, key: "limit" },
                {
                  label: adminLabels.discount,
                  key: "discount",
                },
              ]}
            >
              <Button
                shape={"round"}
                className="icon-button "
                icon={<AiOutlineArrowDown color="#5ab3f0" />}
              >
                {adminLabels.exportCSV}
              </Button>
            </CSVLink>
          </div>
        </div>
        <div className="container mx-auto lg:px-20 mt-5">
          <TableComponent
            columns={columns}
            data={promoCodes}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
            scrolly="500px"
          />
        </div>
      </Spin>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    showProviders: state.providerList.providers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProvidersList: (props) => {
      dispatch(FetchProvidersAction(props));
    },
    setLogoutUser: (data) => {
      dispatch(LogoutUserAction(data));
    },
    removeBeneficiaries: () => {
      dispatch(DeleteBeneficiariesAtLogoutTime());
    },
    removeProviders: () => {
      dispatch(DeleteProvidersAtLogoutTime());
    },
    removeServiceAreas: () => {
      dispatch(DeleteServiceAreasAtLogoutTime());
    },
    removeBranches: () => {
      dispatch(DeleteBranchesAtLogoutTime());
    },
    removeServices: () => {
      dispatch(DeleteServicesAtLogoutTime());
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PromoCodes);
