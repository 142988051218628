export const Images = {
  logo: require("../assets/logo-blue.png"),
  dotPattern: require("../assets/pattern.png"),
  menu: require("../assets/Side-menu.png"),
  profile: require("../assets/Profile.png"),
  assement: require("../assets/assm.png"),
  other: require("../assets/Others.png"),
  business: require("../assets/Business.png"),
  particular: require("../assets/Particular.png"),
  punctual1: require("../assets/PunctualService1.png"),
  punctual2: require("../assets/PunctualService2.png"),
  selfemployed: require("../assets/SelfEmployed.png"),
  servicepack: require("../assets/Servicepack1.png"),
  uploadImg: require("../assets/UploadPhoto.png"),
  document: require("../assets/Document.svg"),
  calendar: require("../assets/Calendar.svg"),
  checkBox: require("../assets/check-box.svg"),
  RadioButton: require("../assets/radio-button.svg"),

  email: require("../assets/email.png"),
  newpass: require("../assets/newpass.png"),
  verification: require("../assets/verification.png"),
  cross: require("../assets/Cross.png"),
  filter: require("../assets/filter.png"),
  checkbox: require("../assets/checkbox.png"),
  checkboxActive: require("../assets/checkboxActive.png"),
  searchBar: require("../assets/Search-bar.png"),
  circleImage: require("../assets/circle.png"),
  uploadFile: require("../assets/uploadFile.png"),
  textInput: require("../assets/TextInput.svg"),
  textBlock: require("../assets/TextBlock.svg"),
  dropDown: require("../assets/DropDown.svg"),
  uploadFile: require("../assets/UploadFile.svg"),
  question: require("../assets/Question.svg"),
  timeDelivery: require("../assets/TimeDelivery.svg"),
  document: require("../assets/Document.svg"),
  loginLogo: require("../assets/login-logo.png"),
  userProfilePlaceholder: require("../assets/profileImagePlaceholder.png"),
  attachedDocument: require("../assets/attachedDocument.png"),
  adminNote: require("../assets/adminNote.svg"),
  additionalBudgetIcon: require("../assets/Additional Budget@3x.png"),
  orderCanceledIcon: require("../assets/Order Canceled@3x.png"),
  orderPlacedIcon: require("../assets/Order Placed@3x.png"),
  RequestBudgetIcon: require("../assets/Request@3x.png"),
};
