import { Errors } from "../../components/antdToast";
import { Remove_Data_Form_Reducers } from "../../components/dataRemoveOnLogout";
import { GetBranches } from "../../config/api/index";

const FetchBranchesAction = (props) => {
  return (dispatch) => {
    GetBranches(
      (success) => {
        if (success?.status === 200) {
          dispatch({
            type: "GET_BRANCHES",
            payload: success?.data?.response?.detail,
          });
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Reducers(props);
          props.history.push("/admin")
        } else {
          Errors("Network Error");
        }
      }
    );
  };
};

const DeleteBranchesAtLogoutTime = () => {
  return (dispatch) => {
    dispatch({
      type: "REMOVE_BRANCHES",
      payload: [],
    });
  };
};

export { FetchBranchesAction, DeleteBranchesAtLogoutTime };
