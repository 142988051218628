const initialState = {
  loaderState: false,
};

const LoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADER_TOGGLE": {
      return {
        ...state,
        loaderState: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default LoaderReducer;
