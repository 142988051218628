import { Button, Modal, Input } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import ButtonComponent from "../../components/ButtonComponent";
import { Remove_Data_Form_Provider_Reducers } from "../../components/dataRemoveOnProviderLogout";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";

import {
  GetOrdersById,
  GetMessagesMethod,
  GetOrderNotificationById,
  AdditionalBudgetDisputeCancelMethod,
  OrderDisputeContinueMethod,
  SendOrderMessageMethod,
  UploadFileMethod,
  OrderReplyMethod,
  GetAdditionalBudgetDetails,
  AdditionalBudgetDisputeContinueMethod,
} from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import {
  DeleteWorkerAtLogoutTime,
  FetchWorkersAction,
} from "../../Redux/actions/fetchWorkersAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { ProviderLogoutAction } from "../../Redux/actions/logoutAction";
import UserDetailContainerCard from "./UserDetailContainerCard";
import { Errors, Success } from "../../components/antdToast";
import { Images } from "../../constant/Images";
import {
  downloadFiles,
  europeanCurrencyFormatter,
  get_url_extension,
} from "../../config/utils";
import InformationContainer from "../../components/informationContainer";
const orderId = "";
const BudgetResponseDetails = (props) => {
  const {
    document,
    additionalBudgetIcon,
    orderCanceledIcon,
    orderPlacedIcon,
    checkbox,
    checkboxActive,
  } = Images;
  const currentSelectedLangue = localStorage.getItem("primaryLanguage");

  const { additionalBudgetId } = useParams();
  const { goBack, push } = useHistory();
  const [selectedOrder, setSelectedOrder] = useState({});
  const [selectedAdditionalBudget, setSelectedAdditionalBudget] = useState({});
  const [disputeConfirmationModal, setDisputeConfirmationModal] =
    useState(false);
  const [disputeAdditionalBudgetStatus, setDisputeAdditionalBudgetStatus] =
    useState("");
  const [disputeAdditionalRefundStatus, setDisputeAdditionalRefundStatus] =
    useState("");
  const [
    disputeAdditionalRefundComplete,
    setDisputeAdditionalBudgetRefundComplete,
  ] = useState("");
  const [budgetUrls, setBudgetUrls] = useState([]);

  const [partialAmount, setPartialAmount] = useState("");
  const [partialAmountSupplementary, setPartialAmountSupplementary] = useState("")

  const [modal_Content, setModalContent] = useState("");
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [is_supplementary,setIs_supplementary] = useState(true)


  useEffect(() => {
    getAdditionalBudgetDetails();
  }, []);

  useEffect(() => {
    if (
      disputeAdditionalBudgetStatus === "" ||
      disputeAdditionalBudgetStatus === "continue"
    ) {
      setDisputeAdditionalRefundStatus("");
      setDisputeAdditionalBudgetRefundComplete("");
    }
  }, [disputeAdditionalBudgetStatus]);

  useEffect(() => {
    if (
      disputeAdditionalRefundStatus === "" ||
      disputeAdditionalRefundStatus === "partial"
    ) {
      setDisputeAdditionalBudgetRefundComplete("");
    }
  }, [disputeAdditionalRefundStatus]);

  const getAdditionalBudgetDetails = () => {
    GetAdditionalBudgetDetails(
      additionalBudgetId,
      (success) => {
        if (success.status === 200) {
          if (success.data.response.detail !== null) {
            setSelectedAdditionalBudget(success?.data?.response?.detail);
            setSelectedOrder(success?.data?.response?.detail?.order);
            setBudgetUrls(JSON?.parse(success?.data?.response?.detail?.files));
          }
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/admin");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  
  //   const getOrdersByProvider = (id) => {
  //     GetOrdersById(
  //       id,
  //       (success) => {
  //         if (success.status === 200) {
  //           if (success.data.response.detail !== null) {
  //             setSelectedOrder(success?.data?.response?.detail);
  //           }
  //         }
  //       },
  //       (error) => {
  //         if (error?.response?.status === 401) {
  //           props.setLoaderState(false);
  //           Errors(error?.response?.data?.message);
  //           Remove_Data_Form_Provider_Reducers(props);
  //           push("/admin");
  //         } else if (error?.response?.status === 409) {
  //           Errors(error?.response?.data?.error?.message);
  //           props.setLoaderState(false);
  //         } else {
  //           props.setLoaderState(false);
  //           Errors("Network Error");
  //         }
  //       }
  //     );
  //   };
  const replyFunction = (orderId) => {
    const obj = {
      order_id: orderId,
    };
    OrderReplyMethod(
      obj,
      (res) => {
        if (res?.data?.status) {
        }
      },
      (err) => {
        if (err?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(err?.response?.data?.message);
          push("/admin");
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const PartialCancelOrderDispute = () => {
    const obj = {
      additional_budget_id: additionalBudgetId,
      type: disputeAdditionalRefundStatus,
      user_percent: Number(partialAmount),
      provider_percent: 100 - Number(partialAmount),
      supplementary_user_percent: Number(partialAmountSupplementary),
      supplementary_provider_percent: 100 - Number(partialAmountSupplementary),
      is_supplementary: 1
    };
    AdditionalBudgetDisputeCancelMethod(
      obj,
      (res) => {
        Success(res.data.response.message);
        setDisputeConfirmationModal(false);
        const myTimeout = setTimeout(() => {
          push("/admin/payouts");
        }, 2000);
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const CompleteCancelAdditionalDispute = () => {
    const userPercent = disputeAdditionalRefundComplete === "user" ? 100 : 0;
    const providerPercent =
      disputeAdditionalRefundComplete === "provider" ? 100 : 0;

    const obj = {
      additional_budget_id: additionalBudgetId,
      type: disputeAdditionalRefundStatus,
      user_percent: userPercent,
      provider_percent: providerPercent,
      is_supplementary : 1

    };
    AdditionalBudgetDisputeCancelMethod(
      obj,
      (res) => {
        Success(res.data.response.message);
        setDisputeConfirmationModal(false);
        const myTimeout = setTimeout(() => {
          push("/admin/payouts");
        }, 2000);
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };

  const ContinueAdditionalBudgetDispute = () => {
    const obj = {
      additional_budget_id: additionalBudgetId,
    };
    AdditionalBudgetDisputeContinueMethod(
      obj,
      (res) => {
        Success(res.data.response.message);
        setDisputeConfirmationModal(false);
        const myTimeout = setTimeout(() => {
          push("/admin/budget-response");
        }, 2000);
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const vat = "21";
  const orderAmountCalculation = (price) => {
    let taxAmount = (vat / 100) * Number(price);
    let totalAmount = Number(price - taxAmount);
    return totalAmount;
  };
  const vatAmountCalculation = (price) => {
    let taxAmount = (vat / 100) * Number(price);
    return taxAmount;
  };
  const totalAmountCalculation = (service, vat, supplementary) => {
    return Number(service) + Number(vat) + Number(supplementary);
  };
  return (
    <section>
      <div className="flex justify-between mt-20 mb-5">
        <div className="pl-5 flex self-center justify-center items-center">
          <div
            onClick={() => goBack()}
            className="flex text-gray-400 items-center cursor-pointer"
          >
            <RiArrowDropLeftLine />
            <span>{adminLabels.back}</span>
          </div>
          <h3 className="ml-10	">{adminLabels.additionalBudget}</h3>
        </div>
      </div>
      <div className="container grid grid-cols-12 gap-3 lg:pl-20 px-2 sm:px-2 lg:px-0">
        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-5">
          <UserDetailContainerCard providerDetails={selectedOrder} />
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-6">
          <div className="bg-white container rounded-lg px-5 py-3">
            {/* <h3 className="hh3 "> {adminLabels.orderInfoAndDocument}</h3>
            <p className="font-color-blue pt-2 mb-1">
              {moment(selectedOrder?.created_at).format("DD-MMM-YYYY")}
            </p> */}

            <div className="scrollable-fixed-height">
              <div className="flex items-center mt-8 ml-2">
                <img
                  src={selectedAdditionalBudget?.order?.service?.avatar}
                  style={{ width: 50, height: 45 }}
                  alt="detail image"
                />
                <div className="pl-2 text-left">
                  <label>
                    <b>
                      {
                        selectedAdditionalBudget?.order?.service?.[
                          currentSelectedLangue === "english"
                            ? "name"
                            : "name_sp"
                        ]
                      }
                    </b>
                  </label>{" "}
                  <br />
                </div>
              </div>
              <div className="grid grid-cols-6 ml-2">
                <div className="col-span-2">
                  <div className="mt-5">
                    <h4 className="font-black">{adminLabels.provider}</h4>
                    <p className="text-gray-400">
                      {selectedAdditionalBudget?.order?.service_provider?.name}
                    </p>
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="mt-5">
                    <h4 className="font-black">{adminLabels.orderId}</h4>
                    <p className="text-gray-400">
                      {selectedAdditionalBudget.order_id}
                    </p>
                  </div>
                </div>

                <div className="col-span-2">
                  <div className="mt-5">
                    <h4 className="font-black">{adminLabels.estimatedTime}</h4>
                    <p className="text-gray-400">
                      {selectedAdditionalBudget?.time}{" "}
                      {selectedAdditionalBudget?.time_type}
                    </p>
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="mt-5">
                    <h4 className="font-black">{adminLabels.date}</h4>
                    <p className="text-gray-400">
                      {moment(selectedAdditionalBudget?.created_at)?.format(
                        "DD-MMM-YYYY HH:mm"
                      )}
                    </p>
                  </div>
                </div>

                <div className="col-span-2">
                  <div className="mt-5">
                    <h4 className="font-black">{adminLabels.serviceFees}</h4>
                    <p className="text-gray-400">
                      €
                      {europeanCurrencyFormatter(
                        Number(selectedAdditionalBudget?.amount || 0)
                      )}{" "}
                    </p>
                  </div>
                </div>

                <div className="col-span-2">
                  <div className="mt-5">
                    <h4 className="font-black">
                      {adminLabels.supplementaryFees}
                    </h4>
                    <p className="text-gray-400">
                      €
                      {europeanCurrencyFormatter(
                        selectedAdditionalBudget?.supplementary_fees
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="mt-5">
                    <h4 className="font-black">{adminLabels.docuDocFees}</h4>
                    <p className="text-gray-400">
                      €
                      {europeanCurrencyFormatter(
                        Number(selectedAdditionalBudget?.amount || 0) * 0.3
                      )}{" "}
                    </p>
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="mt-5">
                    <h4 className="font-black">{adminLabels.providerAmount}</h4>
                    <p className="text-gray-400">
                      {" "}
                      €
                      {europeanCurrencyFormatter(
                        Math.ceil(
                          Number(selectedAdditionalBudget?.amount || 0) * 0.7
                        ) +
                          Number(
                            selectedAdditionalBudget?.supplementary_fees || 0
                          )
                      )}{" "}
                    </p>
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="mt-5">
                    <h4 className="font-black">{adminLabels.status}</h4>
                    <p className="text-gray-400">
                      {selectedAdditionalBudget.status}
                    </p>
                  </div>
                </div>
                <div className="col-span-4">
                  {selectedAdditionalBudget?.supplementaries_fees?.length >
                  0 ? (
                    <div className="col-span-2">
                      <div className="mt-5">
                        <h4 className="font-black">
                          {adminLabels.supplementaryDetails}
                        </h4>
                        {selectedAdditionalBudget?.supplementaries_fees?.map(
                          (item) => (
                            <p className="text-gray-400">
                              <div className="flex justify-between">
                                <div className="text-sm text-black">
                                  {item?.name}
                                </div>
                                <div className="text-sm text-gray-400">
                                  {" "}
                                  €{europeanCurrencyFormatter(item?.price)}
                                </div>
                              </div>
                            </p>
                          )
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-span-4">
                  <div className="mt-5">
                    <h4 className="font-black">
                      {adminLabels.serviceDetailedDescription}
                    </h4>
                    <Button
                      shape={"round"}
                      className="icon-button proximaRegular capitalize"
                      onClick={() => {
                        setShowInfoModal(true);
                        currentSelectedLangue === "english"
                          ? setModalContent(
                              selectedAdditionalBudget?.notes || ""
                            )
                          : setModalContent(
                              selectedAdditionalBudget?.notes || ""
                            );
                      }}
                    >
                      <EyeOutlined />
                      <span>{adminLabels.infoBox}</span>
                    </Button>
                    {/* <p className="mt-2 render-html-class">
                      {selectedAdditionalBudget?.notes ? (
                        <InformationContainer
                          info={selectedAdditionalBudget?.notes}
                        />
                      ) : null}
                    </p> */}
                  </div>
                </div>
              </div>

              <div className="space-y-3 mx-2">
                {budgetUrls?.length > 0 ? (
                  <div className="mt-5">
                    <h4 className="font-black">{adminLabels.attachments}</h4>
                    <div className="">
                      {budgetUrls?.length > 0 ? (
                        <div className="pt-2 flex justify-between flex-wrap">
                          {budgetUrls?.map((item, index) => {
                            return (
                              <div className="flex justify-between" key={index}>
                                <div
                                  onClick={() =>
                                    downloadFiles(item.url, item?.name)
                                  }
                                  className="cursor-pointer mb-0"
                                  style={{ color: "#44B6F7" }}
                                >
                                  <span>{index + 1} - </span>
                                  <span>
                                    {item?.name.substring(0, 50)}
                                    {item?.name?.length > 50 &&
                                      "... " +
                                        item?.name?.slice(
                                          item?.name?.lastIndexOf(".") + 1
                                        )}{" "}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        adminLabels.notAvailable
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="space-y-3 mx-2">
                {selectedAdditionalBudget?.budget_bill !== null ? (
                  <div className="flex justify-between items-center">
                    <h4 className="font-black">
                      {
                        adminLabels.serviceBillServiceBillOfServiceProviderAdditionalBudgetBill
                      }
                    </h4>
                    <Button
                      shape={"round"}
                      onClick={() =>
                        downloadFiles(
                          selectedAdditionalBudget?.budget_bill,
                          `${
                            adminLabels.serviceBillServiceBillOfServiceProviderAdditionalBudgetBill
                          }.${get_url_extension(
                            selectedAdditionalBudget?.budget_bill
                          )}`
                        )
                      }
                      className={`whiteTxt bg-primary`}
                    >
                      {adminLabels.download}
                    </Button>
                  </div>
                ) : null}
                {selectedAdditionalBudget?.supplementary_bill !== null ? (
                  <div className="flex justify-between items-center">
                    <h4 className="font-black">
                      {adminLabels.suplementsBillOfServiceProvider}
                    </h4>
                    <Button
                      shape={"round"}
                      onClick={() =>
                        downloadFiles(
                          selectedAdditionalBudget?.supplementary_bill,
                          `${
                            adminLabels.suplementsBillOfServiceProvider
                          }.${get_url_extension(
                            selectedAdditionalBudget?.supplementary_bill
                          )}`
                        )
                      }
                      className={`whiteTxt bg-primary`}
                    >
                      {adminLabels.download}
                    </Button>
                  </div>
                ) : null}
                {selectedAdditionalBudget?.status === "dispute" ? (
                  <div className="flex justify-between items-center">
                    {["dispute", "disputed"]?.includes(
                      selectedAdditionalBudget?.status
                    ) && (
                      <div className="mt-4 mb-2 flex justify-end items-center gap-5">
                        <Link
                          to={`/admin/user-provider-order-messages/${selectedOrder?.order_id}`}
                          className={`bg-primary  text-white h-6  rounded-full rounded-lg h-12 w-96 bg-primary `}
                        >
                          <ButtonComponent title={adminLabels.contactClient} />
                        </Link>
                        {["dispute"]?.includes(
                          selectedAdditionalBudget?.status
                        ) && (
                          <div className="">
                            <Button
                              shape={"round"}
                              size="large"
                              className={`icon-button  bg-danger`}
                              onClick={() => setDisputeConfirmationModal(true)}
                            >
                              <span className="text-white">
                                {adminLabels?.endDispute}
                              </span>
                            </Button>
                          </div>
                        )}
                        {["disputed"]?.includes(selectedOrder?.status) && (
                          <div className="">
                            <Link
                              to={`/admin/user-provider-order-messages/${selectedOrder?.order_id}`}
                              className={`bg-primary  text-white h-6  rounded-full rounded-lg h-12 w-96 bg-primary `}
                            >
                              <Button
                                shape={"round"}
                                size="large"
                                className={`icon-button  bg-primary`}
                              >
                                <span className="text-white">
                                  {adminLabels?.result}
                                </span>
                              </Button>
                            </Link>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={disputeConfirmationModal}
        onCancel={() => setDisputeConfirmationModal(false)}
        footer={false}
      >
        {" "}
        <div>
          {
            <h4 className="font-bold text-2xl text-center text-gray-600	">
              {adminLabels.endDispute}
            </h4>
          }
        </div>
        <div className="w-full col-span-12 rounded-full lg:col-span-3 sm:col-span-6 md:col-span-6 text-center py-2 px-8"></div>
        <div className="flex justify-around items-center	">
          <div className="flex flex-col space-y-2 w-96">
            <div className="pt-2">
              <div className="pb-2 px-8">
                <p>
                  {
                    adminLabels?.ifYouwantToContinueClickOnContinueOrderOrIfYouWantToCancelClickOnCancelAdditionalBudget
                  }
                </p>
              </div>
              <div className="flex justify-start space-x-12">
                <div className="flex items-center justify-start w-1/2">
                  {disputeAdditionalBudgetStatus === "cancel" ? (
                    <img
                      src={checkboxActive.default}
                      className="cursor-pointer"
                      onClick={() => setDisputeAdditionalBudgetStatus("")}
                    />
                  ) : (
                    <img
                      src={checkbox.default}
                      className="cursor-pointer"
                      onClick={() => setDisputeAdditionalBudgetStatus("cancel")}
                    />
                  )}
                  <span className=" text-dark   font-bold ml-2">
                    {adminLabels?.cancelOrder}
                  </span>
                </div>
                <div className="flex items-center justify-start w-1/2">
                  {disputeAdditionalBudgetStatus === "continue" ? (
                    <img
                      src={checkboxActive.default}
                      className="cursor-pointer"
                      onClick={() => setDisputeAdditionalBudgetStatus("")}
                    />
                  ) : (
                    <img
                      src={checkbox.default}
                      className="cursor-pointer"
                      onClick={() =>
                        setDisputeAdditionalBudgetStatus("continue")
                      }
                    />
                  )}
                  <span className="mr-2 text-dark  font-bold ml-2">
                    {adminLabels?.continue}
                  </span>
                </div>
              </div>
            </div>

            {disputeAdditionalBudgetStatus === "cancel" && (
              <div className="pt-2">
                <div className="pb-2">
                  {adminLabels?.doYouLikeToGoForCompleteOrPartial}
                </div>
                <div className="flex justify-start space-x-12">
                  <div className="flex items-center justify-start w-1/2">
                    {disputeAdditionalRefundStatus === "complete" ? (
                      <img
                        src={checkboxActive.default}
                        className="cursor-pointer"
                        onClick={() => setDisputeAdditionalRefundStatus("")}
                      />
                    ) : (
                      <img
                        src={checkbox.default}
                        className="cursor-pointer"
                        onClick={() => {
                          setDisputeAdditionalRefundStatus("complete")
                          setIs_supplementary(true)
                        }}
                      />
                    )}
                    <span className=" text-dark   font-bold ml-2">
                      {adminLabels?.complete}
                    </span>
                  </div>
                  <div className="flex items-center justify-start w-1/2">
                    {disputeAdditionalRefundStatus === "partial" ? (
                      <img
                        src={checkboxActive.default}
                        className="cursor-pointer"
                        onClick={() => setDisputeAdditionalRefundStatus("")}
                      />
                    ) : (
                      <img
                        src={checkbox.default}
                        className="cursor-pointer"
                        onClick={() =>
                          setDisputeAdditionalRefundStatus("partial")
                        }
                      />
                    )}
                    <span className="mr-2 text-dark  font-bold ml-2">
                      {adminLabels?.partial}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {disputeAdditionalRefundStatus === "complete" && (
              <div className="pt-2">
                <div className="pb-2">
                  {adminLabels?.toWhomeYouLikeToMakeACompleteRefund}
                </div>
                <div className="flex justify-start space-x-12">
                  <div className="flex items-center justify-start w-1/2">
                    {disputeAdditionalRefundComplete === "provider" ? (
                      <img
                        src={checkboxActive.default}
                        className="cursor-pointer"
                        onClick={() =>
                          setDisputeAdditionalBudgetRefundComplete("")
                        }
                      />
                    ) : (
                      <img
                        src={checkbox.default}
                        className="cursor-pointer"
                        onClick={() =>
                          setDisputeAdditionalBudgetRefundComplete("provider")
                        }
                      />
                    )}
                    <span className=" text-dark   font-bold ml-2">
                      {adminLabels?.serviceProvider}
                    </span>
                  </div>
                  <div className="flex items-center justify-start w-1/2">
                    {disputeAdditionalRefundComplete === "user" ? (
                      <img
                        src={checkboxActive.default}
                        className="cursor-pointer"
                        onClick={() =>
                          setDisputeAdditionalBudgetRefundComplete("")
                        }
                      />
                    ) : (
                      <img
                        src={checkbox.default}
                        className="cursor-pointer"
                        onClick={() =>
                          setDisputeAdditionalBudgetRefundComplete("user")
                        }
                      />
                    )}
                    <span className="mr-2 text-dark  font-bold ml-2">
                      {adminLabels?.user}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {disputeAdditionalRefundStatus === "partial" && (
              <>
                <div className="pt-2">
                  <div className="pb-2">
                    {adminLabels?.enterPercentYouwantToRefundUser}
                  </div>
                  <div className="flex justify-start space-x-12 items-end">
                    <div>
                      <Input
                        type={"number"}
                        min={1}
                        max={99}
                        value={partialAmount}
                        onChange={(e) => {
                          let value = e?.target.value;
                          if (Number(value) <= 99) {
                            setPartialAmount(value);
                          }
                        }}
                      />
                    </div>
                    <div>
                      {adminLabels?.remaining}{" "}
                      <b>{" " + 100 - partialAmount + " "}</b> %{" "}
                      {adminLabels?.forServiceProvider}
                    </div>
                  </div>
                </div>
                <div className="pt-2">
                  <div className="pb-2">
                    {adminLabels?.enterPercentOfSupplementaryYouWantToRefundUser}
                  </div>
                  <div className="flex justify-start space-x-12 items-end">
                    <div>
                      <Input
                        type={"number"}
                        min={1}
                        max={99}
                        value={partialAmountSupplementary}
                        onChange={(e) => {
                          let value = e?.target.value;
                          if (Number(value) <= 99) {
                            setPartialAmountSupplementary(value);
                          }
                        }}
                      />
                    </div>
                    <div>
                      {adminLabels?.remaining}{" "}
                      <b>{" " + 100 - partialAmountSupplementary + " "}</b> %{" "}
                      {adminLabels?.forServiceProvider}
                    </div>
                  </div>
                </div>
              </>
            )}
            {disputeAdditionalRefundStatus === "complete" &&
              disputeAdditionalRefundComplete === "user" && (
                <div>
                  <p>
                    {adminLabels?.orderAmount} {" = "}
                    <b>
                      €
                      {europeanCurrencyFormatter(
                        selectedAdditionalBudget?.amount
                      )}
                    </b>
                  </p>
            
                  <p>
                    {adminLabels?.supplementaryAmount} {" = "}
                    <b>
                      €{" "}
                      {europeanCurrencyFormatter(!is_supplementary ? 0 : selectedAdditionalBudget?.supplementary_fees)}
                    </b>
                  </p>
                  <b>{adminLabels?.totalAmount}</b>
                  <p>
                    {adminLabels?.orderAmount} {" + "}
                    {adminLabels?.supplementaryAmount} {" = "}
                    <b>
                      €
                      {europeanCurrencyFormatter(Number(selectedAdditionalBudget?.amount) + Number(!is_supplementary ? 0 : selectedAdditionalBudget?.supplementary_fees))}{" "}
                    </b>
                  </p>
                </div>
              )}
            {disputeAdditionalRefundStatus === "complete" &&
              disputeAdditionalRefundComplete === "provider" && (
                <div>
                  <p>
                    {adminLabels?.orderAmount} ={" "}
                    <b>
                      €{" "}
                      {europeanCurrencyFormatter(
                        selectedAdditionalBudget?.amount
                      )}{" "}
                    </b>
                  </p>
                 
                  <p>
                    {adminLabels?.supplementaryAmount} {" = "}
                    <b>
                      €{" "}
                      {europeanCurrencyFormatter(!is_supplementary ? 0 : selectedAdditionalBudget?.supplementary_fees)}
                    </b>
                  </p>
                  <p>
                    {adminLabels?.docuDocDeduction} 30% {" = "}
                    <b>
                      €
                      {europeanCurrencyFormatter(
                        Number(selectedAdditionalBudget?.amount) * 0.3
                      )}{" "}
                    </b>
                  </p>
                  <p>
                    {adminLabels?.serviceProvider} 70% {" = "}
                    <b>
                      €
                      {europeanCurrencyFormatter(
                        Number(selectedAdditionalBudget?.amount) * 0.7
                      )}{" "}
                    </b>
                  </p>
                  <b>{adminLabels?.totalAmount}</b>
                  <p>
                    {adminLabels?.docuDocDeduction} 70% +{" "}
                    {adminLabels?.supplementaryAmount} {" = "}
                    <b>
                      €
                      {europeanCurrencyFormatter(
                        Number(selectedAdditionalBudget?.amount) * 0.7 +
                          Number(!is_supplementary ? 0 : selectedAdditionalBudget?.supplementary_fees)
                      )}{" "}
                    </b>
                  </p>
                </div>
              )}
            {disputeAdditionalRefundStatus === "partial" && (
              <div>
              
                <b>{adminLabels.totalAmountPaid}</b>
                <p className="mb-0">
                  {adminLabels.service} {" = "}
                  <b>
                    €{" "}
                    {europeanCurrencyFormatter(
                      orderAmountCalculation(selectedAdditionalBudget?.amount)
                    )}{" "}
                  </b>
                </p>
                <p className="mb-0">
                  {adminLabels.vat} {" = "}
                  <b>
                    €{" "}
                    {europeanCurrencyFormatter(
                      vatAmountCalculation(selectedAdditionalBudget?.amount)
                    )}{" "}
                  </b>
                </p>
                <p className="mb-0">
                  {adminLabels.supplementaryAmount} {" = "}
                  <b>
                    €{" "}
                    {europeanCurrencyFormatter(
                      !is_supplementary ? 0 : selectedAdditionalBudget?.supplementary_fees
                    )}
                  </b>
                </p>
                <p className="">
                  {adminLabels.serviceVATSupplements} {" = "}
                  <b>
                    €{" "}
                    {europeanCurrencyFormatter(
                      totalAmountCalculation(
                        Number(
                          orderAmountCalculation(
                            selectedAdditionalBudget?.amount
                          )
                        ),
                        Number(
                          vatAmountCalculation(selectedAdditionalBudget?.amount)
                        ),
                        Number(!is_supplementary ? 0 : selectedAdditionalBudget?.supplementary_fees)
                      )
                    )}
                  </b>
                </p>
                <p>
                  <b className="text-lg">{adminLabels.user}</b>
                </p>
                <p>
                  {adminLabels?.partialAmountOf} {partialAmount} % {" = "}
                  <b>
                    €
                    {europeanCurrencyFormatter(
                      Number(selectedAdditionalBudget?.amount) *
                        (Number(partialAmount) / 100)
                    )}{" "}
                  </b>
                </p>
                <p>
                  {adminLabels?.partialAmountOf} {adminLabels?.supplementary} {partialAmountSupplementary} % {" = "}
                  <b>
                    €
                    {europeanCurrencyFormatter(Number(!is_supplementary ? 0 : selectedAdditionalBudget?.supplementary_fees) * (Number(partialAmountSupplementary) / 100))}{" "}
                  </b>
                </p>
               
                <p>
                  <b className="text-lg">{adminLabels?.serviceProvider}</b>
                </p>
                <p>
                  {adminLabels?.partialAmountOf} {100 - partialAmount} % {" = "}
                  <b>
                    €
                    {europeanCurrencyFormatter(
                      Number(selectedAdditionalBudget?.amount) *
                        (Number(100 - partialAmount) / 100)
                    )}{" "}
                  </b>
                </p>
                <p>
                    {adminLabels?.partialAmountOf} {adminLabels?.supplementary} {100 - partialAmountSupplementary} % {" = "}
                  
                  <b>
                    €
                    {europeanCurrencyFormatter(Number(!is_supplementary ? 0 : selectedAdditionalBudget?.supplementary_fees) * (Number(100 - Number(partialAmountSupplementary)) / 100))}{" "}
                  </b>
                </p>
                <p>
                  {adminLabels?.docuDocDeduction} 30%{" "}
                  {adminLabels?.ofPartialAmount} ={" "}
                  <b>
                    €
                    {europeanCurrencyFormatter(
                      Number(
                        Number(selectedAdditionalBudget?.amount) *
                          (Number(100 - partialAmount) / 100)
                      ) * 0.3
                    )}{" "}
                  </b>
                </p>
                <p>
                  {adminLabels?.serviceProvider} 70%{" "}
                  {adminLabels?.ofPartialAmount} ={" "}
                  <b>
                    €
                    {europeanCurrencyFormatter(
                      Number(
                        Number(selectedAdditionalBudget?.amount) *
                          (Number(100 - partialAmount) / 100)
                      ) * 0.7
                    )}{" "}
                  </b>
                </p>
                <b>{adminLabels?.totalAmount}</b>
                <p>
                  {adminLabels?.serviceProvider} 70% +{" "}
                  {adminLabels?.supplementaryAmount} {" = "}
                  <b>
                    €
                    {europeanCurrencyFormatter((Number(Number(selectedAdditionalBudget?.amount) * (Number(100 - partialAmount) / 100)) * 0.7) + (Number(!is_supplementary ? 0 : selectedAdditionalBudget?.supplementary_fees) * (Number(100 - partialAmountSupplementary) / 100)))}{" "}
                  </b>
                </p>
                {/* <p>
                  {adminLabels?.serviceProvider} 70% +{" "}
                  {adminLabels?.supplementaryAmount} {" = "}
                  <b>
                    €
                    {europeanCurrencyFormatter(
                      Number(Number(selectedAdditionalBudget?.amount) *
                          (Number(100 - partialAmount) / 100)
                      ) *
                        0.7 +
                        Number(!is_supplementary ? 0 : selectedAdditionalBudget?.supplementary_fees) * (Number(100 - partialAmount) / 100)
                    )}{" "}
                  </b>
                </p> */}
              </div>
            )}
            <div className="flex justify-center py-2">
              {disputeAdditionalBudgetStatus === "continue" && (
                <ButtonComponent
                  title={adminLabels?.confirm}
                  onClick={ContinueAdditionalBudgetDispute}
                />
              )}
              {["user", "provider"].includes(
                disputeAdditionalRefundComplete
              ) && (
                <ButtonComponent
                  title={adminLabels?.confirm}
                  onClick={CompleteCancelAdditionalDispute}
                />
              )}
              {disputeAdditionalRefundStatus === "partial" && (
                <ButtonComponent
                  title={adminLabels?.confirm}
                  onClick={PartialCancelOrderDispute}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        visible={showInfoModal}
        centered
        footer={null}
        onCancel={() => {
          setShowInfoModal(false);
        }}
        className="global-modal-css-scroll-height "
      >
        <div>
          <h4 className="font-bold text-2xl text-center text-gray-600	">
            {adminLabels.information}
          </h4>
        </div>

        <div className="scrollable-fixed-height py-5 render-html-class">
          {<InformationContainer info={modal_Content} />}
        </div>
      </Modal>
    </section>
  );
};
const mapStateToProps = (state) => {
  return {
    showWorkers: state.workerList.workers,
    providerDetail: state.providerLoginDetail.loggedUserDetailOfProvider,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
    getWorkers: (props) => {
      dispatch(FetchWorkersAction(props));
    },
    removeWorkerList: () => {
      dispatch(DeleteWorkerAtLogoutTime());
    },
    setProviderLogout: () => {
      dispatch(ProviderLogoutAction());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BudgetResponseDetails);
