import React, { useState } from "react";
import Modal from "antd/lib/modal/Modal";

export default function ModalComponent({
  filterModal,
  setFilterModal = () => {},
  btn = false,
  modalTitle = false,
  children,
  width = 400,
  closeModal,
  buttonFunc,
  className,
}) {
  return (
    <Modal
      visible={filterModal}
      width={width}
      footer={false}
      onCancel={closeModal}
      className={className}
    >
      <div>
        {modalTitle && (
          <h4 className="font-bold text-2xl text-center text-gray-600	">
            {modalTitle}
          </h4>
        )}
      </div>
      {children}
      {btn && (
        <button
          className="w-full bg-primary rounded-full py-3 text-white mt-4"
          onClick={buttonFunc}
        >
          {btn} Save
        </button>
      )}
    </Modal>
  );
}
