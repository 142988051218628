const initialState = {
  loggedUserDetailOfProvider: "",
};

const ProviderLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PROVIDER_LOGGED_USER": {
      return { ...state, loggedUserDetailOfProvider: action.payload };
    }
    case "PROVIDER_LOGOUT_USER": {
      return {
        ...state,
        loggedUserDetailOfProvider: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default ProviderLoginReducer;
