import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Spin } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ForgotPasswordApi } from "../config/api";
import { providerLabels } from "../config/label/providerLabels";
import { Images } from "../constant/Images";
import { LoaderStateAction } from "../Redux/actions/loaderStateAction";
import { Errors, Success } from "./antdToast";

const { loginLogo } = Images;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ForgotCards = (props) => {
  // declare variable
  const [email, setEmail] = useState("");
  const { push } = useHistory();
  const onFinish = (values) => {
    if (email) {
      sendEmailForCode();
    }
  };

  const sendEmailForCode = () => {
    props.setLoaderState(true);
    ForgotPasswordApi(
      email,
      (success) => {
        if (success?.status === 200) {
          props.setLoaderState(false);
          if (success?.data?.status) {
            Success(success?.data?.response?.message);
            setTimeout(() => {
              push({ pathname: `/verfication-otp`, state: email });
            }, 1000);
          } else {
            Errors(success?.data?.error?.message);
          }
        }
      },
      (error) => {
        if (error?.response?.status === 422) {
          Errors(error?.response?.data?.error?.message_sp);
          props.setLoaderState(false);
        } else {
          Errors("Network Error");
          props.setLoaderState(false);
        }
      }
    );
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <>
      <div className="absolute left-14 top-absolute-40 w-1/4">
        <img src={loginLogo.default} />
        <h3 className="font-bold ml-4 mt-2" id="welcome-heading">
          {providerLabels.helloPartner}
        </h3>
      </div>
      <div className="flex text-center h-screen	justify-center items-center">
        <div className="rounded-lg bg-white p-5 relative pb-0 m-4 sm:m-4 md:m-8 lg:m-0  h-2/2 w-full sm:w-full md:w-full lg:w-1/4">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <img src={Images.email.default} className="m-auto my-10" />
            <span className="text-center font-normal text-4xl mb-10">
              {providerLabels.enterEmail}
            </span>
            <p style={{ fontSize: 16 }} className="mt-4 text-gray-500 mx-5">
              {providerLabels.weWillSendYouACodeToChangePassword} <br />
            </p>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: providerLabels.pleaseInputYoureEmailAddress,
                },
                {
                  type: "email",
                  message: providerLabels.theInputIsNotvalidEMail,
                },
              ]}
            >
              <Input
                className="shadow-md border-radius20 p-3"
                placeholder={providerLabels.emailAddress}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button
                className="padding-sign-button"
                shape="round"
                type="primary"
                htmlType="submit"
                block
              >
                {props.loader === true ? (
                  <Spin indicator={antIcon} />
                ) : (
                  providerLabels.send
                )}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    loader: state.loaderToggle.loaderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotCards);
