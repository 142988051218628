import { AdminLogin } from "../../config/api/index";
import { Errors, Success } from "../../components/antdToast";

export function SetLoginDataInAction(data, props, props1) {
  return (dispatch) => {
    AdminLogin(
      data,
      (success) => {
        if (success?.status === 200) {
          localStorage.setItem("token", success?.data?.response?.detail?.token);
          Success(success?.data?.response?.message);
          dispatch({
            type: "LOGGED_USER",
            payload: success?.data?.response?.detail,
          });
          props.setLoaderState(false);
          if (success?.data?.response?.detail?.type === "admin") {
            localStorage.setItem("type", success?.data?.response?.detail?.type);
            localStorage.setItem("status",success?.data?.response?.detail?.admin?.status);
            props1.history.push("/admin/dashboard");
          }
        }
      },
      (error) => {
        if (error?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.data?.error?.message);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
}
