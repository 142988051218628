import axios from "axios";

export const padLeadingZeros = (num) => {
  let s = `${Number(num)}`;
  if (s < 10 && s > 0) {
    s = `0${s}`;
  } else {
    s = s;
  }

  return s;
};

export const regexForPhoneNumber = (phoneNumber) => {
  return phoneNumber.replace(/[^0-9]/g, "")?.slice(0, 15);
};

export const ValidateEmail = (email) => {
  let validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (email.match(validRegex)) {
    return true;
  } else {
    return false;
  }
};

export const regexToRemoveNumberFromTheString = (string) => {
  return string.replace(/[0-9]/g, "");
};

export const validateDescription = (content) => {
  let lengthLength = content?.replace(/<[^>]+>/g, "");
  return lengthLength?.trim()?.length !== 0;
};

export const validatePassword = (password) => {
  var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  return re.test(password);
};

export const functionToConvertStringToCamelCase = (str = "") => {
  return str
    ?.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word?.toLowerCase() : word?.toUpperCase();
    })
    ?.replace(/\s+/g, "");
};

export const functionToPreventSignsFromNumberFromPricing = (event) => {
  if (
    event.key === "-" ||
    event.key === "+" ||
    event.key === "e" ||
    event.key === "E"
  ) {
    event.preventDefault();
  }
};
export const currencyFormat = (amount = 0) => {
  let currencyFormat = Intl.NumberFormat("de-DE", {
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return `${currencyFormat.format(amount)}`;
};
export const replaceDecimalWithComma = (amount = 0) => {
  return String(amount).replace(".", ",");
};

export const limitDecimals = (num) => {
  if (Number.isInteger(Number(num))) {
    return num;
  } else {
    return String(parseFloat(num).toFixed(2));
  }
};
export const replaceAt = (string, index, replaceWith) => {
  return string.replace(new RegExp(`^(.{${index}})(.)`), `$1${replaceWith}`);
};
export const regexForPricing = (input, nativeEvent) => {

  let value = input.includes(",") && input[input.length-1] && nativeEvent?.data === "." ? input.substring(0, input.length - 1) : input.replace(/\./g, ',');

  // Replace dots with commas
  // let value =  input.replace(/\./g, ',');
    
  // Remove all invalid characters except digits and commas
  value = value.replace(/[^0-9,]/g, '');

  // Check if the input already contains a comma
  const commaCount = (value.match(/,/g) || []).length;

  // If the user tries to add another comma, remove the last comma
  if (nativeEvent?.data === ',' && commaCount > 0) {
    // Remove the last comma if there's more than one
    const lastCommaIndex = value.lastIndexOf(',');
    value = value.slice(0, lastCommaIndex) + value.slice(lastCommaIndex + 1);
  }

  return value;
};

export const regexToReplaceDecimalsWithComma = (amount = "0") => {
  return String(amount)?.replace(",", ".");
};
export const downloadFiles = (url = "", name = "") => {
  axios({
    url: url,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link?.setAttribute("download", name);
      document.body.appendChild(link);
      link?.click();
      document.body.removeChild(link);
    })
    .catch((err) => {});
};

export const europeanCurrencyFormatter = (amount = 0) => {
  let currencyFormat = Intl.NumberFormat("de-DE", {
    currency: "EUR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return `${currencyFormat.format(amount)}`;
};
const vat = 21 / 100;
// service provider payment table calculations

// Original Service + VAT (Price Established)
// column M
export const orderOriginalPriceWithVat = (service) => {
  const { original_price } = service;
  return Number(original_price) + vat * Number(original_price);
};
// Original Service (Price established / 1.21)
// column N
export const onePointOffOForderOriginalPriceWithVat = (service) => {
  return Number(orderOriginalPriceWithVat(service) / 1.21);
};

// Discount %
// column o
export const orderDiscountPercentage = (service) => {
  return String(
    100 -
      (Number(service?.reduced_price) / Number(service?.original_price)) *
        100 || 0
  );
};

// Discount € (Service+VAT)
// column p
export const orderDiscountPriceWithVat = (service) => {
  return (
    Number(orderDiscountPercentage(service)) *
    Number(onePointOffOForderOriginalPriceWithVat(service))
  );
};

export const get_url_extension = (url) => {
  return url?.split(/[#?]/)?.[0]?.split(".")?.pop()?.trim();
};

export const capitalizeFirstLetter = (string = "") => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const regexToCheckIfTheStringContainsEmail = (email) => {
  var re =
    /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  return re.test(email);
};

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export const regexToCheckIfTheStringContainsPhoneNumber = (input) => {
  let matched = input.split(" ");
  let maxIndex = matched.length - 1;
  let filtered = matched.filter((s, i) => {
    if (i !== maxIndex && isNumeric(s) && isNumeric(matched[i + 1]))
      return true;
    if (isNumeric(s) && s.length >= 7 && s.length <= 15) return true; // Adjust the length range according to your needs
    return false;
  });
  return filtered.length > 0;
};


export const extractContent = (s) => {
  var span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
};
