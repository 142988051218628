import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiOutlineArrowDown } from "react-icons/ai";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Errors } from "../../components/antdToast";
import TableComponent from "../../components/table";
import {
  GetHelpMessagesMethod,
  HelpAndWorkWithUsReplyMethod,
} from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { fetchHelpAction } from "../../Redux/actions/fetchHelpAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";

const Help = (props) => {
  const { goBack, push } = useHistory();

  //////////// Modal ///////////////////
  const [helpUnRelatedToService, setHelpUnRelatedToService] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [modal_Content, setModalContent] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getHelp();
    document.body.style.overflow = "auto";
    return function cleanup() {
      document.body.style.overflow = "auto";
    };
  }, []);

  const replyFunction = (params) => {
    const obj = {
      id: params?.id,
      actionFor: "help",
    };
    HelpAndWorkWithUsReplyMethod(
      obj,
      (res) => {
        if (res?.data?.status) {
          getHelp();
        }
      },
      (err) => {
        if (err?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(err?.response?.data?.message);
          push("/admin");
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };

  const unrelated_to_Service_Columns = [
    {
      align: "center",
      title: adminLabels.no,
      dataIndex: "no",
      key: "no",
      width: 40,
      render: (text) => <a>{text}</a>,
    },
    {
      align: "center",
      title: adminLabels.name,
      dataIndex: "name",
      key: "name",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.name.toLowerCase().includes(value.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.name.localeCompare(b.name),
      showSorterTooltip: false,
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      align: "center",
      title: adminLabels.email,
      dataIndex: "email",
      key: "email",
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.email.toLowerCase().includes(value.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.email.localeCompare(b.email),
      showSorterTooltip: false,
      render: (email) => (
        <Tooltip placement="topLeft" title={email}>
          {email}
        </Tooltip>
      ),
    },
    {
      align: "center",
      title: adminLabels.phone,
      key: "phone",
      dataIndex: "phone",
      width: 150,
      ellipsis: {
        showTitle: false,
      },

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.phone?.toLowerCase().includes(value?.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.phone - b.phone,
      showSorterTooltip: false,
      render: (phone) => (
        <Tooltip placement="topLeft" title={phone}>
          {phone === null || phone === undefined ? "N/A" : phone}
        </Tooltip>
      ),
    },
    {
      align: "center",
      title: adminLabels.title,
      dataIndex: "Title",
      key: "Title",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.Title.toLowerCase().includes(value.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.Title.localeCompare(b.Title),
      showSorterTooltip: false,
      render: (Title) => (
        <Tooltip placement="topLeft" title={Title}>
          {Title}
        </Tooltip>
      ),
    },
    {
      align: "center",
      title: adminLabels.message,
      // dataIndex: "Message",
      // key: "Message",
      ellipsis: true,
      width: 95,
      render: (text, index) => (
        <div className="table-quill flex justify-center items-center">
          <span
            className="cursor-pointer flex items-center space-x-1 "
            onClick={() => {
              setShowInfoModal(true);
              setModalContent(text);
            }}
          >
            <EyeOutlined />
            <span>{adminLabels.infoBox}</span>
          </span>
        </div>
      ),
    },
    {
      title: adminLabels.action,
      key: "action",
      align: "center",
      width: 130,
      render: (text) => (
        <a href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${text.email}`} target="_blank">
        {/* <a href={`mailto:${text.email}`}> */}
          {!text?.is_read ? <span className="font-bold text-red-500 text-4xl mr-1">.</span> : <span className="font-bold font-color-blue text-4xl mr-1 opacity-0">.</span> }

          <Button
            type="disable"
            shape={"round"}
            className="background-color-gray whiteTxt"
            onClick={() => replyFunction(text)}
          >
            {adminLabels.response}
          </Button>
        </a>
      ),
    },
  ];

  const getHelp = () => {
    GetHelpMessagesMethod(
      (res) => {
        let filterArrayUnRelatedService = res.data.response.detail.filter(
          (help, index) => {
            return help.is_service_related === (0 || "0");
          }
        );

        let unrelatedtoservice = filterArrayUnRelatedService.map(
          (unRelatedToService, index) => {
            return {
              ...unRelatedToService,
              no: index + 1,
              name:
                unRelatedToService.user.first_name +
                " " +
                unRelatedToService.user.last_name,
              email: unRelatedToService.user.email,
              phone: unRelatedToService.user.phone_number,
              Title: unRelatedToService.title,
              Message: unRelatedToService.description,
            };
          }
        );
        setHelpUnRelatedToService(unrelatedtoservice);
        setLoading(false);
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };

  return (
    <section className="">
      <Spin tip={adminLabels.loading} spinning={loading}>
        <div className="flex justify-center lg:justify-between  items-center mt-5 flex-wrap ">
          <div className="pl-5 flex self-center justify-center items-center mb-4 sm:mb-4 lg:mb-0  sub-h3">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{adminLabels.back}</span>
            </div>
            <h3 className="ml-10	">{adminLabels.help}</h3>
          </div>
          <div className=" mr-2 sm:mr-2 lg:mr-28 flex">
            {/* <div className="mr-2 ">
            <Button shape={"circle"} className="icon-button p0 ">
              <FiSearch size={15} />
            </Button>
          </div> */}
            <div>
              <CSVLink
                data={helpUnRelatedToService?.map((item, index) => {
                  return {
                    no: index + 1,
                    name: item?.name,
                    email: item?.email,
                    phone: item?.phone,
                    title: item?.Title,
                    message: item?.Message,
                  };
                })}
                headers={[
                  { label: adminLabels.no, key: "no" },
                  { label: adminLabels.name, key: "name" },
                  { label: adminLabels.email, key: "email" },
                  { label: adminLabels.phone, key: "phone" },
                  { label: adminLabels.title, key: "title" },
                  { label: adminLabels.message, key: "message" },
                ]}
                filename={`${adminLabels.help}.csv`}
                target="_blank"
              >
                <Button
                  shape={"round"}
                  className="icon-button "
                  icon={<AiOutlineArrowDown color="#5ab3f0" />}
                >
                  {adminLabels.exportCSV}
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <div className="container mx-auto px-0.5  sm:px-0.5 lg:px-20 mt-5">
          {/* <Tabs onChange={callback} type="card">
          <Tabs.TabPane tab="Related to Service" key="1">
            <TableComponent
              columns={related_to_Service_Columns}
              scrolly="320px"
               data={helpRelatedToService}
              pagination={{ defaultPageSize: 5 }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Unrelated to Service" key="2">
            <TableComponent
              columns={unrelated_to_Service_Columns}
              scrolly="320px"
               data={helpUnRelatedToService}
              pagination={{ defaultPageSize: 5 }}
            />
          </Tabs.TabPane>
        </Tabs> */}
          <TableComponent
            columns={unrelated_to_Service_Columns}
            scrolly="500px"
            data={helpUnRelatedToService}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
          />
        </div>
        <Modal
          visible={showInfoModal}
          footer={null}
          centered
          className="global-modal-css-scroll-height"
          onCancel={() => {
            setShowInfoModal(false);
          }}
        >
          <div>
            <h4 className="font-bold text-2xl text-center text-gray-600	">
              {adminLabels.helpMessage}
            </h4>
          </div>
          <div className="scrollable-fixed-height">
            <div className="mb-1">
              <p className="mb-0">
                <b>{adminLabels.title}:</b>
              </p>
              <p>{modal_Content?.Title}</p>
            </div>

            <div>
              <p className="mb-0">
                <b>{adminLabels.message}:</b>
              </p>
              <p>{modal_Content?.Message}</p>
            </div>
          </div>
        </Modal>
      </Spin>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    showHelp: state.geHelpList.Help,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getProvidersList: (props) => {
    //   dispatch(fetchHelpAction(props));
    // },
    getHelpList: (props) => {
      dispatch(fetchHelpAction(props));
    },
    setLogoutUser: (data) => {
      dispatch(LogoutUserAction(data));
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Help);
