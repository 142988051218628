import { SearchOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Menu, Spin, Switch, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiOutlineArrowDown } from "react-icons/ai";
import { RiArrowDropLeftLine, RiArrowDropRightLine } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";
import { Errors, Success } from "../../components/antdToast";
import { TableComponent } from "../../components/index";
import {
  GetServiceWorkers,
  UpdateServiceProviderStatusMethod,
} from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { Images } from "../../constant/Images";

const { searchBar } = Images;

function WorkerOfServiceProvider() {
  const { goBack } = useHistory();
  const [active, setActive] = React.useState(false);
  const [serviceWorkers, setServiceWorkers] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getServicesWorkers();
    document.body.style.overflow = "auto";
    return function cleanup() {
      document.body.style.overflow = "auto";
    };
  }, []);

  const getServicesWorkers = () => {
    GetServiceWorkers(
      (res) => {
        setServiceWorkers(
          res.data?.response?.detail?.reverse()?.map((serviceWorker, index) => {
            return {
              ...serviceWorker,
              detail: {
                avatar: serviceWorker.avatar,
                name: serviceWorker.name,
              },
              no: index + 1,
              fullname: serviceWorker.name,
              email: serviceWorker.email,
              phone: serviceWorker.phone,
              providerName: serviceWorker.provider.name,
              registerdDate: moment(serviceWorker.created_at).format(
                "DD-MMM-YYYY"
              ),
              // listofServices:serviceWorker.,
            };
          })
        );
        setLoading(false);
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };

  const setServiceProviderActive = (id) => {
    let userId = new FormData();
    userId.append("service_provider_id", id);
    UpdateServiceProviderStatusMethod(
      userId,
      (success) => {
        if (success.status === 200) {
          let serviceWorkerUpdatedArray = serviceWorkers.map((item) => {
            if (item.service_provider_id === id) {
              return { ...item, status: item.status === 1 ? 0 : 1 };
            } else {
              return item;
            }
          });
          setServiceWorkers(serviceWorkerUpdatedArray);
          Success(success?.data?.response?.message);
          // fetchServiceProviders();
        }
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  const menu = (id, values) => (
    <Menu className="menu-class menu-main-class" style={{ borderRadius: 20 }}>
      <Menu.Item className="bg-white" style={{ borderRadius: 20 }}>
        <Link to={`/admin/worker-detail/${id}`} params={{ id }}>
          <div
            className="flex justify-between bg-white py-2 w-full  items-center"
            style={{ width: 160 }}
          >
            <div>
              <span>{adminLabels.workerDetails}</span>
            </div>
            <div>
              <RiArrowDropRightLine color="#5ab3f0" size={20} />
            </div>
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item className="bg-white" style={{ borderRadius: 20 }}>
        <div
          className="flex justify-between bg-white py-2 w-full  items-center"
          style={{ width: 160 }}
        >
          <div>
            <span>{adminLabels.workerStatus}</span>
          </div>
          <div>
            <Switch
              size="small"
              onChange={() =>
                setServiceProviderActive(values.service_provider_id)
              }
              checked={values?.status === 1 ? true : false}
            />
          </div>
        </div>
      </Menu.Item>
    </Menu>
  );
  const columns = [
    {
      align: "center",
      title: adminLabels.no,
      dataIndex: "no",
      key: "no",
      width: 60,
      render: (text) => <a>{text}</a>,
    },

    {
      align: "center",
      title: adminLabels.name,
      dataIndex: "detail",
      key: "detail",
      width: 200,

      render: (text, index) => (
        <Tooltip placement="topLeft" title={text.name}>
          <div className={"flex"}>
            <img
              src={text.avatar !== null ? text.avatar : Images.profile.default}
              style={{ width: 50, height: 45 }}
              className="rounded-lg"
              alt="detail image"
            />
            <div className="pl-2 text-left mt-2 capitalize">
              <label>{text.name}</label>
              <br />
            </div>
          </div>
        </Tooltip>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.detail?.name
          ?.toLowerCase()
          .includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.detail?.name?.localeCompare(b?.detail?.name),
    },
    {
      align: "center",
      title: adminLabels.email,
      dataIndex: "email",
      key: "email",
      width: 130,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.email.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (email) => (
        <Tooltip placement="topLeft" title={email}>
          {email}
        </Tooltip>
      ),
    },
    {
      align: "center",
      title: adminLabels.phone,
      key: "phone",
      dataIndex: "phone",
      width: 100,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.phone.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      render: (phone) => (
        <Tooltip placement="topLeft" title={phone}>
          {phone}
        </Tooltip>
      ),
    },
    {
      align: "center",
      title: adminLabels.providerName,
      key: "providerName",
      dataIndex: "providerName",
      className: "capitalize",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (companyName) => (
        <Tooltip placement="topLeft" title={companyName}>
          {companyName}
        </Tooltip>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.providerName.toLowerCase().includes(value.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.providerName.localeCompare(b.providerName),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      align: "center",
      title: adminLabels.registeredDate,
      key: "registerdDate",
      dataIndex: "registerdDate",
      width: 150,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        moment(a.registerdDate).unix() - moment(b.registerdDate).unix(),
      showSorterTooltip: false,
      render: (registerdDate) => (
        <Tooltip placement="topLeft" title={registerdDate}>
          {registerdDate}
        </Tooltip>
      ),
    },

    {
      align: "center",
      title: adminLabels.action,
      key: "action",
      width: 100,

      render: (text, index) => (
        <div className="text-center">
          <Dropdown
            overlay={() => menu(text.service_provider_id, text)}
            className={"flex"}
            trigger={["click"]}
          >
            <div
              type="disable"
              shape={"round"}
              className="background-color-gray whiteTxt rounded-full py-2 justify-center cursor-pointer	"
            >
              <span>{adminLabels.viewDetails}</span>
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  const Menus = (ids, values, props) => {
    let id = ids?.values?.service_provider_id;

    const [toggle, setToggle] = useState(ids?.values?.status);
    return (
      <Menu className="menu-class menu-main-class" style={{ borderRadius: 20 }}>
        <Menu.Item className="bg-white" style={{ borderRadius: 20 }}>
          <Link to={`/admin/worker-detail/${id}`} params={{ id }}>
            <div
              className="flex justify-between bg-white py-2 w-full  items-center"
              style={{ width: 160 }}
            >
              <div>
                <span>User Details</span>
              </div>
              <div>
                <RiArrowDropRightLine color="#5ab3f0" size={20} />
              </div>
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item className="bg-white" style={{ borderRadius: 20 }}>
          <div
            className="flex justify-between bg-white py-2 w-full  items-center"
            style={{ width: 160 }}
          >
            <div>User Status</div>
            <div>
              <Switch
                size="small"
                checked={toggle}
                onChange={() => {
                  setServiceProviderActive(id);
                  setToggle(!toggle);
                }}
              />
            </div>
          </div>
        </Menu.Item>
      </Menu>
    );
  };
  return (
    <section className="">
      <Spin tip={adminLabels.loading} spinning={loading}>
        <div className="flex justify-between items-center mt-5">
          <div className="pl-5 flex self-center justify-center items-center sub1-h3">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span> {adminLabels.back}</span>
            </div>
            <h3 className="ml-10	">
              {adminLabels.workerOfServiceProvidersAccountManagement}
            </h3>
          </div>
          <div className=" mr-2 sm:mr-2 lg:mr-28 flex">
           
            <div>
              <CSVLink
                data={serviceWorkers?.map((item,index) => {
                  return {
                    ...item,
                    no: index + 1
                  }
                })}
                headers={[
                  { label: adminLabels.no, key: "no" },
                  { label: adminLabels.name, key: "name" },
                  { label: adminLabels.email, key: "email" },
                  { label: adminLabels.phone, key: "phone" },
                  { label: adminLabels.providerName, key: "providerName" },
                  {label:adminLabels.registeredDate, key : "registerdDate"}
                  
                ]}
                filename={`${adminLabels.workerOfServiceProvidersAccountManagement}.csv`}
                target="_blank"
              >
                <Button
                  shape={"round"}
                  className="icon-button "
                  icon={<AiOutlineArrowDown color="#5ab3f0" />}
                >
                  {adminLabels.exportCSV}
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <div className="container mx-auto px-0.5 sm:px-0.5 lg:px-20 mt-5">
          <TableComponent
            columns={columns}
            data={serviceWorkers}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
            scrolly="500px"
            scroll="100%"
          />
        </div>
      </Spin>
    </section>
  );
}

export default WorkerOfServiceProvider;
