import React from "react";
import ProviderCard from "../../components/ProviderCard";

export default function ServiceProviderLogin(props) {
  return (
    <div className="bg-img">
      <ProviderCard props={props} />
    </div>
  );
}
