import React from 'react';
import { Images } from './../constant/Images';
import { AiFillStar } from 'react-icons/ai';

function CardProfile({ name, rating, phoneNumber = false, email, noOfManagers, nameOfService ,provider=false}) {
    return (

        <div className=" rounded-lg bg-white p-4 grid grid-rows-2  grid-flow-col" >
            <div className="grid grid-cols-6">
                <div className='col-span-12 sm:col-span-6 md:col-span-3 flex'>
                    <img className="h-14" src={Images.userProfilePlaceholder.default} />
                    <div className="pl-4 ">
                        <strong className="font-bold text-xl">
                            {name}
                        </strong>
                        <div className="flex flex-row  ">
                            <AiFillStar className="mt-1" color={"gold"} />
                            <span className="pl-2">{rating}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-12 sm:col-span-6 md:col-span-3 mt-3 grid grid-cols-12 divide-x gap-3">
                {phoneNumber && <div className="col-span-12 sm:col-span-6 md:col-span-3 " ><strong>Phone Number</strong> <br />{phoneNumber}</div>}
                <div className="col-span-12 sm:col-span-6 md:col-span-3 pr-8 pl-8"><strong>Email Address</strong> <br />{email}</div>
                <div className="col-span-12 sm:col-span-6 md:col-span-3 pr-8 pl-8"><strong>Managers</strong> <br />{noOfManagers}</div>
                <div className="col-span-12 sm:col-span-6 md:col-span-3 pr-8 pl-8"><strong>Service Name</strong> <br />{nameOfService}</div>
            </div>
        </div>
    );
}

export default CardProfile;