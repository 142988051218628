import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiOutlineArrowDown } from "react-icons/ai";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { Link, useHistory, useParams } from "react-router-dom";
import { TableComponent } from "../../components";
import { Errors, Success } from "../../components/antdToast";
import RateAndReviews from "../../components/rateAndReviews";
import {
  GetServiceProviderDetails,
  UpdateServiceProviderOrderReviewStatusMethod,
} from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { europeanCurrencyFormatter } from "../../config/utils";
import "./serviceProviderDetails.css";
import UserDetailContainerCard from "./UserDetailContainerCard";

function ServiceProvider({ dividerShow = false }) {
  const [active, setActive] = useState(false);
  const [provider, setProvider] = useState({});
  const [providerServices, setProviderServices] = useState([]);
  const [providerOrders, setProviderOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentSelectedLangue, setCurrentSelectedLangue] = useState("spanish");
  const [serviceProviderReviews, setServiceProviderReviews] = useState([]);

  const { goBack } = useHistory();
  const { providerID } = useParams();
  useEffect(() => {
    document.body.style.overflow = "auto";
    return function cleanup() {
      document.body.style.overflow = "auto";
    };
  });
  const updateServiceProviderOrderRateAndReviewStatus = (id) => {
    const obj = {
      order_id: id,
    };
    UpdateServiceProviderOrderReviewStatusMethod(
      obj,
      (success) => {
        if (success.status === 200) {
          setServiceProviderReviews((prev) =>
            prev?.map((item) => {
              if (item?.order_id === id) {
                return { ...item, status: item.status === 1 ? 0 : 1 };
              } else {
                return item;
              }
            })
          );
          Success(success?.data?.response?.message);
        }
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  useEffect(() => {
    setCurrentSelectedLangue(localStorage.getItem("primaryLanguage"));
    // Update the document title using the browser API
    FetchProvider(providerID);
  }, []);
  const FetchProvider = (id) => {
    GetServiceProviderDetails(
      id,
      (res) => {
        let providerDetail = res?.data?.response?.detail?.user;
        let providerServices = res?.data?.response?.detail?.services;

        let providerOrders = res?.data?.response?.detail?.orders
          ?.reverse()
          .map((providerOrder, index) => {
            return {
              ...providerOrder,
              no: index + 1,
              product: providerOrder.service,
              orderID: providerOrder.order_id,
              date: moment(providerOrder.created_at).format(
                "DD-MMM-YYYY HH:mm"
              ),

              price: Math.round(
                +providerOrder?.after_discount_amount +
                  +providerOrder?.supplementary_fees
              ),
            };
          });

        setProvider(providerDetail);
        setServiceProviderReviews(providerDetail?.ratings?.reverse());
        setProviderServices(providerServices);
        setProviderOrders(providerOrders);
        setLoading(false);
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
    //setProvider(provider)
    //})
    // GetServiceProvider((res,error)=>{
    //  let provider  =  res.data.response.detail.filter((value)=>{
    //    return value.service_provider_id === id
    //  })
    //  setProvider(provider)
    // })
  };
  const UpdateServiceProviderData = () => {
    let updatedObject = { ...provider, status: provider.status === 1 ? 0 : 1 };
    setProvider(updatedObject);

    FetchProvider(providerID);
  };
  const columns = [
    {
      align: "center",
      title: adminLabels.no,
      dataIndex: "no",
      key: "no",
      width: 50,
      render: (text) => <a>{text}</a>,
    },
    {
      align: "center",
      title: adminLabels.product,
      dataIndex: "product",
      key: "product",
      width: 200,
      render: (text, index) => (
        <div className={"flex"}>
          <img
            src={text.avatar}
            style={{ width: 50, height: 50 }}
            className="rounded-lg"
            alt="detail image"
          />
          <div className="pl-2 text-left">
            <label>
              <b>
                {
                  text?.[
                    currentSelectedLangue === "english" ? "name" : "name_sp"
                  ]
                }
              </b>
            </label>{" "}
            <br />
            <span style={{ color: "blue" }}>{text.desc}</span>
          </div>
        </div>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]
          ?.toLowerCase()
          ?.includes(value?.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      align: "center",
      title: adminLabels.orderId,
      dataIndex: "orderID",
      key: "orderID",
      width: 80,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.orderID.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      align: "center",
      title: adminLabels.date,
      key: "date",
      dataIndex: "date",
      width: 120,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: adminLabels.price,
      key: "price",
      dataIndex: "price",
      width: 60,
      // render: (text, index) => (
      //   <div className="text-center flex justify-center">
      //     {/* {text?.after_discount_amount} {text?.supplementary_fees} */}
      //     {Math.abs(+text?.after_discount_amount + +text?.supplementary_fees)}
      //     {(text?.after_discount_amount & text?.supplementary_fees) ? ' €' :''}
      //     €
      //   </div>
      // ),
      render: (text, index) => (
        <div className="text-center flex justify-center">
          {text ? "€ " + europeanCurrencyFormatter(text) : ""}
          {/* {text?.after_discount_amount} {text?.supplementary_fees} */}
          {/* {Math.round((+text?.after_discount_amount ) + (+text?.supplementary_fees))} */}
          {/* {Math.round((+text?.after_discount_amount ) + (+text?.supplementary_fees)) ? " €" : ""} */}
        </div>
      ),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.price - b.price,
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: adminLabels.action,
      key: "action",
      width: 80,

      render: (text, index) => (
        <div className="text-center flex justify-center">
          <Link
            to={{
              pathname: `/admin/provider-service-order-details/${text.order_id}`,
              state: { orderDetails: text },
            }}
            params={{ order_id: text.order_id }}
          >
            <Button
              // onClick={() =>
              //   push("/admin/user-booking-management-order-details")
              // }
              type="disable"
              shape={"round"}
              className="background-color-gray whiteTxt"
            >
              {adminLabels.details}
            </Button>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <section className="pb-20">
      <Spin tip={adminLabels.loading} spinning={loading}>
        <div className="gap-0 grid grid-cols-5 mt-5">
          <div className="col-span-2">
            <div className="flex self-center items-center sub-h3">
              <div
                onClick={() => goBack()}
                className="flex text-gray-400 items-center cursor-pointer ml-5"
              >
                <RiArrowDropLeftLine />
                <span>{adminLabels.back}</span>
              </div>
              <h3 className="ml-10	">
                {adminLabels.adminOfServiceProviderDetails}
              </h3>
            </div>
            <div className="container mx-auto pl-0.5 sm:pl-0.5 lg:pl-20 mt-5">
              <div className="  ">
                <UserDetailContainerCard
                  providerDetails={provider}
                  services={providerServices}
                  updateServiceProviderData={UpdateServiceProviderData}
                />
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div className="flex self-center items-center sub-h3">
              {/* <h3 className="ml-10	">Orders</h3> */}
              <div className="flex justify-between w-full">
                <div>
                  <h3 className="ml-10	">{adminLabels.orders}</h3>
                </div>
                <div>
                  <CSVLink
                    data={providerOrders.map((item, index) => {
                      return {
                        ...item,
                        no: index + 1,
                        nameOfService:
                          item?.product?.[
                            currentSelectedLangue === "english"
                              ? "name"
                              : "name_sp"
                          ],
                      };
                    })}
                    headers={[
                      { label: adminLabels.no, key: "no" },
                      { label: adminLabels.product, key: "nameOfService" },
                      { label: adminLabels.orderId, key: "orderID" },
                      { label: adminLabels.date, key: "date" },
                      { label: adminLabels.price, key: "price" },
                    ]}
                    filename={`${provider?.name} ${adminLabels.orders}.csv`}
                    target="_blank"
                  >
                    <Button
                      shape={"round"}
                      className="icon-button py-0 mr-5"
                      icon={<AiOutlineArrowDown color="#5ab3f0" />}
                    >
                      {adminLabels.exportCSV}
                    </Button>
                  </CSVLink>
                </div>
              </div>
            </div>
            <div className="container mx-auto pl-0.5 sm:pl-0.5 lg:pl-8 mt-5">
              <div className="  ">
                <div className="amin-of-service-provider-details-orders-table">
                  <TableComponent
                    columns={columns}
                    data={providerOrders}
                    scrolly="320px"
                    pagination={{ defaultPageSize: 3 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {serviceProviderReviews?.length > 0 ? (
          <div className="mx-20 mt-10">
            <div>
              <h3 className="">{adminLabels.reviews}</h3>
            </div>
            <div className="mt-5 grid max-w-auto p-6 bg-white rounded-xl">
              <div className="">
                <div>
                  <div className="">
                    {serviceProviderReviews?.map((review, reviewIdx) => (
                      <RateAndReviews
                        review={review}
                        reviewIdx={reviewIdx}
                        updateServiceProviderOrderRateAndReviewStatus={
                          updateServiceProviderOrderRateAndReviewStatus
                        }
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Spin>
    </section>
  );
}

export default ServiceProvider;
