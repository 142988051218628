import React from "react";

function TextAreaComponent({
  value,
  rows = 4,
  onChange,
  placeholder,
  type = "text",
  onKeyPress = () => {},
  shadowState,
}) {
  return (
    <div className="   rounded-2xl">
      <textarea
        rows={rows}
        className={
          shadowState
            ? `rounded-2xl py-3 px-4 w-full text-area-outline shadow-md`
            : "rounded-2xl py-3 px-4 w-full text-area-outline"
        }
        type={type}
        onChange={onChange}
        onKeyPress={onKeyPress}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
}

export default TextAreaComponent;
