//
const initialState = {
  budgets: [],
  loading: true,
};

const GetServiceProviderAdditionalBudgetsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "GET_PROVIDER_ADDITIONAL_BUDGETS": {
      return {
        ...state,
        budgets: action.payload,
        loading: action.loading,
      };
    }
    default:
      return state;
  }
};
export default GetServiceProviderAdditionalBudgetsReducer;
