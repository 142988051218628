import { Errors } from "../../components/antdToast";
import { Remove_Data_Form_Reducers } from "../../components/dataRemoveOnLogout";
import { GetServiceArea } from "../../config/api";

const FetchServiceAreaAction = (props) => {
  return (dispatch) => {
    GetServiceArea(
      (success) => {
        if (success?.status === 200) {
          dispatch({
            type: "SERVICE_AREA_LIST",
            payload: success?.data?.response?.detail,
          });
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Reducers(props);
          props.history.push("/admin")
        } else {
          Errors("Network Error");
        }
      }
    );
  };
};

const DeleteServiceAreasAtLogoutTime = () => {
  return (dispatch) => {
    dispatch({
      type: "REMOVE_SERVICE_AREAS",
      payload: [],
    });
  };
};

export { FetchServiceAreaAction, DeleteServiceAreasAtLogoutTime };
