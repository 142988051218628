import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, message, Modal, Select, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { RiArrowDropLeftLine } from "react-icons/ri";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Errors, Success } from "../../components/antdToast";
import ButtonComponent from "../../components/ButtonComponent";
import { Remove_Data_Form_Reducers } from "../../components/dataRemoveOnLogout";
import InputComponent from "../../components/InputComponent";
import ModalComponent from "../../components/Modal";
import TextAreaComponent from "../../components/textArea";
import {
  Add_Branch_Service,
  EditService,
  GetBranchesByServiceAreaId,
  GetNonAssignServiceForms,
  GetServiceArea,
  GetServiceAreaByBeneficiaryId,
  GetServiceProvider,
} from "../../config/api";
import { objectToFormData } from "../../config/formData/formData";
import { adminLabels } from "../../config/label/adminLabels";
import {
  currencyFormat,
  functionToPreventSignsFromNumberFromPricing,
  limitDecimals,
  regexForPricing,
  validateDescription,
} from "../../config/utils";
import { Images } from "../../constant/Images";
import { DeleteAppUsers } from "../../Redux/actions/fetchAppUsersAction";
import {
  DeleteBeneficiariesAtLogoutTime,
  FetchBeneficiariesAction,
} from "../../Redux/actions/fetchBeneficiariesAction";
import {
  DeleteBranchesAtLogoutTime,
  FetchBranchesAction,
} from "../../Redux/actions/fetchBranchesAction";
import {
  DeleteProvidersAtLogoutTime,
  FetchProvidersAction,
} from "../../Redux/actions/fetchProvidersAction";
import {
  DeleteServiceAreasAtLogoutTime,
  FetchServiceAreaAction,
} from "../../Redux/actions/fetchServiceAreaAction";
import { DeleteServiceForms } from "../../Redux/actions/fetchServiceFormsAction";
import {
  DeleteServicesAtLogoutTime,
  FetchServicesAction,
} from "../../Redux/actions/fetchServicesAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";
import "./addNewService.css";

const { Option } = Select;
const { uploadImg, checkbox, checkboxActive } = Images;
const currentSelectedLangue = localStorage.getItem("primaryLanguage");
var menuOptionsArray = [];

const AddBranchService = (props) => {
  const { goBack, push } = useHistory();
  // Declare Variable States
  const [openModal, setOpenModal] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);

  const [modalHeadingLabel, setModalHeadingLabel] = useState("");
  const [modalId, setModalId] = useState(0);
  const [value, setValue] = useState("");

  const [serviceName, setServiceName] = useState("");
  const [serviceNameEsp, setServiceNameEsp] = useState("");
  const [serviceInfo, setServiceInfo] = useState("");
  const [serviceInfoEsp, setServiceInfoEsp] = useState("");
  const [serviceImage, setServiceImage] = useState(null);
  const [originalPrice, setOriginalPrice] = useState("");
  const [reducedPrice, setReducedPrice] = useState("");
  const [discountedPricePercentage, setDiscountedPricePercentage] =
    useState("0");
  const [isSpecialPrice, setIsSpecialPrice] = useState(false);
  const [isServicePack, setIsServicePack] = useState(false);
  const [isServiceRegionRelated, setIsServiceRegionRelated] = useState(false);
  const [servicePackType, setServicePackType] = useState("fixed price");
  const [paymentType, sePaymentType] = useState("fixed price");
  const [serviceTimeType, setServiceTimeType] = useState([
    "Minute",
    "Hour",
    "Week",
    "Day",
  ]);

  const [serviceTime, setServiceTime] = useState(1);
  const [serviceStatus, setServiceStatus] = useState([]);
  const [serviceProviderIds, setServiceProviderIds] = useState([]);
  const [serviceProviderFeatures, setServiceProvidersFeatures] = useState([]);
  const [beneficiaryId, setBeneficiaryId] = useState("");
  const [serviceAreaId, setServiceAreaId] = useState("");
  const [branhes, setbranches] = useState([]);
  const [beneficiaryFeatures, setBeneficiaryFeatures] = useState([]);
  const [serviceAreaFeatures, setServiceAreaFeatures] = useState(
    props.showServiceArea
  );
  const [branchFeatures, setBranchFeatures] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [selectedTimeType, setSelectedTimeType] = useState("Minute");
  const [statusTitle, setStatusTitle] = useState("");
  const [statusDescription, setStatusDescription] = useState("");
  const [visible, setVisible] = useState(false);
  const [fieldIsRequired, setFieldIsrequired] = useState(false);
  const [selectedBeneficiariesName, setSelectedBeneficiariesName] = useState(
    []
  );

  const [selectedServiceAreaName, setSelectedServiceAreaName] = useState([]);
  const [selectedBranchesName, setSelectedBranchesName] = useState([]);
  const [selectedServiceProvidersName, setSelectedServiceProvidersName] =
    useState([]);
  const [beneficiaryModalButton, setBeneficiaryModalButton] = useState(true);
  const [isSupplementaryCharges, setIsSupplementaryCharges] = useState(false);

  const [stepsIncrement, setStepsIncrement] = useState([
    {
      id:
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15),
      name: "",
      price: "",
    },
  ]);
  const [current, setCurrent] = React.useState(0);
  const [menuoptions, setMenuOptions] = useState([]);
  const [supplementaryCharges, setSupplementaryCharges] = useState([]);
  const [nonAssignServiceForms, setNonAssignServiceForms] = useState([]);
  const [serviceFormId, setServiceFormId] = useState("");
  //call delete func of dropdown modal
  const handleText = (e, listId) => {
    setStepsIncrement((prev) => {
      return prev?.map((item) => {
        if (listId === item?.id) {
          return {
            ...item,
            name: e.target.value,
          };
        } else {
          return item;
        }
      });
    });
  };
  const handleAmount = (e, listId) => {
    setStepsIncrement((prev) => {
      return prev?.map((item) => {
        if (listId === item?.id) {
          return {
            ...item,
            price: regexForPricing(e.target.value, e.nativeEvent) || 1,
          };
        } else {
          return item;
        }
      });
    });
  };
  const prev = (id, index) => {
    supplementaryCharges.splice(index, 1);
    deleteStepInput(id);
  };
  // delete antd stepper in dropdownmodal function
  const deleteStepInput = (id) => {
    setCurrent(current - 1);
    let updatedArray = stepsIncrement?.filter((item) => {
      return item.id != id;
    });
    setStepsIncrement(updatedArray);
  };
  //stepper add in dropdown modal function
  const next = () => {
    setStepsIncrement((prevState) => {
      setCurrent(current + 1);
      return [
        ...prevState,
        {
          id:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
          name: "",
          price: "",
        },
      ];
    });
  };
  let history = useHistory();
  useEffect(() => {
    setServiceImage(null);
    if (props?.match?.params?.id) {
      let findObj = props?.showServices?.find(
        (obj) => obj?.service_id === props?.match?.params?.id
      );
      props.location.state === undefined && push("/admin/services");
      let service = props?.location?.state?.service;
      let mapedfeatures = [];
      GetServiceArea(
        (res) => {
          setServiceAreaFeatures(
            res?.data?.response?.detail?.map((a) => {
              if (a?.service_area_id === service?.service_area_id)
                return {
                  ...a,
                  checked: true,
                };
              else {
                return a;
              }
            })
          );
        },
        (error) => {
          if (error?.response?.status === 401) {
            Errors(error?.response?.data?.error?.message);
          } else {
            Errors("Network Error");
          }
        }
      );
      setServiceAreaFeatures(mapedfeatures);
      fetchBranchesByServiceAreaId(service?.service_area_id);
      setSelectedBranch([service?.branch]);
      setSelectedBranchesName([
        {
          branch: {
            name: service?.branch?.name,
          },
        },
      ]);
      setServiceName(service?.name || "");
      setServiceNameEsp(service?.name_sp || "");
      setServiceImage(service?.avatar);
      setServiceFormId(service?.service_form_id);
      let mappedbene = [];
      let mappedBH = [];
      let mappedSP = [];
      beneficiaryFeatures?.map((beneF, index) => {
        let arr = {
          ...beneF,
          checked:
            beneF?.beneficiary_id === findObj?.beneficiary_id ? true : false,
          priority:
            beneF?.beneficiary_id === findObj?.beneficiary_id
              ? findObj.priority
              : 0,
        };

        mappedbene.push(arr);
      });

      setSelectedBeneficiariesName([findObj?.beneficiary]);
      setSelectedServiceAreaName([service?.service_area]);

      findObj?.service_branches?.map((branches, index) => {
        let arr2 = {
          ...branches?.branch,
          checked: true,
          priority: branches?.priority,
        };
        mappedBH.push(arr2);
      });

      setSelectedServiceProvidersName(service?.service_providers);
      // setServiceProviderIds
      // setServiceProviderIds(service?.service_providers?.map((item) =>))

      setServiceProviderIds(
        service?.service_providers?.map(
          (item) => item?.service_provider?.service_provider_id
        )
      );
      setOriginalPrice(service?.original_price.replace(".", ","));

      setDiscountedPricePercentage(
        String(
          100 -
            (Number(service?.reduced_price) / Number(service?.original_price)) *
              100 || 0
        )
      );

      setIsSpecialPrice(service?.is_special_price === 1 ? true : false);
      setIsServicePack(service?.is_service_pack === 1 ? true : false);
      setIsServiceRegionRelated(
        service?.is_region_related === 1 ? true : false
      );
      setServiceTime(
        service?.service_pack_type === "monthly" ? 1 : service?.time
      );
      setSelectedTimeType(
        service?.service_pack_type === "monthly" ? null : service?.time_type
      );
      // setServicePackType(service?.time_type==="week"?"fixed price":service?.time_type === "Hours"?"fixed price":service?.time_type==="Day"?"fixed price":"monthly")
      setServicePackType(
        service?.time_type === "minute" ||
          service?.time_type === "Week" ||
          service?.time_type === "Hour" ||
          service?.time_type === "Day"
          ? "fixed price"
          : "monthly"
      );
      sePaymentType(service?.service_pack_type);
      setServiceStatus(service?.service_statuses);
      setServiceAreaId(service?.service_area_id);
      setIsSupplementaryCharges(
        service?.service_supplementary_fees?.length ? true : false
      );
      setServiceFormId(service?.service_form_id);
      setStepsIncrement(
        service?.service_supplementary_fees?.length
          ? service?.service_supplementary_fees?.map((sup) => {
              return {
                ...sup,
                price: sup?.price.replace(".", ","),
                id:
                  Math.random().toString(36).substring(2, 15) +
                  Math.random().toString(36).substring(2, 15),
              };
            })
          : [
              {
                id:
                  Math.random().toString(36).substring(2, 15) +
                  Math.random().toString(36).substring(2, 15),
                name: "",
                price: "",
              },
            ]
      );
      setServiceInfo(service?.info || "");
      setServiceInfoEsp(service?.info_sp || "");
      setBeneficiaryFeatures(
        service?.beneficiary?.map((item) => {
          return { ...item, checked: true };
        })
      );

      let mappedProvidersId = findObj?.service_providers?.map(
        (providersId, index) => {
          return providersId?.service_provider_id;
        }
      );
      let mappedBranchesId = findObj?.service_branches?.map(
        (branchId, index) => {
          return {
            branch_id: branchId?.branch_id,
            priority: branchId?.priority,
          };
        }
      );

      GetServiceProvider(
        (res) => {
          let data = res?.data?.response?.detail?.reverse()?.map((provider) => {
            return {
              ...provider,
              checked: service?.service_providers?.some((b) =>
                b?.service_provider_id === provider?.service_provider_id
                  ? true
                  : false
              ),
            };
          });
          setServiceProvidersFeatures(data);
        },
        (err) => {
          if (err?.response?.status === 500) {
            Errors(err?.response?.data?.error?.message);
          } else if (err?.response?.status === 422) {
            Errors(err?.response?.data?.error?.message);
          } else {
            Errors("Network Error");
          }
        }
      );

      GetNonAssignServiceForms(
        (res) => {
          let mappedNonServiceForms = res?.data?.response?.detail?.map(
            (item) => {
              return {
                ...item,
                checked: service?.service_form_id === item?.service_form_id,
              };
            }
          );

          setNonAssignServiceForms(mappedNonServiceForms);
        },
        (error) => {
          if (error?.response?.status === 401) {
            Errors(error?.response?.data?.error?.message);
          } else {
            Errors("Network Error");
          }
        }
      );

      // let mappedServiceForm = nonAssignServiceForms?.map((item) => {
      //   if (item?.service_form_id === service?.service_form_id) {
      //     return {
      //       ...item,
      //       checked: true,
      //     };
      //   } else {
      //     return { ...item, checked: false };
      //   }
      // });
      // setNonAssignServiceForms(mappedServiceForm);
    } else {
      fetchNonAssignServiceForms();
      fetchServiceProviders();
      GetServiceArea(
        (res) => {
          setServiceAreaFeatures(
            res?.data?.response?.detail?.map((a) => {
              return {
                ...a,
                checked: false,
                priority: 0,
              };
            })
          );
        },
        (err) => {
          if (err?.response?.status === 500) {
            Errors(err?.response?.data?.error?.message);
          } else if (err?.response?.status === 422) {
            Errors(err?.response?.data?.error?.message);
          } else {
            Errors("Network Error");
          }
        }
      );
    }
  }, []);

  useEffect(() => {
    let price = String(
      Number(originalPrice.replace(".", "").replace(",", ".")) -
        Number(
          (originalPrice.replace(".", "").replace(",", ".") *
            discountedPricePercentage) /
            100
        )
    );

    setReducedPrice(price);
  }, [originalPrice, discountedPricePercentage]);
  const fetchServiceProviders = () => {
    GetServiceProvider(
      (res) => {
        let data = res?.data?.response?.detail?.reverse();
        setServiceProvidersFeatures(data);
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  //

  //select beneficiary
  const onChecked = (e, i) => {
    const array = beneficiaryFeatures?.map((a, b) => {
      if (b === i) {
        return {
          ...a,
          checked: !a.checked,
          priority: a.checked === true ? "" : a.priority,
        };
      } else {
        return {
          ...a,
          // checked: false,
        };
      }
    });
    setBeneficiaryFeatures(array);
  };

  const onChecked6 = (e, i) => {
    const array = beneficiaryFeatures?.map((a, b) => {
      if (b === i) {
        return {
          ...a,
          checked: !a.checked,
          priority: a.checked === true ? "" : a.priority,
        };
      } else {
        return {
          ...a,
          // checked: false,
        };
      }
    });
    setBeneficiaryFeatures(array);
  };

  //get service area by beneficiary-id
  const fetchIdOfBeneficiary = (id) => {
    GetServiceAreaByBeneficiaryId(
      id,
      (success) => {
        let mappedArray = success?.data?.response?.detail?.map(
          (detail, index) => {
            return {
              ...detail,
              checked: false,
              priority: "",
            };
          }
        );
        setServiceAreaFeatures(mappedArray);
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.error?.message);
          setFieldIsrequired(false);
          Remove_Data_Form_Reducers(props);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
          setFieldIsrequired(false);
        }
      }
    );
  };

  //select service area
  const onChecked1 = (e, i) => {
    if (serviceAreaId === e?.service_area_id) {
      setServiceAreaId("");
    } else {
      setServiceAreaId(e?.service_area_id);
    }
    setSelectedServiceAreaName([e]);
    setServiceAreaFeatures((prev) => {
      return prev?.map((serviceAreaItem) => {
        if (e?.service_area_id === serviceAreaItem?.service_area_id) {
          return {
            ...serviceAreaItem,
            checked: !serviceAreaItem?.checked,
          };
        } else {
          return { ...serviceAreaItem, checked: false };
        }
      });
    });
  };

  const fetchBranchesByServiceAreaId = (id) => {
    //
    GetBranchesByServiceAreaId(
      id,
      (success) => {
        let mappedArray = success?.data?.response?.detail?.map(
          (detail, index) => {
            return {
              ...detail,
              checked: false,
              priority: "",
            };
          }
        );
        if (props?.match?.params?.id) {
          let service = props?.location?.state?.service;

          let mappedBranchesById = mappedArray?.map((beneF, index) => {
            if (beneF?.branch_id === service?.branch?.branch_id) {
              return {
                ...beneF,
                checked: true,
              };
            } else {
              return {
                ...beneF,
                checked: false,
              };
            }
          });
          //
          setBranchFeatures(mappedBranchesById);
        } else {
          setBranchFeatures(mappedArray);
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.error?.message);
          setFieldIsrequired(false);
          Remove_Data_Form_Reducers(props);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
          setFieldIsrequired(false);
        }
      }
    );
  };
  //select branches
  const onChecked2 = (e, i) => {
    const array = branchFeatures?.map((a, b) => {
      if (b === i) {
        setBeneficiaryFeatures(
          e?.branch_beneficiaries?.map((beneficiary) => {
            return { ...beneficiary, checked: false, priority: "" };
          })
        );
        return {
          ...a,
          checked: !a.checked,
        };
      } else {
        return {
          ...a,
          checked: false,
        };
      }
    });

    setBranchFeatures(array);
  };

  //set priorit of branches
  const changePriority = (e, id) => {
    let mappedPriority = branchFeatures?.map((a, b) => {
      if (a.branch_id === id) {
        return {
          ...a,
          priority: e.target.value,
        };
      } else {
        return {
          ...a,
          priority: a.priority,
        };
      }
    });
    setBranchFeatures(mappedPriority);
  };

  //set priorit of Beneficiary
  const changeBeneficiaryPriority = (e, id) => {
    //
    let mappedPriority = beneficiaryFeatures?.map((a, b) => {
      //
      if (a.id === id) {
        Number(e.target.value) > 0
          ? setBeneficiaryModalButton(false)
          : setBeneficiaryModalButton(true);
        return {
          ...a,
          priority: e.target.value && Number(e.target.value),
        };
      } else {
        return {
          ...a,
          priority: a.priority,
        };
      }
    });
    setBeneficiaryFeatures(mappedPriority);
  };

  //select branch
  const selectBranch = () => {
    let filteredFeatures = branchFeatures?.filter((branchFeature) => {
      if (branchFeature.checked === true) {
        return {
          branchFeature,
        };
      }
    });
    let agaimMapped = filteredFeatures?.map((v) => {
      return {
        branch: v,
      };
    });
    setSelectedBranchesName(agaimMapped);
    let mappedFeatures = filteredFeatures?.map((branchFeature) => {
      if (branchFeature?.checked === true) {
        return {
          branch_id: branchFeature?.branch_id,
          beneficiaries: beneficiaryFeatures,
        };
      }
    });
    setSelectedBranch(mappedFeatures);
  };

  //select providers
  const onChecked3 = (e, i) => {
    const array = serviceProviderFeatures?.map((a, b) => {
      if (b === i) {
        return {
          ...a,
          checked: !a.checked,
        };
      } else {
        return {
          ...a,
          checked: a.checked,
        };
      }
    });
    setServiceProvidersFeatures(array);
  };
  const onCheckedSelectServiceForm = (a) => {
    let mappedServiceForm = nonAssignServiceForms?.map((item) => {
      if (item?.service_form_id === a?.service_form_id) {
        return {
          ...item,
          checked: !item?.checked,
        };
      } else {
        return { ...item, checked: false };
      }
    });
    setNonAssignServiceForms(mappedServiceForm);
  };
  // select multiple providers
  const selectProviders = () => {
    let filteredFeatures = serviceProviderFeatures?.filter(
      (serviceProviders) => {
        if (serviceProviders.checked === true) {
          return serviceProviders;
        }
      }
    );
    let agaimMapped = filteredFeatures?.map((v) => {
      return {
        service_provider: v,
      };
    });
    setSelectedServiceProvidersName(agaimMapped);
    let mappedFeatures = filteredFeatures?.map((serviceProviders) => {
      if (serviceProviders?.checked === true) {
        return serviceProviders?.service_provider_id;
      }
    });
    setServiceProviderIds(mappedFeatures || []);
  };

  const openStatusModal = () => {
    setOpenModal(true);
  };

  // save service status
  const setSaveChip = () => {
    setServiceStatus((prevState) => {
      return [
        ...prevState,
        {
          status: statusTitle,
          description: statusDescription,
        },
      ];
    });
    setStatusTitle("");
    setStatusDescription("");
  };

  // delete service status
  const removeChips = (data, index) => {
    setServiceStatus((prevState) => {
      return prevState?.filter((status) => status.index !== index);
    });
  };
  // select timeType
  const handleMenuClick = (e) => {
    setSelectedTimeType(e.key);
    if (e.key === "3") {
      setVisible(false);
    }
  };

  const menu1 = (
    <Menu onClick={handleMenuClick}>
      {serviceTimeType?.map((type, index) => {
        return (
          <Menu.Item className="capitalize" key={type}>
            {adminLabels?.[type]}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  // get not assign service forms
  const fetchNonAssignServiceForms = () => {
    GetNonAssignServiceForms(
      (res) => {
        let mappedNonServiceForms = res?.data?.response?.detail?.map((item) => {
          return { ...item, checked: false };
        });

        setNonAssignServiceForms(mappedNonServiceForms);
      },
      (error) => {
        if (error?.response?.status === 401) {
          Errors(error?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };

  // create branch service

  const createBranchService = () => {
    let beneficiaryData = beneficiaryFeatures?.filter((beneficiary) => {
      return beneficiary.checked === false;
    });
    let beneficiariePriority = beneficiaryFeatures?.filter(
      (beneficiariePriority) => {
        return (
          beneficiariePriority.checked === true &&
          beneficiariePriority.priority === ""
        );
      }
    );

    // beneficiaryData.length === beneficiaryFeatures.length
    //   ? message.error("please select Beneficiaries")
    //   : beneficiariePriority.length > 0
    //   ? message.error("please Add  Priority to Beneficiaries")
    //   : setOpenModal1(false);
    //     selectBranch();

    if (serviceAreaId?.trim()?.length === 0) {
      message.error("please select Service Area");
      setModalId(2);
      setOpenModal1(true);
      return false;
    }
    if (selectedBranchesName?.length === 0) {
      message.error("please select Branch");
      setModalId(3);
      setOpenModal1(true);
      return false;
    }

    if (beneficiaryData.length === beneficiaryFeatures.length) {
      message.error("please select Beneficiaries");
      setModalId(3);
      setOpenModal1(true);
      return false;
    }
    if (beneficiariePriority.length > 0) {
      message.error("please Add  Priority to Beneficiaries");
      setModalId(3);
      setOpenModal1(true);
      return false;
    }
    if (
      !nonAssignServiceForms?.filter((item) => item.checked === true)?.length
    ) {
      setOpenModal2(true);
      message.error("please select service form");
      return false;
    }
    let obj = {
      name: serviceName,
      name_sp: serviceNameEsp,
      info: serviceInfo,
      info_sp: serviceInfoEsp,
      avatar: serviceImage,
      original_price: originalPrice?.replace(",", "."),
      reduced_price: reducedPrice?.trim()?.length === 0 ? 0 : reducedPrice.replace(",", "."),
      is_special_price: isSpecialPrice === true ? 1 : 0,
      is_service_pack: isServicePack === true ? 1 : 0,
      priority: Number(
        beneficiaryFeatures
          ?.filter(
            (beneficiaryFeature) =>
              beneficiaryFeature?.priority > 0 &&
              beneficiaryFeature?.checked === true
          )
          ?.map((value) => value.priority)
          ?.toString()
      ),
      is_region_related: isServiceRegionRelated === true ? 1 : 0,
      service_pack_type: paymentType,
      time_type: paymentType === "monthly" ? null : selectedTimeType,
      time: paymentType === "monthly" ? 0 : serviceTime,
      statuses: JSON.stringify(serviceStatus),
      service_provider_ids: JSON.stringify(serviceProviderIds),
      beneficiary_id: beneficiaryId,
      service_area_id: serviceAreaId,
      branches: JSON.stringify(
        branchFeatures
          ?.filter((branchFeature) => {
            if (branchFeature.checked === true) {
              return {
                branchFeature,
              };
            }
          })
          ?.map((branchFeature) => {
            if (branchFeature.checked === true) {
              return {
                branch_id: branchFeature.branch_id,
                beneficiaries: beneficiaryFeatures?.filter(
                  (item) => Number(item?.priority) > 0 && item.checked === true
                ),
              };
            }
          })
      ),
      supplementary_fees:
        isSupplementaryCharges === true
          ? JSON.stringify(
              stepsIncrement?.map((item) => {
                return {
                  ...item,
                  price: item?.price.replace(",", "."),
                };
              })
            )
          : JSON.stringify([]),
      service_form_id: serviceFormId,
    };

    if (
      serviceName?.trim()?.length !== 0 &&
      serviceNameEsp?.trim()?.length !== 0 &&
      validateDescription(serviceInfo) &&
      validateDescription(serviceInfoEsp) &&
      serviceImage !== null &&
      originalPrice?.trim()?.length !== 0 &&
      serviceProviderIds?.length !== 0 &&
      serviceAreaId?.trim()?.length !== 0 &&
      selectedBranch.length !== 0 &&
      nonAssignServiceForms?.filter((item) => item?.checked === true)
        ?.length !== 0
    ) {
      setFieldIsrequired(false);
      props.setLoaderState(true);
      let data = objectToFormData(obj);
      Add_Branch_Service(
        data,
        (success) => {
          Success(success.data.response.message);
          props.setLoaderState(false);
          setFieldIsrequired(false);
          setServiceName("");
          setBeneficiaryId("");
          setOriginalPrice("");
          setReducedPrice("");
          sePaymentType("");
          setSelectedBranch([]);
          setSelectedTimeType(null);
          setIsServicePack(false);
          setIsServiceRegionRelated(false);
          setIsSpecialPrice(false);
          setServiceProviderIds([]);
          setServiceTime(1);
          setServiceInfo("");
          setServiceStatus([]);
          setSelectedBeneficiariesName([]);
          setSelectedServiceAreaName([]);
          setSelectedBranchesName([]);
          setSelectedServiceProvidersName([]);
          sePaymentType("fixed price");
          props.getServicesList(props);

          push("/admin/services");
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            setFieldIsrequired(false);
            Remove_Data_Form_Reducers(props);
            push("/admin");
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
            setFieldIsrequired(false);
          }
        }
      );
    } else {
      //
      //   serviceInfo.length !== 0,
      //   serviceImage !== null,
      //   originalPrice !== "",
      //   reducedPrice !== "",
      //   selectedTimeType !== null,
      //   serviceTime !== 0,
      //   serviceProviderIds.length !== 0,
      //   serviceAreaId !== "",
      //   beneficiaryId !== "",
      //   selectedBranch.length !== 0,
      //  (infoServicePack.length !== 0 && isServicePack === true))
      setFieldIsrequired(true);
    }
  };

  const edit_Branch_service = () => {
    let beneficiaryData = beneficiaryFeatures?.filter((beneficiary) => {
      return beneficiary.checked === false;
    });
    let beneficiariePriority = beneficiaryFeatures?.filter(
      (beneficiariePriority) => {
        return (
          beneficiariePriority.checked === true &&
          beneficiariePriority.priority === ""
        );
      }
    );

    // beneficiaryData.length === beneficiaryFeatures.length
    //   ? message.error("please select Beneficiaries")
    //   : beneficiariePriority.length > 0
    //   ? message.error("please Add  Priority to Beneficiaries")
    //   : setOpenModal1(false);
    //     selectBranch();

    if (serviceAreaId?.trim()?.length === 0) {
      message.error("please select Service Area");
      setModalId(2);
      setOpenModal1(true);
      return false;
    }
    if (selectedBranchesName?.length === 0) {
      message.error("please select Branch");
      setModalId(3);
      setOpenModal1(true);
      return false;
    }

    if (beneficiaryData.length === beneficiaryFeatures.length) {
      message.error("please select Beneficiaries");
      setModalId(3);
      setOpenModal1(true);
      return false;
    }
    if (beneficiariePriority.length > 0) {
      message.error("please Add  Priority to Beneficiaries");
      setModalId(3);
      setOpenModal1(true);
      return false;
    }
    if (
      !nonAssignServiceForms?.filter((item) => item.checked === true)?.length
    ) {
      setOpenModal2(true);
      message.error("please select service form");
      return false;
    }
    let obj = {
      name: serviceName,
      name_sp: serviceNameEsp,
      info: serviceInfo,
      info_sp: serviceInfoEsp,
      avatar: serviceImage,
      original_price: originalPrice?.replace(",", "."),
      reduced_price: ["0",0,""," ",undefined,null]?.includes(discountedPricePercentage) ? originalPrice?.replace(",", ".") : reducedPrice?.trim()?.length === 0 ? 0 : reducedPrice.replace(",", "."),
      is_special_price: isSpecialPrice === true ? 1 : 0,
      is_service_pack: isServicePack === true ? 1 : 0,
      priority: Number(
        beneficiaryFeatures
          ?.filter(
            (beneficiaryFeature) =>
              beneficiaryFeature.priority > 0 &&
              beneficiaryFeature.checked === true
          )
          ?.map((value) => value.priority)
          ?.toString()
      ),
      is_region_related: isServiceRegionRelated === true ? 1 : 0,
      service_pack_type: paymentType,
      time_type: paymentType === "monthly" ? null : selectedTimeType,
      time: paymentType === "monthly" ? 0 : serviceTime,
      statuses: JSON.stringify(serviceStatus),
      service_provider_ids: JSON.stringify(serviceProviderIds),
      beneficiary_id: beneficiaryId,
      service_area_id: serviceAreaId,
      branches: JSON.stringify(
        branchFeatures
          ?.filter((branchFeature) => {
            if (branchFeature.checked === true) {
              return {
                branchFeature,
              };
            }
          })
          ?.map((branchFeature) => {
            if (branchFeature.checked === true) {
              return {
                branch_id: branchFeature.branch_id,
                beneficiaries: beneficiaryFeatures?.filter(
                  (item) => Number(item?.priority) > 0 && item.checked === true
                ),
              };
            }
          })
      ),
      supplementary_fees:
        isSupplementaryCharges === true
          ? JSON.stringify(
              stepsIncrement?.map((item) => {
                return {
                  ...item,
                  price: item?.price.replace(",", "."),
                };
              })
            )
          : JSON.stringify([]),

      service_id: props.match.params.id,
      service_form_id: serviceFormId,
    };
    if (
      (serviceName?.trim()?.length !== 0 &&
        serviceNameEsp?.trim()?.length !== 0 &&
        validateDescription(serviceInfo) &&
        validateDescription(serviceInfoEsp) &&
        serviceImage !== null &&
        originalPrice?.trim()?.length !== 0 &&
        selectedTimeType !== null &&
        serviceTime !== 0,
      serviceProviderIds?.length !== 0 &&
        serviceAreaId?.trim()?.length !== 0 &&
        nonAssignServiceForms?.filter((item) => item?.checked === true)
          ?.length !== 0 &&
        selectedBranch?.length !== 0)
    ) {
      
      setFieldIsrequired(false);
      props.setLoaderState(true);
      let data = objectToFormData(obj);
      EditService(
        data,
        (success) => {
          Success(success.data.response.message);
          props.setLoaderState(false);
          setFieldIsrequired(false);
          props.getServicesList(props);
          push("/admin/services");
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            setFieldIsrequired(false);
            Remove_Data_Form_Reducers(props);
            push("/admin");
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
            setFieldIsrequired(false);
          }
        }
      );
    } else {
      setFieldIsrequired(true);
    }
  };

  return (
    <section className="pb-12">
      <div className="flex self-center items-center ml-12 pt-10">
        <div
          onClick={() => goBack()}
          className="flex text-gray-400 items-center cursor-pointer"
        >
          <RiArrowDropLeftLine />
          <span>{adminLabels.back}</span>
        </div>
        <h3 className="font-bold text-lg mb-0 ml-10">
          {props.match.params.id
            ? adminLabels.editService
            : adminLabels.addNewService}
        </h3>
      </div>
      <div className="container mx-auto px-2 sm:px-2 lg:px-20 gap-12 grid grid-cols-12">
        <div className="mt-4 col-span-12 sm:col-span-12 lg:col-span-6">
          <div className="grid grid-cols-6 ">
            <div className=" col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 mt-2 lg:ml-2">
              <button
                onClick={() => {
                  setOpenModal1(true);
                  setModalHeadingLabel(adminLabels.selectServiceArea);
                  setModalId(2);
                }}
                disabled={props.loader === true ? true : false}
                className="mb-2 bg-white rounded-full py-3 px-4 text-sm flex justify-between items-center w-full"
              >
                {selectedServiceAreaName?.length !== 0 ? (
                  selectedServiceAreaName?.map(
                    (selectedServiceArea, indexXy) => {
                      return (
                        <span
                          className=" pb-1 text-gray-400 font-medium"
                          key={indexXy}
                        >
                          {
                            selectedServiceArea?.[
                              currentSelectedLangue === "english"
                                ? "name"
                                : "info"
                            ]
                          }
                        </span>
                      );
                    }
                  )
                ) : (
                  <span className="px-30  pb-1 text-gray-400 font-medium">
                    {adminLabels.selectServiceArea}
                  </span>
                )}

                <IoIosArrowForward className="text-gray-400 font-medium" />
              </button>
              {serviceAreaId?.trim()?.length === 0 &&
              fieldIsRequired === true ? (
                <span className="text-red-500">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="px-30 col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 mt-2  lg:ml-2">
              <button
                onClick={() => {
                  setOpenModal1(true);
                  setModalHeadingLabel(adminLabels.selectBranch);
                  setModalId(3);
                }}
                disabled={props?.loader === true ? true : false}
                className="mb-2 bg-white rounded-full py-3 px-4 text-sm flex justify-between items-center w-full"
              >
                {selectedBranchesName?.length !== 0 ? (
                  selectedBranchesName?.map((selectedBranch, index) => {
                    return (
                      <span
                        className=" pb-1 text-gray-400 font-medium"
                        key={index}
                      >
                        {` ${selectedBranch?.branch?.name},`}
                      </span>
                    );
                  })
                ) : (
                  <span className=" pb-1 text-gray-400 font-medium">
                    {adminLabels.selectBranch}
                  </span>
                )}

                <IoIosArrowForward className="text-gray-400 font-medium" />
              </button>
              {selectedBranch?.length === 0 && fieldIsRequired === true ? (
                <span className="text-red-500">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="grid grid-cols-6">
            <div className="mb-2 col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 	lg:ml-2">
              <InputComponent
                placeholder={adminLabels.addServiceNameEng}
                value={serviceName}
                onChange={(e) => setServiceName(e.target.value)}
                disabled={props.loader === true ? true : false}
                classNames="text-gray-400 font-medium"
              />
              {serviceName?.trim()?.length === 0 && fieldIsRequired === true ? (
                <span className="text-red-500 mt-2">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="mb-2 col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 	lg:ml-2">
              <InputComponent
                placeholder={adminLabels.addServiceNameEsp}
                value={serviceNameEsp}
                onChange={(e) => setServiceNameEsp(e.target.value)}
                disabled={props.loader === true ? true : false}
                classNames="text-gray-400 font-medium"
              />
              {serviceNameEsp?.trim()?.length === 0 &&
              fieldIsRequired === true ? (
                <span className="text-red-500 mt-2">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="grid grid-cols-6">
            <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 mt-2 lg:ml-2 relative cursor-pointer">
              <input
                accept={".png, .jpg, .jpeg"}
                className="rounded-full py-3 px-9 w-full absolute opacity-0 input-style cursor-pointer"
                type={"file"}
                onChange={(e) => setServiceImage(e.target.files[0])}
              />
              <button
                style={{ cursor: "pointer" }}
                disabled={props.loader === true ? true : false}
                className="bg-gray-400 pb-4  h-12 rounded-full py-3 px-4 text-sm flex justify-center item-center  w-full mb-4"
              >
                <img
                  style={{ cursor: "pointer" }}
                  width="20"
                  height="20"
                  src={uploadImg.default}
                />
                <span style={{ cursor: "pointer" }} className="pl-1 text-white">
                  {serviceImage !== null ? serviceImage?.name : serviceImage}
                </span>
              </button>
              {serviceImage === null && fieldIsRequired === true ? (
                <span className="text-red-500">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="grid grid-cols-6"></div>
          <div className="grid grid-cols-1">
            <div className=" col-span-12 lg:col-span-6 sm:col-span-12 md:col-span-12 lg:pl-2">
              <ReactQuill
                className={"richTextBox"}
                value={serviceInfo || ""}
                onChange={setServiceInfo}
                disabled={props.loader === true ? true : false}
                placeholder={adminLabels.informationEng}
              />
              {!validateDescription(serviceInfo) && fieldIsRequired === true ? (
                <span className="text-red-500 mt-2">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : null}
            </div>
            <div className=" col-span-12 lg:col-span-6 sm:col-span-12 md:col-span-12 lg:pl-2">
              <ReactQuill
                className={"richTextBox"}
                value={serviceInfoEsp || ""}
                onChange={setServiceInfoEsp}
                disabled={props.loader === true ? true : false}
                placeholder={adminLabels.informationEsp}
              />
              {!validateDescription(serviceInfoEsp) &&
              fieldIsRequired === true ? (
                <span className="text-red-500 mt-2">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : null}
            </div>
            <div className="grid grid-cols-6 lg:pl-2">
              <div className="mb-2 col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 	mr-2">
                <div>
                  <strong className="text-gray-400">
                    {adminLabels.origionalPrice}
                  </strong>
                  <InputComponent
                    type="text"
                    placeholder={`${adminLabels.origionalPrice} (€)`}
                    value={originalPrice}
                    onChange={(e) =>
                      setOriginalPrice(
                        regexForPricing(e.target.value, e.nativeEvent)
                      )
                    }
                    disabled={props.loader === true ? true : false}
                    classNames="text-gray-400 font-medium"
                  />
                  {originalPrice?.trim()?.length === 0 &&
                  fieldIsRequired === true ? (
                    <span className="text-red-500">
                      {adminLabels.pleaseFillTheField}
                    </span>
                  ) : null}
                </div>
                <div>
                  <strong className="text-gray-400">
                    {adminLabels.discountPercentage}
                  </strong>
                  <InputComponent
                    type="number"
                    value={discountedPricePercentage}
                    placeholder={adminLabels.discountPercentage + " %"}
                    maximum="100"
                    onChange={(e) => {
                      const value = Math.abs(Number(e.target.value));
                      if (value <= 100 && value >= 0) {
                        setDiscountedPricePercentage(String(value));
                      }
                    }}
                    // value={originalPrice}
                    // onChange={(e) =>
                    //   Number(e.target.value) < 0
                    //     ? ""
                    //     : setOriginalPrice(limitDecimals(e.target.value))
                    // }
                    disabled={props.loader === true ? true : false}
                    classNames="text-gray-400 font-medium"
                  />
                </div>
              </div>
              <div className="mb-2 col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 	">
                <div>
                  <strong className="text-gray-400">
                    {adminLabels.discountPrice}
                  </strong>
                  <InputComponent
                    type="text"
                    value={(reducedPrice?.replace(".", ","))}
                    disabled={true}
                    classNames="text-gray-400 font-medium"
                  />
                </div>
              </div>
              <div className="col-span-12 lg:col-span-6 sm:col-span-12 lg:ml-5 mb-3 md:col-span-12 ">
                <div className="flex mt-4">
                  {isSpecialPrice ? (
                    <img
                      src={checkboxActive.default}
                      onClick={() => setIsSpecialPrice(!isSpecialPrice)}
                    />
                  ) : (
                    <img
                      src={checkbox.default}
                      onClick={() => setIsSpecialPrice(!isSpecialPrice)}
                    />
                  )}
                  <span className="mr-16 ml-2 text-gray-400 font-bold">
                    {adminLabels.specialPrice}
                  </span>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1"></div>
          </div>
          <div className="w-full sm:w-6/12 mt-3 ml-2 ">
            <ButtonComponent
              className=" bg-primary"
              title={adminLabels.save}
              onClick={
                props.match.params.id
                  ? edit_Branch_service
                  : createBranchService
              }
              disabled={props.loader === true ? true : false}
              loader={props.loader === true ? true : false}
            />
          </div>
        </div>
        <div className=" grid grid-rows-9  col-span-12 sm:col-span-12 lg:col-span-6 space-y-7">
          <div className="h-10 sm:h-10 lg:h-0 ">
            <span className=" lg:ml-2 h-auto sm:h-auto lg:h-12  mt-5 sm:mt-5 lg:mt-0 text-gray-400 ">
              <strong>{adminLabels.serviceProvider}</strong>
            </span>
          </div>
          <div className="grid grid-cols-2 ">
            <div>
              <button
                disabled={props?.loader === true ? true : false}
                onClick={() => {
                  setOpenModal1(true);
                  setModalHeadingLabel(adminLabels.selectServiceProvider);
                  setModalId(5);
                }}
                className="mb-2 bg-white rounded-full py-3 px-4 text-sm flex flex-wrap  items-center w-full justify-between"
              >
                {" "}
                <div className="h-5 overflow-hidden w-4/5 flex justify-items-start">
                  {selectedServiceProvidersName?.length !== 0 ? (
                    selectedServiceProvidersName?.map((selectedSP, index) => {
                      return (
                        <span className=" pb-1 ml-1 text-xs text-gray-400 font-medium">
                          {` ${selectedSP?.service_provider?.name},`}
                        </span>
                      );
                    })
                  ) : (
                    <span className=" pb-1 text-gray-400 font-medium">
                      {adminLabels.selectServiceProvider}
                    </span>
                  )}
                </div>
                <IoIosArrowForward className="text-gray-400 font-medium" />
              </button>
              {serviceProviderIds?.length === 0 && fieldIsRequired === true ? (
                <span className="text-red-500">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="flex justify-start">
            <div className=" lg:ml-2 h-auto sm:h-auto lg:h-12  mt-5 sm:mt-5 lg:mt-0 ">
              <h4 className="text-gray-400">
                <strong>{adminLabels.isServiceIsRegionRelated}</strong>
              </h4>
              <div className="flex mt-4">
                {isServiceRegionRelated ? (
                  <img
                    src={checkboxActive.default}
                    disabled={props.loader === true ? true : false}
                    onClick={() => setIsServiceRegionRelated(false)}
                  />
                ) : (
                  <img
                    src={checkbox.default}
                    disabled={props.loader === true ? true : false}
                    onClick={() => setIsServiceRegionRelated(true)}
                  />
                )}
                <span className="mr-16 text-gray-400 ml-2  font-bold">
                  {adminLabels.yes}
                </span>
                {!isServiceRegionRelated ? (
                  <img
                    src={checkboxActive.default}
                    disabled={props.loader === true ? true : false}
                    onClick={() => setIsServiceRegionRelated(true)}
                  />
                ) : (
                  <img
                    src={checkbox.default}
                    disabled={props.loader === true ? true : false}
                    onClick={() => setIsServiceRegionRelated(false)}
                  />
                )}
                <span className="mr-2 text-gray-400  font-bold ml-2">
                  {" "}
                  {adminLabels.no}
                </span>
              </div>
            </div>
            {/* <div className=" py-5 w-56">
                <button
                  disabled={props.loader === true ? true : false}
                  onClick={() => {
                    setOpenModal2(true);
                    setModalHeadingLabel("Select Service Form");
                  }}
                  className="mb-2 bg-white rounded-full py-3 px-4 text-sm flex flex-wrap  items-center w-full justify-between"
                >
                  {" "}
                  <div className="h-5 overflow-hidden w-5/5 flex justify-items-start ">
                    <span className=" pb-1 text-gray-400 font-medium">
                      Select Service Form
                    </span>
                  </div>
                  <IoIosArrowForward className="text-gray-400 font-medium" />
                </button>
                {serviceProviderIds.length === 0 && fieldIsRequired === true ? (
                  <span className="text-red-500">Please select provider!</span>
                ) : (
                  ""
                )}
              </div> */}
          </div>

          {/* <div className="flex justify-start">
            <div className=" py-5 w-56">
              <h4 className="text-gray-400 mx-2">
                <b>{adminLabels.serviceForm}</b>
              </h4>
              <button
                disabled={props.loader === true ? true : false}
                onClick={() => {
                  setOpenModal2(true);
                }}
                className="mb-2 bg-white rounded-full py-3 px-4 text-sm flex flex-wrap  items-center w-full justify-between"
              >
                {" "}
                <div className="h-5 overflow-hidden w-5/5 flex justify-items-start ">
                  <span className=" pb-1 text-gray-400 font-medium">
                    {nonAssignServiceForms?.filter(
                      (item) => item?.checked === true
                    )?.length === 0
                      ? adminLabels.selectServiceForm
                      : nonAssignServiceForms?.filter(
                          (item) => item?.checked === true
                        )[0]?.title}
                  </span>
                </div>
                <IoIosArrowForward className="text-gray-400 font-medium" />
              </button>
            </div>
          </div> */}

          <div className="h-10 sm:h-10 lg:h-0 ">
            <span className=" lg:ml-2 h-auto sm:h-auto lg:h-12  mt-5 sm:mt-5 lg:mt-0 text-gray-400 ">
              <strong>{adminLabels.serviceForm}</strong>
            </span>
          </div>
          <div className="grid grid-cols-2 ">
            <div>
              <button
                disabled={props.loader === true ? true : false}
                onClick={() => {
                  setOpenModal2(true);
                }}
                // className="mb-2 bg-white rounded-full py-3 px-4 text-sm flex flex-wrap  items-center w-full justify-between"
                className="mb-2 bg-white rounded-full py-3 px-4 text-sm flex flex-wrap  items-center w-full justify-between"
              >
                {" "}
                <div className="h-5 overflow-hidden w-4/5 flex justify-items-start">
                  {nonAssignServiceForms?.filter(
                    (item) => item?.checked === true
                  )?.length === 0 ? (
                    <span className=" pb-1 text-gray-400 font-medium">
                      {adminLabels.selectServiceForm}
                    </span>
                  ) : (
                    <span className="pb-1 ml-1 text-xs text-gray-400 font-medium">
                      {nonAssignServiceForms?.filter(
                        (item) => item?.checked === true
                      )?.length === 0
                        ? adminLabels.selectServiceForm
                        : nonAssignServiceForms?.filter(
                            (item) => item?.checked === true
                          )[0]?.title}
                    </span>
                  )}
                </div>
                <IoIosArrowForward className="text-gray-400 font-medium" />
              </button>
              {nonAssignServiceForms?.filter((item) => item?.checked === true)
                ?.length === 0 && fieldIsRequired === true ? (
                <span className="text-red-500">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : null}
            </div>
          </div>

          <div className=" lg:ml-2 h-auto sm:h-auto lg:h-12  mt-5 sm:mt-5 lg:mt-0 ">
            <h4 className="text-gray-400">
              <strong>{adminLabels.isItServicePack}</strong>
            </h4>
            <div className="flex mt-4">
              {isServicePack ? (
                <img
                  src={checkboxActive.default}
                  disabled={props.loader === true ? true : false}
                  onClick={() => {
                    setIsServicePack(false);
                    setServicePackType("");
                  }}
                />
              ) : (
                <img
                  src={checkbox.default}
                  disabled={props.loader === true ? true : false}
                  onClick={() => setIsServicePack(true)}
                />
              )}
              <span className="mr-16 text-gray-400 ml-2  font-bold">
                {" "}
                {adminLabels.yes}
              </span>
              {!isServicePack ? (
                <img
                  src={checkboxActive.default}
                  disabled={props.loader === true ? true : false}
                  onClick={() => setIsServicePack(true)}
                />
              ) : (
                <img
                  src={checkbox.default}
                  disabled={props.loader === true ? true : false}
                  onClick={() => {
                    setIsServicePack(false);
                    setServicePackType("");
                    //setInfoServicePack("")
                  }}
                />
              )}
              <span className="mr-2 text-gray-400  font-bold ml-2">
                {" "}
                {adminLabels.no}
              </span>
            </div>
          </div>
          <div className=" lg:ml-2 h-auto sm:h-auto lg:h-12  mt-8 sm:mt-5 lg:mt-0 ">
            <h4 className="text-gray-400">
              <strong>{adminLabels.paymentType}</strong>
            </h4>
            <div
              className={`flex mt-4 ${
                props.match.params.id ? "pointer-event-none" : ""
              }`}
            >
              {paymentType === "monthly" ? (
                <img
                  src={checkboxActive.default}
                  disabled={props.loader === true ? true : false}
                />
              ) : (
                <img
                  src={checkbox.default}
                  onClick={() =>
                    props.match.params.id ? null : sePaymentType("monthly")
                  }
                  disabled={props.loader === true ? true : false}
                />
              )}
              <span className="mr-7 text-gray-400 ml-2  font-bold">
                {adminLabels.monthly}
              </span>

              {paymentType === "fixed price" ? (
                <img
                  src={checkboxActive.default}
                  disabled={props.loader === true ? true : false}
                />
              ) : (
                <img
                  src={checkbox.default}
                  disabled={props.loader === true ? true : false}
                  onClick={() =>
                    props.match.params.id ? null : sePaymentType("fixed price")
                  }
                />
              )}
              <span className="mr-2 text-gray-400  font-bold ml-2">
                {adminLabels.fixedPrice}
              </span>
            </div>
          </div>

          <div className="  ml-2 h-auto sm:h-auto lg:h-16 mt-5 sm:mt-5 lg:mt-0 lg:mb-4">
            <h4 className="text-gray-400">
              <strong>{adminLabels.estimatedTimeOfDelivery}</strong>
            </h4>
            <div className="flex justify-around items-center w-4/6 ">
              <Dropdown
                className={` select-input py-2 px-3 mr-4 justify-center rounded-full w-64  ${
                  paymentType === "monthly" ? "bg-disabled " : "bg-primary"
                }`}
                //  className = {`${paymentType === 'monthly' ? 'bg-disabled ':'bg-primary'}`}
                overlay={menu1}
                trigger={["click"]}
                disabled={
                  props.loader === true
                    ? true
                    : paymentType === "monthly"
                    ? true
                    : false
                }
                // disabled = {true}
              >
                <a
                  className="flex justify-around items-center ant-dropdown-link h-10 capitalize"
                  style={{ color: "#fff" }}
                  onClick={(e) => e.preventDefault()}
                >
                  {adminLabels?.[selectedTimeType] || adminLabels.timeType}{" "}
                  <DownOutlined className="mt-2 ml-10" />
                </a>
              </Dropdown>
              <div className=" select-input py-2 px-3 mr-4 justify-center rounded-full w-64 ">
                <InputComponent
                  type="number"
                  onKeyDown={functionToPreventSignsFromNumberFromPricing}
                  classNames="text-gray-400 font-medium"
                  placeholder={adminLabels.enterTime}
                  value={serviceTime}
                  onChange={(e) =>
                    Number(e.target.value) <= 0
                      ? ""
                      : setServiceTime(e.target.value)
                  }
                  disabled={
                    props.loader === true
                      ? true
                      : paymentType === "monthly"
                      ? true
                      : false
                  }
                />
              </div>
              {/* {serviceTimeType === "Time type" ||
                (serviceTime === 0 && fieldIsRequired === true) ? (
                  <span className="text-red-500">{adminLabels.pleaseFillTheField}</span>
                ) : (
                  ""
                )} */}
            </div>
            {/* {serviceTimeType === "Time type" ||
              (serviceTime === 0 && fieldIsRequired === true) ? (
                <span className="text-red-500">{adminLabels.pleaseFillTheField}</span>
              ) : (
                ""
              )} */}
          </div>
          <div className=" lg:ml-2 h-auto   mt-5 sm:mt-5 lg:mt-0">
            <h4 className="text-gray-400">
              <strong>{adminLabels.areThereAnySupplementaryCharges}</strong>
            </h4>
            <div className="flex mt-4">
              {isSupplementaryCharges ? (
                <img
                  src={checkboxActive.default}
                  disabled={props.loader === true ? true : false}
                  onClick={() => setIsSupplementaryCharges(false)}
                />
              ) : (
                <img
                  src={checkbox.default}
                  disabled={props.loader === true ? true : false}
                  onClick={() => setIsSupplementaryCharges(true)}
                />
              )}
              <span className="mr-16 text-gray-400 ml-2  font-bold">
                {adminLabels.yes}
              </span>
              {!isSupplementaryCharges ? (
                <img
                  src={checkboxActive.default}
                  disabled={props.loader === true ? true : false}
                  onClick={() => setIsSupplementaryCharges(true)}
                />
              ) : (
                <img
                  src={checkbox.default}
                  disabled={props.loader === true ? true : false}
                  onClick={() => setIsSupplementaryCharges(false)}
                />
              )}
              <span className="mr-2 text-gray-400  font-bold ml-2">
                {" "}
                {adminLabels.no}
              </span>
            </div>
            <div
              className={`w-5/5 ${
                !isSupplementaryCharges ? "hidden" : "block"
              }`}
            >
              <div className={`step-container overflow-clip`}>
                <Steps
                  progressDot
                  direction="vertical"
                  current={stepsIncrement?.length}
                  className="steps_Dot_Style flex add-class-here"
                  size="small"
                >
                  {stepsIncrement?.map((item, index) => (
                    <Steps.Step
                      key={item.id}
                      className="items-center	"
                      current={stepsIncrement?.length}
                      title={
                        <div className="flex space-x-6">
                          <div className="flex w-7/12 items-center">
                            <InputComponent
                              type="text"
                              width="100%"
                              onChange={(e) => handleText(e, item?.id)}
                              placeholder={"Add Title"}
                              value={item.name}
                              classNames="text-gray-400 font-medium"
                            />
                          </div>
                          <div className="flex w-3/12 items-center space-x-2">
                            <InputComponent
                              type="text"
                              width="100%"
                              onChange={(e) => handleAmount(e, item?.id)}
                              placeholder={"Add Amount"}
                              value={String(item.price)}
                              classNames="text-gray-400 font-medium"
                              onKeyDown={
                                functionToPreventSignsFromNumberFromPricing
                              }
                            />
                            <span>€</span>
                          </div>
                          <div className="flex w-2/12 items-center space-x-2">
                            {stepsIncrement.length > 1 ? (
                              <Button
                                className="bg-danger text-white ml-2 "
                                shape="circle"
                                onClick={() => prev(item.id, index)}
                              >
                                -
                              </Button>
                            ) : null}
                          </div>
                        </div>
                      }
                    />
                  ))}
                  <div className="stepper-add-button">
                    <Button
                      className=" shadow-md bg-primary text-white border-primary-message-sender  "
                      shape="circle"
                      onClick={next}
                    >
                      +
                    </Button>
                  </div>
                </Steps>
              </div>
            </div>
          </div>
        </div>
        {openModal1 ? (
          <Modal
            visible={openModal1}
            width={400}
            footer={false}
            onCancel={() => setOpenModal1(false)}
          >
            <div>
              <h4 className="font-bold text-2xm text-center text-gray-600	">
                {modalHeadingLabel}
              </h4>
            </div>
            <div className="divide-y divide-black-600 divide-solid ">
              {modalId === 1 && beneficiaryFeatures?.length
                ? beneficiaryFeatures?.map((t, i) => {
                    return (
                      <div
                        className="flex justify-between  text-left "
                        key={t.id}
                      >
                        <div className="flex items-center justify-center">
                          <img width={"20px"} src={t?.avatar} alt="logo alt" />
                          <span className="pl-4">{t?.name}</span>
                        </div>
                        <div className="flex items-center justify-center">
                          <div className="mr-4">
                            {modalId === 1 ? (
                              <div className="mr-2">
                                <InputComponent
                                  placeholder="0"
                                  width={70}
                                  value={t?.priority}
                                  disabled={t?.checked === false}
                                  onChange={(e) =>
                                    changeBeneficiaryPriority(e, t.id)
                                  }
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {t.checked ? (
                            <img
                              style={{ width: " 40%", height: "40%" }}
                              src={checkboxActive.default}
                              onClick={() => onChecked(t, i)}
                            />
                          ) : (
                            <img
                              style={{ width: " 40%", height: "40%" }}
                              src={checkbox.default}
                              onClick={() => onChecked(t, i)}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })
                : null}
              {modalId === 2 && serviceAreaFeatures.length
                ? serviceAreaFeatures?.map((t, i) => {
                    return (
                      <>
                        <div
                          className="flex justify-between  text-left py-5 "
                          key={i}
                        >
                          <div className="flex items-center justify-center">
                            <img width={"20px"} src={t.avatar} alt="logo alt" />
                            <span className="pl-4">
                              {
                                t?.[
                                  currentSelectedLangue === "english"
                                    ? "name"
                                    : "info"
                                ]
                              }
                            </span>
                          </div>
                          <div className="flex justify-between items-center">
                            {t.checked ? (
                              <img
                                style={{ width: "100%", height: "100%" }}
                                src={checkboxActive.default}
                                onClick={() => onChecked1(t, i)}
                              />
                            ) : (
                              <img
                                style={{ width: "100%", height: "100%" }}
                                src={checkbox.default}
                                onClick={() => onChecked1(t, i)}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })
                : null}
              {modalId === 3 && branchFeatures.length ? (
                <div className="select-service-modal-branches-container h-96 overflow-y-scroll px-2 py-2 mb-3">
                  {branchFeatures
                    ?.filter((item) =>
                      !props.match.params.id ? item?.status === 1 : item
                    )
                    ?.map((t, i) => {
                      return (
                        <>
                          <div
                            className="flex justify-between  text-left py-2 "
                            key={i}
                          >
                            <div className="flex items-center justify-center">
                              <img
                                width={"20px"}
                                src={t.avatar}
                                alt="logo alt"
                              />
                              <span className="pl-4">
                                {
                                  t?.[
                                    currentSelectedLangue === "english"
                                      ? "name"
                                      : "name_sp"
                                  ]
                                }
                              </span>
                            </div>
                            <div className="flex justify-between  text-left py-5">
                              {t.checked ? (
                                <img
                                  style={{ width: "100%", height: "100%" }}
                                  src={checkboxActive.default}
                                  onClick={() => onChecked2(t, i)}
                                />
                              ) : (
                                <img
                                  style={{ width: "100%", height: "100%" }}
                                  src={checkbox.default}
                                  onClick={() => onChecked2(t, i)}
                                />
                              )}
                            </div>
                          </div>

                          {beneficiaryFeatures && t.checked
                            ? beneficiaryFeatures?.map((tt, i) => {
                                return (
                                  <div
                                    className="flex justify-between  text-left px-10"
                                    key={i}
                                  >
                                    <div className="flex items-center justify-center">
                                      <img
                                        width={"18px"}
                                        src={tt.beneficiary.avatar}
                                        alt="logo alt"
                                      />
                                      <span className="pl-2 text-sm">
                                        {
                                          tt.beneficiary?.[
                                            currentSelectedLangue === "english"
                                              ? "name"
                                              : "info"
                                          ]
                                        }
                                      </span>
                                    </div>
                                    <div className="flex justify-between items-center">
                                      <div className="mr-4">
                                        <div
                                          className=" mb-2 mt-2 rounded-lg"
                                          style={{ width: 75 }}
                                        >
                                          <input
                                            onKeyDown={
                                              functionToPreventSignsFromNumberFromPricing
                                            }
                                            type="number"
                                            className={`rounded-full py-1 px-4 w-full shadow `}
                                            placeholder=""
                                            maxLength={3}
                                            disabled={tt.checked === false}
                                            value={tt.priority}
                                            onChange={(e) =>
                                              Number(e.target.value) <= 0
                                                ? ""
                                                : changeBeneficiaryPriority(
                                                    e,
                                                    tt.id
                                                  )
                                            }
                                          />
                                        </div>

                                        {/* <InputComponent
                                  placeholder="Priority"
                                  width={100}
                                  
                                /> */}
                                      </div>
                                      {tt.checked ? (
                                        <img
                                          style={{ width: " 15%" }}
                                          src={checkboxActive.default}
                                          onClick={() => onChecked6(tt, i)}
                                        />
                                      ) : (
                                        <img
                                          style={{ width: "15%" }}
                                          src={checkbox.default}
                                          onClick={() => onChecked6(tt, i)}
                                        />
                                      )}
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                        </>
                      );
                    })}
                </div>
              ) : null}
              {modalId === 5 && serviceProviderFeatures?.length ? (
                <div className="select-serviceProvider-modal-container h-96 overflow-y-scroll px-2 py-2">
                  {serviceProviderFeatures?.map((t, i) => {
                    return (
                      <div
                        className="flex justify-between  text-left py-5 "
                        key={i}
                      >
                        <div className="flex items-center justify-center">
                          <img
                            src={t.avatar}
                            alt="logo alt"
                            style={{ width: "20px", height: "20px" }}
                          />
                          <span className="pl-4 capitalize">{t.name}</span>
                        </div>
                        <div className="flex justify-between items-center">
                          {t.checked ? (
                            <img
                              style={{ width: "100%", height: "100%" }}
                              src={checkboxActive.default}
                              onClick={() => onChecked3(t, i)}
                            />
                          ) : (
                            <img
                              style={{ width: "100%", height: "100%" }}
                              src={checkbox.default}
                              onClick={() => onChecked3(t, i)}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
            {modalId === 2 ? (
              <>
                <button
                  className="w-full bg-primary rounded-full py-3 text-white"
                  onClick={() => {
                    if (serviceAreaId === "") {
                      message.error("please select Service Area");
                      return false;
                    }
                    setBranchFeatures([]);
                    setBeneficiaryFeatures([]);

                    serviceAreaId &&
                      fetchBranchesByServiceAreaId(serviceAreaId);
                    serviceAreaId && setOpenModal1(false);

                    setSelectedBranch([]);
                    setSelectedBranchesName([]);

                    //setOpenModal1(false)
                    // let data  = serviceAreaFeatures?.filter((serviceAreaFeature)=>{
                    //   return serviceAreaFeature.checked === false
                    // })

                    // data.length === serviceAreaFeatures.length ? message.error('please select Service Area') : ()=>setOpenModal1(false)
                    //  (setOpenModal1(false), fetchBranchesByServiceAreaId(serviceAreaId))

                    // beneficiaryData.length === beneficiaryFeatures.length ?  message.error('please select Beneficiaries') :
                    // beneficiariePriority.length > 0 ?  message.error('please Add  Priority to Beneficiaries') :null

                    // setOpenModal1(false);
                    //  modalId === 2 && fetchBranchesByServiceAreaId(serviceAreaId);
                  }}
                >
                  {adminLabels.apply}
                </button>
              </>
            ) : modalId === 3 ? (
              <>
                <button
                  className="w-full bg-primary rounded-full py-3 text-white"
                  onClick={() => {
                    let beneficiaryData = beneficiaryFeatures?.filter(
                      (beneficiary) => {
                        return beneficiary.checked === false;
                      }
                    );
                    let beneficiariePriority = beneficiaryFeatures?.filter(
                      (beneficiariePriority) => {
                        return (
                          beneficiariePriority.checked === true &&
                          beneficiariePriority.priority === ""
                        );
                      }
                    );
                    beneficiaryData.length === beneficiaryFeatures.length
                      ? message.error("please select Beneficiaries")
                      : beneficiariePriority.length > 0
                      ? message.error("please Add  Priority to Beneficiaries")
                      : setOpenModal1(false);
                    selectBranch();
                  }}
                >
                  {adminLabels.apply}
                </button>
              </>
            ) : (
              <>
                <button
                  disabled={modalId === 1 && beneficiaryModalButton}
                  className="w-full bg-primary rounded-full py-3 text-white"
                  onClick={() => {
                    setOpenModal1(false);
                    //modalId === 1 && fetchIdOfBeneficiary(beneficiaryId);
                    //modalId === 2 && fetchBranchesByServiceAreaId(serviceAreaId);
                    //modalId === 3 && selectBranch();
                    modalId === 5 && selectProviders();
                  }}
                >
                  {adminLabels.apply}
                </button>
              </>
            )}
            {/* <button
            disabled={modalId === 1 && beneficiaryModalButton }
              className="w-full bg-primary rounded-full py-3 text-white"
              onClick={() => {
                setOpenModal1(false);
                modalId === 1 && fetchIdOfBeneficiary(beneficiaryId);
                modalId === 2 && fetchBranchesByServiceAreaId(serviceAreaId);
                modalId === 3 && selectBranch();
                modalId === 5 && selectProviders();
              }}
            >
              {adminLabels.apply}
            </button> */}
          </Modal>
        ) : (
          ""
        )}
        {openModal ? (
          <ModalComponent
            filterModal={openModal}
            modalTitle={adminLabels.addStatus}
            btn={true}
            closeModal={() => setOpenModal(false)}
            buttonFunc={() => {
              setSaveChip();
              setOpenModal(false);
            }}
          >
            <div className="px-30 mt-5">
              <InputComponent
                className=""
                value={statusTitle}
                onChange={(e) => setStatusTitle(e.target.value)}
                placeholder={"Title"}
              />
            </div>
            <div className=" px-30">
              <TextAreaComponent
                className="  "
                rows={5}
                value={statusDescription}
                onChange={(e) => setStatusDescription(e.target.value)}
                placeholder={"Description"}
              />
            </div>
          </ModalComponent>
        ) : (
          ""
        )}
        {openModal2 ? (
          <ModalComponent
            filterModal={openModal2}
            modalTitle={adminLabels.selectServiceForm}
            btn={true}
            closeModal={() => setOpenModal2(false)}
            buttonFunc={() => {
              if (
                nonAssignServiceForms?.filter((item) => item.checked === true)
                  ?.length
              ) {
                setOpenModal2(false);
                setServiceFormId(
                  nonAssignServiceForms?.filter(
                    (item) => item.checked === true
                  )[0]?.service_form_id
                );
              } else {
                message.error("please select service form");
              }
              // setOpenModal2(false);
              // setServiceFormId(
              //   nonAssignServiceForms?.filter(
              //     (item) => item.checked === true
              //   )[0]?.service_form_id
              // );
            }}
          >
            <div className="select-serviceProvider-modal-container h-96 overflow-y-scroll px-2 py-2">
              {nonAssignServiceForms?.length
                ? nonAssignServiceForms?.map((t, i) => {
                    return (
                      <div
                        className="flex justify-between  text-left py-5 "
                        key={i}
                      >
                        <div className="flex items-center justify-center">
                          <span className="pl-4">{t?.title}</span>
                        </div>
                        <div className="flex justify-between items-center">
                          {t?.checked ? (
                            <img
                              style={{ width: "100%", height: "100%" }}
                              src={checkboxActive.default}
                              onClick={() => onCheckedSelectServiceForm(t, i)}
                            />
                          ) : (
                            <img
                              style={{ width: "100%", height: "100%" }}
                              src={checkbox.default}
                              onClick={() => onCheckedSelectServiceForm(t, i)}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </ModalComponent>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    showBeneficiaries: state.beneficiariesList.beneficiaries,
    showServiceArea: state.serviceAreaList.serviceAreas,
    loader: state.loaderToggle.loaderState,
    showBranches: state.branchList.branches,
    showProviders: state.providerList.providers,
    showServices: state.servicesList.services,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBeneificiariesList: () => {
      dispatch(FetchBeneficiariesAction());
    },
    getServiceAreaList: () => {
      dispatch(FetchServiceAreaAction());
    },
    getBranchesList: () => {
      dispatch(FetchBranchesAction());
    },
    getProvidersList: () => {
      dispatch(FetchProvidersAction());
    },
    getServicesList: (props) => {
      dispatch(FetchServicesAction(props));
    },
    setLogoutUser: (data) => {
      dispatch(LogoutUserAction(data));
    },
    removeBeneficiaries: () => {
      dispatch(DeleteBeneficiariesAtLogoutTime());
    },
    removeProviders: () => {
      dispatch(DeleteProvidersAtLogoutTime());
    },
    removeServiceAreas: () => {
      dispatch(DeleteServiceAreasAtLogoutTime());
    },
    removeBranches: () => {
      dispatch(DeleteBranchesAtLogoutTime());
    },
    removeServices: () => {
      dispatch(DeleteServicesAtLogoutTime());
    },
    removeServiceForms: () => {
      dispatch(DeleteServiceForms());
    },
    removeAppUsers: () => {
      dispatch(DeleteAppUsers());
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddBranchService);
