import { SearchOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Spin } from "antd";
import Modal from "antd/lib/modal/Modal";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiOutlineArrowDown } from "react-icons/ai";
import { FaCalendarAlt } from "react-icons/fa";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ModalComponent } from "../../components";
import { Errors } from "../../components/antdToast";
import { Remove_Data_Form_Provider_Reducers } from "../../components/dataRemoveOnProviderLogout";
import TableComponent from "../../components/table";
import { GetOrdersOfProvider } from "../../config/api";
import { providerLabels } from "../../config/label/providerLabels";
import {
  DeleteWorkerAtLogoutTime,
  FetchWorkersAction,
} from "../../Redux/actions/fetchWorkersAction";

import FilledServiceFormFields from "./filledServiceFormFields";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { ProviderLogoutAction } from "../../Redux/actions/logoutAction";
import { fetchServiceProviderAllOrders } from "../../Redux/actions/serviceProviderActions/orders";
var fs = require("fs");

const OrdersAssignation = (props) => {
  const { goBack, push } = useHistory();
  const dispatch = useDispatch();
  const orders = useSelector(
    (state) => state?.serviceProviderAllOrders?.orders?.orders
  );
  const ordersLoading = useSelector(
    (state) => state?.serviceProviderAllOrders?.loading
  );

  const [providerOrders, setProviderOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [orderRegion, setOrderRegion] = useState("");
  const [orderId, setOrderId] = useState("");
  const [docs, setDocs] = useState([]);
  const [assignModal, setAssignModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState(null);
  const [filledServiceForm, setFilledServiceForm] = useState([]);
  const currentSelectedLangue = localStorage.getItem("primaryLanguage");
  const onFilter = () => {
    setFilterModal(true);
  };
  useEffect(() => {
    props.getWorkers(props);
    // getOrdersByProvider();
    dispatch(fetchServiceProviderAllOrders(push));
    document.body.style.overflow = "auto";
    return function cleanup() {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    setLoading(ordersLoading);
  }, [ordersLoading]);
  useEffect(() => {
    let filterdOutByWorkerId = orders?.filter(
      (item) => item.worker_provider_id === null
    );
    let mappedOut = filterdOutByWorkerId?.map((item, index) => {
      const servicePackType = item?.service?.service_pack_type;

      return {
        ...item,
        no: index + 1,
        product: {
          name:
            currentSelectedLangue === "english"
              ? item.service.name
              : item.service.name_sp,
          avatar: item.service.avatar,
          info: item.service.info,
        },
        date: moment(item.created_at)?.format("DD-MMM-YYYY HH:mm"),
        orderId: item.order_id,
        estimatedTime:
          servicePackType === "monthly"
            ? providerLabels[servicePackType]
            : item.service.time === null && item.service.time_type === null
            ? "0"
            : `${item.service.time}  ${
                Number(item.service.time) > 1
                  ? providerLabels[
                      item.service.time_type === "Hours"
                        ? item.service.time_type
                        : item.service.time_type?.toLowerCase() + "s"
                    ]
                  : providerLabels[
                      item.service.time_type === "Hours"
                        ? "hour"
                        : item.service.time_type?.toLowerCase()
                    ]
              }`,
      };
    });

    setProviderOrders(mappedOut || []);
    setLoading(false);
  }, [orders]);
  const getOrdersByProvider = () => {
    GetOrdersOfProvider(
      (success) => {
        if (success.status === 200) {
          if (success.data.response.detail !== null) {
            let filterdOutByWorkerId =
              success?.data?.response?.detail?.orders.filter(
                (item) => item.worker_provider_id === null
              );
            let mappedOut = filterdOutByWorkerId.map((item, index) => {
              const servicePackType = item?.service?.service_pack_type;

              return {
                ...item,
                no: index + 1,
                product: {
                  name:
                    currentSelectedLangue === "english"
                      ? item.service.name
                      : item.service.name_sp,
                  avatar: item.service.avatar,
                  info: item.service.info,
                },
                date: moment(item.created_at).format("DD-MMM-YYYY HH:mm"),
                orderId: item.order_id,
                estimatedTime:
                  servicePackType === "monthly"
                    ? providerLabels[servicePackType]
                    : item.service.time === null &&
                      item.service.time_type === null
                    ? "0"
                    : `${item.service.time}  ${
                        Number(item.service.time) > 1
                          ? providerLabels[
                              item.service.time_type === "Hours"
                                ? item.service.time_type
                                : item.service.time_type?.toLowerCase() + "s"
                            ]
                          : providerLabels[
                              item.service.time_type === "Hours"
                                ? "hour"
                                : item.service.time_type?.toLowerCase()
                            ]
                      }`,
              };
            });

            setProviderOrders(mappedOut);
            setLoading(false);
          }
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const columns = [
    {
      align: "center",
      title: providerLabels.no,
      dataIndex: "no",
      key: "no",
      width: 40,
      render: (text) => <a>{text}</a>,
    },
    {
      align: "center",
      title: providerLabels.product,
      dataIndex: "product",
      key: "product",
      width: 180,
      render: (text, index) => (
        <div className={"flex"}>
          <img
            src={text.avatar}
            style={{ width: 50, height: 45 }}
            // className="rounded-lg"
            alt="detail image"
          />
          <div className="pl-2 text-left">
            <label>
              <b>{text.name}</b>
            </label>{" "}
            {/* <br />
            {renderHTML(text.info)} */}
          </div>
        </div>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.product.name.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.product?.name?.localeCompare(b?.product?.name),
    },

    {
      align: "center",
      title: providerLabels.orderID,
      dataIndex: "orderId",
      key: "orderId",
      width: 100,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.orderId.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.orderId?.localeCompare(b?.orderId),
    },
    {
      align: "center",
      title: providerLabels.date,
      key: "date",
      dataIndex: "date",
      width: 100,
      sortDirections: ["descend", "ascend"],
      sorter: (aa, bb) => moment(aa.date).unix() - moment(bb.date).unix(),
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: providerLabels.timeOfDelivery,
      dataIndex: "estimatedTime",
      key: "estimatedTime",
      width: 100,
    },
    {
      align: "center",
      title: providerLabels.action,

      width: 80,
      render: (text, index) => (
        <div className="text-center	">
          <Button
            type="disable"
            shape={"round"}
            onClick={() => {
              setOrderDetails(text);
              setAssignModal(true);
            }}
            className="background-color-gray whiteTxt"
          >
            {providerLabels.viewDetails}
          </Button>
        </div>
      ),
    },
  ];
  return (
    <section className="">
      <Spin tip={providerLabels.loading} spinning={loading}>
        <div className="flex justify-between mt-5 ">
          <div className="pl-5 flex self-center justify-center items-center">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{providerLabels.back}</span>
            </div>
            <h3 className="ml-10	">{providerLabels.newOrdersToBeAssigned}</h3>
          </div>
          <div className="mr-28 flex">
            <CSVLink
              filename={`${providerLabels.newOrdersToBeAssigned}.csv`}
              target="_blank"
              data={providerOrders?.map((item, index) => {
                return {
                  no: index + 1,
                  product: item?.product?.name,
                  orderID: item?.orderId,
                  orderDate: item?.date,
                  estimatedTime: item?.estimatedTime,
                };
              }) || []}
              headers={[
                { label: providerLabels.no, key: "no" },
                { label: providerLabels.product, key: "product" },
                { label: providerLabels.orderID, key: "orderID" },
                { label: providerLabels.orderDate, key: "orderDate" },
                {
                  label: providerLabels.timeUntilDelivery,
                  key: "estimatedTime",
                },
              ]}
            >
              <Button
                shape={"round"}
                className="icon-button "
                icon={<AiOutlineArrowDown color="#5ab3f0" />}
              >
                {providerLabels.exportCSV}
              </Button>
            </CSVLink>
          </div>
        </div>
        <ModalComponent
          filterModal={assignModal}
          closeModal={() => {
            setAssignModal(false);
            setOrderDetails(null);
          }}
          modalTitle={`${providerLabels.orderID}: ${orderDetails?.order_id} `}
          className="global-modal-css-scroll-height"
        >
          <>
            <div className="scrollable-fixed-height">
              <p className="modalH">
                <b>{providerLabels.serviceSelected}:</b>
              </p>
              <div>
                <p>
                  {" "}
                  "{providerLabels.serviceAreaSelected}" - "
                  {`${
                    currentSelectedLangue === "english"
                      ? orderDetails?.service?.service_area?.name
                      : orderDetails?.service?.service_area?.info
                  }`}
                  " <br />"{providerLabels.serviceSelected}" - "
                  {`${
                    currentSelectedLangue === "english"
                      ? orderDetails?.service?.name
                      : orderDetails?.service?.name_sp
                  }`}
                  " <br />"{providerLabels.branchSelected}" -"
                  {`${
                    currentSelectedLangue === "english"
                      ? orderDetails?.service?.branch?.name
                      : orderDetails?.service?.branch?.name_sp
                  }`}
                  " <br />
                </p>
              </div>

              {/* <p className="modalH">
                <b>{providerLabels.clientInfo}:</b>
              </p>
              <p>
                {providerLabels.name}:{" "}
                {orderDetails?.data?.[0]?.personal !== "" &&
                  orderDetails?.data?.[0]?.personal.firstName +
                    " " +
                    orderDetails?.data?.[0]?.personal.lastName}
                ,
                <br /> {providerLabels.phone}:
                {orderDetails?.data?.[0]?.personal !== "" &&
                  orderDetails?.data?.[0]?.personal.phone_number}
                ,
                {orderDetails?.data?.[0]?.personal?.none?.length ? null : (
                  <>
                    <br />
                    {orderDetails?.data?.[0]?.personal?.business?.length ? (
                      <>
                        {providerLabels.business}:{providerLabels.yes}
                      </>
                    ) : null}
                    {orderDetails?.data?.[0]?.personal?.self?.length ? (
                      <>
                        {providerLabels.selfEmployed}:{providerLabels.yes}
                      </>
                    ) : null}
                    ,
                    <br />
                    {providerLabels.company}:{" "}
                    {orderDetails?.data?.[0]?.personal !== "" &&
                      orderDetails?.data?.[0]?.personal.companyName}
                    <br />
                    {providerLabels.cif}:{" "}
                    {orderDetails?.data?.[0]?.personal !== "" &&
                      orderDetails?.data?.[0]?.personal.CIF}
                  </>
                )}
              </p> */}
              <p className="modalH">
                <b>{providerLabels.regionSelected}:</b>
              </p>
              <p>
                {providerLabels.region}:{" "}
                {orderDetails?.data?.[1]?.region !== "" &&
                  orderDetails?.data?.[1]?.region.state}{" "}
                ,
                <br />
                {providerLabels.city}:{" "}
                {orderDetails?.data?.[1]?.region !== "" &&
                  orderDetails?.data?.[1]?.region.city}
              </p>
              {orderDetails?.data?.[2]?.additionalInfo?.length ? (
                <FilledServiceFormFields
                  filledServiceForm={orderDetails?.data?.[2]?.additionalInfo}
                />
              ) : null}
            </div>
            <Link to={`/order-assign/${orderDetails?.order_id}`}>
              <button className="w-full bg-primary rounded-full py-3 text-white mt-4">
                {providerLabels.manageOrder}
              </button>
            </Link>
          </>
        </ModalComponent>
        <Modal
          visible={filterModal}
          width={400}
          footer={false}
          onCancel={() => {
            setFilterModal(false);
          }}
        >
          <div>
            <h4 className="font-bold text-2xl text-center text-gray-600	">
              Filter By Date
            </h4>
          </div>
          <div className="flex justify-between items-center my-5 ">
            <DatePicker
              className="border-radius20 datePickerStyling"
              suffixIcon={<FaCalendarAlt color={"#5ab3f0"} />}
            />
            <span>to</span>
            <DatePicker
              className="border-radius20 datePickerStyling"
              suffixIcon={<FaCalendarAlt color={"#5ab3f0"} />}
            />
          </div>
          <button
            className="w-full bg-primary rounded-full py-3 text-white"
            onClick={() => setFilterModal(false)}
          >
            Apply
          </button>
        </Modal>
        <div className="container mx-auto px-20 mt-5">
          <TableComponent
            columns={columns}
            data={providerOrders}
            scroll={{ x: 1375 }}
            // pagination={{ defaultPageSize: 5 }}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
            scrolly="500px"
          />
        </div>
      </Spin>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    showWorkers: state.workerList.workers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
    getWorkers: (props) => {
      dispatch(FetchWorkersAction(props));
    },
    removeWorkerList: () => {
      dispatch(DeleteWorkerAtLogoutTime());
    },
    setProviderLogout: () => {
      dispatch(ProviderLogoutAction());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrdersAssignation);
