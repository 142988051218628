import { EyeOutlined } from "@ant-design/icons";
import { List, Modal, Switch, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AiFillStar } from "react-icons/ai";
import renderHTML from "react-render-html";
import { Errors, Success } from "../../components/antdToast";
import InformationContainer from "../../components/informationContainer";
import { UpdateServiceProviderStatusMethod } from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import "./UserDetailContainerCard.css";

const UserDetailContainerCard = ({
  dividerShow = false,
  providerDetails,
  services,
  updateServiceProviderData,
}) => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [modal_Content, setModalContent] = useState("");
  const [currentSelectedLangue, setCurrentSelectedLangue] = useState("spanish");

  useEffect(() => {
    setCurrentSelectedLangue(localStorage.getItem("primaryLanguage"));
  }, []);
  const setServiceProviderActive = (id) => {
    let userId = new FormData();
    userId.append("service_provider_id", id);
    UpdateServiceProviderStatusMethod(
      userId,
      (success) => {
        if (success.status === 200) {
          updateServiceProviderData();
          Success(success?.data?.response?.message);
        }
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  return (
    <>
      <div className="bg-white rounded-lg">
        <div className=" p-6  pl-3 pt-0 pb-3 flex w-full justify-between items-center">
          <div style={{ display: "flex" }} className="p-5 pb-0">
            <img
              className="rounded-full"
              src={providerDetails?.avatar}
              style={{ height: 48, width: 48 }}
            />
            <div className="pl-2">
              <span className="flex space-x-2">
                <strong className=" capitalize">{providerDetails?.name}</strong>

                <div className="flex items-center ">
                  <span className="text-sm mr-1" style={{ color: "#808080" }}>
                    &#40;
                  </span>
                  <p className="text-sm mb-0">
                    {providerDetails?.fake_rating !== null
                      ? parseFloat(providerDetails?.fake_rating)
                      : parseFloat(
                          providerDetails?.average_rating
                            ? providerDetails?.average_rating
                            : 0
                        ).toFixed(1)}
                  </p>
                  <AiFillStar
                    color={"#FFC107"}
                    style={{
                      marginRight: 5,
                      marginTop: 3,
                      fontSize: "13px",
                    }}
                  />
                  <span className="text-sm mr-1" style={{ color: "#808080" }}>
                    &#41;
                  </span>
                </div>
              </span>

              <p>{providerDetails?.email}</p>
            </div>
          </div>
          <div className="flex">
            <div className="flex align-top px-5  table-quill">
              <Tooltip placement="top" title={adminLabels.viewInformation}>
                <span
                  className="flex align-top"
                  onClick={() => {
                    setShowInfoModal(true);
                    currentSelectedLangue === "english"
                      ? setModalContent(providerDetails?.info || "")
                      : setModalContent(providerDetails?.info_sp || "");
                  }}
                >
                  <EyeOutlined className="cursor-pointer" />
                </span>
              </Tooltip>
            </div>
            <div className="flex flex-col">
              <Switch
                size="small"
                onChange={() =>
                  setServiceProviderActive(providerDetails?.service_provider_id)
                }
                checked={providerDetails?.status === 1 ? true : false}
              />
              <span>{adminLabels.status}</span>
            </div>
          </div>
        </div>
        <div className="bg-white container rounded-lg ">
          <div className="grid grid-cols-2 p-6 pl-3 pt-0 pb-3">
            {/* /////////////////details */}
            <div className="" style={{ position: "relative" }}>
              {dividerShow && (
                <div
                  style={{
                    border: "1px solid gray",
                    opacity: 0.2,
                    marginBottom: "15px",
                  }}
                />
              )}
              <div style={{ paddingLeft: 20 }}>
                <b>{adminLabels.phone}</b>
                <p>{providerDetails?.phone}</p>
              </div>
            </div>
            <div className="" style={{ position: "relative" }}>
              {dividerShow && (
                <div
                  style={{
                    border: "1px solid gray",
                    opacity: 0.2,
                    marginBottom: "15px",
                  }}
                />
              )}
              <div style={{ paddingLeft: 20 }}>
                <b>{adminLabels.address}</b>
                <p>
                  {providerDetails?.address} / {providerDetails?.city}
                </p>
              </div>
            </div>

            <div className="" style={{ position: "relative" }}>
              {dividerShow && (
                <div
                  style={{
                    border: "1px solid gray",
                    opacity: 0.2,
                    marginBottom: "15px",
                  }}
                />
              )}
              <div style={{ paddingLeft: 20 }}>
                <b>{adminLabels.serviceName}</b>
                {services.length === 0 ? (
                  <p>N/A</p>
                ) : (
                  <div>
                    <div id="scrollableDiv" className="userDetailCardServices">
                      <List
                        dataSource={services}
                        renderItem={(item) => (
                          <List.Item key={item.name}>
                            <div>
                              {
                                item?.[
                                  currentSelectedLangue === "english"
                                    ? "name"
                                    : "name_sp"
                                ]
                              }
                            </div>
                          </List.Item>
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="" style={{ position: "relative" }}>
              {dividerShow && (
                <div
                  style={{
                    border: "1px solid gray",
                    opacity: 0.2,
                    marginBottom: "15px",
                  }}
                />
              )}
              <div style={{ paddingLeft: 20 }}>
                <b>{adminLabels.state}</b>
                <div>
                  <div id="scrollableDiv" className="userDetailCardStates">
                    <List
                      dataSource={providerDetails?.state}
                      renderItem={(item) => (
                        <List.Item key={item.name}>
                          <div>{item}</div>
                        </List.Item>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4" style={{ position: "relative" }}>
              {dividerShow && (
                <div
                  style={{
                    border: "1px solid gray",
                    opacity: 0.2,
                    marginBottom: "15px",
                  }}
                />
              )}
              <div style={{ paddingLeft: 20 }}>
                <b>{adminLabels.registeredDate}</b>
                <p>
                  {moment(providerDetails?.created_at).format("DD-MMM-YYYY")}
                </p>
              </div>
            </div>

            <div className="mt-4" style={{ position: "relative" }}>
              {dividerShow && (
                <div
                  style={{
                    border: "1px solid gray",
                    opacity: 0.2,
                    marginBottom: "15px",
                  }}
                />
              )}
              <div style={{ paddingLeft: 20 }}>
                <b>{adminLabels.workingHours}</b>
                <div className="flex flex-col items-start">
                  <div
                    className={` flex flex-col items-start text-xs w-full ${
                      providerDetails?.working_hours?.length === 1
                        ? `py-2`
                        : `py-0`
                    }`}
                  >
                    {" "}
                    {providerDetails?.working_hours?.map((hour, index) => {
                      return hour?.isFriday === false ? (
                        <div
                          className=" w-full flex justify-between"
                          key={index}
                        >
                          <span className="w-1/2">
                            {adminLabels?.monToThu} : {hour?.start} -{" "}
                            {hour?.end}
                          </span>
                          {hour?.hasOwnProperty("monTHuLunchHours") &&
                            hour?.monTHuLunchHours !== null && (
                              <span className=" w-1/2 text-right">
                                {adminLabels?.lunchTime} :{" "}
                                {hour?.monTHuLunchHours?.start} -{" "}
                                {hour?.monTHuLunchHours?.end}
                              </span>
                            )}
                        </div>
                      ) : (
                        <div
                          className="w-full flex justify-between"
                          key={index}
                        >
                          <span className="w-1/2">
                            {adminLabels?.fri} : {hour?.start} - {hour?.end}
                          </span>
                          {hour?.hasOwnProperty("fridayLunchHours") &&
                            hour?.fridayLunchHours !== null && (
                              <span className=" w-1/2 text-right">
                                {adminLabels.lunchTime} :{" "}
                                {hour?.fridayLunchHours?.start} -{" "}
                                {hour?.fridayLunchHours?.end}
                              </span>
                            )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={showInfoModal}
        footer={null}
        centered
        className="global-modal-css-scroll-height "
        onCancel={() => {
          setShowInfoModal(false);
        }}
      >
        <div>
          <h4 className="font-bold text-2xl text-center text-gray-600	">
            {adminLabels.information}
          </h4>
        </div>
        <div className="scrollable-fixed-height py-5 render-html-class">
          <InformationContainer info={modal_Content || ""} />
        </div>
      </Modal>
    </>
  );
};
export default UserDetailContainerCard;
