import React, { useEffect } from "react";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { Row } from "antd";
import Data from "../../components/Dashboard/Data";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  DeleteBeneficiariesAtLogoutTime,
  FetchBeneficiariesAction,
} from "../../Redux/actions/fetchBeneficiariesAction";
import {
  DeleteServiceAreasAtLogoutTime,
  FetchServiceAreaAction,
} from "../../Redux/actions/fetchServiceAreaAction";
import {
  DeleteBranchesAtLogoutTime,
  FetchBranchesAction,
} from "../../Redux/actions/fetchBranchesAction";
import {
  DeleteProvidersAtLogoutTime,
  FetchProvidersAction,
} from "../../Redux/actions/fetchProvidersAction";
import {
  DeleteServicesAtLogoutTime,
  FetchServicesAction,
} from "../../Redux/actions/fetchServicesAction";
import { DeleteServiceForms } from "../../Redux/actions/fetchServiceFormsAction";
import { DeleteAppUsers } from "../../Redux/actions/fetchAppUsersAction";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";

const CategoryManagement = (props) => {
  const { goBack } = useHistory();

  useEffect(() => {
    props.getBeneificiariesList(props);
    props.getServiceAreaList(props);
    props.getBranchesList(props);
    props.getProvidersList(props);
    props.getServicesList(props);
  }, []);

  const array = [
    // {
    //   name: "Beneficiaries",
    //   count: props.showBeneficiaries.length,
    //   route: "/admin/beneficiaries",
    // },
    // {
    //   name: "Service Areas",
    //   count: props.showServiceArea.length,
    //   route: "/admin/service-area",
    // },
    {
      name: "Branches",
      count: props.showBranches.length,
      route: "/admin/branches",
    },
    {
      name: "Services",
      count: props.showServices.length,
      route: "/admin/services",
    },
  ];

  return (
    <section>
      <div className="flex self-center items-center ml-5 mt-20">
        <div
          onClick={() => goBack()}
          className="flex text-gray-400 items-center cursor-pointer"
        >
          <RiArrowDropLeftLine />
          <span>Back</span>
        </div>
        <h3 className="font-bold text-lg mb-0 ml-10">Category Management</h3>
      </div>

      <div className="container mx-auto px-2 sm:px-2 lg:px-20 mt-5">
        <Row gutter={16}>
          {array.map((t) => {
            return <Data name={t.name} prize={t.count} route={t.route} />;
          })}
        </Row>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    showBeneficiaries: state.beneficiariesList.beneficiaries,
    showServiceArea: state.serviceAreaList.serviceAreas,
    showBranches: state.branchList.branches,
    showProviders: state.providerList.providers,
    showServices: state.servicesList.services,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBeneificiariesList: (props) => {
      dispatch(FetchBeneficiariesAction(props));
    },
    getServiceAreaList: (props) => {
      dispatch(FetchServiceAreaAction(props));
    },
    getBranchesList: (props) => {
      dispatch(FetchBranchesAction(props));
    },
    getProvidersList: (props) => {
      dispatch(FetchProvidersAction(props));
    },
    getServicesList: (props) => {
      dispatch(FetchServicesAction(props));
    },
    removeBeneficiaries: () => {
      dispatch(DeleteBeneficiariesAtLogoutTime());
    },
    removeProviders: () => {
      dispatch(DeleteProvidersAtLogoutTime());
    },
    removeServiceAreas: () => {
      dispatch(DeleteServiceAreasAtLogoutTime());
    },
    removeBranches: () => {
      dispatch(DeleteBranchesAtLogoutTime());
    },
    removeServices: () => {
      dispatch(DeleteServicesAtLogoutTime());
    },
    removeServiceForms: () => {
      dispatch(DeleteServiceForms());
    },
    removeAppUsers: () => {
      dispatch(DeleteAppUsers());
    },
    setLogoutUser: (data) => {
      dispatch(LogoutUserAction(data));
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryManagement);
