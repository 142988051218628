import "../App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import AdminLogin from "../screens/admin/admin";
import SubAdminAccMng from "../screens/subAdminAccMng";
import { Images } from "../constant/Images";
import Dashboard from "../screens/dashboard/dashboard";
import AppHeader from "../components/Dashboard/AppHeader";
import AddSubAdmin from "../screens/addsubadmin/index";
import AdminProfile from "../screens/subAdminProfile/index";
import SubAdminAccLog from "../screens/subAdminActivityLogs.js";
import UserAccManagement from "../screens/userAccountManagement";
import AddAdminServiceProvider from "../screens/addAddminServiceProvider";
import ServiceProvider from "../screens/serviceProviderDetails/index";
import WorkerOfServiceProvider from "../screens/workerOfServiceProvidersAccntMngmnt";
import Beneficiaries from "../screens/beneficiaries";
import CategoryManagement from "../screens/categoryManagement";
import AdminServiceProvider from "../screens/adminofserviceprovider";
import Support from "../screens/support";
import Help from "../screens/help";
import ServiceArea from "../screens/serviceArea";
import ServiceOrderDetails from "../screens/ServiceOrderDetails";
import ServiceProviderOrderDetails from "../screens/ServiceProviderOrderDetails";
import ServiceWorkerOrderDetails from "../screens/ServiceWorkerOrderDetails";
import ReportAndAnalytics from "../screens/reportAndAnalytics";
import Branches from "../screens/branches";
import Services from "../screens/services";
import NewBeneficiary from "../screens/addNewBeneficiary";
import NewBranch from "../screens/addNewBranch";
import BookingsManagement from "../screens/bookingManagement";
import MyLogs from "../screens/myLogs";
import AddServiceArea from "../screens/addServiceArea";
import Payouts from "../screens/payouts";
import MyEarnings from "../screens/myEarnings";
import PromoCodes from "../screens/promoCodes";
import AddBranchService from "../screens/addNewService";
import ReportAndAnaytics from "../screens/ReportAndAnaytics";
import ServiceProviderUserReport from "../screens/serviceProviderUserReport";
import WorkRequestManagement from "../screens/workRequestManagement";
import AddPromoCode from "../screens/addPromoCode";
import BookingReport from "../screens/bookingReport";
import ServiceProviderDetail from "../screens/serviceProviderDetail";
import Faqs from "../screens/faqs";
import UserAccountDetail from "../screens/userAccountDetail";
import DynamicFormsList from "../screens/dynamicForm/dynamicForm";
import DynamicFormAdd from "../screens/dynamicForm/dynamicFormAdd";
import WorkerDetail from "../screens/workerDetail";
import BudgetResponse from "../screens/budgetResponse";
import DynamicFormEdit from "../screens/dynamicForm/dynamicFormEdit";
import OrderInfoDetail from "../screens/orderInfo";
import BookingManagementOrderDetails from "../screens/BookingManagementOrderDetails";
import AmindSideOrderInfo from "../screens/amindSideOrderInfo";
import AdminSideOrderSteps from "../screens/adminSideOrderSteps";
import DisputeChatMessages from "../screens/disputeChat";

import { connect } from "react-redux";
import UserProviderOrderMessages from "../screens/userProviderOrderMessages";
import AdminIncomePaymentsBills from "../screens/AdminIncomePaymentsBills";
import { spanishLabels } from "../config/label/adminLabels";
import AdminMessages from "../screens/adminMessages";
import BudgetResponseDetails from "../screens/budgetResponseDetails";

const { dotPattern, circleImage } = Images;

function Admin(props) {
  useEffect(() => {
    document.title = spanishLabels.DoCuDocAdmin;
  }, []);
  const [subAdminRoles, setSubAdminRoles] = useState(
    props?.loginInfo?.admin?.roles
  );
  const [adminRoutes, setAdminroutes] = useState([
    {
      path: "/admin/dashboard",
      component: Dashboard,
      menuId: "1",
      checked: false,
    },
    {
      path: "/admin/user-account-management",
      component: UserAccManagement,
      menuId: "2",
      checked: false,
    },
    {
      path: "/admin/admin-service-provider",
      component: AdminServiceProvider,
      menuId: "3",
      checked: false,
    },
    {
      path: "/admin/worker-service-provider",
      component: WorkerOfServiceProvider,
      menuId: "4",
      checked: false,
    },
    {
      path: "/admin/branches",
      component: Branches,
      menuId: "5",
      checked: false,
    },
    {
      path: "/admin/services",
      component: Services,
      menuId: "5",
      checked: false,
    },
    {
      path: "/admin/booking-management",
      component: BookingsManagement,
      menuId: "6",
      checked: false,
    },
    {
      path: "/admin/budget-response",
      component: BudgetResponse,
      menuId: "7",
      checked: false,
    },
    {
      path: "/admin/work-request-management",
      component: WorkRequestManagement,
      menuId: "8",
      checked: false,
    },
    {
      path: "/admin/promo-codes",
      component: PromoCodes,
      menuId: "9",
      checked: false,
    },
    {
      path: "/admin/my-logs",
      component: MyLogs,
      menuId: "10",
      checked: false,
    },
    {
      path: "/admin/help",
      component: Help,
      menuId: "11",
      checked: false,
    },
    {
      path: "/admin/messages",
      component: AdminMessages,
      menuId: "112",
      checked: false,
    },
    {
      path: "/admin/payouts",
      component: Payouts,
      menuId: "12",
      checked: false,
    },
    {
      path: "/admin/my-earnings",
      component: MyEarnings,
      menuId: "13",
      checked: false,
    },
    {
      path: "/admin/Dynamic-forms-list",
      component: DynamicFormsList,
      menuId: "14",
      checked: false,
    },
    // {
    //   path: "/admin/report-and-analytics",
    //   component: ReportAndAnalytics,
    //   menuId: "15",
    //   checked: false,
    // },
    {
      path: "/admin/faqs",
      component: Faqs,
      menuId: "16",
      checked: false,
    },
    {
      path: "/admin/incomes-payments-bills",
      component: AdminIncomePaymentsBills,
      menuId: "17",
      checked: false,
    },
  ]);
  useEffect(() => {
    let token = localStorage.getItem("token");
    props.loginInfo?.admin?.status === "sub_admin" &&
      setAdminroutes(
        adminRoutes?.map((menu) => {
          return {
            ...menu,
            checked: subAdminRoles?.filter(
              (subAdminRole) => subAdminRole?.menuId === menu?.menuId
            )[0]?.checked,
          };
        })
      );
  }, []);
  return (
    <>
      <AppHeader userName={props.loginInfo.admin.name} />
      <div
        className="absolute right-10 top-38 "
        style={{ zIndex: -1, marginTop: " 59px" }}
      >
        <img src={dotPattern.default} />
      </div>

      {adminRoutes.some(
        (item) => item.menuId === "2" && item.checked === true
      ) && (
        <>
          <Route
            path="/admin/user-acount-detail/:userID"
            exact
            component={UserAccountDetail}
          />
          <Route
            path="/admin/user-service-order-details/:orderId"
            exact
            component={ServiceOrderDetails}
          />
        </>
      )}
      {adminRoutes.some(
        (item) => item.menuId === "3" && item.checked === true
      ) && (
        <>
          <Route
            path="/admin/add-admin-service-provider"
            exact
            component={AddAdminServiceProvider}
          />
          <Route
            path="/admin/edit-admin-service-provider/:providerID"
            exact
            component={AddAdminServiceProvider}
          />
          <Route
            path="/admin/service-provider/:providerID"
            exact
            component={ServiceProvider}
          />
          <Route
            path="/admin/provider-service-order-details/:orderId"
            exact
            component={ServiceProviderOrderDetails}
          />
        </>
      )}
      {adminRoutes.some(
        (item) => item.menuId === "4" && item.checked === true
      ) && (
        <>
          <Route
            path="/admin/worker-detail/:workerID"
            exact
            component={WorkerDetail}
          />
          <Route
            path="/admin/worker-service-order-details/:orderId"
            exact
            component={ServiceWorkerOrderDetails}
          />
        </>
      )}
      {adminRoutes.some(
        (item) => item.menuId === "5" && item.checked === true
      ) && (
        <>
          <Route path="/admin/add-branch" exact component={NewBranch} />
          <Route path="/admin/edit-branch/:id" exact component={NewBranch} />
          <Route
            path="/admin/add-new-service"
            exact
            component={AddBranchService}
          />
          <Route
            path="/admin/edit-service/:id"
            exact
            component={AddBranchService}
          />
        </>
      )}
      {adminRoutes.some(
        (item) => item.menuId === "6" && item.checked === true
      ) && (
        <>
          <Route
            path="/admin/booking-management-order-details/:orderId"
            exact
            component={BookingManagementOrderDetails}
          />
          <Route
            path={"/admin/admin-side-order-info"}
            exact
            component={AmindSideOrderInfo}
          />
          <Route
            path="/admin/order-steps/:id"
            component={AdminSideOrderSteps}
          />
          <Route
            path="/admin/dispute-chat/:id"
            component={DisputeChatMessages}
          />
          <Route path="/admin/disputed/:id" component={DisputeChatMessages} />

          <Route
            path="/admin/user-provider-order-messages/:id/"
            component={UserProviderOrderMessages}
          />
        </>
      )}
      {adminRoutes.some(
        (item) => item.menuId === "14" && item.checked === true
      ) && (
        <>
          <Route
            path={"/admin/dynamic-form-add"}
            exact
            component={DynamicFormAdd}
          />
          <Route
            path="/admin/dynamic-form-edit/:id"
            exact
            component={DynamicFormEdit}
          />
        </>
      )}
      {/* {adminRoutes.some(
        (item) => item.menuId === "17" && item.checked === true
      ) && (
        <>
          <Route
            path="/admin/incomes-payments-bills"
            component={AdminIncomePaymentsBills}
          />
        </>
      )} */}
      {props.loginInfo.admin.status === "sub_admin" ? (
        adminRoutes
          .filter((check) => check.checked === true)
          ?.map((subRoute) => {
            return (
              <Route
                path={subRoute.path}
                exact
                component={subRoute.component}
              />
            );
          })
      ) : (
        <>
          {" "}
          <Route path={"/admin/dashboard"} exact component={Dashboard} />
          <Route
            path="/admin/sub-admin-account-manager"
            exact
            component={SubAdminAccMng}
          />
          <Route path="/admin/add-admin" exact component={AddSubAdmin} />
          <Route
            path="/admin/edit-admin/:subAdminID"
            exact
            component={AddSubAdmin}
          />
          <Route path="/admin/admin-profile" exact component={AdminProfile} />
          <Route
            path="/admin/sub-admin-activity-log"
            exact
            component={SubAdminAccLog}
          />
          <Route
            path="/admin/user-account-management"
            exact
            component={UserAccManagement}
          />
          <Route
            path="/admin/admin-service-provider"
            exact
            component={AdminServiceProvider}
          />
          <Route
            path="/admin/add-admin-service-provider"
            exact
            component={AddAdminServiceProvider}
          />
          <Route
            path="/admin/edit-admin-service-provider/:providerID"
            exact
            component={AddAdminServiceProvider}
          />
          <Route
            path="/admin/service-provider/:providerID"
            exact
            component={ServiceProvider}
          />
          <Route
            path="/admin/user-acount-detail/:userID"
            exact
            component={UserAccountDetail}
          />
          <Route
            path="/admin/worker-service-provider"
            exact
            component={WorkerOfServiceProvider}
          />
          <Route path="/admin/beneficiaries" exact component={Beneficiaries} />
          <Route
            path="/admin/category-management"
            exact
            component={CategoryManagement}
          />
          {/* <Route path="/admin/support" exact component={Support} /> */}
          <Route path="/admin/help" exact component={Help} />
          <Route path="/admin/service-area" exact component={ServiceArea} />
          <Route
            path="/admin/worker-detail/:workerID"
            exact
            component={WorkerDetail}
          />
          <Route
            path="/admin/booking-management-order-details/:orderId"
            exact
            component={BookingManagementOrderDetails}
          />
          {/* <Route
        path="/admin/user-service-order-details/:orderId"
        exact
        component={UserServiceOrderDetails}
      /> */}
          <Route
            path="/admin/user-service-order-details/:orderId"
            exact
            component={ServiceOrderDetails}
          />
          <Route
            path="/admin/provider-service-order-details/:orderId"
            exact
            component={ServiceProviderOrderDetails}
          />
          <Route
            path="/admin/worker-service-order-details/:orderId"
            exact
            component={ServiceWorkerOrderDetails}
          />
          <Route path="/admin/order-info-detail" component={OrderInfoDetail} />
          <Route path="/admin/budget-response" component={BudgetResponse} />
          <Route path="/admin/budget-response-detail/:additionalBudgetId" component={BudgetResponseDetails} />
          <Route
            path="/admin/report-and-analytics"
            exact
            component={ReportAndAnalytics}
          />
          <Route path="/admin/branches" exact component={Branches} />
          <Route path="/admin/services" exact component={Services} />
          <Route
            path="/admin/add-beneficiaries"
            exact
            component={NewBeneficiary}
          />
          <Route
            path="/admin/edit-beneficiaries/:id"
            exact
            component={NewBeneficiary}
          />
          <Route path="/admin/add-branch" exact component={NewBranch} />
          <Route path="/admin/edit-branch/:id" exact component={NewBranch} />
          <Route
            path="/admin/booking-management"
            exact
            component={BookingsManagement}
          />
          <Route
            path="/admin/dispute-chat/:id"
            component={DisputeChatMessages}
          />
          <Route path="/admin/disputed/:id" component={DisputeChatMessages} />
          {/* <Route path="/admin/my-logs" exact component={MyLogs} /> */}
          <Route
            path="/admin/add-service-area"
            exact
            component={AddServiceArea}
          />
          <Route
            path="/admin/edit-service-area/:id"
            exact
            component={AddServiceArea}
          />
          <Route
            path="/admin/add-new-service"
            exact
            component={AddBranchService}
          />
          <Route
            path="/admin/edit-service/:id"
            exact
            component={AddBranchService}
          />
          <Route path={"/admin/payouts"} exact component={Payouts} />
          <Route path={"/admin/my-earnings"} exact component={MyEarnings} />
          <Route path={"/admin/promo-codes"} exact component={PromoCodes} />
          <Route
            path={"/admin/work-request-management"}
            exact
            component={WorkRequestManagement}
          />
          {/* <Route
            path={"/admin/report-and-anaytics"}
            exact
            component={ReportAndAnaytics}
          /> */}
          <Route
            path={"/admin/service-provider-user-report"}
            exact
            component={ServiceProviderUserReport}
          />
          <Route
            path={"/admin/add-promo-code"}
            exact
            component={AddPromoCode}
          />
          <Route
            path={"/admin/booking-report"}
            exact
            component={BookingReport}
          />
          <Route
            path={"/admin/service-provider-detail"}
            exact
            component={ServiceProviderDetail}
          />
          <Route path={"/admin/faqs"} exact component={Faqs} />
          <Route
            path={"/admin/dynamic-forms-list"}
            exact
            component={DynamicFormsList}
          />
          <Route
            path={"/admin/dynamic-form-add"}
            exact
            component={DynamicFormAdd}
          />
          <Route
            path="/admin/dynamic-form-edit/:id"
            exact
            component={DynamicFormEdit}
          />
          <Route
            path={"/admin/admin-side-order-info"}
            exact
            component={AmindSideOrderInfo}
          />
          <Route
            path="/admin/order-steps/:id"
            component={AdminSideOrderSteps}
          />{" "}
          <Route
            path="/admin/user-provider-order-messages/:id/"
            component={UserProviderOrderMessages}
          />
          <Route
            path="/admin/incomes-payments-bills"
            component={AdminIncomePaymentsBills}
          />
          <Route path="/admin/messages/" exact component={AdminMessages} />
        </>
      )}

      <div
        className="absolute left-0 "
        style={{ zIndex: -1, marginTop: " -140px" }}
      >
        <img src={circleImage.default} />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loginInfo: state.loginDetail.loggedUserDetail,
  };
};

export default connect(mapStateToProps, 0)(Admin);
