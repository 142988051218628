import { Button, Input, Modal } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { AiFillStar } from "react-icons/ai";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ModalComponent } from "../../components";
import { Errors, Success } from "../../components/antdToast";
import ButtonComponent from "../../components/ButtonComponent";
import InputComponent from "../../components/InputComponent";
import {
  getServiceProviderAndWorkerProfile,
  ProviderChangePassword,
  ProviderSendSupportMessage,
} from "../../config/api";
import { providerLabels } from "../../config/label/providerLabels";
import { Images } from "../../constant/Images";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { ProviderLogoutAction } from "../../Redux/actions/logoutAction";
import "./profile.css";

import { DeleteOutlined } from "@ant-design/icons";
import InformationContainer from "../../components/informationContainer";
import { UploadFileMethod } from "../../config/api";
import { validatePassword } from "../../config/utils";
function Profile(props) {
  const { goBack } = useHistory();
  const [isModal, setIsModal] = useState(false);
  const [isSupportModal, setIsSupportModal] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [isConfirmedPassword, setIsConfirmedPassword] = useState(false);
  const [supportSubject, setSupportSubject] = useState("");
  const [supportMessage, setSupportMessage] = useState("");
  const [fieldIsRequired, setFieldIsrequired] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [modal_Content, setModalContent] = useState("");
  const [multipleDocument, setMultipleDocument] = useState("");
  const [document, setDocument] = useState("");
  const [urls, setUrls] = useState([]);
  const [myProfile, setMyProfile] = useState(null);
  const [currentSelectedLangue, setCurrentSelectedLangue] = useState("spanish");

  useEffect(() => {
    setCurrentSelectedLangue(localStorage.getItem("primaryLanguage"));
    fetchProfile();
  }, []);
  const fetchProfile = () => {
    getServiceProviderAndWorkerProfile(
      props?.providerDetail?.admin?.service_provider_id,
      (response) => {
        setMyProfile(response?.data?.response?.detail?.user);
      },
      (error) => {
      }
    );
  };

  const onpenModal = () => {
    setIsModal(true);
  };
  const onpenSupportModal = () => {
    setIsSupportModal(true);
  };
  const handleConfirmedPassword = (e) => {
    setConfirmPassword(e);
    if (e === newPassword) {
      setIsConfirmedPassword(true);
    } else {
      setIsConfirmedPassword(false);
    }
  };
  const changeProviderPassword = () => {
    if (
      newPassword?.trim()?.length !== 0 &&
      oldPassword?.trim()?.length !== 0 &&
      validatePassword(newPassword) &&
      isConfirmedPassword === true
    ) {
      let obj = {
        old_password: oldPassword,
        new_password: confirmPassword,
      };
      props.setLoaderState(true);
      ProviderChangePassword(
        obj,
        (success) => {
          if (success?.status === 200) {
            Success(success?.data?.response?.message);
            props.setLoaderState(false);
            setNewPassword("");
            setOldPassword("");
            setConfirmPassword("");
            setIsConfirmedPassword(false);
            setIsModal(false);
            setFieldIsrequired(false);
          }
        },
        (error) => {
          if (error?.response?.status === 422) {
            Errors(error?.response.data.error.message);

            props.setLoaderState(false);
          } else {
            Errors("Network Error");
            props.setLoaderState(false);
          }
        }
      );
    } else {
      setFieldIsrequired(true);
    }
  };
  const supportModalHandler = () => {
    if (supportSubject !== "" && supportMessage !== "") {
      let supportObj = {
        title: supportSubject,
        message: supportMessage,
        provider_id: props.providerDetail.admin.service_provider_id,
        attachments: JSON.stringify(urls),
      };
      props.setLoaderState(true);
      ProviderSendSupportMessage(
        supportObj,
        (success) => {
          if (success?.status === 200) {
            Success(success?.data?.response?.message);
            props.setLoaderState(false);
            setSupportSubject("");
            setSupportMessage("");
            setIsSupportModal(false);
          }
        },
        (error) => {
          if (error?.response?.status === 422) {
            Errors(error?.response?.data?.message);
            props.setLoaderState(false);
          } else {
            Errors("Network Error");
            props.setLoaderState(false);
          }
        }
      );
    }
  };

  const getUrl = (url, name, type, id) => {
    setUrls((prevUrls) => [...prevUrls, { url, name, type, id }]);
  };

  const filePrev = (item, index) => {
    setUrls(urls?.filter((file) => file.id !== item.id));
  };

  const selectMultipleDocument = async (valueArray) => {
    let multipleFileNames = "";
    valueArray.map((r, i) => {
      multipleFileNames += `${r.name}, `;
    });
    valueArray?.map((file, i) => {
      let formData = new FormData();
      formData.append("file", file);
      UploadFileMethod(
        formData,
        (success) => {
          if (success.status === 200) {
            if (success.data.response.detail !== null) {
              let id = Math.round(Math.random() * 10000);
              getUrl(success.data.response.detail, file.name, file.type, id);
            }
          }
        },
        (err) => {
          if (err?.response?.status === 500) {
            Errors(err?.response?.data?.error?.message);
          } else if (err?.response?.status === 422) {
            Errors(err?.response?.data?.error?.message);
          } else {
            Errors("Network Error");
          }
        }
      );
    });
    setMultipleDocument(multipleFileNames);
  };
  return (
    <section>
      <div className="flex justify-between items-center mt-5 mb-10">
        <div className="pl-5 flex self-center justify-center items-center">
          <div
            onClick={() => goBack()}
            className="flex text-gray-400 items-center cursor-pointer"
          >
            <RiArrowDropLeftLine />
            <span>{providerLabels.back}</span>
          </div>
          <h3 className="ml-10	">{providerLabels.profile}</h3>
        </div>
      </div>
      <div className="container grid grid-cols-12 gap-3 px-2 sm:px-2 lg:px-0 lg:pl-20">
        <div className="col-span-12 sm:col-span-12 md:col-span-11 lg:col-span-11">
          <div className="bg-white container rounded-lg px-5 py-3">
            <div style={{ display: "flex" }} className="ml-4 py-2 w-full">
              <img
                className="rounded-3xl h-10 w-10"
                style={{ border: "1px solid #46BCFF" }}
                src={
                  (myProfile?.avatar ||
                    props?.providerDetail?.admin?.avatar) === null
                    ? Images?.profile?.default
                    : myProfile?.avatar || props?.providerDetail?.admin?.avatar
                }
              />
              <div className="pl-2">
                <span className="flex space-x-2">
                  <strong className=" ">
                    {myProfile?.name || props?.providerDetail?.admin?.name}
                  </strong>

                  {localStorage.getItem("type") === "service_provider" ? (
                    <div className="flex items-center ">
                      <span
                        className="text-sm mr-1"
                        style={{ color: "#808080" }}
                      >
                        &#40;
                      </span>
                      <p className="text-sm mb-0">
                        {(myProfile?.fake_rating ||
                          props?.providerDetail?.admin?.fake_rating) !== null
                          ? myProfile?.fake_rating ||
                            props?.providerDetail?.admin?.fake_rating
                          : myProfile?.rating ||
                            props?.providerDetail?.admin?.rating
                          ? myProfile?.rating ||
                            props?.providerDetail?.admin?.rating
                          : 0}
                      </p>
                      <AiFillStar
                        color={"#FFC107"}
                        style={{
                          marginRight: 5,
                          marginTop: 3,
                          fontSize: "13px",
                        }}
                      />
                      <span
                        className="text-sm mr-1"
                        style={{ color: "#808080" }}
                      >
                        &#41;
                      </span>
                    </div>
                  ) : (
                    <p className="">{}</p>
                  )}
                </span>
                <p>{myProfile?.email || props?.providerDetail?.admin?.email}</p>
              </div>
            </div>
            <div className="divide-y divide-gray-200 divide-solid">
              <div className="grid grid-cols-10 gap-2 divide-x divide-gray-200 divide-solid pb-3">
                <div className="col-span-5 sm:col-span-5 md:col-span-5 lg:col-span-2">
                  <label className="hh4 ml-4 ">
                    <b className="text-md">{providerLabels.phoneNo}</b>
                  </label>
                  <div className="flex ml-4">
                    <p className="text-sm mb-0">
                      {myProfile?.phone || props?.providerDetail?.admin?.phone}
                    </p>
                  </div>
                </div>
                {localStorage.getItem("type") === "service_provider" && (
                  <div className="col-span-5 sm:col-span-5 md:col-span-5 lg:col-span-2">
                    <label className="hh4 ml-4 ">
                      <b className="text-md">
                        {providerLabels.yearsOfExperience}
                      </b>
                    </label>
                    <div className="flex ml-4">
                      <p className="text-sm mb-0">
                        {myProfile?.experience ||
                          props?.providerDetail?.admin?.experience}
                      </p>
                    </div>
                  </div>
                )}
                {localStorage.getItem("type") === "service_provider" && (
                  <div className="col-span-5 sm:col-span-5 md:col-span-5 lg:col-span-2">
                    <label className="hh4 ml-4 ">
                      <b className="text-md">{providerLabels.address}</b>
                    </label>
                    <div className="flex ml-4">
                      <p className="text-sm mb-0">
                        {myProfile?.address ||
                          props?.providerDetail?.admin?.address}
                      </p>
                    </div>
                  </div>
                )}
                {localStorage.getItem("type") === "service_provider" && (
                  <>
                    <div className="col-span-5 sm:col-span-5 md:col-span-5 lg:col-span-2">
                      <label className="hh4 ml-4 ">
                        <b className="text-md">
                          {providerLabels.registeredDate}
                        </b>
                      </label>
                      <div className="flex ml-4 mt-1">
                        <p className="text-sm mb-0">
                          {moment(
                            myProfile?.created_at ||
                              props?.providerDetail?.admin?.created_at
                          ).format("DD-MMM-YYYY")}
                        </p>
                      </div>
                    </div>
                    <div className="col-span-5 sm:col-span-5 md:col-span-5 lg:col-span-2">
                      <label className="hh4 ml-4 ">
                        <b className="text-md capitalize">
                          {providerLabels.information}
                        </b>
                      </label>
                      <div className="flex ml-4 mt-1">
                        <Button
                          size={"small"}
                          shape={"round"}
                          className="icon-button"
                          onClick={() => {
                            setShowInfoModal(true);
                            currentSelectedLangue === "english"
                              ? setModalContent(
                                  myProfile?.info ||
                                    props?.providerDetail?.admin?.info ||
                                    ""
                                )
                              : setModalContent(
                                  myProfile?.info_sp ||
                                    props?.providerDetail?.admin?.info_sp ||
                                    ""
                                );
                            // setModalContent(myProfile?.info || props?.providerDetail?.admin?.info);
                          }}
                        >
                          {providerLabels.infoBox}
                        </Button>
                      </div>
                    </div>
                  </>
                )}
                {localStorage.getItem("type") === "co_worker" && (
                  <div className="col-span-5 sm:col-span-5 md:col-span-5 lg:col-span-2">
                    <label className="hh4 ml-4 ">
                      <b className="text-md">{providerLabels.registeredDate}</b>
                    </label>
                    <div className="flex ml-4 mt-1">
                      <p className="text-sm mb-0">
                        {moment(
                          myProfile?.created_at ||
                            props?.providerDetail?.admin?.created_at
                        ).format("DD-MMM-YYYY")}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {localStorage.getItem("type") === "service_provider" && (
                <div className="grid grid-cols-10 gap-2 divide-x divide-gray-200 divide-solid pt-3">
                  <div className="col-span-5 sm:col-span-5 md:col-span-5 lg:col-span-2">
                    <label className="hh4 ml-4 ">
                      <b className="text-md">
                        {providerLabels.positionAndDesignation}
                      </b>
                    </label>
                    <div className="flex ml-4 mt-1">
                      <p className="text-sm mb-0">
                        {myProfile?.position ||
                          props?.providerDetail?.admin?.position}
                      </p>
                    </div>
                  </div>

                  {/* <div className="col-span-5 sm:col-span-5 md:col-span-5 lg:col-span-2">
                    <label className="hh4 ml-4 ">
                      <b className="text-md">{providerLabels.workingHours}</b>
                    </label>
                    <div className="flex px-4 mt-1">
                      <div
                        className={` flex flex-col items-start text-xs w-full ${
                          (
                            myProfile?.working_hours.length ||
                            props?.providerDetail?.admin?.working_hours
                          ).length === 1
                            ? `py-2`
                            : `py-0`
                        }`}
                      >
                        {" "}
                        {(
                          myProfile?.working_hours ||
                          props?.providerDetail?.admin?.working_hours
                        )?.map((hour, index) => {
                          return hour?.isFriday === false ? (
                            <div
                              className=" w-full flex justify-between"
                              key={index}
                            >
                              <span className="w-1/2">
                                {providerLabels.monToThu} : {hour?.start} -{" "}
                                {hour?.end}
                              </span>
                              {hour?.hasOwnProperty("monTHuLunchHours") &&
                                hour?.monTHuLunchHours !== null && (
                                  <span className=" w-1/2 text-right">
                                    {providerLabels.lunch} :{" "}
                                    {hour?.monTHuLunchHours?.start} -{" "}
                                    {hour?.monTHuLunchHours?.end}
                                  </span>
                                )}
                            </div>
                          ) : (
                            <div
                              className="w-full flex justify-between"
                              key={index}
                            >
                              <span className="w-1/2">
                                {providerLabels.fri} : {hour?.start} -{" "}
                                {hour?.end}
                              </span>
                              {hour?.hasOwnProperty("fridayLunchHours") &&
                                hour?.fridayLunchHours !== null && (
                                  <span className=" w-1/2 text-right">
                                    {providerLabels.lunch} :{" "}
                                    {hour?.fridayLunchHours?.start} -{" "}
                                    {hour?.fridayLunchHours?.end}
                                  </span>
                                )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div> */}
                  <div className="col-span-5 sm:col-span-5 md:col-span-5 lg:col-span-4">
                    <label className="hh4 ml-4 ">
                      <b className="text-md">{providerLabels.states}</b>
                    </label>
                    <div className="flex ml-4 mt-1">
                      <p className="text-sm mb-0">
                        {(
                          myProfile?.state ||
                          props?.providerDetail?.admin?.state
                        ).join(",  ")}
                      </p>
                    </div>
                  </div>
                  <div className="col-span-5 sm:col-span-5 md:col-span-3 lg:col-span-3">
                    <label className="hh4 ml-4 ">
                      <b className="text-md">{providerLabels.workingHours}</b>
                    </label>
                    <div className="flex px-4 mt-1">
                      <div
                        className={` flex flex-col items-start text-xs w-full ${
                          (
                            myProfile?.working_hours.length ||
                            props?.providerDetail?.admin?.working_hours
                          ).length === 1
                            ? `py-2`
                            : `py-0`
                        }`}
                      >
                        {" "}
                        {(
                          myProfile?.working_hours ||
                          props?.providerDetail?.admin?.working_hours
                        )?.map((hour, index) => {
                          return hour?.isFriday === false ? (
                            <div
                              className=" w-full flex justify-between"
                              key={index}
                            >
                              <span className="w-1/2">
                                {providerLabels.monToThu} : {hour?.start} -{" "}
                                {hour?.end}
                              </span>
                              {hour?.hasOwnProperty("monTHuLunchHours") &&
                                hour?.monTHuLunchHours !== null && (
                                  <span className=" w-1/2 text-right">
                                    {providerLabels.lunch} :{" "}
                                    {hour?.monTHuLunchHours?.start} -{" "}
                                    {hour?.monTHuLunchHours?.end}
                                  </span>
                                )}
                            </div>
                          ) : (
                            <div
                              className="w-full flex justify-between"
                              key={index}
                            >
                              <span className="w-1/2">
                                {providerLabels.fri} : {hour?.start} -{" "}
                                {hour?.end}
                              </span>
                              {hour?.hasOwnProperty("fridayLunchHours") &&
                                hour?.fridayLunchHours !== null && (
                                  <span className=" w-1/2 text-right">
                                    {providerLabels.lunch} :{" "}
                                    {hour?.fridayLunchHours?.start} -{" "}
                                    {hour?.fridayLunchHours?.end}
                                  </span>
                                )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {localStorage.getItem("type") === "co_worker" && (
                <div className="grid grid-cols-10 gap-2 divide-x divide-gray-200 divide-solid pt-3">
                  <div className="col-span-5 sm:col-span-5 md:col-span-5 lg:col-span-2">
                    <label className="hh4 ml-4 ">
                      <b className="text-md">{providerLabels.parentProvider}</b>
                    </label>
                    <div className="flex ml-4">
                      <p className="text-sm mb-0 capitalize">
                        {myProfile?.name ||
                          props?.providerDetail?.admin?.provider?.name}
                      </p>
                    </div>
                  </div>

                  {/* <div className="col-span-5 sm:col-span-5 md:col-span-5 lg:col-span-2">
                    <label className="hh4 ml-4 ">
                      <b className="text-md">{providerLabels.workingHours}</b>
                    </label>
                    <div className="flex px-4 mt-1">
                      <div
                        className={` flex flex-col items-start text-xs w-full ${
                          (
                            myProfile?.working_hours ||
                            props?.providerDetail?.admin?.provider
                              ?.working_hours
                          ).length === 1
                            ? `py-2`
                            : `py-0`
                        }`}
                      >
                        {" "}
                        {(
                          myProfile?.working_hours ||
                          props?.providerDetail?.admin?.provider?.working_hours
                        )?.map((hour, i) => {
                          return hour?.isFriday === false ? (
                            <div
                              className=" w-full flex justify-between"
                              key={i}
                            >
                              <span className="w-1/2">
                                {providerLabels.monToThu} : {hour?.start} -{" "}
                                {hour?.end}
                              </span>
                              {hour?.hasOwnProperty("monTHuLunchHours") &&
                                hour?.monTHuLunchHours !== null && (
                                  <span className=" w-1/2 text-right">
                                    {providerLabels.lunch} :{" "}
                                    {hour?.monTHuLunchHours?.start} -{" "}
                                    {hour?.monTHuLunchHours?.end}
                                  </span>
                                )}
                            </div>
                          ) : (
                            <div
                              className="w-full flex justify-between"
                              key={i}
                            >
                              <span className="w-1/2">
                                {providerLabels.fri} : {hour?.start} -{" "}
                                {hour?.end}
                              </span>
                              {hour?.hasOwnProperty("fridayLunchHours") &&
                                hour?.fridayLunchHours !== null && (
                                  <span className=" w-1/2 text-right">
                                    {providerLabels.lunch} :{" "}
                                    {hour?.fridayLunchHours?.start} -{" "}
                                    {hour?.fridayLunchHours?.end}
                                  </span>
                                )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div> */}

                  <div className="col-span-5 sm:col-span-5 md:col-span-5 lg:col-span-4">
                    <label className="hh4 ml-4 ">
                      <b className="text-md">{providerLabels.states}</b>
                    </label>
                    <div className="flex ml-4 mt-1">
                      <p className="text-sm mb-0">
                        {(
                          myProfile?.state ||
                          props?.providerDetail?.admin?.provider?.state
                        ).join(", ")}
                      </p>
                    </div>
                  </div>
                  <div className="col-span-5 sm:col-span-5 md:col-span-5 lg:col-span-3">
                    <label className="hh4 ml-4 ">
                      <b className="text-md">{providerLabels.workingHours}</b>
                    </label>
                    <div className="flex px-4 mt-1">
                      <div
                        className={` flex flex-col items-start text-xs w-full ${
                          (
                            myProfile?.working_hours ||
                            props?.providerDetail?.admin?.provider
                              ?.working_hours
                          ).length === 1
                            ? `py-2`
                            : `py-0`
                        }`}
                      >
                        {" "}
                        {(
                          myProfile?.working_hours ||
                          props?.providerDetail?.admin?.provider?.working_hours
                        )?.map((hour, i) => {
                          return hour?.isFriday === false ? (
                            <div
                              className=" w-full flex justify-between"
                              key={i}
                            >
                              <span className="w-1/2">
                                {providerLabels.monToThu} : {hour?.start} -{" "}
                                {hour?.end}
                              </span>
                              {hour?.hasOwnProperty("monTHuLunchHours") &&
                                hour?.monTHuLunchHours !== null && (
                                  <span className=" w-1/2 text-right">
                                    {providerLabels.lunch} :{" "}
                                    {hour?.monTHuLunchHours?.start} -{" "}
                                    {hour?.monTHuLunchHours?.end}
                                  </span>
                                )}
                            </div>
                          ) : (
                            <div
                              className="w-full flex justify-between"
                              key={i}
                            >
                              <span className="w-1/2">
                                {providerLabels.fri} : {hour?.start} -{" "}
                                {hour?.end}
                              </span>
                              {hour?.hasOwnProperty("fridayLunchHours") &&
                                hour?.fridayLunchHours !== null && (
                                  <span className=" w-1/2 text-right">
                                    {providerLabels.lunch} :{" "}
                                    {hour?.fridayLunchHours?.start} -{" "}
                                    {hour?.fridayLunchHours?.end}
                                  </span>
                                )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4 px-2 sm:px-2 lg:px-0  lg:pl-20 mt-5">
        <div className="col-span-6 sm:col-span-6 md:col-span-5 lg:col-span-3">
          {localStorage.getItem("type") === "service_provider" ? (
            <ButtonComponent
              onClick={onpenSupportModal}
              title={providerLabels.contactTechnicalSupport}
            />
          ) : (
            <a
              href={`mailto:${
                myProfile?.email ||
                props?.providerDetail?.admin?.provider?.email
              }`}
            >
              <ButtonComponent title={providerLabels.contactCentral} />
            </a>
          )}
        </div>

        <div className="col-span-6 sm:col-span-6 md:col-span-6 lg:col-span-3">
          <ButtonComponent
            title={providerLabels.changePassword}
            onClick={onpenModal}
          />
        </div>
      </div>
      <ModalComponent
        filterModal={isModal}
        closeModal={() => setIsModal(false)}
        modalTitle={providerLabels.changePassword}
      >
        <div className="w-full">
          <div className="grid grid-cols-2 ">
            <div className="w-full col-span-12">
              <InputComponent
                type="password"
                placeholder={providerLabels.enterOldPassword}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                classNames={`${
                  fieldIsRequired && oldPassword.trim()?.length === 0
                    ? "border border-red-400 rounded-3xl"
                    : ""
                } `}
              />
              {fieldIsRequired && oldPassword.trim()?.length === 0 ? (
                <p className="text-sm text-red-500 text-center">
                  {providerLabels.oldPasswordRequired}
                </p>
              ) : null}
            </div>
            <div className="w-full col-span-12">
              <InputComponent
                type="password"
                placeholder={providerLabels.enterNewPassword}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                classNames={`${
                  fieldIsRequired && newPassword.trim()?.length === 0
                    ? "border border-red-400 rounded-3xl"
                    : ""
                } `}
              />

              {newPassword.trim()?.length !== 0 &&
                !validatePassword(newPassword) && (
                  <p className="text-sm text-red-500 ">
                    {providerLabels.passwordValidationText}
                  </p>
                )}
              {fieldIsRequired && newPassword.trim()?.length === 0 ? (
                <p className="text-sm text-red-500 text-center">
                  {providerLabels.newPasswordRequired}
                </p>
              ) : null}
            </div>
            <div className="w-full col-span-12">
              <InputComponent
                type="password"
                placeholder={providerLabels.enterConfirmPassword}
                value={confirmPassword}
                onChange={(e) => handleConfirmedPassword(e.target.value)}
                classNames={`${
                  fieldIsRequired && confirmPassword.trim()?.length === 0
                    ? "border border-red-400 rounded-3xl"
                    : fieldIsRequired && !isConfirmedPassword
                    ? "border border-red-400 rounded-3xl"
                    : ""
                } `}
              />
              {fieldIsRequired && confirmPassword.trim()?.length === 0 ? (
                <p className="text-sm text-red-500 text-center">
                  {providerLabels.confirmPasswordRequired}
                </p>
              ) : null}
              {fieldIsRequired &&
              confirmPassword.trim()?.length > 0 &&
              !isConfirmedPassword ? (
                <p className="text-sm text-red-500 text-center">
                  {providerLabels.confirmPasswordMismatch}
                </p>
              ) : null}
            </div>
          </div>
          <div className="w-full col-span-12">
            <ButtonComponent
              title={providerLabels.update}
              onClick={changeProviderPassword}
              loader={props.loader ? true : false}
              disabled={props.loader ? true : false}
            />
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        filterModal={isSupportModal}
        closeModal={() => setIsSupportModal(false)}
        modalTitle={
          localStorage.getItem("type") === "service_provider"
            ? providerLabels.support
            : providerLabels.contactCentral
        }
      >
        <div className="w-full">
          <div className="grid grid-cols-2 ">
            <div className="w-full col-span-12">
              <InputComponent
                placeholder={providerLabels.subject}
                value={supportSubject}
                onChange={(e) => setSupportSubject(e.target.value)}
              />
            </div>
            <div className="w-full col-span-12  mb-2 mt-2 support-text-area">
              <Input.TextArea
                value={supportMessage}
                onChange={(e) => setSupportMessage(e.target.value)}
                placeholder={providerLabels.message}
                className={`rounded-lg py-3 px-4 w-full`}
                rows={4}
              ></Input.TextArea>
            </div>
          </div>
          {localStorage.getItem("type") === "service_provider" && (
            <div className="mb-6">
              <div
                className="file btn btn-lg   btn-primary text-left uploadDiv "
                style={{ width: "120px", height: "32px" }}
              >
                <span className="btn bg-primary text-white py-2 rounded-3xl cursor-pointer">
                  {document.length > 0
                    ? providerLabels.fileUploaded
                    : providerLabels.uploadFiles}

                  <input
                    type="file"
                    className="uploadInput cursor-pointer"
                    multiple
                    accept=".jpg, .jpeg, .png, .doc, .docx, .docs, .pdf, .xls, .csv, .xslx , .docx , .xlsx"
                    onChange={(e) => {
                      let f = e.target.files;
                      let fileArray = [];
                      for (var i = 0; i < f.length; i++) {
                        fileArray.push(f[i]);
                      }
                      selectMultipleDocument(fileArray);
                    }}
                  />
                </span>
              </div>
              {urls.length > 0 && (
                <div className="pt-2 flex justify-between flex-wrap">
                  {urls?.map((item, index) => {
                    return (
                      <div className="flex justify-between px-1  w-28">
                        <div>
                          {item?.name.substring(0, 5)}
                          {item?.name?.length > 5 &&
                            "... " + item?.name?.split(".")[1]}{" "}
                        </div>
                        <div
                          className="cursor-pointer	"
                          onClick={() => filePrev(item, index)}
                        >
                          {" "}
                          <DeleteOutlined
                            className="cursor-pointer	"
                            style={{ color: "red" }}
                          />{" "}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}

          <div className="w-full col-span-12">
            <ButtonComponent
              title={providerLabels.update}
              onClick={supportModalHandler}
              loader={props.loader ? true : false}
              disabled={props.loader ? true : false}
            />
          </div>
        </div>
      </ModalComponent>
      <Modal
        visible={showInfoModal}
        footer={null}
        centered
        className="global-modal-css-scroll-height "
        onCancel={() => {
          setShowInfoModal(false);
        }}
      >
        <div>
          <h4 className="font-bold text-2xl text-center text-gray-600	">
            {providerLabels.information}
          </h4>
        </div>
        <div className="scrollable-fixed-height py-5 render-html-class">
          <InformationContainer info={modal_Content} />
        </div>
      </Modal>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    providerDetail: state.providerLoginDetail.loggedUserDetailOfProvider,
    loader: state.loaderToggle.loaderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProviderLogout: () => {
      dispatch(ProviderLogoutAction());
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
