import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import AdminLogin from "./screens/admin/admin";
import forgot from "./screens/forgot";
import NewPass from "./screens/newPass";
import Otp from "./screens/otp";
import Admin from "./service/Admin";
import AdminServiceProviderSecrion from "./service/Provider";
import Worker from "./service/Worker";
import { SessionContext } from "./store";
// import { store, PersistedStore } from "./Redux/store/store";
import { connect } from "react-redux";
import ServiceProviderLogin from "./screens/provider";
// import { PersistGate } from "redux-persist/integration/react";

function App(props) {
  const [session, setSession] = useState({});
  useEffect(() => {
    setSession({ username: props?.loginInfo?.type });
    
  }, []);
  return (
    <Router>
      <SessionContext.Provider value={{ session, setSession }}>
        <Switch>
          <Route path={"/admin"} exact component={AdminLogin} />
          <Route path={"/"} exact component={ServiceProviderLogin} />

          <Route path="/forgot" component={forgot} />
          <Route path={"/new-Password"} component={NewPass} />
          <Route path={"/verfication-otp"} component={Otp} />
          {props?.loginInfo?.type === "admin" && (
            <Route path={"/admin"} component={Admin} />
          )}
          {props.providerLoginInfo.type === "service_provider" && (
            <Route path={"/"} component={AdminServiceProviderSecrion} />
          )}
          {props.providerLoginInfo.type === "co_worker" && (
            <Route path={"/worker"} component={Worker} />
          )}
        </Switch>
      </SessionContext.Provider>
    </Router>
  );
}
const mapStateToProps = (state) => {
  return {
    loginInfo: state.loginDetail.loggedUserDetail,
    providerLoginInfo: state.providerLoginDetail.loggedUserDetailOfProvider,
  };
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     // getLoginInfo: (data) => {
//     //   dispatch(SetLoginDataInAction(data));
//     // },
//   };
// };
export default connect(mapStateToProps, 0)(App);
