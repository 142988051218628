import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiOutlineArrowDown } from "react-icons/ai";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { Link, useHistory, useParams } from "react-router-dom";
import { TableComponent } from "../../components";
import { Errors } from "../../components/antdToast";
import { GetServiceWorkerDetails } from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { europeanCurrencyFormatter } from "../../config/utils";
import "./serviceProviderDetails.css";
import UserDetailContainerCard from "./UserDetailContainerCard";

function WorkDetail() {
  const [active, setActive] = useState(false);
  const { push, goBack } = useHistory();
  const [loading, setLoading] = useState(true);
  const [provider, setProvider] = useState({});
  const [providerServices, setProviderServices] = useState([]);
  const [providerOrders, setProviderOrders] = useState([]);
  const { workerID } = useParams();
  const [currentSelectedLangue, setCurrentSelectedLangue] = useState("spanish");

  useEffect(() => {
    setCurrentSelectedLangue(localStorage.getItem("primaryLanguage"));
    fetchServiceWorkerDetail(workerID);
  }, []);
  const fetchServiceWorkerDetail = (id) => {
    GetServiceWorkerDetails(
      id,
      (res) => {
        let providerDetail = res?.data?.response?.detail?.user;
        let providerServices = res?.data?.response?.detail?.services;
        let providerOrders = res?.data?.response?.detail?.orders
          ?.reverse()
          .map((providerOrder, index) => {
            return {
              ...providerOrder,
              no: index + 1,
              product: providerOrder.service,
              orderID: providerOrder.order_id,
              date: moment(providerOrder.created_at).format("DD-MMM-YYYY HH:mm"),

              price: Math.round(
                +providerOrder?.after_discount_amount +
                  +providerOrder?.supplementary_fees
              ),
            };
          });

        setProvider(providerDetail);
        setProviderServices(providerServices);
        setProviderOrders(providerOrders);
        setLoading(false);
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  const UpdateServiceWorkerData = () => {
    let updatedObject = { ...provider, status: provider.status === 1 ? 0 : 1 };
    setProvider(updatedObject);
    fetchServiceWorkerDetail(workerID);
  };
  const columns = [
    {
      align: "center",
      title: adminLabels.no,
      dataIndex: "no",
      key: "no",
      width: 40,
      render: (text) => <a>{text}</a>,
    },
    {
      align: "center",
      title: adminLabels.product,
      dataIndex: "product",
      key: "product",
      width: 300,
      render: (text, index) => (
        <div className={"flex"}>
          <img
            src={text.avatar}
            style={{ width: 50, height: 50 }}
            className="rounded-lg"
            alt="detail image"
          />
          <div className="pl-2 text-left">
            <label>
              <b>{text?.[currentSelectedLangue === "english" ? "name" : "name_sp"]}</b>
            </label>{" "}
            <br />
            <span style={{ color: "blue" }}>{text.desc}</span>
          </div>
        </div>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.product?.[currentSelectedLangue === "english" ? "name" : "name_sp"]?.toLowerCase()?.includes(value?.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      align: "center",
      title: adminLabels.orderId,
      dataIndex: "orderID",
      key: "orderID",
      width: 120,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.orderID.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      align: "center",
      title: adminLabels.date,
      key: "date",
      dataIndex: "date",
      width: 120,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: adminLabels.price,
      key: "price",
      dataIndex: "price",
      width: 100,
      render: (text, index) => (
        <div className="text-center flex justify-center">
          {text ? "€ " + europeanCurrencyFormatter(text) : ""}
          {/* {text?.after_discount_amount} {text?.supplementary_fees} */}
          {/* {Math.round((+text?.after_discount_amount ) + (+text?.supplementary_fees))} */}
          {/* {Math.round((+text?.after_discount_amount ) + (+text?.supplementary_fees)) ? " €" : ""} */}
        </div>
      ),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.price - b.price,
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: adminLabels.action,
      key: "action",
      width: 130,

      render: (text, index) => (
        <div className="text-center">
          <Link
            to={{
              pathname: `/admin/worker-service-order-details/${text.order_id}`,
              state: { orderDetails: text },
            }}
            params={{ order_id: text.order_id }}
          >
            <Button
              // onClick={() => push("/booking-management-order-details")}
              type="disable"
              shape={"round"}
              className="background-color-gray whiteTxt"
            >
              {adminLabels.details}
            </Button>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <section className="pb-20">
      <Spin tip={adminLabels.loading} spinning={loading}>
        <div className="gap-0 grid grid-cols-5 mt-5">
          <div className="col-span-2">
            <div className="flex self-center items-center sub-h3">
              <div
                onClick={() => goBack()}
                className="flex text-gray-400 items-center cursor-pointer ml-5"
              >
                <RiArrowDropLeftLine />
                <span>{adminLabels.back}</span>
              </div>
              <h3 className="ml-10	">{adminLabels.workerDetails}</h3>
            </div>
            <div className="container mx-auto pl-0.5 sm:pl-0.5 lg:pl-20 mt-5">
              <div className="  ">
                <UserDetailContainerCard
                  providerDetails={provider}
                  services={providerServices}
                  updateServiceWorkerData={UpdateServiceWorkerData}
                />
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div className="flex self-center items-center sub-h3">
              {/* <h3 className="ml-10	">Order</h3> */}
              <div className="flex justify-between w-full">
                <div>
                  <h3 className="ml-10	">{adminLabels.orders}</h3>
                </div>
                <div>
                  <CSVLink
                    data={providerOrders.map((item, index) => {
                      return {
                        ...item,
                        no: index + 1,
                        nameOfService: item?.product?.[currentSelectedLangue === "english" ?"name" :"name_sp"]
                      };
                    })}
                    headers={[
                      { label: adminLabels.no, key: "no" },
                      { label: adminLabels.product, key: "nameOfService" },
                      { label: adminLabels.orderId, key: "orderID" },
                      { label: adminLabels.date, key: "date" },
                      { label: adminLabels.price, key: "price" },
                    ]}
                    filename={`${provider?.name} ${adminLabels.orders}.csv`}
                  >
                    <Button
                      shape={"round"}
                      className="icon-button py-0 mr-5"
                      icon={<AiOutlineArrowDown color="#5ab3f0" />}
                    >
                      {adminLabels.exportCSV}
                    </Button>
                  </CSVLink>
                </div>
              </div>
            </div>
            <div className="container mx-auto pl-0.5 sm:pl-0.5 lg:pl-8 mt-5">
              <div className="  ">
                <div className="amin-of-service-provider-details-orders-table">
                  <TableComponent
                    columns={columns}
                    data={providerOrders}
                    scrolly="320px"
                    pagination={{ defaultPageSize: 3 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
      {/* <div className="mt-20 grid grid-cols-6 sm:grid-cols-6 lg:grid-cols-5 gap-3">
        <div className=" px-2 sm:px-2  lg:pl-5 self-center items-center col-span-6 sm:col-span-6 md:col-span-6 lg:col-span-2 -mt-20">
          <div className="flex self-center items-center">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>Back</span>
            </div>
            <h3 className="ml-10">Work Detail</h3>
          </div>
          <div className="container mx-auto lg:pl-20 mt-5">
            <div className=" mt-12 ">
              <div className="">
                <TableTwo
                  names="Shoaib Tahir"
                  job="Software Developer"
                  table={table}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-6 sm:col-span-6 md:col-span-6 lg:col-span-3 lg:mr-10 pl-2 sm:pl-2 lg:pl-0">
          <div className="flex self-center flex-col items-start ml-16 sm:ml-16 lg-ml-0 mt-5 sm:mt-5 lg:mt-0">
            <div>
              <h3> Order</h3>
            </div>
          </div>
          <div className="mt-12">
            <TableComponent columns={columns} data={data} scrolly="500px" />
          </div>
        </div>
      </div> */}
    </section>
  );
}

export default WorkDetail;
