import { DownOutlined } from "@ant-design/icons";
import { Dropdown, List, Menu } from "antd";
import React from "react";
import { AiFillCalendar } from "react-icons/ai";
import { ImBin } from "react-icons/im";
import { MdEdit } from "react-icons/md";
import { adminLabels } from "../../config/label/adminLabels";
import { Images } from "../../constant/Images";
import CustomTooltip from "../toolTip";

const { business, other, checkboxActive, checkbox, uploadFile, uploadImg } =
  Images;
const language = localStorage.getItem("primaryLanguage");

export default function InputFeildsComp({
  data,
  value,
  onChange,
  placeholder,
  //   type = "text",
  onKeyPress = () => {},
  formIdentification,
  deleteField,
  editField,
  index,
}) {
  let checkProperty = data.hasOwnProperty("menu");
  const label = (data) => {
    return data[language === "english" ? "label" : "labelEsp"];
  };
  const descriptionInfo = (data) => {
    return data[language === "english" ? "description" : "descriptionEsp"];
  };
  const option = (data) => {
    return data[language === "english" ? "option" : "optionEsp"];
  };
  const menu = (
    <Menu className="menu-class menu-main-class rounded-3xl">
      {checkProperty === true &&
        data.menu.map((menu, index) => {
          return (
            <>
              <Menu.Item
                key={index}
                className=" rounded-lg px-2 bg-white "
                style={{
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                <div
                  className="flex justify-between bg-white py-3 w-full rounded-lg   items-center"
                  style={{ width: 160 }}
                >
                  <div>
                    <span className="text-gray-400 ">{option(menu)}</span>
                  </div>
                </div>
              </Menu.Item>
            </>
          );
        })}
    </Menu>
  );

  const radioButtonList = (
    <Menu className="menu-class menu-main-class" style={{ borderRadius: 20 }}>
      {checkProperty === true &&
        data.menu.map((menu, index) => {
          return (
            <Menu.Item key={index}>
              <div
                className="flex justify-between bg-white py-3 w-full  items-center"
                style={{ width: 160 }}
              >
                <div>
                  <span className="text-gray-400 ">{option(menu)}</span>
                </div>
              </div>
            </Menu.Item>
          );
        })}
    </Menu>
  );

  return (
    <div className="flex items-center ">
      <div className={`h-0 ${data.fieldId === 12 ? "hidden" : "block"}`}>
        <CustomTooltip description={descriptionInfo(data)} />
      </div>
      {/* {data.fieldId === 12 && (
        <div className={`h-0 `}>
          <button
            type="button"
            className="ant-btn ant-btn-icon-only text-white border-0 border-white"
            ant-click-animating-without-extra-node="false"
           
          >x</button>
        </div>
      )} */}

      <div style={{ width: "100%" }} className="text-left  m-2 rounded-lg">
        {data.fieldId === 1 && (
          <>
            <label className="text-gray-400 ">{label(data)}</label>
            <input
              className="rounded py-3 px-4 w-full text-area-outline border"
              type={"text"}
              onChange={onChange}
              onKeyPress={onKeyPress}
              value={value}
              placeholder={label(data) || placeholder}
            />
          </>
        )}
        {data.fieldId === 2 && (
          <>
            <label className="text-gray-400 ">{label(data)}</label>
            <textarea
              className="rounded py-3 px-4 w-full text-area-outline border "
              type={"text"}
              rows={4}
              onChange={onChange}
              onKeyPress={onKeyPress}
              value={value}
              placeholder={placeholder}
            />
          </>
        )}
        {data.fieldId === 3 && (
          <div calssName="w-full col-span-12 rounded-full lg:col-span-3 sm:col-span-6 md:col-span-6 ">
            <label className="text-gray-400 ">{label(data)}</label>
            <Dropdown
              className="rounded py-3 px-4 w-full text-area-outline border"
              overlay={menu}
              trigger={["click"]}
            >
              <a
                className="flex justify-between item-center ant-dropdown-link"
                style={{ color: "#000" }}
                onClick={(e) => e.preventDefault()}
              >
                <span className="text-gray-400 pl-1">
                  {/* {label(data)} */}
                </span>{" "}
                <DownOutlined
                  className="mt-2 "
                  style={{ color: "lightgray", fontWeight: "900" }}
                />
              </a>
            </Dropdown>
          </div>
        )}
        {data.fieldId === 4 && (
          <>
            <label className="text-gray-400 ">{label(data)}</label>
            <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 mt-2  relative cursor-pointer rounded py-3 px-4 w-full text-area-outline border">
              <input
                className="rounded-full py-3 px-9 w-full absolute opacity-0 input-style cursor-pointer "
                type={"file"}
              />
              <button
                style={{ cursor: "pointer" }}
                className="bg-white pb-4  h-12 rounded-full py-3 px-4 text-sm flex justify-center item-center  w-full"
              >
                {/* <RiImageAddLine color={"#fff"} /> */}
                <img
                  style={{ cursor: "pointer" }}
                  width="30"
                  height="30"
                  src={uploadFile.default}
                />
                <span
                  style={{ cursor: "pointer" }}
                  className="pl-1 text-gray-400"
                >
                  {adminLabels.uploadFiles}
                </span>
              </button>
            </div>
          </>
        )}
        {data.fieldId === 5 && (
          <div>
            <label className="text-gray-400 font-bold ml-5">
              {label(data)}
            </label>
            <div className="flex justify-start ml-10 mb-5">
              <label className="flex itemas-center">
                <img src={checkbox.default} className="mr-2 check-circle" />
                yes
              </label>
              <label className="flex itemas-center ml-10">
                <img
                  src={checkboxActive.default}
                  className="mr-2 check-circle"
                />
                No
              </label>
            </div>

            <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 mt-2 ml-2 relative cursor-pointer">
              <input
                className="rounded-full py-3 px-9 w-full absolute opacity-0 input-style cursor-pointer"
                type={"file"}
              />
              <button
                style={{ cursor: "pointer" }}
                className="bg-white pb-4  h-12 rounded-full py-3 px-4 text-sm flex justify-center item-center  w-full"
              >
                {/* <RiImageAddLine color={"#fff"} /> */}
                <img
                  style={{ cursor: "pointer" }}
                  width="20"
                  height="20"
                  src={uploadFile.default}
                />
                <span
                  style={{ cursor: "pointer" }}
                  className="pl-1 text-gray-400"
                >
                  Upload Multiple Files
                </span>
              </button>
            </div>
          </div>
        )}
        {data.fieldId === 6 && (
          <div>
            <label className="text-gray-400 font-bold ml-5">
              {label(data)}
            </label>
            <div className="m-2 col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 mt-2">
              <input
                className="rounded-full py-3   opacity-0  cursor-pointer"
                type={"file"}
              />
              <button className=" pb-4 bg-white rounded-full py-3 text-sm flex justify-center items-center w-full">
                <AiFillCalendar className="ml-2" color="#5ab3f0" size={25} />
                <span className="text-gray-400">Pickup</span>
              </button>
            </div>
            <input
              className="rounded-full py-3 px-4 w-full"
              type={"text"}
              onChange={onChange}
              onKeyPress={onKeyPress}
              value={value}
              placeholder={"Location"}
            />
          </div>
        )}
        {data.fieldId === 7 && (
          <div className="flex justify-around items-center mt-8">
            <label className="text-gray-400 ">{label(data)}</label>
            <div className="w-full">
              <input
                className="rounded py-3 px-4 w-full text-area-outline border"
                type={"text"}
                onChange={onChange}
                onKeyPress={onKeyPress}
                value={value}
                placeholder={"Hrs"}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        )}
        {data.fieldId === 8 && (
          <>
            {/* <div className="flex justify-around items-center mt-8"> */}
            <label className="text-gray-400">{label(data)}</label>
            <List
              size="small"
              className="rounded px-4 w-full text-area-outline border "
              bordered={false}
              dataSource={data.files}
              renderItem={(item) => (
                <List.Item
                  className=""
                  style={{ paddingTop: "1px", paddingBottom: "1px" }}
                >
                  <span className="ml-2">
                    {item?.name.substring(0, 35)}
                    {item?.name?.length > 35 &&
                      " .... " + item?.name?.split(".")[1]}
                  </span>
                </List.Item>
              )}
            />
            {/* <div className="flex justify-start items-center ">
                <label className="text-color-primary font-black ml-2">
                  <div className="w-full">
                    <div className="rounded py-3 px-4 w-full text-area-outline border">
                      <List
                        size="small"
                        className="rounded py-3 px-4 w-full text-area-outline border "
                        bordered={false}
                        dataSource={data.files}
                        renderItem={(item) => (
                          <List.Item>
                            <span className="ml-2">{item.name}</span>
                          </List.Item>
                        )}
                      />
                    </div>
                  </div>
                </label>
              </div> */}
            {/* </div> */}
          </>
        )}

        {data.fieldId === 9 && (
          <>
            <label className="text-gray-400 ">{label(data)}</label>

            <List
              size="small"
              className="rounded py-3 px-4 w-full text-area-outline border "
              bordered={false}
              dataSource={data.menu}
              renderItem={(item) => (
                <List.Item>
                  <input
                    className="justify:start"
                    type={"radio"}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    value={item.option}
                    name={label(data)}
                  />
                  <span className="ml-2">{option(item)}</span>
                </List.Item>
              )}
            />
          </>
        )}
        {data.fieldId === 10 && (
          <>
            <label className="text-gray-400 ">{label(data)}</label>

            <List
              size="small"
              className="rounded py-3 px-4 w-full text-area-outline border"
              bordered={false}
              dataSource={data.menu}
              renderItem={(item) => (
                <List.Item>
                  <input
                    className="justify:start"
                    type={"checkBox"}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    value={item.option}
                    name={label(data)}
                  />
                  <span className="ml-2">{option(item)}</span>
                </List.Item>
              )}
            />
          </>
        )}
        {data.fieldId === 11 && (
          <>
            <label className="text-gray-400 ">{label(data)}</label>
            <input
              className=" py-3 px-4 w-full border rounded"
              type={"date"}
              onChange={onChange}
              onKeyPress={onKeyPress}
              value={value}
              placeholder={label(data) || placeholder}
            />
          </>
        )}
        {data.fieldId === 12 && (
          <>
            {/* <label className="text-gray-400 ">{label(data)}</label> */}
            <div className=" py-3 px-4 w-full rounded">
              <p>{descriptionInfo(data)}</p>
            </div>
          </>
        )}
      </div>
      <div className="ml-2 h-0">
        <ImBin
          size={25}
          color={"red"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            deleteField(data?.fieldId, index);
          }}
        />
      </div>
      <div className="ml-2 h-0">
        <MdEdit
          size={25}
          style={{ cursor: "pointer" }}
          color={"#5fbdfe"}
          onClick={() => {
            editField(data?.fieldId, index);
          }}
        />
      </div>
    </div>
  );
}
