import React from "react";
import Cards from "../../components/Cards";

export default function AdminLogin(props) {
  return (
    <div className="bg-img">
      <Cards props={props} />
    </div>
  );
}
