import { SearchOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Menu, Spin, Switch, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiOutlineArrowDown } from "react-icons/ai";
import { RiArrowDropLeftLine, RiArrowDropRightLine } from "react-icons/ri";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Errors, Success } from "../../components/antdToast";
import TableComponent from "../../components/table";
import { GetAppUsers, UpdateUserStatusMethod } from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { Images } from "../../constant/Images";

const { searchBar } = Images;

const UserAccManagement = (props) => {
  const { goBack } = useHistory();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAppUsers();
    document.body.style.overflow = "auto";
    return function cleanup() {
      document.body.style.overflow = "auto";
    };
  }, []);
  const fetchAppUsers = () => {
    GetAppUsers(
      (res) => {
        let data = res?.data?.response?.detail
          ?.reverse()
          ?.map((item, index) => {
            return {
              ...item,
              no: index + 1,
              fullName: `${item?.first_name} ${item?.last_name}`,
              created_at: moment(item?.created_at).format("DD-MMM-YYYY"),
              phone: item?.phone_number,
            };
          });
        setUsers(data);
        setLoading(false);
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };

  const setUserActive = (id) => {
    let userId = new FormData();
    userId.append("user_id", id);
    UpdateUserStatusMethod(
      userId,
      (success) => {
        if (success.status === 200) {
          let userUpdatedArray = users?.map((item) => {
            if (item.user_id === id) {
              return { ...item, status: item.status === 1 ? 0 : 1 };
            } else {
              return item;
            }
          });
          setUsers(userUpdatedArray);
          Success(success?.data?.response?.message);
          fetchAppUsers();
        }
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors("Network Error");
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  const menu = (id, values) => (
    <Menu className="menu-class menu-main-class" style={{ borderRadius: 20 }}>
      <Menu.Item className="bg-white" style={{ borderRadius: 20 }}>
        <Link to={`/admin/user-acount-detail/${id}`} params={{ id }}>
          <div
            className="flex justify-between bg-white py-2 w-full  items-center"
            style={{ width: 160 }}
          >
            <div>
              <span>{adminLabels.userDetails}</span>
            </div>
            <div>
              <RiArrowDropRightLine color="#5ab3f0" size={20} />
            </div>
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item className="bg-white" style={{ borderRadius: 20 }} key={id}>
        <div
          className="flex justify-between bg-white py-2 w-full  items-center"
          style={{ width: 160 }}
        >
          <div>{adminLabels.userStatus}</div>
          <div>
            <Switch
              size="small"
              checked={values?.status === 1 ? true : false}
              onChange={() => setUserActive(values.user_id)}
            />
          </div>
        </div>
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: adminLabels.no,
      dataIndex: "no",
      key: "no",
      align: "center",
      width: 50,
      render: (text) => <a>{text}</a>,
    },
    {
      title: adminLabels.fullName,
      dataIndex: "fullName",
      key: "fullName",
      align: "center",
      width: 150,
      className: "capitalize",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.fullName.toLowerCase().includes(value.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      showSorterTooltip: false,
      render: (fullName) => (
        <Tooltip placement="topLeft" title={fullName}>
          {fullName}
        </Tooltip>
      ),
    },
    {
      title: adminLabels.email,
      dataIndex: "email",
      key: "email",
      align: "center",
      width: 180,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.email.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      render: (email) => (
        <Tooltip placement="topLeft" title={email}>
          {email}
        </Tooltip>
      ),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.email.localeCompare(b.email),
      showSorterTooltip: false,
    },
    {
      title: adminLabels.phone,
      key: "phone",
      dataIndex: "phone",
      align: "center",
      width: 100,
      render: (phone) => (
        <>
          {!phone && <span>N/A</span>}
          <Tooltip placement="topLeft" title={phone}>
            {phone}
          </Tooltip>
        </>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.phone?.toLowerCase().includes(value.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.phone - b.phone,
      showSorterTooltip: false,
    },
    {
      title: adminLabels.registeredDate,
      key: "created_at",
      dataIndex: "created_at",
      align: "center",
      width: 100,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
      showSorterTooltip: false,
      render: (created_at) => (
        <>
          <Tooltip placement="topLeft" title={created_at}>
            {created_at}
          </Tooltip>
        </>
      ),
    },

    {
      title: adminLabels.action,
      key: "action",
      width: 80,

      render: (text, index) => (
        <div className="text-center">
          <Dropdown
            //overlay={menu}
            overlay={() => menu(text.user_id, text)}
            className={"flex"}
            trigger={["click"]}
          >
            <div
              type="disable"
              shape={"round"}
              className="background-color-gray whiteTxt rounded-full py-1 justify-center cursor-pointer	"
            >
              <span>{adminLabels.view}</span>
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <section className="">
      <Spin tip={adminLabels.loading} spinning={loading}>
        <div className="flex justify-between mt-5">
          <div className="pl-5 flex self-center justify-center items-center sub1-h3">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{adminLabels.back}</span>
            </div>
            <h3 className="ml-10	">{adminLabels.userAccountManagement}</h3>
          </div>
          <div className=" mr-2 sm:mr-2 lg:mr-28 flex ">
            <div className="mr-2  ">
              <CSVLink
                data={users?.map((item, index) => {
                  return {
                    no: index + 1,
                    name: item?.fullName,
                    email: item?.email,
                    phone: item?.phone,
                    createdAt: item?.created_at,
                  };
                })}
                filename={`${adminLabels.userAccountManagement}.csv`}
                headers={[
                  { label: adminLabels.no, key: "no" },
                  { label: adminLabels.fullName, key: "name" },
                  { label: adminLabels.email, key: "email" },
                  { label: adminLabels.phone, key: "phone" },
                  {
                    label: adminLabels.registeredDate,
                    key: "createdAt",
                  },
                ]}
                target="_blank"
              >
                <Button
                  shape={"round"}
                  className="icon-button "
                  icon={<AiOutlineArrowDown color="#5ab3f0" />}
                >
                  {adminLabels.exportCSV}
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <div className="container mx-auto px-0.5  sm:px-0.5 lg:px-20 mt-5">
          <TableComponent
            columns={columns}
            data={users}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
            scrolly="500px"
          />
        </div>
      </Spin>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    showAppUsers: state.appUsersList.appUsers,
  };
};
export default connect(mapStateToProps, 0)(UserAccManagement);
