import axios from "axios";
import { EndPoints } from "../endpoints/index";
const userType = localStorage.getItem("type");
axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
export const getHeader = (type) => {
  let token = localStorage.getItem("token");
  if (type === 1) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
  } else if (type === 2) {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  } else if (type === 3) {
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
  }
};
export const AdminLogin = async (userObj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Admin_Login}`,
      {
        email: userObj.email,
        password: userObj.password,
      },
      getHeader(2)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error.response);
    }
  }
};

export const Provider_Login = async (userObj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Provider_Login}`,
      {
        email: userObj.email,
        password: userObj.password,
      },
      getHeader(2)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error.response);
    }
  }
};

export const AddServiceProvider = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Add_Service_Provider}`,
      obj,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const AddBeneficiary = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Add_beneficiary}`,
      obj,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const Add_Service_Area = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Add_Service_Area}`,
      obj,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const AddBranch = async (obj, success, failure) => {
  try {
    var res = await axios.post(`/${EndPoints.Add_Branch}`, obj, getHeader(1));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const Add_Branch_Service = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Add_Branch_Service}`,
      obj,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const AddServiceForm = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Add_Service_Form}`,
      obj,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

/////////// delete Dynamic Form Method
export const DeleteDynamicFormMethod = async (
  deleteFormServiceId,
  success,
  failure
) => {
  try {
    var res = await axios.delete(
      `/${EndPoints.Delete_Service_Form}/${deleteFormServiceId}`,
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const AddPromoCodeMethod = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Add_Promo_Code}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const AddFaqMethod = async (obj, success, failure) => {
  try {
    var res = await axios.post(`/${EndPoints.Add_Faq}`, obj, getHeader(3));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const AddWorker = async (obj, success, failure) => {
  try {
    var res = await axios.post(`/${EndPoints.Add_Worker}`, obj, getHeader(1));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const AssignOrderToWorker = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Assign_Order_To_Worker}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const ForgotPasswordApi = async (email, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Provider_Forgot_Password}`,
      { email: email },
      getHeader(3)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const ProviderVerifyCodeApi = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Provider_Verify_Code}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const ProviderResetPasswordApi = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Provider_Reset_Password}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const ProviderChangePassword = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Provider_Change_Password}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};
//provider support
export const ProviderSendSupportMessage = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Provider_Send_Support_Message}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const OrderReassignMethod = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Reassign_Order}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const OrderReassignToProviderMethod = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Order_Reassign_To_Provider}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetServiceProvider = async (success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_Service_Provider}`,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};
// get provider detail , orders and service by Id
export const GetServiceProviderDetails = async (id, success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_Service_Provider_Details}/${id}`,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetWorkers = async (success, failure) => {
  try {
    var res = await axios.get(`/${EndPoints.Get_Workers}`, getHeader(1));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetOrdersOfProvider = async (success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_Orders_Of_Provider}`,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetWorkersByCapacity = async (success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_Workers_Capacity}`,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetBeneficiaries = async (success, failure) => {
  try {
    var res = await axios.get(`/${EndPoints.Get_Beneficiaries}`, getHeader(1));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetServiceArea = async (success, failure) => {
  try {
    var res = await axios.get(`/${EndPoints.Get_Service_Area}`, getHeader(1));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetServiceAreaByBeneficiaryId = async (id, success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_Service_Area_By_Beneficiary_Id}/${id}`,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetBranches = async (success, failure) => {
  try {
    var res = await axios.get(`/${EndPoints.Get_Branches}`, getHeader(1));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetBranchesByServiceAreaId = async (id, success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_Branches_By_Service_Area_Id}/${id}`,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetBranchesByServiceAreaIdAndBeneficiary = async (
  id,
  Beneficiary_id,
  success,
  failure
) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_Branches_By_Service_Area_Id_And_Beneficiary}/${id}/${Beneficiary_id}`,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetBranchServices = async (success, failure) => {
  try {
    var res = await axios.get(`/${EndPoints.Get_Services}`, getHeader(1));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetServiceForms = async (success, failure) => {
  try {
    var res = await axios.get(`/${EndPoints.Get_Service_Forms}`, getHeader(1));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetAppUsers = async (success, failure) => {
  try {
    var res = await axios.get(`/${EndPoints.Get_App_Users}`, getHeader(1));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

// get user detail , orders and service by Id
export const GetAppUserDetails = async (id, success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_Get_Users_Detail}/${id}`,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};
export const GetPromoCodesMethod = async (success, failure) => {
  try {
    var res = await axios.get(`/${EndPoints.Get_Promo_Codes}`, getHeader(3));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const ChangePromoCodeStatus = async (id, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Change_Promo_Code_Status}`,
      id,
      getHeader(1)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};
export const GetFaqsMethod = async (success, failure) => {
  try {
    var res = await axios.get(`/${EndPoints.Get_Faqs}`, getHeader(3));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetCoWorkerOrders = async (success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_Co_Workers_Orders}`,
      getHeader(3)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetMessagesMethod = async (id, success, failure) => {
  try {
    var res = await axios.get(`/${EndPoints.Get_Messages}/${id}`, getHeader(3));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetOrdersById = async (id, success, failure) => {
  try {
    var res = await axios.get(`/${EndPoints.Get_Order}/${id}`, getHeader(3));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetAllOrdersMethod = async (success, failure) => {
  try {
    var res = await axios.get(`/${EndPoints.Get_All_Orders}`, getHeader(3));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetAllAdminMessagesMethod = async (success, failure) => {
  try {
    var res = await axios.get(`/${EndPoints.Get_All_Messages}`, getHeader(3));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const EditServiceArea = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Edit_Service_Area}`,
      obj,
      getHeader(1)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const EditBeneficiary = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Edit_Beneficiary}`,
      obj,
      getHeader(1)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const EditBranch = async (obj, success, failure) => {
  try {
    var res = await axios.post(`/${EndPoints.Edit_Branch}`, obj, getHeader(1));
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const EditService = async (obj, success, failure) => {
  try {
    var res = await axios.post(`/${EndPoints.Edit_Service}`, obj, getHeader(1));
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const EditServiceForms = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Edit_Service_Forms}`,
      obj,
      getHeader(1)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const EditFaqMethod = async (obj, success, failure) => {
  try {
    var res = await axios.post(`/${EndPoints.Edit_Faq}`, obj, getHeader(3));
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const DeleteFaqMethod = async (faq_id, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Delete_Faq}`,
      { faq_id: faq_id },
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const SendOrderMessageMethod = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Send_Order_Message}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const SendOrderBillMethod = async (obj, success, failure) => {
  try {
    var res = await axios.post(`/${EndPoints.Order_Bill}`, obj, getHeader(3));
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const SendAdditionalBudgetBillMethod = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Additional_Budget_Bill}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const UploadFileMethod = async (obj, success, failure) => {
  try {
    var res = await axios.post(`/${EndPoints.Upload_File}`, obj, getHeader(1));
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const uploadOrderStatusMethod = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Order_Status_Progress}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

/////////// delete branch service
export const DeleteBranchServiceMethod = async (
  deleteServiceID,
  success,
  failure
) => {
  try {
    var res = await axios.delete(
      `/${EndPoints.Delete_Service}/${deleteServiceID}`,
      // { deleteServiceID: deleteServiceID },
      // { id: deleteServiceID },
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

/////////// delete branch
export const DeleteBranchMethod = async (deleteBranchID, success, failure) => {
  try {
    var res = await axios.delete(
      `/${EndPoints.Delete_Branch}/${deleteBranchID}`,
      // { deleteServiceID: deleteServiceID },
      // { id: deleteServiceID },
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

/////////// get help
export const GetHelpMessagesMethod = async (success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_All_Help_Messages}`,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
  // try {
  //   var res = await axios.get(`/${EndPoints.Get_All_Help_Messages}`, getHeader(3));
  //   if (res) {
  //     success(res);
  //     return res;
  //   }
  // } catch (error) {
  //   if (error) {
  //     failure(error);
  //   }
  // }
};

// HelpAndWorkWithUsReplyMethod

export const HelpAndWorkWithUsReplyMethod = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${
        EndPoints?.[
          obj?.actionFor === "work" ? "Work_Us_Reply" : "Help_Message_Reply"
        ]
      }`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

// admin order read or reply
export const OrderReplyMethod = async (obj, success, failure) => {
  try {
    var res = await axios.post(`/${EndPoints?.Order_Reply}`, obj, getHeader(3));
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};
// Service Provider order read or reply
export const ServiceProviderOrderReplyMethod = async (
  obj,
  success,
  failure
) => {
  try {
    var res = await axios.post(
      `/${EndPoints?.Order_Reply_Provider}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const AdminAndServiceProviderAdditionalBudgetReadMethod = async (
  obj,

  success,
  failure
) => {
  try {
    var res = await axios.post(
      `/${EndPoints?.Additional_Budget_Read}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const ServiceWorkerOrderReplyMethod = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints?.Order_reply_worker}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetWorkwithUsMethod = async (success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_All_Work_With_Us}`,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

// get_provider_supports
export const GetProviderSupportMethod = async (success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_All_provider_Supports}`,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

// get get_all_workers
export const GetServiceWorkers = async (success, failure) => {
  try {
    var res = await axios.get(`/${EndPoints.Get_Service_Worker}`, getHeader(1));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};
// get provider detail , orders and service by Id
export const GetServiceWorkerDetails = async (id, success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_Service_Worker_Details}/${id}`,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

//  Add_Additonal_Budget API

export const AddAdditonalBudgetMethod = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Add_Additonal_Budget}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

// Update User status

export const UpdateUserStatusMethod = async (id, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Change_User_Status}`,
      id,
      getHeader(1)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};
// Update Service Provider status

export const UpdateServiceProviderStatusMethod = async (
  id,
  success,
  failure
) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Change_Service_Provider_Status}`,
      id,
      getHeader(1)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};
export const UpdateServiceProviderManagementCapacityStatus = async (
  id,
  success,
  failure
) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Change_Service_Provider_No_Management_Capacity_Status}`,
      id,
      getHeader(1)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

// get Admin Panel Data
export const GetAdminPanelDetails = async (start, end, success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_Admin_Dashboard_Data}?start_date=${start}&end_date=${end}`,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

// Get_Budget_Response

export const GetAdditonalBudget = async (success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_Additional_Budgets}`,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};
export const GetAdditionalBudgetDetails = async (id, success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_Additional_Budget_Detail}/${id}`,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

///////// get payouts
export const GetPayouts = async (success, failure) => {
  try {
    var res = await axios.get(`/${EndPoints.Get_Payouts}`, getHeader(1));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

// Payout Order Release

export const PayoutOrderReleaseMethod = async (id, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Order_Payout_Release}`,
      id,
      getHeader(1)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

// Payout Budget Release

export const PayoutBudgetReleaseMethod = async (id, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Budget_Payout_Release}`,
      id,
      getHeader(1)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

// Admin side Earnings

export const GetMyEarnings = async (success, failure) => {
  try {
    var res = await axios.get(`/${EndPoints.Get_Earnings}`, getHeader(1));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

///////// get sub Admins
export const GetsubAdmins = async (success, failure) => {
  try {
    var res = await axios.get(`/${EndPoints.Get_Sub_Admins}`, getHeader(1));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};
export const AddSubAdminMethod = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Add_Sub_Admin}`,
      obj,
      getHeader(1)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

///////// Update Service Status
export const UpdateServiceStatus = async (id, success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Change_Service_Status}/${id}`,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

///////// Update Branch Status
export const UpdateBranchStatus = async (id, success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Change_Branch_Status}/${id}`,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

/////////// edit service worker
export const EditServiceWorker = async (obj, success, failure) => {
  try {
    var res = await axios.post(`/${EndPoints.Edit_Worker}`, obj, getHeader(1));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

///////// get Service Provider Additional Budgets
export const ServiceProviderAdditionalBudgets = async (
  id,
  success,
  failure
) => {
  try {
    var res = await axios.get(
      `/${EndPoints?.[userType === "service_provider" ?  "Get_Provider_Additional_Budgets" : "Get_Worker_Additional_Budgets"]}/${id}`,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

/////////// edit service Provider
export const EditServiceProvider = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Edit_Service_Provider}`,
      obj,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

/////////// edit sub Admin
export const EditSubAdminMethod = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Edit_Sub_Admin}`,
      obj,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

// Get_Non_Assign_Service_Forms
export const GetNonAssignServiceForms = async (success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_Non_Assign_Service_Forms}`,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

// get notification by order id on provider message screen
export const GetOrderNotificationById = async (id, success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_Notifications_By_Order_Id}/${id}`,
      getHeader(3)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

//  Add_Additonal_Budget API

export const EditAdditonalBudgetMethod = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Edit_Additonal_Budget}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const SendDisputeOrderMessageMethod = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Dispute_Order_Message}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetDisputedMessagesMethod = async (id, success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_Dispute_Messages}/${id}`,
      getHeader(3)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const OrderDisputeContinueMethod = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Order_Dispute_Continue}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const AdditionalBudgetDisputeContinueMethod = async (
  obj,
  success,
  failure
) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Budget_Dispute_Continue}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const OrderDisputeCancelMethod = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Order_Dispute_Cancel}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const AdditionalBudgetDisputeCancelMethod = async (
  obj,
  success,
  failure
) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Budget_Dispute_Cancel}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const ServiceProviderBlockOrder = async (obj, success, failure) => {
  try {
    var res = await axios.post(`/${EndPoints.Order_Block}`, obj, getHeader(3));
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const ServiceProviderUnBlockOrder = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Order_UnBlock}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetOrderWorkingHours = async (obj, success, failure) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Get_Order_Working_Hours}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

//getServiceProviderAndWorkerProfile
export const getServiceProviderAndWorkerProfile = async (
  id,
  success,
  failure
) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_Provider_Detail_Info}/${id}`,
      getHeader(3)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

//getServiceProviderBillLogs
export const getServiceProviderBillLogs = async (id, success, failure) => {
  try {
    var res = await axios.get(
      `/${EndPoints.Get_Bill_Logs_Providers}/${id}`,
      getHeader(3)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

//getAdminBillLogs
export const getAdminBillLogs = async (success, failure) => {
  try {
    var res = await axios.get(`/${EndPoints.Get_Bill_Logs}`, getHeader(3));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const getAdminMenuCounts = async (success, failure) => {
  try {
    const res = await axios.get(`/${EndPoints.get_menu_counts}`, getHeader(3));
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const GetProviderMessages = async (success, failure) => {
  try {
    const userType = localStorage.getItem("type") === 'co_worker'
    var res = await axios.get(`/${EndPoints[userType ? "get_worker_provider_messages" : "get_provider_messages"]}`,
      getHeader(1)
    );
    if (res) {
      success(res);
      return res;
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};

export const UpdateServiceProviderOrderReviewStatusMethod = async (
  obj,
  success,
  failure
) => {
  try {
    var res = await axios.post(
      `/${EndPoints.Change_Review_Status}`,
      obj,
      getHeader(3)
    );
    if (res) {
      success(res);
    }
  } catch (error) {
    if (error) {
      failure(error);
    }
  }
};
