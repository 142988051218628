import { SearchOutlined } from "@ant-design/icons";
import { Badge, Button, DatePicker, Input, Spin } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiOutlineArrowDown } from "react-icons/ai";
import { FaCalendarAlt } from "react-icons/fa";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Errors } from "../../components/antdToast";
import { Remove_Data_Form_Provider_Reducers } from "../../components/dataRemoveOnProviderLogout";
import TableComponent from "../../components/table";
import { GetOrdersOfProvider } from "../../config/api";
import { providerLabels } from "../../config/label/providerLabels";
import {
  DeleteWorkerAtLogoutTime,
  FetchWorkersAction,
} from "../../Redux/actions/fetchWorkersAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { ProviderLogoutAction } from "../../Redux/actions/logoutAction";
import { fetchServiceProviderAllOrders } from "../../Redux/actions/serviceProviderActions/orders";

const ServiceProviderAllOrders = (props) => {
  const currentSelectedLangue = localStorage.getItem("primaryLanguage");
  const { goBack, push } = useHistory();

  const [serviceProviderOrders, setServiceProviderOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [disputeOrders, setDisputeOrders] = useState([]);
  const [disputedOrders, setDisputedOrders] = useState([]);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [blockedOrders, setBlockedOrders] = useState([]);
  const [index, setIndex] = useState(5);

  const [filterModal, setFilterModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orderTabs, setOrderTabs] = useState([
    { id: 5, title: providerLabels.all, readCount: 0 },
    {
      id: 0,
      title: providerLabels.completedOrders,
      readCount: 0,
    },
    {
      id: 1,
      title: providerLabels.pendingOrders,
      readCount: pendingOrders?.length,
    },
    {
      id: 2,
      title: providerLabels.allOrderDispute,
      readCount: 0,
    },
    {
      id: 3,
      title: providerLabels.disputedOrders,
      readCount: 0,
    },
    {
      id: 4,
      title: providerLabels.cancelledOrders,
      readCount: 0,
    },
    { id: 6, title: providerLabels.blocked, readCount: 0 },
  ]);
  const dispatch = useDispatch();
  const orders = useSelector(
    (state) => state?.serviceProviderAllOrders?.orders?.orders
  );
  const ordersLoading = useSelector(
    (state) => state?.serviceProviderAllOrders?.loading
  );
  useEffect(() => {
    setOrderTabs((prev) => {
      return prev?.map((item) => {
        switch (item?.id) {
          case 1: {
            return {
              ...item,
              readCount: pendingOrders?.filter(
                (item) => !item?.is_read_provider
              )?.length,
            };
          }
          case 2: {
            return {
              ...item,
              readCount: disputeOrders?.filter(
                (item) => !item?.is_read_provider
              )?.length,
            };
          }
          case 4: {
            return {
              ...item,
              readCount: cancelledOrders?.filter(
                (item) => !item?.is_read_provider
              )?.length,
            };
          }
          default:
            return item;
        }
      });
    });
  }, [pendingOrders, disputeOrders, cancelledOrders]);

  useEffect(() => {
    // getOrdersByProvider();
    dispatch(fetchServiceProviderAllOrders(push));
    document.body.style.overflow = "auto";
    return function cleanup() {
      document.body.style.overflow = "auto";
    };
  }, []);
  useEffect(() => {
    setServiceProviderOrders(
      orders?.map((item, index) => {
        let dates = moment(item.created_at).format("DD-MMM-YYYY HH:mm");
        const servicePackType = item?.service?.service_pack_type;

        return {
          ...item,
          no: index + 1,
          product: {
            ...item.service,
            name: item.service.name,
            avatar: item.service.avatar,
            info: item.service.info,
          },
          date: moment(item.created_at).format("DD-MMM-YYYY HH:mm"),
          orderId: item.order_id,
          date: moment(item.created_at).format("DD-MMM-YYYY HH:mm"),
          deliveryDate:
            servicePackType === "monthly"
              ? providerLabels[servicePackType]
              : moment(dates)
                  .add(item.service.time, item.service.time_type)
                  .local()
                  .format("DD-MMM-YYYY HH:mm"),
          estimatedTime:
            servicePackType === "monthly"
              ? providerLabels[servicePackType]
              : item.service.time === null && item.service.time_type === null
              ? "0"
              : `${item.service.time}  ${
                  Number(item.service.time) > 1
                    ? providerLabels[
                        item.service.time_type === "Hours"
                          ? item.service.time_type
                          : item.service.time_type?.toLowerCase() + "s"
                      ]
                    : providerLabels[
                        item.service.time_type === "Hours"
                          ? "hour"
                          : item.service.time_type?.toLowerCase()
                      ]
                }`,

          orderStatus: providerLabels?.[item?.status],
        };
      })
    );
    filteringDataAndsettingAState(orders, "completed", setCompletedOrders);
    filteringDataAndsettingAState(orders, "pending", setPendingOrders);
    filteringDataAndsettingAState(orders, "dispute", setDisputeOrders);
    filteringDataAndsettingAState(orders, "cancelled", setCancelledOrders);
    filteringDataAndsettingAState(orders, "disputed", setDisputedOrders);
    filteringDataAndsettingAState(orders, "block", setBlockedOrders);
  }, [orders]);
  useEffect(() => {
    setLoading(ordersLoading);
  }, [ordersLoading]);
  const changeIndex = (i) => {
    setIndex(i);
  };
  const ordersStatusColumns = [
    {
      align: "center",
      title: providerLabels.no,
      dataIndex: "no",
      key: "no",
      width: 40,
      render: (text) => <a>{text}</a>,
    },
    {
      align: "center",
      title: providerLabels.product,
      dataIndex: "product",
      key: "product",
      width: 180,
      render: (text, index) => (
        <div className={"flex"}>
          <img
            src={text?.avatar}
            style={{ width: 50, height: 45 }}
            alt="detail image"
          />
          <div className="pl-2 text-left">
            <label>
              <b>
                {
                  text?.[
                    currentSelectedLangue === "english" ? "name" : "name_sp"
                  ]
                }
              </b>
            </label>
          </div>
        </div>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]
          ?.toLowerCase()
          .includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a?.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]?.localeCompare(
          b?.product?.[currentSelectedLangue === "english" ? "name" : "name_sp"]
        ),
    },
    {
      align: "center",
      title: providerLabels.orderID,
      dataIndex: "orderId",
      key: "orderId",
      width: 100,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.orderId.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.orderId?.localeCompare(b?.orderId),
    },
    {
      align: "center",
      title: providerLabels.orderDate,
      key: "date",
      width: 150,
      dataIndex: "date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: providerLabels.deliveryDate,
      key: "deliveryDate",
      width: 100,
      dataIndex: "deliveryDate",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        moment(a.deliveryDate).unix() - moment(b.deliveryDate).unix(),
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: providerLabels.timeUntilDelivery,
      width: 100,
      dataIndex: "estimatedTime",
      key: "estimatedTime",
    },

    {
      align: "center",
      title: providerLabels.action,
      key: "action",
      width: 80,
      render: (text) => (
        <div className="text-center	gap-1 flex items-center justify-center">
          <div className="mb-1">
            {["pending", "dispute", "cancelled"]?.includes(text?.status) &&
            !text?.is_read_provider ? (
              <span className="font-bold text-red-500 text-4xl mr-1">.</span>
            ) : (
              <span className="font-bold font-color-blue text-4xl mr-1 opacity-0">
                .
              </span>
            )}
          </div>
          <Link to={`/order-info-detail/${text.order_id}`}>
            <Button
              onClick={() => setFilterModal(true)}
              type="disable"
              shape={"round"}
              className="background-color-gray whiteTxt"
            >
              {providerLabels.viewDetails}
            </Button>
          </Link>
        </div>
      ),
    },
  ];
  const allOrdersColumns = [
    {
      align: "center",
      title: providerLabels.no,
      dataIndex: "no",
      key: "no",
      width: 40,
      render: (text) => <a>{text}</a>,
    },
    {
      align: "center",
      title: providerLabels.product,
      dataIndex: "product",
      key: "product",
      width: 180,
      render: (text, index) => (
        <div className={"flex"}>
          <img
            src={text?.avatar}
            style={{ width: 50, height: 45 }}
            alt="detail image"
          />
          <div className="pl-2 text-left">
            <label>
              <b>
                {
                  text?.[
                    currentSelectedLangue === "english" ? "name" : "name_sp"
                  ]
                }
              </b>
            </label>
          </div>
        </div>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]
          ?.toLowerCase()
          .includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a?.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]?.localeCompare(
          b?.product?.[currentSelectedLangue === "english" ? "name" : "name_sp"]
        ),
    },
    {
      align: "center",
      title: providerLabels.orderID,
      dataIndex: "orderId",
      key: "orderId",
      width: 100,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.orderId.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.orderId?.localeCompare(b?.orderId),
    },
    {
      align: "center",
      title: providerLabels.orderDate,
      key: "date",
      width: 150,
      dataIndex: "date",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: providerLabels.deliveryDate,
      key: "deliveryDate",
      width: 100,
      dataIndex: "deliveryDate",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        moment(a.deliveryDate).unix() - moment(b.deliveryDate).unix(),
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: providerLabels.timeUntilDelivery,
      width: 100,
      dataIndex: "estimatedTime",
      key: "estimatedTime",
    },
    {
      align: "center",
      title: providerLabels.status,
      width: 100,
      className: "capitalize",
      dataIndex: "orderStatus",
      key: "status",
    },
    {
      align: "center",
      title: providerLabels.action,
      key: "action",
      width: 80,
      render: (text) => (
        <div className="text-center	">
          <Link to={`/order-info-detail/${text.order_id}`}>
            <Button
              onClick={() => setFilterModal(true)}
              type="disable"
              shape={"round"}
              className="background-color-gray whiteTxt"
            >
              {providerLabels.viewDetails}
            </Button>
          </Link>
        </div>
      ),
    },
  ];
  const getOrdersByProvider = () => {
    GetOrdersOfProvider(
      (success) => {
        if (success.status === 200) {
          if (success.data.response.detail !== null) {
            const apiData = success?.data?.response?.detail?.orders;
            setServiceProviderOrders(
              apiData?.map((item, index) => {
                let dates = moment(item.created_at).format("DD-MMM-YYYY HH:mm");
                const servicePackType = item?.service?.service_pack_type;

                return {
                  ...item,
                  no: index + 1,
                  product: {
                    ...item.service,
                    name: item.service.name,
                    avatar: item.service.avatar,
                    info: item.service.info,
                  },
                  date: moment(item.created_at).format("DD-MMM-YYYY HH:mm"),
                  orderId: item.order_id,
                  date: moment(item.created_at).format("DD-MMM-YYYY HH:mm"),
                  deliveryDate:
                    servicePackType === "monthly"
                      ? providerLabels[servicePackType]
                      : moment(dates)
                          .add(item.service.time, item.service.time_type)
                          .local()
                          .format("DD-MMM-YYYY HH:mm"),
                  estimatedTime:
                    servicePackType === "monthly"
                      ? providerLabels[servicePackType]
                      : item.service.time === null &&
                        item.service.time_type === null
                      ? "0"
                      : `${item.service.time}  ${
                          Number(item.service.time) > 1
                            ? providerLabels[
                                item.service.time_type === "Hours"
                                  ? item.service.time_type
                                  : item.service.time_type?.toLowerCase() + "s"
                              ]
                            : providerLabels[
                                item.service.time_type === "Hours"
                                  ? "hour"
                                  : item.service.time_type?.toLowerCase()
                              ]
                        }`,

                  orderStatus: providerLabels?.[item?.status],
                };
              })
            );
            filteringDataAndsettingAState(
              apiData,
              "completed",
              setCompletedOrders
            );
            filteringDataAndsettingAState(apiData, "pending", setPendingOrders);
            filteringDataAndsettingAState(apiData, "dispute", setDisputeOrders);
            filteringDataAndsettingAState(
              apiData,
              "cancelled",
              setCancelledOrders
            );
            filteringDataAndsettingAState(
              apiData,
              "disputed",
              setDisputedOrders
            );
            filteringDataAndsettingAState(apiData, "block", setBlockedOrders);
          }
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const filteringDataAndsettingAState = (data, OrderStatus, setStateToSet) => {
    setLoading(true);
    let filterdOutByWorkerId = data?.filter(
      (item) => item.status === OrderStatus
    );

    let mappedOut = filterdOutByWorkerId?.map((item, index) => {
      let dates = moment(item.created_at).format("DD-MMM-YYYY HH:mm");
      const servicePackType = item?.service?.service_pack_type;

      return {
        ...item,
        no: index + 1,
        product: {
          ...item.service,
          name: item.service.name,
          avatar: item.service.avatar,
          info: item.service.info,
        },
        date: moment(item.created_at).format("DD-MMM-YYYY HH:mm"),
        orderId: item.order_id,
        date: moment(item.created_at).format("DD-MMM-YYYY HH:mm"),
        deliveryDate:
          servicePackType === "monthly"
            ? providerLabels[servicePackType]
            : servicePackType === "monthly"
            ? providerLabels[servicePackType]
            : moment(dates)
                .add(item.service.time, item.service.time_type)
                .local()
                .format("DD-MMM-YYYY HH:mm"),
        estimatedTime:
          servicePackType === "monthly"
            ? providerLabels[servicePackType]
            : item.service.time === null && item.service.time_type === null
            ? "0"
            : `${item.service.time}  ${
                Number(item.service.time) > 1
                  ? providerLabels[
                      item.service.time_type === "Hours"
                        ? item.service.time_type
                        : item.service.time_type?.toLowerCase() + "s"
                    ]
                  : providerLabels[
                      item.service.time_type === "Hours"
                        ? "hour"
                        : item.service.time_type?.toLowerCase()
                    ]
              }`,

        orderStatus: providerLabels?.[item?.status],
      };
    });
    setStateToSet(mappedOut);
    setLoading(false);
  };
  return (
    <section className="">
      <Spin tip={providerLabels.loading} spinning={loading}>
        <div className="flex justify-between mt-5">
          <div className="pl-5 flex self-center justify-center items-center">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{providerLabels.back}</span>
            </div>
            <h3 className="ml-10	">{providerLabels?.allOrders}</h3>
          </div>
          <div className="mr-32 flex">
            {orderTabs?.map((item) => (
              <Badge
                count={item?.readCount}
                offset={[-11]}
                overflowCount={100}
                key={item.id}
              >
                <Button
                  shape={"round"}
                  onClick={() => changeIndex(item.id)}
                  className={
                    index == item.id
                      ? `icon-button mx-1 bg-primary text-white`
                      : `icon-button mx-1`
                  }
                >
                  {item?.title}
                </Button>
              </Badge>
            ))}

            <CSVLink
              data={
                index === 0
                  ? completedOrders?.map((item, index) => {
                      return {
                        no: index + 1,
                        product:
                          item?.product?.[
                            currentSelectedLangue === "english"
                              ? "name"
                              : "name_sp"
                          ],
                        orderID: item?.orderId,
                        orderDate: item?.date,
                        deliveryDate: item?.deliveryDate,
                        estimatedTime: item?.estimatedTime,
                        status: providerLabels?.[item?.status],
                      };
                    }) || []
                  : index === 1
                  ? pendingOrders?.map((item, index) => {
                      return {
                        no: index + 1,
                        product:
                          item?.product?.[
                            currentSelectedLangue === "english"
                              ? "name"
                              : "name_sp"
                          ],
                        orderID: item?.orderId,
                        orderDate: item?.date,
                        deliveryDate: item?.deliveryDate,
                        estimatedTime: item?.estimatedTime,
                        status: providerLabels?.[item?.status],
                      };
                    }) || []
                  : index === 2
                  ? disputeOrders?.map((item, index) => {
                      return {
                        no: index + 1,
                        product:
                          item?.product?.[
                            currentSelectedLangue === "english"
                              ? "name"
                              : "name_sp"
                          ],
                        orderID: item?.orderId,
                        orderDate: item?.date,
                        deliveryDate: item?.deliveryDate,
                        estimatedTime: item?.estimatedTime,
                        status: providerLabels?.[item?.status],
                      };
                    }) || []
                  : index === 3
                  ? disputedOrders?.map((item, index) => {
                      return {
                        no: index + 1,
                        product:
                          item?.product?.[
                            currentSelectedLangue === "english"
                              ? "name"
                              : "name_sp"
                          ],
                        orderID: item?.orderId,
                        orderDate: item?.date,
                        deliveryDate: item?.deliveryDate,
                        estimatedTime: item?.estimatedTime,
                        status: providerLabels?.[item?.status],
                      };
                    }) || []
                  : index === 4
                  ? cancelledOrders?.map((item, index) => {
                      return {
                        no: index + 1,
                        product:
                          item?.product?.[
                            currentSelectedLangue === "english"
                              ? "name"
                              : "name_sp"
                          ],
                        orderID: item?.orderId,
                        orderDate: item?.date,
                        deliveryDate: item?.deliveryDate,
                        estimatedTime: item?.estimatedTime,
                        status: providerLabels?.[item?.status],
                      };
                    }) || []
                  : index === 5
                  ? serviceProviderOrders?.map((item, index) => {
                      return {
                        no: index + 1,
                        product:
                          item?.product?.[
                            currentSelectedLangue === "english"
                              ? "name"
                              : "name_sp"
                          ],
                        orderID: item?.orderId,
                        orderDate: item?.date,
                        deliveryDate: item?.deliveryDate,
                        estimatedTime: item?.estimatedTime,
                        status: providerLabels?.[item?.status],
                      };
                    }) || []
                  : index === 6
                  ? blockedOrders?.map((item, index) => {
                      return {
                        no: index + 1,
                        product:
                          item?.product?.[
                            currentSelectedLangue === "english"
                              ? "name"
                              : "name_sp"
                          ],
                        orderID: item?.orderId,
                        orderDate: item?.date,
                        deliveryDate: item?.deliveryDate,
                        estimatedTime: item?.estimatedTime,
                        status: providerLabels?.[item?.status],
                      };
                    }) || []
                  : []
              }
              filename={`${
                index === 0
                  ? providerLabels.completedOrders
                  : index === 1
                  ? providerLabels.pendingOrders
                  : index === 2
                  ? providerLabels.disputeOrders
                  : index === 3
                  ? providerLabels.disputedOrders
                  : index === 4
                  ? providerLabels.cancelledOrders
                  : index === 5
                  ? providerLabels.allOrders
                  : index === 6
                  ? providerLabels.blocked
                  : []
              }.csv`}
              headers={[
                { label: providerLabels.no, key: "no" },
                { label: providerLabels.product, key: "product" },
                { label: providerLabels.orderID, key: "orderID" },
                { label: providerLabels.orderDate, key: "orderDate" },
                { label: providerLabels.deliveryDate, key: "deliveryDate" },
                {
                  label: providerLabels.timeUntilDelivery,
                  key: "estimatedTime",
                },
                { label: providerLabels.status, key: "status" },
              ]}
              target="_blank"
            >
              <Button
                shape={"round"}
                className="icon-button "
                icon={<AiOutlineArrowDown color="#5ab3f0" />}
              >
                {providerLabels.exportCSV}
              </Button>
            </CSVLink>
          </div>
        </div>
        <Modal
          visible={filterModal}
          width={400}
          footer={false}
          onCancel={() => setFilterModal(false)}
        >
          <div>
            <h4 className="font-bold text-2xl text-center text-gray-600	">
              Filter By Date
            </h4>
          </div>
          <div className="flex justify-between items-center my-5 ">
            <DatePicker
              className="border-radius20 datePickerStyling"
              suffixIcon={<FaCalendarAlt color={"#5ab3f0"} />}
            />
            <span>to</span>
            <DatePicker
              className="border-radius20 datePickerStyling"
              suffixIcon={<FaCalendarAlt color={"#5ab3f0"} />}
            />
          </div>
          <button
            className="w-full bg-primary rounded-full py-3 text-white"
            onClick={() => setFilterModal(false)}
          >
            Apply
          </button>
        </Modal>
        <div className="container mx-auto lg:px-20 mt-5">
          <TableComponent
            columns={index === 5 ? allOrdersColumns : ordersStatusColumns}
            data={
              index === 0
                ? completedOrders
                : index === 1
                ? pendingOrders
                : index === 2
                ? disputeOrders
                : index === 3
                ? disputedOrders
                : index === 4
                ? cancelledOrders
                : index === 5
                ? serviceProviderOrders
                : index === 6
                ? blockedOrders
                : []
            }
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
            scrolly="500px"
          />
        </div>
      </Spin>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    showWorkers: state.workerList.workers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
    getWorkers: (props) => {
      dispatch(FetchWorkersAction(props));
    },
    removeWorkerList: () => {
      dispatch(DeleteWorkerAtLogoutTime());
    },
    setProviderLogout: () => {
      dispatch(ProviderLogoutAction());
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceProviderAllOrders);
