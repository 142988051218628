import moment from "moment";
import React, { useEffect, useState } from "react";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { adminLabels } from "../../config/label/adminLabels";

const currentSelectedLangue = localStorage.getItem("primaryLanguage")

export default function ServiceOrderDetails(props) {
  const [orderDetail, setOrderDetail] = useState({});
  const { goBack, push } = useHistory();

  useEffect(() => {
    fetchOrderDetails();
  }, []);
  const fetchOrderDetails = () => {
    (props?.location?.state === null || props?.location?.state === undefined) &&
      push({ pathname: `/admin/admin-service-provider` });
    setOrderDetail(props?.location?.state?.orderDetails);
  };
  return (
    <section className="pb-40">
      <div className="mt-10">
        <div className="flex items-center">
          <div
            onClick={() => goBack()}
            className="flex text-gray-400 items-center cursor-pointer ml-12"
          >
            <RiArrowDropLeftLine />
            <span>{adminLabels?.back}</span>
          </div>
          <h3 className="ml-10">
            {adminLabels?.orderId} : {orderDetail?.order_id}
          </h3>
        </div>
        {/* first row of screen */}
        <div className="grid grid-rows-1 mt-16">
          <div className="grid grid-cols-12 gap-4  px-2 sm:px-2 lg:px-28">
            <div className=" col-span-12 sm:col-span-12 lg:col-span-6 border h-auto bg-white rounded-sm pb-10">
              <div className="flex items-center mt-8 ml-6">
                <img
                  src={orderDetail?.service?.avatar}
                  style={{ width: 50, height: 45 }}
                  className="rounded-lg"
                  alt="detail image"
                />
                <div className="pl-2 text-left">
                  <label>
                    <b> {orderDetail?.service?.[currentSelectedLangue === "english" ? "name" : "name_sp" ] }</b>
                  </label>
                  <br />
                  {/* <span style={{ color: "blue" }}>
                    Initial Info and Docs recieved
                  </span> */}
                </div>
              </div>
              <div className="flex justify-between items-center  pl-6 pr-40">
                <div className="mt-5">
                  <h4 className="font-black">{adminLabels?.orderId}</h4>
                  <p className="text-gray-400">{orderDetail?.order_id}</p>
                </div>
                <div className="mt-5">
                  <h4 className="font-black">{adminLabels.date}</h4>
                  <p className="text-gray-400">
                    {moment(orderDetail.created_at).format("DD-MMM-YYYY")}
                  </p>
                </div>
              </div>
              <div className="flex justify-between items-center pl-6 pr-32">
                <div className="mt-5">
                  <h4 className="font-black">{adminLabels.amount}</h4>
                  <p className="text-gray-400">
                    {orderDetail?.after_discount_amount} €
                  </p>
                </div>
                <div className="mt-5">
                  <h4 className="font-black">{adminLabels.supplementary}</h4>
                  <p className="text-gray-400">
                    {!orderDetail?.supplementary_fees
                      ? 0
                      : orderDetail?.supplementary_fees}{" "}
                    €
                  </p>
                </div>
                <div className="mt-5">
                  <h4 className="font-black">{adminLabels.total}</h4>
                  <p className="text-gray-400">
                    {" "}
                    {orderDetail === {}
                      ? 0
                      : parseFloat(
                          !orderDetail?.supplementary_fees
                            ? 0
                            : orderDetail?.supplementary_fees
                        ) + parseFloat(orderDetail?.after_discount_amount)}{" "}
                    €
                  </p>
                </div>
              </div>
            </div>
            <div className=" col-span-12 sm:col-span-12  lg:col-span-3 border h-auto bg-white rounded-sm pt-5 pl-5 pr-10">
              <h4 className="font-black text-gray-500 text-xl">
                {adminLabels.assigned}
              </h4>

              <div className="flex items-center mt-1.5">
                <div className="w-10">
                  <img
                    src={orderDetail?.service_provider?.avatar}
                    className="rounded-full w-8 h-8"
                  />
                </div>
                <div className="w-full ml-1.5 mt-2 " id="selected-provider">
                  <label className="font-bold text-md">
                    {orderDetail?.service_provider?.name}
                  </label>{" "}
                  <br />
                  <label className="text-gray-300">
                    {adminLabels.serviceProvider}
                  </label>
                </div>
              </div>
              {orderDetail?.co_worker && (
                <div className="flex items-center mt-1.5">
                  <div className="w-10">
                    <img
                      src={orderDetail?.co_worker?.avatar}
                      className="rounded-full w-8 h-8"
                    />
                  </div>
                  <div className="w-full ml-1.5 mt-2 " id="selected-provider">
                    <label className="font-bold text-md">
                      {orderDetail?.co_worker?.name}
                    </label>{" "}
                    <br />
                    <label className="text-gray-300">
                      {adminLabels.worker}
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div className="col-span-12 sm:col-span-12  lg:col-span-3 border h-auto bg-white rounded-sm pt-5 pl-5 pr-10">
              <h4 className="font-black text-gray-500 text-xl">
                {adminLabels.personal}
              </h4>
              <div className="my-3 text-gray-600 " id="personal-detial">
                <label className="font-bold	text-md">{adminLabels.name}:</label>{" "}
                <span className="text-gray-400 text-sm">
                  {orderDetail?.data &&
                    orderDetail?.data[0]?.personal?.firstName}
                </span>
              </div>
              <div className="my-3 text-gray-600 " id="personal-detial">
                <label className="font-bold	text-md">
                  {adminLabels.surname}:
                </label>{" "}
                <span className="text-gray-400 text-sm">
                  {orderDetail?.data &&
                    orderDetail?.data[0]?.personal?.lastName}
                </span>
              </div>
              <div className="my-3 text-gray-600 " id="personal-detial">
                <label className="font-bold	text-md">{adminLabels.email}:</label>{" "}
                <span className="text-gray-400 text-sm">
                  {orderDetail?.data &&
                    orderDetail?.data[0]?.personal?.emailAddress}
                </span>
              </div>
              <div className="my-3 text-gray-600 " id="personal-detial">
                <label className="font-bold	text-md">{adminLabels.cif}:</label>{" "}
                <span className="text-gray-400 text-sm">
                  {orderDetail?.data && orderDetail?.data[0]?.personal?.CIF}
                </span>
              </div>
              <div className="my-3 text-gray-600 " id="personal-detial">
                <label className="font-bold	text-md">{adminLabels.city}:</label>{" "}
                <span className="text-gray-400 text-sm">
                  {orderDetail?.data && orderDetail?.data[1]?.region?.city}
                </span>
              </div>
              <div className="my-3 text-gray-600 " id="personal-detial">
                <label className="font-bold	text-md"></label>{" "}
                {/* <span className="text-gray-400 text-sm">{t.value}</span> */}
              </div>
              {/* {[
                { name: "Name", value: "John" },
                { name: "Surname", value: "John" },
                { name: "Email", value: "John@mail.com" },
                { name: "ID Number", value: "#123" },
                { name: "City", value: "Madrid" },
                { name: "Beneficiary Data", value: "Particular" },
              ].map((t, i) => {
                return (
                  <div
                    className="my-3 text-gray-600 "
                    key={i}
                    id="personal-detial"
                  >
                    <label className="font-bold	text-md">{t.name}:</label>{" "}
                    <span className="text-gray-400 text-sm">{t.value}</span>
                  </div>
                );
              })}  */}
            </div>
          </div>
        </div>
        {/* second row of screen */}
      </div>
    </section>
  );
}
