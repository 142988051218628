import React, { useState, useEffect, useRef } from "react";
import InputComponent from "../../components/InputComponent";
import ButtonComponent from "../../components/ButtonComponent";
import { RiImageAddLine } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";
import { Images } from "../../constant/Images";
import { Menu, Button } from "antd";
import { Select, Tag, Modal } from "antd";
import ReactQuill from "react-quill";
import {
  DeleteBeneficiariesAtLogoutTime,
  FetchBeneficiariesAction,
} from "../../Redux/actions/fetchBeneficiariesAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { objectToFormData } from "../../config/formData/formData";
import { connect } from "react-redux";
import { Add_Service_Area, EditServiceArea } from "../../config/api";
import {
  DeleteServiceAreasAtLogoutTime,
  FetchServiceAreaAction,
} from "../../Redux/actions/fetchServiceAreaAction";
import { Errors, Success } from "../../components/antdToast";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";
import { DeleteProvidersAtLogoutTime } from "../../Redux/actions/fetchProvidersAction";
import { DeleteBranchesAtLogoutTime } from "../../Redux/actions/fetchBranchesAction";
import { DeleteServicesAtLogoutTime } from "../../Redux/actions/fetchServicesAction";
import { Remove_Data_Form_Reducers } from "../../components/dataRemoveOnLogout";
import { DeleteServiceForms } from "../../Redux/actions/fetchServiceFormsAction";
import { DeleteAppUsers } from "../../Redux/actions/fetchAppUsersAction";

const { uploadImg, checkbox, checkboxActive } = Images;
const { Option } = Select;

function AddServiceArea(props) {
  const { goBack, push } = useHistory();
  // Declare Variable States
  const [serviceAreaName, setServiceAreaName] = useState("");
  const [serviceAreaImage, setServiceAreaImage] = useState("Upload Image");
  const [features, setFeatures] = useState(props.showBeneficiaries);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [value, setValue] = useState("");
  const [openModal1, setOpenModal1] = useState(false);
  const [priority, setPriority] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [fieldIsRequired, setFieldIsrequired] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedBeneficiariresName, setSelectedBeneFiciariesName] = useState(
    []
  );
  useEffect(() => {
    props.getServiceAreaList();
    if (props.match.params.id) {
      let findObj = props.showServiceArea.find(
        (obj) => obj.service_area_id === props.match.params.id
      );
      setServiceAreaName(findObj.name);
      setDescription(findObj.info);
      setServiceAreaImage(findObj.avatar);
      let mapedfeatures = [];
      features.map((feature, index) => {
        let arr1 = findObj?.beneficiaries
          .map((t) =>
            t.beneficiary_id === feature.beneficiary_id ? t.priority : 0
          )
          .filter((a) => a !== 0);
        let arr = {
          ...feature,
          checked: findObj.beneficiaries.some((a, i) =>
            a.beneficiary_id === feature?.beneficiary_id ? true : false
          ),
          priority: arr1.length !== 0 ? arr1[0] : 0,
        };
        mapedfeatures.push(arr);
      });
      setFeatures(mapedfeatures);
    } else {
      let mapedfeatures = features.map((feature, index) => {
        return {
          ...feature,
          checked: false,
          priority: 0,
        };
      });
      setFeatures(mapedfeatures);
    }
  }, []);

  useEffect(() => {
    if (features.length !== 0) {
      selectedBeneficiarires();
    }
  }, [features]);

  const onChecked = (e, i) => {
    const array = features.map((a, b) => {
      if (b === i) {
        return {
          ...a,
          checked: !a.checked,
        };
      } else {
        return {
          ...a,
          checked: a.checked,
        };
      }
    });
    setFeatures(array);
  };

  const children = [];
  for (let i = 10; i < 36; i++) {
    children.push(
      <Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>
    );
  }

  const changePriority = (e, id) => {
    let mappedPriority = features.map((a, b) => {
      if (a.beneficiary_id === id) {
        return {
          ...a,
          priority: e.target.value,
        };
      } else {
        return {
          ...a,
          priority: a.priority,
        };
      }
    });
    setFeatures(mappedPriority);
  };

  const selectedBeneficiarires = () => {
    let filteredFeatures = features.filter((selectFeature) => {
      if (selectFeature.checked === true) {
        return {
          selectFeature,
        };
      }
    });
    setSelectedBeneFiciariesName(filteredFeatures);
    let mappedFeatures = filteredFeatures.map((selectFeature) => {
      if (selectFeature.checked === true) {
        return {
          beneficiary_id: selectFeature.beneficiary_id,
          priority: selectFeature.priority,
        };
      }
    });
    setSelectedFeatures(mappedFeatures);
  };

  const createServiceArea = () => {
    if (
      description.length !== 0 &&
      serviceAreaImage !== "Upload Image" &&
      serviceAreaName !== "" &&
      selectedFeatures.length !== 0
    ) {
      let obj = {
        name: serviceAreaName,
        info: description.toString(),
        avatar: serviceAreaImage,
        beneficiaries: JSON.stringify(selectedFeatures),
      };
      let data = objectToFormData(obj);
      props.setLoaderState(true);
      Add_Service_Area(
        data,
        (success) => {
          if (success.status === 200) {
            props.setLoaderState(false);
            Success(success.data.response.message);
            setServiceAreaImage("Upload Image");
            setServiceAreaName("");
            setSelectedFeatures([]);
            setFieldIsrequired(false);
            setSelectedBeneFiciariesName([]);
            setDescription("");
            props.getServiceAreaList(props);
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            setFieldIsrequired(false);
            Remove_Data_Form_Reducers(props);
            push("/admin");
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
            setFieldIsrequired(false);
          }
        }
      );
    } else {
      setFieldIsrequired(true);
    }
  };

  const editServiceArea = () => {
    if (
      description.length !== 0 &&
      serviceAreaImage !== "Upload Image" &&
      serviceAreaName !== "" &&
      selectedFeatures.length !== 0
    ) {
      let obj = {
        name: serviceAreaName,
        info: description.toString(),
        avatar: serviceAreaImage,
        beneficiaries: JSON.stringify(selectedFeatures),
        service_area_id: props.match.params.id,
      };
      let data = objectToFormData(obj);
      props.setLoaderState(true);
      EditServiceArea(
        data,
        (success) => {
          if (success.status === 200) {
            props.setLoaderState(false);
            Success(success.data.response.message);
            setFieldIsrequired(false);
            props.getServiceAreaList(props);
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            setFieldIsrequired(false);
            Remove_Data_Form_Reducers(props);
            push("/admin");
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
            setFieldIsrequired(false);
          }
        }
      );
    }
  };

  return (
    <section className="mt-20">
      <div className="flex self-center items-center ml-12  pb-5 sub-h3">
        <div
          onClick={() => goBack()}
          className="flex text-gray-400 items-center cursor-pointer"
        >
          <RiArrowDropLeftLine />
          <span>Back</span>
        </div>
        <h3 className="font-bold text-lg mb-0 ml-10">
          {props.match.params.id ? "Edit Service Area" : "Add New Service Area"}
        </h3>
      </div>

      <div
        style={{
          paddingBottom: "10px",
          paddingTop: "20px",
        }}
        className="container mx-auto lg:pl-32"
      >
        <div className="grid grid-cols-12 ">
          <div className="  lg:m-2 col-span-12 lg:col-span-3 sm:col-span-12 md:col-span-6  mx-2 sm:mx-2 lg:mx-0.5 mb-2 sm:mb-2 lg:mb-0 ">
            <button
              className=" bg-white rounded-full py-3 px-4 text-sm flex justify-between justify-center items-center w-full"
              onClick={() => {
                setOpenModal1(true);
              }}
              disabled={props.loader === true ? true : false}
            >
              {selectedBeneficiariresName.length !== 0 ? (
                selectedBeneficiariresName.map((selectedNameOfBene, index) => (
                  <span className="pl-3 pb-1">{`${selectedNameOfBene.name},`}</span>
                ))
              ) : (
                <span className="pl-3 pb-1">Select Beneficiaries</span>
              )}
              <IoIosArrowForward />
            </button>
            {selectedFeatures.length === 0 && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">
                Please select the beneficiaries!
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="lg:m-2 mx-2 sm:mx-2 lg:mx-0.5 col-span-12 lg:col-span-3 sm:col-span-12 md:col-span-6 mt-2 mb-2 sm:mb-2 lg:mb-0  relative cursor-pointer">
            <input
              className="rounded-full py-3 px-9 w-full absolute opacity-0  cursor-pointer"
              type={"file"}
              onChange={(e) => setServiceAreaImage(e.target.files[0])}
            />
            <button className=" pb-4 bg-gray-400 rounded-full py-3 text-sm flex justify-center items-center w-full">
              <img
                style={{ cursor: "pointer" }}
                width="20"
                height="20"
                src={uploadImg.default}
                className="mr-1"
                disabled={props.loader === true ? true : false}
              />{" "}
              <span className="  text-white">
                {serviceAreaImage !== "Upload Image"
                  ? serviceAreaImage?.name
                  : serviceAreaImage}
              </span>
            </button>
            {serviceAreaImage === "Upload Image" && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">Please select image!</span>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="grid grid-cols-6">
          <div className="col-span-6 lg:col-span-3 sm:col-span-6 md:col-span-6 mx-2 sm:mx-2 lg:mx-0.5 mb-2 sm:mb-2 lg:mb-0">
            <InputComponent
              placeholder={"service area name"}
              value={serviceAreaName}
              onChange={(e) => setServiceAreaName(e.target.value)}
              disabled={props.loader === true ? true : false}
            />
            {serviceAreaName === "" && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">Please fill the field!</span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className=" mt-3 pb-5  lg:mr-6  grid grid-cols-12 ">
          <div className=" col-span-12 lg:col-span-6 sm:col-span-12 md:col-span-12 mx-2 sm:mx-2 lg:mx-0.5 ">
            <ReactQuill
              className={"richTextBox "}
              value={description}
              onChange={setDescription}
              disabled={props.loader === true ? true : false}
            />
            {description.length === 0 && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">Please fill the field!</span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="w-912/12  sm:w-12/12  lg:w-1/4 mt-3 lg:ml-2 lg:pr-4 px-2 sm:px-2 lg:px-0">
          {errorMessage.length > 0 ? (
            <div className="text-red-500 pb-4">{errorMessage}</div>
          ) : (
            ""
          )}
          {successMessage.length > 0 ? (
            <div className="pb-4" style={{ color: "green" }}>
              {successMessage}
            </div>
          ) : (
            ""
          )}
          <ButtonComponent
            className="bg-primary"
            title={"Save"}
            onClick={
              props.match.params.id ? editServiceArea : createServiceArea
            }
            disabled={props.loader === true ? true : false}
            loader={props.loader === true ? true : false}
          />
        </div>
        <Modal
          visible={openModal1}
          width={450}
          footer={false}
          onCancel={() => setOpenModal1(false)}
        >
          <div>
            <h4 className="font-bold text-2xm text-center text-gray-600	">
              Select Beneficiaries
            </h4>
          </div>
          <div className="divide-y divide-black-600 divide-solid ">
            {features.length
              ? features.map((t, i) => {
                  return (
                    <div className="flex justify-between items-center py-5 ">
                      <span>{t?.name}</span>
                      <div className="mr-2">
                        <InputComponent
                          placeholder="Priority"
                          value={t?.priority}
                          disabled={t?.checked === false}
                          onChange={(e) => changePriority(e, t.beneficiary_id)}
                        />
                      </div>
                      {t?.checked ? (
                        <img
                          src={checkboxActive.default}
                          onClick={() => onChecked(t, i)}
                        />
                      ) : (
                        <img
                          src={checkbox.default}
                          onClick={() => onChecked(t, i)}
                        />
                      )}
                    </div>
                  );
                })
              : null}
          </div>
          <button
            className="w-full bg-primary rounded-full py-3 text-white"
            onClick={() => {
              setOpenModal1(false);
              selectedBeneficiarires();
            }}
          >
            Apply
          </button>
        </Modal>
      </div>
    </section>
  );
}
const mapStateToProps = (state) => {
  return {
    showBeneficiaries: state.beneficiariesList.beneficiaries,
    showServiceArea: state.serviceAreaList.serviceAreas,
    loader: state.loaderToggle.loaderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBeneificiariesList: () => {
      dispatch(FetchBeneficiariesAction());
    },
    getServiceAreaList: (props) => {
      dispatch(FetchServiceAreaAction(props));
    },
    setLogoutUser: (data) => {
      dispatch(LogoutUserAction(data));
    },
    removeBeneficiaries: () => {
      dispatch(DeleteBeneficiariesAtLogoutTime());
    },
    removeProviders: () => {
      dispatch(DeleteProvidersAtLogoutTime());
    },
    removeServiceAreas: () => {
      dispatch(DeleteServiceAreasAtLogoutTime());
    },
    removeBranches: () => {
      dispatch(DeleteBranchesAtLogoutTime());
    },
    removeServices: () => {
      dispatch(DeleteServicesAtLogoutTime());
    },
    removeServiceForms: () => {
      dispatch(DeleteServiceForms());
    },
    removeAppUsers: () => {
      dispatch(DeleteAppUsers());
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddServiceArea);
