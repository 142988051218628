import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Errors } from "../../components/antdToast";
import { Remove_Data_Form_Provider_Reducers } from "../../components/dataRemoveOnProviderLogout";
import TableComponent from "../../components/table";
import { GetOrdersOfProvider } from "../../config/api";
import { providerLabels } from "../../config/label/providerLabels";
import {
  DeleteWorkerAtLogoutTime,
  FetchWorkersAction,
} from "../../Redux/actions/fetchWorkersAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { ProviderLogoutAction } from "../../Redux/actions/logoutAction";
import { fetchServiceProviderMessages } from "../../Redux/actions/serviceProviderActions/messages";
import {
  child,
  onValue,
  push as fbPush,
  ref,
  runTransaction,
  serverTimestamp,
  update,
} from "firebase/database";
import { firebaseDatabase } from "../../config/Firebase";
import { Button, DatePicker, Input, Spin } from "antd";

const currentSelectedLangue = localStorage.getItem("primaryLanguage");
// const getOrderUnreadCountData = (orderID) =>
//   ref(firebaseDatabase, "orders/" + orderID + "/userUnread");

const MyMessage = (props) => {
  const { goBack, push } = useHistory();
  const [providerOrders, setProviderOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const messages = useSelector(
    (state) => state.serviceProviderMessages?.messages
  );
  const messageLoading = useSelector(
    (state) => state?.serviceProviderMessages?.loading
  );
  useEffect(() => {
    // getOrdersByProvider();
    const interval = setInterval(() => {
      // getOrdersByProvider();
      dispatch(fetchServiceProviderMessages(push));
    }, 20000);
    dispatch(fetchServiceProviderMessages(push));
    return () => {
      clearInterval(interval);
    };

    // getOrdersByProvider();
  }, []);
  useEffect(() => {
    let mappedOut = messages?.map((item, index) => {
      const servicePackType = item?.order?.service?.service_pack_type;
      return {
        ...item,
        no: index + 1,
        name:
          item?.order?.data !== null &&
          item?.order?.data?.[0]?.personal.firstName +
            " " +
            item?.order?.data?.[0]?.personal.lastName,
        phone:
          item?.order?.data !== null &&
          item?.order?.data?.[0]?.personal.countryCode,
        product: {
          ...item?.order?.service,
          name: item?.order?.service?.name,
          avatar: item?.order?.service?.avatar,
          info: item?.order?.service?.info,
        },
        date: moment(item?.created_at)?.format("DD-MMM-YYYY HH:mm"),
        orderId: item?.order_id,
        time:
          servicePackType === "monthly"
            ? providerLabels[servicePackType]
            : item?.order?.service?.time === null &&
              item?.order?.service?.time_type === null
            ? "0"
            : `${item?.order?.service?.time}  ${
                Number(item?.order?.service?.time) > 1
                  ? providerLabels[
                      item?.order?.service?.time_type === "Hours"
                        ? item?.order?.service?.time_type
                        : item?.order?.service?.time_type?.toLowerCase() + "s"
                    ]
                  : providerLabels[
                      item?.order?.service?.time_type === "Hours"
                        ? "hour"
                        : item?.order?.service?.time_type?.toLowerCase()
                    ]
              }`,
      };
    });
    setProviderOrders(mappedOut);
  }, [messages]);

  useEffect(() => {
    setLoading(messageLoading);
  }, [messageLoading]);

  const getOrderUnreadCountData = (orderID) => {
    const getData = ref(firebaseDatabase, `orders/${orderID}/providerUnread`);
    let count = 0;
    onValue(getData, (snapshot) => {
      const data = snapshot?.val();
      count = data;
    });
    return count;
  };
  const columns = [
    {
      align: "center",
      title: providerLabels.no,
      dataIndex: "no",
      key: "no",
      width: 20,
      render: (text) => <a>{text}</a>,
    },
    {
      align: "center",
      title: providerLabels.product,
      dataIndex: "product",
      key: "product",
      width: 80,
      render: (text, index) => (
        <div className={"flex"}>
          <img
            src={text?.avatar}
            style={{ width: 50, height: 45 }}
            alt="detail image"
          />
          <div className="pl-2 text-left">
            <label>
              <b>
                {
                  text?.[
                    currentSelectedLangue === "english" ? "name" : "name_sp"
                  ]
                }
              </b>
            </label>
          </div>
        </div>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]
          ?.toLowerCase()
          .includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a?.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]?.localeCompare(
          b?.product?.[currentSelectedLangue === "english" ? "name" : "name_sp"]
        ),
    },
    {
      align: "center",
      title: providerLabels.name,
      dataIndex: "name",
      key: "name",
      width: 40,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.name?.toLowerCase()?.includes(value?.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      showSorterTooltip: false,
    },

    {
      align: "center",
      title: providerLabels.orderID,
      dataIndex: "orderId",
      key: "orderId",
      width: 30,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.orderId.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.orderId?.localeCompare(b?.orderId),
    },
    {
      align: "center",
      title: providerLabels.date,
      key: "date",
      dataIndex: "date",
      width: 40,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: providerLabels.timeOfDelivery,
      key: "time",
      dataIndex: "time",
      width: 40,
    },
    // {
    //   align: "center",
    //   title: providerLabels.unreadMessages,
    //   // key: "count",
    //   // dataIndex: "count",
    //   render: (text, index) => (
    //     <div>{getOrderUnreadCountData(text?.order_id)}</div>
    //   ),
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a.phone - b.phone,
    //   showSorterTooltip: false,
    //   width: 100,

    // },
    {
      align: "center",
      title: providerLabels.action,
      key: "action",
      width: 35,

      render: (text, index) => (
        //{getOrderUnreadCountData(text?.order_id)}
        // <div className="text-center	">
        //   <Link
        //     to={
        //       localStorage.getItem("type") === "co_worker"
        //         ? `/worker/add-my-message-order/${text.order_id}`
        //         : `/add-my-message-order/${text.order_id}/${text.user_id}/${text.service_provider_id}/${text.service_id}`
        //     }
        //   >
        //     <Button
        //       type="disable"
        //       shape={"round"}
        //       className="background-color-gray whiteTxt proximaRegular"
        //     >
        //       {providerLabels.viewMessage}
        //     </Button>
        //   </Link>
        // </div>
        <div className="gap-1 flex items-center justify-center">
          <div className="mb-1">
            {getOrderUnreadCountData(text?.order_id) > 0 ? (
              <span className="font-bold text-red-500 text-4xl mr-1">.</span>
            ) : (
              <span className="font-bold font-color-blue text-4xl mr-1 opacity-0">
                .
              </span>
            )}
          </div>
          <div>
            <Link
              to={
                localStorage.getItem("type") === "co_worker"
                  ? `/worker/add-my-message-order/${text.order_id}`
                  : `/add-my-message-order/${text.order_id}/${text.user_id}/${text.service_provider_id}/${text.service_id}`
              }
            >
              <Button
                type="disable"
                shape={"round"}
                className="background-color-gray whiteTxt"
              >
                {providerLabels.viewDetails}
              </Button>
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <section className="">
      <Spin tip={providerLabels.loading} spinning={loading}>
        <div className="flex justify-between mt-5 mr-2 sm:mr-2 lg:mr-28">
          <div className="pl-5 flex self-center justify-center items-center">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{providerLabels.back}</span>
            </div>
            <h3 className="ml-10	">{providerLabels.myMessage}</h3>
          </div>
          {/* <Button shape={"circle"} className="icon-button p0 ">
          <FiSearch size={15} />
        </Button> */}
        </div>
        <div className="container mx-auto lg:px-20 mt-5">
          <TableComponent
            columns={columns}
            data={providerOrders}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
            scrolly="500px"
            scroll="100%"
          />
        </div>
      </Spin>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    showWorkers: state.workerList.workers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
    getWorkers: (props) => {
      dispatch(FetchWorkersAction(props));
    },
    removeWorkerList: () => {
      dispatch(DeleteWorkerAtLogoutTime());
    },
    setProviderLogout: () => {
      dispatch(ProviderLogoutAction());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyMessage);
