import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCF_3s_WfrPV28QgHaClYP6ovOI41czCDI",
  authDomain: "docudoc-45d67.firebaseapp.com",
  databaseURL:
    "https://docudoc-45d67-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "docudoc-45d67",
  storageBucket: "docudoc-45d67.appspot.com",
  messagingSenderId: "82163416212",
  appId: "1:82163416212:web:a5b6fb863d96dd60044881",
  measurementId: "G-R8ZE2H0S6P",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firebaseDatabase = getDatabase();
export { firebaseDatabase, app };
