import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Spin } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Errors, Success } from "../../components/antdToast";
import { ProviderResetPasswordApi } from "../../config/api";
import { spanishLabels } from "../../config/label/providerLabels";
import { Images } from "../../constant/Images";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";

const { logo } = Images;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const NewPass = (props) => {
  // declare variable
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isConfirmedPassword, setIsConfirmedPassword] = useState(false);
  const { push } = useHistory();

  const onFinish = (values) => {
    if (
      newPassword !== "" &&
      confirmPassword !== "" &&
      isConfirmedPassword === true
    ) {
      let obj = {
        email: props.location.state,
        password: newPassword,
      };

      sendPasswordForResetPassword(obj);
    }
  };
  const sendPasswordForResetPassword = (obj) => {
    props.setLoaderState(true);
    ProviderResetPasswordApi(
      obj,
      (success) => {
        if (success?.status === 200) {
          Success(success?.data?.response?.message);
          props.setLoaderState(false);
          setTimeout(() => {
            push({ pathname: "/", state: obj.email });
          }, 1000);
        }
      },
      (error) => {
        if (error?.response?.status === 422) {
          Errors(error?.response?.data?.message);
          props.setLoaderState(false);
        } else {
          Errors("Network Error");
          props.setLoaderState(false);
        }
      }
    );
  };

  const handleConfirmedPassword = (e) => {
    if (e.target.value === newPassword) {
      setIsConfirmedPassword(true);
    } else {
      setConfirmPassword(false);
    }
  };
  const onFinishFailed = (errorInfo) => {};

  return (
    <div className="bg-img">
      <div className="absolute left-14 top-absolute-40 w-1/4">
        <img src={logo.default} />
      </div>
      <div className="flex text-center h-screen	justify-center items-center">
        <div className="rounded-lg bg-white p-5 relative pb-0 w-1/4 h-2/2">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <img src={Images.newpass.default} className="m-auto my-5" />
            <h3 className="text-center mb-10 pb-5">
              {spanishLabels.enterNewPassword}
            </h3>
            <Form.Item
              className="form"
              name="password"
              rules={[
                { required: true, message: spanishLabels?.passwordRequired },
              ]}
            >
              <Input.Password
                className="shadow-md border-radius20 p-3"
                placeholder={spanishLabels.enterNewPassword}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              className="form"
              name="confirm_password"
              rules={[
                { required: true, message: spanishLabels?.passwordRequired },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (value === newPassword) {
                      return Promise.resolve();
                    }
                    return Promise.reject(spanishLabels?.confirmPasswordMismatch);
                  },
                }),
              ]}
              value={confirmPassword}
              onChange={handleConfirmedPassword}
            >
              <Input.Password
                className="shadow-md border-radius20 p-3"
                placeholder={spanishLabels.enterConfirmPassword}
              />
            </Form.Item>
            <Form.Item>
              <Button
                className="padding-sign-button"
                shape="round"
                type="primary"
                htmlType="submit"
                block
              >
                {props.loader === true ? (
                  <Spin indicator={antIcon} />
                ) : (
                  spanishLabels.confirm
                )}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    loader: state.loaderToggle.loaderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPass);
