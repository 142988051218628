import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { RootReducer } from "../reducers/combineReducer";

const PersistedConfigration = {
  key: "root",
  storage,
};
const PersistedReducer = persistReducer(PersistedConfigration, RootReducer);
const store = createStore(PersistedReducer, applyMiddleware(thunk));
const PersistedStore = persistStore(store);

export { store, PersistedStore };
