import { Table } from "antd";
import React from "react";

function TableComponent({ columns, data, scroll = "100%", scrolly = "290px",pagination}) {
  return (
   
    <Table
      columns={columns}
      dataSource={data}
      className="styling-table proximaRegular"
      bordered
      pagination={{showSizeChanger: true, defaultPageSize: String(pagination?.defaultPageSize) || '6' , pageSizeOptions: [ String(pagination?.defaultPageSize) || '6', '10', '20','50']}}
      scroll={{ x: scroll, y: scrolly }}
      style={{height:scrolly}}
    />
  
  );
}

export default TableComponent;
