import { Button, Spin } from "antd";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiOutlineArrowDown } from "react-icons/ai";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import TableComponent from "../../components/table";
import { adminLabels } from "../../config/label/adminLabels";
import moment from "moment";

import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, Modal } from "antd";
import InformationContainer from "../../components/informationContainer";
import { getAdminBillLogs } from "../../config/api";
import {
  currencyFormat,
  downloadFiles,
  functionToConvertStringToCamelCase,
  get_url_extension,
  orderDiscountPercentage,
} from "../../config/utils";

export default function ServiceProviderIncomePaymentsBills(params) {
  const [loading, setLoading] = useState(true);
  const [billLogsData, setBillLogsData] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [modal_Content, setModalContent] = useState("");
  const { goBack } = useHistory();
  const currentSelectedLangue = localStorage.getItem("primaryLanguage");

  const downloadSystemGeneratedOrderBillFunction = (id) => {
    downloadFiles(
      `https://api.docudocapp.com/api/download_bill/${id}`,
      `${adminLabels.order} ${id}.pdf`
    );
  };
  const downloadSystemGeneratedAdditionalBudgetBillFunction = (id) => {
    // functionToDownloadFile(
    //   `https://api.docudocapp.com/api/download_additional_budget_bill/${id}`,
    //   `${adminLabels.additionalBudget}.pdf`
    // );
  };
  const tableColumns = [
    {
      align: "center",
      title: adminLabels.no,
      dataIndex: "no",
      key: "no",
      width: 60,
    },
    {
      align: "center",
      title: adminLabels.billNumber,
      dataIndex: "log_Bill_ID",
      key: "log_Bill_ID",
      width: 200,
    },
    {
      align: "center",
      title: adminLabels.serviceName,
      dataIndex: "product",
      key: "product",
      width: 400,
      render: (text, index) => (
        <div className={"flex"}>
          <img
            src={text?.avatar}
            style={{ width: 50, height: 45 }}
            alt="detail image"
          />
          <div className="pl-2 text-left">
            <label>
              <b>
                {
                  text?.[
                    currentSelectedLangue === "english" ? "name" : "name_sp"
                  ]
                }
              </b>
            </label>
          </div>
        </div>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]
          ?.toLowerCase()
          .includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a?.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]?.localeCompare(
          b?.product?.[currentSelectedLangue === "english" ? "name" : "name_sp"]
        ),
    },
    {
      align: "center",
      title: adminLabels.orderId,
      dataIndex: "order_id",
      key: "order_id",
      width: 200,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.order_id.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.order_id?.localeCompare(b?.order_id),
    },
    {
      align: "center",
      title: adminLabels.serviceInformation,
      align: "center",
      width: 150,
      render: (text, index) => (
        <div className="table-quill flex justify-center items-center">
          <span
            className="cursor-pointer flex items-center space-x-1 "
            onClick={() => {
              setShowInfoModal(true);
              currentSelectedLangue === "english"
                ? setModalContent(text?.product.info || "")
                : setModalContent(text?.product.info_sp || "");
            }}
          >
            <EyeOutlined />
            <span>{adminLabels.infoBox}</span>
          </span>
        </div>
      ),
    },
    {
      align: "center",
      title: adminLabels.additionalBudgetNumber,
      key: "additionalBudgetNumber",
      dataIndex: "additionalBudgetNumber",
    },
    {
      align: "center",
      title: adminLabels.typeOfMovement,

      dataIndex: "typeOfMovement",
      key: "typeOfMovement",
      width: 300,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.typeOfMovement
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.typeOfMovement?.localeCompare(b?.typeOfMovement),
    },
    {
      align: "center",
      title: adminLabels.dateOfPayment,
      key: "dateOfPayment",
      width: 180,
      dataIndex: "dateOfPayment",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        moment(a.dateOfPayment).unix() - moment(b.dateOfPayment).unix(),
      showSorterTooltip: false,
    },
    {
      width: 250,
      title: adminLabels.nameAndSurnameOfClient,
      key: "clientNameSurName",
      dataIndex: "clientNameSurName",
    },
    {
      title: adminLabels.region,
      key: "clientRegion",
      dataIndex: "clientRegion",
    },
    {
      title: adminLabels.city,
      key: "clientCity",
      dataIndex: "clientCity",
    },
    {
      title: adminLabels.userType,
      key: "clientUserType",
      dataIndex: "clientUserType",
    },
    {
      title: adminLabels.companyName,
      key: "clientCompanyName",
      dataIndex: "clientCompanyName",
    },

    {
      align: "center",
      title: adminLabels.cifOrNif,
      key: "clientCIfNIF",
      dataIndex: "clientCIfNIF",
    },
    {
      width: 250,
      //align: "center",
      title: adminLabels.companyAddress,
      key: "clientAddress",
      dataIndex: "clientAddress",
    },

    {
      align: "center",
      title: adminLabels.dispute,
      key: "isDispute",
      dataIndex: "isDispute",
    },
    {
      align: "center",
      title: adminLabels.comment,
      key: "disputeComment",
      dataIndex: "disputeComment",
    },
    {
      width: 250,
      align: "center",
      title: adminLabels.originalServiceVATPriceEstablishedInAdminPanel,
      key: "originalServiceVATPriceEstablishedInAdminPanel",
      dataIndex: "originalServiceVATPriceEstablishedInAdminPanel",
      render: (text, index) => text,
    },
    {
      width: 250,
      align: "center",
      title: adminLabels.originalServicePriceEstablished,
      key: "originalServicePriceEstablished",
      dataIndex: "originalServicePriceEstablished",
      render: (text, index) => text,
    },
    {
      align: "center",
      title: adminLabels.discount,
      key: "discountPercentage",
      dataIndex: "discountPercentage",
      render: (text) => `${Number(text)?.toFixed(1)}%`,
    },

    {
      align: "center",
      title: adminLabels.discountServiceVAT,
      key: "discountServiceVAT",
      dataIndex: "discountServiceVAT",
      render: (text, index) => text,
    },
    {
      align: "center",
      title: adminLabels.baseImponibleWithoutVATDiscount,
      key: "baseImponibleWithoutVATDiscount",
      dataIndex: "baseImponibleWithoutVATDiscount",
      render: (text, index) => text,
    },
    {
      align: "center",
      title: adminLabels.VATToBePayedOfServiceDiscount,
      key: "VATToBePayedOfServiceDiscount",
      dataIndex: "VATToBePayedOfServiceDiscount",
      render: (text, index) => text,

      
    },
    {
      align: "center",
      title: adminLabels.servicioDicount,
      key: "servicioDicount",
      dataIndex: "servicioDicount",
      render: (text, index) => text,

    },
 
    {
      align: "center",
      title: adminLabels.sumOfAllSupplements,
      key: "sumOfAllSupplements",
      dataIndex: "sumOfAllSupplements",
      render: (text, index) => text,

    },
    {
      align: "center",
      title: adminLabels.totalPayedByClient,
      key: "totalPayedByClient",
      dataIndex: "totalPayedByClient",
      render: (text, index) => text,

    },
    {
      align: "center",
      title: adminLabels.seviceDocuDoc30SeviceDiscount,
      key: "seviceDocuDoc30SeviceDiscount",
      dataIndex: "seviceDocuDoc30SeviceDiscount",
      render: (text, index) => text,

    },
    {
      align: "center",
      title: adminLabels.seviceProvider70SeviceWithoutVAT,
      key: "seviceProvider70SeviceWithoutVAT",
      dataIndex: "seviceProvider70SeviceWithoutVAT",
      render: (text, index) => text,

    },
    {
      align:"center",
      title: adminLabels.serviceProviderSelfEmployedORBusiness,
      key: "serviceProviderSelfEmployedORBusiness",
      dataIndex: "serviceProviderSelfEmployedORBusiness",
      render: (text, index) => <div>{text}</div>,
    },
    {
      align: "center",
      title: adminLabels.fifteenPercentageOfServiceProviderServiceVAT,
      dataIndex: "fifteenPercentageOfServiceProviderServiceVAT",
      key: "fifteenPercentageOfServiceProviderServiceVAT",
      render: (text, index) => text,
    },

    {
      align: "center",
      title: adminLabels.totalPayedOnlyToDoubleCheck,
      dataIndex: "totalPayedOnlyToDoubleCheck",
      key: "totalPayedOnlyToDoubleCheck",
      render: (text, index) => text,
    },
    {
      align: "center",
      title: adminLabels.idOfPayment,
      dataIndex: "idOfPayment",
      key: "idOfPayment",
      // render: (text, index) => <div>€{currencyFormat(text)}</div>,
    },
    {
      // align: "center",
      title: adminLabels.serviceProvider,

      dataIndex: "serviceProviderName",
      key: "serviceProviderName",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.serviceProviderName
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },

      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a?.serviceProviderName?.localeCompare(b?.serviceProviderName),
    },
    {
      align: "center",
      title: adminLabels.VATDocuDoc,
      key: "VATDocuDoc",
      dataIndex: "VATDocuDoc",
      render: (text, index) => text,
    },
    {
      align: "center",
      title: adminLabels.VATServiceProvider,
      key: "VATServiceProvider",
      dataIndex: "VATServiceProvider",
      render: (text, index) => text,
    },
    {
      align: "center",
      title: adminLabels.whichStaysInDocuDoc,
      key: "whichStaysInDocuDoc",
      dataIndex: "whichStaysInDocuDoc",
      render: (text, index) => text,
    },
    {
      align: "center",
      title: adminLabels.toBePayedServiceProvider,
      key: "toBePayedServiceProvider",
      dataIndex: "toBePayedServiceProvider",
      render: (text, index) => text,
    },
    {
      align: "center",
      title: adminLabels.statusPenidngOrCompleted,
      key: "statusPenidngOrCompleted",
      dataIndex: "statusPenidngOrCompleted",
    },
    {
      align: "center",
      title: adminLabels.completionDate,
      key: "completionDate",
      dataIndex: "completionDate",
    },
    {
      align: "center",
      title:
        adminLabels.serviceBillServiceBillOfServiceProviderAdditionalBudgetBill,
      key: "serviceBillServiceBillOfServiceProviderAdditionalBudgetBill",
      dataIndex: "serviceBillServiceBillOfServiceProviderAdditionalBudgetBill",
      render: (text, index) => (
        <div className="text-center	">
         
          <Button
            disabled={text === null}
            shape={"round"}
            onClick={() =>
              downloadFiles(
                text,
                `${
                  adminLabels.serviceBillServiceBillOfServiceProviderAdditionalBudgetBill
                }.${get_url_extension(text)}`
              )
            }
            className={`whiteTxt ${
              text === null ? "bg-disabled" : "bg-primary"
            }`}
          >
            {adminLabels.download}
          </Button>
        </div>
      ),
    },
    {
      align: "center",
      title: adminLabels.suplementsBillOfServiceProvider,
      key: "suplementsBillOfServiceProvider",
      dataIndex: "suplementsBillOfServiceProvider",
      render: (text, index) => (
        <div className="text-center	">
          <Button
            disabled={text === null}
            onClick={() =>
              downloadFiles(
                text,
                `${
                  adminLabels.suplementsBillOfServiceProvider
                }.${get_url_extension(text)}`
              )
            }
            shape={"round"}
            className={`whiteTxt ${
              text === null ? "bg-disabled" : "bg-primary"
            }`}
          >
            {adminLabels.download}
          </Button>
        </div>
      ),
    },
    {
      align: "center",
      title: adminLabels.billDocuDocAutomaticGenerated,
      render: (text, index) => (
        <div className="text-center	">
          <Button
            disabled={text === null}
            onClick={() =>
              downloadSystemGeneratedOrderBillFunction(text?.order_id)
            }
            shape={"round"}
            className={`whiteTxt ${
              text === null ? "bg-disabled" : "bg-primary"
            }`}
          >
            {adminLabels.download}
          </Button>
        </div>
      ),
    },
  ];

  const negativeSignForDisputeOrderAndBudget = (amount,isDispute = false) => {
    return isDispute? `-€${currencyFormat(amount)}` : `€${currencyFormat(amount)}`
  }
  const getAdminIncomePayment = () => {
    setLoading(true);

    getAdminBillLogs(
      (res) => {
        const data = res?.data?.response?.detail?.map((item, index) => {
          const userPersonal = item?.order?.data?.[0]?.personal;

          const servicePackType = item?.order?.service?.service_pack_type;
          const typeOfServiceProvider = item?.data?.business_type;
          const order_OR_AdditionalBudget_Dispute_Status = item?.data?.dispute;
          
          const isOderOrBudget = item?.data?.is_additional_budget 
          
          const dates = moment(isOderOrBudget ? item?.additional_budget?.created_at : item?.order?.created_at).format("DD-MMM-YYYY HH:mm");
          const BillNo = (isOderOrBudget ? item?.additional_budget?.[order_OR_AdditionalBudget_Dispute_Status? "reimb_id" : "bill_id"] : item?.order?.[order_OR_AdditionalBudget_Dispute_Status? "reimb_id" : "bill_id"]) || "-" ;
         
          const column_p = adminLabels?.[order_OR_AdditionalBudget_Dispute_Status ? "yes" : "no"] || "-";
          const column_q = order_OR_AdditionalBudget_Dispute_Status ? isOderOrBudget ? item?.additional_budget?.budget_dispute_refund?.type : item?.order?.order_dispute_refund?.type || "-" : "-";
          const column_r = column_q?.toLowerCase() === "partial" ? (Number(isOderOrBudget ? item?.additional_budget?.amount || 0: item?.service?.original_price || 0)) * (Number(isOderOrBudget ? Number(item?.additional_budget?.budget_dispute_refund?.provider_percent) / 100 : Number(item?.order?.order_dispute_refund?.provider_percent) / 100)) : (Number(isOderOrBudget ? item?.additional_budget?.amount || 0: item?.service?.original_price || 0));
          const column_s = Number(column_r / 1.21);
          const column_t =  item?.type === "order" ? Number(orderDiscountPercentage(item?.service)) : 0;
          const column_u = Number(column_s * (column_t / 100));
          const column_v = Number(column_s - column_u);
          const column_w = column_v * 0.21;
          const column_x = column_v + column_w;
          const column_y = column_q?.toLowerCase() === "partial" ? (Number(isOderOrBudget ? item?.additional_budget?.supplementary_fees || 0: item?.service?.supplementary_fees || 0)) * (Number(isOderOrBudget ? Number(item?.additional_budget?.budget_dispute_refund?.supplementary_provider_percent) / 100 : Number(item?.order?.order_dispute_refund?.supplementary_provider_percent) / 100)) : (Number(isOderOrBudget ? item?.additional_budget?.supplementary_fees || 0: item?.service?.supplementary_fees || 0));
          const column_z = column_v +  column_w + column_y;
          const column_aa = column_v * 0.3
          const column_ab = column_v * 0.7
          const column_ac = typeOfServiceProvider 
          const column_ad = typeOfServiceProvider === "self" ? Number(column_ab * 0.15) : 0
          const column_ae = column_w + column_ab + column_aa + column_y
          const column_af = item?.data?.transaction_id
          const column_ag = column_w * 0.3
          const column_ah = column_w * 0.7
          const column_ai = column_aa + column_ag
          const column_aj = column_ah + column_ab + column_y
          const column_ak = item?.service_provider?.name
          const column_al = isOderOrBudget ? item?.additional_budget?.status : item?.order_detail?.status
          const column_am = isOderOrBudget? moment(dates)?.add(item?.additional_budget?.time, item?.additional_budget?.time_type).local().format("DD-MMM-YYYY HH:mm") : servicePackType === "monthly" ? adminLabels[servicePackType] : moment(dates).add(item.service.time, item?.service.time_type).local().format("DD-MMM-YYYY HH:mm");
      
          return {
            ...item,
            no: index + 1,
            log_Bill_ID: BillNo,
            idOfPayment: column_af,

            product: {
              ...item.service,
              name: item.service.name,
              avatar: item.service.avatar,
              info: isOderOrBudget ? item.additional_budget?.notes : item.service.info,
            },
            additionalBudgetNumber: item?.additional_budget?.bill_id || "-",
            orderId: item.order_id,
            typeOfMovement: `${adminLabels?.[functionToConvertStringToCamelCase(item?.data?.movement?.replace("_", " "))]} ${item?.data?.movement === "order" && item?.service?.service_pack_type === "monthly" ? (`(${adminLabels?.[item?.service?.service_pack_type]})`) : ""} ${order_OR_AdditionalBudget_Dispute_Status ? (`(${adminLabels?.["reimbursement"]})`) : ""}`,
            date: moment(item.created_at)?.format("DD-MMM-YYYY"),
            clientNameSurName:
              `${item?.order?.data?.[0]?.personal?.firstName} ${item?.order?.data?.[0]?.personal?.lastName}` ||
              "-",
            clientCompanyName:
              item?.order?.data?.[0]?.personal.companyName || "-",
            clientCIfNIF: item?.order?.data?.[0]?.personal?.CIF || "-",
            clientAddress: item?.order?.data?.[0]?.personal?.address || "-",
            clientRegion: item?.order?.data?.[0]?.personal?.region || "-",
            clientCity: item?.order?.data?.[1]?.region?.city || "-",
            clientUserType:
              adminLabels?.[
                functionToConvertStringToCamelCase(
                  userPersonal?.none?.trim()?.length > 0
                    ? userPersonal?.none
                    : userPersonal?.business?.trim()?.length > 0
                    ? userPersonal?.business
                    : userPersonal?.self?.trim()?.length > 0
                    ? userPersonal?.self
                    : null
                )
              ],
            isDispute: column_p || "-",
            disputeComment: adminLabels[column_q] || "-",
            originalServiceVATPriceEstablishedInAdminPanel: negativeSignForDisputeOrderAndBudget(column_r,order_OR_AdditionalBudget_Dispute_Status),
            originalServicePriceEstablished: negativeSignForDisputeOrderAndBudget(column_s,order_OR_AdditionalBudget_Dispute_Status),
            discountPercentage: column_t,
            discountServiceVAT: negativeSignForDisputeOrderAndBudget(column_u,order_OR_AdditionalBudget_Dispute_Status),
            baseImponibleWithoutVATDiscount: negativeSignForDisputeOrderAndBudget(column_v,order_OR_AdditionalBudget_Dispute_Status),
            VATToBePayedOfServiceDiscount: negativeSignForDisputeOrderAndBudget(column_w,order_OR_AdditionalBudget_Dispute_Status),
            servicioDicount: negativeSignForDisputeOrderAndBudget(column_x,order_OR_AdditionalBudget_Dispute_Status),
            sumOfAllSupplements: negativeSignForDisputeOrderAndBudget(column_y,order_OR_AdditionalBudget_Dispute_Status),
            totalPayedByClient: negativeSignForDisputeOrderAndBudget(column_z,order_OR_AdditionalBudget_Dispute_Status),
            seviceDocuDoc30SeviceDiscount: negativeSignForDisputeOrderAndBudget(column_aa,order_OR_AdditionalBudget_Dispute_Status),
            seviceProvider70SeviceWithoutVAT: negativeSignForDisputeOrderAndBudget(column_ab,order_OR_AdditionalBudget_Dispute_Status),
            serviceProviderSelfEmployedORBusiness: adminLabels[column_ac],
            fifteenPercentageOfServiceProviderServiceVAT: negativeSignForDisputeOrderAndBudget(column_ad,order_OR_AdditionalBudget_Dispute_Status),
            totalPayedOnlyToDoubleCheck: negativeSignForDisputeOrderAndBudget(column_ae,order_OR_AdditionalBudget_Dispute_Status),
            VATDocuDoc:  negativeSignForDisputeOrderAndBudget(column_ag,order_OR_AdditionalBudget_Dispute_Status),
            VATServiceProvider: negativeSignForDisputeOrderAndBudget(column_ah,order_OR_AdditionalBudget_Dispute_Status),
            whichStaysInDocuDoc: negativeSignForDisputeOrderAndBudget(column_ai,order_OR_AdditionalBudget_Dispute_Status) ,
            toBePayedServiceProvider: negativeSignForDisputeOrderAndBudget(column_aj,order_OR_AdditionalBudget_Dispute_Status),
            serviceProviderName: column_ak,
            statusPenidngOrCompleted : adminLabels[column_al],
            completionDate: column_am,
            serviceBillServiceBillOfServiceProviderAdditionalBudgetBill:
              item?.type === "order"
                ? item?.order?.order_bill
                : item?.type === "additional_budget"
                ? item?.additional_budget?.budget_bill
                : null,
            suplementsBillOfServiceProvider:
              item?.type === "order"
                ? item?.order?.supplementary_bill
                : item?.type === "additional_budget"
                ? item?.additional_budget?.supplementary_bill
                : null,
            dateOfPayment: moment(item?.data?.date_payment)?.format(
              "DD-MMM-YYYY"
            ),

            // IVAAPagar21: column_t_8,
            // serviceDiscountVATFinalPriceOfService: column_u_9,
            // sumOfAllSupplements: column_v_10,
            // totalPayedByClient: column_w_11,
            // seviceDocuDoc30SeviceDiscount: column_x_12,
            // seviceProvider70SeviceWithoutVAT: column_y_13,
            // serviceProviderSelfEmployedORBusiness: adminLabels[typeOfServiceProvider],
            // serviceProviderName: item?.service_provider?.name,
            // fifteenPercentageOfServiceProviderServiceVAT: column_AI_20,
            // totalPayedOnlyToDoubleCheck: column_AJ_21,
            // idOfPayment: column_AK_22,
            // VATDocuDoc: column_AC_14,
            // VATServiceProvider: column_AD_15,
            // whichStaysInDocuDoc: column_AE_16,
            // toBePayedServiceProvider: column_AF_17,
            // completionDate: column_AH_18,
            // serviceBillServiceBillOfServiceProviderAdditionalBudgetBill:
            //   item?.type === "order"
            //     ? item?.order?.order_bill
            //     : item?.type === "additional_budget"
            //     ? item?.additional_budget?.budget_bill
            //     : null,
            // suplementsBillOfServiceProvider:
            //   item?.type === "order"
            //     ? item?.order?.supplementary_bill
            //     : item?.type === "additional_budget"
            //     ? item?.additional_budget?.supplementary_bill
            //     : null,
            // dateOfPayment: moment(item?.data?.date_payment)?.format(
            //   "DD-MMM-YYYY"
            // ),
            // statusPenidngOrCompleted: column_AG_19,
          };
        });
        setBillLogsData(data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    getAdminIncomePayment();
  }, []);
  return (
    <section className="pb-10">
      <Spin tip={adminLabels.loading} spinning={loading}>
        <div className="flex justify-start lg:justify-between my-5  flex-wrap sm:flex-wrap">
          <div className="pl-5 flex self-center justify-center items-center sub-h3 mb-4 sm:mb-4 lg:mb-0">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{adminLabels.back}</span>
            </div>
            <h3 className="ml-10">{adminLabels.payments}</h3>
          </div>
          <div className="lg:mr-28 flex justify-end flex-wrap sm:flex-wrap">
            <div className="ml-2">
              <CSVLink
                filename={`${adminLabels.payments}.csv`}
                target="_blank"
                data={billLogsData?.map((item, index) => {
                  return {
                    ...item,
                    no: index + 1,
                    product: item?.service.name,
                    orderId: item?.order_id,
                  };
                })}
                headers={[
                  {
                    label: adminLabels.no,
                    key: "no",
                  },
                  {
                    label: adminLabels.billNumber,
                    key: "log_Bill_ID",
                  },
                  {
                    label: adminLabels.serviceName,
                    key: "product",
                  },
                  {
                    label: adminLabels.orderId,
                    key: "order_id",
                  },
                  {
                    label: adminLabels.additionalBudgetNumber,
                    key: "additionalBudgetNumber",
                  },
                  {
                    label: adminLabels.typeOfMovement,
                    key: "typeOfMovement",
                  },
                  {
                    label: adminLabels.dateOfPayment,
                    key: "dateOfPayment",
                  },
                  {
                    label: adminLabels.nameAndSurnameOfClient,
                    key: "clientNameSurName",
                  },

                  {
                    label: adminLabels.region,
                    key: "clientRegion",
                  },
                  {
                    label: adminLabels.city,
                    key: "clientCity",
                  },
                  {
                    label: adminLabels.userType,
                    key: "clientUserType",
                  },

                  {
                    label: adminLabels.companyName,
                    key: "clientCompanyName",
                  },
                  {
                    label: adminLabels.cifOrNif,
                    key: "clientCIfNIF",
                  },
                  {
                    label: adminLabels.companyAddress,
                    key: "clientAddress",
                  },

                  {
                    label: adminLabels.dispute,
                    key: "isDispute",
                  },
                  {
                    label: adminLabels.comment,
                    key: "disputeComment",
                  },
                  {
                    label:
                      adminLabels.originalServiceVATPriceEstablishedInAdminPanel,
                    key: "originalServiceVATPriceEstablishedInAdminPanel",
                  },
                  {
                    label: adminLabels.originalServicePriceEstablished,
                    key: "originalServicePriceEstablished",
                  },
                  {
                    label: adminLabels.discount,
                    key: "discountPercentage",
                  },
                  {
                    label: adminLabels.discountServiceVAT,
                    key: "discountServiceVAT",
                  },
                  {
                    label: adminLabels.baseImponibleWithoutVATDiscount,
                    key: "baseImponibleWithoutVATDiscount",
                  },
                  {
                    label: adminLabels.VATToBePayedOfServiceDiscount,
                    key: "VATToBePayedOfServiceDiscount",
                  },
                  {
                    label: adminLabels.servicioDicount,
                    key: "servicioDicount",
                  },
                 
                  {
                    label: adminLabels.sumOfAllSupplements,
                    key: "sumOfAllSupplements",
                  },
                  {
                    label: adminLabels.totalPayedByClient,
                    key: "totalPayedByClient",
                  },
                  {
                    label: adminLabels.seviceDocuDoc30SeviceDiscount,
                    key: "seviceDocuDoc30SeviceDiscount",
                  },
                  {
                    label: adminLabels.seviceProvider70SeviceWithoutVAT,
                    key: "seviceProvider70SeviceWithoutVAT",
                  },
                  {
                    label: adminLabels.serviceProviderSelfEmployedORBusiness,
                    key: "serviceProviderSelfEmployedORBusiness",
                  },
                  {
                    label:
                      adminLabels.fifteenPercentageOfServiceProviderServiceVAT,
                    
                    key: "fifteenPercentageOfServiceProviderServiceVAT",
                    
                  },

                  {
                    label: adminLabels.totalPayedOnlyToDoubleCheck,
                    
                    key: "totalPayedOnlyToDoubleCheck",
                    
                  },
                  {
                    label: adminLabels.idOfPayment,
                    
                    key: "idOfPayment",
                    
                  },
                  {
                    label: adminLabels.serviceProvider,

                    key: "serviceProviderName",
                  },
                  {
                    label: adminLabels.VATDocuDoc,
                    key: "VATDocuDoc",
                  },
                  {
                    label: adminLabels.VATServiceProvider,
                    key: "VATServiceProvider",
                  },
                  {
                    label: adminLabels.whichStaysInDocuDoc,
                    key: "whichStaysInDocuDoc",
                  },
                  {
                    label: adminLabels.toBePayedServiceProvider,
                    key: "toBePayedServiceProvider",
                  },

                  {
                    label: adminLabels.completionDate,
                    key: "completionDate",
                  },
                  {
                    label: adminLabels.statusPenidngOrCompleted,
                    key: "statusPenidngOrCompleted",
                  },
                  {
                    label: adminLabels.completionDate,
                    key: "completionDate",
                  },
                ]}
              >
                <Button
                  shape={"round"}
                  className="icon-button "
                  icon={<AiOutlineArrowDown color="#5ab3f0" />}
                >
                  {adminLabels.exportCSV}
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <div className="container mx-auto lg:px-20 mt-5">
          <TableComponent
            scroll={"1150%"}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
            scrolly="500px"
            columns={tableColumns}
            data={billLogsData}
          />
        </div>
      </Spin>
      <Modal
        visible={showInfoModal}
        centered
        footer={null}
        onCancel={() => {
          setShowInfoModal(false);
        }}
        className="global-modal-css-scroll-height "
      >
        <div>
          <h4 className="font-bold text-2xl text-center text-gray-600	">
            {adminLabels.information}
          </h4>
        </div>
        <div className="scrollable-fixed-height py-5 render-html-class">
          {<InformationContainer info={modal_Content} />}
        </div>
      </Modal>
    </section>
  );
}
