import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import SubAdminHeader from "../components/Dashboard/SubAdminHeader";
import { Images } from "../constant/Images";
import AddWorkerServiceAdmin from "../screens/addWorkerServiceProvider";
import MyMessage from "../screens/myMessage";
import AddMyMessage from "../screens/myMessageAdd";
import MessageDetail from "../screens/myMessageDetail";
import AddMyMessageOrder from "../screens/myMessageOrder";
import OrderAssgin from "../screens/orderAssign";
import OrdersAssignation from "../screens/orderAssignation";
import orderInfoDetail from "../screens/orderInfo";
import orderInfoClient from "../screens/orderInfoClient";
import Orders from "../screens/orders";
import OrderSteps from "../screens/orderSteps";
import Profile from "../screens/profile";
import ProviderAdditionalBudgets from "../screens/providerAdditionalBudgets";
import providerSideworkerDetail from "../screens/providerSideworkerDetail";
import ServiceProviderAllOrders from "../screens/serviceProviderAllOrders";
import ServiceWorkerOrderDetails from "../screens/ServiceWorkerOrderDetails";
import ProviderSideServiceWorkerOrderDetails from "../screens/ServiceWorkerOrderDetails";
import WorkerServiceProvider from "../screens/workerServiceProvider";
import DisputeChatMessages from "../screens/disputeChat";
import serviceProviderIncomePaymentsBills from "../screens/serviceProviderIncomePaymentsBills";
import { spanishLabels } from "../config/label/providerLabels";
const { dotPattern } = Images;
export default function AdminServiceProviderSecrion(params) {
  useEffect(() => {
    document.title = spanishLabels.DoCuDocPartner;
  }, []);
  return (
    <>
      <SubAdminHeader />
      <div className="absolute right-10 top-20 " style={{ zIndex: -1 }}>
        <img src={dotPattern.default} />
      </div>
      <Route path="/dashboard" component={OrdersAssignation} />
      <Route path="/order-assignation" component={Orders} />
      <Route path="/all-orders" component={ServiceProviderAllOrders} />
      <Route path="/dispute-chat/:id" component={DisputeChatMessages} />
      <Route path="/disputed/:id" component={DisputeChatMessages} />
      <Route path="/my-message" component={MyMessage} />
      <Route path="/order-info-detail/:id" component={orderInfoDetail} />
      <Route path="/order-info-client" component={orderInfoClient} />
      <Route path="/profile" component={Profile} />
      <Route
        path="/incomes-payments-bills"
        component={serviceProviderIncomePaymentsBills}
      />
      <Route
        path="/worker-of-service-provider"
        component={WorkerServiceProvider}
      />
      <Route
        path="/add-worker-of-service-provider"
        component={AddWorkerServiceAdmin}
      />
      <Route
        path="/edit-worker-of-service-provider/:id"
        component={AddWorkerServiceAdmin}
      />

      <Route path="/add-my-message-order/:id/" component={AddMyMessageOrder} />
      <Route path="/message-detail/:id" component={MessageDetail} />
      <Route path="/add-my-message" component={AddMyMessage} />
      <Route path="/order-assign/:id" component={OrderAssgin} />
      <Route path="/order-steps/:id" component={OrderSteps} />
      <Route
        path="/worker-detail/:workerID"
        exact
        component={providerSideworkerDetail}
      />

      <Route
        path="/worker-service-order-details/:orderId"
        exact
        component={ProviderSideServiceWorkerOrderDetails}
      />
      <Route
        path="/additional-budget"
        exact
        component={ProviderAdditionalBudgets}
      />
    </>
  );
}
