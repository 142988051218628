import {
  DeleteOutlined,
  DownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Dropdown,
  Input,
  Menu,
  Spin,
  Tooltip
} from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import {
  AiOutlineArrowDown,
  AiOutlineDownload
} from "react-icons/ai";
import { RiArrowDropLeftLine } from "react-icons/ri";
import ReactQuill from "react-quill";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ModalComponent } from "../../components";
import { Errors, Success } from "../../components/antdToast";
import ButtonComponent from "../../components/ButtonComponent";
import { Remove_Data_Form_Provider_Reducers } from "../../components/dataRemoveOnProviderLogout";
import InformationContainer from "../../components/informationContainer";
import InputComponent from "../../components/InputComponent";
import TableComponent from "../../components/table";
import {
  AdminAndServiceProviderAdditionalBudgetReadMethod,
  EditAdditonalBudgetMethod,
  SendAdditionalBudgetBillMethod,
  UploadFileMethod,
} from "../../config/api";
import myConstant from "../../config/constants";
import { providerLabels } from "../../config/label/providerLabels";
import {
  capitalizeFirstLetter,
  europeanCurrencyFormatter,
  functionToPreventSignsFromNumberFromPricing,
  get_url_extension,
  regexForPricing,
  regexToReplaceDecimalsWithComma,
} from "../../config/utils";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { ProviderLogoutAction } from "../../Redux/actions/logoutAction";
import { fetchServiceProviderAdditionalBudgets } from "../../Redux/actions/serviceProviderActions/additionalBudgets";

function Additional_Budget(props) {
  const currentSelectedLangue = localStorage.getItem("primaryLanguage");
  const [additionalBudget, setAdditionalBudget] = useState([]);
  const [pendingAdditionalBudget, setPendingAdditionalBudget] = useState([]);
  const [acceptedAdditionalBudget, setAcceptedAdditionalBudget] = useState([]);
  const [declinedAdditionalBudget, setDeclinedAdditionalBudget] = useState([]);
  const [completedAdditionalBudget, setCompletedAdditionalBudget] = useState(
    []
  );
  const [disputeAdditionalBudget, setDisputeAdditionalBudget] = useState([]);
  const [disputedAdditionalBudget, setDisputedAdditionalBudget] = useState([]);

  const { goBack, push } = useHistory();
  const dispatch = useDispatch();
  const budgets = useSelector(
    (state) => state?.serviceProviderAllAdditionalBudgets?.budgets
  );
  const budgetsLoading = useSelector(
    (state) => state?.serviceProviderAllAdditionalBudgets?.loading
  );
  const [loading, setLoading] = useState(true);

  const [value, setValue] = useState("");

  const [filterModal, setFilterModal] = useState(false);
  const [reasonModal, setReasonModal] = useState(false);
  const [additionalBudgetDocument, setAdditionalBudgetDocument] = useState([]);
  const [estimatedTime, setEstimatedTime] = useState("");
  const [fieldIsRequired, setFieldIsrequired] = useState(false);
  const [stepsIncrement, setStepsIncrement] = useState([
    {
      id: Math.round(Math.random() * 100),
      name: "",
      price: 0,
    },
  ]);
  const [supplementaryCharges, setSupplementaryCharges] = useState([]);
  const [isSupplementaryCharges, setIsSupplementaryCharges] = useState(false);
  const [isBudgetSendLoading, setIsBudgetSendLoading] = useState(false);

  const [urls, setUrls] = useState([]);
  const [addBudgetUrls, setAddBudgetUrls] = useState([]);
  const [muiltipleDocument, setMuiltipleDocument] = useState("");
  const [budget, setBudget] = useState(null);
  const [current, setCurrent] = useState(0);

  const [additionalBudgetData, setAdditionalBudgetData] = useState({});
  const [serviceTimeType, setServiceTimeType] = useState([
    "Minute",
    "Hour",
    "Week",
    "Day",
  ]);
  const [selectedTimeType, setSelectedTimeType] = useState(null);
  const [serviceTime, setServiceTime] = useState(null);
  const [visible, setVisible] = useState(false);

  const [budgetUrls, setBudgetUrls] = useState([]);
  const [budgetResponseDetail, setBudgetResponseDetail] = useState({});
  const [additionalBudgetDetailModal, setAdditionalBudgetDetailModal] =
    useState(false);
  const [index, setIndex] = useState(0);
  const [finishAdditionalBudgetModal, setFinishAdditionalBudgetModal] =
    useState(false);
  const [additionalBudgetSteps, setAdditionalBudgetSteps] = useState([
    { id: 0, title: providerLabels.all, status: "all" },
    {
      id: 1,
      title: providerLabels.pendingToAccept,
      status: "pending",
      count: 0,
    },
    {
      id: 2,
      title: providerLabels.accepted,
      status: "accepted",
      count: 0,
    },
    {
      id: 3,
      title: providerLabels.changeforRequest,
      status: "declined",
      count: 0,
    },
    {
      id: 5,
      title: providerLabels.dispute,
      status: "dispute",
      count: 0,
    },
    {
      id: 6,
      title: providerLabels.disputed,
      status: "disputed",
      count: 0,
    },
    {
      id: 4,
      title: providerLabels.completed,
      status: "completed",
      count: 0,
    },
  ]);
  const [additionalBudgetInVoiceBill, setAdditionalBudgetInVoiceBill] =
    useState("");
  const [supplementaryInVoiceBill, setSupplementaryInVoiceBill] = useState("");

  useEffect(() => {
    dispatch(
      fetchServiceProviderAdditionalBudgets(
        props?.providerDetail?.admin?.service_provider_id,
        push
      )
    );
  }, []);
  useEffect(() => {
    let addBudget = budgets?.map((adb, i) => {
      return {
        ...adb,
        no: i + 1,
        product: adb?.order?.service,
        nameOfService:
          budget?.order?.service?.[
            currentSelectedLangue === "english" ? "name" : "name_sp"
          ],
        date: moment(adb?.created_at)?.format("DD-MMM-YYYY HH:mm"),

        userName: `${adb?.order?.data?.[0]?.personal.firstName} ${adb?.order?.data?.[0]?.personal.lastName}`,
        additionalBudgetAmount: adb?.amount,
        status: adb?.status,
        orderID: adb?.order_id,
      };
    });
    setAdditionalBudget(addBudget);
    setPendingAdditionalBudget(
      addBudget
        ?.filter((item) => item?.status === "pending")
        ?.map((adb, i) => {
          return {
            ...adb,
            no: i + 1,
          };
        })
    );
    setAcceptedAdditionalBudget(
      addBudget
        ?.filter((item) => item?.status === "accepted")
        ?.map((adb, i) => {
          return {
            ...adb,
            no: i + 1,
          };
        })
    );
    setDeclinedAdditionalBudget(
      addBudget
        ?.filter((item) => item?.status === "declined")
        ?.map((adb, i) => {
          return {
            ...adb,
            no: i + 1,
          };
        })
    );
    setCompletedAdditionalBudget(
      addBudget
        ?.filter((item) => item?.status === "completed")
        ?.map((adb, i) => {
          return {
            ...adb,
            no: i + 1,
          };
        })
    );
    setDisputeAdditionalBudget(
      addBudget
        ?.filter((item) => item?.status === "dispute")
        ?.map((adb, i) => {
          return {
            ...adb,
            no: i + 1,
          };
        })
    );
    setDisputedAdditionalBudget(
      addBudget
        ?.filter((item) => item?.status === "disputed")
        ?.map((adb, i) => {
          return {
            ...adb,
            no: i + 1,
          };
        })
    );
  }, [budgets]);

  useEffect(() => {
    setAdditionalBudgetSteps((prev) =>
      prev.map((item) => {
        if (item.id === 1) {
          return {
            ...item,
            count: pendingAdditionalBudget.filter(
              (item) => item?.is_read_provider === 0
            )?.length,
          };
        } else if (item.id === 2) {
          return {
            ...item,
            count: acceptedAdditionalBudget.filter(
              (item) => item?.is_read_provider === 0
            )?.length,
          };
        } else if (item.id === 3) {
          return {
            ...item,
            count: declinedAdditionalBudget.filter(
              (item) => item?.is_read_provider === 0
            )?.length,
          };
        } else if (item.id === 4) {
          return {
            ...item,
            count: completedAdditionalBudget.filter(
              (item) => item?.is_read_provider === 0
            )?.length,
          };
        } else if (item.id === 5) {
          return {
            ...item,
            count: disputeAdditionalBudget.filter(
              (item) => item?.is_read_provider === 0
            )?.length,
          };
        } else if (item.id === 6) {
          return {
            ...item,
            count: disputedAdditionalBudget.filter(
              (item) => item?.is_read_provider === 0
            )?.length,
          };
        } else {
          return item;
        }
      })
    );
  }, [
    pendingAdditionalBudget,
    acceptedAdditionalBudget,
    declinedAdditionalBudget,
    completedAdditionalBudget,
    disputeAdditionalBudget,
    disputedAdditionalBudget,
  ]);

  useEffect(() => {
    setLoading(budgetsLoading);
  }, [budgetsLoading]);

  const columns = [
    {
      align: "center",
      title: providerLabels.no,
      dataIndex: "no",
      key: "no",
      width: 40,
      render: (text) => <a>{text}</a>,
    },
    {
      align: "center",
      title: providerLabels.product,
      dataIndex: "product",
      width: 180,
      key: "product",
      render: (text, index) => (
        <Tooltip placement="topLeft" title={text?.name}>
          <div className={"flex"}>
            <img
              src={text?.avatar}
              style={{ width: 50, height: 45 }}
              alt="detail image"
            />
            <div className="pl-2 text-left">
              <label>
                <b>
                  {
                    text?.[
                      currentSelectedLangue === "english" ? "name" : "name_sp"
                    ]
                  }
                </b>
              </label>{" "}
              <br />
            </div>
          </div>
        </Tooltip>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a?.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]?.localeCompare(
          b?.product?.[currentSelectedLangue === "english" ? "name" : "name_sp"]
        ),
    },
    {
      title: providerLabels.date,
      key: "date",
      dataIndex: "date",
      align: "center",
      width: 130,
      align: "center",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      showSorterTooltip: false,
      render: (date) => (
        <Tooltip placement="topLeft" title={date}>
          {date}
        </Tooltip>
      ),
    },
    {
      align: "center",
      title: providerLabels.orderID,
      dataIndex: "orderID",
      key: "orderID",
      width: 120,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.orderID.toLowerCase().includes(value.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.orderID?.localeCompare(b?.orderID),
    },

    {
      align: "center",
      title: providerLabels.userName,
      dataIndex: "userName",
      className: "capitalize",
      key: "userName",
      width: 120,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.userName.toLowerCase().includes(value.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.userName?.localeCompare(b?.userName),
    },
    {
      align: "center",
      title: providerLabels.additionalBudgetAmount,
      key: "additionalBudgetAmount",
      dataIndex: "additionalBudgetAmount",
      width: 100,
      render: (text, index) => (
        <div className="text-center	"> €{europeanCurrencyFormatter(text)}</div>
      ),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        Number(a.additionalBudgetAmount) - Number(b.additionalBudgetAmount),
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: providerLabels.status,
      key: "status",

      width: 100,
      render: (text, index) => (
        <span
          style={{
            color:
              text.status === "accepted"
                ? "green"
                : text.status === "declined"
                ? "red"
                : "black",
            fontWeight: "bold",
          }}
        >
          {text.status === "declined" ? (
            <div className="flex flex-col">
              <span className="text-danger">
                {" "}
                {providerLabels.changeforRequest}
              </span>
              <span
                className="text-black cursor-pointer"
                onClick={() => {
                  setAdditionalBudgetData(text);
                  setReasonModal(true);
                }}
              >
                {providerLabels.reason}
              </span>
            </div>
          ) : (
            <span className="text-danger capitalize">
              {providerLabels[text.status]}
            </span>
          )}
        </span>
      ),
    },

    {
      title: providerLabels.action,
      key: "action",
      width: 90,

      render: (text, index) => (
        <>
          <div className="text-center	gap-1 flex items-center justify-center">
            <div className="mb-1">
              {!text?.is_read_provider ? (
                <span className="font-bold text-red-500 text-4xl mr-1">.</span>
              ) : (
                <span className="font-bold font-color-blue text-4xl mr-1 opacity-0">
                  .
                </span>
              )}
            </div>
            <div className="flex flex-col w-full gap-2">
              <div
                type="disable"
                shape={"round"}
                className="background-color-gray whiteTxt rounded-full py-1 justify-center cursor-pointer	w-full"
                onClick={() => {
                  setBudgetUrls(JSON?.parse(text?.files));
                  setBudgetResponseDetail(text);
                  setAdditionalBudgetDetailModal(true);
                }}
              >
                <span>{providerLabels.view}</span>
              </div>
              {text?.status === "accepted" ? (
                <>
                  <div
                    type="disable"
                    shape={"round"}
                    className="background-color-gray whiteTxt rounded-full py-1 justify-center cursor-pointer	w-full capitalize"
                    onClick={() => {
                      setBudgetUrls(JSON?.parse(text?.files));
                      setBudgetResponseDetail(text);
                      setFinishAdditionalBudgetModal(true);
                    }}
                  >
                    <span>{providerLabels.finish}</span>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </>
      ),
    },
  ];
  const acceptedBudgetColumns = [
    {
      align: "center",
      title: providerLabels.no,
      dataIndex: "no",
      key: "no",
      width: 40,
      render: (text) => <a>{text}</a>,
    },
    {
      align: "center",
      title: providerLabels.product,
      dataIndex: "product",
      width: 180,
      key: "product",
      render: (text, index) => (
        <Tooltip placement="topLeft" title={text?.name}>
          <div className={"flex"}>
            <img
              src={text?.avatar}
              style={{ width: 50, height: 45 }}
              alt="detail image"
            />
            <div className="pl-2 text-left">
              <label>
                <b>
                  {
                    text?.[
                      currentSelectedLangue === "english" ? "name" : "name_sp"
                    ]
                  }
                </b>
              </label>{" "}
              <br />
            </div>
          </div>
        </Tooltip>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a?.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]?.localeCompare(
          b?.product?.[currentSelectedLangue === "english" ? "name" : "name_sp"]
        ),
    },
    {
      title: providerLabels.date,
      key: "date",
      dataIndex: "date",
      align: "center",
      width: 130,
      align: "center",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      showSorterTooltip: false,
      render: (date) => (
        <Tooltip placement="topLeft" title={date}>
          {date}
        </Tooltip>
      ),
    },
    {
      align: "center",
      title: providerLabels.orderID,
      dataIndex: "orderID",
      key: "orderID",
      width: 120,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.orderID.toLowerCase().includes(value.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.orderID?.localeCompare(b?.orderID),
    },

    {
      align: "center",
      title: providerLabels.userName,
      dataIndex: "userName",
      className: "capitalize",
      key: "userName",
      width: 120,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.userName.toLowerCase().includes(value.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.userName?.localeCompare(b?.userName),
    },
    {
      align: "center",
      title: providerLabels.additionalBudgetAmount,
      key: "additionalBudgetAmount",
      dataIndex: "additionalBudgetAmount",
      width: 100,
      render: (text, index) => (
        <div className="text-center	"> €{europeanCurrencyFormatter(text)}</div>
      ),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        Number(a.additionalBudgetAmount) - Number(b.additionalBudgetAmount),
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: providerLabels.status,
      key: "status",

      width: 100,
      render: (text, index) => (
        <span
          style={{
            color:
              text.status === "accepted"
                ? "green"
                : text.status === "declined"
                ? "red"
                : "black",
            fontWeight: "bold",
          }}
        >
          {text.status === "declined" ? (
            <div className="flex flex-col">
              <span className="text-danger">
                {" "}
                {providerLabels.changeforRequest}
              </span>
              <span
                className="text-black cursor-pointer"
                onClick={() => {
                  setAdditionalBudgetData(text);
                  setReasonModal(true);
                }}
              >
                {providerLabels.reason}
              </span>
            </div>
          ) : (
            <span className="text-danger capitalize">
              {providerLabels[text.status]}
            </span>
          )}
        </span>
      ),
    },

    {
      title: providerLabels.action,
      key: "action",
      width: 90,

      render: (text, index) => (
        <>
          <div className="text-center	gap-1 flex items-center justify-center">
            <div className="mb-1">
              {!text?.is_read_provider ? (
                <span className="font-bold text-red-500 text-4xl mr-1">.</span>
              ) : (
                <span className="font-bold font-color-blue text-4xl mr-1 opacity-0">
                  .
                </span>
              )}
            </div>
            <div className="flex flex-col w-full gap-2">
              <div
                type="disable"
                shape={"round"}
                className="background-color-gray whiteTxt rounded-full py-1 justify-center cursor-pointer	w-full"
                onClick={() => {
                  setBudgetUrls(JSON?.parse(text?.files));
                  setBudgetResponseDetail(text);
                  setAdditionalBudgetDetailModal(true);
                }}
              >
                <span>{providerLabels.view}</span>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];
  const handleText = (e, index) => {
    let mappedStepsIncrement = stepsIncrement?.map((value, index1) => {
      if (index1 === index) {
        return {
          name: e.target.value,
          id: value.id,
          price: value?.price,
        };
      } else {
        return value;
      }
    });

    setStepsIncrement(mappedStepsIncrement);
  };
  const handleAmount = (e, index) => {
    const mappedStepsIncrement = stepsIncrement?.map((value, index1) => {
      if (index1 === index) {
        return {
          name: value?.name,
          id: value?.id,
          price: regexForPricing(e.target.value, e.nativeEvent) || 1,
        };
      } else {
        return value;
      }
    });

    setStepsIncrement(mappedStepsIncrement);
  };
  const prev = (id, index) => {
    supplementaryCharges.splice(index, 1);
    deleteStepInput(id);
  };
  // delete antd stepper in dropdownmodal function
  const deleteStepInput = (id) => {
    setCurrent(current - 1);
    let updatedArray = stepsIncrement?.filter((item) => {
      return item.id != id;
    });
    setStepsIncrement(updatedArray);
  };
  //stepper add in dropdown modal function
  const next = () => {
    setStepsIncrement((prevState) => {
      setCurrent(current + 1);
      return [
        ...prevState,
        {
          id: Math.round(Math.random() * 100),
          name: "",
          price: 0,
        },
      ];
    });
  };

  const getUrl = (url, name, type, id) => {
    setUrls((prevurls) => [...prevurls, { url, name, type, id }]);
  };
  const selectMuiltipleDocument = async (valueArray) => {
    let muiltipleFileNames = "";
    valueArray?.map((r, i) => {
      muiltipleFileNames += `${r.name}, `;
    });
    valueArray?.map((file, i) => {
      let formData = new FormData();
      formData.append("file", file);
      UploadFileMethod(
        formData,
        (success) => {
          if (success.status === 200) {
            if (success.data.response.detail !== null) {
              let id = Math.round(Math.random() * 10000);
              getUrl(success.data.response.detail, file.name, file.type, id);
            }
          }
        },
        (err) => {
          if (err?.response?.status === 500) {
            Errors(err?.response?.data?.error?.message);
          } else if (err?.response?.status === 422) {
            Errors(err?.response?.data?.error?.message);
          } else {
            Errors("Network Error");
          }
        }
      );
    });
    setMuiltipleDocument(muiltipleFileNames);
  };
  const fileprev = (item, index) => {
    setAddBudgetUrls(addBudgetUrls?.filter((file) => file.id !== item.id));
  };
  const getadditionalBudgetUrl = (url, name, type, id) => {
    setAddBudgetUrls((prevurls) => [...prevurls, { url, name, type, id }]);
  };
  const selectMuiltipleBudgetDocument = async (valueArray) => {
    let muiltipleFileNames = "";
    valueArray?.map((r, i) => {
      muiltipleFileNames += `${r.name}, `;
    });
    valueArray?.map((file, i) => {
      let formData = new FormData();
      formData.append("file", file);
      UploadFileMethod(
        formData,
        (success) => {
          if (success.status === 200) {
            if (success.data.response.detail !== null) {
              let id = Math.round(Math.random() * 10000);
              getadditionalBudgetUrl(
                success.data.response.detail,
                file.name,
                file.type,
                id
              );
            }
          }
        },
        (err) => {
          if (err?.response?.status === 500) {
            Errors(err?.response?.data?.error?.message);
          } else if (err?.response?.status === 422) {
            Errors(err?.response?.data?.error?.message);
          } else {
            Errors("Network Error");
          }
        }
      );
    });
    setAdditionalBudgetDocument(muiltipleFileNames);
  };

  const editAdditionalBudget = (_) => {
    if (
      value?.trim()?.length !== 0 &&
      value !== "" &&
      serviceTime !== null &&
      selectedTimeType !== null &&
      budget !== null &&
      Number(regexToReplaceDecimalsWithComma(budget) > -1) &&
      (isSupplementaryCharges
        ? stepsIncrement?.some(
            (supItem) =>
              supItem?.name?.trim()?.length !== 0 || Number(supItem?.price) > 1
          )
        : true)
    ) {
      let obj = {
        additional_budget_id: additionalBudgetData?.additional_budget_id,
        order_id: additionalBudgetData?.order_id,
        amount: regexToReplaceDecimalsWithComma(budget),
        user_id: additionalBudgetData.user_id,
        service_provider_id: additionalBudgetData.service_provider_id,
        service_id: additionalBudgetData.service_id,
        supplementary_fees:
          isSupplementaryCharges === true
            ? JSON.stringify(
                stepsIncrement?.map((item) => {
                  return {
                    ...item,
                    price: item?.price?.replace(",", "."),
                  };
                })
              )
            : JSON.stringify([]),
        notes: value,
        time: serviceTime,
        time_type: selectedTimeType,
        urls:
          addBudgetUrls.length === 0
            ? JSON.stringify([])
            : JSON.stringify(addBudgetUrls),
      };
      setIsBudgetSendLoading(true);
      setFieldIsrequired(false);
      EditAdditonalBudgetMethod(
        obj,
        (success) => {
          if (success.status === 200) {
            setIsBudgetSendLoading(false);
            dispatch(
              fetchServiceProviderAdditionalBudgets(
                props?.providerDetail?.admin?.service_provider_id,
                push
              )
            );
            Success(success.data.response.message);
            setFilterModal(false);
            setBudget(0);
            setStepsIncrement([
              {
                id: Math.round(Math.random() * 100),
                name: "",
                price: 0,
              },
            ]);
            setAddBudgetUrls([]);
            setEstimatedTime("");
            setSelectedTimeType(null);
            setServiceTime(null);
            setValue("");
            setIsSupplementaryCharges(false);
          }
        },
        (err) => {
          setIsBudgetSendLoading(false);

          if (err?.response?.status === 500) {
            Errors(err?.response?.data?.error?.message);
          } else if (err?.response?.status === 422) {
            Errors(err?.response?.data?.error?.message);
          } else {
            Errors("Network Error");
          }
        }
      );
    } else {
      setFieldIsrequired(true);
    }
  };

  const handleMenuClick = (e) => {
    setSelectedTimeType(e.key);
    if (e.key === "3") {
      setVisible(false);
    }
  };
  const handleAdditionalBudgetFileInvoiceBill = (e) => {
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    UploadFileMethod(
      formData,
      (success) => {
        if (success.status === 200) {
          if (success.data.response.detail !== null) {
            setAdditionalBudgetInVoiceBill(success.data.response.detail);
          }
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const handleSupplementaryFileInvoiceBill = (e) => {
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    UploadFileMethod(
      formData,
      (success) => {
        if (success.status === 200) {
          if (success.data.response.detail !== null) {
            setSupplementaryInVoiceBill(success.data.response.detail);
          }
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const menu1 = (
    <Menu onClick={handleMenuClick}>
      {serviceTimeType?.map((type) => {
        return (
          <Menu.Item className="capitalize" key={type}>
            {providerLabels[type]}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const downloadFiles = (url, name) => {
    axios({
      url: url,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {});
  };
  const downloadBillFunction = (additional_Budget) => {
    downloadFiles(
      `https://api.docudocapp.com/api/download_additional_budget_bill/${additional_Budget?.additional_budget_id}`,
      `${providerLabels.bill}.pdf`
    );
  };
  const handleReadAdditionalBudget = () => {
    if (additionalBudgetDetailModal) {
      const obj = {
        order_id: budgetResponseDetail.order_id,
        additional_budget_id: budgetResponseDetail?.additional_budget_id,
        read_user: "is_read_provider",
      };
      AdminAndServiceProviderAdditionalBudgetReadMethod(
        obj,
        (res) => {
          if (res?.data?.status) {
            dispatch(
              fetchServiceProviderAdditionalBudgets(
                props?.providerDetail?.admin?.service_provider_id,
                push
              )
            );
            if (index === 0) {
              setAdditionalBudget((prev) =>
                prev.map((item) => {
                  if (
                    item?.additional_budget_id ===
                    budgetResponseDetail?.additional_budget_id
                  ) {
                    return {
                      ...item,
                      is_read_provider: 1,
                    };
                  } else {
                    return item;
                  }
                })
              );
            }
            if (index === 1) {
              setPendingAdditionalBudget((prev) =>
                prev.map((item) => {
                  if (
                    item?.additional_budget_id ===
                    budgetResponseDetail?.additional_budget_id
                  ) {
                    return {
                      ...item,
                      is_read_provider: 1,
                    };
                  } else {
                    return item;
                  }
                })
              );
            }
            if (index === 2) {
              setAcceptedAdditionalBudget((prev) =>
                prev.map((item) => {
                  if (
                    item?.additional_budget_id ===
                    budgetResponseDetail?.additional_budget_id
                  ) {
                    return {
                      ...item,
                      is_read_provider: 1,
                    };
                  } else {
                    return item;
                  }
                })
              );
            }
            if (index === 3) {
              setDeclinedAdditionalBudget((prev) =>
                prev.map((item) => {
                  if (
                    item?.additional_budget_id ===
                    budgetResponseDetail?.additional_budget_id
                  ) {
                    return {
                      ...item,
                      is_read_provider: 1,
                    };
                  } else {
                    return item;
                  }
                })
              );
            }
          }
        },
        (err) => {
          if (err?.response?.status === 500) {
            Errors(err?.response?.data?.error?.message);
          } else if (err?.response?.status === 422) {
            Errors(err?.response?.data?.error?.message);
          } else {
            Errors("Network Error");
          }
        }
      );
    }
  };
  const sendBillToUser = (id) => {
    let obj = {
      additional_budget_id: id,
      budget_bill: additionalBudgetInVoiceBill,
      supplementary_bill: supplementaryInVoiceBill,
    };
    SendAdditionalBudgetBillMethod(
      obj,
      (success) => {
        if (success.status === 200) {
          Success(success.data.response.message);
          dispatch(
            fetchServiceProviderAdditionalBudgets(
              props?.providerDetail?.admin?.service_provider_id,
              push
            )
          );
          props.setLoaderState(false);
          setAdditionalBudgetInVoiceBill("");
          setSupplementaryInVoiceBill("");
          setFinishAdditionalBudgetModal(false);
          setBudgetResponseDetail({});
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  useEffect(() => {
    handleReadAdditionalBudget();
  }, [additionalBudgetDetailModal]);
  return (
    <>
      <section className="">
        <Spin tip={providerLabels.loading} spinning={loading}>
          <div className="flex flex-center sm:flex-center  lg:justify-between flex-wrap sm:flex-wrap lg:flex-nowrap mt-5 ml-5 ">
            <div className="flex self-center justify-center items-center ">
              <div
                onClick={() => goBack()}
                className="flex text-gray-400 items-center cursor-pointer"
              >
                <RiArrowDropLeftLine />
                <span>{providerLabels.back}</span>
              </div>
              <h3 className="ml-10	">{providerLabels.additionalBudgets}</h3>
            </div>
            <div className="mr-28 flex">
              {additionalBudgetSteps?.map((item) => (
                <Badge count={item.count} offset={[-11]} overflowCount={100}>
                  <Button
                    key={item.id}
                    shape={"round"}
                    onClick={() => setIndex(item.id)}
                    className={
                      index == item.id
                        ? `icon-button mx-1 bg-primary text-white`
                        : `icon-button mx-1`
                    }
                  >
                    {capitalizeFirstLetter(item?.title)}
                  </Button>
                </Badge>
              ))}
              <CSVLink
                data={
                  index === 0
                    ? additionalBudget
                    : index === 1
                    ? pendingAdditionalBudget
                    : index === 2
                    ? acceptedAdditionalBudget
                    : index === 3
                    ? declinedAdditionalBudget
                    : index === 4
                    ? completedAdditionalBudget
                    : index === 5
                    ? disputeAdditionalBudget
                    : index === 6
                    ? disputedAdditionalBudget
                    : []?.map((item, index) => {
                        return {
                          no: index + 1,
                          orderID: item?.orderID,
                          userName: item?.userName,
                          additionalBudgetAmount: item?.additionalBudgetAmount,
                          status:
                            item.status === "declined"
                              ? providerLabels.changeforRequest
                              : providerLabels[item.status],
                        };
                      }) || []
                }
                headers={[
                  { label: providerLabels.no, key: "no" },
                  { label: providerLabels.orderID, key: "orderID" },
                  { label: providerLabels.userName, key: "userName" },
                  {
                    label: providerLabels.additionalBudgetAmount,
                    key: "additionalBudgetAmount",
                  },
                  { label: providerLabels.status, key: "status" },
                ]}
                filename={`${providerLabels.additionalBudgets} ${
                  index !== 0 ? additionalBudgetSteps?.[index]?.title : ""
                }.csv`}
                target="_blank"
              >
                <Button
                  shape={"round"}
                  className="icon-button "
                  icon={<AiOutlineArrowDown color="#5ab3f0" />}
                >
                  {providerLabels.exportCSV}
                </Button>
              </CSVLink>
            </div>
          </div>
          <div className="container mx-auto lg:px-20 mt-5">
            <TableComponent
              columns={columns}
              data={
                index === 0
                  ? additionalBudget
                  : index === 1
                  ? pendingAdditionalBudget
                  : index === 2
                  ? acceptedAdditionalBudget
                  : index === 3
                  ? declinedAdditionalBudget
                  : index === 4
                  ? completedAdditionalBudget
                  : index === 5
                  ? disputeAdditionalBudget
                  : index === 6
                  ? disputedAdditionalBudget
                  : []
              }
              pagination={{ defaultPageSize: 6, showSizeChanger: true }}
              scrolly="500px"
            />
          </div>
        </Spin>
      </section>
      <ModalComponent
        filterModal={filterModal}
        closeModal={() => setFilterModal(false)}
        modalTitle={providerLabels.addBudget}
        width={420}
      >
        <div className="">
          <div className="flex justify-center">
            <div className="richTextContainer text-center">
              <ReactQuill
                className={"richTextBox shadow-lg w-full"}
                value={value || ""}
                onChange={setValue}
              />
              {(value === "" || value == null) && fieldIsRequired === true ? (
                <span className="text-red-500">
                  {providerLabels.pleaseFillTheField}
                </span>
              ) : null}
              <div
                className="file btn btn-lg   btn-primary text-left uploadDiv"
                style={{ width: "120px", height: "32px" }}
              >
                <span className="btn bg-primary text-white py-2 rounded-3xl cursor-pointer">
                  {additionalBudgetDocument.length > 0
                    ? providerLabels.fileUploaded
                    : providerLabels.uploadFiles}

                  <input
                    type="file"
                    className="uploadInput cursor-pointer"
                    multiple
                    accept=".jpg, .jpeg, .png, .doc, .docx, .docs, .pdf, .xls, .csv, .xslx , .docx , .xlsx"
                    onChange={(e) => {
                      let f = e.target.files;
                      let fileArray = [];
                      for (var i = 0; i < f.length; i++) {
                        fileArray.push(f[i]);
                      }
                      selectMuiltipleBudgetDocument(fileArray);
                    }}
                  />
                </span>
              </div>
              {addBudgetUrls.length > 0 && (
                <div className="pt-2 flex justify-between flex-wrap">
                  {addBudgetUrls?.map((item, index) => {
                    return (
                      <div className="flex justify-between px-1  w-28">
                        <div>
                          {item?.name.substring(0, 5)}
                          {item?.name?.length > 5 &&
                            "... " + item?.name?.split(".")[1]}{" "}
                        </div>
                        <div
                          className="cursor-pointer	"
                          onClick={() => fileprev(item, index)}
                        >
                          {" "}
                          <DeleteOutlined
                            className="cursor-pointer	"
                            style={{ color: "red" }}
                          />{" "}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="flex flex-col justify-between mb-0 mt-3">
                <label className="lab1">{providerLabels.estimatedTime}</label>

                <div className="flex justify-around items-center  ">
                  <Dropdown
                    overlay={menu1}
                    className={` select-input py-2 px-3 mr-4 justify-center rounded-full w-64  bg-primary`}
                    trigger={["click"]}
                    disabled={props.loader === true ? true : false}
                  >
                    <a
                      className="flex justify-around items-center ant-dropdown-link h-10 capitalize"
                      style={{ color: "#fff" }}
                      onClick={(e) => e.preventDefault()}
                    >
                      {providerLabels[selectedTimeType] ||
                        providerLabels.timeType}
                      <DownOutlined className=" ml-10" />
                    </a>
                  </Dropdown>
                  <div className=" select-input  px-3  justify-center rounded-full  ">
                    <InputComponent
                      type="number"
                      classNames="text-gray-400 font-medium mt-0"
                      placeholder={providerLabels.enterTime}
                      value={serviceTime}
                      onChange={(e) => {
                        setServiceTime(String(Math.abs(e.target.value)));
                      }}
                      onKeyDown={functionToPreventSignsFromNumberFromPricing}
                      disabled={props.loader === true ? true : false}
                    />
                  </div>
                </div>
                {(serviceTime === null || selectedTimeType === null) &&
                fieldIsRequired === true ? (
                  <span className="text-red-500">
                    {providerLabels.pleaseFillTheField}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="flex mt-0 justify-between items-center">
                <label className="lab1">
                  {providerLabels.budgetVATIncluded}
                </label>
                <div className="w-2/5">
                  <div className="flex items-center">
                    <InputComponent
                      placeholder="0"
                      className=""
                      type="text"
                      value={budget || ""}
                      onChange={(e) =>
                        setBudget(
                          regexForPricing(e.target.value, e.nativeEvent) || 0
                        )
                      }
                    />
                    <span className=" font-black mr-1">€</span>
                  </div>
                </div>
              </div>
              {(budget === null || budget === "" || Number(budget < 0)) &&
              fieldIsRequired === true ? (
                <span className="text-red-500">
                  {" "}
                  {providerLabels.pleaseFillTheField}
                </span>
              ) : null}
              <div className="flex my-0 justify-between">
                <label className="lab1">
                  {providerLabels.docuDocDeduction}
                </label>
                <label className="lab1">30%</label>
              </div>
              <div className="flex my-2 justify-between">
                <label className="lab1">{providerLabels.totalAmount}</label>
                <label className="lab1 truncate   w-44 flex justify-end">
                  {europeanCurrencyFormatter(
                    (Number(
                      budget === ""
                        ? 0
                        : Number(regexToReplaceDecimalsWithComma(budget || "0"))
                    ) /
                      100) *
                      70
                  )}{" "}
                  €
                </label>
              </div>
              {/* <div className="flex my-0 justify-between flex-col items-center supplementary_Charges_option">
                <div className="flex justify-start w-full items-center space-x-2">
                  <label className="lab1">
                    {providerLabels.supplementaryCharges}
                  </label>
                  <input
                    value={isSupplementaryCharges}
                    checked={isSupplementaryCharges}
                    onChange={(e) => {
                      setIsSupplementaryCharges(e.target.checked);
                    }}
                    type="checkbox"
                  />
                </div>
                <div
                  className={`w-5/5 ${
                    !isSupplementaryCharges ? "hidden" : "block"
                  }`}
                >
                  <div className={`step-container overflow-clip`}>
                    <Steps
                      progressDot
                      direction="vertical"
                      current={current}
                      className="steps_Dot_Style flex add-class-here"
                      size="small"
                    >
                      {stepsIncrement?.map((item, index) => (
                        <Steps.Step
                          key={item.id}
                          className="items-center	"
                          title={
                            <div className="stepper-add-button">
                              <div className="flex w-full items-center">
                                <InputComponent
                                  width="100%"
                                  onChange={(e) => handleText(e, index)}
                                  placeholder={providerLabels.addTitle}
                                  value={item.name}
                                />
                                <InputComponent
                                  type="text"
                                  width="100%"
                                  onChange={(e) => handleAmount(e, index)}
                                  placeholder={providerLabels.addAmount}
                                  value={item.price}
                                  pattern="[0-9]"
                                />
                              </div>
                            </div>
                          }
                          description={
                            stepsIncrement.length > 1 ? (
                              <Button
                                className="bg-danger text-white ml-2 "
                                shape="circle"
                                onClick={() => prev(item.id, index)}
                              >
                                -
                              </Button>
                            ) : null
                          }
                        />
                      ))}
                      <div className="stepper-add-button">
                        <Button
                          className=" shadow-md bg-primary text-white border-primary-message-sender  "
                          shape="circle"
                          onClick={next}
                        >
                          +
                        </Button>
                      </div>
                    </Steps>
                  </div>
                </div>
              </div> */}
              {/* {isSupplementaryCharges &&
              stepsIncrement.some(
                (supItem) =>
                  supItem?.name?.trim()?.length === 0 ||
                  Number(supItem?.price) < 1
              ) &&
              fieldIsRequired === true ? (
                <span className="text-red-500">
                  {providerLabels.pleaseFillTheField}
                </span>
              ) : null} */}

              <div className="flex my-2 justify-between">
                <label className="lab1"> {providerLabels.grandTotal}</label>
                <label className="lab1">
                  {" "}
                  {europeanCurrencyFormatter(
                    (Number(
                      budget === ""
                        ? 0
                        : Number(regexToReplaceDecimalsWithComma(budget || "0"))
                    ) /
                      100) *
                      70 +
                      (isSupplementaryCharges === true
                        ? stepsIncrement
                            .map((item) =>
                              Number(
                                regexToReplaceDecimalsWithComma(item?.price)
                              )
                            )
                            .reduce((prev, curr) => prev + curr, 0)
                        : 0)
                  )}{" "}
                  €
                </label>
              </div>
              <div className="flex my-2 justify-between">
                <label className="lab1">{providerLabels.userWillPay}</label>
                <label className="lab1">
                  {" "}
                  {europeanCurrencyFormatter(
                    Number(
                      budget === ""
                        ? 0
                        : regexToReplaceDecimalsWithComma(budget || "0")
                    ) +
                      (isSupplementaryCharges === true
                        ? stepsIncrement
                            .map((item) =>
                              Number(
                                regexToReplaceDecimalsWithComma(item?.price)
                              )
                            )
                            .reduce((prev, curr) => prev + curr, 0)
                        : 0)
                  )}{" "}
                  €
                </label>
              </div>
            </div>
          </div>
        </div>

        <button
          className="w-full bg-primary rounded-full py-3 text-white mt-4"
          onClick={editAdditionalBudget}
          disabled={isBudgetSendLoading}
        >
          {isBudgetSendLoading ? providerLabels.loading : providerLabels.resend}
        </button>
      </ModalComponent>
      <ModalComponent
        filterModal={reasonModal}
        closeModal={() => setReasonModal(false)}
        modalTitle={providerLabels.reason}
        width={420}
      >
        <div className="h-60 overflow-y-scroll select-serviceProvider-modal-container ">
          <p>{additionalBudgetData.reason}</p>
        </div>
        <button
          className="w-full bg-primary rounded-full py-3 text-white mt-4"
          onClick={() => {
            if (
              additionalBudgetData?.order?.status !== myConstant?.dispute &&
              additionalBudgetData?.order?.status !== myConstant?.disputed
            ) {
              setReasonModal(false);
              setValue(additionalBudgetData?.notes);
              setAddBudgetUrls(JSON?.parse(additionalBudgetData?.files));
              setSelectedTimeType(additionalBudgetData?.time_type);
              setServiceTime(additionalBudgetData?.time);
              setBudget(
                String(additionalBudgetData?.amount)?.replace(".", ",")
              );
              // setIsSupplementaryCharges(
              //   additionalBudgetData?.supplementaries_fees?.length === 0
              //     ? false
              //     : true
              // );
              // setStepsIncrement(
              //   additionalBudgetData?.supplementaries_fees.length === 0
              //     ? [
              //         {
              //           id: Math.round(Math.random() * 100),
              //           name: "",
              //           price: 0,
              //         },
              //       ]
              //     : additionalBudgetData?.supplementaries_fees?.map((sup) => {
              //         return {
              //           id: sup.id,
              //           name: sup.name,
              //           price: String(sup.price)?.replace(".", ","),
              //         };
              //       })
              // );
              setFilterModal(true);
            } else {
              Errors("additional budget of dispute order cannot be edit");
            }
          }}
        >
          {providerLabels.editBudget}
        </button>
      </ModalComponent>
      <ModalComponent
        filterModal={additionalBudgetDetailModal}
        width={570}
        closeModal={() => setAdditionalBudgetDetailModal(false)}
        modalTitle={providerLabels.requestDetail}
        className="global-modal-css-scroll-height"
      >
        <div className="scrollable-fixed-height">
          <div className="flex items-center mt-8 ml-2">
            <img
              src={budgetResponseDetail?.order?.service?.avatar}
              style={{ width: 50, height: 45 }}
              alt="detail image"
            />
            <div className="pl-2 text-left">
              <label>
                <b>
                  {
                    budgetResponseDetail?.order?.service?.[
                      currentSelectedLangue === "english" ? "name" : "name_sp"
                    ]
                  }
                </b>
              </label>{" "}
              <br />
            </div>
          </div>
          <div className="grid grid-cols-6 ml-2">
            <div className="col-span-2">
              <div className="mt-5">
                <h4 className="font-black">{providerLabels.provider}</h4>
                <p className="text-gray-400">
                  {budgetResponseDetail?.order?.service_provider?.name}
                </p>
              </div>
            </div>
            <div className="col-span-2">
              <div className="mt-5">
                <h4 className="font-black">{providerLabels.orderId}</h4>
                <p className="text-gray-400">{budgetResponseDetail.order_id}</p>
              </div>
            </div>

            <div className="col-span-2">
              <div className="mt-5">
                <h4 className="font-black">{providerLabels.estimatedTime}</h4>
                <p className="text-gray-400">
                  {budgetResponseDetail?.time} {budgetResponseDetail?.time_type}
                </p>
              </div>
            </div>
            <div className="col-span-2">
              <div className="mt-5">
                <h4 className="font-black">{providerLabels.date}</h4>
                <p className="text-gray-400">
                  {moment(budgetResponseDetail?.created_at)?.format(
                    "DD-MMM-YYYY"
                  )}
                </p>
              </div>
            </div>

            <div className="col-span-2">
              <div className="mt-5">
                <h4 className="font-black">{providerLabels.serviceFees}</h4>
                <p className="text-gray-400">
                  €{" "}
                  {europeanCurrencyFormatter(
                    Number(budgetResponseDetail?.amount)
                  )}
                </p>
              </div>
            </div>
            <div className="col-span-2">
              <div className="mt-5">
                <h4 className="font-black">
                  {providerLabels.supplementaryFees}
                </h4>
                <p className="text-gray-400">
                  €{" "}
                  {europeanCurrencyFormatter(
                    Number(budgetResponseDetail?.supplementary_fees)
                  )}
                </p>
              </div>
            </div>
            <div className="col-span-2">
              <div className="mt-5">
                <h4 className="font-black">{providerLabels.docuDocFees}</h4>
                <p className="text-gray-400">
                  €{" "}
                  {europeanCurrencyFormatter(
                    Number(budgetResponseDetail?.amount) * 0.3
                  )}
                </p>
              </div>
            </div>
            <div className="col-span-2">
              <div className="mt-5">
                <h4 className="font-black">{providerLabels.providerAmount}</h4>
                <p className="text-gray-400">
                  €
                  {europeanCurrencyFormatter(
                    Math.ceil(Number(budgetResponseDetail?.amount) * 0.7) +
                      Number(budgetResponseDetail?.supplementary_fees)
                  )}
                </p>
              </div>
            </div>
            <div className="col-span-2">
              <div className="mt-5">
                <h4 className="font-black">{providerLabels.status}</h4>
                <p className="text-gray-400">{budgetResponseDetail?.status}</p>
              </div>
            </div>
            <div className="col-span-6">
              <div className="grid grid-cols-2">
                {budgetResponseDetail?.supplementaries_fees?.length > 0 ? (
                  <div className="">
                    <div className="mt-5">
                      <h4 className="font-black">
                        {providerLabels.supplementaryDetails}
                      </h4>
                      {budgetResponseDetail?.supplementaries_fees?.map(
                        (item) => (
                          <p className="text-gray-400">
                            <div className="flex justify-between">
                              <div className="text-sm text-black">
                                {item?.name}
                              </div>
                              <div className="text-sm text-gray-400">
                                {" "}
                                €{europeanCurrencyFormatter(item?.price)}
                              </div>
                            </div>
                          </p>
                        )
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-span-6">
              <div className="mt-5">
                <h4 className="font-black">
                  {providerLabels.serviceDetailedDescription}
                </h4>
                <p className="mt-2 render-html-class">
                  {budgetResponseDetail?.notes ? (
                    <InformationContainer info={budgetResponseDetail?.notes} />
                  ) : null}
                </p>
              </div>
            </div>
          </div>
          <div className="space-y-3 mx-2">
            {budgetUrls?.length > 0 ? (
              <div className="mt-5">
                <h4 className="font-black">{providerLabels.attachments}</h4>
                <div>
                  {budgetUrls?.length > 0 ? (
                    <div className="pt-2 flex justify-between flex-wrap">
                      {budgetUrls?.map((item, index) => {
                        return (
                          <div
                            className="flex justify-between w-28"
                            key={index}
                          >
                            <div
                              onClick={() =>
                                downloadFiles(item.url, item?.name)
                              }
                              className="cursor-pointer mb-0"
                              style={{ color: "#44B6F7" }}
                            >
                              <span>{index + 1} - </span>
                              <span>
                                {item?.name.substring(0, 5)}
                                {item?.name?.length > 5 &&
                                  "... " +
                                    item?.name?.slice(
                                      item?.name?.lastIndexOf(".") + 1
                                    )}{" "}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    providerLabels.notAvailable
                  )}
                </div>
              </div>
            ) : null}
          </div>
          {budgetResponseDetail?.status === "accepted" ? (
            <div className="flex justify-end w-full items-center space-x-2">
              <div>
                <AiOutlineDownload
                  onClick={() => downloadBillFunction(budgetResponseDetail)}
                  size={"30"}
                  color="#46BCFF"
                  className="cursor-pointer"
                />
              </div>
              <div>{providerLabels.downloadBill}</div>
            </div>
          ) : null}
          <div className="space-y-3 mx-2">
            {budgetResponseDetail?.budget_bill !== null ? (
              <div className="flex justify-between items-center">
                <h4 className="font-black">
                  {
                    providerLabels.serviceBillServiceBillOfServiceProviderAdditionalBudgetBill
                  }
                </h4>
                <Button
                  shape={"round"}
                  onClick={() =>
                    downloadFiles(
                      budgetResponseDetail?.budget_bill,
                      `${
                        providerLabels.serviceBillServiceBillOfServiceProviderAdditionalBudgetBill
                      }.${get_url_extension(budgetResponseDetail?.budget_bill)}`
                    )
                  }
                  className={`whiteTxt bg-primary`}
                >
                  {providerLabels.download}
                </Button>
              </div>
            ) : null}
            {budgetResponseDetail?.supplementary_bill !== null ? (
              <div className="flex justify-between items-center">
                <h4 className="font-black">
                  {providerLabels.suplementsBillOfServiceProvider}
                </h4>
                <Button
                  shape={"round"}
                  onClick={() =>
                    downloadFiles(
                      budgetResponseDetail?.supplementary_bill,
                      `${
                        providerLabels.suplementsBillOfServiceProvider
                      }.${get_url_extension(
                        budgetResponseDetail?.supplementary_bill
                      )}`
                    )
                  }
                  className={`whiteTxt bg-primary`}
                >
                  {providerLabels.download}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        filterModal={finishAdditionalBudgetModal}
        closeModal={() => setFinishAdditionalBudgetModal(false)}
      >
        <div>
          <div className="justify-center">
            <div className="divide-y divide-gray-200 divide-solid py-3">
              <div className="flex justify-between py-3 ">
                <span className="lab1">{providerLabels.service}</span>
                <span className="lab2">
                  {
                    budgetResponseDetail?.order?.service?.[
                      currentSelectedLangue === "english" ? "name" : "name_sp"
                    ]
                  }
                </span>
              </div>
              <div className="flex justify-between  py-3">
                <span className="lab1">{providerLabels.amount}</span>
                <span className="lab2">{`€${europeanCurrencyFormatter(
                  budgetResponseDetail?.amount
                )}`}</span>
              </div>
              <div className="flex justify-between  py-3">
                <span className="lab1">{providerLabels.supplementary}</span>
                <span className="lab2">{`€${europeanCurrencyFormatter(
                  budgetResponseDetail?.supplementary_fees
                )}`}</span>
              </div>
              <div className="flex justify-between  py-3 items-center">
                <span className="lab1">
                  {providerLabels.additionalBudgetBill}
                </span>

                <div className="file btn btn-lg btn-primary orderBillUploadDiv ">
                  {additionalBudgetInVoiceBill !== ""
                    ? providerLabels.fileUploaded
                    : providerLabels.upload}
                  <input
                    type="file"
                    name="file"
                    className="uploadInput cursor-pointer"
                    onChange={handleAdditionalBudgetFileInvoiceBill}
                  />
                </div>
              </div>
              {/* <div className="flex justify-between  py-3 items-center capitalize">
                <span className="lab1">{providerLabels.supplementaryBill}</span>

                <div className="file btn btn-lg btn-primary orderBillUploadDiv ">
                  {supplementaryInVoiceBill !== ""
                    ? providerLabels.fileUploaded
                    : providerLabels.upload}
                  <input
                    type="file"
                    name="file"
                    className="uploadInput cursor-pointer"
                    onChange={handleSupplementaryFileInvoiceBill}
                  />
                </div>
              </div> */}
              <div className="flex justify-between  py-3">
                <span className="lab1">{providerLabels.totalAmount}</span>
                <span className="lab2">{`€${europeanCurrencyFormatter(
                  Number(budgetResponseDetail?.amount) +
                    Number(budgetResponseDetail?.supplementary_fees)
                )}`}</span>
              </div>
            </div>
          </div>
          <ButtonComponent
            onClick={() =>
              sendBillToUser(budgetResponseDetail?.additional_budget_id)
            }
            className="bg-primary my-5 p-2"
            title={providerLabels.sendToUser}
            disabled={props.loader === true}
            loader={props.loader === true ? true : false}
          />
        </div>
      </ModalComponent>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    providerDetail: state.providerLoginDetail.loggedUserDetailOfProvider,
    loader: state.loaderToggle.loaderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProviderLogout: () => {
      dispatch(ProviderLogoutAction());
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Additional_Budget);
