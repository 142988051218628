import { SearchOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Menu, Modal, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import {
  AiFillQuestionCircle,
  AiOutlineArrowDown,
  AiOutlinePlus,
} from "react-icons/ai";
import { MdModeEdit } from "react-icons/md";
import { RiArrowDropLeftLine, RiDeleteBinFill } from "react-icons/ri";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { ModalComponent, TableComponent } from "../../components";
import { Errors, Success } from "../../components/antdToast";
import InputComponent from "../../components/InputComponent";
import {
  AddServiceForm,
  DeleteDynamicFormMethod,
  GetBranchServices,
  GetServiceForms,
} from "../../config/api";
import { objectToFormData } from "../../config/formData/formData";
import { adminLabels } from "../../config/label/adminLabels";
import { Images } from "../../constant/Images";
import { DeleteBeneficiariesAtLogoutTime } from "../../Redux/actions/fetchBeneficiariesAction";
import { DeleteBranchesAtLogoutTime } from "../../Redux/actions/fetchBranchesAction";
import { DeleteProvidersAtLogoutTime } from "../../Redux/actions/fetchProvidersAction";
import { DeleteServiceAreasAtLogoutTime } from "../../Redux/actions/fetchServiceAreaAction";
import { FetchServiceForms } from "../../Redux/actions/fetchServiceFormsAction";
import {
  DeleteServicesAtLogoutTime,
  FetchServicesAction,
} from "../../Redux/actions/fetchServicesAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";

const { assement, uploadImg, checkbox, checkboxActive } = Images;

const DynamicFormsList = (props) => {
  const { goBack, push } = useHistory();
  const [serviceForms, setServiceForms] = useState([]);
  const [services, setServices] = useState([]);
  const [filterModal, setFilterModal] = React.useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [deleteFormServiceId, setdeleteFormServiceId] = useState("");
  const [loading, setLoading] = useState(false);
  const [cloneDynamicForm, setCloneDynamicForm] = useState(false);
  const [title, setTitle] = React.useState("");
  const [form, setForm] = useState({});
  useEffect(() => {
    fetchServiceForms();
    fetchServices();
    props.setAndFetchServiceForms();
  }, []);
  useEffect(() => {
    document.body.style.overflow = "auto";
    return function cleanup() {
      document.body.style.overflow = "auto";
    };
  });
  const fetchServiceForms = () => {
    setLoading(true)
    GetServiceForms(
      (res) => {
        
        setServiceForms(
          res?.data?.response?.detail.reverse().map((item, index) => {
            return {
              ...item,
              No: index + 1,
              service:
                item.service === null
                  ? {
                      name: item.title,
                      avatar:
                        "https://cdn-icons-png.flaticon.com/512/3447/3447545.png",
                    }
                  : item.service,
            };
          })
        );
        // fetchServices()
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        if (error?.response?.status === 401) {
          Errors(error?.response?.data?.message);
          push("/admin");
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  const fetchServices = () => {
    // GetBranchServices(
    //   (res) => {
    //     let filterdServices = res?.data?.response?.detail.filter((item, i) => {
    //       return item?.service_form_id === null;
    //     });

    //     setServices(
    //       filterdServices.reverse().map((service) => {
    //         return { ...service, checked: false };
    //       })
    //     );

    //     setLoading(false);
    //   },
    //   (error) => {
    //     if (error?.response?.status === 401) {
    //       Errors(error?.response?.data?.message);
    //       push("/admin");
    //     } else {
    //       Errors("Network Error");
    //     }
    //   }
    // );
  };
  const columns = [
    {
      align: "center",
      title: adminLabels.no,
      dataIndex: "No",
      key: "No",
      width: 30,
      render: (text) => <a>{text}</a>,
    },
    {
      align: "center",
      title: adminLabels.forms,
      dataIndex: `service`,
      key: "service",
      width: 150,
      render: (text, index) => (
        <Tooltip placement="topLeft" title={text?.name}>
          <div className={"flex"}>
            <img
              src={text?.avatar}
              style={{ width: 50, height: 45 }}
              alt="detail image"
            />
            <div className="pl-2 text-left flex items-center">
              <label>
                <b>{text?.name}</b>
              </label>
            </div>
          </div>
        </Tooltip>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.service.name.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.service?.name?.localeCompare(b?.service?.name),
      showSorterTooltip: false,
    },
    {
      key: "data",
      width: 100,
    },

    {
      align: "center",
      title: adminLabels.action,
      key: "action",
      width: 100,
      render: (text, index) => (
        <div className="flex justify-center space-x-2">
          <Dropdown
            overlay={() => menu(text.service_form_id)}
            className={"flex"}
            trigger={["click"]}
          >
            <Button
              // onClick={() => setFilterModal(true)}
              type="disable"
              shape={"round"}
              className="background-color-gray whiteTxt"
            >
              {adminLabels.viewDetails}
            </Button>
          </Dropdown>

          {/* <Button
            onClick={() => cloneForm(text)}
            type="disable"
            shape={"round"}
            className="background-color-gray whiteTxt"
          >
            {adminLabels.clone}
          </Button> */}
        </div>
      ),
    },
  ];
  const submitFormList = () => {
    if (title !== "") {
      let obj = {
        service_ids: JSON.stringify(
          services
            ?.filter((service) => service?.checked === true)
            ?.map((service) => service?.service_id)
        ),
        data: JSON.stringify(form),
        title: title,
      };
      let data = objectToFormData(obj);

      AddServiceForm(
        data,
        (success) => {
          props.setAndFetchServiceForms();
          fetchServiceForms();
          fetchServices();

          if (success.status === 200) {
            Success(success.data.response.message);
            setCloneDynamicForm(false);
            setTitle("");
            setForm([]);
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            Errors(error?.response?.data?.message);
            push("/admin");
          } else {
            Errors("Network Error");
          }
        }
      );
    } else {
      Errors("Please Add Form Title !");
    }
  };
  const cloneForm = (formData) => {
    setForm(formData.data);
    setCloneDynamicForm(true);
  };
  const deleteDynamicForm = () => {
    if (deleteFormServiceId !== "") {
      DeleteDynamicFormMethod(
        deleteFormServiceId,
        (success) => {
          if (success?.status === 200) {
            setOpenConfirmationModal(false);

            fetchServiceForms();
            fetchServices();
          }
        },
        (err) => {
          if (err?.response?.status === 500) {
            Errors(err?.response?.data?.error?.message);
          } else if (err?.response?.status === 422) {
            Errors(err?.response?.data?.error?.message);
          } else {
            Errors("Network Error");
          }
        }
      );
    }
  };

  const deleteModal = (id) => {
    setOpenConfirmationModal(true);
    setdeleteFormServiceId(id);
  };

  const onChecked = (value) => {
    let mappedOutService = services.map((service) => {
      if (service.service_id === value.service_id) {
        return {
          ...service,
          checked: !service.checked,
        };
      } else {
        return {
          ...service,
        };
      }
    });
    setServices(mappedOutService);
  };
  const menu = (id) => {
    return (
      <Menu className="menu-class menu-main-class" style={{ borderRadius: 20 }}>
        <Menu.Item
          onClick={() => deleteModal(id)}
          className="bg-white"
          style={{ borderRadius: 20 }}
        >
          <div
            className="flex justify-between bg-transparent py-2 w-full  items-center"
            style={{ width: 160 }}
          >
            <div>
              <span>{adminLabels.deleteForm}</span>
            </div>
            <div>
              <RiDeleteBinFill color="red" />
            </div>
          </div>
        </Menu.Item>
        <Menu.Item
          className="bg-white"
          style={{ borderRadius: 20 }}
          onClick={() => push({ pathname: `/admin/dynamic-form-edit/${id}` })}
        >
          <div
            className=" bg-transparent py-2 w-full  items-center"
            style={{ width: 160 }}
          >
            <div className="flex justify-between items-center text-black">
              <div>
                <span>{adminLabels.editForm}</span>
              </div>
              <div>
                <MdModeEdit color="#61dafb" />
              </div>
            </div>
          </div>
        </Menu.Item>
      </Menu>
    );
  };
  return (
    <section className="">
      <Spin tip={adminLabels.loading} spinning={loading}>
        <div className="flex flex-center sm:flex-center  lg:justify-between flex-wrap sm:flex-wrap lg:flex-nowrap mt-5 ml-5 ">
          <div className="flex self-center justify-center items-center ">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{adminLabels.back}</span>
            </div>
            <h3 className="ml-10	">{adminLabels.dynamicForms}</h3>
          </div>
          <div className="lg:mr-28 flex space-x-1">
            <Button
              shape={"round"}
              className="icon-button"
              icon={<AiOutlinePlus color="#5ab3f0" />}
              // onClick={() => setFilterModal(true)}
              onClick={() => {
                push({
                  pathname: "/admin/dynamic-form-add",
                  state: { skippedServiceID: true },
                })
              }}
            >
              {adminLabels.addNew}
            </Button>

            <CSVLink
              data={serviceForms?.map((item, index) => {
                return {
                  no: index + 1,
                  name: item?.service.name,
                  steps: item?.data
                    ?.filter((item) => item?.hasOwnProperty("sectionTitle"))
                    .map((a, i) => {
                      return i + 1;
                    }),
                };
              })}
              headers={[
                { label: adminLabels.no, key: "no" },
                { label: adminLabels.name, key: "name" },
                { label: adminLabels.steps, key: "steps" },
              ]}
              filename={`${adminLabels.dynamicForms}.csv`}
              target="_blank"
            >
              <Button
                shape={"round"}
                className="icon-button "
                icon={<AiOutlineArrowDown color="#5ab3f0" />}
              >
                {adminLabels.exportCSV}
              </Button>
            </CSVLink>
          </div>
        </div>
        <div className="container mx-auto lg:px-20 mt-5">
          <TableComponent
            columns={columns}
            data={serviceForms}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
            scrolly="500px"
          />
        </div>
        <ModalComponent
          filterModal={filterModal}
          closeModal={() => setFilterModal(false)}
          modalTitle={adminLabels.serviceForDynamicForm}
        >
          <div className="divide-y-2 overflow-x-auto h-72">
            {services?.length
              ? services?.map((text, index) => {
                  return (
                    <div className="flex justify-between  text-left py-2">
                      <div className="flex items-center justify-center">
                        <img
                          style={{ width: "50px", height: "50px" }}
                          src={text?.avatar}
                          alt="logo alt"
                        />
                        <span className="pl-4">{text?.name}</span>
                      </div>
                      <div
                        key={index}
                        className="flex align-center p-3 cursor-pointer"
                      >
                        {text?.checked ? (
                          <img
                            style={{ width: "20px", height: "20px" }}
                            src={checkboxActive.default}
                            onClick={() => onChecked(text, index)}
                          />
                        ) : (
                          <img
                            style={{ width: "20px", height: "20px" }}
                            src={checkbox.default}
                            onClick={() => onChecked(text, index)}
                          />
                        )}
                      </div>
                    </div>
                  );
                })
              : "no records found !"}
          </div>
          <div className="icon-button  mr-5 flex justify-end space-x-2 ">
            <Button
              className=""
              shape={"round"}
              onClick={() =>
                push({
                  pathname: "/admin/dynamic-form-add",
                  state: { skippedServiceID: true },
                })
              }
            >
              {adminLabels.skip}
            </Button>
            <Button
              className=""
              shape={"round"}
              onClick={() =>
                services?.filter((service) => service?.checked === true)
                  .length === 0
                  ? Errors("please select service !")
                  : push({
                      pathname: "/admin/dynamic-form-add",
                      state: services
                        ?.filter((service) => service?.checked === true)
                        ?.map((service) => service?.service_id),
                    })
              }
            >
              {adminLabels.done}
            </Button>
          </div>
          {/* <div  className="icon-button float-right mr-5 pb-10">
          <Button
            shape={"round"}
         
          >
            Skip
          </Button>
        </div> */}

          {/* <span className="float-right mr-5 text-gray-500 cursor-pointer">
          Skip
        </span> */}
        </ModalComponent>
        <Modal
          visible={openConfirmationModal}
          width={400}
          footer={false}
          onCancel={() => setOpenConfirmationModal(false)}
        >
          <div>
            <div className="flex justify-center w-full">
              <AiFillQuestionCircle
                size={80}
                className="mr-4"
                color={"#5ab3f0"}
              />
            </div>
            <div className="my-5">
              <h4 className="font-bold text-2xm text-center text-gray-600	">
                {adminLabels.doYouReallyWantToDeleteThisService}
              </h4>
            </div>
          </div>

          <div className="flex justify-between">
            <button
              className="w-2/5 bg-danger  rounded-full py-2 text-white"
              onClick={deleteDynamicForm}
            >
              {adminLabels.yes}
            </button>
            <button
              onClick={() => setOpenConfirmationModal(false)}
              className="w-2/5 bg-primary rounded-full py-2 text-white"
            >
              {adminLabels.no}
            </button>
          </div>
        </Modal>
        <ModalComponent
          filterModal={cloneDynamicForm}
          closeModal={() => setCloneDynamicForm(false)}
          modalTitle={adminLabels.cloneServiceForm}
        >
          <div className="w-full">
            <div className="w-full ml-2 pr-4 ">
              <InputComponent
                value={title}
                placeholder={adminLabels.formTitle}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <div className="divide-y-2 overflow-x-auto h-72">
              {services?.length
                ? services?.map((text, index) => {
                    return (
                      <div className="flex justify-between  text-left py-2">
                        <div className="flex items-center justify-center">
                          <img
                            style={{ width: "50px", height: "50px" }}
                            src={text?.avatar}
                            alt="logo alt"
                          />
                          <span className="pl-4">{text?.name}</span>
                        </div>
                        <div
                          key={index}
                          className="flex align-center p-3 cursor-pointer"
                        >
                          {text?.checked ? (
                            <img
                              style={{ width: "20px", height: "20px" }}
                              src={checkboxActive.default}
                              onClick={() => onChecked(text, index)}
                            />
                          ) : (
                            <img
                              style={{ width: "20px", height: "20px" }}
                              src={checkbox.default}
                              onClick={() => onChecked(text, index)}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
            <div className="flex justify-between">
              <button
                className="w-full bg-primary  rounded-full py-2 text-white"
                onClick={submitFormList}
              >
                {adminLabels.save}
              </button>
            </div>
          </div>
        </ModalComponent>
      </Spin>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    showServices: state.servicesList.services,
    showServiceForms: state.serviceFormsList.serviceForms.reverse(),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getServicesList: () => {
      dispatch(FetchServicesAction());
    },
    setLogoutUser: (data) => {
      dispatch(LogoutUserAction(data));
    },
    setAndFetchServiceForms: () => {
      dispatch(FetchServiceForms());
    },
    removeBeneficiaries: () => {
      dispatch(DeleteBeneficiariesAtLogoutTime());
    },
    removeProviders: () => {
      dispatch(DeleteProvidersAtLogoutTime());
    },
    removeServiceAreas: () => {
      dispatch(DeleteServiceAreasAtLogoutTime());
    },
    removeBranches: () => {
      dispatch(DeleteBranchesAtLogoutTime());
    },
    removeServices: () => {
      dispatch(DeleteServicesAtLogoutTime());
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicFormsList);
