import {
  DeleteOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Button, Divider, Spin, Steps } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiFillCalendar, AiFillFolder } from "react-icons/ai";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ModalComponent } from "../../components";
import { Errors, Success } from "../../components/antdToast";
import ButtonComponent from "../../components/ButtonComponent";
import { Remove_Data_Form_Reducers } from "../../components/dataRemoveOnLogout";
import DFormToolKit from "../../components/dFormToolkit";
import InputFeildsComp from "../../components/dynamicFormComp/inputText";
import InputComponent from "../../components/InputComponent";
import TextAreaComponent from "../../components/textArea";
import { AddServiceForm, UploadFileMethod } from "../../config/api";
import { objectToFormData } from "../../config/formData/formData";
import { adminLabels } from "../../config/label/adminLabels";
import { Images } from "../../constant/Images";
import { DeleteAppUsers } from "../../Redux/actions/fetchAppUsersAction";
import { DeleteBeneficiariesAtLogoutTime } from "../../Redux/actions/fetchBeneficiariesAction";
import { DeleteBranchesAtLogoutTime } from "../../Redux/actions/fetchBranchesAction";
import { DeleteProvidersAtLogoutTime } from "../../Redux/actions/fetchProvidersAction";
import { DeleteServiceAreasAtLogoutTime } from "../../Redux/actions/fetchServiceAreaAction";
import {
  DeleteServiceForms,
  FetchServiceForms,
} from "../../Redux/actions/fetchServiceFormsAction";
import {
  DeleteServicesAtLogoutTime,
  FetchServicesAction,
} from "../../Redux/actions/fetchServicesAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";
import "./dynamicform.css";

axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
export const getHeader = (type) => {
  let token = localStorage.getItem("token");
  if (type === 1) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
  } else if (type === 2) {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  } else if (type === 3) {
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
  }
};
const {
  textInput,
  textBlock,
  dropDown,
  uploadFile,
  question,
  timeDelivery,
  document,
  checkbox,
  checkboxActive,
  calendar,
  checkBox,
  RadioButton,
  adminNote,
} = Images;

const tool = [
  { img: textInput, name: adminLabels.shortAnswers, id: 1 },
  { img: textBlock, name: adminLabels.longAnswers, id: 2 },
  { img: dropDown, name: adminLabels.dropdown, id: 3 },
  { img: uploadFile, name: adminLabels.uploadFiles, id: 4 },
  // { img: question, name: " QUESTION", id: 5 },
  // { img: timeDelivery, name: " TIME OF DELIVERY", id: 7 },
  { img: document, name: adminLabels.attachFile, id: 8 },
  { img: RadioButton, name: adminLabels.radioButton, id: 9 },
  { img: checkBox, name: adminLabels.checkBox, id: 10 },
  { img: calendar, name: adminLabels.calender, id: 11 },
  { img: adminNote, name: adminLabels.adminNote, id: 12 },
];

var menuOptionsArray = [];
var radioOptionsArray = [];
var checkBoxArray = [];
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const DynamicFormAdd = (props) => {
  const [title, setTitle] = React.useState("");
  const [fullName, setFullName] = React.useState("");
  const [fullNameEsp, setFullNameEsp] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [descriptionEsp, setDescriptionEsp] = React.useState("");
  const [dropDownTitle, setDropDownTitle] = React.useState("");
  const [dropDownTitleEsp, setDropDownTitleEsp] = React.useState("");
  const [radioButtonTitle, setRadioButtonTitle] = React.useState("");
  const [radioButtonTitleEsp, setRadioButtonTitleEsp] = React.useState("");
  const [checkBoxTitle, setCheckBoxTitle] = React.useState("");
  const [checkBoxTitleEsp, setCheckBoxTitleEsp] = React.useState("");
  const [fileSelectTitle, setFileSelectTitle] = React.useState("");
  const [selectSection, setSelectSection] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState("");
  const [form, setForm] = React.useState([]);
  const [formList, setFormList] = React.useState([]);
  const [toolTipModal, setToolTipModal] = React.useState(false);
  const { Step } = Steps;
  const [current, setCurrent] = React.useState(0);
  const [radioCurrent, setRadioCurrent] = React.useState(0);
  const [checkBoxCurrent, setCheckBoxCurrent] = React.useState(0);
  const [stepCount, setStepCount] = useState(0);
  const [fieldId, setFieldId] = useState(0);
  const [formId, setFormId] = useState(0);
  const [yesQuestion, setYesQuestion] = useState(false);
  const [noQuestion, setNoQuestion] = useState(true);
  const [dropDownStepperId, setDropDownStepperId] = useState(0);
  const [checkDisablityUploader, setCheckDisabiltyUploader] = useState(true);
  const [checkDisablityPickUp, setCheckDisabiltyPickUp] = useState(false);
  const [menuoptions, setMenuOptions] = useState([]);
  const [radioOptions, setRadioOption] = useState([]);
  const [checkBoxOptions, setCheckBoxOptions] = useState([]);
  const [option, setOption] = useState("");
  const { goBack, push } = useHistory();
  const [checkStateOfToolKit, setCheckStateOfToolKit] = useState(true);
  const [steps, setSteps] = React.useState([{ name: "+", title: "" }]);
  const [singleFile, setSingleFile] = React.useState([]);
  const [stepsIncrement, setStepsIncrement] = useState([
    {
      title: "",
      id:
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15),
      name: "",
    },
    {
      id: -1,
    },
  ]);

  const [radioButtonSteps, setRadioButtonSteps] = useState([
    {
      title: "",
      id:
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15),
      name: "",
    },
    {
      id: -1,
    },
  ]);
  const [CheckBoxSteps, setCheckBoxSteps] = useState([
    {
      title: "",
      id:
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15),
      name: "",
    },
    {
      id: -1,
    },
  ]);
  const [isEdit, setIsEdit] = useState(false);
  const [updateFormId, setUpdateFormId] = useState("");
  const [updateFieldId, setUpdateFieldId] = useState("");
  // const [updateIndex, setUpdateIndex] = useState("");
  const [sectionTitleModal, setSectionTitleModal] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [formArray, setFormArray] = useState([]);
  const [updateIndex, setUpdateIndex] = useState(0);
  const [urls, setUrls] = useState([]);
  const [muiltipleDocument, setMuiltipleDocument] = useState("");
  const [dynamicFormTitle, setDynamicFormTitle] = useState("");
  const [fieldIsRequired, setFieldIsrequired] = useState(false);
  let urlArray = [];
  useEffect(() => {
    // props.location.state
    if (props?.location?.state?.skippedServiceID === true) {
    } else {
      props.location.state === undefined && push("/admin/Dynamic-forms-list");
    }
    // props.location.state === undefined && push("/admin/Dynamic-forms-list");
    const stepData = formList.map((t, i) => {
      return { name: i + 1, title: t.title };
    });
    if (checkDisablityUploader === true) {
      setCheckDisabiltyPickUp(false);
      setFieldId(5);
    }
    setSteps([...stepData, { name: "+", title: "" }]);
  }, []);

  // document.body.style.overflow = "hidden";
  // return function cleanup() {
  //   document.body.style.overflow = "auto";
  // };

  //modal open function
  const onClick = (id) => {
    setIsEdit(false);
    setFullName("");
    setFullNameEsp("");
    setDescription("");
    setDescriptionEsp("");
    setDropDownTitle("");
    setDropDownTitleEsp("");
    setRadioButtonTitle("");
    setRadioButtonTitleEsp("");
    setCheckBoxTitle("");
    setCheckBoxTitleEsp("");
    if (id === 1) {
      setToolTipModal(true);
      setModalTitle(adminLabels.addInputFeild);
      setFieldId(1);
    } else if (id === 2) {
      setToolTipModal(true);
      setModalTitle(adminLabels.addTextBlock);
      setFieldId(2);
    } else if (id == 3) {
      setStepsIncrement([
        {
          title: "",
          id:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
          name: "",
          nameEsp: "",
        },
        {
          id: -1,
        },
      ]);
      setToolTipModal(true);
      setModalTitle(adminLabels.dropdown);
      setFieldId(3);
    } else if (id === 4) {
      setToolTipModal(true);
      setModalTitle(adminLabels.addFileUploader);
      setFieldId(4);
    } else if (id === 5) {
      setToolTipModal(true);
      setModalTitle("Add Question");
      setFieldId(5);
    } else if (id === 6) {
      setToolTipModal(true);
      setModalTitle("Add Question");
      setFieldId(6);
    } else if (id === 7) {
      setToolTipModal(true);
      setModalTitle("Time of Delivery");
      setFieldId(7);
    } else if (id === 8) {
      setUrls([]);
      setToolTipModal(true);
      setModalTitle(adminLabels.attachFile);
      setFieldId(8);
    } else if (id === 9) {
      setRadioButtonSteps([
        {
          title: "",
          id:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
          name: "",
          nameEsp: "",
        },
        {
          id: -1,
        },
      ]);
      setToolTipModal(true);
      setModalTitle(adminLabels.radioButton);
      setFieldId(9);
    } else if (id === 10) {
      setCheckBoxSteps([
        {
          title: "",
          id:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
          name: "",
          nameEsp: "",
        },
        {
          id: -1,
        },
      ]);
      setToolTipModal(true);
      setModalTitle(adminLabels.checkBox);
      setFieldId(10);
    } else if (id === 11) {
      setToolTipModal(true);
      setModalTitle(adminLabels.calender);
      setFieldId(11);
    } else if (id === 12) {
      setToolTipModal(true);
      setModalTitle(adminLabels.adminNote);
      setFieldId(12);
    }
  };

  //form Title Add in stepper function
  const onTitleSelect = (formTitle) => {
    setFormArray((prev) => {
      return [...prev, { sectionTitle: formTitle }];
    });
    setSectionTitleModal(false);
    setTitle("");
  };

  //Add field in form function;
  const onFeildSelect = () => {
    // var foundObj = formList?.find((formDetail) => formDetail.formId === formId);
    if (fieldId === 3) {
      let filtered = stepsIncrement?.filter((item) => item?.id !== -1);
      let menuMapped = filtered?.map((item, index) => {
        return { option: item?.name, optionEsp: item?.nameEsp };
      });
      setFormArray((prev) => {
        return [
          ...prev,
          {
            label: dropDownTitle,
            labelEsp: dropDownTitleEsp,
            description,
            descriptionEsp,
            menu: menuMapped,
            fieldId,
          },
        ];
      });
      setStepsIncrement([
        {
          title: "",
          id:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
          name: "",
          nameEsp: "",
        },
        {
          id: -1,
        },
      ]);
    } else if (fieldId === 9) {
      let filtered = radioButtonSteps?.filter((item) => item?.id !== -1);
      let menuMapped = filtered?.map((item, index) => {
        return { option: item?.name, optionEsp: item?.nameEsp };
      });
      setFormArray((prev) => {
        return [
          ...prev,
          {
            label: radioButtonTitle,
            labelEsp: radioButtonTitleEsp,
            description,
            descriptionEsp,
            menu: menuMapped,
            fieldId,
          },
        ];
      });
      setRadioButtonSteps([
        {
          title: "",
          id:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
          name: "",
          nameEsp: "",
        },
        {
          id: -1,
        },
      ]);
    } else if (fieldId === 8) {
      setFormArray((prev) => {
        return [
          ...prev,
          {
            label: fullName,
            labelEsp: fullNameEsp,
            description,
            descriptionEsp,
            files: urls,
            fieldId,
          },
        ];
      });
    } else if (fieldId === 10) {
      let filtered = CheckBoxSteps?.filter((item) => item?.id !== -1);
      let menuMapped = filtered?.map((item, index) => {
        return { option: item?.name, optionEsp: item?.nameEsp };
      });

      setFormArray((prev) => {
        return [
          ...prev,
          {
            label: checkBoxTitle,
            labelEsp: checkBoxTitleEsp,
            description,
            descriptionEsp,
            menu: menuMapped,
            fieldId,
          },
        ];
      });
      setCheckBoxSteps([
        {
          title: "",
          id:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
          name: "",
          nameEsp: "",
        },
        {
          id: -1,
        },
      ]);
    } else {
      setFormArray((prev) => {
        return [
          ...prev,
          {
            label: fullName,
            labelEsp: fullNameEsp,
            description,
            descriptionEsp,
            fieldId,
          },
        ];
      });
    }

    setFullName("");
    setFullNameEsp("");
    setDescription("");
    setDescriptionEsp("");
    setDropDownTitle("");
    setDropDownTitleEsp("");
    setRadioButtonTitle("");
    setRadioButtonTitleEsp("");
    setCheckBoxTitle("");
    setCheckBoxTitleEsp("");
    setCheckDisabiltyPickUp(false);
    setToolTipModal(false);
    menuOptionsArray = [];
    radioOptionsArray = [];
    setOption("");

    setDropDownTitle("");
    setDropDownTitleEsp("");
    setRadioButtonTitle("");
    setRadioButtonTitleEsp("");
  };

  const checkArray = (item) => {
    let fetchArray = steps.filter((items) => items.name !== item.name);
    let fetchObject = fetchArray.find(
      (filterobjects) => filterobjects.title === ""
    );
    if (fetchObject !== undefined) {
      return true;
    }
    return false;
  };

  //this title form add function
  const onAddForm = (item) => {
    if (steps.length !== 6) {
      if (checkArray(item) === true) {
        Errors("first filled the form then other form will be come");
        //alert("first filled the form then other form will be come");
      } else {
        setSelectSection(true);
        steps.splice(steps.length - 1, 0, { name: stepCount + 1, title: "" });
        setStepCount(stepCount + 1);
        setForm([]);
        setSteps(steps);
        setFormId(steps.length - 1);
      }
      setCheckStateOfToolKit(true);
    } else {
      alert("not add form more");
    }
  };

  //stepper add in dropdown modal function
  const next = () => {
    setStepsIncrement((prevState) => {
      setCurrent(current + 1);
      return [
        ...prevState,
        {
          title: "",
          id:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
          name: "",
          nameEsp: "",
        },
      ];
    });
  };

  //radio button modal function
  const radioButtonNext = () => {
    setRadioButtonSteps((prevState) => {
      setRadioCurrent(radioCurrent + 1);
      return [
        ...prevState,
        {
          title: "",
          id:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
          name: "",
          nameEsp: "",
        },
      ];
    });
  };

  const CheckBoxNext = () => {
    setCheckBoxSteps((prevState) => {
      setCheckBoxCurrent(checkBoxCurrent + 1);
      return [
        ...prevState,
        {
          title: "",
          id:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
          name: "",
          nameEsp: "",
        },
      ];
    });
  };

  //call delete func of dropdown modal
  const prev = (id, index) => {
    // stepsIncrement.splice(index, 1);
    deleteStepInput(id);
  };
  const RadioPrev = (id, index) => {
    deleteRadioInput(id);
  };
  const ChexkBoxPrev = (id, index) => {
    deleteCheckBoxInput(id);
  };
  // delete antd stepper in dropdownmodal function
  const deleteStepInput = (id) => {
    setCurrent(current - 1);
    let updatedArray = stepsIncrement.filter((item) => {
      return item.id != id;
    });
    setStepsIncrement(updatedArray);
  };

  const deleteRadioInput = (id) => {
    setRadioCurrent(radioCurrent - 1);
    let updatedArray = radioButtonSteps.filter((item) => {
      return item.id != id;
    });
    setRadioButtonSteps(updatedArray);
  };
  const deleteCheckBoxInput = (id) => {
    setCheckBoxCurrent(checkBoxCurrent - 1);
    let updatedArray = CheckBoxSteps?.filter((item) => {
      return item.id != id;
    });
    setCheckBoxSteps(updatedArray);
  };
  //specific form captcher function
  const fectchFormDataByClick = (formsId) => {
    var specificFormFiltered = formList.find(
      (specificForm) => specificForm.formId === formsId
    );
    if (specificFormFiltered === undefined) {
      alert("Please first make the form title");
      setForm([]);
      setSelectSection(true);
      setCheckStateOfToolKit(true);
    } else {
      let showForm =
        specificFormFiltered.formStructure.length !== 0
          ? specificFormFiltered.formStructure?.map((a) => {
              return {
                ...a,
                label: a.label,
                labelEsp: a.labelEsp,
                description: a.description,
                descriptionEsp: a.descriptionEsp,
              };
            })
          : [];
      setForm([showForm]);
      setFormId(formsId);
      setCheckStateOfToolKit(false);
      setSelectSection(false);
    }
  };

  //delete specific form field function
  const deleteSpecificFormField = (fieldId, index) => {
    let mappedOut = formArray?.filter((item, subIndex) => index !== subIndex);

    setFormArray(mappedOut);
  };

  //update field label and description function
  const editInputTextField = (fieldId, index) => {
    setFieldId(fieldId);
    onClick(fieldId);
    setUpdateIndex(index);
    setIsEdit(true);
    if (fieldId === 3) {
      let Menu = formArray[index].menu;
      let mappedOut = Menu?.map((item, index) => {
        return {
          id: index + 1,
          name: item?.option,
          nameEsp: item?.optionEsp,
        };
      });
      setStepsIncrement((prev) => {
        let prevState = prev?.filter((item) => item.id === -1);
        return [...prevState, ...mappedOut];
      });
      setDropDownTitle(formArray[index].label);
      setDropDownTitleEsp(formArray[index].labelEsp);
      setDescriptionEsp(formArray[index].labelEsp);
      setDescription(formArray[index].description);
      setDescriptionEsp(formArray[index].descriptionEsp);
    } else if (fieldId === 9) {
      let Menu = formArray[index].menu;
      let mappedOut = Menu?.map((item, index) => {
        return {
          id: index + 1,
          name: item?.option,
          nameEsp: item?.optionEsp,
        };
      });
      setRadioButtonSteps((prev) => {
        let prevState = prev?.filter((item) => item.id === -1);
        return [...prevState, ...mappedOut];
      });
      setRadioButtonTitle(formArray[index].label);
      setRadioButtonTitleEsp(formArray[index].labelEsp);
      setDescription(formArray[index].description);
      setDescriptionEsp(formArray[index].descriptionEsp);
    } else if (fieldId === 8) {
      setFullName(formArray[index].label);
      setFullNameEsp(formArray[index].labelEsp);
      setDescription(formArray[index].description);
      setDescriptionEsp(formArray[index].descriptionEsp);
      setUrls(formArray[index].files);
    } else if (fieldId === 10) {
      let Menu = formArray[index].menu;
      let mappedOut = Menu?.map((item, index) => {
        return {
          id: index + 1,
          name: item?.option,
          nameEsp: item?.optionEsp,
        };
      });
      setCheckBoxSteps((prev) => {
        let prevState = prev?.filter((item) => item.id === -1);
        return [...prevState, ...mappedOut];
      });
      setCheckBoxTitle(formArray[index].label);
      setCheckBoxTitleEsp(formArray[index].labelEsp);
      setDescription(formArray[index].description);
      setDescriptionEsp(formArray[index].descriptionEsp);
    } else {
      setFullName(formArray[index].label);
      setFullNameEsp(formArray[index].labelEsp);
      setDescription(formArray[index].description);
      setDescriptionEsp(formArray[index].descriptionEsp);
    }
  };

  //field labels updated
  const updatedFieldlabel = () => {
    // formArray[updateIndex].label = fullName;
    // formArray[updateIndex].description = description;
    // setForm(...);
    // setToolTipModal(false);
    // setDropDownTitle("");
    let mapped = formArray?.map((item, index) => {
      if (item?.fieldId === fieldId && index === updateIndex) {
        if (item?.fieldId === 3) {
          let filtered = stepsIncrement?.filter((item) => item?.id !== -1);
          let menuMapped = filtered?.map((item, index) => {
            return { option: item?.name, optionEsp: item?.nameEsp };
          });
          return {
            label: dropDownTitle,
            labelEsp: dropDownTitleEsp,
            description,
            descriptionEsp,
            menu: menuMapped,
            fieldId,
          };
        } else if (item?.fieldId === 9) {
          let filtered = radioButtonSteps?.filter((item) => item?.id !== -1);
          let menuMapped = filtered?.map((item, index) => {
            return { option: item?.name, optionEsp: item.nameEsp };
          });
          return {
            label: radioButtonTitle,
            labelEsp: radioButtonTitleEsp,
            description,
            descriptionEsp,
            menu: menuMapped,
            fieldId,
          };
        } else if (item?.fieldId === 10) {
          let filtered = CheckBoxSteps?.filter((item) => item?.id !== -1);
          let menuMapped = filtered?.map((item, index) => {
            return { option: item?.name, optionEsp: item?.nameEsp };
          });
          return {
            label: checkBoxTitle,
            labelEsp: checkBoxTitleEsp,
            description,
            descriptionEsp,
            menu: menuMapped,
            fieldId,
          };
        } else if (item?.fieldId === 8) {
          let findObj = formArray?.find((item) => item?.fieldId === fieldId);

          return {
            description,
            descriptionEsp,
            fieldId: fieldId,
            files: urls,
            label: fullName,
            labelEsp: fullNameEsp,
          };
        } else {
          return {
            label: fullName,
            labelEsp: fullNameEsp,
            description,
            descriptionEsp,
            fieldId,
          };
        }
      } else {
        return item;
      }
    });
    setFormArray(mapped);
    setToolTipModal(false);
    setDropDownTitle("");
    setDropDownTitleEsp("");
    setRadioButtonTitle("");
    setRadioButtonTitleEsp("");
    setUrls([]);
    setStepsIncrement([
      {
        title: "",
        id:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15),
        name: "",
        nameEsp: "",
      },
      {
        id: -1,
      },
    ]);
  };

  const handleOptions = (e, index, lang) => {
    let option = e.target.value;
    // pushFunction(option, e, index);
    let mappedStepsIncrement = stepsIncrement.map((value, index1) => {
      if (index1 === index) {
        if (lang === "eng") {
          return {
            ...value,
            name: e.target.value,
            id: value.id,
            title: value.title,
          };
        } else {
          return {
            ...value,
            nameEsp: e.target.value,
            id: value.id,
            title: value.title,
          };
        }
      } else {
        return value;
      }
    });
    setStepsIncrement(mappedStepsIncrement);
  };

  const handleRadioOptions = (e, index, lang) => {
    let mappedStepsIncrement = radioButtonSteps.map((value, index1) => {
      if (index1 === index) {
        if (lang === "eng") {
          return {
            ...value,
            name: e.target.value,
            id: value.id,
            title: value.title,
          };
        } else {
          return {
            ...value,
            nameEsp: e.target.value,
            id: value.id,
            title: value.title,
          };
        }
      } else {
        return value;
      }
    });
    setRadioButtonSteps(mappedStepsIncrement);
  };

  const handleCheckBoxOptions = (e, index, lang) => {
    let mappedStepsIncrement = CheckBoxSteps.map((value, index1) => {
      if (index1 === index) {
        if (lang === "eng") {
          return {
            ...value,
            name: e.target.value,
            id: value.id,
            title: value.title,
          };
        } else {
          return {
            ...value,
            nameEsp: e.target.value,
            id: value.id,
            title: value.title,
          };
        }
      } else {
        return value;
      }
    });
    setCheckBoxSteps(mappedStepsIncrement);
  };

  const showAlert = () => {
    return alert(
      "Please first give title of form then add other formin formList"
    );
  };

  const getUrl = (url, name, type, id) => {
    setUrls((prevurls) => [...prevurls, { url, name, type, id }]);
  };
  const fileprev = (item, index) => {
    setUrls(urls?.filter((file) => file.id !== item.id));
  };
  const selectMuiltipleDocument = async (valueArray) => {
    let muiltipleFileNames = "";
    valueArray.map((r, i) => {
      muiltipleFileNames += `${r.name}, `;
    });
    valueArray?.map((file, i) => {
      let formData = new FormData();
      formData.append("file", file);
      UploadFileMethod(
        formData,
        (success) => {
          if (success.status === 200) {
            if (success.data.response.detail !== null) {
              let id = Math.round(Math.random() * 10000);
              getUrl(success.data.response.detail, file.name, file.type, id);
            }
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            Remove_Data_Form_Reducers(props);
            push("/admin");
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
          }
        }
      );
    });
    setMuiltipleDocument(muiltipleFileNames);
  };

  const submitFormList = () => {
    if (formArray.length !== 0 && dynamicFormTitle.trim().length !== 0) {
      let obj = {
        service_ids:
          props?.location?.state?.skippedServiceID === true
            ? null
            : JSON.stringify(props.location.state),
        data: JSON.stringify(formArray),
        title: dynamicFormTitle,
      };
      let data = objectToFormData(obj);
      props.setLoaderState(true);
      AddServiceForm(
        data,
        (success) => {
          if (success.status === 200) {
            props.setLoaderState(false);
            Success(success.data.response.message);
            setFormList([]);
            setForm([]);
            setSteps([{ name: "+", title: "" }]);
            props.setServiceForms();
            push("/admin/Dynamic-forms-list");
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            Remove_Data_Form_Reducers(props);
            push("/admin");
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
          }
        }
      );
    } else {
      setFieldIsrequired(true);
    }
  };

  return (
    <section className="py-20 px-2 sm:px-2 lg:px-10">
      <div className="container mx-auto">
        <div className="flex  justify-start sm:justify-start lg:justify-between items-center flex-wrap sm:flex-wrap">
          <div className="flex sub-h3">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{adminLabels.back}</span>
            </div>
            <h3 className="ml-8">{adminLabels.punctualForm}</h3>
          </div>

          <div className="flex items-center flex-wrap sm:flex-wrap justify-between w-full sm:w-full lg:w-max ">
            <div className=" flex items-center ">
              {/* <div>
                <button
                  type="button"
                  onClick={() => setSectionTitleModal(true)}
                  className="ant-btn ant-btn-circle ant-btn-lg bg-primary mr-2 text-white"
                >
                  <span>+</span>
                </button>
              </div> */}

              <DFormToolKit
                toolList={tool}
                onClick={onClick}
                arrayLength={formArray}
                stateOfToolkit={true}
              />
              <Button
                className="bg-primary text-white ml-2 py-20"
                shape={"round"}
                size="large"
                onClick={submitFormList}
              >
                {props.loader === true ? (
                  <Spin indicator={antIcon} />
                ) : (
                  adminLabels.save
                )}
              </Button>
            </div>
          </div>
        </div>
        <div className="w-80">
          <InputComponent
            value={dynamicFormTitle}
            onChange={(e) => setDynamicFormTitle(e.target.value)}
            placeholder={adminLabels.formTitle}
          />
          {dynamicFormTitle.trim().length === 0 && fieldIsRequired === true ? (
            <span className="text-red-500">
              {adminLabels.pleaseFillTheField}
            </span>
          ) : (
            ""
          )}
        </div>
        {formArray.length > 0 ? (
          <div className="mt-10 bg-white rounded-lg shadow-lg px-5 py-10">
            {formArray?.map((formSection, i) => {
              return (
                <div className="grid grid-cols-12  ">
                  <div className="col-span-2 sm:col-span-2 " />
                  <div className="text-center col-span-9 sm:col-span-9 lg:col-span-8 ">
                    {formSection.hasOwnProperty("sectionTitle") && (
                      <div className="px-30 mt-5 ">
                        <Divider>
                          <h3>{formSection?.sectionTitle}</h3>
                        </Divider>
                      </div>
                    )}
                    {formSection.hasOwnProperty("fieldId") && (
                      <div key={i} className="grid grid-cols-12  ">
                        <div className="col-span-2 sm:col-span-3 " />
                        <div className="text-center col-span-9 sm:col-span-9 lg:col-span-6 ">
                          <div className="px-30 mt-5 ">
                            <InputFeildsComp
                              data={formSection}
                              index={i}
                              formIdentification={formSection.fieldId}
                              deleteField={deleteSpecificFormField}
                              editField={editInputTextField}
                            />
                          </div>
                        </div>
                        <div className="col-span-3 sm:col-span-3" />
                      </div>
                    )}
                  </div>

                  <div className="col-span-3 sm:col-span-2" />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="mt-10 bg-white rounded-lg shadow-lg px-5 py-20">
            <div className="px-30 py-20">
              <div className="text-center text-5xl font-color-blue">
                <InfoCircleOutlined className="add" />
              </div>
              <Divider>
                <div>
                  <h3 className="font-color-blue">
                    {adminLabels.formNotAvaliable}
                  </h3>
                </div>
              </Divider>
            </div>
          </div>
        )}
      </div>
      <ModalComponent
        filterModal={toolTipModal}
        closeModal={() => setToolTipModal(false)}
        modalTitle={modalTitle}
      >
        {fieldId === 3 ? 
        (
          <>
            <InputComponent
              value={dropDownTitle}
              onChange={(e) => setDropDownTitle(e.target.value)}
              placeholder={adminLabels.dropDownTitle}
            />
            <InputComponent
              value={dropDownTitleEsp}
              onChange={(e) => setDropDownTitleEsp(e.target.value)}
              placeholder={adminLabels.dropDownTitle}
            />
            <div className="step-container">
              <Steps
                progressDot
                direction="vertical"
                current={stepsIncrement.length}
                style={{ marginTop: 25 }}
                className="steps_Dot_Style"
                size="small"
              >
                {stepsIncrement?.map(
                  (item, index) =>
                    item.id !== -1 && (
                      <Step
                        key={item.id}
                        title={
                          item.id !== -1 && (
                            <div className="">
                              <div>
                                <InputComponent
                                  value={item.name}
                                  width="100%"
                                  onChange={(e) =>
                                    handleOptions(e, index, "eng")
                                  }
                                  placeholder={adminLabels.addTitle}
                                />
                              </div>
                              <div>
                                <InputComponent
                                  value={item.nameEsp}
                                  width="100%"
                                  onChange={(e) =>
                                    handleOptions(e, index, "esp")
                                  }
                                  placeholder={adminLabels.addTitle}
                                />
                              </div>
                            </div>
                          )
                        }
                        description={
                          stepsIncrement.length > 2 ? (
                            <Button
                              className="bg-danger text-white ml-2"
                              shape="circle"
                              onClick={() => prev(item.id)}
                            >
                              -
                            </Button>
                          ) : (
                            ""
                          )
                        }
                      />
                    )
                )}
                <div className="stepper-add-button">
                  <Button
                    onClick={next}
                    className="bg-primary text-white "
                    shape="circle"
                  >
                    +
                  </Button>
                </div>
              </Steps>
            </div>
          </>
        ) : fieldId === 9 ? (
          <>
            <InputComponent
              value={radioButtonTitle}
              onChange={(e) => setRadioButtonTitle(e.target.value)}
              placeholder={adminLabels.radioButtonTitle}
            />
            <InputComponent
              value={radioButtonTitleEsp}
              onChange={(e) => setRadioButtonTitleEsp(e.target.value)}
              placeholder={adminLabels.radioButtonTitle}
            />
            <div className="step-container">
              <Steps
                progressDot
                direction="vertical"
                current={radioButtonSteps?.length}
                style={{ marginTop: 25 }}
                className="steps_Dot_Style"
                size="small"
              >
                {radioButtonSteps.map(
                  (item, index) =>
                    item.id !== -1 && (
                      <>
                        <Step
                          key={item.id}
                          title={
                            item.id !== -1 && (
                              <div className="">
                                <InputComponent
                                  value={item.name}
                                  width="100%"
                                  onChange={(e) =>
                                    handleRadioOptions(e, index, "eng")
                                  }
                                  placeholder={adminLabels.addTitle}
                                />
                                <InputComponent
                                  value={item.nameEsp}
                                  width="100%"
                                  onChange={(e) =>
                                    handleRadioOptions(e, index, "esp")
                                  }
                                  placeholder={adminLabels.addTitle}
                                />
                              </div>
                            )
                          }
                          description={
                            radioButtonSteps.length > 2 ? (
                              <Button
                                className="bg-danger text-white ml-2"
                                shape="circle"
                                onClick={() => RadioPrev(item.id)}
                              >
                                -
                              </Button>
                            ) : (
                              ""
                            )
                          }
                        />
                      </>
                    )
                  // <Step
                  //   key={item.id}
                  //   title={
                  //     item.id !== -1 ? (
                  //       <div className="flex w-full items-center">
                  //         <InputComponent
                  //           value={item.name}
                  //           width="100%"
                  //           onChange={(e) => handleRadioOptions(e, index)}
                  //           placeholder={adminLabels.addTitle}
                  //         />
                  //       </div>
                  //     ) : (
                  //       <div className="stepper-add-button">
                  //         <Button
                  //           className="bg-primary text-white "
                  //           shape="circle"
                  //         >
                  //           +
                  //         </Button>
                  //       </div>
                  //     )
                  //   }
                  //   description={
                  //     item.id !== -1 ? (
                  //       <Button
                  //         className="bg-danger text-white ml-2"
                  //         shape="circle"
                  //         onClick={() => RadioPrev(item.id)}
                  //       >
                  //         -
                  //       </Button>
                  //     ) : (
                  //       ""
                  //     )
                  //   }
                  //   onClick={item.id === -1 ? radioButtonNext : () => {}}
                  // />
                )}
                <div className="stepper-add-button">
                  <Button
                    onClick={radioButtonNext}
                    className="bg-primary text-white "
                    shape="circle"
                  >
                    +
                  </Button>
                </div>
              </Steps>
            </div>
          </>
        ) : fieldId === 10 ? (
          <>
            <InputComponent
              value={checkBoxTitle}
              onChange={(e) => setCheckBoxTitle(e.target.value)}
              placeholder={adminLabels.checkBoxTitle}
            />
            <InputComponent
              value={checkBoxTitleEsp}
              onChange={(e) => setCheckBoxTitleEsp(e.target.value)}
              placeholder={adminLabels.checkBoxTitle}
            />
            <div className="step-container">
              {/* <Steps
                progressDot
                direction="vertical"
                current={CheckBoxSteps.length + 1}
                className="steps_Dot_Style"
                style={{ marginTop: 25 }}
                size="small"
              >
                {CheckBoxSteps?.map(
                  (item, index) =>
                    item.id !== -1 && (
                      <>
                        <Step
                          key={item.id}
                          title={
                            item.id !== -1 && (
                              <div className="">
                                <InputComponent
                                  value={item.name}
                                  width="100%"
                                  onChange={(e) =>
                                    handleCheckBoxOptions(e, index, "eng")
                                  }
                                  placeholder={adminLabels.addTitle}
                                />
                                <InputComponent
                                  value={item.nameEsp}
                                  width="100%"
                                  onChange={(e) =>
                                    handleCheckBoxOptions(e, index, "esp")
                                  }
                                  placeholder={adminLabels.addTitle}
                                />
                              </div>
                            )
                          }
                          description={
                            CheckBoxSteps?.length > 2 ? (
                              <Button
                                className="bg-danger text-white ml-2"
                                shape="circle"
                                onClick={() => ChexkBoxPrev(item.id)}
                              >
                                -
                              </Button>
                            ) : (
                              ""
                            )
                          }
                        />
                      </>
                    )
                )}
              </Steps> */}
              <Steps
                progressDot
                direction="vertical"
                current={CheckBoxSteps?.length}
                style={{ marginTop: 25 }}
                className="steps_Dot_Style"
                size="small"
              >
                {CheckBoxSteps.map(
                  (item, index) =>
                    item.id !== -1 && (
                      <>
                        <Step
                          key={item.id}
                          title={
                            item.id !== -1 && (
                              <div className="">
                                <InputComponent
                                  value={item.name}
                                  width="100%"
                                  onChange={(e) =>
                                    handleCheckBoxOptions(e, index, "eng")
                                  }
                                  placeholder={adminLabels.addTitle}
                                />
                                <InputComponent
                                  value={item.nameEsp}
                                  width="100%"
                                  onChange={(e) =>
                                    handleCheckBoxOptions(e, index, "esp")
                                  }
                                  placeholder={adminLabels.addTitle}
                                />
                              </div>
                            )
                          }
                          description={
                            CheckBoxSteps.length > 2 ? (
                              <Button
                                className="bg-danger text-white ml-2"
                                shape="circle"
                                onClick={() => ChexkBoxPrev(item.id)}
                              >
                                -
                              </Button>
                            ) : (
                              ""
                            )
                          }
                        />
                      </>
                    )
                  // <Step
                  //   key={item.id}
                  //   title={
                  //     item.id !== -1 ? (
                  //       <div className="flex w-full items-center">
                  //         <InputComponent
                  //           value={item.name}
                  //           width="100%"
                  //           onChange={(e) => handleRadioOptions(e, index)}
                  //           placeholder={adminLabels.addTitle}
                  //         />
                  //       </div>
                  //     ) : (
                  //       <div className="stepper-add-button">
                  //         <Button
                  //           className="bg-primary text-white "
                  //           shape="circle"
                  //         >
                  //           +
                  //         </Button>
                  //       </div>
                  //     )
                  //   }
                  //   description={
                  //     item.id !== -1 ? (
                  //       <Button
                  //         className="bg-danger text-white ml-2"
                  //         shape="circle"
                  //         onClick={() => RadioPrev(item.id)}
                  //       >
                  //         -
                  //       </Button>
                  //     ) : (
                  //       ""
                  //     )
                  //   }
                  //   onClick={item.id === -1 ? radioButtonNext : () => {}}
                  // />
                )}
                <div className="stepper-add-button">
                  <Button
                    onClick={CheckBoxNext}
                    className="bg-primary text-white "
                    shape="circle"
                  >
                    +
                  </Button>
                </div>
              </Steps>
            </div>
          </>
        ) : (
          <div className="px-30 mt-5">
            {fieldId === 12 ? null : (
              <>
                <label className="ml-6 text-gray-400 capitalize">
                  {adminLabels.addFeildLabel}
                </label>
                <InputComponent
                  className="  "
                  placeholder={
                    fieldId === 1
                      ? adminLabels.inputFeildTitleNameEmailAndAddress
                      : fieldId === 2
                      ? adminLabels.descriptionOrInformation
                      : fieldId === 4
                      ? adminLabels.uploadTitle
                      : fieldId === 5
                      ? "Your Question"
                      : fieldId === 7
                      ? "Add Time of Delivery (24Hr/48Hr)"
                      : fieldId === 8
                      ? adminLabels.addFileTitleHere
                      : fieldId === 11
                      ? adminLabels.addTitleForCalender
                      : fieldId === 12
                      ? adminLabels.addTitleForAdminNote
                      : ""
                  }
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
               <InputComponent
                  className="  "
                  placeholder={
                    fieldId === 1
                      ? adminLabels.inputFeildTitleNameEmailAndAddressEsp
                      : fieldId === 2
                      ? adminLabels.descriptionOrInformationEsp
                      : fieldId === 4
                      ? adminLabels.uploadTitleEsp
                      : fieldId === 5
                      ? "Your Question"
                      : fieldId === 7
                      ? "Add Time of Delivery (24Hr/48Hr)"
                      : fieldId === 8
                      ? adminLabels.addFileTitleHereEsp
                      : fieldId === 11
                      ? adminLabels.addTitleForCalenderEsp
                      : fieldId === 12
                      ? adminLabels.addTitleForAdminNoteEsp
                      : ""
                  }
                  value={fullNameEsp}
                  onChange={(e) => setFullNameEsp(e.target.value)}
                />
              </>
            )}
          </div>
        )}
        {fieldId === 8 ? (
          <>
            <div className="flex justify-center items-center mt-5 cursor-pointer">
              <div className="file btn btn-lg  uploadFileInputDiv cursor-pointer">
                {adminLabels.uploadFromComputer}
                <input
                  type="file"
                  name="file"
                  className="uploadInput cursor-pointer"
                  multiple
                  accept=".jpg, .jpeg, .png, .doc, .docx, .docs, .pdf, .xls, .csv, .xslx , .docx , .xlsx"
                  onChange={(e) => {
                    let f = e.target.files;
                    let fileArray = [];
                    for (var i = 0; i < f.length; i++) {
                      fileArray.push(f[i]);
                    }
                    selectMuiltipleDocument(fileArray);
                  }}
                />
              </div>
            </div>
            <div className="py-4">
              {urls?.map((item, index) => {
                return (
                  <div className="flex justify-between px-6 ">
                    <div>
                      {item?.name.substring(0, 35)}
                      {item?.name?.length > 35 &&
                        "... " + item?.name?.split(".")[1]}{" "}
                    </div>
                    <div
                      className="cursor-pointer	"
                      onClick={() => fileprev(item, index)}
                    >
                      {" "}
                      <DeleteOutlined
                        className="cursor-pointer	"
                        style={{ color: "red" }}
                      />{" "}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          ""
        )}
        {fieldId === 5 || fieldId === 6 ? (
          <div className="flex justify-start mt-5  mb-5">
            <label
              className="flex itemas-center"
              onClick={() => {
                setYesQuestion(true);
                setNoQuestion(false);
              }}
            >
              {yesQuestion === true ? (
                <img
                  src={checkboxActive.default}
                  className="mr-2 check-circle"
                />
              ) : (
                <img src={checkbox.default} className="mr-2 check-circle" />
              )}
              {adminLabels.yes}
            </label>
            <label
              className="flex itemas-center ml-10"
              onClick={() => {
                setNoQuestion(true);
                setYesQuestion(false);
              }}
            >
              {noQuestion === true ? (
                <img
                  src={checkboxActive.default}
                  className="mr-2 check-circle"
                />
              ) : (
                <img src={checkbox.default} className="mr-2 check-circle" />
              )}
              {adminLabels.no}
            </label>
          </div>
        ) : (
          ""
        )}
        {fieldId === 5 || fieldId === 6 ? (
          <div className="flex justify-start mt-5 mb-5">
            <div className="flex items-center">
              {checkDisablityUploader ? (
                <img
                  src={checkboxActive.default}
                  className="mr-2 check-circle"
                  onClick={() => {
                    setCheckDisabiltyUploader(false);
                    setCheckDisabiltyPickUp(true);
                    setFieldId(6);
                  }}
                />
              ) : (
                <img
                  src={checkbox.default}
                  className="mr-2 check-circle"
                  onClick={() => {
                    setCheckDisabiltyUploader(true);
                    setCheckDisabiltyPickUp(false);
                    setFieldId(5);
                  }}
                />
              )}
              <Button
                shape={"round"}
                className="icon-button"
                disabled={checkDisablityUploader ? false : true}
                icon={<AiFillFolder color="#5ab3f0" />}
              >
                Uppload Multiple Files
              </Button>
            </div>
            <div className="flex itemas-center ml-10">
              {checkDisablityPickUp ? (
                <img
                  src={checkboxActive.default}
                  className="mr-2 check-circle"
                  onClick={() => {
                    setCheckDisabiltyPickUp(false);
                    setCheckDisabiltyUploader(true);
                    setFieldId(5);
                  }}
                />
              ) : (
                <img
                  src={checkbox.default}
                  className="mr-2 check-circle"
                  onClick={() => {
                    setCheckDisabiltyPickUp(true);
                    setCheckDisabiltyUploader(false);
                    setFieldId(6);
                  }}
                />
              )}
              <Button
                shape={"round"}
                className="icon-button"
                disabled={checkDisablityPickUp ? false : true}
                icon={<AiFillCalendar color="#5ab3f0" />}
              >
                Pickup
              </Button>
            </div>
          </div>
        ) : (
          ""
        )}
        {checkDisablityPickUp ? (
          <InputComponent
            className="  "
            placeholder={"Location"}
            onChange={(e) => setFullName(e.target.value)}
          />
        ) : (
          ""
        )}
        <div className="mt-5">
          <TextAreaComponent
            className="  "
            rows={fieldId === 5 || fieldId === 6 || fieldId === 8 ? 3 : 5}
            placeholder={
              fieldId === 12
                ? adminLabels.noteHere
                : adminLabels.fieldDescription
            }
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
          <TextAreaComponent
            className="  "
            rows={fieldId === 5 || fieldId === 6 || fieldId === 8 ? 3 : 5}
            placeholder={
              fieldId === 12
                ? adminLabels.noteHere
                : adminLabels.fieldDescription
            }
            onChange={(e) => setDescriptionEsp(e.target.value)}
            value={descriptionEsp}
          />
        </div>

        <div className="my-3">
          <ButtonComponent
            title={adminLabels.save}
            onClick={() => {
              isEdit ? updatedFieldlabel() : onFeildSelect(steps.length - 1);
            }}
          />
        </div>
      </ModalComponent>
      <ModalComponent
        filterModal={sectionTitleModal}
        closeModal={() => setSectionTitleModal(false)}
        modalTitle={adminLabels.addYourSectionTitle}
        width={350}
      >
        <div className="grid grid-cols-12 mt-10 py-2">
          <div className="col-span-12 sm:col-span-12" />
          <div className="text-center col-span-12 sm:col-span-12 lg:col-span-12">
            <div className="w-full ml-2 pr-4 ">
              <InputComponent
                className={title}
                placeholder={adminLabels.sectionTitle}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="w-full  ml-2 pr-4">
              <ButtonComponent
                className="bg-primary"
                title={adminLabels.save}
                onClick={() => onTitleSelect(title)}
              />
            </div>
          </div>
          <div className="col-span-3 sm:col-span-3" />
        </div>
      </ModalComponent>
    </section>
  );
};
const mapStateToProps = (state) => {
  return {
    showServices: state.servicesList.services,
    loader: state.loaderToggle.loaderState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getServicesList: () => {
      dispatch(FetchServicesAction());
    },
    setLogoutUser: (data) => {
      dispatch(LogoutUserAction(data));
    },
    setServiceForms: () => {
      dispatch(FetchServiceForms());
    },
    removeBeneficiaries: () => {
      dispatch(DeleteBeneficiariesAtLogoutTime());
    },
    removeProviders: () => {
      dispatch(DeleteProvidersAtLogoutTime());
    },
    removeServiceAreas: () => {
      dispatch(DeleteServiceAreasAtLogoutTime());
    },
    removeBranches: () => {
      dispatch(DeleteBranchesAtLogoutTime());
    },
    removeServices: () => {
      dispatch(DeleteServicesAtLogoutTime());
    },
    removeServiceForms: () => {
      dispatch(DeleteServiceForms());
    },
    removeAppUsers: () => {
      dispatch(DeleteAppUsers());
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicFormAdd);
