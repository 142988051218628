const initialState = {
    WORK_WITH_US: [],
  };
  
  const getWorkWithUsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_WORK_WITH_US": {
        return {
          ...state,
          WORK_WITH_US: action.payload,
        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default getWorkWithUsReducer;
  