import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Input,
  Menu,
  Modal,
  Spin,
  Switch,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import {
  AiFillQuestionCircle,
  AiOutlineArrowDown,
  AiOutlinePlus,
} from "react-icons/ai";
import { RiArrowDropLeftLine, RiPencilLine } from "react-icons/ri";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Errors, Success } from "../../components/antdToast";
import InformationContainer from "../../components/informationContainer";
import TableComponent from "../../components/table";
import {
  DeleteBranchServiceMethod,
  GetBranchServices,
  UpdateServiceStatus,
} from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { DeleteBeneficiariesAtLogoutTime } from "../../Redux/actions/fetchBeneficiariesAction";
import { DeleteBranchesAtLogoutTime } from "../../Redux/actions/fetchBranchesAction";
import { DeleteProvidersAtLogoutTime } from "../../Redux/actions/fetchProvidersAction";
import { DeleteServiceAreasAtLogoutTime } from "../../Redux/actions/fetchServiceAreaAction";
import {
  DeleteServicesAtLogoutTime,
  FetchServicesAction,
} from "../../Redux/actions/fetchServicesAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";

const Services = (props) => {
  const { goBack, push } = useHistory();
  const [servicesList, setServicesList] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [deleteServiceID, setDeleteServiceID] = useState("");
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [modal_Content, setModalContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentSelectedLangue, setCurrentSelectedLangue] = useState("spanish");

  useEffect(() => {
    setCurrentSelectedLangue(localStorage.getItem("primaryLanguage"));

    getServices();

    document.body.style.overflow = "auto";
    return function cleanup() {
      document.body.style.overflow = "auto";
    };
  }, []);
  const { renderHTML } = ReactQuill;
  const getServices = () => {
    GetBranchServices(
      (res) => {
        let services = res?.data?.response?.detail
          ?.reverse()
          ?.map((service, index) => {
            return {
              ...service,
              no: index + 1,
              serviceName: {
                name: service?.name,
                nameEsp: service?.name_sp,
                url: service?.avatar,
                desc: service?.info_service_pack,
              },
              servicearea: service?.service_area,
              beneficiary: service?.service_beneficiaries?.map((item) => {
                return {
                  ...item,
                  priority: item?.priority,
                  service_beneficiary: item?.beneficiary,
                };
              }),
              branchName:
                localStorage.getItem("primaryLanguage") === "english"
                  ? service?.branch?.name
                  : service?.branch?.name_sp,
              // priority: service.service_branches.map((Item) => Item),
              priority: service?.priority,
              information: service?.info,
            };
          });
        setServicesList(services);
        setLoading(false);
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  const deleteService = async () => {
    if (deleteServiceID !== "") {
      props.setLoaderState(true);
      await DeleteBranchServiceMethod(
        deleteServiceID,
        (success) => {
          if (success?.status === 200) {
            setOpenConfirmationModal(false);

            Success(success?.data?.response?.message);
            props.setLoaderState(false);
            getServices();
          }
        },
        (err) => {
          if (err?.response?.status === 500) {
            Errors("Network Error");
          } else if (err.response.status === 422) {
            Errors(err.response.data.error.message);
          } else {
            Errors(err?.response?.data?.error?.message);
          }
        }
      );
    }
  };
  const updateServiceStatus = (serviceID) => {
    UpdateServiceStatus(
      serviceID,
      (success) => {
        if (success?.status === 200) {
          Success(success?.data?.response?.message);
          getServices();
        }
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors("Network Error");
        } else if (err.response.status === 422) {
          Errors(err.response.data.error.message);
        } else {
          Errors(err?.response?.data?.error?.message);
        }
      }
    );
  };

  const menu = (text, values) => {
    return (
      <Menu className="menu-class menu-main-class" style={{ borderRadius: 20 }}>
        <Menu.Item
          key={text.id + Math.random()}
          onClick={() => handleMenu(text.service_id, text)}
          className="bg-white"
          style={{ borderRadius: 20 }}
        >
          <div
            className="flex justify-between bg-white py-2 w-full  items-center"
            style={{ width: 160 }}
          >
            <div>
              <span>{adminLabels.edit}</span>
            </div>
            <div>
              <RiPencilLine color="#46bcff" size={20} />
            </div>
          </div>
        </Menu.Item>
        <Menu.Item
          key={text.id + Math.random()}
          className="bg-white"
          style={{ borderRadius: 20 }}
        >
          <div
            className="flex justify-between bg-white py-2 w-full  items-center"
            style={{ width: 160 }}
          >
            <div>
              <span>
                {text?.status === 1
                  ? adminLabels.enabled
                  : adminLabels.disabled}
              </span>
            </div>

            <div>
              <Switch
                size="small"
                checked={text?.status === 1 ? true : false}
                onChange={() => updateServiceStatus(text.service_id)}
              />
            </div>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  const handleMenu = (id, service) => {
    // push(`/admin/edit-service/${id}`);
    push({
      pathname: `/admin/edit-service/${id}`,
      state: { service: service },
    });
  };

  const deleteModal = (id) => {
    setOpenConfirmationModal(true);
    setDeleteServiceID(id);
  };
  const columns = [
    {
      title: adminLabels.no,
      dataIndex: "no",
      key: "no",
      align: "center",
      width: 60,
      render: (text) => <a>{text}</a>,
    },
    {
      title: adminLabels.service,
      dataIndex: "serviceName",
      key: "serviceName",
      align: "center",
      width: 180,
      render: (text, index) =>
        currentSelectedLangue === "english" ? (
          <Tooltip placement="topLeft" title={text?.name}>
          <div className={"flex items-center"}>
            <img
              src={text?.url}
              style={{ width: 45, height: 45 }}
              alt="detail image"
            />
            <div className="pl-2 text-left">
              <label>
                <b>{text?.name}</b>
              </label>
              <br />
              <h4
                style={{ color: "green", marginBottom: 0, fontSize: "10px" }}
              >
                <strong>{text?.desc}</strong>
              </h4>
            </div>
          </div>
        </Tooltip>
        ) : (
          <Tooltip placement="topLeft" title={text?.name}>
          <div className={"flex items-center"}>
            <img
              src={text?.url}
              style={{ width: 45, height: 45 }}
              alt="detail image"
            />
            <div className="pl-2 text-left">
              <label>
                <b>{text?.nameEsp}</b>
              </label>
            </div>
          </div>
        </Tooltip>
       
        ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return currentSelectedLangue === "english"
          ? record.serviceName.name.toLowerCase().includes(value.toLowerCase())
          : record.serviceName.nameEsp.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        currentSelectedLangue === "english"
          ? a.serviceName.name.localeCompare(b.serviceName.name)
          : a.serviceName.nameEsp.localeCompare(b.serviceName.nameEsp),
    },
    {
      align: "center",
      title: adminLabels.serviceArea,
      dataIndex: "servicearea",
      key: "servicearea",
      width: 120,
      render: (e) => (
        <Tooltip
          placement="topLeft"
          title={e?.[currentSelectedLangue === "english" ? "name" : "info"]}
        >
          <div className="flex items-center ml-2">
            <span className="ml-4">{`${
              e?.[currentSelectedLangue === "english" ? "name" : "info"]
            }`}</span>
          </div>
        </Tooltip>
      ),

      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.servicearea?.[currentSelectedLangue === "english" ? "name" : "info"]
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.servicearea?.[currentSelectedLangue === "english" ? "name" : "info"].localeCompare(b?.servicearea?.[currentSelectedLangue === "english" ? "name" : "info"]),
    },
    {
      align: "center",
      title: adminLabels.beneficiaries,
      dataIndex: "beneficiary",
      key: "beneficiary",
      width: 180,
      render: (e) => (
        <Tooltip
          placement="topLeft"
          title={e.map((benf, i) => (
            <div className="flex justify-between" key={i}>
              <div>
                {
                  benf?.beneficiary?.[
                    currentSelectedLangue === "english" ? "name" : "info"
                  ]
                }
              </div>
              <div>&#40;{benf?.priority}&#41;</div>
            </div>
          ))}
        >
          <div className="flex   flex-col">
            {e.map((benf, index) => {
              return (
                <div className="flex justify-between" key={index}>
                  <div className="benefi 	flex space-x-2 items-center">
                    <div>
                      <img
                        src={benf?.beneficiary?.avatar}
                        style={{ width: 20, height: 20 }}
                        alt="benf Image"
                      />
                    </div>
                    <div>
                      {
                        benf?.beneficiary?.[
                          currentSelectedLangue === "english" ? "name" : "info"
                        ]
                      }
                    </div>
                  </div>
                  <div className="priority font-bold">
                    &#40;{benf?.priority}&#41;
                  </div>
                </div>
              );
            })}
          </div>
        </Tooltip>
      ),
    },
    {
      title: adminLabels.branch,
      dataIndex: "branchName",
      key: "branchName",
      align: "center",
      width: 80,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record?.branchName
          ?.toLowerCase()
          ?.includes(value?.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.branchName?.localeCompare(b?.branchName),
      render: (branchName) => (
        <Tooltip placement="topLeft" title={branchName}>
          {branchName}
        </Tooltip>
      ),
    },
    {
      title: adminLabels.information,

      align: "center",
      width: 70,
      render: (text, index) => (
        <div className="table-quill flex justify-center items-center">
          <span
            className="cursor-pointer flex items-center space-x-1 "
            onClick={() => {
              setShowInfoModal(true);
              currentSelectedLangue === "english"
                ? setModalContent(text?.info || "")
                : setModalContent(text?.info_sp || "");
            }}
          >
            <EyeOutlined />
            <span>{adminLabels.infoBox}</span>
          </span>
        </div>
      ),
    },
    {
      title: adminLabels.action,
      key: "action",
      width: 120,

      render: (text, index) => (
        <div className="text-center">
          <Dropdown
            overlay={() => menu(text)}
            className={"flex"}
            trigger={["click"]}
            onVisibleChange={false}
          >
            <div
              type="disable"
              shape={"round"}
              className="background-color-gray whiteTxt rounded-full py-3 justify-center cursor-pointer	"
            >
              <span>{adminLabels.viewDetails}</span>
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <section className="">
      <Spin tip={adminLabels.loading} spinning={loading}>
        <div className="flex justify-between mt-5 ">
          <div className="pl-5 flex self-center justify-center items-center sub-h3">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{adminLabels.back}</span>
            </div>
            <h3 className="ml-10	">{adminLabels.services}</h3>
          </div>
          <div className="mr-1 sm:mr-1 lg:mr-28 flex">
            {/* <div className="mr-2">
              <Button shape={"circle"} className="icon-button p0 ">
                <FiSearch size={15} />
              </Button>
            </div> */}
            <Link to="/admin/add-new-service">
              <Button
                shape={"round"}
                className="icon-button"
                icon={<AiOutlinePlus color="#5ab3f0" />}
              >
                {adminLabels.addNew}
              </Button>
            </Link>
            <div className="ml-2">
              <CSVLink
                data={servicesList.map((item, index) => {
                  return {
                    ...item,
                    no: index + 1,
                    nameOfService:
                      currentSelectedLangue === "english"
                        ? item?.name
                        : item?.name_sp,
                    nameOfServiceArea:
                      item?.servicearea?.[
                        currentSelectedLangue === "english" ? "name" : "info"
                      ],
                    beneficiary: item?.beneficiary?.map((item) => {
                      return ` ${
                        item?.beneficiary?.[
                          currentSelectedLangue === "english" ? "name" : "info"
                        ]
                      } (${item?.priority}) `;
                    }),
                    nameOfBranch: item?.branchName,
                  };
                })}
                headers={[
                  { label: adminLabels.no, key: "no" },
                  { label: adminLabels.service, key: "nameOfService" },
                  { label: adminLabels.serviceArea, key: "nameOfServiceArea" },
                  { label: adminLabels.beneficiaries, key: "beneficiary" },
                  {
                    label: adminLabels.branch,
                    key: "nameOfBranch",
                  },
                ]}
                filename={`${adminLabels.services}.csv`}
                target="_blank"
              >
                <Button
                  shape={"round"}
                  className="icon-button "
                  icon={<AiOutlineArrowDown color="#5ab3f0" />}
                >
                  {adminLabels.exportCSV}
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <div className="container mx-auto lg:px-20 mt-5">
          <TableComponent
            columns={columns}
            data={servicesList}
            // pagination={{ defaultPageSize: 5 }}
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
            scrolly="500px"
          />
        </div>
        <Modal
          visible={showInfoModal}
          centered
          footer={null}
          onCancel={() => {
            setShowInfoModal(false);
          }}
          className="global-modal-css-scroll-height "
        >
          <div>
            <h4 className="font-bold text-2xl text-center text-gray-600	">
              {adminLabels.information}
            </h4>
          </div>
          <div className="scrollable-fixed-height py-5 render-html-class">
            {<InformationContainer info={modal_Content} />}
          </div>
        </Modal>
        <Modal
          visible={openConfirmationModal}
          width={400}
          footer={false}
          onCancel={() => setOpenConfirmationModal(false)}
        >
          <div>
            <div className="flex justify-center w-full">
              <AiFillQuestionCircle
                size={80}
                className="mr-4"
                color={"#5ab3f0"}
              />
            </div>
            <div className="my-5">
              <h4 className="font-bold text-2xm text-center text-gray-600	">
                Do you really want to Delete this Service ?
              </h4>
            </div>
          </div>

          <div className="flex justify-between">
            <button
              className="w-2/5 bg-danger  rounded-full py-2 text-white"
              onClick={deleteService}
            >
              yes
            </button>
            <button
              onClick={() => setOpenConfirmationModal(false)}
              className="w-2/5 bg-primary rounded-full py-2 text-white"
            >
              No
            </button>
          </div>
        </Modal>
      </Spin>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    showServices: state.servicesList.services,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getServicesList: () => {
      dispatch(FetchServicesAction());
    },
    setLogoutUser: (data) => {
      dispatch(LogoutUserAction(data));
    },
    removeBeneficiaries: () => {
      dispatch(DeleteBeneficiariesAtLogoutTime());
    },
    removeProviders: () => {
      dispatch(DeleteProvidersAtLogoutTime());
    },
    removeServiceAreas: () => {
      dispatch(DeleteServiceAreasAtLogoutTime());
    },
    removeBranches: () => {
      dispatch(DeleteBranchesAtLogoutTime());
    },
    removeServices: () => {
      dispatch(DeleteServicesAtLogoutTime());
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
