const initialState = {
  beneficiaries: [],
};

const GetBeneficiariesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_BENEFICIARIES": {
      return {
        ...state,
        beneficiaries: action.payload,
      };
    }
    case "REMOVE_BENEFICIARIES": {
      return {
        ...state,
        beneficiaries: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default GetBeneficiariesReducer;
