import { DatePicker } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import React, { useState } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Errors, Success } from "../../components/antdToast";
import ButtonComponent from "../../components/ButtonComponent";
import { Remove_Data_Form_Reducers } from "../../components/dataRemoveOnLogout";
import InputComponent from "../../components/InputComponent";
import { AddPromoCodeMethod } from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { functionToPreventSignsFromNumberFromPricing } from "../../config/utils";
import { Images } from "../../constant/Images";
import { DeleteBeneficiariesAtLogoutTime } from "../../Redux/actions/fetchBeneficiariesAction";
import { DeleteBranchesAtLogoutTime } from "../../Redux/actions/fetchBranchesAction";
import {
  DeleteProvidersAtLogoutTime,
  FetchProvidersAction,
} from "../../Redux/actions/fetchProvidersAction";
import { DeleteServiceAreasAtLogoutTime } from "../../Redux/actions/fetchServiceAreaAction";
import { DeleteServicesAtLogoutTime } from "../../Redux/actions/fetchServicesAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";
import "./../../style/index.css";
const { business, other, selfemployed, particular } = Images;

function AddPromoCode(props) {
  const { goBack, push } = useHistory();
  // Declare Variable States
  const [promoCode, setPromoCode] = useState("");
  const [userLimit, setUserLimit] = useState("");
  const [discount, setDiscount] = useState("");
  const [startDate, setStartDate] = useState(
    String(moment(new Date()).format("YYYY/MM/DD"))
  );
  const [endDate, setEndDate] = useState(
    String(moment(new Date()).format("YYYY/MM/DD"))
  );
  const [discountError, setDiscountError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [dateError, setDateError] = useState(false);
  const [isFieldError, setIsFieldError] = useState(false);
  const handleStartDate = (date, dateString) => {
    if (date !== null) setStartDate(dateString);
  };
  
  const handleEndDate = (date, dateString) => {
    if (date !== null) setEndDate(dateString);
  };
  const createPromoCode = () => {
    if (
      promoCode?.trim()?.length !== 0 &&
      userLimit?.trim()?.length !== 0 &&
      discount?.trim()?.length !== 0 &&
      moment(endDate).isAfter(startDate) &&
      discountError === false &&
      dateError === false
    ) {
      let obj = {
        code: promoCode,
        start_date: moment(startDate)?.format("YYYY-MM-DD"),
        end_date: moment(endDate)?.format("YYYY-MM-DD"),
        discount: discount,
        limit: userLimit,
      };
      props.setLoaderState(true);
      AddPromoCodeMethod(
        obj,
        (success) => {
          if (success?.status === 200) {
            Success(success?.data?.response?.message);
            push("/admin/promo-codes");
            props.setLoaderState(false);
            setDiscount("");
            setPromoCode("");
            setUserLimit("");
            setStartDate("2021-10-20");
            setEndDate("2021-10-20");
          }
        },
        (fail) => {
          if (fail !== null) {
            if (fail?.response?.status === 409) {
              Errors(fail?.response?.data?.error?.message);
              props.setLoaderState(false);
              setIsFieldError(false);
            } else if (fail?.response?.status === 401) {
              props.setLoaderState(false);
              Remove_Data_Form_Reducers(props);
              push("/admin");
            } else {
              props.setLoaderState(false);
              Errors("Network Error");
            }
          }
        }
      );
    } else {
      setIsFieldError(true);
    }
  };
  return (
    <section className="">
      <div className="flex self-center items-center ml-12 pt-10 pb-5">
        <div
          onClick={() => goBack()}
          className="flex text-gray-400 items-center cursor-pointer"
        >
          <RiArrowDropLeftLine />
          <span>{adminLabels.back}</span>
        </div>
        <h3 className="font-bold text-lg mb-0 ml-10">
          {adminLabels.addNewPromo}
        </h3>
      </div>
      <div className="container px-2 sm:px-2 lg:px-20 lg:pl-32 ">
        <div className="grid grid-cols-12">
          <div className="px-30 col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6">
            <InputComponent
              className="  "
              placeholder={adminLabels.promoCode}
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              maxLength={6}
            />
            {isFieldError === true && promoCode?.trim()?.length === 0 && (
              <p className="text-red-500 ml-2">
                {adminLabels.pleaseFillTheField}
              </p>
            )}
          </div>
          <div className=" lg:ml-2 px-30 col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6">
            <InputComponent
              className="  "
              placeholder={adminLabels.userLimit}
              value={userLimit}
              type="number"
              onChange={(e) => setUserLimit(e.target.value)}
              onKeyDown={functionToPreventSignsFromNumberFromPricing}
            />
            {isFieldError === true && userLimit === "" && (
              <p className="text-red-500 ml-2">
                {adminLabels.pleaseFillTheField}
              </p>
            )}
          </div>
        </div>

        <div className="grid grid-cols-12 ">
          <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6">
            <InputComponent
              placeholder={adminLabels.discount}
              value={discount}
              type="number"
              onKeyDown={functionToPreventSignsFromNumberFromPricing}
              onChange={(e) => {
                if (e.target.value > 99) {
                  setDiscountError(true);
                  setErrorMessage("Discount must be less than 100");
                } else {
                  setDiscount(e.target.value);
                  setDiscountError(false);
                  setErrorMessage("");
                }
              }}
            />
            {isFieldError === true && discount?.trim()?.length === 0 && (
              <p className="text-red-500 ml-2">
                {adminLabels.pleaseFillTheField}
              </p>
            )}
            {isFieldError === true && discount !== "" && Number(discount) > 99 && (
              <p className="text-red-500 ml-2">
                Discount must be less than 100
              </p>
            )}
          </div>
        </div>
        <div className="grid grid-cols-6 mb-3 mt-4 pl-3">
          <h3>{adminLabels.duration}</h3>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2">
          <div className=" flex  items-center mb-4">
            <DatePicker
              style={{ width: "48%" }}
              className="border-radius20  "
              suffixIcon={<FaCalendarAlt color={"#5ab3f0"} />}
              onChange={handleStartDate}
              value={moment(startDate, "YYYY-MM-DD")}
            />
            <span className="mx-3">{adminLabels.to}</span>
            <DatePicker
              style={{ width: "48%" }}
              className=" border-radius20 "
              suffixIcon={<FaCalendarAlt color={"#5ab3f0"} />}
              onChange={handleEndDate}
              value={moment(endDate, "YYYY-MM-DD")}
              // disabled={startDate === "2021-10-20"}
            />
          </div>
          <br />
          {isFieldError === true && !moment(endDate).isAfter(startDate) && (
            <p className="text-red-500 ml-2">
              end date should be greater than start date
            </p>
          )}
          {dateError === true && <p className="text-red-500">{errorMessage}</p>}
        </div>

        <div className="w-full sm:w-3/12  pr-4">
          <ButtonComponent
            className="bg-primary"
            title={adminLabels.save}
            onClick={createPromoCode}
            disabled={props.loader === true ? true : false}
            loader={props.loader === true ? true : false}
          />
        </div>
      </div>
      <Modal>
        <div className="flex justify-between items-center my-5 ">
          <DatePicker
            className="border-radius20 datePickerStyling"
            suffixIcon={<FaCalendarAlt color={"#5ab3f0"} />}
          />
          <span>to</span>
          <DatePicker
            className="border-radius20 datePickerStyling"
            suffixIcon={<FaCalendarAlt color={"#5ab3f0"} />}
          />
        </div>
      </Modal>
    </section>
  );
}
const mapStateToProps = (state) => {
  return {
    loader: state.loaderToggle.loaderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLogoutUser: (data) => {
      dispatch(LogoutUserAction(data));
    },
    getProvidersList: (props) => {
      dispatch(FetchProvidersAction(props));
    },
    removeBeneficiaries: () => {
      dispatch(DeleteBeneficiariesAtLogoutTime());
    },
    removeProviders: () => {
      dispatch(DeleteProvidersAtLogoutTime());
    },
    removeServiceAreas: () => {
      dispatch(DeleteServiceAreasAtLogoutTime());
    },
    removeBranches: () => {
      dispatch(DeleteBranchesAtLogoutTime());
    },
    removeServices: () => {
      dispatch(DeleteServicesAtLogoutTime());
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPromoCode);
