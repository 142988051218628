import { SearchOutlined } from "@ant-design/icons";
import { Badge, Button, Input, Spin, Tooltip } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiOutlineArrowDown } from "react-icons/ai";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { Errors } from "../../components/antdToast";
import { Remove_Data_Form_Reducers } from "../../components/dataRemoveOnLogout";
import InformationContainer from "../../components/informationContainer";
import ModalComponent from "../../components/Modal";
import TableComponent from "../../components/table";
import {
  AdminAndServiceProviderAdditionalBudgetReadMethod,
  GetAdditonalBudget,
} from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import {
  capitalizeFirstLetter,
  europeanCurrencyFormatter,
  get_url_extension,
} from "../../config/utils";
import { Images } from "../../constant/Images";
const { searchBar } = Images;
const currentSelectedLangue = localStorage.getItem("primaryLanguage");

export default function BudgetResponse(params) {
  const [openModal, setOpenModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [budgetResponse, setBudgetResponse] = useState([]);
  const [budgetResponseNoResponse, setBudgetResponseNoResponse] = useState([]);
  const [budgetResponsePaymentAccepted, setBudgetResponsePaymentAccepted] =
    useState([]);
  const [budgetResponseChangeRequested, setBudgetResponseChangeRequested] =
    useState([]);
  const [budgetResponseComplete, setBudgetResponseComplete] = useState([]);
  const [budgetResponseDispute, setBudgetResponseDispute] = useState([]);
  const [budgetResponseDisputed, setBudgetResponseDisputed] = useState([]);

  const [budgetResponseDetail, setBudgetResponseDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const [budgetUrls, setBudgetUrls] = useState([]);
  const { goBack, push } = useHistory();
  const [index, setIndex] = useState(0);

  const [additionalBudgetSteps, setAdditionalBudgetSteps] = useState([
    { id: 0, title: adminLabels.all, status: "all" },
    {
      id: 1,
      title: adminLabels.NoResponse,
      status: "pending",
      count: 0,
    },
    {
      id: 2,
      title: adminLabels.paymentAccepted,
      status: "accepted",
      count: 0,
    },
    {
      id: 3,
      title: adminLabels.changeRequested,
      status: "declined",
      count: 0,
    },
    {
      id: 4,
      title: adminLabels.dispute,
      status: "dispute",
      count: 0,
    },
    {
      id: 6,
      title: adminLabels.disputed,
      status: "disputed",
      count: 0,
    },
    {
      id: 5,
      title: adminLabels.completed,
      status: "completed",
      count: 0,
    },
  ]);
  useEffect(() => {
    fetchAdditonalBudgets();
    document.body.style.overflow = "auto";
    return function cleanup() {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    setAdditionalBudgetSteps((prev) =>
      prev.map((item) => {
        if (item.id === 1) {
          return {
            ...item,
            count: budgetResponseNoResponse.filter(
              (item) => item?.is_read_admin === 0
            )?.length,
          };
        } else if (item.id === 2) {
          return {
            ...item,
            count: budgetResponsePaymentAccepted.filter(
              (item) => item?.is_read_admin === 0
            )?.length,
          };
        } else if (item.id === 3) {
          return {
            ...item,
            count: budgetResponseChangeRequested.filter(
              (item) => item?.is_read_admin === 0
            )?.length,
          };
        } else if (item.id === 4) {
          return {
            ...item,
            count: budgetResponseDispute.filter(
              (item) => item?.is_read_admin === 0
            )?.length,
          };
        } else if (item.id === 6) {
          return {
            ...item,
            count: budgetResponseDisputed.filter(
              (item) => item?.is_read_admin === 0
            )?.length,
          };
        } else if (item.id === 5) {
          return {
            ...item,
            count: budgetResponseComplete.filter(
              (item) => item?.is_read_admin === 0
            )?.length,
          };
        } else {
          return item;
        }
      })
    );
  }, [
    budgetResponseNoResponse,
    budgetResponsePaymentAccepted,
    budgetResponseChangeRequested,
    budgetResponseDispute,
    budgetResponseDisputed,
    budgetResponseComplete,
  ]);
  const fetchAdditonalBudgets = () => {
    GetAdditonalBudget(
      (success) => {
        let data = success?.data?.response?.detail?.map((budget, index) => {
          return {
            ...budget,
            no: index + 1,
            product: budget?.order?.service,
            nameOfService:
              budget?.order?.service?.[
                currentSelectedLangue === "english" ? "name" : "name_sp"
              ],
            orderid: budget?.order_id,
            date: moment(budget?.created_at)?.format("DD-MMM-YYYY HH:mm"),
            price:
              Number(budget?.amount || 0) +
              Number(budget?.supplementary_fees || 0),
            provider: budget?.order?.service_provider?.name,
            orderStatus: adminLabels?.[budget?.order?.status],
          };
        });
        setBudgetResponse(
          data?.map((adb, i) => {
            return {
              ...adb,
              no: i + 1,
              additionalBudgetStatus:
                adb?.status === "accepted"
                  ? adminLabels.paymentAccepted
                  : adb?.status === "declined"
                  ? adminLabels.changeRequested
                  : adb?.status === "dispute"
                  ? adminLabels.dispute
                  : adb?.status === "completed"
                  ? adminLabels.completed
                  : adminLabels.NoResponse,
            };
          })
        );

        setBudgetResponseNoResponse(
          data
            ?.filter((item) => item?.status === "pending")
            .map((adb, i) => {
              return {
                ...adb,
                no: i + 1,
                additionalBudgetStatus:
                  adb?.status === "accepted"
                    ? adminLabels.paymentAccepted
                    : adb?.status === "declined"
                    ? adminLabels.changeRequested
                    : adb?.status === "dispute"
                    ? adminLabels.dispute
                    : adb?.status === "disputed"
                    ? adminLabels.disputed
                    : adb?.status === "completed"
                    ? adminLabels.completed
                    : adminLabels.NoResponse,
              };
            })
        );

        setBudgetResponsePaymentAccepted(
          data
            ?.filter((item) => item?.status === "accepted")
            .map((adb, i) => {
              return {
                ...adb,
                no: i + 1,
                additionalBudgetStatus:
                  adb?.status === "accepted"
                    ? adminLabels.paymentAccepted
                    : adb?.status === "declined"
                    ? adminLabels.changeRequested
                    : adb?.status === "dispute"
                    ? adminLabels.dispute
                    : adb?.status === "disputed"
                    ? adminLabels.disputed
                    : adb?.status === "completed"
                    ? adminLabels.completed
                    : adminLabels.NoResponse,
              };
            })
        );
        setBudgetResponseChangeRequested(
          data
            ?.filter((item) => item?.status === "declined")
            .map((adb, i) => {
              return {
                ...adb,
                no: i + 1,
                additionalBudgetStatus:
                  adb?.status === "accepted"
                    ? adminLabels.paymentAccepted
                    : adb?.status === "declined"
                    ? adminLabels.changeRequested
                    : adb?.status === "dispute"
                    ? adminLabels.dispute
                    : adb?.status === "disputed"
                    ? adminLabels.disputed
                    : adb?.status === "completed"
                    ? adminLabels.completed
                    : adminLabels.NoResponse,
              };
            })
        );
        setBudgetResponseComplete(
          data
            ?.filter((item) => item?.status === "completed")
            .map((adb, i) => {
              return {
                ...adb,
                no: i + 1,
                additionalBudgetStatus:
                  adb?.status === "accepted"
                    ? adminLabels.paymentAccepted
                    : adb?.status === "declined"
                    ? adminLabels.changeRequested
                    : adb?.status === "dispute"
                    ? adminLabels.dispute
                    : adb?.status === "disputed"
                    ? adminLabels.disputed
                    : adb?.status === "completed"
                    ? adminLabels.completed
                    : adminLabels.NoResponse,
              };
            })
        );
        setBudgetResponseDispute(
          data
            ?.filter((item) => item?.status === "dispute")
            .map((adb, i) => {
              return {
                ...adb,
                no: i + 1,
                additionalBudgetStatus:
                  adb?.status === "accepted"
                    ? adminLabels.paymentAccepted
                    : adb?.status === "declined"
                    ? adminLabels.changeRequested
                    : adb?.status === "dispute"
                    ? adminLabels.dispute
                    : adb?.status === "disputed"
                    ? adminLabels.disputed
                    : adb?.status === "completed"
                    ? adminLabels.completed
                    : adminLabels.NoResponse,
              };
            })
        );
        setBudgetResponseDisputed(
          data
            ?.filter((item) => item?.status === "disputed")
            .map((adb, i) => {
              return {
                ...adb,
                no: i + 1,
                additionalBudgetStatus:
                  adb?.status === "accepted"
                    ? adminLabels.paymentAccepted
                    : adb?.status === "declined"
                    ? adminLabels.changeRequested
                    : adb?.status === "dispute"
                    ? adminLabels.dispute
                    : adb?.status === "disputed"
                    ? adminLabels.disputed
                    : adb?.status === "completed"
                    ? adminLabels.completed
                    : adminLabels.NoResponse,
              };
            })
        );
        setLoading(false);
      },
      (fail) => {
        if (fail !== null) {
          if (fail?.response?.status === 409) {
            Errors(fail?.response?.data?.error?.message);
            params.setLoaderState(false);
          } else if (fail?.response?.status === 401) {
            params.setLoaderState(false);
            Errors(fail?.response?.data?.error?.message);
            Remove_Data_Form_Reducers(params);
            push("/admin");
          } else {
            params.setLoaderState(false);
            Errors("Network Error");
          }
        }
      }
    );
  };
  const downloadFiles = (url, name) => {
    axios({
      url: url,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
      });
  };
  const columns = [
    {
      title: adminLabels.no,
      dataIndex: "no",
      key: "no",
      align: "center",
      width: 40,
      render: (text) => <a>{text}</a>,
    },
    {
      align: "center",
      title: adminLabels.product,
      dataIndex: "product",
      width: 180,
      key: "product",
      render: (text, index) => (
        <Tooltip placement="topLeft" title={text?.name}>
          <div className={"flex"}>
            <img
              src={text?.avatar}
              style={{ width: 50, height: 45 }}
              alt="detail image"
            />
            <div className="pl-2 text-left">
              <label>
                <b>
                  {
                    text?.[
                      currentSelectedLangue === "english" ? "name" : "name_sp"
                    ]
                  }
                </b>
              </label>{" "}
              <br />
            </div>
          </div>
        </Tooltip>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a?.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]?.localeCompare(
          b?.product?.[currentSelectedLangue === "english" ? "name" : "name_sp"]
        ),
    },
    {
      title: adminLabels.orderId,
      dataIndex: "orderid",
      key: "orderid",
      align: "center",
      width: 130,
      align: "center",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.orderid.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.orderid?.localeCompare(b?.orderid),
      render: (orderid) => (
        <Tooltip placement="topLeft" title={orderid}>
          {orderid}
        </Tooltip>
      ),
    },
    {
      title: adminLabels.date,
      key: "date",
      dataIndex: "date",
      align: "center",
      width: 130,
      align: "center",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      showSorterTooltip: false,
      render: (date) => (
        <Tooltip placement="topLeft" title={date}>
          {date}
        </Tooltip>
      ),
    },
    {
      title: adminLabels.price,
      key: "price",
      dataIndex: "price",
      align: "center",
      width: 110,
      align: "center",
      render: (text, index) => (
        <Tooltip
          placement="topLeft"
          title={text ? "€" + europeanCurrencyFormatter(text) : ""}
        >
          {/* <div className="text-center flex justify-center"> */}
          {text ? "€" + europeanCurrencyFormatter(text) : ""}
          {/* </div> */}
        </Tooltip>
      ),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.price - b.price,
      showSorterTooltip: false,
    },
    {
      title: adminLabels.provider,
      dataIndex: "provider",
      key: "provider",
      align: "center",
      width: 100,
      align: "center",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.provider.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.provider?.localeCompare(b?.provider),
      render: (provider) => (
        <Tooltip placement="topLeft" title={provider}>
          {provider}
        </Tooltip>
      ),
    },
    // {
    //   title: adminLabels.order,
    //   key: "orderStatus",
    //   dataIndex: "orderStatus",
    //   width: 80,
    //   align: "center",
    //   sortDirections: ["descend", "ascend"],
    //   sorter: (a, b) => a?.orderStatus?.localeCompare(b?.orderStatus),
    //   render: (order) => (
    //     <Tooltip placement="topLeft" title={order}>
    //       <span className="capitalize">{order}</span>
    //     </Tooltip>
    //   ),
    // },
    {
      title: adminLabels.status,
      key: "additionalBudgetStatus",
      dataIndex: "additionalBudgetStatus",
      width: 90,
      align: "center",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a?.additionalBudgetStatus?.localeCompare(b?.additionalBudgetStatus),
      render: (status) => (
        <Tooltip placement="topLeft" title={status}>
          <span className={`capitalize`}>{status}</span>
        </Tooltip>
      ),
    },
    //  ...(index === 2
    //     ? {
    //         title: "download bill",
    //         key: "action",
    //         width: 90,

    //         render: (text, index) => (
    //           <>
    //             <div
    //               type="disable"
    //               shape={"round"}
    //               className="text-center background-color-gray whiteTxt rounded-full py-1 justify-center cursor-pointer	w-full"
    //             >
    //               <span>download</span>
    //             </div>
    //           </>
    //         ),
    //       }
    //     : {}),
    {
      title: adminLabels.action,
      key: "action",
      width: 90,

      render: (text, index) => (
        <>
          <div className="text-center	gap-1 flex items-center justify-center">
            <div className="mb-1">
              {!text?.is_read_admin ? (
                <span className="font-bold text-red-500 text-4xl mr-1">.</span>
              ) : (
                <span className="font-bold font-color-blue text-4xl mr-1 opacity-0">
                  .
                </span>
              )}
            </div>
            <div
              type="disable"
              shape={"round"}
              className="background-color-gray whiteTxt rounded-full py-1 justify-center cursor-pointer	w-full"
              onClick={() => {
                setBudgetUrls(JSON?.parse(text?.files));
                setBudgetResponseDetail(text);
                setFilterModal(true);
              }}
            >
              <span>{adminLabels.view}</span>
            </div>
          </div>
        </>
      ),
    },
  ];
  const handleReadAdditionalBudget = () => {
    if (filterModal) {
      const obj = {
        order_id: budgetResponseDetail.order_id,
        additional_budget_id: budgetResponseDetail?.additional_budget_id,
        read_user: "is_read_admin",
      };
      AdminAndServiceProviderAdditionalBudgetReadMethod(
        obj,
        (res) => {
          if (res?.data?.status) {
            if (index === 0) {
              setBudgetResponse((prev) =>
                prev.map((item) => {
                  if (
                    item?.additional_budget_id ===
                    budgetResponseDetail?.additional_budget_id
                  ) {
                    return {
                      ...item,
                      is_read_admin: 1,
                    };
                  } else {
                    return item;
                  }
                })
              );
            }
            if (index === 1) {
              setBudgetResponseNoResponse((prev) =>
                prev.map((item) => {
                  if (
                    item?.additional_budget_id ===
                    budgetResponseDetail?.additional_budget_id
                  ) {
                    return {
                      ...item,
                      is_read_admin: 1,
                    };
                  } else {
                    return item;
                  }
                })
              );
            }
            if (index === 2) {
              setBudgetResponsePaymentAccepted((prev) =>
                prev.map((item) => {
                  if (
                    item?.additional_budget_id ===
                    budgetResponseDetail?.additional_budget_id
                  ) {
                    return {
                      ...item,
                      is_read_admin: 1,
                    };
                  } else {
                    return item;
                  }
                })
              );
            }
            if (index === 3) {
              setBudgetResponseChangeRequested((prev) =>
                prev.map((item) => {
                  if (
                    item?.additional_budget_id ===
                    budgetResponseDetail?.additional_budget_id
                  ) {
                    return {
                      ...item,
                      is_read_admin: 1,
                    };
                  } else {
                    return item;
                  }
                })
              );
            }
            if (index === 4) {
              
              setBudgetResponseDispute((prev) =>
                prev.map((item) => {
                  if (
                    item?.additional_budget_id ===
                    budgetResponseDetail?.additional_budget_id
                  ) {
                    return {
                      ...item,
                      is_read_admin: 1,
                    };
                  } else {
                    return item;
                  }
                })
              );
            }

            if (index === 5) {
              
              setBudgetResponseComplete((prev) =>
                prev.map((item) => {
                  if (
                    item?.additional_budget_id ===
                    budgetResponseDetail?.additional_budget_id
                  ) {
                    return {
                      ...item,
                      is_read_admin: 1,
                    };
                  } else {
                    return item;
                  }
                })
              );
            }

            if (index === 6) {
              setBudgetResponseDisputed((prev) =>
                prev.map((item) => {
                  if (
                    item?.additional_budget_id ===
                    budgetResponseDetail?.additional_budget_id
                  ) {
                    return {
                      ...item,
                      is_read_admin: 1,
                    };
                  } else {
                    return item;
                  }
                })
              );
            }
            // ? budgetResponse
            // : index === 1
            // ? budgetResponseNoResponse
            // : index === 2
            // ? budgetResponsePaymentAccepted
            // : index === 3
            // ? budgetResponseChangeRequested
            // : [])
          }
        },
        (err) => {
          if (err?.response?.status === 401) {
            Errors(err?.response?.data?.message);
            push("/admin");
          } else {
            Errors("Network Error");
          }
        }
      );
    }
  };
  useEffect(() => {
    handleReadAdditionalBudget();
  }, [filterModal]);
  return (
    <section className="">
      <Spin tip={adminLabels.loading} spinning={loading}>
        <div
          // style={{ marginRight: "95px" }}
          className="flex justify-between mt-5 mr-2 sm:mr-2 lg:mr-32"
        >
          <div className="pl-5 flex self-center justify-around items-center ">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer sub-h3"
            >
              <RiArrowDropLeftLine />
              <span>{adminLabels.back}</span>
            </div>
            <h3 className="ml-10">{adminLabels.budgetResponse}</h3>
          </div>
          <div className=" flex">
            {/* <div className="mr-2 ">
              <Button shape={"circle"} className="icon-button p0 ">
                <FiSearch size={15} />
              </Button>
            </div> */}
            {additionalBudgetSteps?.map((item) => (
              <Badge count={item.count} offset={[-11]} overflowCount={100}>
                <Button
                  key={item.id}
                  shape={"round"}
                  onClick={() => setIndex(item.id)}
                  className={
                    index == item.id
                      ? `icon-button mx-1 bg-primary text-white`
                      : `icon-button mx-1`
                  }
                >
                  {capitalizeFirstLetter(item?.title)}
                </Button>
              </Badge>
            ))}
            <div>
              <CSVLink
                filename={`${adminLabels.budgetResponse} ${
                  index !== 0 ? additionalBudgetSteps?.[index]?.title : ""
                }.csv`}
                data={
                  index === 0
                    ? budgetResponse
                    : index === 1
                    ? budgetResponseNoResponse
                    : index === 2
                    ? budgetResponsePaymentAccepted
                    : index === 3
                    ? budgetResponseChangeRequested
                    : index === 4
                    ? budgetResponseDispute
                    : index === 5
                    ? budgetResponseComplete
                    : index === 6
                    ? budgetResponseDisputed
                    : []?.map((item, index) => {
                        return {
                          ...item,
                          no: index + 1,
                        };
                      })
                }
                headers={[
                  { label: adminLabels.no, key: "no" },
                  { label: adminLabels.product, key: "nameOfService" },
                  { label: adminLabels.orderId, key: "orderid" },
                  { label: adminLabels.date, key: "date" },
                  { label: adminLabels.price, key: "price" },
                  { label: adminLabels.provider, key: "provider" },
                  { label: adminLabels.status, key: "additionalBudgetStatus" },
                ]}
                target="_blank"
              >
                <Button
                  shape={"round"}
                  className="icon-button "
                  icon={<AiOutlineArrowDown color="#5ab3f0" />}
                >
                  {adminLabels.exportCSV}
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>
        <ModalComponent
          filterModal={filterModal}
          width={570}
          closeModal={() => setFilterModal(false)}
          modalTitle={adminLabels.requestDetail}
          className="global-modal-css-scroll-height"
        >
          <div className="scrollable-fixed-height">
            <div className="flex items-center mt-8 ml-2">
              <img
                src={budgetResponseDetail?.order?.service?.avatar}
                style={{ width: 50, height: 45 }}
                alt="detail image"
              />
              <div className="pl-2 text-left">
                <label>
                  <b>
                    {
                      budgetResponseDetail?.order?.service?.[
                        currentSelectedLangue === "english" ? "name" : "name_sp"
                      ]
                    }
                  </b>
                </label>{" "}
                <br />
              </div>
            </div>
            <div className="grid grid-cols-6 ml-2">
              <div className="col-span-2">
                <div className="mt-5">
                  <h4 className="font-black">{adminLabels.provider}</h4>
                  <p className="text-gray-400">
                    {budgetResponseDetail?.order?.service_provider?.name}
                  </p>
                </div>
              </div>
              <div className="col-span-2">
                <div className="mt-5">
                  <h4 className="font-black">{adminLabels.orderId}</h4>
                  <p className="text-gray-400">
                    {budgetResponseDetail.order_id}
                  </p>
                </div>
              </div>

              <div className="col-span-2">
                <div className="mt-5">
                  <h4 className="font-black">{adminLabels.estimatedTime}</h4>
                  <p className="text-gray-400">
                    {budgetResponseDetail?.time}{" "}
                    {budgetResponseDetail?.time_type}
                  </p>
                </div>
              </div>
              <div className="col-span-2">
                <div className="mt-5">
                  <h4 className="font-black">{adminLabels.date}</h4>
                  <p className="text-gray-400">
                    {moment(budgetResponseDetail?.created_at)?.format(
                      "DD-MMM-YYYY HH:mm"
                    )}
                  </p>
                </div>
              </div>

              <div className="col-span-2">
                <div className="mt-5">
                  <h4 className="font-black">{adminLabels.serviceFees}</h4>
                  <p className="text-gray-400">
                    €
                    {europeanCurrencyFormatter(
                      Number(budgetResponseDetail?.amount || 0)
                    )}{" "}
                  </p>
                </div>
              </div>

              <div className="col-span-2">
                <div className="mt-5">
                  <h4 className="font-black">
                    {adminLabels.supplementaryFees}
                  </h4>
                  <p className="text-gray-400">
                    €
                    {europeanCurrencyFormatter(
                      budgetResponseDetail?.supplementary_fees
                    )}
                  </p>
                </div>
              </div>
              <div className="col-span-2">
                <div className="mt-5">
                  <h4 className="font-black">{adminLabels.docuDocFees}</h4>
                  <p className="text-gray-400">
                    €
                    {europeanCurrencyFormatter(
                      Number(budgetResponseDetail?.amount || 0) * 0.3
                    )}{" "}
                  </p>
                </div>
              </div>
              <div className="col-span-2">
                <div className="mt-5">
                  <h4 className="font-black">{adminLabels.providerAmount}</h4>
                  <p className="text-gray-400">
                    {" "}
                    €
                    {europeanCurrencyFormatter(
                      Math.ceil(
                        Number(budgetResponseDetail?.amount || 0) * 0.7
                      ) + Number(budgetResponseDetail?.supplementary_fees || 0)
                    )}{" "}
                  </p>
                </div>
              </div>
              <div className="col-span-2">
                <div className="mt-5">
                  <h4 className="font-black">{adminLabels.status}</h4>
                  <p className="text-gray-400">
                    {budgetResponseDetail.additionalBudgetStatus}
                  </p>
                </div>
              </div>
              {budgetResponseDetail?.supplementaries_fees?.length > 0 ? (
                <div className="col-span-4">
                  <div className="mt-5">
                    <h4 className="font-black">
                      {adminLabels.supplementaryDetails}
                    </h4>
                    {budgetResponseDetail?.supplementaries_fees?.map((item) => (
                      <p className="text-gray-400">
                        <div className="flex justify-between">
                          <div className="text-sm text-black">{item?.name}</div>
                          <div className="text-sm text-gray-400">
                            {" "}
                            €{europeanCurrencyFormatter(item?.price)}
                          </div>
                        </div>
                      </p>
                    ))}
                  </div>
                </div>
              ) : null}
              <div className="col-span-4">
                <div className="mt-5">
                  <h4 className="font-black">
                    {adminLabels.serviceDetailedDescription}
                  </h4>
                  <p className="mt-2 render-html-class">
                    {budgetResponseDetail?.notes ? (
                      <InformationContainer
                        info={budgetResponseDetail?.notes}
                      />
                    ) : null}
                  </p>
                </div>
              </div>
            </div>
            <div className="space-y-3 mx-2">
              {budgetUrls?.length > 0 ? (
                <div className="mt-5">
                  <h4 className="font-black">{adminLabels.attachments}</h4>
                  <div>
                    {budgetUrls?.length > 0 ? (
                      <div className="pt-2 flex justify-between flex-wrap">
                        {budgetUrls?.map((item, index) => {
                          return (
                            <div
                              className="flex justify-between w-28"
                              key={index}
                            >
                              <div
                                onClick={() =>
                                  downloadFiles(item.url, item?.name)
                                }
                                className="cursor-pointer mb-0"
                                style={{ color: "#44B6F7" }}
                              >
                                <span>{index + 1} - </span>
                                <span>
                                  {item?.name.substring(0, 5)}
                                  {item?.name?.length > 5 &&
                                    "... " +
                                      item?.name?.slice(
                                        item?.name?.lastIndexOf(".") + 1
                                      )}{" "}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      adminLabels.notAvailable
                    )}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="space-y-3 mx-2">
              {budgetResponseDetail?.budget_bill !== null ? (
                <div className="flex justify-between items-center">
                  <h4 className="font-black">
                    {
                      adminLabels.serviceBillServiceBillOfServiceProviderAdditionalBudgetBill
                    }
                  </h4>
                  <Button
                    shape={"round"}
                    onClick={() =>
                      downloadFiles(
                        budgetResponseDetail?.budget_bill,
                        `${
                          adminLabels.serviceBillServiceBillOfServiceProviderAdditionalBudgetBill
                        }.${get_url_extension(
                          budgetResponseDetail?.budget_bill
                        )}`
                      )
                    }
                    className={`whiteTxt bg-primary`}
                  >
                    {adminLabels.download}
                  </Button>
                </div>
              ) : null}
              {budgetResponseDetail?.supplementary_bill !== null ? (
                <div className="flex justify-between items-center">
                  <h4 className="font-black">
                    {adminLabels.suplementsBillOfServiceProvider}
                  </h4>
                  <Button
                    shape={"round"}
                    onClick={() =>
                      downloadFiles(
                        budgetResponseDetail?.supplementary_bill,
                        `${
                          adminLabels.suplementsBillOfServiceProvider
                        }.${get_url_extension(
                          budgetResponseDetail?.supplementary_bill
                        )}`
                      )
                    }
                    className={`whiteTxt bg-primary`}
                  >
                    {adminLabels.download}
                  </Button>
                </div>
              ) : null}
              {budgetResponseDetail?.status === "dispute" ? (
                <div className="flex justify-between items-center">
                  <div className="">
                    <Button
                      shape={"round"}
                      size="sm"
                      className={`icon-button  bg-danger`}
                      onClick={() =>
                        push(
                          `budget-response-detail/${budgetResponseDetail?.additional_budget_id}`
                        )
                      }
                    >
                      <span className="text-white">
                        {adminLabels?.endDispute}
                      </span>
                    </Button>
                  </div>
                </div>
              ) : null}

              
            </div>
          </div>
        </ModalComponent>

        <div className="container mx-auto lg:px-20 mt-5">
          {index === 0 ? (
            <>
              <TableComponent
                columns={columns}
                data={budgetResponse || []}
                pagination={{ defaultPageSize: 6, showSizeChanger: true }}
                scrolly="500px"
              />
            </>
          ) : index === 1 ? (
            <>
              <TableComponent
                columns={columns}
                data={budgetResponseNoResponse || []}
                pagination={{ defaultPageSize: 6, showSizeChanger: true }}
                scrolly="500px"
              />
            </>
          ) : index === 2 ? (
            <>
              <TableComponent
                columns={columns}
                data={budgetResponsePaymentAccepted || []}
                pagination={{ defaultPageSize: 6, showSizeChanger: true }}
                scrolly="500px"
              />
            </>
          ) : index === 3 ? (
            <>
              <TableComponent
                columns={columns}
                data={budgetResponseChangeRequested || []}
                pagination={{ defaultPageSize: 6, showSizeChanger: true }}
                scrolly="500px"
              />
            </>
          ) : index === 4 ? (
            <>
              <TableComponent
                columns={columns}
                data={budgetResponseDispute || []}
                pagination={{ defaultPageSize: 6, showSizeChanger: true }}
                scrolly="500px"
              />
            </>
          ) : index === 5 ? (
            <>
              <TableComponent
                columns={columns}
                data={budgetResponseComplete || []}
                pagination={{ defaultPageSize: 6, showSizeChanger: true }}
                scrolly="500px"
              />
            </>
          ) : index === 6 ? (
            <>
              <TableComponent
                columns={columns}
                data={budgetResponseDisputed || []}
                pagination={{ defaultPageSize: 6, showSizeChanger: true }}
                scrolly="500px"
              />
            </>
          ) : null}
          {/* <TableComponent
            columns={columns}
            data={
              index === 0
                ? budgetResponse
                : index === 1
                ? budgetResponseNoResponse
                : index === 2
                ? budgetResponsePaymentAccepted
                : index === 3
                ? budgetResponseChangeRequested
                : []
            }
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
            scrolly="500px"
          /> */}
        </div>
      </Spin>
    </section>
  );
}
