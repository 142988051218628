import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiOutlineArrowDown } from "react-icons/ai";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Errors, Success } from "../../components/antdToast";
import ButtonComponent from "../../components/ButtonComponent";
import { Remove_Data_Form_Reducers } from "../../components/dataRemoveOnLogout";
import ModalComponent from "../../components/Modal";
import TableComponent from "../../components/table";
import {
  GetAllOrdersMethod,
  OrderReassignToProviderMethod,
} from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { europeanCurrencyFormatter } from "../../config/utils";
import { Images } from "../../constant/Images";
import { DeleteBeneficiariesAtLogoutTime } from "../../Redux/actions/fetchBeneficiariesAction";
import { DeleteBranchesAtLogoutTime } from "../../Redux/actions/fetchBranchesAction";
import {
  DeleteProvidersAtLogoutTime,
  FetchProvidersAction,
} from "../../Redux/actions/fetchProvidersAction";
import { DeleteServiceAreasAtLogoutTime } from "../../Redux/actions/fetchServiceAreaAction";
import { DeleteServicesAtLogoutTime } from "../../Redux/actions/fetchServicesAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";

const { checkbox, checkboxActive } = Images;
const currentSelectedLangue = localStorage.getItem("primaryLanguage");
const BookingsManagement = (props) => {
  const { goBack, push } = useHistory();
  const [allOrders, setAllOrders] = useState([]);
  const [index, setIndex] = useState(0);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [cancelledOrders, setCancelledOrders] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [providers, setProviders] = useState([]);
  const [providerId, setProviderId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    allOrdersOfProviders();
    setProviders(props?.showProviders);
    document.body.style.overflow = "auto";
    return function cleanup() {
      document.body.style.overflow = "auto";
    };
  }, []);
  const allOrdersOfProviders = () => {
    GetAllOrdersMethod(
      (success) => {
        if (success?.status === 200) {
          props.setLoaderState(false);
          let mappedOut = success?.data?.response?.detail?.map(
            (item, index) => {
              let dates = moment(item?.created_at).format("DD-MMM-YYYY");
              const servicePackType = item?.service?.service_pack_type;

              return {
                no: index + 1,
                product: {
                  ...item?.service,
                  name: item?.service?.name,
                  avatar: item?.service?.avatar,
                  info: item?.service?.info,
                },
                orderId: item?.order_id,
                date: moment(item.created_at).format("DD-MMM-YYYY HH:mm"),
                deliveryDate:
                  servicePackType === "monthly"
                    ? adminLabels[servicePackType]
                    : moment(dates)
                        .add(item.service?.time, item?.service?.time_type)
                        .local()
                        .format("DD-MMM-YYYY HH:mm"),
                price: item?.amount,
                estimatedTime:
                  servicePackType === "monthly"
                    ? adminLabels[servicePackType]
                    : item.service.time === null &&
                      item.service.time_type === null
                    ? "0"
                    : `${item.service.time}  ${
                        Number(item.service.time) > 1
                          ? adminLabels[
                              item.service.time_type === "Hours"
                                ? item.service.time_type
                                : item.service.time_type?.toLowerCase() + "s"
                            ]
                          : adminLabels[
                              item.service.time_type === "Hours"
                                ? "hour"
                                : item.service.time_type?.toLowerCase()
                            ]
                      }`,
                is_reassign: item?.is_reassign,
                status: item?.status,
                service_provider_id: item?.service_provider_id,
              };
            }
          );
          let filterdPending = mappedOut?.filter(
            (item) => item.status === "pending"
          );
          let filterdCompleted = mappedOut?.filter(
            (item) => item.status === "completed"
          );
          let filterdCancelled = mappedOut?.filter(
            (item) => item.status === "cancelled"
          );

          setPendingOrders(filterdPending);
          setCompletedOrders(filterdCompleted);
          setCancelledOrders(filterdCancelled);
          setAllOrders(mappedOut);
          setLoading(false);
        }
      },
      (fail) => {
        if (fail !== null) {
          if (fail?.response?.status === 409) {
            Errors(fail?.response?.data?.error?.message);
            props.setLoaderState(false);
          } else if (fail?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(fail?.response?.data?.error?.message);
            Remove_Data_Form_Reducers(props);
            push("/admin");
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
          }
        }
      }
    );
  };

  const columns = [
    {
      align: "center",
      title: adminLabels.no,
      dataIndex: "no",
      key: "no",
      width: 60,
      render: (text) => <a>{text}</a>,
    },
    {
      title: adminLabels.product,
      dataIndex: "product",
      key: "product",
      width: 180,

      render: (text, index) => (
        <div className={"flex"}>
          <img
            src={text?.avatar}
            style={{ width: 50, height: 45 }}
            alt="detail image"
          />
          <div className="pl-2 text-left">
            <label>
              <b>
                {
                  text?.[
                    currentSelectedLangue === "english" ? "name" : "name_sp"
                  ]
                }
              </b>
            </label>{" "}
          </div>
        </div>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) =>
        a?.product?.[
          currentSelectedLangue === "english" ? "name" : "name_sp"
        ]?.localeCompare(
          b?.product?.[currentSelectedLangue === "english" ? "name" : "name_sp"]
        ),
    },
    {
      align: "center",
      title: adminLabels.orderId,
      key: "orderId",
      dataIndex: "orderId",
      width: 100,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
            {/* <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
            >
              Search
            </Button> */}
            {/* <Button
              onClick={() => {
                clearFilters();
              }}
              type="danger"
              size="small"
            >
              Reset
            </Button> */}
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.orderId.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.orderId?.localeCompare(b?.orderId),
    },
    {
      align: "center",
      title: adminLabels.date,
      key: "date",
      dataIndex: "date",
      width: 100,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: adminLabels.price,
      key: "price",
      dataIndex: "price",
      width: 100,
      render: (text, index) => (
        <div className="text-center flex justify-center">
          {text ? "€ " + europeanCurrencyFormatter(text) : ""}
        </div>
      ),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a?.price?.localeCompare(b?.price),
      // render: (text, index) => <div className="text-center	">{text}</div>,
    },
    {
      align: "center",
      title: adminLabels.timeOfDelivery,
      key: "estimatedTime",
      dataIndex: "estimatedTime",
      width: 120,
    },
    {
      align: "center",
      title: adminLabels.deliveryDate,
      key: "deliveryDate",
      dataIndex: "deliveryDate",
      width: 100,
      sortDirections: ["descend", "ascend"],
      sorter: (aa, bb) =>
        moment(aa.deliveryDate).unix() - moment(bb.deliveryDate).unix(),
      showSorterTooltip: false,
    },
  ];

  const fetchProviders = (detail) => {
    setProviders((prevState) => {
      let filterdProviders = prevState.filter(
        (item) => item.service_provider_id !== detail.service_provider_id
      );

      return [...filterdProviders];
    });
    setOpenModal(true);
    setOrderId(detail.orderId);
  };

  const orderReassignToProvider = () => {
    if (providerId !== "") {
      let obj = {
        order_id: orderId,
        service_provider_id: providerId,
      };
      props.setLoaderState(true);
      OrderReassignToProviderMethod(
        obj,
        (success) => {
          if (success?.status === 200) {
            props.setLoaderState(false);
            Success(success?.data?.response?.message);
            allOrdersOfProviders();
            setTimeout(() => {
              setOpenModal(false);
            }, 1000);
          }
        },
        (fail) => {
          if (fail !== null) {
            if (fail?.response?.status === 409) {
              Errors(fail?.response?.data?.error?.message);
              props.setLoaderState(false);
            } else if (fail?.response?.status === 401) {
              props.setLoaderState(false);
              Errors(fail?.response?.data?.error?.message);
              Remove_Data_Form_Reducers(props);
              push("/admin");
            } else {
              props.setLoaderState(false);
              Errors("Network Error");
            }
          }
        }
      );
    } else {
      alert("Please select provider");
    }
  };

  const onCheckedProvider = (e, i) => {
    const array = providers?.map((a, b) => {
      if (b === i) {
        setProviderId(a.service_provider_id);
        return {
          ...a,
          checked: !a.checked,
        };
      } else {
        return {
          ...a,
          checked: false,
        };
      }
    });
    setProviders(array);
  };

  const changeIndex = (i) => {
    setIndex(i);
  };

  return (
    <section className="">
      <Spin tip={adminLabels.loading} spinning={loading}>
        <div className="flex justify-between flex-wrap sm:flex-wrap lg:flex-nowrap mt-5 ">
          <div className="pl-5 flex self-center justify-center items-center sub-h3">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{adminLabels.back}</span>
            </div>
            <h3 className="ml-10	">{adminLabels.myLogs}</h3>
          </div>
          <div className=" mr-1 sm:mr-1 justify-center sm:justify-center lg:justify-center lg:mr-28 flex  flex-wrap  sm:flex-wrap lg:flex-nowrap">
            <Button
              shape={"round"}
              onClick={() => changeIndex(0)}
              className={
                index == 0
                  ? `icon-button mx-1 bg-primary text-white`
                  : `icon-button mx-1`
              }
            >
              {adminLabels.all}
            </Button>

            <Button
              shape={"round"}
              onClick={() => changeIndex(1)}
              className={
                index == 1
                  ? `icon-button mx-1 bg-primary text-white`
                  : `icon-button mx-1`
              }
            >
              {adminLabels.InProgress}
            </Button>

            <Button
              shape={"round"}
              onClick={() => changeIndex(2)}
              className={
                index == 2
                  ? `icon-button mx-1 bg-primary text-white`
                  : `icon-button mx-1`
              }
            >
              {adminLabels.completedOrders}
            </Button>

            <Button
              shape={"round"}
              onClick={() => changeIndex(3)}
              className={
                index == 3
                  ? `icon-button mx-1 bg-primary text-white`
                  : `icon-button mx-1`
              }
            >
              {adminLabels.cancelledOrders}
            </Button>
            <CSVLink
              data={
                (index === 0 &&
                  allOrders?.map((item, index) => {
                    return {
                      ...item,
                      no: index + 1,
                      nameOfService:
                        item?.product?.[
                          currentSelectedLangue === "english"
                            ? "name"
                            : "name_sp"
                        ],
                    };
                  })) ||
                (index === 1 &&
                  pendingOrders?.map((item, index) => {
                    return {
                      ...item,
                      no: index + 1,
                      nameOfService:
                        item?.product?.[
                          currentSelectedLangue === "english"
                            ? "name"
                            : "name_sp"
                        ],
                    };
                  })) ||
                (index === 2 &&
                  completedOrders?.map((item, index) => {
                    return {
                      ...item,
                      no: index + 1,
                      nameOfService:
                        item?.product?.[
                          currentSelectedLangue === "english"
                            ? "name"
                            : "name_sp"
                        ],
                    };
                  })) ||
                (index === 3 &&
                  cancelledOrders?.map((item, index) => {
                    return {
                      ...item,
                      no: index + 1,
                      nameOfService:
                        item?.product?.[
                          currentSelectedLangue === "english"
                            ? "name"
                            : "name_sp"
                        ],
                    };
                  }))
              }
              filename={
                (index === 0 &&
                  `${adminLabels.orders} ${adminLabels.myLogs}.csv`) ||
                (index === 1 &&
                  `${adminLabels.InProgress} ${adminLabels.myLogs}.csv`) ||
                (index === 2 &&
                  `${adminLabels.completedOrders} ${adminLabels.myLogs}.csv`) ||
                (index === 3 &&
                  `${adminLabels.cancelledOrders} ${adminLabels.myLogs}.csv`)
              }
              headers={[
                { label: adminLabels.no, key: "no" },
                { label: adminLabels.product, key: "nameOfService" },
                { label: adminLabels.orderId, key: "orderId" },
                { label: adminLabels.date, key: "date" },
                { label: adminLabels.price, key: "price" },
                { label: adminLabels.timeOfDelivery, key: "estimatedTime" },
                { label: adminLabels.deliveryDate, key: "deliveryDate" },
              ]}
              target="_blank"
            >
              <Button
                shape={"round"}
                className="icon-button "
                icon={<AiOutlineArrowDown color="#5ab3f0" />}
              >
                {adminLabels.exportCSV}
              </Button>
            </CSVLink>
          </div>
        </div>
        <div className="container mx-auto lg:px-20 mt-5">
          <TableComponent
            columns={columns}
            // pagination={{ defaultPageSize: 5 }}
            data={
              (index === 0 && allOrders) ||
              (index === 1 && pendingOrders) ||
              (index === 2 && completedOrders) ||
              (index === 3 && cancelledOrders)
            }
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
            scrolly="500px"
          />
          <ModalComponent
            modalTitle="Select Providers For Reassign The Service"
            filterModal={openModal}
            closeModal={() => setOpenModal(false)}
          >
            {providers?.map((t, i) => {
              return (
                <>
                  <div className="flex justify-between  text-left py-5 ">
                    <div className="flex items-center justify-center">
                      <img width={"30px"} src={t.avatar} alt="logo alt" />
                      <span className="pl-4">{t.name}</span>
                    </div>
                    <div className="flex justify-between items-center">
                      {t.checked ? (
                        <img
                          style={{ width: "100%" }}
                          src={checkboxActive.default}
                          onClick={() => onCheckedProvider(t, i)}
                        />
                      ) : (
                        <img
                          style={{ width: "100%" }}
                          src={checkbox.default}
                          onClick={() => onCheckedProvider(t, i)}
                        />
                      )}
                    </div>
                  </div>
                </>
              );
            })}
            <ButtonComponent
              className=" bg-primary"
              title={"Reassign"}
              onClick={orderReassignToProvider}
              disabled={props.loader === true ? true : false}
              loader={props.loader === true ? true : false}
            />
          </ModalComponent>
        </div>
      </Spin>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.loaderToggle.loaderState,
    showProviders: state.providerList.providers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLogoutUser: (data) => {
      dispatch(LogoutUserAction(data));
    },
    getProvidersList: (props) => {
      dispatch(FetchProvidersAction(props));
    },
    removeBeneficiaries: () => {
      dispatch(DeleteBeneficiariesAtLogoutTime());
    },
    removeProviders: () => {
      dispatch(DeleteProvidersAtLogoutTime());
    },
    removeServiceAreas: () => {
      dispatch(DeleteServiceAreasAtLogoutTime());
    },
    removeBranches: () => {
      dispatch(DeleteBranchesAtLogoutTime());
    },
    removeServices: () => {
      dispatch(DeleteServicesAtLogoutTime());
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingsManagement);
