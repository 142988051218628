import { Switch } from "antd";
import moment from "moment";
import React from "react";
import { AiFillStar } from "react-icons/ai";
import { Images } from "../constant/Images";
import { adminLabels } from "../config/label/adminLabels";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const { userProfilePlaceholder } = Images;
const RateAndReviews = ({
  review,
  reviewIdx,
  updateServiceProviderOrderRateAndReviewStatus,
}) => {
  return (
    <div key={review.id} className="flex space-x-4 text-sm text-gray-500">
      <div className="flex-none py-10">
        <img
          src={userProfilePlaceholder.default}
          alt=""
          className="h-10 w-10 rounded-full bg-gray-100"
        />
      </div>
      <div
        className={classNames(
          reviewIdx === 0 ? "" : "border-t border-gray-200",
          "flex-1 py-10"
        )}
      >
        <h4 className="font-medium text-gray-900">{`${review?.user?.first_name} ${review?.user?.last_name}`}</h4>

        <p className="mb-1">
          <small>
            <time
              className="text-sm"
              dateTime={moment(review.created_at).fromNow()}
            >
              {moment(review.created_at).fromNow()}
            </time>
          </small>
        </p>

        <div className="flex items-center space-x-2">
          <div className="flex items-center">
            {[0, 1, 2, 3, 4].map((rating) => (
              <AiFillStar
                className={classNames(
                  review.rate > rating ? "text-yellow-400" : "text-gray-300",
                  "h-4 w-4 flex-shrink-0"
                )}
                aria-hidden="true"
              />
            ))}
          </div>
          <div>
            <Switch
              onChange={() =>
                updateServiceProviderOrderRateAndReviewStatus(review?.order_id)
              }
              checked={review?.status === 1 ? true : false}
              size="small"
            />
          </div>
        </div>
        <p className="sr-only">{review.rating} out of 5 stars</p>
        <div className="mt-4 ">
          <div>
            <span className="text-lg text-black font-bold capitalize">{adminLabels.review}</span>
          </div>
          <blockquote>
            <div
              className="prose prose-sm text-sm italic max-w-none text-gray-500 "
              dangerouslySetInnerHTML={{ __html: `"${review.review || '-'}"` }}
            />
          </blockquote>
        </div>
        <div className="mt-4 ">
          <div>
            <span className="text-lg text-black font-bold">{adminLabels.whatCanBeImproved}</span>
          </div>
          <blockquote>
            <div
              className="prose prose-sm text-sm italic max-w-none text-gray-500"
              dangerouslySetInnerHTML={{ __html: `"${review.improved || '-'}"` }}
            />
          </blockquote>
        </div>
        {/* <div className="mt-4 ">
          <div>
            <span>{adminLabels.whatCanBeImproved}</span>
          </div>
          <blockquote>

          <div
            className="prose prose-sm max-w-none text-gray-500"
            dangerouslySetInnerHTML={{ __html: `"${review.improved}"` }}
          />
          </blockquote>
        </div> */}
      </div>
    </div>
  );
};

export default RateAndReviews;
