import { Button, List, Switch } from "antd";
import moment from "moment";
import { CSVLink } from "react-csv";
import { AiOutlineArrowDown } from "react-icons/ai";
import { Errors, Success } from "../../components/antdToast";
import { UpdateUserStatusMethod } from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { Images } from "../../constant/Images";
import "./UserDetailContainerCard.css";
const UserDetailContainerCard = ({
  dividerShow = false,
  userDetails,
  services,
  updateUserData,
}) => {
  const setUserActive = (id) => {
    let userId = new FormData();
    userId.append("user_id", id);
    UpdateUserStatusMethod(
      userId,
      (success) => {
        if (success.status === 200) {
          updateUserData();
          Success(success?.data?.response?.message);
        }
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  const { userProfilePlaceholder } = Images;
  return (
    <div className="bg-white rounded-lg">
      <div className=" p-6  pl-3 pt-0 pb-3 flex w-full justify-between items-center">
        <div style={{ display: "flex" }} className="p-5 pb-0">
          <img
            className="rounded-full"
            src={userDetails?.avatar ? userDetails?.avatar : userProfilePlaceholder.default}
            style={{ height: 48, width: 48 }}
          />
          <div className="pl-2">
            <span className="flex space-x-2 capitalize">
              <strong className=" ">{userDetails?.first_name}</strong>
              <strong className=" ">{userDetails?.last_name}</strong>
            </span>

            <p>{userDetails?.email}</p>
          </div>
        </div>
        <div>
          <div className="flex flex-col">
            <Switch
              size="small"
              checked={userDetails?.status === 1 ? true : false}
              onChange={() => setUserActive(userDetails?.user_id)}
            />
            <span>{adminLabels?.status}</span>
          </div>
        </div>
      </div>
      <div className="bg-white container rounded-lg ">
        {/* <div className="grid grid-cols-2 p-6 pl-3 pt-0 pb-3"> */}

        <div className="" style={{ position: "relative" }}>
          {dividerShow && (
            <div
              style={{
                border: "1px solid gray",
                opacity: 0.2,
                marginBottom: "15px",
              }}
            />
          )}
          <div style={{ paddingLeft: 20 }}>
            <b>{adminLabels.phone}</b>
            <p>{userDetails?.phone_number ? userDetails?.phone_number : "N/A"}</p>
          </div>
        </div>
        <div className="" style={{ position: "relative" }}>
          {dividerShow && (
            <div
              style={{
                border: "1px solid gray",
                opacity: 0.2,
                marginBottom: "15px",
              }}
            />
          )}
          <div style={{ paddingLeft: 20 }}>
            <b>{adminLabels.registeredDate}</b>
            <p>{moment(userDetails?.created_at).format("DD-MMM-YYYY")}</p>
          </div>
        </div>
        {/* </div> */}
      </div>
      {/* <div className="bg-white container rounded-lg "> */}
      {/* <div className="grid grid-cols-2 p-6 pl-3 pt-0 pb-3"> */}
      <div className="" style={{ position: "relative" }}>
        {dividerShow && (
          <div
            style={{
              border: "1px solid gray",
              opacity: 0.2,
              marginBottom: "15px",
            }}
          />
        )}
        <div style={{ paddingLeft: 20 }}>
          <div className="flex justify-between">
            <div>
              <b>{adminLabels.profiles}</b>
            </div>
            <div>
              <CSVLink
                data={
                  userDetails?.profiles?.length
                    ? userDetails?.profiles?.map((profile) => {
                        return {
                          ...profile,
                          firstName: profile?.data[0]?.firstName,
                          lastName: profile?.data[0]?.lastName,
                        };
                      })
                    : []
                }
                filename={"user profiles.csv"}
                target="_blank"
              >
                <Button
                  shape={"round"}
                  className="icon-button py-0 mr-5"
                  icon={<AiOutlineArrowDown color="#5ab3f0" />}
                >
                  {adminLabels.exportCSV}
                </Button>
              </CSVLink>
            </div>
          </div>
          {userDetails?.profiles?.length === 0 ? (
            <p>N/A</p>
          ) : (
            <div>
              <div id="scrollableDiv" className="userDetailCardServices">
                <List
                  dataSource={userDetails?.profiles}
                  renderItem={(item, index) => (
                    <List.Item key={index}>
                      <div className="capitalize">
                        {item.data[0].firstName} {item.data[0].lastName}
                      </div>
                    </List.Item>
                  )}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};
export default UserDetailContainerCard;
