// Admin Panel Labels
let englishLabels = {
  logIn: "LogIn",
  welcome: "Welcome",
  emailAddress: "Email Address",
  password: "Password",
  helloAdmin: "Hello Admin",
  forgotPassword: "Forgot Password?",
  welcomeToDashboard: "Welcome To Dashboard",
  total: "Total",
  budgetResponse: "Budget Response",
  usersRegistered: "Users Registered",
  adminOf: "Admin of",
  serviceProvider: "Service Provider",
  workerOf: "Worker Of",
  bookRequests: "Book Requests",
  completedBookings: "Completed Bookings",
  openBookings: "Open Bookings",
  reassignedOrders: "Reassigned Orders",
  disputeOrders: "Dispute Orders",
  revenue: "Revenue",
  filter: "Filter",
  dashboard: "Dashboard",
  subAdminAccountManagement: "Sub-admin Account Management",
  subAdminsActivityLogs: "Sub-admin Activity Logs",
  userAccountManagement: "User Account Management",
  adminOfserviceProviderAccountManagement:
    "Admin of Service Provider Account Management",
  adminOfServiceProviderAccountManagement:
    "Admin of Service Provider Account Management",
  workerOfServiceProvidersAccountManagement:
    "Worker of Service Provider Account Management",
  categoriesManagement: "Categories Management",
  branches: "Branches",
  service: "Service",
  services: "Services",
  disabled: "disabled",
  bookingsManagement: "Bookings Management",
  workWithUsRequestsManagement: "Work With Us Requests Management",
  promoCodes: "Promo Codes",
  promoCode: "Promo Code",
  myLogs: "My Logs",
  help: "Help",
  payouts: "Payouts",
  myEarnings: "My Earnings",
  dynamicForms: "Dynamic Forms",
  reportAndAnaytics: "Report and Analytics",
  faqs: "FAQ's",
  no: "No",
  fullName: "Full Name",
  email: "Email",
  phone: "Phone",
  address: "Address",
  action: "Action",
  view: "View",
  subAdminDetails: "Sub Admin Details",
  edit: "Edit",
  name: "Name",
  roles: "Roles",
  addNew: "Add New",
  exportCSV: "Export CSV",
  subAdmin: "Sub Admin",
  new: "new",
  apply: "Apply",
  functionalityAndFeatures: "Functionality And Features",
  phoneNo: "Phone No",
  save: "save",
  personalDetails: "Personal Details",
  back: "Back",
  address: "Address",
  pleaseFillTheField: "Please fill the field!",
  registeredDate: "Registered Date",
  userAccountDetails: "User Account Details",
  userOrder: "User Order",
  product: "Product",
  price: "Price",
  profiles: "Profiles",
  status: "Status",
  time: "Time",
  userStatus: "User Status",
  userDetails: "User Details",
  city: "City",
  region: "Region",
  workingHours: "Working Hours	",
  information: "Information",
  informationEng: "Information (Eng)",
  informationEsp: "Information (Esp)",
  viewDetails: "View Details",
  providerDetails: "Provider Details",
  providerStatus: "Provider Status",
  adminOfServiceProvider: "Admin Of Service Provider",
  providerInfo: "Provider Info",
  uploadImage: "Upload Image",
  nameOfServiceProvider: "Name Of Service Provider",
  yearsOfExperience: "Years of Experience",
  positionDesignation: "Position/Designation",
  hideRating: "Hide Fake Rating",
  contactInfo: "Contact Info",
  personalInfo: "Personal Info",
  state: "State",
  setWorkingHours: "Set Working Hours",
  fromMondayToThursday: "From Monday To Thursday",
  addLunchHours: "Add Lunch Hours",
  friday: "Friday",
  serviceName: "Service Name",
  adminOfServiceProviderDetails: "Admin Of Service Provider Details",
  providerName: "Provider Name",
  workerDetails: "Worker Details",
  workerStatus: "Worker Status",
  orders: "Orders",
  branch: "Branch",
  serviceArea: "Service Area",
  beneficiaries: "Beneficiaries",
  branchLevel: "Branch Level",
  enabled: "Enabled",
  addNewBranch: "Add New Branch",
  editBranch: "Edit Branch",
  selectServiceArea: "Select Service Area",
  selectBranchLevel: "Select Branch Level",
  parentBranch: "Parent Branch",
  addBranchName: "Add Branch Name",
  addBranchNameEng: "Add Branch Name (Eng)",
  addBranchNameEsp: "Add Branch Name (Esp)",
  assignOrder: "Assign Order",
  timeOfDelivery: "Time of Delivery",
  assigned: "Assigned",
  InProgress: "In Progress",
  completedOrders: "Completed Orders",
  cancelledOrders: "Cancelled Orders",
  completeInfoAndDocsReceived: "Complete Info And Docs Received",
  orderId: "Order Id",
  currentStep: "Current Step",
  orderInfoAndDocument: "Order Info And Document",
  countDown: "CountDown",
  theStepsToFlowUntilDelivery: "The Steps To Flow Until Delivery",
  dueDateOfService: "Due date of service",
  requestDetail: "Request Detail",
  estimatedTime: "Estimated Time",
  serviceFees: "Service Fees",
  docuDocFees: "DocuDoc Fees",
  serviceDetailedDescription: "Service Detailed Description",
  description: "Description",
  duration: "Duration",
  userLimit: "User Limit",
  deletePromocode: "Delete Promocode",
  discount: "Discount",
  addNewPromo: "Add New Promo",
  title: "Title",
  message: "Message",
  response: "Response",
  orderDate: "Order Date",
  totalAmount: "Total Amount",
  totalAmountPaid: "Total Amount Paid",
  providerAmount: "Provider Amount",
  chargeId: "Charge Id",
  orderStatus: "Order Status",
  serviceProvider: "Service Provider",
  release: "Release",
  released: "Released",
  releaseAmount: "Release Amount",
  supplementaryCharges: "Supplementary Charges",
  paymentRecieved: "Payment Received",
  paymentRelease: "Payment Release",
  payment: "Payment",
  payments: "Payments",
  supplementaryAmount: "Supplementary Amount",
  serviceProviderAmount: "Service Provider Amount",
  providerBudgetAmount: "Provider Budget Amount",
  providerOrderAmount: "Provider Order Amount",
  totalBudgetAmount: "Total Budget Amount",
  totalOrderAmount: "Total Order Amount",
  forms: "Forms",
  steps: "Steps",
  cloneServiceForm: "Clone Service Form",
  formTitle: "Form title",
  clone: "Clone",
  skip: "Skip",
  done: "Done",
  punctualForm: "Punctual Form",
  formNotAvaliable: "Form Not Available !",
  shortAnswers: "Short Answers",
  longAnswers: "Long Answers",
  dropdown: "Dropdown",
  attachFile: "Attach File",
  radioButton: "Radio Button",
  checkBox: "Check Box",
  calender: "Calender",
  adminNote: "Admin Note",
  addYourSectionTitle: "Add Your Section Title",
  sectionTitle: "Section Title",
  editForm: "Edit Form",
  deleteForm: "Delete Form",
  editPunctualForm: "Edit Punctual Form",
  registrationDateAndTime: "Registration Date and time",
  personalData: "Personal Data",
  orderNumber: "Order Number",
  oderDueDateAndTime: "Oder Due Date and Time",
  paymentDetails: "Payment Details",
  dateAndTimeOfCreation: "Date and time of creation",
  servicesCovered: "Services Covered",
  clientUserReports: "Client User Reports",
  bookingReport: "Booking Report",
  bookingReports: "Booking Reports",
  serviceProviderReport: "Service Provider Report",
  serviceProviderReports: "Service Provider Reports",
  frequentlyAskedQuestion: "Frequently Asked Question",
  addQuestionAndAnswer: "Add Question And Answer",
  question: "Question",
  addInputFeild: "Add Input field",
  addFeildLabel: "Add field Label",
  inputFeildTitleNameEmailAndAddress:
    "Input Field Title (name, email and address) (Eng)",
  inputFeildTitleNameEmailAndAddressEsp:
    "Input Field Title (name, email and address) (Esp)",
  fieldDescription: "Field Description",
  addTextBlock: "Add Text Block",
  dropDownTitle: "DropDown Title",
  descriptionOrInformation: "Description or Information (Eng)",
  descriptionOrInformationEsp: "Description or Information (Esp)",
  addFileUploader: "Add File Uploader",
  uploadTitle: "Upload Title (Eng)",
  uploadTitleEsp: "Upload Title (Esp)",
  yourFileTitleHere: "Your File Title here",
  uploadFromComputer: "Upload from computer",
  addTitleForAdminNote: "Add Title for Admin Note (Eng)",
  addTitleForAdminNoteEsp: "Add Title for Admin Note (Esp)",
  checkBoxTitle: "Check Box Title",
  addTitleForCalender: "Add Title for Calender (Eng)",
  addTitleForCalenderEsp: "Add Title for Calender (Esp)",

  noteHere: "Note Here",
  enterEmail: "Enter email",
  weWillSendYouACodeToChangePassword:
    "we will send you a code to change password",
  all: "All",
  date: "Date",
  deliveryDate: "Delivery Date",
  provider: "Provider",
  additionalBudget: "Additional Budget",
  logOut: "Logout",
  budgetSupplementary: "Budget Supplementary",
  orderSupplementary: "Order Supplementary",
  additionalAmount: "Additional Amount",
  serviceAmount: "Service Amount",
  received: "Received",
  confirmation: "Confirmation",
  yes: "Yes",
  areYouSureWantDeleteThisFaq: "Are You Sure Want To Delete This FAQ?",
  cancel: "Cancel",
  ok: "Ok",
  states: "States",
  registrationDetails: "Registration Details",
  registration: "Registration",
  add: "Add",
  search: "search",
  userOrders: "User Orders",
  details: "Details",
  viewInformation: "view Information",
  to: "To",
  level: "Level",
  selectParentBranch: "Select Parent Branch",
  addNewService: "Add New Service",
  editService: "Edit Service",
  selectBranch: "Select Branch",
  selectServiceProvider: "Select Service Provider",
  isServiceIsRegionRelated: "Service is Region Related?",
  addServiceName: "Add Service Name",
  addServiceNameEng: "Add Service Name (Eng)",
  addServiceNameEsp: "Add Service Name (Esp)",
  serviceForm: "Service Form",
  selectServiceForm: "Select Service Form",
  isItServicePack: "Is it Service Pack?",
  serviceForDynamicForm: "Service For Dynamic Form",
  uploadFiles: "Upload Files",
  addFileTitleHere: "Add File Title Here (Eng)",
  addFileTitleHereEsp: "Add File Title Here (Esp)",
  radioButtonTitle: "Radio Button Title",
  addTitle: "Add Title",
  doYouReallyWantToDeleteThisService:
    "Do You Really Want To Delete This Service ?",
  supplementaryFees: "Supplementary Fees",
  letsAssign: "Let's Assign",
  selectProvidersForReassignTheService:
    "Select Providers For Reassign The Service",
  reassign: "Reassign",
  user: "Client",
  messages: "Messages",
  documents: "Documents",
  endDispute: "End Dispute",
  ifYouwantToContinueClickOnContinueOrderOrIfYouWantToCancelClickOnCancelOrder:
    "if you want to continue click on continue order or if you want to cancel click on cancel order",
  ifYouwantToContinueClickOnContinueOrderOrIfYouWantToCancelClickOnCancelAdditionalBudget:
    "if you want to continue click on continue Additional Budget or if you want to cancel click on cancel Additional Budget",
  cancelOrder: "Dispute",
  continueOrder: "Continue Order",
  continue: "Continue",
  continueOrderAdditionalBudget: "Continue Additional Budget",
  confirm: "Confirm",
  doYouLikeToGoForCompleteOrPartial:
    "Do you like to go for Complete or Partial ?",
  complete: "Complete",
  toWhomeYouLikeToMakeACompleteRefund:
    "To whome you like to make a complete refund ?",
  orderAmount: "Order Amount",
  supplementaryAmount: "Supplementary Amount",
  docuDocDeduction: "DocuDoc Deduction",
  partial: "Partial",
  enterPercentYouwantToRefundUser: "Enter Percent You want To Refund User",
  enterPercentOfSupplementaryYouWantToRefundUser:
    "Enter Supplementary Percent You want To Refund User",
  remaining: "Remaining",
  partialAmountOf: "Partial Amount Of",
  partialAmount: "Partial Amount",
  ofPartialAmount: "of partial amount",
  loading: "Loading...",
  send: "Send",
  sendMessage: "send message ...",
  forServiceProvider: "for Service Provider",
  attachedDocuments: "Attached Documents",
  myDocuments: "My Documents",
  noChatAvailable: "No Chat Available",
  filterByDate: "Filter By Date",
  amount: "amount",
  supplementary: "Supplementary",
  personal: "Personal",
  surname: "surname",
  cif: "CIF",
  worker: "Worker",
  infoBox: "Info Box",
  monToThu: "Mon - Thu",
  fri: "Fri",
  lunchTime: "Lunch Time",
  lunch: "Lunch",
  orderStatusProgress: "Order Status Progress",
  finishThisStep: "Finish this step",
  finishThisOrder: "Finish this Order",
  customer: "Customer",
  dueDate: "Due Date",
  addBill: "Add Bill",
  upload: "Upload",
  fileUploaded: "file Uploaded",
  sendToUser: "Send To User",
  endDateCouldntBeLessThanStartDate:
    "End-date couldn't be less than start-date",
  areThereAnySupplementaryCharges: "Are There Any Supplementary Charges?",
  monthly: "Monthly",
  fixedPrice: "Fixed Price",
  estimatedTimeOfDelivery: "Estimated time of delivery",
  specialPrice: "Special Price",
  origionalPrice: "Original Price",
  // specialPrice: "Special Prices",
  discountPrice: "Discount Price",
  addStatus: "Add Status",
  timeType: "Time Type",
  editQuestionAndAnswer: "Edit Question And Answer",
  disputedOrders: "Disputed Orders",
  createAndManageSubAdmins: "Create and manage sub admins",
  workerOfServiceProvidersAccountManagement:
    "Worker of Service Providers Account Management",
  releaseDisputedAmount: "Release  Disputed Amount",
  downloadAll: "Download All",
  download: "Download",
  attachedDocumentCopies: "Attached Document Copies",
  disputeDetails: "dispute details",
  userDocuments: "User Documents",
  orderChats: "Order Chats",
  helpMessage: "Help Message",
  order: "Order",
  changeRequested: "change requested",
  paymentAccepted: "payment accepted",
  NoResponse: " No response ",
  contactClient: "Contact Client",
  unselectAll: "unselect all",
  selectAll: "select all",
  attachments: "Attachments",
  notAvailable: "N/A",
  paymentType: "Payment Type",
  serviceInformation: "Service Information",
  additionalBudgetNumber: "Additional Budget Number",
  typeOfMovement: "Type of Movement",
  dateOfOrder: "Date of Order",
  nameAndSurnameOfClient: "Name and Surname of Client",
  companyName: "Company Name",
  cifOrNif: "CIF/NIF",
  companyAddress: "Company Address",
  dispute: "Dispute",
  discount: "Discount",
  sumOfAllSupplements: "Sum of all Supplements",
  toBePayedServiceProvider: "To be Payed Service Provider",
  statusPenidngOrCompleted: "Status (Pending or Completed)",
  completionDate: "Completion Date",
  billNumber: "Bill Number",
  dateOfPayment: "Date of Payment",
  originalServicePriceEstablished:
    "Original Service (Price established / 1.21)",
  discountServiceVAT: "Discount € (Service+VAT)",
  serviceDiscount: "Service Discount",
  service_Discount: "Service-Discount",
  VATToBePayedOfServiceDiscount: "VAT to be payed (21% of Service-Discount)",
  totalPayedByClient: "Total Payed by Client",
  notifications: "Notifications",
  important: "Important",
  documentationWasMissingLabel: [
    "As Documentation was missing,",
    <br />,
    "The delivery time of the service",
    <br />,
    "is on hold until the full information",
    <br />,
    "is received",
  ],
  downloadAllUserFiles: "download all user files",
  downloadAllFiles: "download all  files",
  selectedUserType: "Selected User Type",
  serviceSelected: "Service Selected",
  business: "Business",
  company: "Company",
  cif: "CIF",
  sumOfAllSupplements: "Sum of all Supplements",
  service_Discount: "Service-Discount+VAT (Final Price of Service)",
  serviceDiscountVATFinalPriceOfService:
    "Service-Discount+VAT (Final Price of Service)",
  invalidEmail: "invalid Email",
  providerDocuments: "Provider Documents",
  amountForServiceProvider: "Amount for service provider",
  amountForUser: "Amount for user",
  disputeRefund: "Dispute Refund",
  minute: "minute",
  Minute: "minute",
  minutes: "minutes",
  Minutes: "minutes",
  Hours: "hours",
  Hour: "hour",
  Week: "week",
  Day: "day",
  day: "day",
  days: "days",
  hour: "hour",
  hours: "hours",
  week: "week",
  weeks: "weeks",
  completed: "completed",
  pending: "pending",
  disputed: "disputed",
  cancelled: "cancelled",
  block: "block",
  accepted: "accepted",
  enterTime: "enter time",

  receiveInitialInformation: "Receive initial information",
  receiveFullInformationAndDocumentation:
    "Receive full information and documentation",
  managementOfTheService: "Management of the service",
  finalizationOfManagementOfService: "Finalization of management of service",
  serviceDelivered: "service delivered",
  answerEng: "Answer (Eng)",
  answerEsp: "Answer (Esp)",
  questionEng: "Question (Eng)",
  questionEsp: "Question (Esp)",
  comment: "Comment",
  viewMessage: "View Messages",

  orderPlaced: "Order Placed",
  orderCanceled: "Order Canceled",
  orderCompleted: "Order Completed",
  requestForFurtherDocumentation: "Request for further documentation",
  additionalBudgetRequest: "Additional Budget Request",
  discountPercentage: "Discount Percentage",
  reviews: "Reviews",
  originalServiceVATPriceEstablishedInAdminPanel:
    "Original Service + VAT (Price Established in Admin Panel)",
  servicioDicount: "Service Discount",
  seviceDocuDoc30SeviceDiscount: "Sevice DocuDoc (30% Sevice -Discount)",
  seviceProvider70SeviceWithoutVAT: "Sevice Provider (70% Sevice without VAT)",
  IVAAPagar21: "VAT to be payed (21% of Service-Discount)",
  VATDocuDoc: "VAT DocuDoc",
  VATServiceProvider: "VAT Service Provider",
  whichStaysInDocuDoc: "Which stays in DocuDoc",
  supplementaryDetails: "Supplementary details",
  serviceVATSupplements: "Service + VAT + Supplements",
  vat: "VAT",
  managementCapacity: "No Capacity",
  result: "Result",
  userType: "User Type",
  serviceBillServiceBillOfServiceProviderAdditionalBudgetBill:
    "Service Bill of Service Provider/Additional Budget Bill",
  suplementsBillOfServiceProvider: "Suplements Bill of Service Provider",
  billDocuDocAutomaticGenerated: "Bill DocuDoc Automatic generated",
  fifteenPercentageOfServiceProviderServiceVAT:
    "15% of Service Provider Service - VAT",
  idOfPayment: "ID of Payment",
  totalPayedOnlyToDoubleCheck: "Total Payed (Only To Double Check)",
  fakeRating: "Fake Rating",
  download: "download",
  budgetCompleted: "Budget Completed",
  disputeAdditionalBudget: "Dispute Additional budget",
  none: "none",
  selfEmployed: "self employed",
  self: "Self employed",
  whatCanBeImproved: "What can be improved?",
  review: "review",
  finalizados: "Completed",
  cancelados: "Cancelled",
  reasignados: "Reassigned",
  porDisputar: "By Dispute",
  disputados: "Disputed",
  billNeeded: "bill needed?",
  releaseDate: "Release date",
  serviceProviderType: "Service Provider Type",
  selfEmployedServiceProvider: "Self employed",
  businessServiceProvider: "Business",
  serviceProviderSelfEmployedORBusiness:
    " Service Provider Self Employed / Business",
  baseImponibleWithoutVATDiscount: "Base imponible  (Without VAT)-Discount",
  reimbursement: "Reimbursement",
  serviceProviderServiceAmount: "Service Provider Service Amount",
  serviceProviderSupplementaryAmount: "Service Provider Supplementary Amount",
  docuDocAmount: "DocuDoc Amount",
};
let spanishLabels = {
  DoCuDocAdmin: "DocuDoc Administración",
  logIn: "Iniciar Sesión",
  welcome: "¡Admin, bienvenido!",
  emailAddress: "Correo electrónico",
  password: "Contraseña",
  helloAdmin: "Hola Administrador",
  welcomeAdmin: "bienvenido administrador",
  forgotPassword: "¿Has olvidado tu contraseña?",
  welcomeToDashboard: "Bienvenido al tablero",
  total: "Total",
  budgetResponse: "Presupuestos",
  usersRegistered: "Usuarios registrados",
  adminOf: "Admin de",
  serviceProvider: "Proveedor de servicio",
  workerOf: "Usuario de",
  bookRequests: "Pedidos",
  completedBookings: "Pedidos completados",
  openBookings: "Pedidos abiertos",
  reassignedOrders: "Pedidos reasignados",
  revenue: "Ingresos",
  filter: "Filtrar",
  dashboard: "Tablero",
  subAdminAccountManagement: "Sub-Admins",
  subAdminsActivityLogs: "Registros de actividad de Sub-Admin",
  userAccountManagement: "Usuarios",
  adminOfserviceProviderAccountManagement: "Admin de Partner",
  adminOfServiceProviderAccountManagement: "Admin de Partner",
  workerOfServiceProvidersAccountManagement: "Usuario de Partner",
  categoriesManagement: "Categorías: Ramas y Servicios",
  branches: "Ramas",
  services: "Servicios",
  service: "Servicio",
  disabled: "disabled",
  bookingsManagement: "Pedidos",
  workWithUsRequestsManagement: `Solicitudes de "Trabaja con nosotros"`,
  promoCodes: "Códigos promocionales",
  promoCode: "Código promocional",
  myLogs: "Mis registros",
  help: "Solicitudes de Ayuda",
  payouts: "Pagos",
  myEarnings: "Mis Ingresos",
  dynamicForms: "Formularios",
  reportAndAnaytics: "Informe y Analytics",
  faqs: "Preguntas frecuentes",
  no: "No",
  fullName: "Nombre completo",
  email: "Correo electrónico",
  phone: "Teléfono",
  address: "Dirección",
  action: "Acción",
  view: "Detalles",
  subAdminDetails: "Ver Detalles",
  edit: "Editar",
  name: "Nombre",
  roles: "Permisos",
  addNew: "Añadir nuevo",
  exportCSV: "Descargar CSV",
  subAdmin: "Sub-Admin",
  new: "Nuevo",
  apply: "Aplicar",
  functionalityAndFeatures: "Permisos",
  phoneNo: "Teléfono",
  save: "Guardar",
  personalDetails: "Detos personales",
  back: "Atrás",
  pleaseFillTheField: "¡Por favor rellene el campo!",
  registeredDate: "Fecha de registro",
  userAccountDetails: "Detalles de la cuenta de usuario",
  userOrder: "Pedidos del usuario",
  product: "Nombre del Servicio",
  price: "Precio",
  profiles: "Perfiles",
  status: "Estado",
  time: "Horario",
  userStatus: "Estado del usuario",
  userDetails: "Detalles del usuario",
  city: "Ciudad",
  region: "Comunidad Autónoma",
  workingHours: "Horario laboral  T",
  information: "Información",
  informationEng: "nformación (Eng)",
  informationEsp: "nformación (Esp)",
  viewDetails: "Ver detalles",
  providerDetails: "Ver detalles",
  providerStatus: "Estado",
  adminOfServiceProvider: "Admin de Partner",
  providerInfo: "Información",
  uploadImage: "Subir imágen",
  nameOfServiceProvider: "Nombre completo",
  yearsOfExperience: "Años de experiencia",
  positionDesignation: "Categoría",
  hideRating: "Ocultar calificación falsa",
  contactInfo: "Datos de contacto",
  personalInfo: "Localidad y horario",
  state: "Estado",
  setWorkingHours: "Establecer horario laboral",
  fromMondayToThursday: "De lunes a jueves",
  addLunchHours: "Pausa para almorzar",
  friday: "Viernes",
  serviceName: "Nombre del Servicio",
  adminOfServiceProviderDetails: "Detalles",
  providerName: "Nombre del Admin del Partner",
  workerDetails: "Trabajadora detalles",
  workerStatus: "Estado",
  orders: "Pedidos Asignados",
  branch: "Rama",
  serviceArea: "Área de servicios",
  beneficiaries: "Beneficiarios",
  branchLevel: "Nivel de rama",
  enabled: "Activado",
  addNewBranch: "Añadir nueva rama",
  editBranch: "Editar Rama",
  selectServiceArea: "Seleccionar Área de Servicio",
  selectBranchLevel: "Seleccione Nivel de Rama",
  parentBranch: "Rama anterior",
  addBranchName: "Nombre de Rama",
  addBranchNameEng: "Nombre de Rama (Eng)",
  addBranchNameEsp: "Nombre de Rama (Esp)",
  assignOrder: "Asignar Pedido",
  timeOfDelivery: "Tiempo de entrega",
  assigned: "Asignado",
  InProgress: "En curso",
  completedOrders: "Pedidos completados",
  cancelledOrders: "Pedidos cancelados",
  completeInfoAndDocsReceived: "Información y documentción recibida",
  orderId: "ID Pedido",
  currentStep: "Paso actual",
  orderInfoAndDocument: "Información y documentación del pedido",
  countDown: "Cuenta regresiva hasta entrega",
  orderStatus: "Estado de pedido",
  theStepsToFlowUntilDelivery: "Pasos a seguir hasta entrega",
  dueDateOfService: "Fecha final de entrega del servicio",
  requestDetail: "Solicitar Detalle",
  estimatedTime: "Entega estimada",
  serviceFees: "Tarifas de servicios",
  docuDocFees: "Parte DocuDoc",
  serviceDetailedDescription: "Descripción detallada del servicio",
  description: "Descripción",
  duration: "Duración",
  userLimit: "Límite por usuario",
  deletePromocode: "Eliminar promoción",
  discount: "Descuento",
  addNewPromo: "Añadir nueva promoción",
  title: "Nombre",
  message: "Mensaje",
  response: "Respuesta",
  orderDate: "Fecha del pedido",
  totalAmount: "Cantidad total",
  totalAmountPaid: "Cantidad total Pago",
  providerAmount: "Parte Partner",
  chargeId: "ID de Pago",

  release: "Liberar",
  released: "Liberado",
  releaseAmount: "Cantidad liberada",
  supplementaryCharges: "Cargos suplementarios",
  paymentRecieved: "Pago recibido",
  paymentRelease: "Liberación de pago",
  payment: "Pago",
  payments: "Todos los movimientos",
  supplementaryAmount: "Monto suplementario",
  serviceProviderAmount: "Monto del Partner",
  providerBudgetAmount: "Cantidad presupuestaria del proveedor",
  providerOrderAmount: "Cantidad de orden del proveedor",
  totalBudgetAmount: "Monto total del presupuesto",
  totalOrderAmount: "Monto total del pedido",
  forms: "Formularios",
  steps: "Pasos",
  cloneServiceForm: "Duplicar Formulario",
  formTitle: "Título de la Formulario",
  clone: "Duplicado",
  skip: "Omitir",
  done: "Completado",
  punctualForm: "Formulario puntual",
  formNotAvaliable: "¡Formulario no disponible!",
  shortAnswers: "Texto corto",
  longAnswers: "Texto largo",
  dropdown: "Dropdown",
  attachFile: "Adjuntar Archivo",
  radioButton: "Boón de Radio",
  checkBox: "Checkboxes",
  calender: "Calendario",
  adminNote: "Nota de admin",
  addYourSectionTitle: "Añadir título",
  sectionTitle: "Título",
  editForm: "Editar Formulario",
  deleteForm: "Eliminar Formulario",
  editPunctualForm: "Editar Formulario",
  registrationDateAndTime: "Registro",
  personalData: "Info requerida",
  orderNumber: "Número de Pedido",
  oderDueDateAndTime: "Fecha y hora de entrega",
  paymentDetails: "Detalles del pago",
  dateAndTimeOfCreation: "Fecha y hora de la creación",
  servicesCovered: "Servicios cubiertos",
  clientUserReports: "Datos de Usuarios",
  bookingReport: "Datos de Pedidos",
  bookingReports: "Datos de Pedidos",
  serviceProviderReport: "Datos de Partners",
  serviceProviderReports: "Datos de Partners",
  frequentlyAskedQuestion: "Preguntas frecuentes",
  addQuestionAndAnswer: "Añadir pregunta y respuesta",
  question: "Pregunta",
  addInputFeild: "Añadir campo",
  addFeildLabel: "Añadir nombre del campo",
  inputFeildTitleNameEmailAndAddress: "Título del campo (Eng)",
  inputFeildTitleNameEmailAndAddressEsp: "Título del campo (Esp)",
  fieldDescription: "Descripción",
  addTextBlock: "Agregar bloque de texto",
  dropDownTitle: "Desplegable",
  descriptionOrInformation: "Descripción o información (Eng)",
  descriptionOrInformationEsp: "Descripción o información (Esp)",
  addFileUploader: "Añadir subidor de archivos",
  uploadTitle: "Subir archivo (Eng)",
  uploadTitleEsp: "Subir archivo (Esp)",
  yourFileTitleHere: "Su título de archivo aquí",
  uploadFromComputer: "Subir desde el ordenador",
  addTitleForAdminNote: "Agregar título (Eng)",
  addTitleForAdminNoteEsp: "Agregar título (Esp)",
  checkBoxTitle: "Agregar título",
  addTitleForCalender: "Agregar título (Eng)",
  addTitleForCalenderEsp: "Agregar título (Esp)",
  noteHere: "Nota",
  enterEmail: "Ingrese correo electrónico",
  weWillSendYouACodeToChangePassword:
    "Le enviaremos un código a su correo electrónico, para que pueda cambiar la contraseña.",
  all: "Todos",
  date: "Fecha",
  deliveryDate: "Fecha Entrega",
  provider: "Partner",
  additionalBudget: "Presupuesto",
  logOut: "Cerrar sesión",
  budgetSupplementary: "Presupuesto complementario",
  orderSupplementary: "Pedido complementario",
  additionalAmount: "Coste adicional",
  serviceAmount: "Coste del servicio",
  received: "Recibida",
  confirmation: "Confirmar",
  yes: "Sí",
  no: "No",
  areYouSureWantDeleteThisFaq:
    "Está seguro de querer eliminar esta pregunta frecuente?",
  cancel: "Cancelar",
  ok: "Ok",
  states: "Comunidades atónomoas",
  registrationDetails: "Registration Details",
  registration: "Registration",
  add: "Añadir",
  search: "búsqueda",
  userOrders: "Pedidos del usuario",
  details: "Detalles",
  viewInformation: "Ver información",
  to: "To",
  level: "Rama",
  selectParentBranch: "Seleccione rama anterior",
  addNewService: "Añadir nuevo servicio",
  editService: "Editar servicio",
  selectBranch: "Seleccione rama",
  selectServiceProvider: "Seleccionar",
  isServiceIsRegionRelated: "Service is Region Related?",
  addServiceName: "Nombre de Servicio",
  addServiceNameEng: "Nombre de Servicio (Eng)",
  addServiceNameEsp: "Nombre de Servicio (Esp)",
  serviceForm: "Servicio Formulario",
  selectServiceForm: "Seleccione Servicio Formulario",
  isItServicePack: "¿Paquete de servicios?",
  serviceForDynamicForm: "Formulario dinámico del servicio",
  uploadFiles: "Subir archivo",
  addFileTitleHere: "Título de archivo (Eng)",
  addFileTitleHereEsp: "Título de archivo (Esp)",
  radioButtonTitle: "Título del botón de radio",
  addTitle: "Añadir título",
  doYouReallyWantToDeleteThisService:
    "¿Realmente quieres eliminar este servicio?",
  supplementaryFees: "Suplementos Administrativos",
  letsAssign: "Vamos a asignar",
  selectProvidersForReassignTheService:
    "Seleccione el Partner para reasignar el servicio",
  reassign: "Reasignar",
  user: "Client",
  messages: "Mensajes",
  documents: "documentos",
  endDispute: "Disputa final",
  ifYouwantToContinueClickOnContinueOrderOrIfYouWantToCancelClickOnCancelOrder: `Para devolver parte o la totalidad del importe pagado, presione “Disputar”, para continuar con el pedido, presione  "Continuar".`,
  ifYouwantToContinueClickOnContinueOrderOrIfYouWantToCancelClickOnCancelAdditionalBudget: `Para devolver parte o la totalidad del importe abonado, pulse "Impugnar"; para continuar con el presupuesto adicional, pulse "Continuar".`,
  cancelOrder: "Disputar",
  continueOrder: "Continuar",
  continue: "Continuar",
  continueOrderAdditionalBudget: "Continuar Presupuesto adicional",
  confirm: "Confirmar",
  doYouLikeToGoForCompleteOrPartial:
    "El acuerdo es de un reembolso completo o parcial?",
  complete: "Completo",
  toWhomeYouLikeToMakeACompleteRefund:
    "¿A quién se le va a hacer un reembolso completo?",
  orderAmount: "Monto del pedido",
  supplementaryAmount: "Cantidad suplementaria",
  docuDocDeduction: "Dududoc deducción",
  partial: "Parcial",
  enterPercentYouwantToRefundUser:
    "Ingrese el porcentaje que desea reembolsar al usuario",
  enterPercentOfSupplementaryYouWantToRefundUser:
    "Ingrese el suplementaria porcentaje que desea reembolsar al usuario",
  remaining: "Restante",
  partialAmountOf: "Cantidad parcial de",
  partialAmount: "Cantidad parcial",
  ofPartialAmount: "de cantidad parcial",
  loading: "Cargando...",
  send: "Enviar",
  sendMessage: "enviar mensaje ...",
  forServiceProvider: "Para el Partner",
  attachedDocuments: "Documentos adjuntos",
  myDocuments: "Mis documentos",
  noChatAvailable: "No hay chat disponible",
  filterByDate: "Filtrar por fecha",
  amount: "Monto",
  supplementary: "Suplementario",
  personal: "Personal",
  surname: "Apellido",
  cif: "CIF",
  worker: "Usuario",
  infoBox: "Info Box",
  monToThu: "Lun - Jue",
  fri: "Vie",
  lunchTime: "Pausa del mediodía",
  lunch: "almorzar",
  orderStatusProgress: "Progreso de estado del pedido",
  finishThisStep: "Paso completado",
  finishThisOrder: "Pedido terminado y enviado",
  customer: "Cliente",
  dueDate: "Fecha de entrega",
  addBill: "Añadir Factura",
  upload: "Subir",
  fileUploaded: "Archivo subido",
  sendToUser: "Enviar notificación de servicio entregado",
  endDateCouldntBeLessThanStartDate:
    "End-date couldn't be less than start-date",
  areThereAnySupplementaryCharges: "¿Cargos complementario?",
  monthly: "Mensual",
  fixedPrice: "Precio fijo",
  estimatedTimeOfDelivery: "Tiempo estimado de entrega",
  specialPrice: "Precios especiale",
  origionalPrice: "Precio original",
  discountPrice: "Precio Descuento",
  addStatus: "Add Status",
  timeType: "Tipo de tiempo",
  editQuestionAndAnswer: "Editar pregunta y respuesta",
  disputedOrders: "Disputed Orders",
  disputeOrders: "Órdenes de disputa",
  createAndManageSubAdmins: "Crea y administra subadmins",
  workerOfServiceProvidersAccountManagement: "Usuarios de Partner",
  releaseDisputedAmount: "Liberar la cantidad disputada",
  downloadAll: "Descargar todo",
  download: "Descargar",
  attachedDocumentCopies: "Copias de documentos adjuntos",
  disputeDetails: "dispute details",
  userDocuments: "Documentos de usuario",
  orderChats: "Ordenar chats",
  helpMessage: "Mensaje de ayuda",
  order: "Pedido",
  changeRequested: "cambio solicitado",
  paymentAccepted: "Pago aceptado",
  NoResponse: "ninguna respuesta",
  contactClient: "Contactar cliente",
  pleaseInputYoureEmailAddress:
    "¡Por favor ingrese su dirección de correo electrónico!",

  theInputIsNotvalidEMail: "Correo electrónico inválido",
  pleaseEnterYourPassword: "¡Por favor, introduzca su contraseña!",
  unselectAll: "deselecciona todo",
  selectAll: "seleccionar todo",
  attachments: "Archivos adjuntos",
  notAvailable: "No disponible",
  serviceInformation: "Información",
  additionalBudgetNumber: "Additional Budget Number",
  typeOfMovement: "Tipo de Movimiento",
  dateOfOrder: "Fecha Pedido",
  nameAndSurnameOfClient: "Nombre y Apellido Cliente",
  companyName: "Nombre Empresa",
  cifOrNif: "CIF/NIF",
  companyAddress: "Dirección Empresa",
  dispute: "Disputa",
  discount: "Descuento",
  sumOfAllSuplements: "Suma Suplidos",
  toBePayedServiceProvider: "Pagar Prestador de Servicio",
  statusPenidngOrCompleted: "Status (Penidng or Completed)",
  completionDate: "Completion Date",
  billNumber: "Núm Factura",
  dateOfPayment: "Fecha Pago",
  originalServicePriceEstablished: "Servicio Original",
  discountServiceVAT: "Descuento € (Servicio+IVA)",
  serviceDiscount: "Servicio-Descuento (Base Imponible)",
  service_Discount: "Servicio-Descuento (Base Imponible)",
  serviceDiscountVATFinalPriceOfService: "Importe factura",
  VATToBePayedOfServiceDiscount: "IVA a pagar (21%)",
  totalPayedByClient: "Total Pagado por el Cliente",
  notifications: "Notificaciones",
  important: "Importante",
  documentationWasMissingLabel: [
    "Como faltaba documentación,",
    <br />,
    "El tiempo de entrega del servicio.",
    <br />,
    "está en espera hasta la información completa",
    <br />,
    "Esta recibido",
  ],
  downloadAllUserFiles: "descargar todos los archivos de usuario",
  downloadAllFiles: "descargar todos los archivos",
  selectedUserType: "Tipo de usuario seleccionado",
  business: "Empresa",
  company: "Empresa",
  cif: "CIF",
  sumOfAllSupplements: "Suma Suplidos",
  invalidEmail: "email inválido",
  providerDocuments: "Proveedor Documentos",
  amountForServiceProvider: "Importe para el proveedor de servicios",
  amountForUser: "Cantidad para el usuario",
  disputeRefund: "Reembolso de disputas",
  minute: "minuto",
  minutes: "minutos",
  Minute: "minuto",
  Minutes: "minutos",
  Hours: "horas",
  Hour: "hora",
  Week: "semana",
  Day: "día",
  day: "día",
  days: "días",
  hour: "hora",
  hours: "horas",
  week: "semana",
  weeks: "semanas",
  serviceSelected: "Servicio seleccionado",

  completed: "terminado",
  pending: "pendiente",
  disputed: "cuestionado",
  cancelled: "cancelado",
  block: "bloquear",
  accepted: "aceptado",
  enterTime: "ingresar tiempo",

  receiveInitialInformation: "Información inicial recibida",
  receiveFullInformationAndDocumentation: "Información necesaria recibida",
  managementOfTheService: "Prestación del servicio iniciada",
  finalizationOfManagementOfService: "Prestación del servicio finalizada",
  serviceDelivered: "Servicio entregado",
  answerEng: "Answer (Eng)",
  answerEsp: "Answer (Esp)",
  questionEng: "Pregunta (Eng)",
  questionEsp: "Pregunta (Esp)",
  comment: "Comentario",
  viewMessage: "Ver mensajes",

  orderPlaced: "Pedido realizado",
  orderCanceled: "Orden cancelada",
  orderCompleted: "Pedido completado",
  requestForFurtherDocumentation: "Solicitud de documentación adicional",
  additionalBudgetRequest: "Solicitud de presupuesto adicional",
  discountPercentage: "Porcentaje de descuento",
  reviews: "Evualuaciones",
  originalServiceVATPriceEstablishedInAdminPanel: "Servicio Original + IVA",
  servicioDicount: "Servicio-Descuento (Base Imponible)",
  seviceDocuDoc30SeviceDiscount: "Sevicio DocuDoc (30% Sevicio-Descuento)",
  seviceProvider70SeviceWithoutVAT: "Servicio Proveedor (70%)",
  IVAAPagar21: "VAT a price (21%)",
  VATDocuDoc: "IVA DocuDoc",
  VATServiceProvider: "IVA Service Provider",
  whichStaysInDocuDoc: "Que se queda en DocuDoc",
  supplementaryDetails: "Suplementarios detalles",
  serviceVATSupplements: "Service + VAT + Supplements",
  vat: "IVA",
  managementCapacity: "Sin Capacidad",
  result: "Resultado",
  userType: "Tipo de usuario",
  serviceBillServiceBillOfServiceProviderAdditionalBudgetBill:
    "Factura Servicio Prestador del Servicio",
  suplementsBillOfServiceProvider: "Factura Suplements Prestador del Servicio",
  billDocuDocAutomaticGenerated: "Factura DocuDoc",
  fifteenPercentageOfServiceProviderServiceVAT:
    "15% de la base imponible del prestador de servicios (Retención IRPF)",
  idOfPayment: "ID de Pago",
  totalPayedOnlyToDoubleCheck:
    "Total pagado por la cliente (solo para verificar)(feminine)",
  fakeRating: "Calificación falsa",
  download: "descargar",
  budgetCompleted: "Presupuesto finalizado",
  disputeAdditionalBudget: "Disputa Presupuesto",
  none: "Partucular",
  selfEmployed: "Autónomo",
  self: "Autónomo",
  whatCanBeImproved: "¿Qué se puede mejorar?",
  review: "opiniones",
  finalizados: "Finalizados",
  cancelados: "Cancelados",
  reasignados: "Reasignados",
  porDisputar: "Por Disputar",
  disputados: "Disputados",
  billNeeded: "¿Se necesita factura?",
  releaseDate: "Fecha de lanzamiento",
  serviceProviderType: "Tipo de proveedor de servicios",
  selfEmployedServiceProvider: "Autonomo",
  businessServiceProvider: "Sociedad",
  serviceProviderSelfEmployedORBusiness:
    "Prestador del servicio (Atónomo / Sociedad)",
  baseImponibleWithoutVATDiscount:
    "Servicio Original (Without VAT) -Descuento (Base Imponible)",
  reimbursement: "Reembolso",
  serviceProviderServiceAmount: "monto del servicio del proveedor de servicios",
  serviceProviderSupplementaryAmount:
    "importe suplementario del proveedor de servicios",
  docuDocAmount: "Monto del documento",
};
let language = localStorage.getItem("primaryLanguage");

const adminLabels = language === "english" ? englishLabels : spanishLabels;

export { adminLabels, spanishLabels, englishLabels };
