import { combineReducers } from "redux";
import LoginReducer from "./loginReducer";
import LoaderReducer from "./loaderReducer";
import GetBeneficiariesReducer from "./getBeneficiariesReducer";
import GetServiceAreaReducer from "./getServiceAreaReducer";
import GetBranchesReducer from "./getBranchesReducer";
import GetServiceProvidersReducer from "./getProvidersReducer";
import GetServicesReducer from "./getServicesReducer";
import GetServiceFormsReducer from "./getServiceFormsReducer";
import GetAppUsersReducer from "./getAppUsersReducer";
import ProviderLoginReducer from "./providerLoginReducer";
import GetWorkersReducer from "./getWorkersReducer";
import GeHelpReducer from "./getHelpReducer";
import GetWorkWithUsReducer from "./getWorkWithUsReducer";
import GetServiceProviderMessagesReducer from "./serviceProviderReducers/messages";
import GetServiceProviderOrdersReducer from "./serviceProviderReducers/orders";
import GetServiceProviderAdditionalBudgetsReducer from "./serviceProviderReducers/additionalBudget";
export const RootReducer = combineReducers({
  loginDetail: LoginReducer,
  providerLoginDetail: ProviderLoginReducer,
  loaderToggle: LoaderReducer,
  beneficiariesList: GetBeneficiariesReducer,
  serviceAreaList: GetServiceAreaReducer,
  branchList: GetBranchesReducer,
  providerList: GetServiceProvidersReducer,
  servicesList: GetServicesReducer,
  serviceFormsList: GetServiceFormsReducer,
  appUsersList: GetAppUsersReducer,
  workerList: GetWorkersReducer,
  geHelpList: GeHelpReducer,
  getWorkWithUsList: GetWorkWithUsReducer,
  serviceProviderMessages: GetServiceProviderMessagesReducer,
  serviceProviderAllOrders: GetServiceProviderOrdersReducer,
  serviceProviderAllAdditionalBudgets: GetServiceProviderAdditionalBudgetsReducer,
});
