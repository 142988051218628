import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { RiArrowDropLeftLine } from "react-icons/ri";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Errors } from "../../components/antdToast";
import ButtonComponent from "../../components/ButtonComponent";
import { Remove_Data_Form_Provider_Reducers } from "../../components/dataRemoveOnProviderLogout";
import { GetMessagesMethod } from "../../config/api";
import { Images } from "../../constant/Images";
import {
  DeleteWorkerAtLogoutTime,
  FetchWorkersAction
} from "../../Redux/actions/fetchWorkersAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { ProviderLogoutAction } from "../../Redux/actions/logoutAction";
import { SessionContext } from "../../store";

const MessageDetail = (props) => {
  const { goBack, push } = useHistory();
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [message, setMessages] = useState([]);
  const { session } = useContext(SessionContext);
  const [value, setValue] = useState("");

  useEffect(() => {
    GetMessagesMethod(
      props.match.params.id,
      (success) => {
        if (success.status === 200) {
          if (success.data.response.detail !== null) {
            let filterdOut = success.data.response.detail.messages.filter(
              (item) => item.is_provider_message === "0"
            );
            let mappedOut = filterdOut.reverse().map((item) => {
              return {
                message: item.message,
              };
            });
            setMessages(mappedOut);
          }
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  }, []);

  return (
    <section>
      <div className="flex justify-center sm:justify-center lg:justify-between items-center mt-20 mb-10  flex-wrap sm:flex-wrap lg:flex-nowrap">
        <div className="pl-5 flex self-center justify-center items-center w-full sm:w-full lg:w-1/2  ">
          <div
            onClick={() => goBack()}
            className="flex text-gray-400 items-center cursor-pointer"
          >
            <RiArrowDropLeftLine />
            <span>Back</span>
          </div>
          <div className="flex justify-between items-center w-full ">
            <div className="flex items-center">
              <h3 className="ml-8">John Doe</h3>
              <span
                className=""
                style={{
                  fontWeight: "normal",
                  fontSize: 20,
                  color: "#7D7D7D",
                  marginLeft: "10px",
                }}
              >
                (+341 234 24 252)
              </span>
            </div>
            <div className="w-40">
              <Link
                to={
                  localStorage.getItem("type") === "service_provider"
                    ? `/add-my-message-order/${props.match.params.id}`
                    : `/worker/add-my-message-order/${props.match.params.id}`
                }
              >
                <ButtonComponent title="Add Message" height="h-10" />
              </Link>
            </div>
          </div>
        </div>
        <div className="items-center" style={{ width: "46%" }}>
          <label className="hh3 text-xl">Documents</label>
        </div>
      </div>
      <div className="container grid grid-cols-12 gap-3 lg:pl-24">
        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-5">
          <div
            style={{ minHeight: "50vh", width: "50%" }}
            className="bg-white container rounded-lg px-5 py-6  "
          >
            <div className="bg-white container rounded-lg col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-3 p-5 border-2 float-left worker-message">
              <div className="table-quill">
                <ReactQuill
                  value={message[0]?.message[0]?.message || ""}
                  style={{ width: 380 }}
                  readOnly
                />
                {`${moment(message[0]?.created_at).format(
                  "h:mm:ss a"
                )} || ${moment(message[0]?.created_at).format("DD-MMM-YYYY")}`}
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-5">
          <div
            style={{ minHeight: "20vh" }}
            className="bg-white container rounded-lg px-5 py-3 "
          >
            <div className="divide-y-2 divide-gray-100 divide-solid">
              <div className="flex grid grid-cols-12 gap-2 divide-x-2 divide-gray-100 divide-solid">
                <div className="col-span-4 sm:col-span-6 md:col-span-6 lg:col-span-6 py-2">
                  <label className="hh4 ">Attached Document copies</label>
                  {message[0]?.message[0]?.documents?.map((docs, index) => {
                    return (
                      <a href={docs} download>
                        <div className="flex mt-2" key={index}>
                          <img src={Images.document.default} />
                          <span className="ml-2">File {index + 1}</span>
                        </div>
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    showWorkers: state.workerList.workers,
    providerLoginInfo: state.providerLoginDetail.loggedUserDetailOfProvider,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
    getWorkers: (props) => {
      dispatch(FetchWorkersAction(props));
    },
    removeWorkerList: () => {
      dispatch(DeleteWorkerAtLogoutTime());
    },
    setProviderLogout: () => {
      dispatch(ProviderLogoutAction());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MessageDetail);
