import { DownOutlined } from "@ant-design/icons";
import {
  Dropdown,
  Menu,
  Select,
  Spin,
  Switch,
  TimePicker,
  TreeSelect,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { AiFillStar } from "react-icons/ai";
import { IoIosArrowForward } from "react-icons/io";
import { RiArrowDropLeftLine, RiInformationLine } from "react-icons/ri";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { flags } from "../../assets/flags";
import { CountryCodes } from "../../assets/flags/CountryCodes";
import { Errors, Success } from "../../components/antdToast";
import ButtonComponent from "../../components/ButtonComponent";
import { Remove_Data_Form_Reducers } from "../../components/dataRemoveOnLogout";
import InputComponent from "../../components/InputComponent";
import ModalComponent from "../../components/Modal";
import {
  AddServiceProvider,
  EditServiceProvider,
} from "../../config/api/index";
import { adminLabels } from "../../config/label/adminLabels";
import {
  functionToPreventSignsFromNumberFromPricing,
  regexForPhoneNumber,
  regexToRemoveNumberFromTheString,
  validateDescription,
  ValidateEmail,
} from "../../config/utils";
import { Images } from "../../constant/Images";
import { DeleteBeneficiariesAtLogoutTime } from "../../Redux/actions/fetchBeneficiariesAction";
import { DeleteBranchesAtLogoutTime } from "../../Redux/actions/fetchBranchesAction";
import {
  DeleteProvidersAtLogoutTime,
  FetchProvidersAction,
} from "../../Redux/actions/fetchProvidersAction";
import { DeleteServiceAreasAtLogoutTime } from "../../Redux/actions/fetchServiceAreaAction";
import { DeleteServicesAtLogoutTime } from "../../Redux/actions/fetchServicesAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";
import "./addAddminServiceProvider.css";

const { uploadImg, checkboxActive, checkbox } = Images;
const { AC } = flags;
var Time;

function AddAdminServiceProvider(props) {
  const { goBack, push } = useHistory();
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");

  const [info, setInfo] = useState("");
  const [infoEsp, setInfoEsp] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [experience, setExperience] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+34");
  const [countryFlag, setCountryFlag] = useState(
    "https://cdn.britannica.com/36/4336-004-6BD81071/Flag-Spain.jpg"
  );
  const [workingHours, setWorkingHours] = useState([]);
  const [startMTTH, setStartMTTH] = useState("00:00:00");
  const [endMTTH, setEndMTTH] = useState("00:00:00");
  const [checkedLunchMTTH, setCheckedLunchMTTH] = useState(false);
  const [startLunchMTTH, setStartLunchMTTH] = useState("00:00:00");
  const [endLunchMTTH, setEndLunchMTTH] = useState("00:00:00");
  const [startFri, setStartFri] = useState("00:00:00");
  const [endFri, setEndFri] = useState("00:00:00");
  const [startLunchFri, setStartLunchFri] = useState("00:00:00");
  const [endLunchFri, setEndLunchFri] = useState("00:00:00");

  const [checkedLunchFri, setCheckedLunchFri] = useState(false);

  const [mondayTohursdayObj, setMondayTohursdayObj] = useState(null);
  const [mondayTohursdayLunchObj, setMondayTohursdayLunchObj] = useState(null);
  const [friTohursdayLunchObj, setFriTohursdayLunchObj] = useState(null);
  const [fridayObj, setFridayObj] = useState(null);
  const [errorMessageOfMTTH, setErrorMessageOfMTTH] = useState("");
  const [errorMessageOfLunchMTTH, setErrorMessageOfLunchMTTH] = useState("");

  const [errorMessageOfFri, setErrorMessageOfFri] = useState("");
  const [errorMessageOfLunchFri, setErrorMessageOfLunchFri] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [fieldIsRequired, setFieldIsrequired] = useState(false);
  const [fridayWorkingHoursSlot, setFridayWorkingHoursSlot] = useState(false);
  const [selectAllState, setSelectAllState] = useState([]);
  //  const [states, setStates] = useState("States");
  const { Option } = Select;
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [position, setPosition] = useState("");
  const [position_sp, setPosition_sp] = useState("");
  const [fakeRating, setFakeRating] = useState("5");
  const [showFakeRating, setShowFakeRating] = useState(false);
  const [fakeRatingSection, setFakeRatingSection] = useState(true);
  const [disabledMonThuHoursStart, setDisabledMonThuHoursStart] = useState([]);
  const [disabledMonThuHoursEnd, setDisabledMonThuHoursEnd] = useState([]);
  const [disabledFriHoursStart, setDisabledFriHoursStart] = useState([]);
  const [disabledFriHoursEnd, setDisabledFriHoursEnd] = useState([]);
  const [countryCodeState, setCountryCodeState] = useState(
    CountryCodes?.map((country) => {
      return {
        ...country,
        name: country.name.toLowerCase(),
      };
    })
  );
  const [searchCountry, setSearchCountry] = useState("");
  const [typeOfServiceProvider, setTypeOfServiceProvider] = useState("self");

  useEffect(() => {
    setAvatar(null);
    if (props?.match?.params?.providerID) {
      props?.location?.state === undefined &&
        push("/admin/admin-service-provider");
      let serviceProvider = props?.location?.state?.provider;
      setAvatar(serviceProvider?.avatar);
      setName(serviceProvider?.name);
      setEmail(serviceProvider?.email);
      setAddress(serviceProvider?.address);
      setCity(serviceProvider?.city);
      setInfo(serviceProvider?.info || "");
      setInfoEsp(serviceProvider?.info_sp || "");

      setExperience(serviceProvider?.experience);
      setSelectedStates(serviceProvider?.state);
      setPosition(serviceProvider?.position);
      setPosition_sp(serviceProvider?.position_sp || "");
      setFakeRating(String(serviceProvider?.fake_rating || 5));
      setTypeOfServiceProvider(serviceProvider?.type || "self");
      setShowFakeRating(serviceProvider?.fake_rating === null ? true : false);
      setFakeRatingSection(
        serviceProvider?.fake_rating === null ? false : true
      );
      setCountryCode(serviceProvider?.phone.split("-")[0]);
      setPhoneNumber(serviceProvider?.phone.split("-")[1]);
      let flag = CountryCodes?.map((cc) => cc)?.filter((country, index) => {
        return (
          country?.phoneCode ===
          serviceProvider?.phone?.split("-")[0]?.toString()
        );
      });
      setCountryFlag(!flag?.length ? "ES" : flag[0].code);
      setWorkingHours(serviceProvider?.working_hours);
      if (serviceProvider?.working_hours) {
        serviceProvider?.working_hours?.map((item) => {
          if (item?.hasOwnProperty("monTHuLunchHours")) {
            setStartMTTH(item?.start);
            setEndMTTH(item?.end);
            if (item?.monTHuLunchHours) {
              setCheckedLunchMTTH(true);
              setStartLunchMTTH(item?.monTHuLunchHours?.start);
              setEndLunchMTTH(item?.monTHuLunchHours?.end);
            }
          } else if (item?.hasOwnProperty("fridayLunchHours")) {
            setStartFri(item?.start);
            setEndFri(item?.end);
            if (item?.fridayLunchHours) {
              setCheckedLunchFri(true);
              setStartLunchFri(item?.fridayLunchHours?.start);
              setEndLunchFri(item?.fridayLunchHours?.end);
            }
          }
        });
      }
    }
  }, []);
  const handleMenuClick2 = (e) => {
    setCountryCode("+" + e.key);

    if (e.key === "3") {
      setVisible(false);
    }
  };
  const statesOfSpain = [
    {
      value: "Andalucia",
      title: "Andalucia",
    },
    {
      value: "Aragon",
      title: "Aragon",
    },
    {
      value: "Cantabria",
      title: "Cantabria",
    },
    {
      value: "Castilla Y Leon",
      title: "Castilla Y Leon",
    },
    {
      value: "Castilla La Mancha ",
      title: "Castilla La Mancha ",
    },
    {
      value: "Cataluna",
      title: "Cataluna",
    },
    {
      value: "Ceuta",
      title: "Ceuta",
    },
    {
      value: "Comunidad De Madrid",
      title: "Comunidad De Madrid",
    },
    {
      value: "Comunidad Foral De Navarra",
      title: "Comunidad Foral De Navarra",
    },
    {
      value: "Comunidad Valenciana",
      title: "Comunidad Valenciana",
    },
    {
      value: "Extremadura ",
      title: "Extremadura ",
    },
    {
      value: "Galicia",
      title: "Galicia",
    },
    {
      value: "Islas Baleares",
      title: "Islas Baleares",
    },
    {
      value: "La Rioja",
      title: "La Rioja",
    },
    {
      value: "Melilla ",
      title: "Melilla ",
    },
    {
      value: "Pais Vasco",
      title: "Pais Vasco",
    },
    {
      value: "Principado De Asturias",
      title: "Principado De Asturias",
    },
    {
      value: "Region De Murcia ",
      title: "Region De Murcia ",
    },
  ];

  const allIds = statesOfSpain?.map(({ value }) => value);
  const onKeyDown = (e) => {
    if (e.keyCode === 8 || e.keyCode == 46) {
      setCountryCodeState(
        CountryCodes?.map((country) => {
          return {
            ...country,
            name: country?.name?.toLowerCase(),
          };
        })
      );
    }
  };
  const countryHandleChange = (e) => {
    setSearchCountry(e.target.value);

    if (e.target.value === "" || e.target.value?.trim()?.length === 0) {
      setCountryCodeState(
        CountryCodes?.map((country) => {
          return {
            ...country,
            name: country?.name?.toLowerCase(),
          };
        })
      );
    } else {
      setCountryCodeState(
        countryCodeState?.filter((country) => {
          return country?.name?.includes(e.target.value?.toLowerCase());
        })
      );
    }
  };
  const countryCodeBlur = () => {
    setSearchCountry("");
  };

  const menu = (
    <Menu className="menu-class country-menu " onClick={handleMenuClick2}>
      <div>
        <input
          className="pl-2"
          type="text"
          placeholder={adminLabels.search}
          value={searchCountry}
          onChange={countryHandleChange}
          onKeyDown={onKeyDown}
          onBlur={countryCodeBlur}
        />
      </div>
      <Menu.Divider />
      {countryCodeState?.map((country, index) => {
        var intKey = parseInt(country.phoneCode);
        return (
          <Menu.Item
            className="bg-white"
            key={intKey}
            onClick={() => setCountryFlag(country.code)}
          >
            <div className="flex bg-white w-max	py-2 w-full pl-2 pr-10 rounded-full items-center">
              <img
                src={require(`../../assets/flags/${country.code}.png`).default}
                style={{ width: 15, height: 15, margin: 2, Radius: 20 }}
              />
              <span>{country.phoneCode}</span>
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const onChangeMTTHStart = (time) => {
    let timeString = moment(time).format("HH:mm");
    Time = new Date(time).toISOString();

    if (startMTTH !== "00:00:00") {
      if (timeString === "") {
        onChangeMTTHEnd(time, timeString);
        setMondayTohursdayObj(null);
        setStartMTTH("00:00:00");
        setEndMTTH("00:00:00");
        setErrorMessageOfMTTH("");
      }
    }
    setStartMTTH(timeString !== "" ? timeString : "00:00:00");

    let startDisabledHours = [];
    for (let i = parseInt(timeString.split(":")[0]); i >= 0; i--) {
      startDisabledHours.push(i);
    }
    setDisabledMonThuHoursStart(startDisabledHours);
  };

  const onChangeMTTHEnd = (time) => {
    let timeString = moment(time).format("HH:mm");
    if (Time >= new Date(time).toISOString()) {
      setErrorMessageOfMTTH(adminLabels.endDateCouldntBeLessThanStartDate);
      // Time = "";
    } else {
      setEndMTTH(timeString !== "" ? timeString : "00:00:00");
      setMondayTohursdayObj({
        isFriday: false,
        start: startMTTH,
        end: timeString !== "" ? timeString : "00:00:00",
      });

      setErrorMessageOfMTTH("");

      let endDisabledHours = [];
      for (let i = parseInt(timeString.split(":")[0]); i <= 23; i++) {
        endDisabledHours.push(i);
      }
      setDisabledMonThuHoursEnd(endDisabledHours);
    }
  };
  const onChangeLunchMTTHStart = (time) => {
    let timeString = moment(time).format("HH:mm");
    Time = new Date(time).toISOString();

    if (startLunchMTTH !== "00:00:00") {
      if (timeString === "") {
        onChangeLunchMTTHEnd(time, timeString);
        setMondayTohursdayLunchObj(null);
        setStartLunchMTTH("00:00:00");
        setEndLunchMTTH("00:00:00");
        setErrorMessageOfLunchMTTH("");
      }
    }
    setStartLunchMTTH(timeString !== "" ? timeString : "00:00:00");
  };
  const onChangeLunchMTTHEnd = (time) => {
    let timeString = moment(time).format("HH:mm");
    if (Time >= new Date(time).toISOString()) {
      setErrorMessageOfLunchMTTH(adminLabels.endDateCouldntBeLessThanStartDate);
      // Time = "";
    } else {
      setEndLunchMTTH(timeString !== "" ? timeString : "00:00:00");
      setMondayTohursdayLunchObj({
        isFriday: false,
        start: startLunchMTTH,
        end: timeString !== "" ? timeString : "00:00:00",
      });
      setErrorMessageOfLunchMTTH("");
    }
  };

  const onChangeFriStart = (time) => {
    let timeString = moment(time).format("HH:mm");
    Time = new Date(time).toISOString();
    if (startFri !== "00:00:00") {
      if (timeString === "") {
        onChangeFriEnd(time, timeString);
        setFridayObj(null);
        setStartFri("00:00:00");
        setEndFri("00:00:00");
        setErrorMessageOfFri("");
      }
    }
    setStartFri(timeString !== "" ? timeString : "00:00:00");
    let startDisabledHours = [];
    for (let i = parseInt(timeString.split(":")[0]); i >= 0; i--) {
      startDisabledHours.push(i);
    }
    setDisabledFriHoursStart(startDisabledHours);
  };

  const onChangeFriEnd = (time) => {
    let timeString = moment(time).format("HH:mm");
    if (Time >= new Date(time).toISOString()) {
      setErrorMessageOfFri(adminLabels.endDateCouldntBeLessThanStartDate);
      // Time = "";
    } else {
      setEndFri(timeString !== "" ? timeString : "00:00:00");
      setFridayObj({
        isFriday: true,
        start: startFri,
        end: timeString !== "" ? timeString : "00:00:00",
      });
      setErrorMessageOfFri("");
      let endDisabledHours = [];
      for (let i = parseInt(timeString.split(":")[0]); i <= 23; i++) {
        endDisabledHours.push(i);
      }
      setDisabledFriHoursEnd(endDisabledHours);
      // Time = "";
    }
  };

  const onChangeLunchFriStart = (time) => {
    let timeString = moment(time).format("HH:mm");
    Time = new Date(time).toISOString();

    if (startLunchFri !== "00:00:00") {
      if (timeString === "") {
        onChangeLunchMTTHEnd(time, timeString);
        setFriTohursdayLunchObj(null);
        setStartLunchFri("00:00:00");
        setEndLunchMTTH("00:00:00");
        setErrorMessageOfLunchMTTH("");
      }
    }
    setStartLunchFri(timeString !== "" ? timeString : "00:00:00");
  };
  const onChangeLunchFriEnd = (time) => {
    let timeString = moment(time).format("HH:mm");
    if (Time >= new Date(time).toISOString()) {
      setErrorMessageOfLunchFri(adminLabels.endDateCouldntBeLessThanStartDate);
      // Time = "";
    } else {
      setEndLunchFri(timeString !== "" ? timeString : "00:00:00");
      setFriTohursdayLunchObj({
        isFriday: true,
        start: startLunchFri,
        end: timeString !== "" ? timeString : "00:00:00",
      });
      setErrorMessageOfLunchFri("");
    }
  };
  const pushWorkingHoursDetail = () => {
    if (mondayTohursdayObj !== null && fridayObj !== null) {
      setWorkingHours((prevState) => {
        return [
          {
            ...mondayTohursdayObj,
            monTHuLunchHours:
              checkedLunchMTTH === true ? mondayTohursdayLunchObj : null,
          },
          {
            ...fridayObj,
            fridayLunchHours:
              checkedLunchFri === true ? friTohursdayLunchObj : null,
          },
        ];
      });
      setVisible(false);
    } else if (mondayTohursdayObj !== null && fridayObj === null) {
      setWorkingHours((prevState) => {
        return [
          {
            ...mondayTohursdayObj,
            monTHuLunchHours:
              checkedLunchMTTH === true ? mondayTohursdayLunchObj : null,
          },
        ];
      });
      setVisible(false);
    } else if (mondayTohursdayObj === null && fridayObj !== null) {
      setWorkingHours((prevState) => {
        return [
          {
            ...fridayObj,
            fridayLunchHours:
              checkedLunchFri === true ? friTohursdayLunchObj : null,
          },
        ];
      });
      setVisible(false);
    }
  };

  const ratingHandleChange = (value) => {
    setFakeRating(value);
  };

  const onSubmitProvider = () => {
    let formData = new FormData();
    if (
      avatar !== null &&
      name?.trim()?.length !== 0 &&
      email?.trim()?.length !== 0 &&
      ValidateEmail(email) &&
      experience?.trim()?.length !== 0 &&
      position?.trim()?.length !== 0 &&
      position_sp?.trim()?.length !== 0 &&
      address?.trim()?.length !== 0 &&
      phoneNumber !== "" &&
      selectedStates?.length !== 0 &&
      city !== "" &&
      workingHours?.length !== 0 &&
      validateDescription(info) &&
      validateDescription(infoEsp)
    ) {
      formData.append("name", name);
      formData.append("email", email);
      formData.append("address", address);
      formData.append("city", city);
      formData.append("state", JSON.stringify(selectedStates));
      formData.append("info", info?.toString());
      formData.append("info_sp", infoEsp?.toString());
      formData.append("avatar", avatar);
      formData.append("working_hours", JSON.stringify(workingHours));
      formData.append("experience", experience);
      formData.append("position", position);
      formData.append("position_sp", position_sp);
      formData.append("phone", `${countryCode}-${phoneNumber}`);
      formData.append("fake_rating", fakeRating);
      formData.append("type", typeOfServiceProvider);
      AddServiceProvider(
        formData,
        (success) => {
          if (success?.status === 200) {
            Success(success?.data?.response?.message);
            goBack();

            setAddress("");
            setAvatar(null);
            setCity("");
            setCountryCode("+34");
            setEmail("");
            setName("");
            setPhoneNumber("");
            setCountryFlag(
              "https://cdn.britannica.com/36/4336-004-6BD81071/Flag-Spain.jpg"
            );
            setExperience("");
            setPosition("");
            setPosition_sp("")
            setStartMTTH("00:00:00");
            setEndMTTH("00:00:00");
            setStartFri("00:00:00");
            setEndFri("00:00:00");
            setMondayTohursdayObj(null);
            setFridayObj(null);
            setSelectedStates([]);
            setInfo("");
            props.getProvidersList();
            setFieldIsrequired(false);
          }
        },
        (fail) => {
          if (fail !== null) {
            if (fail?.response?.status === 409) {
              Errors(fail?.response?.data?.error?.message);
              props.setLoaderState(false);
              setFieldIsrequired(false);
            } else if (fail?.response?.status === 401) {
              props.setLoaderState(false);
              Remove_Data_Form_Reducers(props);
            }
            props.setLoaderState(false);
          }
        }
      );
    } else {
      setFieldIsrequired(true);
    }
  };

  const onEditProvider = () => {
    let formData = new FormData();
    if (
      avatar !== null &&
      name?.trim()?.length !== 0 &&
      email?.trim()?.length !== 0 &&
      ValidateEmail(email) &&
      experience?.trim()?.length !== 0 &&
      position?.trim()?.length !== 0 &&
      position_sp?.trim()?.length &&
      address?.trim()?.length !== 0 &&
      phoneNumber !== "" &&
      selectedStates?.length !== 0 &&
      city !== "" &&
      workingHours?.length !== 0 &&
      validateDescription(info) &&
      validateDescription(infoEsp)
    ) {
      formData.append("name", name);
      formData.append("email", email);
      formData.append("address", address);
      formData.append("city", city);
      formData.append("state", JSON.stringify(selectedStates));
      formData.append("info", info?.toString());
      formData.append("info_sp", infoEsp?.toString());
      formData.append("avatar", avatar);
      formData.append("working_hours", JSON.stringify(workingHours));
      formData.append("experience", experience);
      formData.append("position", position);
      formData.append("position_sp", position_sp);
      formData.append("phone", `${countryCode}-${phoneNumber}`);
      formData.append("service_provider_id", props?.match?.params?.providerID);
      formData.append("type", typeOfServiceProvider);

      if (!showFakeRating) {
        formData.append("fake_rating", showFakeRating ? null : fakeRating);
      }

      EditServiceProvider(
        formData,
        (success) => {
          if (success?.status === 200) {
            Success(success?.data?.response?.message);
            goBack();
            props.setLoaderState(false);
            setAddress("");
            setAvatar(null);
            setCity("");
            setCountryCode("+34");
            setEmail("");
            setName("");
            setPhoneNumber("");
            setCountryFlag(
              "https://cdn.britannica.com/36/4336-004-6BD81071/Flag-Spain.jpg"
            );
            setExperience("");
            setPosition("");
            setPosition_sp("")
            setStartMTTH("00:00:00");
            setEndMTTH("00:00:00");
            setStartFri("00:00:00");
            setEndFri("00:00:00");
            setMondayTohursdayObj(null);
            setFridayObj(null);
            setSelectedStates([]);
            setInfo("");
            props.getProvidersList();
            setFieldIsrequired(false);
          }
        },
        (fail) => {
          if (fail !== null) {
            if (fail?.response?.status === 409) {
              Errors(fail?.response?.data?.error?.message);
              props.setLoaderState(false);
              setFieldIsrequired(false);
            } else if (fail?.response?.status === 401) {
              props.setLoaderState(false);
              Remove_Data_Form_Reducers(props);
            }
            props.setLoaderState(false);
          }
        }
      );
    } else {
      setFieldIsrequired(true);
    }
  };

  return (
    <section className="add-service-provider-section">
      <Spin tip={adminLabels.loading} spinning={false}>
        <div className="flex self-center items-center lg:ml-12 pt-10 pb-5 sub-h3">
          <div
            onClick={() => goBack()}
            className="flex text-gray-400 items-center cursor-pointer"
          >
            <RiArrowDropLeftLine />
            <span>{adminLabels.back}</span>
          </div>
          <h3 className="font-bold text-lg mb-0 ml-10">
            {props?.match?.params?.providerID
              ? adminLabels.edit
              : adminLabels.add}{" "}
            {adminLabels.adminOfServiceProvider}
          </h3>
        </div>
        <div
          style={{
            paddingBottom: "10px",
          }}
          className="container mx-auto  lg:pl-28 lg:pr-14"
        >
          <h5 className="mb-0 mt-5 ml-5 sm:ml-5 lg:ml-10 text-gray-500 font-bold text-xl">
            <strong>{adminLabels.providerInfo}</strong>
          </h5>
          <div className="grid grid-cols-12  ">
            <div className="col-span-12  lg:col-span-3 sm:col-span-6 md:col-span-6 mt-2 lg:ml-2 relative cursor-pointer mx-4 sm:mx-4 lg:mr-2 lg:mx-0">
              <input
                className="rounded-full py-3 px-9 w-full absolute opacity-0  cursor-pointer"
                type={"file"}
                accept={".png, .jpg, .jpeg"}
                onChange={(e) => {
                  setAvatar(e.target.files[0]);
                }}
                disabled={props.loader === true ? true : false}
              />
              <button className="bg-gray-400 rounded-full py-3 px-2 text-sm flex justify-center items-center w-full">
                <img
                  style={{ cursor: "pointer" }}
                  width="20"
                  height="20"
                  src={uploadImg.default}
                  className="mr-0 sm:mr-0 lg:mr-1"
                />
                <span className="text-white">
                  {avatar !== null ? avatar?.name : avatar}
                </span>
              </button>
              {avatar === null && fieldIsRequired === true ? (
                <span className="text-red-500 mt-2">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : null}
            </div>
            <div className="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-3 mx-4 sm:mx-4 lg:mr-2">
              <InputComponent
                placeholder={adminLabels.nameOfServiceProvider}
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={props.loader === true ? true : false}
              />
              {name?.trim()?.length === 0 && fieldIsRequired === true ? (
                <span className="text-red-500 mt-2">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : (
                ""
              )}
            </div>

          
  
            <div className=" col-span-3">
              <InputComponent
                type="text"
                placeholder={`${adminLabels.positionDesignation} (Eng)`}
                value={position}
                onChange={(e) => setPosition(e.target.value)}
                disabled={props.loader === true ? true : false}
              />
              {position.trim()?.length === 0 && fieldIsRequired === true ? (
                <span className="text-red-500 mt-2">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : null}
            </div>
            <div className="col-span-3 mx-4 sm:mx-4">
              <InputComponent
                type="text"
                placeholder={`${adminLabels.positionDesignation} (Esp)`}
                value={position_sp}
                onChange={(e) => setPosition_sp(e.target.value)}
                disabled={props.loader === true ? true : false}
              />
              {position_sp.trim()?.length === 0 && fieldIsRequired === true ? (
                <span className="text-red-500 mt-2">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : null}
            </div>
            <div className=" col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-3 mx-4 sm:mx-4 lg:mr-4 ">
              <InputComponent
                type="number"
                placeholder={adminLabels.yearsOfExperience}
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
                disabled={props.loader === true ? true : false}
                onKeyDown={functionToPreventSignsFromNumberFromPricing}
              />
              {experience?.trim()?.length === 0 && fieldIsRequired === true ? (
                <span className="text-red-500 mt-2">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className=" col-span-12 mx-4 sm:mx-4 lg:mr-4 ">
              <div className="flex justify-start">
                <div className=" lg:ml-2 h-auto sm:h-auto lg:h-12 sm:mt-5 lg:my-10 ">
                  <h4 className="text-gray-400">
                    <strong>{adminLabels.serviceProviderType}</strong>
                  </h4>
                  <div className="flex mt-4 space-x-5">
                    <div className="flex space-x-1 items-center">
                      <div>
                        <img
                          className="object-contain cursor-pointer"
                          src={
                            typeOfServiceProvider === "self"
                              ? checkboxActive.default
                              : checkbox.default
                          }
                          disabled={props.loader === true ? true : false}
                          onClick={() => setTypeOfServiceProvider("self")}
                        />
                      </div>
                      <div>
                        <span>{adminLabels.selfEmployedServiceProvider}</span>
                      </div>
                    </div>
                    <div className="flex space-x-1 items-center">
                      <div>
                        <img
                          className="object-contain cursor-pointer"
                          src={
                            typeOfServiceProvider === "business"
                              ? checkboxActive.default
                              : checkbox.default
                          }
                          disabled={props.loader === true ? true : false}
                          onClick={() => setTypeOfServiceProvider("business")}
                        />
                      </div>
                      <div>
                        <span>{adminLabels.businessServiceProvider}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5 className="mb-0 mt-5 ml-5 sm:ml-5  lg:ml-10 text-gray-500 font-bold text-xl">
            <strong>{adminLabels.fakeRating}</strong>
          </h5>
          <div className="mt-15 grid grid-cols-12 ">
            <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-3 mx-4 sm:mx-4 lg:mr-2">
              <div className="flex flex-col justify-start">
                {!showFakeRating ? (
                  <div className="my-2">
                    <Select
                      style={{ width: "50%" }}
                      placeholder="Provider Rating"
                      onChange={ratingHandleChange}
                      className="rounded-full py-3 px-4 w-full inline-block"
                      value={fakeRating}
                    >
                      {["1", "2", "3", "4", "5"]?.map((rate, i) => (
                        <Option key={rate.toString() + i} value={rate}>
                          <div className="flex items-center mt-1">
                            <div>
                              <AiFillStar
                                color={"#FFC107"}
                                style={{
                                  marginRight: 5,
                                  marginTop: 3,
                                  fontSize: "13px",
                                }}
                              />
                            </div>
                            <div className="text-sm mb-0">
                              {rate.toString()}
                            </div>
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </div>
                ) : null}
                <div className="space-x-2 flex items-center">
                  <span>{adminLabels.hideRating}</span>
                  <Switch
                    checked={showFakeRating}
                    onChange={(checked) => {
                      setShowFakeRating(checked);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <h5 className="mb-0 mt-5 ml-5 sm:ml-5  lg:ml-10 text-gray-500 font-bold text-xl">
            <strong>{adminLabels.contactInfo}</strong>
          </h5>
          <div className="mt-15 grid grid-cols-12 ">
            <div className="col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-3 mx-4 sm:mx-4 lg:mr-2">
              <InputComponent
                placeholder={adminLabels.address}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                disabled={props.loader === true ? true : false}
              />
              {address?.trim()?.length === 0 && fieldIsRequired === true ? (
                <span className="text-red-500 mt-2">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : null}
            </div>
            <div className="col-span-12  lg:col-span-3 sm:col-span-6 md:col-span-6  mx-4 sm:mx-4 lg:mr-2">
              <InputComponent
                type="email"
                placeholder={adminLabels.email}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={
                  props.loader === true
                    ? true
                    : props?.match?.params?.providerID
                    ? true
                    : false
                }
              />
              {email?.trim()?.length === 0 && fieldIsRequired === true ? (
                <span className="text-red-500 mt-2">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : (
                ""
              )}
              {email?.trim()?.length !== 0 &&
                fieldIsRequired === true &&
                !ValidateEmail(email) && (
                  <span className="text-red-500 mt-2">
                    {adminLabels.invalidEmail}
                  </span>
                )}
            </div>
            <div className="col-span-12  lg:col-span-3 sm:col-span-6 md:col-span-6  mx-4 sm:mx-4 lg:mr-2">
              <div className="flex flex-col">
                <div className="flex items-center">
                  <Dropdown
                    overlay={menu}
                    className={"flex height45 cursor-pointer"}
                    trigger={["click"]}
                    disabled={props.loader === true ? true : false}
                  >
                    <div className="flex bg-white w-max	py-2 w-full pl-2  pr-10 rounded-full items-center lg:mr-2">
                      <img
                        src={
                          countryFlag ===
                          "https://cdn.britannica.com/36/4336-004-6BD81071/Flag-Spain.jpg"
                            ? countryFlag
                            : require(`../../assets/flags/${countryFlag}.png`)
                                .default
                        }
                        style={{
                          width: 15,
                          height: 15,
                          Radius: 20,
                          margin: 2,
                        }}
                      />
                      <span className="text-sm">{countryCode}</span>
                      <DownOutlined className="ml-1" />
                    </div>
                  </Dropdown>
                  <div className="col-span-12  lg:col-span-3 sm:col-span-6 md:col-span-6  lg:mr-2">
                    <InputComponent
                      type="number"
                      placeholder={adminLabels.phoneNo}
                      value={phoneNumber}
                      onChange={(e) =>
                        setPhoneNumber(regexForPhoneNumber(e.target.value))
                      }
                      disabled={props.loader === true ? true : false}
                    />
                  </div>
                </div>
                {phoneNumber === "" && fieldIsRequired === true ? (
                  <span className="text-red-500 ">
                    {adminLabels.pleaseFillTheField}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <h5 className="mb-0 mt-5 ml-5 sm:ml-5 lg:ml-10 text-gray-500 font-bold text-xl">
            <strong>{adminLabels.personalInfo}</strong>
          </h5>
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 mx-4 sm:mx-4 mt-2  lg:mr-2  ">
              <TreeSelect
                className="multi-select-dropdown "
                allowClear={true}
                placeholder={adminLabels.state}
                treeCheckable={true}
                showCheckedStrategy={TreeSelect.SHOW_CHILD}
                style={{ width: "100%" }}
                dropdownMatchSelectWidth={true}
                dropdownStyle={{ maxHeight: "300px" }}
                onChange={(ids) => setSelectedStates(ids)}
                value={selectedStates}
                maxTagCount={2}
                maxTagPlaceholder={(omittedValues) =>
                  `+ ${omittedValues?.length} States ...`
                }
                treeData={[
                  {
                    title:
                      selectedStates?.length === statesOfSpain?.length ? (
                        <span
                          onClick={() => setSelectedStates([])}
                          style={{
                            display: "inline-block",
                            color: "#286FBE",
                            cursor: "pointer",
                          }}
                        >
                          {adminLabels.unselectAll}
                        </span>
                      ) : (
                        <span
                          onClick={() => setSelectedStates(allIds)}
                          style={{
                            display: "inline-block",
                            color: "#286FBE",
                            cursor: "pointer",
                          }}
                        >
                          {adminLabels.selectAll}
                        </span>
                      ),
                    value: "xxx",
                    disableCheckbox: true,
                    disabled: true,
                  },
                  ...statesOfSpain,
                ]}
              />
              {selectedStates?.length == 0 && fieldIsRequired === true ? (
                <span className="text-red-500 mt-2">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6  mx-4 sm:mx-4 lg:mr-2">
              <InputComponent
                placeholder={adminLabels.city}
                value={city}
                onChange={(e) =>
                  setCity(
                    regexToRemoveNumberFromTheString(
                      e.target.value
                    )?.trimStart()
                  )
                }
                disabled={props.loader === true ? true : false}
              />
              {city === "" && fieldIsRequired === true ? (
                <span className="text-red-500 mt-2">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="px-30 col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 mt-2  mx-4 sm:mx-4  lg:ml-2">
              <button
                onClick={() => {
                  setVisible(true);
                }}
                className={`mb-2 bg-white rounded-full  px-4 text-sm flex justify-between items-center  ${
                  workingHours?.length === 0 ? `py-3 w-full` : `py-2`
                } `}
                disabled={props.loader === true ? true : false}
              >
                {workingHours?.length ? (
                  <div
                    className={` flex flex-col items-start text-xs w-full ${
                      workingHours?.length === 1 ? `py-2` : `py-0`
                    }`}
                  >
                    {" "}
                    {workingHours?.map((hour, index) => {
                      return hour?.isFriday === false ? (
                        <div className=" w-80 flex justify-between" key={index}>
                          <span>
                            {adminLabels.monToThu} : {hour?.start} - {hour?.end}
                          </span>
                          {hour?.monTHuLunchHours !== null && (
                            <span className="ml-2">
                              {adminLabels.lunch} :{" "}
                              {hour?.monTHuLunchHours?.start} -{" "}
                              {hour?.monTHuLunchHours?.end}
                            </span>
                          )}
                        </div>
                      ) : (
                        <div className=" w-80 flex justify-between">
                          <span>
                            {adminLabels.fri} : {hour?.start} - {hour?.end}
                          </span>
                          {hour?.fridayLunchHours !== null && (
                            <span className="ml-2">
                              {adminLabels.lunch} :{" "}
                              {hour?.fridayLunchHours?.start} -{" "}
                              {hour?.fridayLunchHours?.end}
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <span className=" pb-1 text-gray-400 font-medium">
                    {adminLabels.workingHours}{" "}
                  </span>
                )}

                <IoIosArrowForward className="text-gray-400 font-medium" />
              </button>
              {workingHours?.length === 0 && fieldIsRequired === true ? (
                <span className="text-red-500 mt-2">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>

          <h5 className="mb-0 mt-5 ml-5 sm:ml-5 lg:ml-10 text-gray-500 font-bold text-xl flex justify-start item-center mb-3">
            <RiInformationLine className="mr-4 mt-1 text-black" />
            <strong>{adminLabels.information}</strong>
          </h5>
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-6 sm:col-span-6 md:col-span-6 mx-4 sm:mx-4 lg:mr-2">
              <ReactQuill
                className={"richTextBox "}
                value={info || ""}
                onChange={setInfo}
                disabled={props.loader === true ? true : false}
                placeholder={adminLabels.informationEng}
              />
              {!validateDescription(info) && fieldIsRequired === true ? (
                <span className="text-red-500 mt-2">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : null}
            </div>
            <div className=" col-span-12 lg:col-span-6 sm:col-span-12 md:col-span-12 mx-2 sm:mx-2 lg:mx-0 ">
              <ReactQuill
                className={"richTextBox "}
                value={infoEsp || ""}
                onChange={setInfoEsp}
                placeholder={adminLabels.informationEsp}
              />
              {!validateDescription(infoEsp) && fieldIsRequired === true ? (
                <span className="text-red-500 mt-2">
                  {adminLabels.pleaseFillTheField}
                </span>
              ) : null}
            </div>
          </div>
          <div className="w-full sm:w-3/12 mt-3 ml-2 pr-4">
            <ButtonComponent
              className="bg-primary"
              title={adminLabels.save}
              onClick={
                props?.match?.params?.providerID
                  ? onEditProvider
                  : onSubmitProvider
              }
              disabled={props.loader === true ? true : false}
              loader={props.loader === true ? true : false}
            />
          </div>
          <ModalComponent
            filterModal={visible}
            modalTitle={adminLabels.setWorkingHours}
            closeModal={() => setVisible(false)}
            footer={false}
          >
            <div className="mt-5">
              <div className="w-full flex flex-col">
                <h4 className="text-gray-500 font-black">
                  {adminLabels.fromMondayToThursday}
                </h4>
                <div className="w-full flex justify-between items-center mt-4 ">
                  <TimePicker
                    minuteStep={15}
                    size="small"
                    className="border-radius20 timePicker ant-time-picker-working-hour"
                    style={{ width: "40%" }}
                    value={moment(startMTTH, "HH:mm:ss")}
                    onSelect={onChangeMTTHStart}
                    use12Hours={false}
                    format={"HH:mm"}
                  />
                  <span className="font-black ">{adminLabels.to}</span>
                  <TimePicker
                    minuteStep={15}
                    size="small"
                    style={{ width: "40%" }}
                    className="border-radius20 timePicker"
                    value={moment(endMTTH, "HH:mm:ss")}
                    onSelect={onChangeMTTHEnd}
                    use12Hours={false}
                    format={"HH:mm"}
                    disabled={startMTTH === "00:00:00"}
                  />
                </div>
                <div className="flex justify-center mt-2 items-center">
                  <span className="text-red-500">{errorMessageOfMTTH}</span>
                </div>
                <div className="w-full flex  items-center mt-1 space-x-1 ">
                  <span>{adminLabels.addLunchHours}</span>
                  <span>
                    <input
                      type="checkbox"
                      value={checkedLunchMTTH}
                      checked={checkedLunchMTTH}
                      onChange={(e) => setCheckedLunchMTTH(e.target.checked)}
                    />
                  </span>
                </div>
                {checkedLunchMTTH && (
                  <div className="w-full flex justify-between items-center mt-1 ">
                    <TimePicker
                      disabledHours={() => [
                        ...disabledMonThuHoursStart,
                        ...disabledMonThuHoursEnd,
                      ]}
                      minuteStep={15}
                      size="small"
                      className="border-radius20 timePicker ant-time-picker-working-hour"
                      style={{ width: "40%" }}
                      value={moment(startLunchMTTH, "HH:mm:ss")}
                      onSelect={onChangeLunchMTTHStart}
                      use12Hours={false}
                      format={"HH:mm"}
                    />
                    <span className="font-black ">{adminLabels.to}</span>
                    <TimePicker
                      disabledHours={() => [
                        ...disabledMonThuHoursStart,
                        ...disabledMonThuHoursEnd,
                      ]}
                      minuteStep={15}
                      size="small"
                      style={{ width: "40%" }}
                      className="border-radius20 timePicker"
                      value={moment(endLunchMTTH, "HH:mm:ss")}
                      onSelect={onChangeLunchMTTHEnd}
                      defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                      use12Hours={false}
                      format={"HH:mm"}
                      disabled={startLunchMTTH === "00:00:00"}
                    />
                  </div>
                )}

                <div className="flex justify-center mt-2 items-center">
                  <span className="text-red-500">
                    {errorMessageOfLunchMTTH}
                  </span>
                </div>
              </div>
              <div className="w-full flex flex-col mt-5">
                <h4 className="text-gray-500 font-black">
                  {adminLabels.friday}
                </h4>

                <div className="w-full flex justify-between items-center mt-4">
                  <TimePicker
                    minuteStep={15}
                    size="small"
                    className="border-radius20 timePicker"
                    style={{ width: "40%" }}
                    value={moment(startFri, "HH:mm:ss")}
                    onSelect={onChangeFriStart}
                    use12Hours={false}
                    format={"HH:mm"}
                  />
                  <span className="font-black "> To</span>
                  <TimePicker
                    minuteStep={15}
                    size="small"
                    style={{ width: "40%" }}
                    className="border-radius20 timePicker"
                    value={moment(endFri, "HH:mm:ss")}
                    onSelect={onChangeFriEnd}
                    disabled={startFri === "00:00:00"}
                    use12Hours={false}
                    format={"HH:mm"}
                  />
                </div>
                <div className="flex justify-center mt-2 items-center">
                  <span className="text-red-500">{errorMessageOfFri}</span>
                </div>
              </div>
              <div className="w-full flex  items-center  mt-1 space-x-1 ">
                <span>{adminLabels.addLunchHours}</span>
                <span>
                  <input
                    value={checkedLunchFri}
                    checked={checkedLunchFri}
                    type="checkbox"
                    onChange={(e) => setCheckedLunchFri(e.target.checked)}
                  />
                </span>
              </div>
              {checkedLunchFri && (
                <div className="w-full flex justify-between items-center mt-1 ">
                  <TimePicker
                    disabledHours={() => [
                      ...disabledFriHoursStart,
                      ...disabledFriHoursEnd,
                    ]}
                    minuteStep={15}
                    size="small"
                    className="border-radius20 timePicker ant-time-picker-working-hour"
                    style={{ width: "40%" }}
                    value={moment(startLunchFri, "HH:mm:ss")}
                    onSelect={onChangeLunchFriStart}
                    use12Hours={false}
                    format={"HH:mm"}
                  />
                  <span className="font-black "> To</span>
                  <TimePicker
                    disabledHours={() => [
                      ...disabledFriHoursStart,
                      ...disabledFriHoursEnd,
                    ]}
                    minute
                    minuteStep={15}
                    size="small"
                    style={{ width: "40%" }}
                    className="border-radius20 timePicker"
                    value={moment(endLunchFri, "HH:mm:ss")}
                    onSelect={onChangeLunchFriEnd}
                    use12Hours={false}
                    format={"HH:mm"}
                    disabled={startLunchFri === "00:00:00"}
                  />
                </div>
              )}
              <div className="flex justify-center mt-2 items-center">
                <span className="text-red-500">{errorMessageOfLunchFri}</span>
              </div>
              <button
                className="w-full bg-primary rounded-full py-3 text-white mt-5 "
                onClick={pushWorkingHoursDetail}
                disabled={
                  mondayTohursdayObj === null && fridayObj === null
                    ? true
                    : false
                }
              >
                {adminLabels.save}
              </button>
            </div>
          </ModalComponent>
        </div>
      </Spin>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    loader: state.loaderToggle.loaderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLogoutUser: (data) => {
      dispatch(LogoutUserAction(data));
    },
    getProvidersList: (props) => {
      dispatch(FetchProvidersAction(props));
    },
    removeBeneficiaries: () => {
      dispatch(DeleteBeneficiariesAtLogoutTime());
    },
    removeProviders: () => {
      dispatch(DeleteProvidersAtLogoutTime());
    },
    removeServiceAreas: () => {
      dispatch(DeleteServiceAreasAtLogoutTime());
    },
    removeBranches: () => {
      dispatch(DeleteBranchesAtLogoutTime());
    },
    removeServices: () => {
      dispatch(DeleteServicesAtLogoutTime());
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAdminServiceProvider);
