import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import {
  child,
  push as fbPush,
  ref,
  runTransaction,
  serverTimestamp,
  update,
} from "firebase/database";
import React, { useEffect, useState } from "react";
import { BsCheckCircle, BsCircle } from "react-icons/bs";
import { RiArrowDropLeftLine } from "react-icons/ri";
import ReactQuill from "react-quill";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ModalComponent } from "../../components";
import { Errors, Success } from "../../components/antdToast";
import ButtonComponent from "../../components/ButtonComponent";
import { Remove_Data_Form_Provider_Reducers } from "../../components/dataRemoveOnProviderLogout";
import InputComponent from "../../components/InputComponent";
import TextAreaComponent from "../../components/textArea";
import {
  AssignOrderToWorker,
  GetOrdersById,
  GetWorkersByCapacity,
  OrderReassignMethod,
  SendOrderMessageMethod,
} from "../../config/api";
import { firebaseDatabase } from "../../config/Firebase";
import { providerLabels } from "../../config/label/providerLabels";
import { Images } from "../../constant/Images";
import {
  DeleteWorkerAtLogoutTime,
  FetchWorkersAction,
} from "../../Redux/actions/fetchWorkersAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { ProviderLogoutAction } from "../../Redux/actions/logoutAction";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const OrderAssgin = (props) => {
  const { goBack, push } = useHistory();
  const orders = useSelector((state) => state?.serviceProviderAllOrders?.orders?.orders);

  const [reassign, setReassign] = useState(false);
  const [assignManager, setAssignManager] = useState(false);
  const [workerOfCapacity, setWorkerOfCapacity] = useState([]);
  const [workerProviderId, setWorkerProviderId] = useState("");
  const [reason, setReason] = useState("");
  const [requestInfoDoc, setRequestInfoDoc] = useState(false);
  const [value, setValue] = useState("");
  const [fieldIsRequired, setFieldIsrequired] = useState(false);
  const [searchWorker, setSearchWorker] = useState("");
  const [selectedOrder, setSelectedOrder] = useState({});
  const [message, setMessage] = useState("");
  const onChecked = (e, i) => {
    const array = workerOfCapacity?.map((a, b) => {
      if (a?.service_provider_id === e?.service_provider_id) {
        setWorkerProviderId(a?.service_provider_id);
        return {
          ...a,
          name: a?.name,
          checked: !a.checked,
        };
      } else {
        return {
          ...a,
          name: a?.name,
          checked: false,
        };
      }
    });
    setWorkerOfCapacity(array);
  };

  useEffect(() => {
    getWorkersOfCapacity();
    getOrdersByProvider();
  }, []);

  useEffect(() => {
    const serviceProviderOrderCount = orders?.filter((item) => item.worker_provider_id !== null && ["pending", "dispute"]?.includes(item?.status))
   
    setWorkerOfCapacity((prev) => prev?.map((item) => {
      if(item?.is_worker === 0) {
        return{
          ...item,
          service_orders_count: Number(serviceProviderOrderCount?.length) || 0,
        }
      }else {
        return {
          ...item,
          service_orders_count: Number(item?.orders_dispute_count) + Number(item?.orders_pending_count) || 0
        }
        
      }
    }))
  },[orders])

  const sendMessageToUserThroughFirebase = (message) => {
    const postData = {
      is_provider_message: "1",
      service_provider_id: selectedOrder?.service_provider?.service_provider_id,
      service_provider: selectedOrder?.service_provider,
      admin_id: null,
      admin: null,
      is_admin_message: "0",
      user_id: selectedOrder?.user_id,
      user: selectedOrder?.user,
      message: [
        {
          message: message,
          documents: null,
        },
      ],
      time: serverTimestamp(),
    };
    // Get a key for a new Post.
    const newPostKey = fbPush(child(ref(firebaseDatabase), "posts")).key;
    runTransaction(
      ref(firebaseDatabase, "orders/" + props.match.params.id + "/userUnread"),
      (post) => {
        if (post === null) return 1;
        return post + 1;
      }
    );
    runTransaction(
      ref(firebaseDatabase, "orders/" + props.match.params.id + "/adminUnread"),
      (post) => {
        if (post === null) return 1;
        return post + 1;
      }
    );
    // Write the new post's data simultaneously in the posts list and the user's post list.
    const updates = {};

    // updates['/posts/' + newPostKey] = postData;
    updates["/orders/" + props.match.params.id + "/messages/" + newPostKey] =
      postData;

    push(`/add-my-message-order/${props.match.params.id}`);
    return update(ref(firebaseDatabase), updates);
  };
  const sendMessageToUser = () => {
    if (message !== "") {
      let obj = {
        order_id: props.match.params.id,
        is_further: 1,
        is_provider_message: 1,
        message: JSON.stringify([
          {
            message: message === "" ? null : message,
            //documents: urls.length === 0 ? null : urls,
            documents: null,
          },
        ]),
      };

      SendOrderMessageMethod(
        obj,
        (success) => {
          if (success.status === 200) {
            props.setLoaderState(false);
            setRequestInfoDoc(false);
            Success(success.data.response.message);

            sendMessageToUserThroughFirebase(message);
            setMessage("");
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            Remove_Data_Form_Provider_Reducers(props);
            push("/");
          } else if (error?.response?.status === 409) {
            Errors(error?.response?.data?.error?.message);
            props.setLoaderState(false);
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
          }
        }
      );
    }
  };
  const getWorkersOfCapacity = () => {
    const serviceProviderOrderCount = orders?.filter((item) => item.worker_provider_id !== null && ["pending", "dispute"]?.includes(item?.status))
   
    GetWorkersByCapacity(
      (success) => {
        if (success.status === 200) {
          if (success.data.response.detail !== null) {
            let mappedOut = success?.data?.response?.detail
              ?.filter((item) => item?.status === 1)
              ?.map((item, index) => {
                return {
                  ...item,
                  no: index + 1,
                  checked: false,
                  service_orders_count: Number(item?.orders_dispute_count) + Number(item?.orders_pending_count) || 0
                };
              });
            let serviceProvidervalue = JSON?.parse(
              JSON?.parse(localStorage.getItem("persist:root"))
                ?.providerLoginDetail
            )?.loggedUserDetailOfProvider?.admin;

            mappedOut.unshift({...serviceProvidervalue , service_orders_count : serviceProviderOrderCount?.length});
            setWorkerOfCapacity(mappedOut);
          }
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const getOrdersByProvider = (id) => {
    GetOrdersById(
      props.match.params.id,
      (success) => {
        if (success.status === 200) {
          if (success.data.response.detail !== null) {
            setSelectedOrder(success?.data?.response?.detail);
          }
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const assignOrderToWorker = () => {
    if (workerProviderId !== "") {
      let obj = {
        order_id: props.match.params.id,
        worker_provider_id: workerProviderId,
      };
      props.setLoaderState(true);
      AssignOrderToWorker(
        obj,
        (success) => {
          if (success.status === 200) {
            props.setLoaderState(false);
            Success(success.data.response.message);
            props.getWorkers(props);
            setTimeout(() => {
              setAssignManager(false);
            }, 500);
            getWorkersOfCapacity();
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            Remove_Data_Form_Provider_Reducers(props);
            push("/");
          } else if (error?.response?.status === 409) {
            Errors(error?.response?.data?.error?.message);
            props.setLoaderState(false);
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
          }
        }
      );
    } else {
      Errors("please select worker for order assign");
    }
  };

  const noManagementCapacityMethod = () => {
    if (reason !== "") {
      let obj = {
        order_id: props.match.params.id,
        reason,
      };
      props.setLoaderState(true);
      OrderReassignMethod(
        obj,
        (success) => {
          if (success.status === 200) {
            props.setLoaderState(false);
            setReason("");
            Success(success.data.response.message);
            setTimeout(() => {
              setReassign(false);
            }, 1000);
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            Remove_Data_Form_Provider_Reducers(props);
            push("/");
          } else if (error?.response?.status === 409) {
            Errors(error?.response?.data?.error?.message);
            props.setLoaderState(false);
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
          }
        }
      );
    } else {
    }
  };

  return (
    <section>
      <div className="flex justify-between mt-20 ">
        <div className="pl-5 flex self-center justify-center items-center">
          <div
            onClick={() => goBack()}
            className="flex text-gray-400 items-center cursor-pointer"
          >
            <RiArrowDropLeftLine />
            <span> {providerLabels.back}</span>
          </div>
          <h3 className="ml-10	">
            {" "}
            {providerLabels.orderID}: {props.match.params.id}
          </h3>
        </div>
      </div>
      <div className="grid grid-cols-5 ">
        <div className="col-start-3 col-end-3">
          <div className="my-5 text-center">
            <label className="lab2">
              {providerLabels.ifInfoOrDocumentationIsIncomplete}
            </label>

            <ButtonComponent
              onClick={() => setRequestInfoDoc(true)}
              className="bg-primary my-5"
              title={providerLabels.requestInfoOrDocumentation}
            />
          </div>
          <div className="my-5 text-center">
            <label className="lab2 ">
              {providerLabels.ifAllInfoIsCorrectAndComplete}
            </label>
            <ButtonComponent
              onClick={() => setAssignManager(true)}
              className="bg-primary mt-5 mb-2"
              title={providerLabels.assignManager}
            />
            {/* <ButtonComponent
              onClick={() => setReassign(true)}
              className="bg-primary "
              title={providerLabels.noManagementCapacity}
            /> */}
          </div>
        </div>
      </div>
      <ModalComponent
        filterModal={assignManager}
        closeModal={() => setAssignManager(false)}
        modalTitle={providerLabels.assignWorker}
      >
        <div>
          <div className="justify-center">
            <div className="grid grid-cols-6">
              <div className="col-span-6 lg:col-span-6 sm:col-span-6 md:col-span-6 shadow-md rounded-full">
                <InputComponent
                  placeholder={providerLabels.search}
                  onChange={(e) => setSearchWorker(e.target.value)}
                  value={searchWorker}
                />
              </div>
            </div>
            <div className="flex items-center justify-between mt-4 mb-1">
              <span className="lab2">{providerLabels.workers}</span>
              <span className="lab2 mr-5">{providerLabels.orderCapicity}</span>
            </div>

            <div className="select-serviceProvider-modal-container h-96 overflow-y-scroll px-2 py-2">
              {workerOfCapacity?.length
                ? workerOfCapacity
                    ?.filter((item) => {
                      return item?.name?.toLowerCase()?.includes(searchWorker);
                    })
                    ?.map((t, i) => {
                      return (
                        <div
                          className="flex justify-between text-left py-5 "
                          key={i}
                        >
                          <div className="flex items-center justify-between">
                            <img
                              width={"40px"}
                              src={
                                t.avatar !== null
                                  ? t.avatar
                                  : Images.profile.default
                              }
                              alt="logo alt"
                            />
                            <span className="pl-4 pr-1 capitalize">
                              {t.name}
                            </span>
                          </div>
                          {t.checked ? (
                            <div className="flex items-center">
                              <span className="pr-12">{t.service_orders_count || 0}</span>
                              <BsCheckCircle
                                size={20}
                                color={"#55b319"}
                                onClick={() => onChecked(t)}
                              />
                            </div>
                          ) : (
                            <div className="flex items-center">
                              <span className="pr-12">{t.service_orders_count || 0}</span>
                              <BsCircle
                                size={20}
                                color={"#55b319"}
                                onClick={() => onChecked(t)}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })
                : null}
            </div>
            <button
              className="w-full bg-primary rounded-full py-3 text-white mt-4"
              onClick={assignOrderToWorker}
              disabled={props.loader === true ? true : false}
            >
              {providerLabels.finalizeAssignation}
              {props.loader === true ? <Spin indicator={antIcon} /> : ""}
            </button>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        filterModal={reassign}
        closeModal={() => setReassign(false)}
        modalTitle={providerLabels.reassignButton}
      >
        <div>
          <div className="justify-center">
            <div className=" col-span-12 lg:col-span-6 sm:col-span-12 md:col-span-12">
              <form>
                <TextAreaComponent
                  className="p-2 rounded-lg w-full shadow-md "
                  placeholder={providerLabels.reasonForReassignment}
                  rows="6"
                  value={reason}
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                ></TextAreaComponent>
              </form>
            </div>
            <button
              className="w-full bg-primary rounded-full py-3 text-white mt-4"
              disabled={props.loader === true ? true : false}
              onClick={() => noManagementCapacityMethod()}
            >
              {providerLabels.reassign}
              {props.loader === true ? <Spin indicator={antIcon} /> : ""}
            </button>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        filterModal={requestInfoDoc}
        closeModal={() => setRequestInfoDoc(false)}
        modalTitle={providerLabels.requestInfoDocument}
      >
        <div>
          <div className="justify-center">
            <div className=" col-span-12 lg:col-span-6 sm:col-span-12 md:col-span-12">
              <ReactQuill
                className={"richTextBox shadow-lg w-full"}
                value={message}
                onChange={setMessage}
                // value={value}
                // onChange={setValue}
              />
              {value === "" && fieldIsRequired === true ? (
                <span className="text-red-500">Please Add Info!</span>
              ) : (
                ""
              )}
            </div>
            <button
              className="w-full bg-primary rounded-full py-3 text-white mt-4"
              disabled={props.loader === true ? true : false}
              onClick={() => sendMessageToUser()}
            >
              {providerLabels.request}
              {props.loader === true ? <Spin indicator={antIcon} /> : ""}
            </button>
          </div>
        </div>
      </ModalComponent>
    </section>
  );
};
const mapStateToProps = (state) => {
  return {
    showWorkers: state.workerList.workers,
    loader: state.loaderToggle.loaderState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
    getWorkers: (props) => {
      dispatch(FetchWorkersAction(props));
    },
    removeWorkerList: () => {
      dispatch(DeleteWorkerAtLogoutTime());
    },
    setProviderLogout: () => {
      dispatch(ProviderLogoutAction());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderAssgin);
