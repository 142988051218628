export const EndPoints = {
  Admin_Login: "admin_login",
  Provider_Login: "provider_login",
  Add_Service_Provider: "add_service_provider",
  Add_beneficiary: "add_beneficiary",
  Get_Beneficiaries: "get_beneficiaries",
  Add_Service_Area: "add_service_area",
  Provider_Forgot_Password: "provider_forgot_password",
  Provider_Verify_Code: "provider_verify_code",
  Provider_Reset_Password: "provider_reset_password",
  Provider_Change_Password: "provider_change_password",
  Provider_Send_Support_Message: "send_support_message",
  Add_Branch: "add_branch",
  Add_Branch_Service: "add_service",
  Add_Promo_Code: "add_promo_code",
  Change_Promo_Code_Status: "change_promo_code_status",
  Assign_Order_To_Worker: "assign_order_to_worker",
  Add_Service_Form: "add_service_form",
  Add_Worker: "add_worker",
  Edit_Worker: "edit_worker",
  Add_Faq: "add_faq",
  Get_Service_Area_By_Beneficiary_Id: "get_service_areas_by_beneficiary_id",
  Get_Service_Area: "get_service_areas",
  Get_Branches: "get_branches",
  Get_Branches_By_Service_Area_Id: "get_branches_by_service_area_id",
  Get_Branches_By_Service_Area_Id_And_Beneficiary:
    "get_branches_by_service_area_and_beneficiary_id",
  Get_Service_Provider: "get_providers",
  Get_Service_Provider_Details: "get_provider_detail",
  Get_Service_Worker: "get_all_workers",
  Get_Service_Worker_Details: "get_worker_detail",
  Get_Services: "get_services",
  Get_Service_Forms: "get_service_forms",
  Delete_Service_Form: "delete_service_form",
  Get_App_Users: "get_users",
  Get_Get_Users_Detail: "get_user_detail",
  Get_Workers: "get_workers",
  Get_Orders_Of_Provider: "get_provider_orders",
  Get_Workers_Capacity: "get_workers_capacity",
  Get_Promo_Codes: "get_promo_codes",
  Get_Faqs: "get_faqs",
  Get_Co_Workers_Orders: "get_co_workers_orders",
  Get_Messages: "get_messages",
  Edit_Service_Area: "edit_service_area",
  Edit_Beneficiary: "edit_beneficiary",
  Edit_Branch: "edit_branch",
  Delete_Branch: "delete_branch",
  Change_Branch_Status: "change_branch_status",
  Edit_Service: "edit_service",
  Delete_Service: "delete_service",
  Change_Service_Status: "change_service_status",
  Edit_Service_Forms: "edit_service_form",
  Edit_Faq: "edit_faq",
  Delete_Faq: "delete_faq",
  Send_Order_Message: "send_order_message",
  Upload_File: "upload_file",
  Order_Status_Progress: "order_status_progress",
  Get_Order: "get_order",
  Order_Bill: "order_bill",
  Reassign_Order: "reassign_order",
  Get_All_Orders: "get_all_orders",
  Order_Reassign_To_Provider: "order_reassign_to_provider",
  Get_All_Help_Messages: "get_help_messages",
  Get_All_Work_With_Us: "get_work_us",
  Get_All_provider_Supports: "get_provider_supports",
  Add_Additonal_Budget: "add_budget",
  Edit_Additonal_Budget: "edit_budget",
  Change_User_Status: "change_user_status",
  Change_Service_Provider_Status: "change_provider_status",
  Change_Service_Provider_No_Management_Capacity_Status:
    "change_service_provider_status",
  Get_Admin_Dashboard_Data: "dashboard_data",
  Get_Additional_Budgets: "get_additional_budgets",
  Get_Payouts: "get_payouts",
  Order_Payout_Release: "order_payout_release",
  Budget_Payout_Release: "budget_payout_release",
  Get_Earnings: "get_earnings",
  Get_Sub_Admins: "get_sub_admins",
  Add_Sub_Admin: "add_sub_admin",
  Get_Provider_Additional_Budgets: "get_provider_additional_budgets",
  Get_Worker_Additional_Budgets: "get_worker_additional_budgets",

  Edit_Service_Provider: "edit_service_provider",
  Edit_Sub_Admin: "edit_sub_admin",
  Get_Non_Assign_Service_Forms: "get_non_assign_service_forms",
  Get_Notifications_By_Order_Id: "get_notifications_by_order_id",
  Dispute_Order_Message: "dispute_order_message",
  Get_Dispute_Messages: "get_dispute_messages",
  Order_Dispute_Continue: "order_dispute_continue",
  Order_Dispute_Cancel: "order_dispute_cancel",
  Order_Block: "order_block",
  Order_UnBlock: "order_unblock",
  Get_Order_Working_Hours: "get_order_working_hours",
  Get_Provider_Detail_Info: "get_provider_detail_info",
  Get_Bill_Logs_Providers: "get_bill_logs_providers",
  Get_Bill_Logs: "get_bill_logs",
  Help_Message_Reply: "help_message_reply",
  Work_Us_Reply: "work_us_reply",
  Order_Reply: "order_reply",
  Order_Reply_Provider: "order_reply_provider",
  Order_reply_worker: "order_reply_worker",
  get_menu_counts: "get_menu_counts",
  get_provider_messages: "get_provider_messages",
  get_worker_provider_messages: "get_worker_provider_messages",
  Change_Review_Status: "change_review_status",
  Additional_Budget_Read: "additional_budget_read",
  Get_All_Messages: "get_all_messages",
  Additional_Budget_Bill: "additional_budget_bill",
  Get_Additional_Budget_Detail: "get_additional_budget_detail",
  Budget_Dispute_Continue: "budget_dispute_continue",
  Budget_Dispute_Cancel: "budget_dispute_cancel",
};
