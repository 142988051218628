const initialState = {
  serviceForms: [],
};

const GetServiceFormsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SERVICE_FORMS": {
      return {
        ...state,
        serviceForms: action.payload,
      };
    }
    case "REMOVE_SERVICE_FORMS": {
      return {
        ...state,
        serviceForms: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default GetServiceFormsReducer;
