import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { BsCheckCircle, BsCircle } from "react-icons/bs";
import { RiArrowDropLeftLine, RiArrowDropRightLine } from "react-icons/ri";
import { Redirect, useHistory } from "react-router-dom";
import { flags } from "../../assets/flags";
import { CountryCodes } from "../../assets/flags/CountryCodes";
import { Errors, Success } from "../../components/antdToast";
import ButtonComponent from "../../components/ButtonComponent";
import InputComponent from "../../components/InputComponent";
import { AddSubAdminMethod, EditSubAdminMethod } from "../../config/api";

import { adminLabels } from "../../config/label/adminLabels";
import {
  functionToConvertStringToCamelCase,
  regexForPhoneNumber,
  ValidateEmail,
} from "../../config/utils";
import "./addsubadmin.css";
const { AC } = flags;
function AddSubAdmin(props) {
  const { goBack, push } = useHistory();
  // Declare Variable States
  const [fullName, setFullName] = useState("");
  const [visible, setVisible] = useState(false);
  // const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [countryCode, setCountryCode] = useState("+34");
  const [countryFlag, setCountryFlag] = useState(
    "https://cdn.britannica.com/36/4336-004-6BD81071/Flag-Spain.jpg"
  );
  const [countryCodeState, setCountryCodeState] = useState(
    CountryCodes.map((country) => {
      return {
        ...country,
        name: country.name.toLowerCase(),
      };
    })
  );
  const [fieldIsRequired, setFieldIsRequired] = useState(false);
  const [features, setFeatures] = useState([
    { menuId: "1", name: "Dashboard", checked: false },
    // {menuId:"1" ,name: "Admin of Service Provider Account Management", checked: false },
    { menuId: "2", name: "User Account Management", checked: false },
    {
      menuId: "3",
      name: "Admin of Service Provider Account Management",
      checked: false,
    },

    {
      menuId: "4",
      name: "Worker of Service Providers Account Management",
      checked: false,
    },
    { menuId: "5", name: "Categories Management", checked: false },
    { menuId: "6", name: "Bookings Management", checked: false },
    { menuId: "7", name: "Budget Response", checked: false },
    { menuId: "8", name: "Work with us Requests Management", checked: false },
    { menuId: "9", name: "Promo Codes", checked: false },
    { menuId: "10", name: "My Logs", checked: false },
    { menuId: "11", name: "Help", checked: false },
    { menuId: "112", name: "messages", checked: false },
    { menuId: "12", name: "Payouts", checked: false },
    { menuId: "13", name: "My Earnings", checked: false },
    { menuId: "14", name: "Dynamic Forms", checked: false },
    // { menuId: "15", name: "Report and Anaytics", checked: false },
    { menuId: "16", name: "faqs", checked: false },
    { menuId: "17", name: "payments", checked: false },
  ]);
  const [functionalityModal, setFunctionalityModal] = useState(false);
  const [onRoute, setOnRoute] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loader, setLoader] = useState(false);
  const [searchCountry, setSearchCountry] = useState("");

  const handleMenuClick2 = (e) => {
    setCountryCode("+" + e.key);
    if (e.key === "3") {
      setVisible(false);
    }
  };
  const { Option } = Select;

  useEffect(() => {
    if (props?.match?.params?.subAdminID) {
      props?.location?.state === undefined &&
        push("/admin/sub-admin-account-manager");

      let subAdmin = props?.location?.state?.subAdmin;

      setFullName(subAdmin?.fullname);
      setEmail(subAdmin?.email);
      setAddress(subAdmin?.address);
      setCountryCode(subAdmin?.phone.split("-")[0]);
      setPhoneNumber(subAdmin?.phone.split("-")[1]);
      let flag = countryCodeState
        .map((cc) => cc)
        ?.filter((country, index) => {
          return (
            country?.phoneCode === subAdmin?.phone.split("-")[0].toString()
          );
        });

      setCountryFlag(!flag.length ? "ES" : flag[0].code);
      let mappedSubAdminRoles = features?.map((role) => {
        return {
          ...role,
          checked: subAdmin?.roles?.some(
            (a) => a?.menuId === role?.menuId && a?.checked === true
          ),
        };
      });
      setFeatures(mappedSubAdminRoles);
    }
  }, []);
  const onKeyDown = (e) => {
    if (e.keyCode === 8 || e.keyCode == 46) {
      setCountryCodeState(
        CountryCodes.map((country) => {
          return {
            ...country,
            name: country.name.toLowerCase(),
          };
        })
      );
    }
  };
  const countryHandleChange = (e) => {
    setSearchCountry(e.target.value);

    if (e.target.value === "" || e.target.value.trim().length === 0) {
      setCountryCodeState(
        CountryCodes.map((country) => {
          return {
            ...country,
            name: country.name.toLowerCase(),
          };
        })
      );
    } else {
      setCountryCodeState(
        countryCodeState.filter((country) => {
          return country.name.includes(e.target.value.toLowerCase());
        })
      );
    }
  };
  const countryCodeBlur = () => {
    setSearchCountry("");
  };
  const menu = (
    <Menu className="menu-class country-menu " onClick={handleMenuClick2}>
      <div>
        <input
          className="pl-2"
          type="text"
          placeholder={adminLabels.search}
          value={searchCountry}
          onChange={countryHandleChange}
          onKeyDown={onKeyDown}
          onBlur={countryCodeBlur}
        />
      </div>
      <Menu.Divider />
      {countryCodeState.map((country, index) => {
        var intKey = parseInt(country.phoneCode);
        return (
          <Menu.Item
            className="bg-white"
            key={intKey}
            onClick={() => setCountryFlag(country.code)}
          >
            <div className="flex bg-white w-max	py-2 w-full pl-2 pr-10 rounded-full items-center">
              <img
                src={require(`../../assets/flags/${country.code}.png`).default}
                style={{ width: 15, height: 15, margin: 2, Radius: 20 }}
              />
              <span>{country.phoneCode}</span>
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const onFeatured = () => {
    setFunctionalityModal(true);
  };
  const onChecked = (e, i) => {
    const array = features.map((a, b) => {
      if (b === i) {
        return {
          menuId: a.menuId,
          name: a?.name,
          checked: !a.checked,
        };
      } else {
        return {
          menuId: a?.menuId,
          name: a?.name,
          checked: a?.checked,
        };
      }
    });
    setFeatures(array);
  };
  const onClick = (e) => {
    setOnRoute(true);
  };

  if (onRoute) {
    return <Redirect to="/admin-profile" />;
  }
  const addSubAdmin = () => {
    if (
      features?.filter((item) => item.checked === false)?.length !==
        features?.length &&
      phoneNumber !== "" &&
      fullName !== "" &&
      email !== "" &&
      address !== "" &&
      ValidateEmail(email)
    ) {
      let formdata = new FormData();
      formdata.append("name", fullName);
      formdata.append("email", email);
      formdata.append("phone", `${countryCode}-${phoneNumber}`);

      formdata.append("address", address);
      formdata.append("roles", JSON.stringify(features));
      setLoader(true);
      AddSubAdminMethod(
        formdata,
        (res) => {
          // Errors, Success
          if (res.status === 200) {
            Success(res.data.response.message);
            push("/admin/sub-admin-account-manager");
            setLoader(false);
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            Errors(error?.response?.data?.error?.message);
            // setFieldIsRequired(false);
            setLoader(false);
          } else {
            Errors("Network Error");
            // setFieldIsRequired(false);
            setLoader(false);
          }
        }
      );
    } else {
      setFieldIsRequired(true);
    }
  };

  const editSubAdmin = () => {
    if (
      features?.filter((item) => item.checked === false)?.length !==
        features?.length &&
      phoneNumber !== "" &&
      fullName !== "" &&
      email !== "" &&
      address !== "" &&
      ValidateEmail(email)
    ) {
      let formdata = new FormData();
      formdata.append("name", fullName);
      formdata.append("email", email);
      formdata.append("phone", `${countryCode}-${phoneNumber}`);

      formdata.append("address", address);
      formdata.append("roles", JSON.stringify(features));
      setLoader(true);
      EditSubAdminMethod(
        formdata,
        (res) => {
          // Errors, Success
          if (res.status === 200) {
            Success(res.data.response.message);
            push("/admin/sub-admin-account-manager");
            setLoader(false);
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            Errors(error?.response?.data?.error?.message);
            // setFieldIsRequired(false);
            setLoader(false);
          } else {
            Errors("Network Error");
            // setFieldIsRequired(false);
            setLoader(false);
          }
        }
      );
    } else {
      setFieldIsRequired(true);
    }
  };
  return (
    <section className="mt-20">
      <div className="flex self-center items-center ml-12 pt-10 pb-5">
        <div
          onClick={() => goBack()}
          className="flex text-gray-400 items-center cursor-pointer"
        >
          <RiArrowDropLeftLine />
          <span>{adminLabels.back}</span>
        </div>
        <h3 className="font-bold text-lg mb-0 ml-10">
          {props?.match?.params?.subAdminID
            ? adminLabels.edit
            : adminLabels.add}{" "}
          {adminLabels.subAdmin}
        </h3>
      </div>
      <div className="container mx-auto  flex justify-center items-center lg:items-start flex-col lg:px-28">
        <h5 className="mb-3  ml-5 text-xl text-gray-500">
          <strong>{adminLabels.personalDetails}</strong>
        </h5>
        <div className="mt-5 grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-12 pr-0 sm:pr-0">
          <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 lg:pr-2">
            <InputComponent
              value={fullName}
              placeholder={adminLabels.fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
            {fullName === "" && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">
                {adminLabels.pleaseFillTheField}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 lg:pr-2">
            <InputComponent
              value={email}
              className="row-span-2"
              placeholder={adminLabels.email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={props?.match?.params?.subAdminID ? true : false}
            />
            {email === "" && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">
                {adminLabels.pleaseFillTheField}
              </span>
            ) : (
              ""
            )}
            {email?.length !== 0 &&
              fieldIsRequired === true &&
              !ValidateEmail(email) && (
                <span className="text-red-500 mt-2">
                  {adminLabels.invalidEmail}
                </span>
              )}
          </div>
          {/* <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 lg:pr-2">
            <InputComponent
              value={password}
              placeholder={"Password"}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div> */}
          {/* <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 lg:pr-2">
            <InputComponent
              value={email}
              className="row-span-2"
              placeholder={"Email"}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div> */}
          {/* <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 lg:pr-2">
            <InputComponent
              value={address}
              placeholder={"Address"}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div> */}
          <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 lg:pr-2">
            <div className="flex items-center">
              <Dropdown
                overlay={menu}
                className={"flex height45 cursor-pointer"}
                trigger={["click"]}
                disabled={props.loader === true ? true : false}
              >
                <div className="flex bg-white w-max	py-2 w-full pl-2  pr-10 rounded-full items-center lg:mr-2">
                  <img
                    src={
                      countryFlag ===
                      "https://cdn.britannica.com/36/4336-004-6BD81071/Flag-Spain.jpg"
                        ? countryFlag
                        : require(`../../assets/flags/${countryFlag}.png`)
                            .default
                    }
                    style={{
                      width: 15,
                      height: 15,
                      Radius: 20,
                      margin: 2,
                    }}
                  />
                  <span className="text-sm">{countryCode}</span>
                  <DownOutlined className="ml-1" />
                </div>
              </Dropdown>
              <InputComponent
                type="text"
                value={phoneNumber}
                onChange={(e) =>
                  setPhoneNumber(regexForPhoneNumber(e.target.value))
                }
                placeholder={adminLabels.phoneNo}
              />
            </div>
            {phoneNumber === "" && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">
                {adminLabels.pleaseFillTheField}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 lg:pr-2">
            <InputComponent
              value={address}
              placeholder={adminLabels.address}
              onChange={(e) => setAddress(e.target.value)}
            />
            {address === "" && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">
                {adminLabels.pleaseFillTheField}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 lg:pr-2 mt-2 ">
            <button
              className=" bg-white rounded-full py-3 px-2 pl-4 flex justify-between w-full "
              onClick={(e) => onFeatured(e)}
            >
              <span className="text-gray-400">
                {adminLabels.functionalityAndFeatures}
              </span>
              <RiArrowDropRightLine size={22} className="text-gray-400" />
            </button>
            {features?.filter((item) => item.checked === false)?.length ===
              features?.length && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">
                {adminLabels.pleaseFillTheField}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 lg:pr-2 mt-2">
            <ButtonComponent
              className="bg-primary"
              title={adminLabels.save}
              onClick={
                props?.match?.params?.subAdminID ? editSubAdmin : addSubAdmin
              }
              loader={loader}
            />
          </div>
        </div>
        <Modal
          visible={functionalityModal}
          width={400}
          footer={false}
          onCancel={() => setFunctionalityModal(false)}
        >
          <div>
            <h4 className="font-bold text-2xm text-center text-gray-600	">
              {adminLabels.functionalityAndFeatures}
            </h4>
          </div>
          <div className="divide-y divide-black-600 divide-solid h-80 overflow-y-scroll  Functionality-And-Features-modal-scroll pr-2">
            {features.length
              ? features.map((t, i) => {
                  return (
                    <div
                      className="flex justify-between items-center py-5 "
                      key={i}
                    >
                      <span>
                        {
                          adminLabels?.[
                            functionToConvertStringToCamelCase(t?.name)
                          ]
                        }
                      </span>
                      {t.checked ? (
                        <BsCheckCircle
                          size={20}
                          color={"#55b319"}
                          onClick={() => onChecked(t, i)}
                        />
                      ) : (
                        <BsCircle
                          size={20}
                          color={"#55b319"}
                          onClick={() => onChecked(t, i)}
                        />
                      )}
                    </div>
                  );
                })
              : null}
          </div>
          <button
            className="w-full bg-primary rounded-full py-3 text-white"
            onClick={() => setFunctionalityModal(false)}
          >
            {adminLabels.apply}
          </button>
        </Modal>
      </div>
    </section>
  );
}

export default AddSubAdmin;
