import { Button, Modal, Input } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import ButtonComponent from "../../components/ButtonComponent";
import { Remove_Data_Form_Provider_Reducers } from "../../components/dataRemoveOnProviderLogout";
import {
  GetOrdersById,
  GetMessagesMethod,
  GetOrderNotificationById,
  OrderDisputeCancelMethod,
  OrderDisputeContinueMethod,
  SendOrderMessageMethod,
  UploadFileMethod,
  OrderReplyMethod,
} from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import {
  DeleteWorkerAtLogoutTime,
  FetchWorkersAction,
} from "../../Redux/actions/fetchWorkersAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { ProviderLogoutAction } from "../../Redux/actions/logoutAction";
import UserDetailContainerCard from "./UserDetailContainerCard";
import { Errors, Success } from "../../components/antdToast";
import { Images } from "../../constant/Images";
import { europeanCurrencyFormatter, functionToConvertStringToCamelCase } from "../../config/utils";

const UserServiceOrderDetails = (props) => {
  const {
    document,
    additionalBudgetIcon,
    orderCanceledIcon,
    orderPlacedIcon,
    checkbox,
    checkboxActive,
  } = Images;

  const { orderId } = useParams();
  const { goBack, push } = useHistory();
  const [selectedOrder, setSelectedOrder] = useState({});

  const [disputeConfirmationModal, setDisputeConfirmationModal] =
    useState(false);
  const [disputeOrderStatus, setDisputeOrderStatus] = useState("");
  const [disputeOrderRefundStatus, setDisputeOrderRefundStatus] = useState("");
  const [disputeOrderRefundComplete, setDisputeOrderRefundComplete] =
    useState("");
  const [is_supplementary, setIs_supplementary] = useState(true);
  const [partialAmount, setPartialAmount] = useState("");
  const [partialAmountSupplementary, setPartialAmountSupplementary] = useState("")

  useEffect(() => {
    getOrdersByProvider(orderId);
    replyFunction(orderId);
  }, []);

  useEffect(() => {
    if (disputeOrderStatus === "" || disputeOrderStatus === "continue") {
      setDisputeOrderRefundStatus("");
      setDisputeOrderRefundComplete("");
    }
  }, [disputeOrderStatus]);

  useEffect(() => {
    if (
      disputeOrderRefundStatus === "" ||
      disputeOrderRefundStatus === "partial"
    ) {
      setDisputeOrderRefundComplete("");
    }
  }, [disputeOrderRefundStatus]);

  const getOrdersByProvider = (id) => {
    GetOrdersById(
      id,
      (success) => {
        if (success.status === 200) {
          if (success.data.response.detail !== null) {
            setSelectedOrder(success?.data?.response?.detail);
          }
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/admin");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const replyFunction = (orderId) => {
    const obj = {
      order_id: orderId,
    };
    OrderReplyMethod(
      obj,
      (res) => {
        if (res?.data?.status) {
        }
      },
      (err) => {
        if (err?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(err?.response?.data?.message);
          push("/admin");
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const PartialCancelOrderDispute = () => {
    
    const obj = {
      order_id: orderId,
      type: disputeOrderRefundStatus,
      user_percent: Number(partialAmount),
      provider_percent: 100 - Number(partialAmount),
      supplementary_user_percent: Number(partialAmountSupplementary),
      supplementary_provider_percent: 100 - Number(partialAmountSupplementary),
      is_supplementary: 1
    };

    OrderDisputeCancelMethod(
      obj,
      (res) => {
        Success(res.data.response.message);
        setDisputeConfirmationModal(false);
        const myTimeout = setTimeout(() => {
          push("/admin/payouts");
        }, 2000);
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const CompleteCancelOrderDispute = () => {
    const userPercent = disputeOrderRefundComplete === "user" ? 100 : 0;
    const providerPercent = disputeOrderRefundComplete === "provider" ? 100 : 0;

    const obj = {
      order_id: orderId,
      type: disputeOrderRefundStatus,
      user_percent: userPercent,
      provider_percent: providerPercent,
      is_supplementary : 1 

    };
    OrderDisputeCancelMethod(
      obj,
      (res) => {
        Success(res.data.response.message);
        setDisputeConfirmationModal(false);
        const myTimeout = setTimeout(() => {
          push("/admin/payouts");
        }, 2000);
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const ContinueOrderDispute = () => {
    const obj = {
      order_id: orderId,
    };
    OrderDisputeContinueMethod(
      obj,
      (res) => {
        Success(res.data.response.message);
        setDisputeConfirmationModal(false);
        const myTimeout = setTimeout(() => {
          push("/admin/payouts");
        }, 2000);
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.message);
          Remove_Data_Form_Provider_Reducers(props);
          push("/");
        } else if (error?.response?.status === 409) {
          Errors(error?.response?.data?.error?.message);
          props.setLoaderState(false);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
        }
      }
    );
  };
  const vat = "21";
  const orderAmountCalculation = (price) => {
    let taxAmount = (vat / 100) * Number(price);
    let totalAmount = Number(price - taxAmount);
    return totalAmount;
  };
  const vatAmountCalculation = (price) => {
    let taxAmount = (vat / 100) * Number(price);
    return taxAmount;
  };
  const totalAmountCalculation = (service, vat, supplementary) => {
    return Number(service) + Number(vat) + Number(supplementary);
  };
  return (
    <section>
      <div className="flex justify-between mt-20 mb-5">
        <div className="pl-5 flex self-center justify-center items-center">
          <div
            onClick={() => goBack()}
            className="flex text-gray-400 items-center cursor-pointer"
          >
            <RiArrowDropLeftLine />
            <span>{adminLabels.back}</span>
          </div>
          <h3 className="ml-10	">
            {adminLabels.orderId}:{selectedOrder?.order_id}
          </h3>
        </div>
      </div>
      <div className="container grid grid-cols-12 gap-3 lg:pl-20 px-2 sm:px-2 lg:px-0">
        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12 flex mb-5">
          <h3 className="ml-8	my-4">{adminLabels.currentStep}</h3>
          <span
            className="flex items-center"
            style={{
              fontWeight: "normal",
              fontSize: 20,
              color: "#7D7D7D",
              marginLeft: 10,
              marginTop: 12,
            }}
          >
            {adminLabels?.[functionToConvertStringToCamelCase(selectedOrder?.service?.service_statuses?.findLast((currentStep) => currentStep?.order_status !==null)?.status)] || 'N/A'}
          </span>
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-5">
          <UserDetailContainerCard userOrderDetails={selectedOrder} />
        </div>

        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-3">
          <div className="bg-white container rounded-lg px-5 py-3">
            <h3 className="hh3 "> {adminLabels.orderInfoAndDocument}</h3>
            <div className="mt-4 mb-2">
              <Link
                to={{
                  pathname: "/admin/admin-side-order-info",
                  state: { orderDetail: selectedOrder },
                }}
              >
                <ButtonComponent
                  className="bg-primary"
                  title={adminLabels.details}
                />
              </Link>
            </div>
          </div>
          <div className="bg-white container rounded-lg px-5 py-3 mt-5">
            <h3 className="hh3">{adminLabels.theStepsToFlowUntilDelivery}</h3>
            <div className="mt-4 mb-2">
              <Link
                to={`/admin/order-steps/${selectedOrder.order_id}`}
                params={{ OrderID: selectedOrder?.order_id }}
              >
                <ButtonComponent
                  className="bg-primary"
                  title={adminLabels.details}
                />
              </Link>
            </div>
          </div>
    
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-3">
          <div className="bg-white container rounded-lg px-5 py-3">
            <h3 className="hh3">{adminLabels.countDown}</h3>
            <p className="font-color-blue py-4 ">3 days 4h: 43m</p>
            <div className="mt-4 mb-2"></div>
          </div>
          <div className="bg-white container rounded-lg px-5 py-3 mt-5 pb-5">
            <h3 className="hh3">{adminLabels.orderStatus}</h3>
            <p className="font-color-blue pt-2 mb-1">
              {moment(selectedOrder?.created_at).format("DD-MMM-YYYY")}
            </p>
            <p className="font-color-red mb-0">
              {adminLabels.dueDateOfService}
            </p>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-5">
          <div className="mt-4 mb-2 flex justify-end items-center gap-5">
            <Link
              to={`/admin/user-provider-order-messages/${selectedOrder?.order_id}`}
              className={`bg-primary  text-white h-6  rounded-full rounded-lg h-12 w-96 bg-primary `}
            >
              <ButtonComponent title={adminLabels.contactClient} />
            </Link>
            {["dispute"]?.includes(selectedOrder?.status) && (
              <div className="">
                <Button
                  shape={"round"}
                  size="large"
                  className={`icon-button  bg-danger`}
                  onClick={() => setDisputeConfirmationModal(true)}
                >
                  <span className="text-white">{adminLabels?.endDispute}</span>
                </Button>
              </div>
            )}
            {["disputed"]?.includes(selectedOrder?.status) && (
              <div className="">
                <Link
                  to={`/admin/user-provider-order-messages/${selectedOrder?.order_id}`}
                  className={`bg-primary  text-white h-6  rounded-full rounded-lg h-12 w-96 bg-primary `}
                >
                  <Button
                    shape={"round"}
                    size="large"
                    className={`icon-button  bg-primary`}
                  >
                    <span className="text-white">{adminLabels?.result}</span>
                  </Button>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        visible={disputeConfirmationModal}
        onCancel={() => setDisputeConfirmationModal(false)}
        footer={false}
      >
        {" "}
        <div>
          {
            <h4 className="font-bold text-2xl text-center text-gray-600	">
              {adminLabels.endDispute}
            </h4>
          }
        </div>
        <div className="w-full col-span-12 rounded-full lg:col-span-3 sm:col-span-6 md:col-span-6 text-center py-2 px-8"></div>
        <div className="flex justify-around items-center	">
          <div className="flex flex-col space-y-2 w-96">
            <div className="pt-2">
              <div className="pb-2 px-8">
                <p>
                  {
                    adminLabels?.ifYouwantToContinueClickOnContinueOrderOrIfYouWantToCancelClickOnCancelOrder
                  }
                </p>
              </div>
              <div className="flex justify-start space-x-12">
                <div className="flex items-center justify-start w-1/2">
                  {disputeOrderStatus === "cancel" ? (
                    <img
                      src={checkboxActive.default}
                      className="cursor-pointer"
                      onClick={() => setDisputeOrderStatus("")}
                    />
                  ) : (
                    <img
                      src={checkbox.default}
                      className="cursor-pointer"
                      onClick={() => setDisputeOrderStatus("cancel")}
                    />
                  )}
                  <span className=" text-dark   font-bold ml-2">
                    {adminLabels?.cancelOrder}
                  </span>
                </div>
                <div className="flex items-center justify-start w-1/2">
                  {disputeOrderStatus === "continue" ? (
                    <img
                      src={checkboxActive.default}
                      className="cursor-pointer"
                      onClick={() => setDisputeOrderStatus("")}
                    />
                  ) : (
                    <img
                      src={checkbox.default}
                      className="cursor-pointer"
                      onClick={() => setDisputeOrderStatus("continue")}
                    />
                  )}
                  <span className="mr-2 text-dark  font-bold ml-2">
                    {adminLabels?.continueOrder}
                  </span>
                </div>
              </div>
            </div>

            {disputeOrderStatus === "cancel" && (
              <div className="pt-2">
                <div className="pb-2">
                  {adminLabels?.doYouLikeToGoForCompleteOrPartial}
                </div>
                <div className="flex justify-start space-x-12">
                  <div className="flex items-center justify-start w-1/2">
                    {disputeOrderRefundStatus === "complete" ? (
                      <img
                        src={checkboxActive.default}
                        className="cursor-pointer"
                        onClick={() => setDisputeOrderRefundStatus("")}
                      />
                    ) : (
                      <img
                        src={checkbox.default}
                        className="cursor-pointer"
                        onClick={() => {
                          setIs_supplementary(true)
                          setDisputeOrderRefundStatus("complete")
                        }}
                      />
                    )}
                    <span className=" text-dark   font-bold ml-2">
                      {adminLabels?.complete}
                    </span>
                  </div>
                  <div className="flex items-center justify-start w-1/2">
                    {disputeOrderRefundStatus === "partial" ? (
                      <img
                        src={checkboxActive.default}
                        className="cursor-pointer"
                        onClick={() => setDisputeOrderRefundStatus("")}
                      />
                    ) : (
                      <img
                        src={checkbox.default}
                        className="cursor-pointer"
                        onClick={() => setDisputeOrderRefundStatus("partial")}
                      />
                    )}
                    <span className="mr-2 text-dark  font-bold ml-2">
                      {adminLabels?.partial}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {disputeOrderRefundStatus === "complete" && (
              <div className="pt-2">
                <div className="pb-2">
                  {adminLabels?.toWhomeYouLikeToMakeACompleteRefund}
                </div>
                <div className="flex justify-start space-x-12">
                  <div className="flex items-center justify-start w-1/2">
                    {disputeOrderRefundComplete === "provider" ? (
                      <img
                        src={checkboxActive.default}
                        className="cursor-pointer"
                        onClick={() => setDisputeOrderRefundComplete("")}
                      />
                    ) : (
                      <img
                        src={checkbox.default}
                        className="cursor-pointer"
                        onClick={() =>
                          setDisputeOrderRefundComplete("provider")
                        }
                      />
                    )}
                    <span className=" text-dark   font-bold ml-2">
                      {adminLabels?.serviceProvider}
                    </span>
                  </div>
                  <div className="flex items-center justify-start w-1/2">
                    {disputeOrderRefundComplete === "user" ? (
                      <img
                        src={checkboxActive.default}
                        className="cursor-pointer"
                        onClick={() => setDisputeOrderRefundComplete("")}
                      />
                    ) : (
                      <img
                        src={checkbox.default}
                        className="cursor-pointer"
                        onClick={() => setDisputeOrderRefundComplete("user")}
                      />
                    )}
                    <span className="mr-2 text-dark  font-bold ml-2">
                      {adminLabels?.user}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {disputeOrderRefundStatus === "partial" && (
              <>
                <div className="pt-2">
                  <div className="pb-2">
                    {adminLabels?.enterPercentYouwantToRefundUser}
                  </div>
                  <div className="flex justify-start space-x-12 items-end">
                    <div>
                      <Input
                        type={"number"}
                        min={1}
                        max={99}
                        value={partialAmount}
                        onChange={(e) => {
                          let value = e?.target.value;
                          if (Number(value) <= 99) {
                            setPartialAmount(value);
                          }
                        }}
                      />
                    </div>
                    <div>
                      {adminLabels?.remaining}{" "}
                      <b>{" " + 100 - partialAmount + " "}</b> %{" "}
                      {adminLabels?.forServiceProvider}
                    </div>
                  </div>
                </div>
                <div className="pt-2">
                  <div className="pb-2">
                    {adminLabels?.enterPercentOfSupplementaryYouWantToRefundUser}
                  </div>
                  <div className="flex justify-start space-x-12 items-end">
                    <div>
                      <Input
                        type={"number"}
                        min={1}
                        max={99}
                        value={partialAmountSupplementary}
                        onChange={(e) => {
                          let value = e?.target.value;
                          if (Number(value) <= 99) {
                            setPartialAmountSupplementary(value);
                          }
                        }}
                      />
                    </div>
                    <div>
                      {adminLabels?.remaining}{" "}
                      <b>{" " + 100 - partialAmountSupplementary + " "}</b> %{" "}
                      {adminLabels?.forServiceProvider}
                    </div>
                  </div>
                </div>
              </>
            )}
            {disputeOrderRefundStatus === "complete" &&
              disputeOrderRefundComplete === "user" && (
                <div>
                  <p>
                    {adminLabels?.orderAmount} {" = "}
                    <b>
                      €
                      {europeanCurrencyFormatter(
                        selectedOrder?.after_discount_amount
                      )}
                    </b>
                  </p>
                  {/* <p>
                   <div className="flex items-center">
                    <div>
                      <img
                        src={is_supplementary ? checkboxActive.default : checkbox.default}
                        className="cursor-pointer"
                        onClick={() => setIs_supplementary(!is_supplementary)}
                      />
                    </div>
                    <div>
                      <span className="mr-2 text-dark  font-bold ml-2">
                        {adminLabels?.supplementary}
                      </span>
                    </div>
                   </div>
                  </p> */}
                  <p>
                    {adminLabels?.supplementaryAmount} {" = "}
                    <b>
                      €{" "}
                      {europeanCurrencyFormatter(!is_supplementary ? 0 : selectedOrder?.supplementary_fees)}
                    </b>
                  </p>
                  <b>{adminLabels?.totalAmount}</b>
                  <p>
                    {adminLabels?.orderAmount} {" + "}
                    {adminLabels?.supplementaryAmount} {" = "}
                    <b>
                      €
                      {europeanCurrencyFormatter(Number(selectedOrder?.after_discount_amount) + Number(!is_supplementary ? 0 : selectedOrder?.supplementary_fees)
                      )}{" "}
                    </b>
                  </p>
                </div>
              )}
            {disputeOrderRefundStatus === "complete" &&
              disputeOrderRefundComplete === "provider" && (
                <div>
                  <p>
                    {adminLabels?.orderAmount} ={" "}
                    <b>
                      €{" "}
                      {europeanCurrencyFormatter(
                        selectedOrder?.after_discount_amount
                      )}{" "}
                    </b>
                  </p>
                  
                  <p>
                    {adminLabels?.supplementaryAmount} {" = "}
                    <b>
                      €{" "}
                      {europeanCurrencyFormatter(!is_supplementary ? 0 : selectedOrder?.supplementary_fees)}
                    </b>
                  </p>
                  <p>
                    {adminLabels?.docuDocDeduction} 30% {" = "}
                    <b>
                      €
                      {europeanCurrencyFormatter(
                        Number(selectedOrder?.after_discount_amount) * 0.3
                      )}{" "}
                    </b>
                  </p>
                  <p>
                    {adminLabels?.serviceProvider} 70% {" = "}
                    <b>
                      €
                      {europeanCurrencyFormatter(
                        Number(selectedOrder?.after_discount_amount) * 0.7
                      )}{" "}
                    </b>
                  </p>
                  <b>{adminLabels?.totalAmount}</b>
                  <p>
                    {adminLabels?.docuDocDeduction} 70% +{" "}
                    {adminLabels?.supplementaryAmount} {" = "}
                    <b>
                      €
                      {europeanCurrencyFormatter(
                        Number(selectedOrder?.after_discount_amount) * 0.7 +
                          Number(!is_supplementary ? 0 : selectedOrder?.supplementary_fees)
                      )}{" "}
                    </b>
                  </p>
                </div>
              )}
            {disputeOrderRefundStatus === "partial" && (
              <div>
              
                <b>{adminLabels.totalAmountPaid}</b>
                <p className="mb-0">
                  {adminLabels.service} {" = "}
                  <b>
                    €{" "}
                    {europeanCurrencyFormatter(
                      orderAmountCalculation(
                        selectedOrder?.after_discount_amount
                      )
                    )}{" "}
                  </b>
                </p>
                <p className="mb-0">
                  {adminLabels.vat} {" = "}
                  <b>
                    €{" "}
                    {europeanCurrencyFormatter(
                      vatAmountCalculation(selectedOrder?.after_discount_amount)
                    )}{" "}
                  </b>
                </p>
                <p className="mb-0">
                  {adminLabels.supplementaryAmount} {" = "}
                  <b>
                    €{" "}
                    {europeanCurrencyFormatter(!is_supplementary ? 0 : selectedOrder?.supplementary_fees)}
                  </b>
                </p>
                <p className="">
                  {adminLabels.serviceVATSupplements} {" = "}
                  <b>
                    €{" "}
                    {europeanCurrencyFormatter(
                      totalAmountCalculation(
                        Number(
                          orderAmountCalculation(
                            selectedOrder?.after_discount_amount
                          )
                        ),
                        Number(
                          vatAmountCalculation(
                            selectedOrder?.after_discount_amount
                          )
                        ),
                        Number(!is_supplementary ? 0 : selectedOrder?.supplementary_fees)
                      )
                    )}
                  </b>
                </p>
                <p>
                  <b className="text-lg">{adminLabels.user}</b>
                </p>
                <p>
                  {adminLabels?.partialAmountOf} {partialAmount} % {" = "}
                  <b>
                    €
                    {europeanCurrencyFormatter(
                      Number(selectedOrder?.after_discount_amount) *
                        (Number(partialAmount) / 100)
                    )}{" "}
                  </b>
                </p>
                <p>
                  {adminLabels?.partialAmountOf} {adminLabels?.supplementary} {partialAmountSupplementary} % {" = "}
                  <b>
                    €
                    {europeanCurrencyFormatter(Number(!is_supplementary ? 0 : selectedOrder?.supplementary_fees) * (Number(partialAmountSupplementary) / 100))}{" "}
                  </b>
                </p>
                <p>
                  <b className="text-lg">{adminLabels?.serviceProvider}</b>
                </p>
                <p>
                  {adminLabels?.partialAmountOf} {100 - partialAmount} % {" = "}
                  <b>
                    €
                    {europeanCurrencyFormatter(
                      Number(selectedOrder?.after_discount_amount) *
                        (Number(100 - partialAmount) / 100)
                    )}{" "}
                  </b>
                </p>
                <p>
                  {adminLabels?.partialAmountOf} {adminLabels?.supplementary} {100 - partialAmountSupplementary} % {" = "}
                  <b>
                    €
                    {europeanCurrencyFormatter(Number(!is_supplementary ? 0 : selectedOrder?.supplementary_fees) * (Number(100 - Number(partialAmountSupplementary)) / 100))}{" "}
                  </b>
                </p>
                <p>
                  {adminLabels?.docuDocDeduction} 30%{" "}
                  {adminLabels?.ofPartialAmount} ={" "}
                  <b>
                    €
                    {europeanCurrencyFormatter(
                      Number(
                        Number(selectedOrder?.after_discount_amount) *
                          (Number(100 - partialAmount) / 100)
                      ) * 0.3
                    )}{" "}
                  </b>
                </p>
                <p>
                  {adminLabels?.serviceProvider} 70%{" "}
                  {adminLabels?.ofPartialAmount} ={" "}
                  <b>
                    €
                    {europeanCurrencyFormatter(
                      Number(
                        Number(selectedOrder?.after_discount_amount) *
                          (Number(100 - partialAmount) / 100)
                      ) * 0.7
                    )}{" "}
                  </b>
                </p>
                <b>{adminLabels?.totalAmount}</b>
                <p>
                  {adminLabels?.serviceProvider} 70% +{" "}
                  {adminLabels?.supplementaryAmount} {" = "}
                  <b>
                    €
                    {europeanCurrencyFormatter((Number(Number(selectedOrder?.after_discount_amount) * (Number(100 - partialAmount) / 100)) * 0.7) + (Number(!is_supplementary ? 0 : selectedOrder?.supplementary_fees) * (Number(100 - partialAmountSupplementary) / 100)))}{" "}
                  </b>
                </p>
              </div>
            )}
            <div className="flex justify-center py-2">
              {disputeOrderStatus === "continue" && (
                <ButtonComponent
                  title={adminLabels?.confirm}
                  onClick={ContinueOrderDispute}
                />
              )}
              {["user", "provider"].includes(disputeOrderRefundComplete) && (
                <ButtonComponent
                  title={adminLabels?.confirm}
                  onClick={CompleteCancelOrderDispute}
                />
              )}
              {disputeOrderRefundStatus === "partial" && (
                <ButtonComponent
                  title={adminLabels?.confirm}
                  onClick={PartialCancelOrderDispute}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </section>
  );
};
const mapStateToProps = (state) => {
  return {
    showWorkers: state.workerList.workers,
    providerDetail: state.providerLoginDetail.loggedUserDetailOfProvider,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
    getWorkers: (props) => {
      dispatch(FetchWorkersAction(props));
    },
    removeWorkerList: () => {
      dispatch(DeleteWorkerAtLogoutTime());
    },
    setProviderLogout: () => {
      dispatch(ProviderLogoutAction());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserServiceOrderDetails);
