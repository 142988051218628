import { Button, Dropdown, Menu, Space, Tag } from "antd";
import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import TableComponent from "../../components/table";
import { RiDeleteBinFill, RiArrowDropRightLine } from "react-icons/ri";
import { Images } from "../../constant/Images";
import { Link, useHistory } from "react-router-dom";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { FcDown } from "react-icons/fc";

export default function BookingReport(params) {
  const { goBack } = useHistory();

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: 100,

      // align: "center",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",

      // align: "center",
      width: 300,
      render: (text, index) => (
        <div className={"flex items-center"}>
          <img
            src={text.image}
            style={{ width: 45, height: 45 }}
            alt="detail image"
          />
          <div className="pl-2 text-left">
            <label>
              <b>{text.name}</b>
            </label>
            <br />
            <span style={{ color: "blue", marginBottom: 0 }}>{text.desc}</span>
          </div>
        </div>
      ),
    },
    {
      // align: "center",
      title: "Beneficiaries",
      dataIndex: "beneficiaries",
      key: "beneficiaries",
      width: 200,
      render: (e) => (
        <div className="flex items-center ml-2">
          <img src={e.url} alt="" />
          <span className="ml-3">{e.name}</span>
        </div>
      ),
    },
    {
      // align: "center",
      title: "Service Area",
      dataIndex: "servicearea",
      key: "servicearea",
      width: 160,
      render: (e) => (
        <div className="flex items-center ml-2">
          <img src={e.url} alt="" className="h-8 w-8" />
          <span className="ml-2">{e.name}</span>
        </div>
      ),
    },
    {
      title: "Order ID",
      dataIndex: "ordierId",
      key: "ordierId",
      width: 150,
      // align: "center",
    },
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      width: 150,
      // align: "center",
    },
    {
      title: "Price",
      key: "price",
      dataIndex: "price",
      width: 100,
      // align: "center",
    },
    {
      title: "Panctual service",
      key: "panctualservice",
      dataIndex: "panctualservice",
      width: 150,
      // align: "center",
    },
    {
      title: "Service Pack",
      key: "servicepack",
      dataIndex: "servicepack",
      width: 150,

      // align: "center",
    },
    {
      title: "DueDate of contract",
      key: "duedateofcontract",
      dataIndex: "duedateofcontract",
      width: 200,

      // align: "center",
    },
    {
      title: "Contracting Time",
      key: "contractingtime",
      dataIndex: "contractingtime",
      width: 200,

      // align: "center",
    },
    {
      title: "Name of Service",
      key: "nameofservice",
      dataIndex: "nameofservice",
      width: 200,

      // align: "center",
    },
    {
      title: "Location",
      key: "location",
      dataIndex: "location",
      width: 100,

      // align: "center",
    },
    {
      title: "Order Status",
      key: "orderstatus",
      dataIndex: "orderstatus",
      width: 150,

      // align: "center",
    },
    {
      title: "Order Delivery Time ",
      key: "orderdeliverytime",
      dataIndex: "orderdeliverytime",
      width: 200,

      // align: "center",
      //
    },
    {
      title: "Each Step Time",
      key: "eachsteptime",
      dataIndex: "eachsteptime",
      width: 200,
      // align: "center",
    },
    {
      title: "Total Payment made",
      key: "totalpaymentmade",
      dataIndex: "totalpaymentmade",
      width: 250,

      // align: "center",
    },
    {
      title: "Amount of Service provider",
      key: "amountofserviceprovider",
      dataIndex: "amountofserviceprovider",
      width: 300,

      // align: "center",
    },
    {
      title: "Amount of Additional Fees(Courier)",
      key: "amountofadditionalfees",
      dataIndex: "amountofadditionalfees",
      width: 300,

      // align: "center",
    },
    {
      title: "VAT",
      key: "vat",
      dataIndex: "vat",
      width: 80,

      // align: "center",
    },
    {
      title: "Actual Profit for Service",
      key: "actualprofitforservice",
      dataIndex: "actualprofitforservice",
      width: 300,

      // align: "center",
    },
    {
      title: "Name of Service Provider",
      key: "nameofserviceprovider",
      dataIndex: "nameofserviceprovider",
      width: 300,

      // align: "center",
    },
    {
      title: "Name Admin of Service Provider",
      key: "nameadminofserviceprovider",
      dataIndex: "nameadminofserviceprovider",
      width: 300,
      align: "center",
    },
    {
      title: "Name Worker of Service Provider",
      key: "nameworkerofserviceprovider",
      dataIndex: "nameworkerofserviceprovider",
      width: 300,

      // align: "center",
    },
    {
      title: "Action",
      key: "action",
      width: 200,

      render: (text, index) => (
        <div className="text-center">
          <div
            type="disable"
            shape={"round flex"}
            className="background-color-gray whiteTxt rounded-full py-1 px-2 justify-center cursor-pointer	"
          >
            <span>View Details</span>
          </div>
        </div>
      ),
    },
  ];

  const data = [
    {
      no: "1",
      product: {
        image: Images.punctual1.default,
        name: "Life Insurance",
        desc: "Initial Info and Docs received",
      },
      servicearea: { name: "Service", url: Images.assement.default },
      beneficiaries: { name: "Particular", url: Images.particular.default },
      ordierId: "123456",
      price: "$849",
      date: "1 May 2021",
      panctualservice: "Yes",
      servicepack: "yes",
      duedateofcontract: "Yearly",
      contractingtime: "12:00",
      nameofservice: "service name",
      location: "Spain",
      orderstatus: "Finished",
      orderdeliverytime: "12:00",
      eachsteptime: "12:00",
      totalpaymentmade: "$35",
      amountofserviceprovider: "$35",
      amountofadditionalfees: "$35",
      vat: "2%",
      actualprofitforservice: "$88",
      nameofserviceprovider: "John Doe",
      nameadminofserviceprovider: "Jahanzaib",
      nameworkerofserviceprovider: "Ali Khan",
    },
    {
      no: "2",
      product: {
        image: Images.punctual1.default,
        name: "Life Insurance",
        desc: "Initial Info and Docs received",
      },
      servicearea: { name: "Service", url: Images.assement.default },
      beneficiaries: {
        name: "Self Employed",
        url: Images.selfemployed.default,
      },
      ordierId: "123456",
      price: "$849",
      date: "1 May 2021",
      panctualservice: "Yes",
      servicepack: "yes",
      duedateofcontract: "Yearly",
      contractingtime: "12:00",
      nameofservice: "service name",
      location: "Spain",
      orderstatus: "Finished",
      orderdeliverytime: "12:00",
      eachsteptime: "12:00",
      totalpaymentmade: "$35",
      amountofserviceprovider: "$35",
      amountofadditionalfees: "$35",
      vat: "2%",
      actualprofitforservice: "$88",
      nameofserviceprovider: "John Doe",
      nameadminofserviceprovider: "Jahanzaib",
      nameworkerofserviceprovider: "Ali Khan",
    },
    {
      no: "3",
      product: {
        image: Images.punctual1.default,
        name: "Life Insurance",
        desc: "Initial Info and Docs received",
      },
      servicearea: { name: "Service", url: Images.assement.default },
      beneficiaries: {
        name: "Business",
        url: Images.business.default,
      },
      ordierId: "123456",
      price: "$849",
      date: "1 May 2021",
      panctualservice: "Yes",
      servicepack: "yes",
      duedateofcontract: "Yearly",
      contractingtime: "12:00",
      nameofservice: "service name",
      location: "Spain",
      orderstatus: "Finished",
      orderdeliverytime: "12:00",
      eachsteptime: "12:00",
      totalpaymentmade: "$35",
      amountofserviceprovider: "$35",
      amountofadditionalfees: "$35",
      vat: "2%",
      actualprofitforservice: "$88",
      nameofserviceprovider: "John Doe",
      nameadminofserviceprovider: "Jahanzaib",
      nameworkerofserviceprovider: "Ali Khan",
    },
  ];

  return (
    <section className="">
      <div className="flex justify-between mt-5  flex flex-wrap sm:flex-wrap">
        <div className="pl-5 flex self-center justify-center items-center sub-h3 mb-4 sm:mb-4 lg:mb-0">
          <div
            onClick={() => goBack()}
            className="flex text-gray-400 items-center cursor-pointer"
          >
            <RiArrowDropLeftLine />
            <span>Back</span>
          </div>
          <h3 className="ml-10	">Booking Reports</h3>
        </div>
        <div className="lg:mr-28 flex">
          <Link to={"/admin/client-user-report"}>
            <Button shape={"round"} className="icon-button lg:mx-1">
              Client User Reports
            </Button>
          </Link>
          <Button
            type={"primary"}
            shape={"round"}
            className="bg-primary  icon-button mx-1"
          >
            Booking Report
          </Button>
          <div
            className=" bg-white flex justify-center item-center"
            style={{
              borderRadius: "100px",
              padding: "10px",
              width: "40px",
              height: "40px",
            }}
          >
            <FcDown color="green" />
          </div>
        </div>
      </div>
      <div className="container mx-auto lg:px-20 mt-5 min-w-full">
        <TableComponent columns={columns} data={data}  />
      </div>
    </section>
  );
}
