import { Button, Space, Tag } from "antd";
import React from "react";
import { AiFillStar } from "react-icons/ai";
import TableComponent from "../../components/table";
import { Images } from "../../constant/Images";
import { RiArrowDropLeftLine } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";
import { FcDown } from "react-icons/fc";

const columns = [
  {
    align: "center",
    title: "No",
    dataIndex: "no",
    key: "no",
    width: 60,
    render: (text) => <a>{text}</a>,
  },
  {
    align: "center",
    title: "Full Name",
    dataIndex: "fullname",
    key: "fullname",
    width: 150,
  },
  {
    align: "center",
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: 200,
  },
  {
    align: "center",
    title: "Amount of Booking",
    key: "ammountOfBooking",
    dataIndex: "ammountOfBooking",
    width: 160,
  },
  {
    align: "center",
    title: "Registration Date",
    dataIndex: "registrationDate",
    key: "registrationDate",
    width: 160,
  },
  {
    align: "center",
    title: "Rating given",
    key: "ratingGiven",
    dataIndex: "ratingGiven",
    width: 100,
    render: (text, index) => (
      <div className="flex items-center justify-center">
        <AiFillStar color={"#FFC107"} style={{ marginRight: 5 }} />
        <label>{text.ratting}</label>
      </div>
    ),
  },
  {
    align: "center",
    title: "Service which he clicked",
    dataIndex: "serviceClicked",
    width: 200,
    render: (text, index) => (
      <div className={"flex items-center"}>
        <img
          src={text.image}
          style={{ width: 30, height: 30 }}
          alt="detail image"
        />
        <div className="pl-2 text-left">
          <label>{text.name}</label>
        </div>
      </div>
    ),
  },
  {
    align: "center",
    title: "Amount of clicks",
    dataIndex: "amountofclicks",
    key: "amountofclicks",
    width: 150,
  },
  {
    align: "center",
    title: "Date and time of clicks",
    dataIndex: "dateandtiemofclicks",
    key: "dateandtiemofclicks",
    width: 200,
  },
  {
    align: "center",
    title: "Amount of booking made",
    dataIndex: "amountofbookingmade",
    key: "amountofbookingmade",
    width: 200,
  },
  {
    align: "center",
    title: "Action",
    key: "action",
    width: 160,
    render: (text, index) => (
      <div className="text-center">
        <Button
          type="disable"
          shape={"round"}
          className="background-color-gray whiteTxt"
        >
          View Details
        </Button>
      </div>
    ),
  },
];

const data = [
  {
    no: "1",
    fullname: "John Brown",
    email: "abc@gmail.com",
    ammountOfBooking: "$845",
    registrationDate: "10 May 2021",
    ratingGiven: { ratting: 4.3 },
    serviceClicked: {
      image: Images.punctual1.default,
      name: "Punctual Service 1",
    },
    amountofclicks: "3",
    dateandtiemofclicks: "28 May 2021 & 12:00",
    amountofbookingmade: "$859",
  },
  {
    no: "2",
    fullname: "John Brown",
    email: "abc@gmail.com",
    ammountOfBooking: "$845",
    registrationDate: "10 May 2021",
    ratingGiven: { ratting: 4.3 },
    serviceClicked: {
      image: Images.punctual2.default,
      name: "Punctual Service 2",
    },
    amountofclicks: "3",
    dateandtiemofclicks: "28 May 2021 & 12:00",
    amountofbookingmade: "$859",
  },
  {
    no: "3",
    fullname: "John Brown",
    email: "abc@gmail.com",
    ammountOfBooking: "$845",
    registrationDate: "10 May 2021",
    ratingGiven: { ratting: 4.3 },
    serviceClicked: {
      image: Images.servicepack.default,
      name: "Service Pack 1",
    },
    amountofclicks: "3",
    dateandtiemofclicks: "28 May 2021 & 12:00",
    amountofbookingmade: "$859",
  },
];

export default function ClientUserReport(params) {
  const { goBack } = useHistory();
  return (
    <section>
      <div className="flex justify-start lg:justify-between mt-5  flex-wrap sm:flex-wrap">
        <div className="pl-5 flex self-center justify-center items-center sub-h3 mb-4 sm:mb-4 lg:mb-0">
          <div
            onClick={() => goBack()}
            className="flex text-gray-400 items-center cursor-pointer"
          >
            <RiArrowDropLeftLine />
            <span>Back</span>
          </div>
          <h3 className="ml-10	">Client User Reports</h3>
        </div>
        <div className="lg:mr-28 flex justify-end flex-wrap sm:flex-wrap">
          <Button
            type={"primary"}
            shape={"round"}
            className="bg-primary icon-button mx-1"
          >
            Client User Reports
          </Button>
          <Link to={"/admin/booking-report"}>
            <Button shape={"round"} className="icon-button lg:mx-1">
              Booking Report
            </Button>
          </Link>
          <div
            className=" bg-white flex justify-center item-center"
            style={{
              borderRadius: "100px",
              padding: "10px",
              width: "40px",
              height: "40px",
            }}
          >
            <FcDown color="green" />
          </div>
        </div>
      </div>
      <div className="container mx-auto lg:px-20 mt-5">
        <TableComponent columns={columns} data={data} />
      </div>
    </section>
  );
}
