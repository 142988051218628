import axios from "axios";
import React, { useState, useEffect } from "react";
import { providerLabels } from "../../config/label/providerLabels";
import { Images } from "../../constant/Images";

const language = localStorage.getItem("primaryLanguage") === "english";
const FilledServiceFormFields = ({ filledServiceForm }) => {
  const downloadFiles = (url, name) => {
    axios({
      url: url,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
      });
  };
  const label = (item) => item[language ? "label" : "labelEsp"];
  return (
    <div>
      {filledServiceForm?.length !== 0 ? (
        <>
          <h3 className="font-bold text-2xl text-center text-gray-600">
            {providerLabels.serviceForm}
          </h3>
          {filledServiceForm?.map((item) => {
            if (item?.fieldId === 4 || item?.fieldId === 8) {
              return (
                <>
                  <p className="modalH">
                    <b>{label(item)}</b>
                    <div className="divide-gray-100 divide-y divide-solid">
                      {item?.fieldId === 4
                        ? item?.value?.length > 0
                          ? item?.value?.map((item) => {
                              return (
                                <div className="flex justify-between">
                                  <label className="flex items-center ">
                                    <img
                                      src={Images?.document?.default}
                                      className="mr-2"
                                    />
                                    <div
                                      className="flex cursor-pointer"
                                      onClick={() =>
                                        downloadFiles(item?.url, item?.name)
                                      }
                                    >
                                      <span className="text-sm text-black">
                                        {item?.name}
                                      </span>
                                    </div>
                                  </label>
                                </div>
                              );
                            })
                          : "N/A"
                        : item?.files?.length > 0
                        ? item?.files?.map((item) => {
                            return (
                              <div className="flex justify-between">
                                <label className="flex items-center ">
                                  <img
                                    src={Images?.document?.default}
                                    className="mr-2"
                                  />
                                  <div
                                    className="flex cursor-pointer"
                                    onClick={() =>
                                      downloadFiles(item?.url, item?.name)
                                    }
                                  >
                                    <span className="text-sm text-black">
                                      {item?.name}
                                    </span>
                                  </div>
                                </label>
                              </div>
                            );
                          })
                        : "N/A"}
                    </div>
                  </p>
                </>
              );
            } else if (item?.hasOwnProperty("sectionTitle")) {
              return (
                <div className="flex flex-col">
                  <p className="modalH">{item?.sectionTitle}:</p>
                </div>
              );
            } else {
              return (
                <div className="flex flex-col">
                  <p className="mb-0 font-black">{label(item)}</p>
                  <p>{item?.value}</p>
                </div>
              );
            }
          })}
        </>
      ) : null}
    </div>
  );
};

export default FilledServiceFormFields;
