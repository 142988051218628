import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, message, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { RiArrowDropLeftLine } from "react-icons/ri";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Errors, Success } from "../../components/antdToast";
import ButtonComponent from "../../components/ButtonComponent";
import { Remove_Data_Form_Reducers } from "../../components/dataRemoveOnLogout";
import InputComponent from "../../components/InputComponent";
import ModalComponent from "../../components/Modal";
import {
  AddBranch,
  EditBranch,
  GetBranches,
  GetServiceArea,
  GetServiceAreaByBeneficiaryId,
} from "../../config/api";
import { objectToFormData } from "../../config/formData/formData";
import { adminLabels } from "../../config/label/adminLabels";
import { functionToPreventSignsFromNumberFromPricing, validateDescription } from "../../config/utils";
import { Images } from "../../constant/Images";
import { DeleteAppUsers } from "../../Redux/actions/fetchAppUsersAction";
import {
  DeleteBeneficiariesAtLogoutTime,
  FetchBeneficiariesAction,
} from "../../Redux/actions/fetchBeneficiariesAction";
import {
  DeleteBranchesAtLogoutTime,
  FetchBranchesAction,
} from "../../Redux/actions/fetchBranchesAction";
import { DeleteProvidersAtLogoutTime } from "../../Redux/actions/fetchProvidersAction";
import {
  DeleteServiceAreasAtLogoutTime,
  FetchServiceAreaAction,
} from "../../Redux/actions/fetchServiceAreaAction";
import { DeleteServiceForms } from "../../Redux/actions/fetchServiceFormsAction";
import { DeleteServicesAtLogoutTime } from "../../Redux/actions/fetchServicesAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";
import "./addNewBranch.css";

const { uploadImg, checkbox, checkboxActive } = Images;
const currentSelectedLangue = localStorage.getItem("primaryLanguage")

const AddNewBranch = (props) => {
  const { goBack, push } = useHistory();

  // Declare Variable States
  const [branchName, setBranchName] = useState("");
  const [branchNameEsp, setBranchNameEsp] = useState("");
  const [branchImage, setBranchImage] = useState(null);
  const [description, setDescription] = useState("");
  const [descriptionEsp, setDescriptionEsp] = useState("");
  const [modalId, setModalId] = useState(0);
  const [beneficiaryFeatures, setBeneficiaryFeatures] = useState([
    {
      avatar:
        "https://docudoc-resources.s3.eu-west-3.amazonaws.com/images/70ead172-1c3e-424d-b250-061e4f2decd5.png",
      beneficiary_id: "70ead172-1c3e-424d-b250-061e4f2decd5",
      created_at: "2021-12-07T13:28:46.000000Z",
      id: 1,
      info: null,
      name: "Particular",
      updated_at: "2021-12-07T13:28:46.000000Z",
      priority: "",
      checked: false,
    },
    {
      avatar:
        "https://docudoc-resources.s3.eu-west-3.amazonaws.com/images/65e5f162-4af8-4442-a55a-e8cbbf09bb70.png",
      beneficiary_id: "65e5f162-4af8-4442-a55a-e8cbbf09bb70",
      created_at: "2021-12-07T13:28:46.000000Z",
      id: 2,
      info: null,
      name: currentSelectedLangue === "english" ? "Self Employed" : "Autónomo" ,
      updated_at: "2021-12-07T13:28:46.000000Z",
      priority: "",
      checked: false,
    },
    {
      avatar:
        "https://docudoc-resources.s3.eu-west-3.amazonaws.com/images/0e6e1528-78bc-45cb-a222-278243f10810.png",
      beneficiary_id: "0e6e1528-78bc-45cb-a222-278243f10810",
      created_at: "2021-12-07T13:28:47.000000Z",
      id: 3,
      info: null,
      name: currentSelectedLangue === "english" ? "Business" : "Empresa" ,
      updated_at: "2021-12-07T13:28:47.000000Z",
      priority: "",
      checked: false,
    },
  ]);
  const [beneficiaryModalButton, setBeneficiaryModalButton] = useState(true);

  const [serviceAreaFeatures, setServiceAreaFeatures] = useState([]);
  const [functionalityModal, setFunctionalityModal] = useState(false);
  const [branchLevel, setBranchLevel] = useState("");
  const [visible, setVisible] = useState(false);
  const [beneficiaryId, setBeneficiaryId] = useState("");
  const [branchPriority, setBranchPriority] = useState(0);
  const [selectedServiceArea, setSelectedServiceArea] = useState([]);
  const [fieldIsRequired, setFieldIsrequired] = useState(false);
  const [selectedBeneficiariesName, setSelectedBeneficiariesName] = useState(
    []
  );
  const [selectedServiceAreaName, setSelectedServiceAreaName] = useState([]);
  const [parentModal, setParentModal] = useState(false);
  const [parentBranches, setParentBranches] = useState([]);
  const [parentId, setParentId] = useState("");
  let history = useHistory();
  useEffect(() => {
    setBranchLevel(adminLabels.selectBranchLevel);
    if (props?.match?.params?.id) {
      props.location.state === undefined && push("/admin/branches");
      let branch = props?.location?.state?.branch;
      let findObj = props.showBranches.find(
        (obj) => obj.branch_id === props.match.params.id
      );
      setBranchName(branch?.name);
      setBranchNameEsp(branch?.name_sp || "");
      setBranchImage(branch?.avatar);
      setDescription(branch?.info);
      setDescriptionEsp(branch?.info_sp || "");
      setBranchLevel(branch?.level);
      setBeneficiaryId(findObj?.beneficiary_id);
      let mapedfeatures = [];
      GetServiceArea(
        (res) => {
          setServiceAreaFeatures(
            res?.data?.response?.detail?.map((a) => {
              if (a?.service_area_id === branch?.service_area?.service_area_id)
                return {
                  ...a,
                  checked: true,
                };
              else {
                return a;
              }
            })
          );
        },
        (error) => {
          if (error?.response?.status === 401) {
            Errors(error?.response?.data?.error?.message);
          } else {
            Errors("Network Error");
          }
        }
      );
      setServiceAreaFeatures(mapedfeatures);
      let mappedOut = branch?.branch_beneficiaries?.map((item) => {
        return item?.beneficiary_id;
      });
      let mapedBeneficiriesfeatures = beneficiaryFeatures?.map(
        (item, index) => {
          if (mappedOut?.includes(item?.beneficiary_id) === true) {
            return {
              ...item,
              checked: true,
              priority: Number(
                branch.branch_beneficiaries?.filter(
                  (bra) => bra.beneficiary_id === item?.beneficiary_id
                )[0].priority
              ),
            };
          } else {
            return {
              ...item,
            };
          }
        }
      );
      setBeneficiaryFeatures(mapedBeneficiriesfeatures);

      GetBranches(
        (res) => {
          let mappedbranches = res?.data?.response?.detail?.map((a) => {
            if (a?.branch_id === branch?.parent_branch_id)
              return {
                ...a,
                checked: true,
              };
            else {
              return a;
            }
          });

          setParentBranches(mappedbranches);
        },
        (error) => {
          if (error?.response?.status === 401) {
            Errors(error?.response?.data?.error?.message);
          } else {
            Errors("Network Error");
          }
        }
      );
      selecteServiceAreas();
    } else {
      GetBranches(
        (res) => {
          setParentBranches(
            res?.data?.response?.detail?.map((a) => {
              return {
                ...a,
                checked: false,
              };
            })
          );
        },
        (error) => {
          if (error?.response?.status === 401) {
            Errors(error?.response?.data?.error?.message);
          } else {
            Errors("Network Error");
          }
        }
      );
      GetServiceArea(
        (res) => {
          setServiceAreaFeatures(
            res?.data?.response?.detail?.map((a) => {
              return {
                ...a,
                checked: false,
                priority: 0,
              };
            })
          );
        },
        (error) => {
          if (error?.response?.status === 401) {
            Errors(error?.response?.data?.error?.message);
          } else {
            Errors("Network Error");
          }
        }
      );
    }
  }, []);

  useEffect(() => {
    let filterdByBranchServiceCount = props.showBranches?.filter(
      (item) => item?.branch_services_count === 0 && item?.level === "1"
    );
    let mappedByBranchServiceCount = filterdByBranchServiceCount?.map(
      (g, i) => {
        return {
          ...g,
          checked: false,
        };
      }
    );
    setParentBranches(mappedByBranchServiceCount);
  }, [props.showBranches]);

  useEffect(() => {
    selecteServiceAreas();
    if (beneficiaryFeatures?.length !== 0) {
      selecteServiceAreas();
    }
  }, [beneficiaryFeatures]);

  const onFeatured = () => {
    setFunctionalityModal(true);
  };

  const onChecked = (e, i) => {
    const array = beneficiaryFeatures?.map((a, b) => {
      if (b === i) {
        return {
          ...a,
          checked: !a?.checked,
          priority: a?.checked === true ? "" : a?.priority,
        };
      } else {
        return {
          ...a,
        };
      }
    });
    setBeneficiaryFeatures(array);
  };

  const onChecked1 = (e, i) => {
    const array = serviceAreaFeatures?.map((a, b) => {
      if (b === i) {
        if (a?.checked === false) {
          setSelectedServiceAreaName([a]);
        } else {
          setBeneficiaryFeatures([
            {
              avatar:
                "https://docudoc-resources.s3.eu-west-3.amazonaws.com/images/70ead172-1c3e-424d-b250-061e4f2decd5.png",
              beneficiary_id: "70ead172-1c3e-424d-b250-061e4f2decd5",
              created_at: "2021-12-07T13:28:46.000000Z",
              id: 1,
              info: null,
              name: "Particular",
              updated_at: "2021-12-07T13:28:46.000000Z",
              priority: "",
              checked: false,
            },
            {
              avatar:
                "https://docudoc-resources.s3.eu-west-3.amazonaws.com/images/65e5f162-4af8-4442-a55a-e8cbbf09bb70.png",
              beneficiary_id: "65e5f162-4af8-4442-a55a-e8cbbf09bb70",
              created_at: "2021-12-07T13:28:46.000000Z",
              id: 2,
              info: null,
              name: currentSelectedLangue === "english" ? "Self Employed" : "Autónomo" ,
              updated_at: "2021-12-07T13:28:46.000000Z",
              priority: "",
              checked: false,
            },
            {
              avatar:
                "https://docudoc-resources.s3.eu-west-3.amazonaws.com/images/0e6e1528-78bc-45cb-a222-278243f10810.png",
              beneficiary_id: "0e6e1528-78bc-45cb-a222-278243f10810",
              created_at: "2021-12-07T13:28:47.000000Z",
              id: 3,
              info: null,
              name: currentSelectedLangue === "english" ? "Business" : "Empresa" ,
              updated_at: "2021-12-07T13:28:47.000000Z",
              priority: "",
              checked: false,
            },
          ]);
        }
        return {
          ...a,
          checked: !a.checked,
        };
      } else {
        return {
          ...a,
          checked: false,
        };
      }
    });
    setServiceAreaFeatures(array);
  };

  const onCheckedParentBranch = (e, i,id) => {
    const array = parentBranches?.map((a, b) => {
      if (a?.branch_id === id) {
        if (a.checked === false) {
          setParentId(a.branch_id);
        }
        return {
          ...a,
          checked: !a.checked,
        };
      } else {
        return {
          ...a,
          checked: false,
        };
      }
    });
    setParentBranches(array);
  };

  const handleMenuClick = (e) => {
    if (e.key === "2") {
      setBranchLevel(e.key);
      setParentModal(true);
    } else {
      setBranchLevel(e.key);
      const array = parentBranches.map((a, b) => {
        return {
          ...a,
          checked: false,
        };
      });
      setParentBranches(array);
    }
    if (e.key === "3") {
      setVisible(false);
    }
  };

  const fetchIdOfBeneficiary = (id) => {
    GetServiceAreaByBeneficiaryId(
      id,
      (success) => {
        let mappedArray = success?.data?.response?.detail.map(
          (detail, index) => {
            return {
              ...detail,
              checked: false,
              priority: 0,
            };
          }
        );
        setServiceAreaFeatures(mappedArray);
      },
      (error) => {
        if (error?.response?.status === 401) {
          props.setLoaderState(false);
          Errors(error?.response?.data?.error?.message);
          setFieldIsrequired(false);
          Remove_Data_Form_Reducers(props);
        } else {
          props.setLoaderState(false);
          Errors("Network Error");
          setFieldIsrequired(false);
        }
      }
    );
  };

  //set priorit of Beneficiary
  const changeBeneficiaryPriority = (e, id) => {
    let mappedPriority = beneficiaryFeatures.map((a, b) => {
      if (a.id === id) {
        Number(e.target.value) > 0
          ? setBeneficiaryModalButton(false)
          : setBeneficiaryModalButton(true);
        return {
          ...a,
          priority: e.target.value && Number(e.target.value),
        };
      } else {
        return {
          ...a,
          priority: a.priority,
        };
      }
    });
    setBeneficiaryFeatures(mappedPriority);
  };

  const selecteServiceAreas = () => {
    let filteredFeatures = serviceAreaFeatures.filter((selectFeature) => {
      if (selectFeature.checked === true) {
        return {
          selectFeature,
        };
      }
    });
    setSelectedServiceAreaName(filteredFeatures);
    let mappedFeatures = filteredFeatures?.map((selectFeature) => {
      if (selectFeature?.checked === true) {
        return {
          service_area_id: selectFeature?.service_area_id,
          beneficiaries: beneficiaryFeatures?.filter((beneficiaryFeature) => {
            return beneficiaryFeature?.checked === true;
          }),
        };
      }
    });

    setSelectedServiceArea(mappedFeatures);
  };

  const createBranch = () => {
    let serviceAreaFeaturesData = serviceAreaFeatures.filter(
      (serviceAreaFeature) => {
        return serviceAreaFeature.checked === false;
      }
    );
    let beneficiaryData = beneficiaryFeatures.filter((beneficiary) => {
      return beneficiary.checked === false;
    });
    let beneficiariePriority = beneficiaryFeatures.filter(
      (beneficiariePriority) => {
        return (
          beneficiariePriority.checked === true &&
          beneficiariePriority.priority === ""
        );
      }
    );

    if (serviceAreaFeaturesData.length === serviceAreaFeatures.length) {
      message.error("please select Service Area");
      onFeatured();
      setModalId(2);
      return false;
    }
    if (beneficiaryData.length === beneficiaryFeatures.length) {
      message.error("please select Beneficiaries");
      onFeatured();
      setModalId(2);
      return false;
    }
    if (beneficiariePriority.length > 0) {
      message.error("please Add  Priority to Beneficiaries");
      onFeatured();
      setModalId(2);
      return false;
    }
    if (
      validateDescription(description) &&
      validateDescription(descriptionEsp) &&
      branchImage !== null &&
      branchName?.trim()?.length !== 0 &&
      branchNameEsp?.trim()?.length !== 0 &&
      selectedServiceArea.length !== 0 &&
      branchLevel !== adminLabels.selectBranchLevel &&
      (branchLevel === "2"
        ? parentBranches?.filter(
            (filterBranch) => filterBranch.checked === true
          )?.length !== 0
        : true)
    ) {
      let obj = {
        name: branchName,
        name_sp: branchNameEsp,
        info: description?.toString(),
        info_sp: descriptionEsp?.toString(),
        avatar: branchImage,
        service_areas: JSON.stringify(selectedServiceArea),
        level: branchLevel,
        priority: Number(
          beneficiaryFeatures
            .filter((beneficiaryFeature) => beneficiaryFeature.priority > 0)
            .map((value) => value.priority)
            .toString()
        ),
        parent_branch_id:
          branchLevel === "2"
            ? parentBranches?.filter((item) => item?.checked === true)?.[0]
                ?.branch_id
            : null,
      };

      let data = objectToFormData(obj);
      props.setLoaderState(true);
      AddBranch(
        data,
        (success) => {
          if (success.status === 200) {
            props.setLoaderState(false);
            Success(success.data.response.message);
            setBranchImage(null);
            setBranchName("");
            setServiceAreaFeatures([]);
            setBeneficiaryId("");
            setBranchLevel(adminLabels.selectBranchLevel);
            setFieldIsrequired(false);
            setBranchPriority(0);
            setDescription("");
            setDescriptionEsp("");
            setParentId("");
            setParentBranches([]);
            setSelectedBeneficiariesName([]);
            setSelectedServiceAreaName([]);
            props.getBranchesList(props);
            history.push("branches");
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            setFieldIsrequired(false);
            Remove_Data_Form_Reducers(props);
            push("/admin");
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
            setFieldIsrequired(false);
          }
        }
      );
    } else {
      setFieldIsrequired(true);
    }
  };

  const editBranch = () => {
    let data = serviceAreaFeatures.filter((serviceAreaFeature) => {
      return serviceAreaFeature.checked === false;
    });
    let beneficiaryData = beneficiaryFeatures.filter((beneficiary) => {
      return beneficiary.checked === false;
    });
    let beneficiariePriority = beneficiaryFeatures.filter(
      (beneficiariePriority) => {
        return (
          beneficiariePriority.checked === true &&
          beneficiariePriority.priority === ""
        );
      }
    );

    if (data.length === serviceAreaFeatures.length) {
      message.error("please select Service Area");
      onFeatured();
      setModalId(2);
      return false;
    }
    if (beneficiaryData.length === beneficiaryFeatures.length) {
      message.error("please select Beneficiaries");
      onFeatured();
      setModalId(2);
      return false;
    }
    if (beneficiariePriority.length > 0) {
      message.error("please Add  Priority to Beneficiaries");
      onFeatured();
      setModalId(2);
      return false;
    }
    if (
      validateDescription(description) &&
      validateDescription(descriptionEsp) &&
      branchImage !== null &&
      branchName?.trim()?.length !== 0 &&
      branchNameEsp?.trim()?.length !== 0 &&
      (serviceAreaFeatures?.filter(
        (selectedServiceArea) => selectedServiceArea?.checked === true
      )).length !== 0 &&
      branchLevel !== adminLabels.selectBranchLevel &&
      (branchLevel === "2"
        ? parentBranches?.filter(
            (filterBranch) => filterBranch.checked === true
          )?.length !== 0
        : true)
    ) {
      let obj = {
        name: branchName,
        name_sp: branchNameEsp,
        info: description?.toString(),
        info_sp: descriptionEsp?.toString(),
        avatar: branchImage,
        service_areas: JSON.stringify(
          serviceAreaFeatures
            .filter((selectFeature) => {
              if (selectFeature.checked === true) {
                return {
                  selectFeature,
                };
              }
            })
            ?.map((selectFeature) => {
              if (selectFeature?.checked === true) {
                return {
                  service_area_id: selectFeature?.service_area_id,
                  beneficiaries: beneficiaryFeatures?.filter(
                    (beneficiaryFeature) => {
                      return beneficiaryFeature?.checked === true;
                    }
                  ),
                };
              }
            })
        ),

        level: branchLevel,
        priority: 1,
        parent_branch_id:
          branchLevel === "2"
            ? parentBranches?.filter((item) => item?.checked === true)?.[0]
                ?.branch_id
            : null,
        branch_id: props?.match?.params?.id,
      };
      let data = objectToFormData(obj);
      props.setLoaderState(true);
      EditBranch(
        data,
        (success) => {
          if (success.status === 200) {
            props.setLoaderState(false);
            Success(success.data.response.message);
            setFieldIsrequired(false);
            props.getBranchesList(props);
            history.push("/admin/branches");
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            props.setLoaderState(false);
            Errors(error?.response?.data?.message);
            setFieldIsrequired(false);
            Remove_Data_Form_Reducers(props);
            push("/admin");
          } else {
            props.setLoaderState(false);
            Errors("Network Error");
            setFieldIsrequired(false);
          }
        }
      );
    } else {
      setFieldIsrequired(true);
    }
  };

  const menu1 = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">{adminLabels.level} 1</Menu.Item>
      <Menu.Item key="2">{adminLabels.level} 2</Menu.Item>
    </Menu>
  );

  return (
    <section className="mt-5 mb-4">
      <div className="flex self-center items-center ml-12  pb-5 sub-h3">
        <div
          onClick={() => goBack()}
          className="flex text-gray-400 items-center cursor-pointer"
        >
          <RiArrowDropLeftLine />
          <span> {adminLabels.back}</span>
        </div>
        <h3 className="font-bold text-lg mb-0 ml-10">
          {props.match.params.id
            ? adminLabels.editBranch
            : adminLabels.addNewBranch}
        </h3>
      </div>

      <div
        style={{ paddingBottom: "10px", paddingTop: "0px" }}
        className="container mx-auto lg:pl-32"
      >
        <div className="grid grid-cols-12 ">
          <div className="w-full col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-3 mx-4 sm:mx-4 lg:mx-0 mt-2 pl-0 sm:pl-0 lg:pr-2 ">
            <button
              onClick={(e) => {
                onFeatured(e);
                setModalId(2);
              }}
              className={` bg-white rounded-full py-3 px-2 text-gray-400 flex justify-between justify-center items-center w-full`}
            >
              <span
                className={`px-30 pl-3 pb-1  ${
                  serviceAreaFeatures?.filter(
                    (selectedServiceArea) =>
                      selectedServiceArea?.checked === true
                  )?.length !== 0
                    ? "text-black "
                    : "text-gray-400 "
                } `}
              >
                {serviceAreaFeatures?.filter(
                  (selectedServiceArea) => selectedServiceArea?.checked === true
                )?.length !== 0
                  ? (serviceAreaFeatures?.filter((selectedServiceArea) => selectedServiceArea?.checked === true))[0]?.[currentSelectedLangue === "english"? "name" : "info"]
                  : adminLabels.selectServiceArea}
              </span>
              <IoIosArrowForward />
            </button>
            {(serviceAreaFeatures?.filter(
              (selectedServiceArea) => selectedServiceArea?.checked === true
            )).length === 0 && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">
                {adminLabels.pleaseFillTheField}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="grid grid-cols-12 mt-1">
          <div className="w-full col-span-12 sm:col-span-12 md:col-span-6 lg:col-span-3 mx-4 sm:mx-4 lg:mx-0 mt-2 pl-0 sm:pl-0 lg:pr-2 ">
            <Dropdown
              className="mb-2 bg-white select-input py-2.5 px-3  justify-center rounded-full  "
              overlay={menu1}
              trigger={["click"]}
              disabled={props.loader === true ? true : false}
            >
              <a
                className="flex justify-between item-center ant-dropdown-link"
                style={{ color: "#000" }}
                onClick={(e) => e.preventDefault()}
              >
                <span
                  className={` ${
                    branchLevel === adminLabels.selectBranchLevel
                      ? "text-gray-400"
                      : "text-black"
                  } pl-1`}
                >
                  {branchLevel}
                </span>{" "}
                <DownOutlined
                  className="mt-2 "
                  style={{ color: "lightgray", fontWeight: "900" }}
                />
              </a>
            </Dropdown>
            {branchLevel === adminLabels.selectBranchLevel &&
            fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">
                {adminLabels.pleaseFillTheField}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="  col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 mx-2 sm:mx-2 lg:mx-0 lg:ml-4">
            <InputComponent
              className="  "
              placeholder={adminLabels.parentBranch}
              disabled={true}
              value={parentBranches
                ?.filter((filterBranch) => filterBranch.checked === true)
                ?.map((branch) => branch?.[currentSelectedLangue === "english" ? "name" : "name_sp"])}
            />
            {branchLevel === "2" &&
            parentBranches?.filter(
              (filterBranch) => filterBranch.checked === true
            )?.length === 0 &&
            fieldIsRequired === true ? (
              <span className="text-red-500 ">
                {adminLabels.pleaseFillTheField}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="grid grid-cols-12">
          <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 mx-2 sm:mx-2 lg:mx-0 ">
            <InputComponent
              placeholder={adminLabels.addBranchNameEng}
              value={branchName}
              onChange={(e) => setBranchName(e.target.value)}
            />
            {branchName?.trim()?.length === 0 && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">
                {adminLabels.pleaseFillTheField}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="  col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 mx-2 sm:mx-2 lg:mx-0 lg:ml-4">
            <InputComponent
              placeholder={adminLabels.addBranchNameEsp}
              value={branchNameEsp}
              onChange={(e) => setBranchNameEsp(e.target.value)}
            />
            {branchNameEsp?.trim()?.length === 0 && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">
                {adminLabels.pleaseFillTheField}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="grid grid-cols-12">
          <div className="col-span-12 lg:col-span-3 sm:col-span-6 md:col-span-6 mx-2 sm:mx-2 lg:mx-0 cursor-pointer mt-2">
            <input
              className="rounded-full py-3  absolute opacity-0  cursor-pointer"
              type={"file"}
              accept={".png, .jpg, .jpeg"}
              onChange={(e) => setBranchImage(e.target.files[0])}
            />
            <button className=" pb-4 bg-gray-400 rounded-full py-3 text-sm flex justify-center items-center w-full">
              <img
                style={{ cursor: "pointer" }}
                width="20"
                height="20"
                src={uploadImg.default}
                className="mr-1"
              />
              <span className="text-white">
                {branchImage !== null ? branchImage?.name : branchImage}
              </span>
            </button>
            {branchImage === null && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">
                {adminLabels.pleaseFillTheField}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="lg:mx-2 mt-3 pb-5 lg:mr-6  grid grid-cols-12 space-x-10">
          <div className=" col-span-12 lg:col-span-6 sm:col-span-12 md:col-span-12 mx-2 sm:mx-2 lg:mx-0 ">
            <ReactQuill
              className={"richTextBox "}
              value={description || ""}
              onChange={setDescription}
              placeholder={adminLabels.informationEng}
            />
            {!validateDescription(description) && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">
                {adminLabels.pleaseFillTheField}
              </span>
            ) : null}
          </div>
          <div className=" col-span-12 lg:col-span-6 sm:col-span-12 md:col-span-12 mx-2 sm:mx-2 lg:mx-0 ">
            <ReactQuill
              className={"richTextBox "}
              value={descriptionEsp || ""}
              onChange={setDescriptionEsp}
              placeholder={adminLabels.informationEsp}
            />
            {!validateDescription(descriptionEsp) &&
            fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">
                {adminLabels.pleaseFillTheField}
              </span>
            ) : null}
            {/* {descriptionEsp?.length === 0 && fieldIsRequired === true ? (
              <span className="text-red-500 mt-2">
                {adminLabels.pleaseFillTheField}
              </span>
            ) : (
              ""
            )} */}
          </div>
        </div>
        <div className="w-912/12  sm:w-12/12  lg:w-1/4 mt-3 lg:ml-2 lg:pr-4 px-2 sm:px-2 lg:px-0">
          <ButtonComponent
            className="bg-primary"
            title={adminLabels.save}
            onClick={props.match.params.id ? editBranch : createBranch}
            disabled={props.loader === true ? true : false}
            loader={props.loader === true ? true : false}
          />
        </div>
      </div>
      <Modal
        visible={functionalityModal}
        width={400}
        footer={false}
        onCancel={() => setFunctionalityModal(false)}
      >
        <div>
          <h4 className="font-bold text-2xm text-center text-gray-600	">
            {modalId === 1
              ? "Select Beneficiaries"
              : adminLabels.selectServiceArea}
          </h4>
        </div>
        <div className="divide-y divide-black-600 divide-solid mt-2">
          {modalId === 1
            ? beneficiaryFeatures.length
              ? beneficiaryFeatures.map((t, i) => {
                  return (
                    <div className="flex justify-between  text-left ">
                      <div className="flex items-center justify-center">
                        <img width={"20px"} src={t.avatar} alt="logo alt" />
                        <span className="pl-4">{t.name}</span>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="mr-4">
                          {modalId === 1 ? (
                            <div className="mr-2">
                              <InputComponent
                                placeholder="0"
                                width={70}
                                value={t?.priority}
                                disabled={t?.checked === false}
                                onChange={(e) =>
                                  changeBeneficiaryPriority(e, t.id)
                                }
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {/* <InputComponent
                              placeholder="Priority"
                              width={100}
                              
                            /> */}
                        </div>
                        {t.checked ? (
                          <img
                            style={{ width: " 40%", height: "40%" }}
                            src={checkboxActive.default}
                            onClick={() => onChecked(t, i)}
                          />
                        ) : (
                          <img
                            style={{ width: "40%", height: "40%" }}
                            src={checkbox.default}
                            onClick={() => onChecked(t, i)}
                          />
                        )}
                      </div>
                    </div>
                  );
                })
              : null
            : serviceAreaFeatures?.length
            ? serviceAreaFeatures?.map((t, i) => {
                return (
                  <>
                    <div className="flex justify-between  text-left py-5 ">
                      <div className="flex items-center justify-center">
                        <img width={"20px"} src={t?.avatar} alt="logo alt" />
                        <span className="pl-4">{currentSelectedLangue === "english" ? t?.name : t?.info}</span>
                      </div>
                      <div className="flex justify-between items-center">
                        {t?.checked ? (
                          <img
                            style={{ width: "100%", height: "100%" }}
                            src={checkboxActive.default}
                            onClick={() => onChecked1(t, i)}
                            // setBeneficiaryFeatures([])
                            //}}
                          />
                        ) : (
                          <img
                            style={{ width: "100%", height: "100%" }}
                            src={checkbox.default}
                            onClick={() => onChecked1(t, i)}
                            //  setBeneficiaryFeatures(t.beneficiaries)
                            //}}
                          />
                        )}
                      </div>
                    </div>
                    {beneficiaryFeatures?.length && t.checked === true
                      ? beneficiaryFeatures?.map((tt, i) => {
                          return (
                            <div className="flex justify-between  text-left px-10">
                              <div className="flex items-center justify-center">
                                <img
                                  width={"18px"}
                                  src={tt?.avatar}
                                  alt="logo alt"
                                />
                                <span className="pl-2 text-sm">{tt?.name}</span>
                              </div>
                              <div className="flex justify-between items-center">
                                <div className="mr-4">
                                  {modalId === 2 ? (
                                    <div
                                      className=" mb-2 mt-2 rounded-lg"
                                      style={{ width: 75 }}
                                    >
                                      <input
                                        type="number"
                                        className={`rounded-full py-1 px-4 w-full shadow `}
                                        // placeholder="0"
                                        onKeyDown={functionToPreventSignsFromNumberFromPricing}
                                        disabled={tt?.checked === false}
                                        value={tt?.priority}
                                        onChange={(e) =>
                                          Number(e.target.value) <= 0
                                            ? ""
                                            : changeBeneficiaryPriority(
                                                e,
                                                tt.id
                                              )
                                        }
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {/* <InputComponent
                                  placeholder="Priority"
                                  width={100}
                                  
                                /> */}
                                </div>
                                {tt?.checked ? (
                                  <img
                                    style={{ width: " 15%" }}
                                    src={checkboxActive?.default}
                                    onClick={() => onChecked(tt, i)}
                                  />
                                ) : (
                                  <img
                                    style={{ width: "15%" }}
                                    src={checkbox?.default}
                                    onClick={() => onChecked(tt, i)}
                                  />
                                )}
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </>
                );
              })
            : null}
        </div>
        {modalId === 1 ? (
          <button
            className="w-full bg-primary rounded-full py-3 text-white"
            onClick={() => {
              setFunctionalityModal(false);
              fetchIdOfBeneficiary(beneficiaryId);
            }}
            disabled={beneficiaryId === "" ? true : beneficiaryModalButton}
          >
            {adminLabels.apply}
          </button>
        ) : (
          <button
            className="w-full bg-primary rounded-full py-3 text-white"
            onClick={() => {
              let data = serviceAreaFeatures.filter((serviceAreaFeature) => {
                return serviceAreaFeature.checked === false;
              });
              let beneficiaryData = beneficiaryFeatures.filter(
                (beneficiary) => {
                  return beneficiary.checked === false;
                }
              );
              let beneficiariePriority = beneficiaryFeatures.filter(
                (beneficiariePriority) => {
                  return (
                    beneficiariePriority.checked === true &&
                    beneficiariePriority.priority === ""
                  );
                }
              );

              data.length === serviceAreaFeatures.length
                ? message.error("please select Service Area")
                : beneficiaryData.length === beneficiaryFeatures.length
                ? message.error("please select Beneficiaries")
                : beneficiariePriority.length > 0
                ? message.error("please Add  Priority to Beneficiaries")
                : setFunctionalityModal(false);

              selecteServiceAreas();
            }}
          >
            {adminLabels.apply}
          </button>
        )}
      </Modal>
      <ModalComponent
        modalTitle={adminLabels.selectParentBranch}
        filterModal={parentModal}
        closeModal={() => setParentModal(false)}
      >
        <div className="h-96 overflow-y-scroll px-2 select-branch-modal-branches-container py-2">
         
          {(props.match.params.id ? (parentBranches?.filter((item) => item?.branch_id !== props.match.params.id)) : (parentBranches))?.filter((item) => item?.parent_branch_id === null && item?.status === 1) ?.map((t, i) => {
            return (
              <div className="flex justify-between  text-left py-5 ">
                <div className="flex items-center justify-center">
                  <img width={"30px"} src={t.avatar} alt="logo alt" />
                  <span className="pl-4">{t?.[currentSelectedLangue === "english"? "name" : "name_sp"]}</span>
                </div>
                <div className="flex justify-between items-center">
                  {t.checked ? (
                    <img
                      style={{ width: "100%" }}
                      src={checkboxActive.default}
                      onClick={() => onCheckedParentBranch(t, i,t?.branch_id)}
                    />
                  ) : (
                    <img
                      style={{ width: "100%" }}
                      src={checkbox.default}
                      onClick={() => onCheckedParentBranch(t, i,t?.branch_id)}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <button
          className="w-full bg-primary rounded-full py-3 text-white mt-3"
          onClick={() => {
            setParentModal(false);
          }}
        >
          {adminLabels.apply}
        </button>
      </ModalComponent>
    </section>
  );
};
const mapStateToProps = (state) => {
  return {
    showBeneficiaries: state.beneficiariesList.beneficiaries,
    showServiceArea: state.serviceAreaList.serviceAreas,
    loader: state.loaderToggle.loaderState,
    showBranches: state.branchList.branches,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBeneificiariesList: () => {
      dispatch(FetchBeneficiariesAction());
    },
    getServiceAreaList: () => {
      dispatch(FetchServiceAreaAction());
    },
    getBranchesList: (props) => {
      dispatch(FetchBranchesAction(props));
    },
    setLogoutUser: (data) => {
      dispatch(LogoutUserAction(data));
    },
    removeBeneficiaries: () => {
      dispatch(DeleteBeneficiariesAtLogoutTime());
    },
    removeProviders: () => {
      dispatch(DeleteProvidersAtLogoutTime());
    },
    removeServiceAreas: () => {
      dispatch(DeleteServiceAreasAtLogoutTime());
    },
    removeBranches: () => {
      dispatch(DeleteBranchesAtLogoutTime());
    },
    removeServices: () => {
      dispatch(DeleteServicesAtLogoutTime());
    },
    removeServiceForms: () => {
      dispatch(DeleteServiceForms());
    },
    removeAppUsers: () => {
      dispatch(DeleteAppUsers());
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddNewBranch);
