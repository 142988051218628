import { SearchOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Menu, Modal, Spin, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { AiOutlineArrowDown, AiOutlinePlus } from "react-icons/ai";
import {
  RiArrowDropLeftLine,
  RiArrowDropRightLine,
  RiPencilLine,
} from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";
import { Errors } from "../../components/antdToast";
import TableComponent from "../../components/table";
import { GetsubAdmins } from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { Images } from "../../constant/Images";
import "./subAdminAccMng.css";
import { functionToConvertStringToCamelCase } from "../../config/utils";

const { dotPattern } = Images;

export default function SubAdminAccMng(props) {
  const { goBack, push } = useHistory();
  const [screenWidth, setScreenWidth] = useState(0);
  const [subAdmins, setSubAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [functionalityModal, setFunctionalityModal] = useState(false);
  const [adminInfo, setAdminInfo] = useState({});

  useEffect(() => {
    if (props?.match?.params?.subAdminID) {
      props?.location?.state === undefined &&
        push("/admin/sub-admin-account-manager");
    }
  }, []);

  useEffect(() => {
    fetchSubAdmin();

    window.addEventListener("resize", () => {
      setScreenWidth(window.outerWidth);
    });
    document.body.style.overflow = "auto";
    return function cleanup() {
      document.body.style.overflow = "auto";
    };
  }, []);
  const fetchSubAdmin = () => {
    GetsubAdmins(
      (res) => {
        let data = res?.data?.response?.detail
          ?.reverse()
          ?.map((subAdmin, index) => {
            return {
              ...subAdmin,
              no: index + 1,
              fullname: subAdmin?.name,
              email: subAdmin?.email,
              phone: subAdmin?.phone,
              address: subAdmin?.address,
            };
          });
        setSubAdmins(data);
        setLoading(false);
      },
      (error) => {
        if (error?.response?.status === 401) {
          Errors(error?.response?.data?.error?.message);
          setLoading(false);
        } else {
          Errors("Network Error");
          setLoading(false);
        }
      }
    );
  };

  const menu = (id, values) => (
    <Menu
      className="menu-class menu-main-class"
      style={{ borderRadius: 20 }}
      key={Math.random() * 10000000}
    >
      <Menu.Item
        key={Math.random() * 10000000}
        className="bg-white"
        style={{ borderRadius: 20 }}
        onClick={() => setFunctionalityModal(true)}
      >
        <div
          className="flex justify-between bg-white py-2 w-full  items-center"
          style={{ width: 160 }}
        >
          <div>
            <span>{adminLabels.subAdminDetails}</span>
          </div>
          <div>
            <RiArrowDropRightLine color="#5ab3f0" size={20} />
          </div>
        </div>
      </Menu.Item>

      <Menu.Item
        key={Math.random() * 10000000}
        className="bg-white"
        style={{ borderRadius: 20 }}
        onClick={() => handleMenu(id, values)}
      >
        <div
          className="flex justify-between bg-white py-2 w-full  items-center"
          style={{ width: 160 }}
        >
          <div>
            <span>{adminLabels.edit}</span>
          </div>
          <div>
            <RiPencilLine color="#5ab3f0" size={20} />
          </div>
        </div>
      </Menu.Item>
    </Menu>
  );

  const handleMenu = (id, subAdmin) => {
    push({
      pathname: `/admin/edit-admin/${id}`,
      state: { subAdmin: subAdmin },
    });
  };
  const columns = [
    {
      align: "center",
      title: adminLabels.no,
      dataIndex: "no",
      key: "no",
      width: 60,
      render: (text) => <a>{text}</a>,
    },
    {
      align: "center",
      title: adminLabels.name,
      dataIndex: "fullname",
      key: "fullname",
      width: 200,
      className: "capitalize",
      render: (fullname) => (
        <Tooltip placement="topLeft" title={fullname}>
          {fullname}
        </Tooltip>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.fullname.toLowerCase().includes(value.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.fullname.localeCompare(b.fullname),
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: adminLabels.email,
      dataIndex: "email",
      key: "email",
      width: 150,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.email.toLowerCase().includes(value.toLowerCase());
      },
      ellipsis: {
        showTitle: false,
      },
      render: (email) => (
        <Tooltip placement="topLeft" title={email}>
          {email}
        </Tooltip>
      ),
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.email.localeCompare(b.email),
      showSorterTooltip: false,
    },
    {
      align: "center",
      title: adminLabels.phone,
      key: "phone",
      dataIndex: "phone",
      width: 150,
      render: (phone) => (
        <Tooltip placement="topLeft" title={phone}>
          {phone}
        </Tooltip>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.phone.toLowerCase().includes(value.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.phone - b.phone,
      showSorterTooltip: false,
    },

    {
      align: "center",
      title: adminLabels.address,
      dataIndex: "address",
      key: "address",
      width: 180,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <span className="search-filter-dropdown">
            <Input
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              autoFocus
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          </span>
        );
      },
      filterIcon: () => {
        return <SearchOutlined style={{ color: "#ffff" }} />;
      },
      onFilter: (value, record) => {
        return record.address.toLowerCase().includes(value.toLowerCase());
      },
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.address.localeCompare(b.address),
      showSorterTooltip: false,
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },

    {
      title: adminLabels.action,
      key: "action",
      width: 80,

      render: (text, index) => (
        <div className="text-center">
          <Dropdown
            //overlay={menu}
            overlay={() => menu(text.id, text)}
            className={"flex"}
            trigger={["click"]}
            onClick={() => setAdminInfo(text)}
          >
            <div
              type="disable"
              shape={"round"}
              className="background-color-gray whiteTxt rounded-full py-1 justify-center cursor-pointer	"
            >
              <span>{adminLabels.view}</span>
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];
  return (
    <section className="">
      <Spin tip={adminLabels.loading} spinning={loading}>
        <div className="flex  items-center mt-5 flex-wrap sm:flex-wrap lg:flex-nowrap justify-end sm:justify-center lg:justify-between mr-2 sm:mr-2 lg:mr-0 ">
          <div className="pl-5 flex self-center justify-center items-center sub-h3">
            <div
              onClick={() => goBack()}
              className="flex text-gray-400 items-center cursor-pointer"
            >
              <RiArrowDropLeftLine />
              <span>{adminLabels.back}</span>
            </div>
            <h3 className="ml-10 ">{adminLabels.subAdminAccountManagement}</h3>
          </div>
          <div className=" flex mr-0 sm:mr-0 lg:mr-28 ">
            <Link
              to="/admin/add-admin"
              className="mx-4 md:mx-4  sm:mr-4 md:mr-4 lg:mr-2"
            >
              <Button
                shape={"round"}
                className="icon-button "
                icon={<AiOutlinePlus color="#5ab3f0" />}
              >
                {adminLabels.addNew}
              </Button>
            </Link>
            <CSVLink
              data={subAdmins?.map((item, index) => {
                return {
                  no: index + 1,
                  name: item?.fullname,
                  email: item?.email,
                  phone: item?.phone,
                  address: item?.address,
                  roles: item?.roles
                    ?.filter((item) => item.checked === true)
                    ?.map((adminRole) => adminRole.name)
                    ?.join(),
                };
              })}
              headers={[
                { label: adminLabels.no, key: "no" },
                { label: adminLabels.name, key: "name" },
                { label: adminLabels.email, key: "email" },
                { label: adminLabels.phone, key: "phone" },
                {
                  label: adminLabels.address,
                  key: "address",
                },
                {
                  label: adminLabels.roles,
                  key: "roles",
                },
              ]}
              filename={`${adminLabels.subAdminAccountManagement}.csv`}
              target="_blank"
            >
              <Button
                shape={"round"}
                className="icon-button "
                icon={<AiOutlineArrowDown color="#5ab3f0" />}
              >
                {adminLabels.exportCSV}
              </Button>
            </CSVLink>
          </div>
        </div>
        <div className="container mx-auto px-0.5 sm:px-1 md:px-1 lg:px-20 mt-5 ">
          <TableComponent
            columns={columns}
            data={subAdmins}
            scrolly="500px"
            pagination={{ defaultPageSize: 6, showSizeChanger: true }}
          />
        </div>
        <Modal
          visible={functionalityModal}
          width={400}
          footer={false}
          onCancel={() => setFunctionalityModal(false)}
        >
          <div>
            <h4 className="font-bold text-2xm text-center text-gray-600	">
              {adminLabels.subAdminDetails}
            </h4>
          </div>
          <div className=" h-80 overflow-y-scroll  Functionality-And-Features-modal-scroll pr-2">
            {/* {adminInfo} */}
            <div className="grid grid-cols-2 ml-2 ">
              <div className="grid ">
                <div className="mt-5">
                  <h5 className="font-black">{adminLabels.name}</h5>
                  <p className="text-gray-400">{adminInfo?.name}</p>
                </div>
              </div>
              <div className="grid grid-cols-2 ">
                <div className="col-span-12  lg:col-span-3 sm:col-span-6 md:col-span-6">
                  <div className=" mt-5">
                    <h5 className="font-black">{adminLabels.registration}</h5>
                    <p className="text-gray-400">
                      {moment(adminInfo?.created_at).format("DD-MMM-YYYY")}
                    </p>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 ">
                <div className="col-span-12  lg:col-span-3 sm:col-span-6 md:col-span-6">
                  <div className=" mt-5">
                    <h5 className="font-black">{adminLabels.email}</h5>
                    <p className="text-gray-400">{adminInfo?.email}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="ml-2">
              <div className="col-span-12  lg:col-span-3 sm:col-span-6 md:col-span-6">
                <div className=" mt-5">
                  <h5 className="font-black">{adminLabels.phone}</h5>
                  <p className="text-gray-400">{adminInfo?.phone}</p>
                </div>
              </div>
            </div>
            <div className="ml-2">
              <div className="col-span-12  lg:col-span-3 sm:col-span-6 md:col-span-6">
                <div className=" mt-5">
                  <h5 className="font-black">{adminLabels.address}</h5>
                  <p className="text-gray-400">{adminInfo?.address}</p>
                </div>
              </div>
            </div>
            <div className="ml-2">
              <div className="col-span-12  lg:col-span-3 sm:col-span-6 md:col-span-6">
                <div className=" mt-5">
                  <h5 className="font-black">{adminLabels.roles}</h5>
                  <div className="divide-y divide-black-600 divide-solid ">
                    {adminInfo?.roles?.length
                      ? adminInfo?.roles
                          ?.filter((item) => item.checked === true)
                          .map((t, index) => {
                            return (
                              <div
                                key={index}
                                className="flex justify-between items-center py-1 "
                              >
                                <span>
                                  {
                                    adminLabels?.[
                                      functionToConvertStringToCamelCase(t.name)
                                    ]
                                  }
                                </span>
                              </div>
                            );
                          })
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="w-full bg-primary rounded-full py-3 text-white flex justify-center space-x-2"
            onClick={() => handleMenu(adminInfo.id, adminInfo)}
          >
            <span>{adminLabels.edit}</span>
            <span>
              <RiPencilLine color="#ffff" size={20} />
            </span>
          </button>
        </Modal>
      </Spin>
    </section>
  );
}
