const initialState = {
  serviceAreas: [],
};

const GetServiceAreaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SERVICE_AREA_LIST": {
      return {
        ...state,
        serviceAreas: action.payload,
      };
    }
    case "REMOVE_SERVICE_AREAS": {
      return {
        ...state,
        serviceAreas: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default GetServiceAreaReducer;
