//service provider and worker label
const englishLabels = {
  emailAddress: "Email Address",
  password: "Password",
  logIn: "Log In",
  newOrdersToBeAssigned: "New Orders To Be Assigned",
  helloPartner: "hello partner",
  product: "Product",
  orderID: "Order ID",
  date: "Date",
  timeOfDelivery: "Time of delivery",
  action: "Action",
  viewDetails: "View Details",
  exportCSV: "Export CSV",
  assignedOrders: "Assigned Orders",
  myMessage: "My Message",
  additionalBudget: "Additional Budget",
  profile: "Profile",
  workers: "Workers",
  back: "Back",
  orderDate: "Order Date",
  deliveryDate: "Delivery date",
  userName: "User Name",
  additionalBudgetAmount: "Additional Budget Amount",
  status: "Status",
  additionalBudgets: "Additional Budgets",
  phone: "Phone",
  yearsOfExperience: "Years Of Experience",
  Address: "Address",
  registeredDate: "Registered Date",
  positionAndDesignation: "Position/Designation",
  workingHours: "Working Hours",
  states: "States",
  contactTechnicalSupport: "Contact Technical Support",
  support: "Support",
  changePassword: "Change Password",
  addNew: "Add New",
  addWorkerOfServiceProvider: "Add Worker Of Service Provider",
  editWorkerOfServiceProvider: "Edit Worker Of Service Provider",
  edit: "edit",
  personalDetails: "Personal Details",
  uploadImage: "Upload Image",
  name: "Name",
  phoneNo: "Phone No",
  save: "Save",
  enterOldPassword: "Enter old password",
  enterNewPassword: "Enter new password ",
  enterConfirmPassword: "Enter confirm password ",
  passwordRequired: "password required",
  update: "Update",
  currentStep: "Current Step",
  completeInfoAndDocsReceived: "Complete Info And Docs Received",
  orderInfoAndDocument: "Order Info And Document",
  theStepsToFlowUntilDelivery: "The Steps To Flow Until Delivery",
  phoneNumber: "Phone Number",
  serviceName: "Service Name",
  registeredDate: "Registered Date",
  workingHours: "Working Hours",
  countDown: "CountDown",
  orderStatus: "Order Status",
  dueDateOfService: "Due date of service",
  details: "Details",
  reassign: "Reassign",
  contactClient: "Contact Client",
  orderStatusProgress: "Order Status Progress",
  block: "Block",
  finishThisOrder: "Finish this Order",
  service: "Service",
  amount: "Amount",
  dueDate: "Due Date",
  addBill: "Add Bill",
  totalAmount: "Total Amount",
  sendToUser: "Send To User",
  reasonOfBlock: "Reason Of Block",
  reason: "Reason",
  send: "Send",
  parentProvider: "Parent Provider",
  contactCentral: "Contact Central",
  enterEmail: "Enter Email",
  weWillSendYouACodeToChangePassword:
    "we will send you a code to change password",
  pleaseInputYoureEmailAddress: "Please input your email address!",
  enterVerificationCode: "Enter Verification Code",
  confirm: "Confirm",
  serviceSelected: "Service Selected",
  clientInfo: "Client Info",
  regionSelected: "Region Selected",
  correctDocumentCoppies: "Correct Document Copies",
  downloadAll: "Download All",
  manageOrder: "Manage Order",
  ifInfoOrDocumentationIsIncomplete: "If Info or documentation is incomplete?",
  ifAllInfoIsCorrectAndComplete: "If all info is correct and complete?",
  assignManager: "Assign Manager",
  noManagementCapacity: "No Management Capacity",
  assignWorker: "Assign Worker",
  orderCapicity: "Order Capacity",
  workers: "Workers",
  finalizeAssignation: "Finalize Assignation",
  reassignButton: "Reassign Button",
  reasonForReassignment: "Reason for reassignment",
  receiveInitialInformation: "Receive initialiInformation",
  receiveFullInformationAndDocumentation:
    "Receive full information and documentation",
  managementOfTheService: "Management of the service",
  finalizationOfManagementOfService: "Finalization of management of service",
  serviceDelivered: "Service delivered",
  enterOrderStatus: "Enter order status",
  finishThisStep: "Finish this step",
  service: "Service",
  upload: "Upload",
  addBudget: "Add Budget",
  attachedDocuments: "Attached Documents",
  myDocuments: "My Documents",
  userDocuments: "User Documents",
  forgetPassword: "Forget Password",
  theInputIsNotvalidEMail: "The input is not valid E-mail",
  address: "Address",
  no: "No",
  email: "Email",
  changeforRequest: "Change for request",
  editBudget: "Edit Budget",
  selectedServiceName: "Selected Service Name",
  uploadFiles: "Upload Files",
  fileUploaded: "file Uploaded",
  estimatedTime: "Estimated Time",
  budget: "Budget",
  docuDocDeduction: "DocuDoc Deduction",
  supplementaryCharges: "Supplementary Charges",
  addTitle: "Add Title",
  addAmount: "Add Amount",
  grandTotal: "Grand Total",
  userWillPay: "User will pay",
  resend: "Resend",
  timeUntilDelivery: "Time until delivery",
  serviceAreaSelected: "Service Area Selected",
  branchSelected: "Branch Selected",
  business: "Business",
  company: "Company",
  region: "Region",
  city: "City",
  correctDocumentCoppies: "Correct Document Copies",
  requestInfoOrDocumentation: "Request Info Or Documentation",
  requestInfoDocument: "Request Info / Document",
  request: "Request",
  search: "search",
  viewMessage: "View Messages",
  noChatAvailable: "No Chat Available",
  documents: "Documents",
  notifications: "Notifications",
  messages: "Messages",
  message: "message",
  payment: "Payment",
  payments: "Payments",
  customer: "Customer",
  orders: "Orders",
  allOrders: "All Orders",
  completedOrders: "Completed",
  pendingOrders: "Pending",
  disputeOrders: "Dispute",
  disputedOrders: "Disputed",
  cancelledOrders: "Cancelled",
  user: "Client",
  sendMessage: "send message ...",
  loading: "Loading...",
  attachedDocumentCopies: "Attached Document Copies",
  client: "Client",
  workerDetails: "Worker Details",
  supplementary: "Supplementary",
  total: "Total",
  surname: "surname",
  cif: "CIF",
  worker: "Worker",
  serviceProvider: "Service Provider",
  pleaseFillTheField: "Please fill the field!",
  logOut: "Logout",
  timeType: "Time Type",
  orderNotification: "Order Notification",
  important: "Important",
  documentationWasMissingLabel: [
    "As Documentation was missing,",
    <br />,
    "The delivery time of the service",
    <br />,
    "is on hold until the full information",
    <br />,
    "is received",
  ],
  fromMondayToThursday: "From Monday To Thursday",
  addLunchHours: "Add Lunch Hours",
  friday: "Friday",
  monToThu: "Mon - Thu",
  fri: "Fri",
  lunchTime: "Lunch Time",
  lunch: "Lunch",
  disputeDetails: "Dispute Details",
  ifYouwantToContinueClickOnContinueOrderOrIfYouWantToCancelClickOnCancelOrder:
    "if you want to continue click on continue order or if you want to cancel click on cancel order",
  cancelOrder: "Cancel Order",
  continueOrder: "Continue Order",
  complete: "Complete",
  partial: "Partial",
  enterPercentYouwantToRefundUser: "Enter Percent You want To Refund User",
  remaining: "Remaining",
  partialAmountOf: "Partial Amount Of",
  partialAmount: "Partial Amount",
  ofPartialAmount: "of partial amount",
  forServiceProvider: "for Service Provider",
  orderAmount: "Order Amount",
  supplementaryAmount: "Supplementary Amount",
  userDocuments: "User Documents",
  orderChats: "Order Chats",
  information: "Information",
  infoBox: "Info Box",
  SendBudget: "Send budget",
  endDispute: "End Dispute",
  view: "View",
  requestDetail: "Request Detail",
  provider: "Provider",
  orderId: "Order Id",
  serviceFees: "Service Fees",
  supplementaryFees: "Supplementary Fees",
  docuDocFees: "DocuDoc Fees",
  providerAmount: "Provider Amount",
  serviceDetailedDescription: "Service Detailed Description",
  attachments: "Attachments",
  all: "All",
  yes: "Yes",
  no: "No",
  selfEmployed: "Self Employed",
  serviceForm: "Service Form",
  blocked: "Blocked",
  unblock: "Un Block",
  serviceInformation: "Service Information",
  additionalBudgetNumber: "Additional Budget Number",
  typeOfMovement: "Type of Movement",
  dateOfOrder: "Date of Order",
  nameAndSurnameOfClient: "Name and Surname of Client",
  companyName: "Company Name",
  cifOrNif: "CIF/NIF",
  companyAddress: "Company Address",
  dispute: "Dispute",
  discount: "Discount",
  sumOfAllSuplements: "Sum of all Supplements",
  vATServiceProvider: "IVA Service Provider",
  toBePayedServiceProvider: "To be Payed Service Provider",
  statusPenidngOrCompleted: "Status (Pending or Completed)",
  completionDate: "Completion Date",
  blockedOrders: "blocked Orders",
  downloadAllUserFiles: "download all user files",
  downloadAllFiles: "download all  files",
  selectedUserType: "Selected User Type",
  subject: "subject",
  adminDocuments: "Admin Documents",
  oldPasswordRequired: "Old Password Required",
  newPasswordRequired: "New Password Required!",
  confirmPasswordRequired: "Confirm Password Required!",
  confirmPasswordMismatch: "Confirm Password Mismatch!",
  passwordValidationText:
    "Password must be eight characters length,at least one uppercase letter, one lowercase letter, one number and one special character.",

  minute: "minute",
  Minute: "minute",
  minutes: "minutes",
  Minutes: "minutes",
  Hours: "hours",
  Hour: "hour",
  Week: "week",
  Day: "day",
  day: "day",
  days: "days",
  hour: "hour",
  hours: "hours",
  week: "week",
  weeks: "weeks",

  completed: "completed",
  pending: "pending",
  disputed: "disputed",
  cancelled: "cancelled",
  block: "block",
  accepted: "accepted",
  enterTime: "enter time",
  receiveInitialInformation: "Receive Initial Information",
  receiveFullInformationAndDocumentation:
    "Receive full information and documentation",
  managementOfTheService: "Management of the service",
  finalizationOfManagementOfService: "Finalization of management of service",
  serviceDelivered: "Service delivered",
  order: "order",
  yes: `yes`,
  no: `No`,
  orderPlaced: "Order Placed",
  orderCanceled: "Order Canceled",
  orderCompleted: "Order Completed",
  requestForFurtherDocumentation: "Request for further documentation",
  additionalBudgetRequest: "Additional Budget Request",
  monthly: "Monthly",
  unreadMessages: "Unread Messages",
  discountEuroServiceVAT: "Discount € (Service+VAT)",
  originalServiceVATPriceEstablished:
    "Original Service + VAT (Price Established)",
  originalServicePriceEstablished:
    "Original Service (Price established / 1.21)",
  discountServiceVAT: "Discount € (Service+VAT)",
  serviceDiscount: "Service Discount",
  vatToBePayedTwentyPercentOfServiceDiscount:
    "VAT to be payed (21% of Service-Discount)",
  serviceDiscountVAT: "Service-Discount+VAT",

  serviceProviderSeventyPercentWithoutVat:
    "Service Provider (70% Service without VAT)",
  vatServiceProvider: "VAT Service Provider",
  downloadBill: "Download Bill",
  bill: "Bill",
  liberatedPaymentPendingOrYes: "Liberated Payment (Pending or Yes) ",
  liberationDate: "Liberation Date",
  allOrderDispute: "Dispute",
  supplementaryDetails: "Supplementary details",
  orderBill: "order bill",
  supplementaryBill: "supplementary bill",
  servicePrice: "Service Price",
  pleaseIndicateTheServiceNameAndQuoteNumber:
    "Please indicate the Service Name and Quote Number",
  finishThisBudget: "finish this budget",
  finish: "finish",
  additionalBudgetBill: "Additional Budget Bill",
  download: "download",
  budgetCompleted: "Budget Completed",
  serviceBillServiceBillOfServiceProviderAdditionalBudgetBill:
    "Service Bill of Service Provider/Additional Budget Bill",
  suplementsBillOfServiceProvider: "Suplements Bill of Service Provider",
  budgetVATIncluded: "Budget (VAT included)",
  taxableIncome: "Taxable income",
  vATServiceProviderBaseTaxableIncome: "VAT Service Provider (taxable income)",
  messageToAvoidSharingEmailAndPhoneNumber:
    "Never get paid or pay someone outside of DocuDoc. You may open yourself up to scams or fraud, and lose DocuDoc Payment Protection.",
  billNeeded: "bill needed?",
  reimbursement: "Reimbursement",
  totalPricePaidByTheClient: "Total Price paid by the client",
  pendingToAccept: `Pending`,
};
const spanishLabels = {
  DoCuDocPartner: "DocuDoc Partner",
  emailAddress: "Dirección de correo electrónico",
  password: "Contraseña",
  logIn: "Iniciar sesión",
  newOrdersToBeAssigned: "Nuevos pedidos por asignar",

  product: "Nombre del servicio",
  orderID: "ID Pedido",
  date: "Fecha",
  timeOfDelivery: "Tiempo de entrega",
  action: "Acción",
  viewDetails: "Ver detalles",
  exportCSV: "Descargar CSV",
  assignedOrders: "Pedidos asignadas",
  myMessage: "Mis mensajes",
  additionalBudget: "Presupuestos adicionales",
  profile: "Perfil",
  workers: "Otros Usuarios",
  back: "Atrás",
  orderDate: "Fecha Pedido",
  deliveryDate: "Fecha Entrega",
  userName: "Nombre de usuario",
  additionalBudgetAmount: "Monto presupuesto adicional",
  status: "Estado",
  additionalBudgets: "Presupuestos adicionales",
  phone: "Teléfono",
  yearsOfExperience: "Años de experiencia",
  Address: "Dirección",
  registeredDate: "Fecha de registro",
  positionAndDesignation: "Categoría",
  workingHours: "Horas Laborales",
  states: "Comunidades atónomoas",
  contactTechnicalSupport: "Contacto soporte técnico",
  support: "soporte",
  changePassword: "Cambiar contraseña",
  addNew: "Añadir nuevo",
  addWorkerOfServiceProvider: "Añadir usuario",
  editWorkerOfServiceProvider: "Editar usuario",
  personalDetails: "Info",
  uploadImage: "Subir imágen",
  name: "Nombre",
  phoneNo: "Teléfono",
  save: "Guardar",
  enterOldPassword: "Introduzca contraseña actual",
  enterNewPassword: "Introduzca nueva contraseña ",
  enterConfirmPassword: "Confirme la contraseña",
  passwordRequired: "contraseña necesaria",
  update: "Actualizar",
  currentStep: "Paso actual",
  completeInfoAndDocsReceived: "Información y documentación recibida",
  orderInfoAndDocument: "Información y documento del pedido",
  theStepsToFlowUntilDelivery: "Los pasos a seguir hasta la entrega",
  phoneNumber: "Número de teléfono",
  serviceName: "Nombre del Servicio",
  countDown: "Cuenta regresiva",
  orderStatus: "Estado del pedido",
  dueDateOfService: "Fecha de entrega del servicio",
  details: "Detalles",
  reassign: "Reasignar",
  contactClient: "Contactar cliente",
  orderStatusProgress: "Progreso de estado del pedido",
  block: "Bloquear",
  finishThisOrder: "Pedido terminado y enviado",
  service: "Servicio",
  amount: "Monto",
  dueDate: "Fecha de entrega",
  addBill: "Añadir Factura",
  totalAmount: "Honorarios a recibir",
  sendToUser: "Enviar notificación de servicio entregado",
  reasonOfBlock: "Motivo del bloqueo del servicio",
  reason: "Razón",
  send: "Enviar",
  parentProvider: "Admin Central",
  contactCentral: "Contacto Admin Central",
  enterEmail: "Ingrese su correo electrónico",
  weWillSendYouACodeToChangePassword:
    "Le enviaremos un código a su correo electrónico, para que pueda cambiar la contraseña.",
  pleaseInputYoureEmailAddress:
    "¡Por favor ingrese su dirección de correo electrónico!",
  enterVerificationCode: "Introduzca el código de verificación",
  confirm: "Confirmar",
  serviceSelected: "Servicio seleccionado",
  clientInfo: "Información del cliente",
  regionSelected: "Región seleccionada",
  correctDocumentCoppies: "Información y documentación del cliente",
  downloadAll: "Descargar todo",
  manageOrder: "Asignar pedido",
  ifInfoOrDocumentationIsIncomplete: "¿Info o documentación incompleta?",
  ifAllInfoIsCorrectAndComplete: "Información es correcta y completa?",
  assignManager: "Asignar Usuario Partner",
  noManagementCapacity: "Sin capacidad de gestión para este pedido",
  assignWorker: "Asignar Usuario Partner",
  orderCapicity: "Capacidad de pedidos",
  finalizeAssignation: "Finalizar la asignación",
  reassignButton: "Reasignar",
  reasonForReassignment: "Razón de la reasignación",
  receiveInitialInformation: "Información inicial recibida",
  receiveFullInformationAndDocumentation:
    "Toda la información y documentación necesaria recibida",
  managementOfTheService: "Gestión del Servicio contratado",
  finalizationOfManagementOfService:
    "Finalización de la gestión del servicio contratado",
  serviceDelivered: "Servicio entregado",
  enterOrderStatus: "Introduzca el estado del servicio",
  finishThisStep: "Paso completado",
  upload: "Subir",
  addBudget: "Enviar presupuesto",
  attachedDocuments: "Documentos adjuntos",
  myDocuments: "Mis documentos",
  userDocuments: "Documentos del cliente",

  helloPartner: "Hola  Partner",

  forgetPassword: "¿Has olvidado tu contraseña?",
  theInputIsNotvalidEMail: "Correo electrónico inválido",
  address: "Dirección",
  no: "No",
  email: "Correo electrónico",
  changeforRequest: "Cambio por solicitud",
  editBudget: "Editar presupuesto",
  selectedServiceName: "Nombre del servicio seleccionado",
  uploadFiles: "Subir archivo",
  fileUploaded: "Archivo subido",
  estimatedTime: "Entega estimada",
  budget: "presupuesto",
  docuDocDeduction: "Deducción DocuDoc",
  supplementaryCharges: "Suplidos",
  addTitle: "Añadir título",
  addAmount: "Añadir monto",
  grandTotal: "Total a recibir",
  userWillPay: "Precio total del presupuesto que pagará el usuario",
  resend: "Enviar presupuesto",
  timeUntilDelivery: "Tiempo hasta la entrega del servicio",
  serviceAreaSelected: "Área de servicio",
  branchSelected: "Rama",
  business: "Empresa",
  company: "Empresa",
  region: "Autónoma",
  city: "Ciudad",
  correctDocumentCoppies: "Documentación",
  requestInfoOrDocumentation: "Solicitar información o documentación.",
  requestInfoDocument: "Solicitud de información / documentación",
  request: "Petición",
  search: "búsqueda",
  viewMessage: "Ver mensajes",
  noChatAvailable: "No hay chat disponible",
  documents: "documentos",
  notifications: "Notificaciones",
  messages: "Mensajes",
  message: "Mensaje",
  payment: "Pago",
  payments: "Pagos",
  customer: "Cliente",
  orders: "Pedidos Asignados",
  allOrders: "Todos los pedidos",
  completedOrders: "Finalizados",
  pendingOrders: "Pendientes",
  disputeOrders: "Disputar",
  disputedOrders: "Disputados",
  cancelledOrders: "Cancelados",
  user: "Cliente",
  sendMessage: "Contactar proveedor del servicio",
  loading: "Cargando...",
  attachedDocumentCopies: "Attached Document Copies",
  client: "Cliente",
  workerDetails: "Trabajadora detalles",
  supplementary: "Suplemento administrativo",
  total: "Total",
  surname: "Apellido",
  cif: "CIF",
  worker: "Usuario",
  serviceProvider: "Proveedor de servicio",
  pleaseFillTheField: "¡Por favor rellene el campo!",
  logOut: "Cerrar sesión",
  timeType: "Tipo de tiempo",
  orderNotification: "Notificación del pedido",
  important: "Importante",
  fromMondayToThursday: "De lunes a jueves",
  addLunchHours: "Pausa para almorzar",
  friday: "Viernes",
  monToThu: "Lun - Jue",
  fri: "Vie",
  lunchTime: "Pausa del mediodía",
  lunch: "almorzar",
  disputeDetails: "Dispute Details",
  ifYouwantToContinueClickOnContinueOrderOrIfYouWantToCancelClickOnCancelOrder: `Si desea continuar con el pedido, haga clic en "Continuar". Si desea cancelar el pedido, haga clic en "Cancelar".`,
  cancelOrder: "Cancelar",
  continueOrder: "Continuar",
  complete: "Completo",
  partial: "Parcial",
  enterPercentYouwantToRefundUser:
    "Ingrese el porcentaje que desea reembolsar al usuario",
  remaining: "Restante",
  partialAmountOf: "Cantidad parcial de",
  partialAmount: "Cantidad parcial",
  ofPartialAmount: "de cantidad parcial",
  forServiceProvider: "Para el Partner",
  orderAmount: "Monto del pedido",
  supplementaryAmount: "Cantidad suplementaria",
  orderChats: "pedidos chat",
  pleaseEnterYourPassword: "¡Por favor, introduzca su contraseña!",
  information: "Información",
  infoBox: "Info Box",
  SendBudget: "Enviar presupuesto",
  documentationWasMissingLabel: [
    "Como faltaba documentación,",
    <br />,
    "El tiempo de entrega del servicio.",
    <br />,
    "está en espera hasta la información completa",
    <br />,
    "Esta recibido",
  ],
  endDispute: "Disputa final",
  view: "Detalles",
  requestDetail: "Mensaje a cliente",
  provider: "Partner",
  orderId: "ID Pedido",
  serviceFees: "Tarifas de servicios",
  supplementaryFees: "Suplementos Administrativos",
  docuDocFees: "Parte DocuDoc",
  providerAmount: "Parte Partner",
  serviceDetailedDescription: "Descripción detallada del servicio",
  attachments: "Attachments",
  all: "Todos",
  yes: "Sí",
  no: "No",
  selfEmployed: "Self Employed",
  CIF: "CIF",
  serviceForm: "Servicio Formulario",
  blocked: "bloqueados",
  unblock: "desatascar",
  serviceInformation: "Información",
  additionalBudgetNumber: "Additional Budget Number",
  typeOfMovement: "Tipo de Movimiento",
  dateOfOrder: "Fecha Pedido",
  nameAndSurnameOfClient: "Nombre y Apellido Cliente",
  companyName: "Nombre Empresa",
  cifOrNif: "CIF/NIF",
  companyAddress: "Dirección Empresa",
  dispute: "Disputa",
  discount: "Descuento",
  sumOfAllSuplements: "Suma Suplidos",
  vATServiceProvider: "IVA Service Provider",
  toBePayedServiceProvider: "Pagar Prestador de Servicio",
  statusPenidngOrCompleted: "Status (Penidng or Completed)",
  completionDate: "Completion Date",
  blockedOrders: "Pedidos bloqueados",
  downloadAllUserFiles: "descargar todos los archivos de usuario",
  downloadAllFiles: "descargar todos los archivos",
  selectedUserType: "Tipo de usuario seleccionado",

  subject: "Sujeto",
  adminDocuments: "Documentos de administración",
  oldPasswordRequired: "Se requiere contraseña anterior!",
  newPasswordRequired: "Nueva contraseña requerida!",
  confirmPasswordRequired: "Confirmar contraseña requerida!",
  confirmPasswordMismatch: "Confirmar discrepancia de contraseña!",
  passwordValidationText:
    "La contraseña debe tener ocho caracteres, al menos una letra mayúscula, una letra minúscula, un número y un carácter especial",
  minute: "minuto",
  minutes: "minutos",
  Minute: "minuto",
  Minutes: "minutos",
  Hours: "horas",
  Hour: "hora",
  Week: "semana",
  Day: "día",
  day: "día",
  days: "días",
  hour: "hora",
  hours: "horas",
  week: "semana",
  weeks: "semanas",
  completed: "terminado",
  pending: "Pendientes",
  disputed: "disputed",
  cancelled: "cancelado",
  block: "bloquear",
  accepted: "aceptado",
  enterTime: "ingresar tiempo",

  receiveInitialInformation: "Información inicial recibida",
  receiveFullInformationAndDocumentation: "Información necesaria recibida",
  managementOfTheService: "Prestación del servicio iniciada",
  finalizationOfManagementOfService: "Prestación del servicio finalizada",
  serviceDelivered: "Servicio entregado",
  order: "pedido",
  yes: `Sí`,
  no: `No`,

  orderPlaced: "Pedido confirmado",
  orderCanceled: "Pedido cancelado",
  orderCompleted: " Pedido entregado",
  requestForFurtherDocumentation:
    " Solicitud de información. Por favor contacte con el profesional.",
  additionalBudgetRequest: "Solicitud de presupuesto adicional",
  monthly: "Mensual",
  unreadMessages: "mensajes no leídos",
  discountEuroServiceVAT: "Descuento € (Servicio+IVA)",
  originalServiceVATPriceEstablished: "Servicio Original + IVA",
  originalServicePriceEstablished:
    "Original Service (Price established / 1.21)",
  discountServiceVAT: "Descuento € (Servicio+IVA)",
  serviceDiscount: "Servicio-Descuento (Base Imponible)",
  vatToBePayedTwentyPercentOfServiceDiscount: "IVA a pagar (21%)",
  serviceDiscountVAT: "Importe factura",

  serviceProviderSeventyPercentWithoutVat: "Servicio Proveedor (70%)",
  vatServiceProvider: "IVA Service Provider",
  downloadBill: "Descargar factura",
  bill: "factura",
  liberatedPaymentPendingOrYes: "Pago liberado (Pendiento / Pagado)",
  liberationDate: "Día de liberación",
  allOrderDispute: "Por Disputar",
  supplementaryDetails: "Suplementarios detalles",
  orderBill: "Factura del servicio",
  supplementaryBill: " Facturas Suplidos",
  servicePrice: "Precio del servicio",
  pleaseIndicateTheServiceNameAndQuoteNumber:
    "Indique el nombre del asunto presupuestado seguido del número del presupuesto indicado en el PDF adjunto.",
  finishThisBudget: "terminar este presupuesto",
  finish: "Finalizar",
  additionalBudgetBill: "Factura",
  download: "descargar",
  budgetCompleted: "Presupuesto finalizado",
  serviceBillServiceBillOfServiceProviderAdditionalBudgetBill:
    "Factura Servicio Prestador del Servicio",
  suplementsBillOfServiceProvider: "Factura Suplements Prestador del Servicio",
  budgetVATIncluded: "Presupuesto (IVA incluido)",
  taxableIncome: "Base Imponible",
  vATServiceProviderBaseTaxableIncome: "21% de IVA",
  messageToAvoidSharingEmailAndPhoneNumber:
    "Perderá la garantía de reembolso, si paga servicios fuera de DocuDoc.",
  billNeeded: "¿Se necesita factura?",
  reimbursement: "Reembolso",
  totalPricePaidByTheClient: "Precio total pagado por el cliente",
  pendingToAccept: `Pendientes de aceptación`,
};

let language = localStorage.getItem("primaryLanguage");
const providerLabels = language === "english" ? englishLabels : spanishLabels;

export { providerLabels, spanishLabels, englishLabels };
