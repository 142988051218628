import { Badge, Button, Dropdown, Menu } from "antd";
import { onValue, ref } from "firebase/database";
import React, { useContext, useEffect, useState } from "react";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { DeleteAppUsers } from "../../Redux/actions/fetchAppUsersAction";
import { DeleteBeneficiariesAtLogoutTime } from "../../Redux/actions/fetchBeneficiariesAction";
import { DeleteBranchesAtLogoutTime } from "../../Redux/actions/fetchBranchesAction";
import {
  DeleteProvidersAtLogoutTime,
  FetchProvidersAction,
} from "../../Redux/actions/fetchProvidersAction";
import { DeleteServiceAreasAtLogoutTime } from "../../Redux/actions/fetchServiceAreaAction";
import { DeleteServiceForms } from "../../Redux/actions/fetchServiceFormsAction";
import { DeleteServicesAtLogoutTime } from "../../Redux/actions/fetchServicesAction";
import { LoaderStateAction } from "../../Redux/actions/loaderStateAction";
import { LogoutUserAction } from "../../Redux/actions/logoutAction";
import { firebaseDatabase } from "../../config/Firebase";
import {
  GetAdditonalBudget,
  GetAllAdminMessagesMethod,
  GetAllOrdersMethod,
  GetHelpMessagesMethod,
  GetPayouts,
  GetWorkwithUsMethod,
} from "../../config/api";
import { adminLabels } from "../../config/label/adminLabels";
import { Images } from "../../constant/Images";
import { SessionContext } from "../../store/index";
import { Errors } from "../antdToast";
import { Remove_Data_Form_Reducers } from "../dataRemoveOnLogout";
import "./AppHeader.css";

function AppHeader(props) {
  const { push, location } = useHistory();
  const [collapse, setCollapse] = React.useState(false);
  const [subAdminRoles, setSubAdminRoles] = useState(
    props?.loginInfo?.admin?.roles
  );
  const [allBookingOrders, setAllBookingOrders] = useState([]);
  const [budgetResponse, setBudgetResponse] = useState([]);
  const [helpUnRelatedToService, setHelpUnRelatedToService] = useState([]);
  const [workWithUsList, setWorkWithUsList] = useState([]);

  const [ordersPayouts, setOrdersPayouts] = useState([]);
  const [disputeOrdersPayouts, setDisputeOrdersPayouts] = useState([]);
  const [additional_budgetPayouts, setAdditional_budgetPayouts] = useState([]);

  const [adminMessages, setAdminMessages] = useState([]);
  const [messages, setMessages] = useState([]);
  const [serviceProviderUserMessages, setServiceProviderUserMessages] =
    useState([]);

  const [serviceProviderMessages, setServiceProviderMessages] = useState([]);

  const [orderCount, setOrderCount] = useState([]);

  const [menus, setMenus] = React.useState([
    {
      name: adminLabels.dashboard,
      id: 1,
      sub: [],
      route: "/admin/dashboard",
      menuId: "1",
      checked: false,
    },
    {
      name: adminLabels.subAdminAccountManagement,
      id: 2,
      sub: [
        {
          name: adminLabels.createAndManageSubAdmins,
          route: "/admin/sub-admin-account-manager",
        },
      ],
      collapse: false,
    },
    {
      name: adminLabels.userAccountManagement,
      menuId: "2",
      checked: false,
      id: 3,
      sub: [],
      route: "/admin/user-account-management",
      collapse: false,
    },
    {
      name: adminLabels.adminOfserviceProviderAccountManagement,
      menuId: "3",
      checked: false,
      id: 4,
      route: "/admin/admin-service-provider",
      sub: [],
      collapse: false,
    },
    {
      name: adminLabels.workerOfServiceProvidersAccountManagement,
      menuId: "4",
      checked: false,
      id: 5,
      route: "/admin/worker-service-provider",
      sub: [],
      collapse: false,
    },
    {
      name: adminLabels.categoriesManagement,
      menuId: "5",
      checked: false,
      id: 6,
      //route: "/admin/category-management",
      sub: [
        {
          name: adminLabels.branches,
          route: "/admin/branches",
        },
        {
          name: adminLabels.services,
          route: "/admin/services",
        },
      ],
      collapse: false,
    },
    {
      name: adminLabels.bookingsManagement,
      menuId: "6",
      checked: false,
      id: 7,
      sub: [],
      route: "/admin/booking-management",
      collapse: false,
      // budget: "100",
    },
    {
      name: adminLabels.budgetResponse,
      menuId: "7",
      checked: false,
      id: 8,
      sub: [],
      route: "/admin/budget-response",
      collapse: false,
      // budget: "5",
    },
    {
      name: adminLabels.workWithUsRequestsManagement,
      menuId: "8",
      checked: false,
      id: 9,
      sub: [],
      route: "/admin/work-request-management",
      collapse: false,
      // budget: 10,
    },
    {
      name: adminLabels.promoCodes,
      menuId: "9",
      checked: false,
      id: 9,
      sub: [],
      route: "/admin/promo-codes",
      collapse: false,
    },
    // {
    //   name: adminLabels.myLogs,
    //   menuId: "10",
    //   checked: false,
    //   id: 10,
    //   sub: [],
    //   route: "/admin/my-logs",
    //   collapse: false,
    // },
    {
      name: adminLabels.help,
      menuId: "11",
      checked: false,
      id: 12,
      sub: [],
      route: "/admin/help",
      collapse: false,
    },
    {
      name: adminLabels.messages,
      menuId: "112",
      checked: false,
      id: 12,
      sub: [],
      route: "/admin/messages",
      collapse: false,
    },
    {
      name: adminLabels.payouts,
      menuId: "12",
      checked: false,
      id: 13,
      sub: [],
      route: "/admin/payouts",
      collapse: false,
      // budget: 10,
    },
    {
      name: adminLabels.myEarnings,
      menuId: "13",
      checked: false,
      id: 14,
      sub: [],
      route: "/admin/my-earnings",
      collapse: false,
    },
    {
      name: adminLabels.dynamicForms,
      menuId: "14",
      checked: false,
      id: 15,
      sub: [],
      route: "/admin/Dynamic-forms-list",
      collapse: false,
    },
    {
      name: adminLabels.faqs,
      menuId: "16",
      checked: false,
      id: 17,
      sub: [],
      route: "/admin/faqs",
      collapse: false,
    },
    {
      name: adminLabels.payments,
      menuId: "17",
      checked: false,
      id: 17,
      sub: [],
      route: "/admin/incomes-payments-bills",
      collapse: false,
    },
  ]);

  const { session, setSession } = useContext(SessionContext);
  const getOrderUnreadCountData = (orderID) => {
    const getData = ref(firebaseDatabase, `orders/${orderID}/adminUnread`);
    let count = 0;
    onValue(getData, (snapshot) => {
      const data = snapshot?.val();
      if (data !== null) {
        setOrderCount((prev) => [...prev, data]);
      } else {
        setOrderCount((prev) => [...prev, 0]);
      }
    });
  };
  const changeLanguage = (lang) => {
    localStorage.setItem("primaryLanguage", lang);
    window.location.reload();
  };

  const allOrdersOfProviders = () => {
    GetAllOrdersMethod(
      (success) => {
        if (success?.status === 200) {
          let mappedOut = success?.data?.response?.detail;
          setAllBookingOrders(mappedOut);
        }
      },
      (fail) => {
        if (fail !== null) {
          if (fail?.response?.status === 409) {
            Errors(fail?.response?.data?.error?.message);
          } else if (fail?.response?.status === 401) {
            Errors(fail?.response?.data?.error?.message);
          } else {
            Errors("Network Error");
          }
        }
      }
    );
  };
  const allMessagesOfUSerAndServiceProvider = () => {
    GetAllAdminMessagesMethod(
      (success) => {
        if (success?.status === 200) {
          let mappedOut = success?.data?.response?.detail;

          setMessages(
            mappedOut?.map((item, index) => {
              return {
                ...item,
                // messageCount: getOrderUnreadCountData(item?.order_id),
                unreadCount: getOrderUnreadCountData(
                  item?.order_id,
                  setOrderCount
                ),
              };
            })
          );
        }
      },
      (fail) => {
        if (fail !== null) {
          if (fail?.response?.status === 409) {
            Errors(fail?.response?.data?.error?.message);
          } else if (fail?.response?.status === 401) {
            Errors(fail?.response?.data?.error?.message);
            push("/admin");
          } else {
            Errors("Network Error");
          }
        }
      }
    );
  };
  const fetchAdditionalBudgets = () => {
    GetAdditonalBudget(
      (success) => {
        let data = success?.data?.response?.detail;

        setBudgetResponse(data);
      },
      (fail) => {
        if (fail !== null) {
          if (fail?.response?.status === 409) {
            Errors(fail?.response?.data?.error?.message);
          } else if (fail?.response?.status === 401) {
            Errors(fail?.response?.data?.error?.message);
            push("/admin");
          } else {
            Errors("Network Error");
          }
        }
      }
    );
  };
  const getHelp = () => {
    GetHelpMessagesMethod(
      (res) => {
        let filterArrayUnRelatedService = res.data.response.detail.filter(
          (help, index) => {
            return help?.is_service_related === (0 || "0");
          }
        );

        setHelpUnRelatedToService(
          filterArrayUnRelatedService?.filter((item) => item?.is_read === 0)
        );
      },
      (err) => {
        if (err?.response?.status === 500) {
          Errors(err?.response?.data?.error?.message);
        } else if (err?.response?.status === 422) {
          Errors(err?.response?.data?.error?.message);
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  const getWorkWithUs = () => {
    GetWorkwithUsMethod(
      (success) => {
        if (success?.status === 200) {
          const data = success?.data?.response.detail?.filter(
            (item) => item?.is_read === 0
          );
          setWorkWithUsList(data);
        }
      },
      (error) => {
        if (error?.response?.status === 401) {
          Errors(error?.response?.data?.message);
          push("/admin");
        } else {
          Errors("Network Error");
        }
      }
    );
  };
  const fetchPayouts = () => {
    GetPayouts(
      (success) => {
        let orders = success.data.response.detail.orders;
        setOrdersPayouts(
          orders
            ?.filter((item) => item?.status !== "disputed")
            ?.filter((item) => item?.payment_released === 0)
        );
        setDisputeOrdersPayouts(
          orders
            ?.filter((item) => item?.status === "disputed")
            ?.filter((item) => item?.payment_released === 0)
        );
        setAdditional_budgetPayouts(
          success.data.response.detail.additional_budget?.filter(
            (item) => item?.payment_released === 0
          )
        );
      },
      (err) => {
        if (err !== null) {
          if (err?.response?.status === 409) {
            Errors(err?.response?.data?.error?.message);
          } else if (err?.response?.status === 401) {
            Errors(err?.response?.data?.error?.message);
          } else {
            Errors("Network Error");
          }
        }
      }
    );
  };

  const langMenu = (
    <Menu>
      {localStorage.getItem("primaryLanguage") === "english" ? (
        <Menu.Item
          onClick={() => changeLanguage("spanish")}
          icon={
            <img
              src={require(`../../assets/flags/ES.png`).default}
              style={{ width: 15, height: 15, Radius: 20 }}
            />
          }
        >
          <b>Es</b>
        </Menu.Item>
      ) : (
        <Menu.Item
          onClick={() => changeLanguage("english")}
          icon={
            <img
              src={require(`../../assets/flags/US.png`).default}
              style={{ width: 15, height: 15, Radius: 20 }}
            />
          }
        >
          <b>Eng</b>
        </Menu.Item>
      )}
    </Menu>
  );
  useEffect(() => {
    props.loginInfo?.admin?.status === "sub_admin" &&
      setMenus(
        menus.map((menu) => {
          return {
            ...menu,
            checked: subAdminRoles?.filter(
              (subAdminRole) => subAdminRole?.menuId === menu?.menuId
            )[0]?.checked,
          };
        })
      );
    if (props.loginInfo?.admin?.status === "sub_admin") {
      if (
        menus?.some((item) => item.menuId === "11" && item.checked === true)
      ) {
        getHelp();
      }
      if (
        menus?.some((item) => item.menuId === "12" && item.checked === true)
      ) {
        fetchPayouts();
      }
      if (menus?.some((item) => item.menuId === "8" && item.checked === true)) {
        getWorkWithUs();
      }
      if (menus?.some((item) => item.menuId === "6" && item.checked === true)) {
        allOrdersOfProviders();
      }
      if (
        menus?.some((item) => item.menuId === "112" && item.checked === true)
      ) {
        allMessagesOfUSerAndServiceProvider();
      }
      if (menus?.some((item) => item.menuId === "7" && item.checked === true)) {
        fetchAdditionalBudgets();
      }
    } else {
      getHelp();
      fetchPayouts();
      getWorkWithUs();
      allOrdersOfProviders();
      allMessagesOfUSerAndServiceProvider();
      fetchAdditionalBudgets();
    }
  }, []);

  useEffect(() => {
    setMenus((prev) => {
      return prev?.map((item) => {
        if (item?.menuId === "6") {
          return {
            ...item,
            count: allBookingOrders?.filter(
              (item) =>
                ["dispute", "cancelled"]?.includes(item?.status) ||
                item.is_reassign === 1
            )?.length,
          };
        } else if (item?.menuId === "7") {
          return {
            ...item,
            count: budgetResponse.filter((fItem) => fItem.is_read_admin === 0)
              ?.length,
          };
        } else if (item?.menuId === "11") {
          return {
            ...item,
            count: helpUnRelatedToService?.length,
          };
        } else if (item?.menuId === "8") {
          return {
            ...item,
            count: workWithUsList?.length,
          };
        } else if (item?.menuId === "12") {
          return {
            ...item,
            count:
              ordersPayouts?.length +
              disputeOrdersPayouts?.length +
              additional_budgetPayouts?.length,
          };
        } else if (item?.menuId === "112") {
          return {
            ...item,
            count: orderCount?.filter(
              (item) => ![null, undefined, 0].includes(item)
            ).length,
          };
        } else {
          return item;
        }
      });
    });
  }, [
    allBookingOrders,
    budgetResponse,
    helpUnRelatedToService,
    workWithUsList,
    ordersPayouts,
    disputeOrdersPayouts,
    additional_budgetPayouts,
    serviceProviderUserMessages,
    serviceProviderMessages,
    orderCount,
  ]);

  useEffect(() => {
    const messagesWithTheCount = messages?.map((item) => {
      return { ...item, messageCount: getOrderUnreadCountData(item?.order_id) };
    });
    setServiceProviderUserMessages(messagesWithTheCount);
  }, [messages]);
  const onLogout = () => {
    setSession({ userName: "" });
    Remove_Data_Form_Reducers(props);
    push("/admin");
  };

  const actionRoute = (e, sub) => {
    setCollapse(false);
    push(e);
  };
  const func = (a, b, bool) => {
    let array;
    props.loginInfo?.admin?.status === "sub_admin"
      ? (array = menus
          .filter((check) => check.checked === true)
          .map((z, x) => {
            if (x === b) {
              return {
                ...z,
                collapse: bool,
              };
            } else {
              return {
                ...z,
              };
            }
          }))
      : (array = menus.map((z, x) => {
          if (x === b) {
            return {
              ...z,
              collapse: bool,
            };
          } else {
            return {
              ...z,
            };
          }
        }));
    setMenus(array);
  };
  const menu = (
    <Menu
      defaultSelectedKeys={["1"]}
      className="menu-class custom-dropdown-styling "
    >
      {props.loginInfo?.admin?.status === "sub_admin"
        ? menus.length
          ? menus
              .filter((check) => check.checked === true)
              .map((t, i) => {
                let menuItemKey = t.id.toString();
                if (t.sub.length) {
                  return (
                    <div
                      className={`px-3 ant-dropdown-menu-title-content cursor-pointer ${
                        t.collapse ? "" : "on-hover-behavoir"
                      }`}
                      key={menuItemKey}
                      onClick={() => func(t, i, !t.collapse)}
                    >
                      <div className="flex items-center ">
                        {t.collapse ? (
                          <FaAngleDown
                            size={15}
                            className="mr-4"
                            color={"#5ab3f0"}
                          />
                        ) : (
                          <FaAngleRight size={15} className="mr-4" />
                        )}
                        {t.name}
                      </div>
                      {t.collapse && t.sub?.length
                        ? t.sub.map((a, b) => {
                            return (
                              <Menu.Item
                                key={b}
                                className={
                                  location.pathname === a.route
                                    ? "activeMenuStyle  "
                                    : ""
                                }
                                onClick={() => actionRoute(a.route)}
                              >
                                <div
                                  className={
                                    location.pathname === a.route
                                      ? "flex items-center font-black"
                                      : "flex items-center "
                                  }
                                >
                                  <FaAngleRight size={15} className="mr-4" />
                                  {a.name}
                                </div>
                              </Menu.Item>
                            );
                          })
                        : null}
                    </div>
                  );
                } else {
                  return (
                    <Menu.Item
                      key={t.name + i}
                      onClick={() => actionRoute(t.route)}
                      className={
                        location.pathname === t.route ? "activeMenuStyle  " : ""
                      }
                    >
                      <div>
                        <div
                          className={
                            location.pathname === t.route
                              ? "flex items-center font-black  "
                              : "flex items-center"
                          }
                        >
                          <FaAngleRight size={15} className="mr-4" />
                          <>
                            {" "}
                            {t.name}
                            {t?.count !== undefined && t?.count > 0 ? (
                              <span className="ml-5 font-normal text-xs text-red-500 p-2">
                                {`( ${t?.count} )`}
                              </span>
                            ) : null}
                          </>
                        </div>
                      </div>
                    </Menu.Item>
                  );
                }
              })
          : null
        : menus.length
        ? menus.map((t, i) => {
            let menuItemKey = t.id.toString();
            if (t.sub.length) {
              return (
                <div
                  className={`px-3 ant-dropdown-menu-title-content cursor-pointer ${
                    t.collapse ? "" : "on-hover-behavoir"
                  }`}
                  key={menuItemKey}
                  onClick={() => func(t, i, !t.collapse)}
                >
                  <div className="flex items-center ">
                    {t.collapse ? (
                      <FaAngleDown
                        size={15}
                        className="mr-4"
                        color={"#5ab3f0"}
                      />
                    ) : (
                      <FaAngleRight size={15} className="mr-4" />
                    )}
                    {t.name}
                  </div>
                  {t.collapse && t.sub?.length
                    ? t.sub.map((a, b) => {
                        return (
                          <Menu.Item
                            key={b}
                            className={
                              location.pathname === a.route
                                ? "activeMenuStyle  "
                                : ""
                            }
                            onClick={() => actionRoute(a.route)}
                          >
                            <div
                              className={
                                location.pathname === a.route
                                  ? "flex items-center font-black"
                                  : "flex items-center "
                              }
                            >
                              <FaAngleRight size={15} className="mr-4" />
                              {a.name}
                            </div>
                          </Menu.Item>
                        );
                      })
                    : null}
                </div>
              );
            } else {
              return (
                <Menu.Item
                  key={t.name + i}
                  onClick={() => actionRoute(t.route)}
                  className={
                    location.pathname === t.route ? "activeMenuStyle  " : ""
                  }
                >
                  <div>
                    <div
                      className={
                        location.pathname === t.route
                          ? "flex items-center font-black  "
                          : "flex items-center"
                      }
                    >
                      <FaAngleRight size={15} className="mr-4" />
                      <>
                        {t.name}
                        {t?.count !== undefined && t?.count > 0 ? (
                          <span className="ml-5 font-normal text-xs text-red-500 p-2">
                            {`( ${t?.count} )`}
                          </span>
                        ) : null}
                      </>
                    </div>
                  </div>
                </Menu.Item>
              );
            }
          })
        : null}
      <Menu.Item onClick={() => onLogout()}>
        {/* <Link to={/}> */}
        <div className="flex items-center">
          <FaAngleRight size={15} className="mr-4" />
          {adminLabels.logOut}
        </div>
        {/* </Link> */}
      </Menu.Item>
    </Menu>
  );
  const sideBarBadgeCounterFunction = () => {
    const a =
      allBookingOrders?.filter(
        (item) =>
          ["dispute", "cancelled"]?.includes(item?.status) ||
          item.is_reassign === 1
      )?.length || 0;

    const b = budgetResponse.filter(
      (fItem) => fItem.is_read_admin === 0
    )?.length;

    const c = helpUnRelatedToService?.length || 0;
    const d = workWithUsList?.length || 0;
    const e =
      ordersPayouts?.length +
        disputeOrdersPayouts?.length +
        additional_budgetPayouts?.length || 0;
    const f = orderCount?.filter(
      (item) => ![null, undefined, 0].includes(item)
    ).length;

    return a + b + c + d + e + f;
  };
  return (
    <header className="grid grid-flow-row auto-rows-max">
      <nav className="nav-container">
        <div className="nav-center">
          <div className="nav-header app-header">
            <Dropdown
              overlay={menu}
              overlayStyle={{ top: 75 }}
              className={"hellos"}
              trigger={["click"]}
              onVisibleChange={(e) => setCollapse(e)}
              arrow={false}
            >
              <a
                className="ant-dropdown-link"
                onClick={() => {}}
                style={{ minWidth: 40 }}
              >
                <Badge count={sideBarBadgeCounterFunction() || 0}>
                  <img
                    className=""
                    src={collapse ? Images.cross.default : Images.menu.default}
                  />
                </Badge>
              </a>
            </Dropdown>
            <Link to="/admin/dashboard" className="lg:ml-16 md:ml-8 sm:ml-4 ">
              <img width={"160px"} src={Images.logo.default} />
            </Link>
          </div>
          <div className="flex items-center justify-start align-nav-items">
            <div className="mr-5 text-xs">
              <Dropdown
                overlay={langMenu}
                placement="bottomRight"
                className=""
                arrow
              >
                <Button
                  type="text"
                  icon={
                    <img
                      src={
                        require(`../../assets/flags/${
                          localStorage.getItem("primaryLanguage") === "english"
                            ? "US"
                            : "ES"
                        }.png`).default
                      }
                      style={{ width: 15, height: 15, Radius: 20 }}
                    />
                  }
                >
                  <b>
                    {localStorage.getItem("primaryLanguage") === "english"
                      ? "Eng"
                      : "Es"}
                  </b>
                </Button>
              </Dropdown>
            </div>

            <div className="mr-5">
              <Link to="#" className="text-black capitalize">
                {props.userName}
              </Link>
            </div>
            <div>
              <Link to="#">
                <img
                  src={Images.userProfilePlaceholder.default}
                  style={{ height: 45, width: 45 }}
                />
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

const mapStateToProps = (state) => {
  return {
    loginInfo: state.loginDetail.loggedUserDetail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setLogoutUser: (data) => {
      dispatch(LogoutUserAction(data));
    },
    removeBeneficiaries: () => {
      dispatch(DeleteBeneficiariesAtLogoutTime());
    },
    removeProviders: () => {
      dispatch(DeleteProvidersAtLogoutTime());
    },
    removeServiceAreas: () => {
      dispatch(DeleteServiceAreasAtLogoutTime());
    },
    removeBranches: () => {
      dispatch(DeleteBranchesAtLogoutTime());
    },
    removeServices: () => {
      dispatch(DeleteServicesAtLogoutTime());
    },
    removeServiceForms: () => {
      dispatch(DeleteServiceForms());
    },
    removeAppUsers: () => {
      dispatch(DeleteAppUsers());
    },
    setLoaderState: (bool) => {
      dispatch(LoaderStateAction(bool));
    },
    getProviders: (props) => {
      dispatch(FetchProvidersAction(props));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
